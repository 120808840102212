import { AfterViewInit, OnInit, Component, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { TableDataService } from '../../../Services/tableData.service';
import { Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../../../Services/app-config.service';
import { EodHeaderModel } from '../../../DataModels/eod-header-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
// import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import {DataExportService} from '../../../Services/dataExport.service';
import {BsToastService} from '../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-pos-data-browser',
  templateUrl: './pos-data-browser-list.component.html',
  styleUrls: ['./pos-data-browser-list.component.css'],
})
export class PosDataBrowserListComponent implements AfterViewInit, OnInit, OnDestroy {
  // end grid defs -----

  constructor(
    private table: TableDataService,
    private router: Router,
    private storage: StorageService,
    private appConfig: AppConfigService,
    private notification: BsToastService,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    public dataExport: DataExportService,
    private titleService: Title
  ) {}

  gridIsReady = false;
  apiController = '';
  dataSetFlag = 0;
  datasetName = '';
  exportDT: Date;
  selectedBDay = '';
  selectedStore = '';
  eod: EodHeaderModel;
  PosDataCount = 0;
  // accounts$: Observable<any>;
  eodCount = 0;
  itemSalesCount = 0;
  currencyCount = 0;
  isLoaded = false;
  dayList: Array<EodHeaderModel>;
  storeList: Array<EodHeaderModel>;

  isLoading = false;

  vm = {
    list: [],
  };

  // excel export stuff
  @ViewChild('posDataBrowser') posDataBrowser: ElementRef;

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;
  datasetType = 0;

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;
  dtColumnDefs: DataTables.ColumnDefsSettings[] = [];

  ngOnInit() {
    this.titleService.setTitle($localize`:POS Data Browser@@posDataBrowser:POS Data Browser`);
    this.vm.list = [];
    this.configDataTable();
    // this.getItemSalesSummaryData(1);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([1, 'asc']);
  }

  ngAfterViewInit() {
    this.getBusinessDayData();
    // this.getItemSalesSummaryData(1);
  }

  excelBtnOnClick() {
    const currentDate = new Date().toDateString();
    const table = this.posDataBrowser.nativeElement;

    this.dataExport.exportToExcel(table, 'POS_Data ' + currentDate + '.xlsx', currentDate);
  }

  onChangeBDay(event): void {
    const newVal = event.target.value;
    // alert(newVal);
    this.selectedBDay = newVal;
    this.storeList = null; // clear store  list because date change
    this.getStoresForBDate(); // now we know the date so go get stores for that date - binds list var on get
  }

  onChangeStore(event): void {
    const newVal = event.target.value;
    this.selectedStore = newVal;
  }

  onChangeDataset(event): void {
    if (this.isLoaded) {
      this.vm.list = [];
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy(false);
      });
    }
    const newVal = event.target.value;
    this.datasetType = newVal;
  }

  getData(value: string) {
    this.vm.list = [];
    console.log(this.vm.list.length);

    this.isLoading = true;

    if (value == '0') {
      this.apiController = 'MenuItemSales';
    } else if (value == '1') {
      this.apiController = 'ItemSales';
    }

    this.datasetName = this.apiController;

    this.table
      .getTableData(
        this.apiController,
        '?storeCode=' + this.selectedStore + '&eodHeaderBusinessDate=' + this.selectedBDay
      )
      .subscribe(
        (result) => {
          // console.log('Controller: ' + this.apiController + ' Store: ' + this.selectedStore + ' BDay: ' + this.selectedBDay);
          this.vm.list = result.Data;
          // console.log('sysusr data:' + JSON.stringify(this.vm.list));
          this.dtTrigger.next('');
          this.dataTableReady = true;

          this.PosDataCount = result.length;
          this.isLoaded = true;
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
    this.getItemSalesSummaryData(value);

    this.isLoading = false;
  }

  getPagedApiData(value: string) {
    this.isLoading = true;

    if (value == '0') {
      this.apiController = 'MenuItemSales';
    } else if (value == '1') {
      this.apiController = 'ItemSales';
    }

    this.datasetName = this.apiController;

    this.table
      .getTableData(
        this.apiController,
        '?storeCode=' + this.selectedStore + '&eodHeaderBusinessDate=' + this.selectedBDay
      )
      .subscribe(
        (result) => {
          this.vm.list = result;
          this.dtTrigger.next('');
          this.dataTableReady = true;

          this.PosDataCount = result.length;
          this.isLoaded = true;
          this.eodCount = this.vm.list.length;
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );

    this.getItemSalesSummaryData(value);

    this.isLoading = false;
  }

  getStoresForBDate() {
    this.table.getTableData('EODHeaders').subscribe(
      (result) => {
        // this.dayList = result;
        this.storeList = result;
        // this.eodCount =  this.dayList.length;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading EOD data @@errorloadingEODdata:Error loading EOD data`);
        console.log(error);
      },
      () => {}
    );
  }

  getBusinessDayData() {
    this.table.getTableData('EODHeaders').subscribe(
      (result) => {
        this.dayList = result;
        // this.storeList = result;
        // this.eodCount = this.dayList.length;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading EOD data @@errorloadingEODdata:Error loading EOD data`);
        console.log(error);
      },
      () => {}
    );
  }

  getItemSalesSummaryData(value: string) {
    // just for testing - link it up to the table data type selected after add more api queries etc.
    if (value == '0') {
      this.apiController = 'MenuItemSales';
    } else if (value == '1') {
      this.apiController = 'ItemSales';
    }

    this.table.getTableData(this.apiController, '?fromdate=' + '01-01-2019' + '&todate=' + '01-03-2019').subscribe(
      (result) => {
        // this.chartData = result;
        // console.log(JSON.stringify(result));
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {}
    );
    // chart display settings
    // this.primaryXAxis = {valueType: 'Category', title: 'Items', labelIntersectAction: 'Rotate45'};
    // this.primaryYAxis = {title: 'Sales'};
    // this.tooltip = { enable: true };
    // this.marker = { visible: true, width: 10, height: 10 };
    // this.emptySeries1 = {mode: 'Gap'};
    // this.emptySeries2 = {mode: 'Average'};
    // this.border = { width: 2, color: this.appConfig.chartBorderColor};
    // this.margin = { left: 40, right: 40, top: 40, bottom: 40 };
    // this.animation = { enable: true};
    // // this.chart.animate(5);
    // this.title = $localize`:Top 10 Item Sales Summary @@top10ItemSalesSummary:Top 10 Item Sales Summary`;
  }

  print() {
    // this.chart.print();
  }

  onNavigate(aID: string) {
    // this.router.navigate(['admin/bank-form', {id: aID}]);
  }

  onClose() {
    this.router.navigate(['home']).then();
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
