import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppConfigService} from '../../../Services/app-config.service';
import {AuthService} from '../../../Services/auth.service';

@Component({
  selector: 'app-error500-page',
  templateUrl: './error500-page.component.html',
  styleUrls: ['./error500-page.component.scss']
})
export class Error500PageComponent implements OnInit, AfterViewInit {

  errorCode = '';
  errorFromPage = '';
  errorDateTime = '';

  constructor(
    private appConfig: AppConfigService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.errorCode = localStorage.getItem('lastErrorTrapped',);
    if (this.errorCode === '0'){
      this.errorCode = 'Server Unreachable';
    }

    this.errorFromPage = localStorage.getItem('lastErrorUrl');
    this. errorDateTime = localStorage.getItem('lastErrorDateTime');
  }

  ngAfterViewInit() {
    // const leftMenuColor = this.appConfig.leftMenuThemeColor;
    const bodyThemeColor = this.appConfig.bodyThemeColor;
    // document.body.setAttribute('data-leftbar-theme', leftMenuColor);
    document.body.setAttribute('data-layout-color', bodyThemeColor);

    setTimeout(() => {
      // this._activateMenu();
    });

    // document.body.setAttribute('data-layout-color', leftMenuColor);
  }

  logout() {
    this.authService.logout();
  }
}
