import {Component, Input, OnInit} from '@angular/core';
import {PermissionsService} from '../../../Services/permissions.service';
import {TableDataService} from '../../../Services/tableData.service';
import {StorageService} from '../../../Services/storage.service';
import {DatePipe} from '@angular/common';
import {CustomFilterPipe} from '../../admin/custom-filter.pipe';
import {Router} from '@angular/router';
import {SharedDataService} from '../../../Services/sharedData.service';
import {EncrDecrService} from '../../../Services/encr-decr.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-home-navigation',
  templateUrl: './home-navigation.component.html',
  styleUrls: ['./home-navigation.component.css']
})
export class HomeNavigationComponent implements OnInit {
  @Input() vm: any;
  permissionsAdmin = true;

  constructor(public p: PermissionsService,
              public tableDataService: TableDataService,
              public localStorage: StorageService,
              public datePipe: DatePipe,
              public cFilter: CustomFilterPipe,
              private router: Router,
              private sharedData: SharedDataService,
              private encryptDecrypt: EncrDecrService,
              private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Quick Navigation@@quickNavigation:Quick Navigation`);
    this.getInfo();
    const x = this.localStorage.StorageGet('Permissions');
    this.p.LoadPermissions(JSON.parse(x), 'home nav');
  }

  getInfo() {

    if (this.localStorage.StorageGet('location')) {
      this.vm.location = JSON.parse(this.localStorage.StorageGet('location'));
    } else {
      this.tableDataService.getTableData('location?state=active').subscribe((locationResult: any[]) => {
        this.vm.location = locationResult;
        this.localStorage.StorageSet('location', JSON.stringify(locationResult));
        // this.sharedData.addLocation(JSON.stringify(locationResult));
      });
    }
  }
    encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      } else if (data[d] != null && data[d] == '') {
        ret.push(encodeURIComponent(d) + '=');
      }
    }
    return ret.join('&');
  }
}
