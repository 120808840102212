import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataService } from '../../../../Services/tableData.service';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { Title } from '@angular/platform-browser';
import { StorageService } from '../../../../Services/storage.service';
import {DatePipe, formatDate} from '@angular/common';
import { FilterPipe } from 'ngx-filter-pipe';
import { AppConfigService } from '../../../../Services/app-config.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { DataExportService } from '../../../../Services/dataExport.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import { EmailModel } from '../../../../DataModels/email-model';
import { take } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import {Modal} from 'bootstrap';
import { RoutingTrackerService} from '../../../../Services/routing-tracker.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
})
export class InvoicesComponent implements OnInit, AfterViewInit, AfterViewChecked {
  // @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  // gets email data to send to api for mailing
  @ViewChildren('InvoiceForm') FormDocument: QueryList<ElementRef>;
  @ViewChildren('DocId') RecId: QueryList<ElementRef>;
  @ViewChildren('Email2') EmailFromDoc: QueryList<ElementRef>;

  private rptName: string;
  public urlParams: any = [];
  timeOut = 0;
  public vm: any = {
    exportToExcel: true,
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    report: [],
    request: {
      fromDate: null,
      toDate: null,
      account_id: null,
      entity_id: null,
      location_id: null,
      entity_type_id: null,
      header_id: null,
      batch_id: null,
      company_id: null,
    },
    fromDateError: false,
    toDateError: false,
    dateError: false,
    newBatch: [],
    showSpecificDates: false,
    buttonsDisabled: false,
    showFilters: false,
    d: {
      date: '',
      name: '',
    },
    monthCount: 0,
    subLedger: null,
    locationName: null,
    entity: [],
    entityType: [],
    entityFilter: {
      name: null,
    },
    entityInput: null,
    company: [],
    location: [],
    invoice: [],
    reportTotal: 0,
    reportSubtotal: 0,
    reportTax1Code: '',
    reportTax2Code: '',
    reportTax3Code: '',
    reportTax1Total: 0,
    reportTax2Total: 0,
    reportTax3Total: 0,
    entity_email: '',
    startEntityName: 0,
    startEntity: 0,
    entityName: [],
    email: {
      toemail: null,
      fromemail: this.appConfig.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    invoiceFilter: {
      search_string: null,
    },
    batchFilter: {
      description: null,
    },
    batch: [],
    updateBatch: null,
    showEmail: true,
    batchName: [],
    startBatch: 0,
    startBatchName: 0,
    batchInput: null,
    invoiceName: [],
    startInvoice: 0,
    startInvoiceName: 0,
    invoiceInput: null,
  };
  batchRecvdFlag = false;

  buttonConfig: any = {
    reportFormat: true,
    print: true,
    email: true,
    update: true,
    exportExcel: true,
  };


  div: HTMLElement;
  emailToSendArray: EmailModel[] = [];
  recIdArray = [];
  emailAddressesFromDocArray = [];
  reportFormatCheckBoxValue = true;
  reportDataToSendInEmail: EmailModel = new EmailModel();
  routedFrom = '';

  constructor(
    private route: ActivatedRoute,
    public tableDataService: TableDataService,
    public dateFilter: DateFilterService,
    private titleService: Title,
    public localStorage: StorageService,
    private router: Router,
    // public aEmail: EmailService,
    public notification: BsToastService,
    public excelExporter: DataExportService,
    public appConfig: AppConfigService,
    public prevUrl: RoutingTrackerService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  ngOnInit() {
    this.vm.buttonsDisabled = true;
    this.vm.report_format = true;

    this.urlParams = this.route.snapshot.queryParams;

    this.vm.subLedger = this.urlParams.subLedger;
    this.vm.type = this.urlParams.type;

    if (this.urlParams.batch_id ) {
      this.batchRecvdFlag = true;
      this.vm.batch = this.urlParams.batch_id;
      this.vm.batch_id = this.urlParams.batch_id;
    }

    this.vm.request.entity_id = this.urlParams.entity_id;


    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.rptName = $localize`:Invoices @@invoices:Invoices`;
    this.FilterOptionsInit().then();


    if (this.batchRecvdFlag) {
      this.update();
    } else {
      this.FilterOptionsInit().then(x => {
        this.update();
      });
    }
  }

  ngAfterViewInit() {
    let normalizedReportName = '';

    // at some point the def of sales and purchase order got changed when dealing with reporting - values on queries are diff so this norms
    if (this.vm.subLedger.toUpperCase() == 'SALESORDERS' || this.vm.subLedger.toUpperCase() == 'SO' ) {
      normalizedReportName = $localize`:Sales Order @@salesOrder:Sales Order`;
    }
    if (this.vm.subLedger.toUpperCase() == 'PURCHASEORDERS' || this.vm.subLedger.toUpperCase() == 'PO' ) {
      normalizedReportName = $localize`:Purchase Order @@purchaseOrder:Purchase Order`;
    }

    this.titleService.setTitle(
      normalizedReportName + ' ' + $localize`:Report @@report:Report`
    );
  }

  ngAfterViewChecked() {
    if (this.vm.report.length > 0 && this.vm.report_format == true) {
      const divReport = document.getElementById('excel-export');
      this.reportDataToSendInEmail.pdfHtml = divReport.outerHTML;
      this.reportDataToSendInEmail.activity_message = 'Invoice Report Sent';
    } else if (this.vm.report.length > 0 && this.vm.report_format == false) {
      const divForm = document.getElementById('invoice-form');
      this.reportDataToSendInEmail.pdfHtml = divForm.outerHTML;
      this.reportDataToSendInEmail.activity_message = 'Invoice Sent';
    }
    this.reportDataToSendInEmail.header_id = '';
    this.reportDataToSendInEmail.entity_id = '';
  }

  async getRecId() {
    // because ng .toArray makes elementref array - I have to build my own regular array - index will match main doc since in same doc
    this.recIdArray = [];
    this.RecId.changes.pipe(take(1)).subscribe((r) => {
      this.RecId.forEach((el, index) => {
        // console.log(`index ${index} :` + el.nativeElement.innerText);
        this.recIdArray.push(el.nativeElement.innerText);
      });
    });
    // console.log('recid array: ' + JSON.stringify(this.recIdArray));
    return this.recIdArray;
  }

  async getEmail() {
    // because ng .toArray makes elementref array - I have to build my own regular array - index will match main doc since in same doc
    this.emailAddressesFromDocArray = [];
    this.EmailFromDoc.changes.pipe(take(1)).subscribe((r) => {
      this.EmailFromDoc.forEach((el, index) => {
        // console.log(`index ${index} :` + el.nativeElement.innerText);
        this.emailAddressesFromDocArray.push(el.nativeElement.innerText);
      });
    });
    // console.log('recid array: ' + JSON.stringify(this.recIdArray));
    return this.emailAddressesFromDocArray;
  }

 setEmailData() {
    // do stuff to send email to via senemail modal inoput
   const element = document.getElementById('sendEmailModal') as HTMLElement;
   const emailModal = new Modal(element);
   emailModal.show();


 }

  reportFormat(event: any) {
    // console.log('report format');
    // no need to show form for AP and PS
    if (this.vm.subLedger === 'ap' || this.vm.subLedger === 'ps') {
      event = true;
      this.vm.report_format = event;
      this.reportFormatCheckBoxValue = event;
    } else {
      this.reportFormatCheckBoxValue = event;
    }
    // this.reportFormatCheckBoxValue = event;
    this.emailToSendArray = [];

    if (this.vm.report.length > 0 && !event) {
      this.getRecId().then();
      this.FormDocument.changes.pipe(take(1)).subscribe(async (r) => {
        await this.getEmail().then();
        // console.log('recid array before subscribe main doc: ' + JSON.stringify(this.recIdArray));
        let noEmailAddress = false;
        let noEmailAddressCount = 0;
        // let noEmailCustomerName = [];

        this.FormDocument.forEach((element, index) => {

          const emailDocObj = {} as EmailModel;

          emailDocObj.fromEmail = '';
          emailDocObj.fromName = 'AcctXErp';
          emailDocObj.header_id = this.recIdArray[index];
          emailDocObj.toEmail = this.emailAddressesFromDocArray[index];
          emailDocObj.body = 'Please see the attached Invoice.' + '<br><br><br>'
            + 'Best regards,'
            + '<br><br>'
            + 'AcctXErp'
            + '<br><br>';
          emailDocObj.Subject = 'AcctXErp Invoice';


          // *** Beware *** will extract EVERY occurrence of the match pattern for email
          // alternate method for getting email address - not fond of it - too many possible fails.
          // emailDocObj.toEmail = element.nativeElement.innerText.match(/[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+/g);

          emailDocObj.pdfHtml = element.nativeElement.outerHTML;
          emailDocObj.includeAttachment = true;

          if (emailDocObj.toEmail) {
            this.emailToSendArray.push(emailDocObj);
          } else {
            noEmailAddress = true;
            noEmailAddressCount += 1;
          }
        });
        // console.log('testArray of email and html: ');
        // console.log(JSON.stringify(this.emailToSendArray));

        if (noEmailAddress) {
          this.notification.showWarningToast(
            noEmailAddressCount + ' item(s) have no To email address and will not be sent if emailed'
          );
        }
      });
    }
  }

  sendInvoices() {
    this.tableDataService
      .putTableDataByArray('email/PostByArray', this.emailToSendArray)
      .subscribe((resp: HttpResponse<any>) => {
        if (resp != null) {
          // this.notification.showWarningToast(resp.statusText.toString());
        }
        // this.emailToSendArray = [];
      });
  }

  exportToExcel() {
    this.vm.buttonsDisabled = true;
    if (this.vm.report_format == false) {
      this.vm.report_format = true;
    }

    setTimeout(() => {
      this.excelExporter.exportToExcel(this.ExcelExport, 'Invoice Report.xlsx');
      this.vm.buttonsDisabled = false;
    }, 1000);
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  async FilterOptionsInit() {
    this.vm.buttonsDisabled = true;
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getTableData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });
    this.tableDataService
      .getTableData('entity_type?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.entityType = result.Data;
      });
    this.vm.entity = await this.getFilterValues('entity', '?state=active');
    for (const [x, value] of Object.entries(this.vm.entity)) {
      this.vm.entityName.push({ name: this.vm.entity[x].name });
    }
    if (this.vm.request.batch_id) {
      this.tableDataService.getTableData('t_batch', this.vm.request.batch_id).subscribe((result: any[]) => {
        this.vm.newBatch = result;
      });
    }
    if (this.vm.subLedger == '2') {
      this.vm.subLedger = 'ar';
    } else if (this.vm.subLedger == '4') {
      this.vm.subLedger = 'ap';
    }
    if (!this.vm.subLedger) {
      this.vm.subLedger = 'ar';
    }

    let tempBatchType = null;
    if (this.vm.subLedger === 'ap') {
      tempBatchType = '4';
    } else if (this.vm.subLedger === 'ar') {
      tempBatchType = '2';
    } else if (this.vm.subLedger === 'sq') {
      tempBatchType = '8';
    } else if (this.vm.subLedger === 'so') {
      tempBatchType = '9';
    } else if (this.vm.subLedger === 'po') {
      tempBatchType = '10';
    } else if (this.vm.subLedger === 'ps') {
      tempBatchType = '12';
    }
    // console.log('before:' + this.urlParams.entity_id);
    // console.log('before:' + JSON.stringify(this.vm.entity));
    if (this.urlParams.entity_id != null || this.urlParams.entity_id != undefined) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        // console.log('outside if:' + this.vm.entity[y].id);
        if (this.vm.entity[y]) {
          // console.log('inside if:' + this.vm.entity[y].id);
          if (this.vm.entity[y].id == this.urlParams.entity_id) {
            this.vm.entityInput = this.vm.entity[y].name;
            this.vm.request.entity_id = this.vm.entity[y].id;
            this.vm.request.entity_email = this.vm.entity[y].email;
            // console.log('break:' + this.vm.entityInput);
            break;
          }
        }
      }
    }
    if (this.urlParams.invoiceId) {
      this.vm.request.header_id = this.urlParams.invoiceId;
    }
    // this.vm.urlParamValid = false;

    if (this.urlParams.fromDate) {
      // this.vm.urlParamValid = true;
      this.vm.request.fromDate = this.urlParams.fromDate;
      // } else if (this.localStorage.StorageGet('fromDate', '')) {
      //   this.vm.request.fromDate = this.localStorage.StorageGet('fromDate', '');
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth() - 3, 1);
      this.vm.request.fromDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      // this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
    }

    if (this.urlParams.toDate) {
      // this.vm.urlParamValid = true;
      this.vm.request.toDate = this.urlParams.toDate;
      // } else if (this.localStorage.StorageGet('toDate', '')) {
    } else {
      let d = new Date();
     // console.log('date1: ' + d);
      d = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      // d.setDate(d.getDate());
      // console.log('date: ' + d);
      this.vm.request.toDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      // this.localStorage.StorageSet('toDate', this.vm.request.toDate);
    }

    if (this.vm.request.toDate && this.vm.request.fromDate && this.vm.request.entity_id) {
      // if (this.vm.urlParamValid) {
      // this.update();
      // }
    }
    this.vm.buttonsDisabled = false;
  }

  async getFilterValues(tablename, param) {
    let values;

    await this.tableDataService.getTableData(tablename + param + '&pageNumber=0&pageSize=99999')
      .toPromise()
      .then((result: ResponseModel) => {
        const data: any = result.Data;
        values = data;
      });

    return values;
  }

  newDateFilter(toDate, fromDate) {
    this.vm.buttonsDisabled = true;
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
    this.vm.buttonsDisabled = false;
  }

  update() {

   //  console.log('batchflag: ' + this.batchRecvdFlag);

    let tempToDate: any;
    let tempFromDate: any;

    tempToDate = new Date(this.vm.request.toDate);
    tempFromDate = new Date(this.vm.request.fromDate);

    if (this.batchRecvdFlag) {
      this.vm.request.fromDate = new Date();
      this.vm.request.toDate = new Date();
      tempFromDate = new Date(this.vm.request.fromDate);
      tempToDate = new Date(this.vm.request.toDate);
    }

   // console.log('url from: ' + this.vm.request.fromDate);
   // console.log('url to: ' + this.vm.request.toDate);

    let errorDescription = '';
    this.vm.fromDateError = this.vm.toDateError = this.vm.dateError = false;

    if (!this.vm.request.toDate && !this.batchRecvdFlag) {
      errorDescription += $localize`:To Date is required. @@toDateisrequired:To Date is required.`;
      this.vm.toDateError = true;
    }

    if (!this.vm.request.fromDate && !this.batchRecvdFlag) {
      errorDescription += $localize`:From Date is required. @@fromDateisrequired:From Date is required.`;
      this.vm.fromDateError = true;
    }

    if (tempToDate - tempFromDate < 0 && !this.vm.request.batch_id) {
      this.vm.dateError = true;
      errorDescription += $localize`:The To Date must be greater than the From Date. @@theToDatemustbegreaterthantheFromDate:The To Date must be greater than the From Date.`;
    }

    if (errorDescription && !this.batchRecvdFlag) {
      this.notification.showWarningToast(errorDescription);
    } else {
      this.vm.buttonsDisabled = true;
      this.vm.report = [];

      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      // a batch was sent in query - use it and ignore other params
      if (this.batchRecvdFlag) {
        this.vm.request.batch_id = this.urlParams.batch_id;
        this.vm.request.toDate = '';
        this.vm.request.fromDate = '';
      }

      // fix dates for sql
      let from = '';
      let to = '';

     // console.log('here');

      if (!this.batchRecvdFlag) {
        // from = new Date(this.vm.request.fromDate).toISOString();
        // to = new Date(this.vm.request.toDate).toISOString();
        from = formatDate(this.vm.request.fromDate, 'yyyy-MM-dd', 'en-US');
        to = formatDate(this.vm.request.toDate, 'yyyy-MM-dd', 'en-US');

        // to = this.dateFilter.GetDateFormat(this.vm.request.toDate);
        // from = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      } else {
        from = this.vm.request.fromDate;
        to = this.vm.request.toDate;
      }

      const urlParamsJson = {
        fromDate: !this.vm.request.batch_id ? from : '',
        toDate: !this.vm.request.batch_id ? to : '',
        subLedger: this.vm.subLedger,
        entity_id: this.vm.request.entity_id,
        location_id: this.vm.request.location_id,
        company_id: this.vm.request.company_id,
        entity_type_id: this.vm.request.entity_type_id,
        // header_id: this.vm.request.batch_id,
        batch_id: this.vm.request.batch_id ? this.vm.request.batch_id : '',
      };

      const urlParams: string = this.encodeQueryData(urlParamsJson);
      // console.log('query: ' + urlParams);

      this.tableDataService.getTableData('InvoiceReport', '?' + urlParams).subscribe((result) => {
        this.vm.report = result;
        // console.log(JSON.stringify(this.vm.report));
        if (!this.vm.report.length) {
          this.notification.showWarningToast('No data matching selection criteria');
          this.vm.buttonsDisabled = false;
          return;
        }
        this.calcTotals();
        this.vm.showFilters = false;
        this.vm.buttonsDisabled = false;
        this.reportFormat(this.reportFormatCheckBoxValue);
      });
    }
  }

  calcTotals() {
    this.vm.reportTotal = 0;
    this.vm.reportSubtotal = 0;
    this.vm.reportTax1Code = '';
    this.vm.reportTax2Code = '';
    this.vm.reportTax3Code = '';
    this.vm.reportTax1Total = 0;
    this.vm.reportTax2Total = 0;
    this.vm.reportTax3Total = 0;
    let reverse = -1;
    if (this.vm.subLedger === 'ap') {
      reverse = 1;
    }

    for (const [x, value] of Object.entries(this.vm.report)) {
      this.vm.reportSubtotal += this.vm.report[x].amount;
      this.vm.reportTotal += this.vm.report[x].amount;
      this.vm.report[x].tax_total_1 = 0;
      this.vm.report[x].tax_total_2 = 0;
      for (const [y, value2] of Object.entries(this.vm.report[x].details)) {
        if (this.vm.reportTax1Code === '') {
          this.vm.reportTax1Code = this.vm.report[x].details[y].tax_1_code;
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.reportTax1Code === this.vm.report[x].details[y].tax_1_code) {
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.reportTax1Code === this.vm.report[x].details[y].tax_2_code) {
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_2;
        }
        if (this.vm.reportTax2Code === '') {
          this.vm.reportTax2Code = this.vm.report[x].details[y].tax_2_code;
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.reportTax2Code === this.vm.report[x].details[y].tax_2_code) {
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.reportTax2Code === this.vm.report[x].details[y].tax_1_code) {
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_1;
        }

        if (!this.vm.report[x].tax_code_1) {
          this.vm.report[x].tax_code_1 = this.vm.report[x].details[y].tax_1_code;
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.report[x].tax_code_1 === this.vm.report[x].details[y].tax_1_code) {
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.report[x].tax_code_1 === this.vm.report[x].details[y].tax_2_code) {
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_2;
        }
        if (!this.vm.report[x].tax_code_2) {
          this.vm.report[x].tax_code_2 = this.vm.report[x].details[y].tax_2_code;
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.report[x].tax_code_2 === this.vm.report[x].details[y].tax_2_code) {
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.report[x].tax_code_2 === this.vm.report[x].details[y].tax_1_code) {
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_1;
        }
        this.vm.reportTotal += this.vm.report[x].details[y].tax_amount_1 + this.vm.report[x].details[y].tax_amount_2;
      }
    }
  }

  close() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom == undefined) {
      window.close();
    } else {
      this.router.navigateByUrl('/home').then();
    }
  }

  clear() {
    this.vm.request = {
      fromDate: null,
      toDate: null,
      account_id: null,
      entity_id: null,
      location_id: null,
      entity_type_id: null,
      header_id: null,
      batch_id: null,
      company_id: null,
    };
    this.vm.report = [];
    this.vm.entityInput = '';
  }

  emailReport(toEmail) {
    // console.log('emailing');
    // let tempPdfHtml = '';
    // if (this.reportFormatCheckBoxValue) {
    //   tempPdfHtml = this.FormDocument..innerHTML;
    // } else {
    //   tempPdfHtml = this.FormDocument.nativeElement.innerHTML;
    // }

    // this.vm.email.toemail = toEmail;
    // this.vm.email.PdfHtml = tempPdfHtml;
    // this.vm.email.Subject = this.vm.subLedger.toUpperCase() + ' ' + this.rptName + ' Report';
    // this.aEmail.sendEmail(this.vm.email);
  }

  updateEntity() {
    this.vm.entity_email = '';
    this.vm.buttonsDisabled = true;
    this.vm.startEntityName = 0;
    // this.localStorage.StorageSet('entity', '');
    this.tableDataService
      .getTableData('entity', '?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.entity = result.Data;
        this.vm.buttonsDisabled = false;
      });
  }

  updateInvoice() {
    this.vm.buttonsDisabled = true;
    this.vm.startInvoiceName = 0;
    // this.localStorage.StorageSet('invoice', '');
    let tempBatchType = null;

    if (this.vm.subLedger === 'ap') {
      tempBatchType = '4';
      // document.getElementById('payables').classList.add('active');
      // document.getElementById('ap-invoices').classList.add('active');
    } else if (this.vm.subLedger === 'ar') {
      tempBatchType = '2';
      // document.getElementById('receivables').classList.add('active');
      // document.getElementById('ar-invoices').classList.add('active');
    } else if (this.vm.subLedger === 'sq') {
      tempBatchType = '8';
      // document.getElementById('crm').classList.add('active');
      // document.getElementById('sales-quote-report').classList.add('active');
    } else if (this.vm.subLedger === 'so') {
      tempBatchType = '9';
      // document.getElementById('sales').classList.add('active');
      // document.getElementById('sales-order-report').classList.add('active');
    } else if (this.vm.subLedger === 'po') {
      tempBatchType = '10';
      // document.getElementById('purchases').classList.add('active');
      // document.getElementById('purchase-order-report').classList.add('active');
    } else if (this.vm.subLedger === 'ps') {
      tempBatchType = '12';
      // document.getElementById('purchases').classList.add('active');
      // document.getElementById('purchase-shipment-report').classList.add('active');
    } else if (this.vm.subLedger === 'ss') {
      // document.getElementById('sales').classList.add('active');
      // document.getElementById('sales-shipment-report').classList.add('active');
    }

    const entryParamsJson = {
      fromDate: '',
      toDate: '',
      numberOfResults: this.appConfig.maxRecords,
      batchType: tempBatchType,
      search: '',
      unpaidInvoices: '',
      pageNumber: 0,
      pageSize: 999999,
    };

    const entryParams: string = this.encodeQueryData(entryParamsJson);

    this.tableDataService.getTableData('entry', '?' + entryParams).subscribe((result: ResponseModel) => {
      this.vm.invoice = result.Data;
      this.vm.buttonsDisabled = false;
    });
  }

  updateBatch() {
    this.vm.buttonsDisabled = true;
    this.vm.startBatchName = 0;
    // this.localStorage.StorageSet('batch', '');
    let tempBatchType = null;
    if (this.vm.subLedger === 'ap') {
      tempBatchType = '4';
    } else if (this.vm.subLedger === 'ar') {
      tempBatchType = '2';
    } else if (this.vm.subLedger === 'sq') {
      tempBatchType = '8';
    } else if (this.vm.subLedger === 'so') {
      tempBatchType = '9';
    } else if (this.vm.subLedger === 'po') {
      tempBatchType = '10';
    } else if (this.vm.subLedger === 'ps') {
      tempBatchType = '12';
    }

    this.tableDataService.getTableData('t_batch', '?state=active&batchType=' + tempBatchType).subscribe((result) => {
      // this.tableDataService.getTableData('t_batch', '?state=active').subscribe((result) => {
      this.vm.batch = result;
      this.vm.buttonsDisabled = false;
    });
  }

  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id === '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name === entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.entity_email = this.vm.entity[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  selectBatch(batchName) {
    this.vm.request.batch_id = '';
    if (this.vm.request.batch_id === '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.batch)) {
        if (this.vm.batch[y].description === batchName) {
          this.vm.batchInput = batchName;
          this.vm.request.batch_id = this.vm.batch[y].id;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput === '') {
      this.vm.request.entity_id = undefined;
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.entityInput }).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].name;
        const entityEmail = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].email;
        if (entityId !== '' || entityId !== null) {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
          this.vm.entity_email = entityEmail;
        } else {
          this.vm.request.entity_id = this.vm.entity_email = undefined;
        }
      } else {
        this.vm.request.entity_id = this.vm.entity_email = undefined;
      }
    }
    if (entityIdStart !== this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }

  testBatch() {
    const batchIdStart = this.vm.request.batch_id;
    if (!this.vm.batchInput || this.vm.batchInput === '' || this.vm.batchInput === null) {
      this.vm.request.batch_id = undefined;
    } else {
      if (this.FilterObject(this.vm.batch, { description: this.vm.batchInput }).length > 0) {
        const batchId = this.FilterObject(this.vm.batch, { description: this.vm.batchInput }, true)[0].id;
        if (batchId !== '' || batchId !== null) {
          this.vm.request.batch_id = batchId;
        }
      }
    }
    if (batchIdStart !== this.vm.request.batch_id) {
      this.vm.report = [];
    }
  }

  testInvoice() {
    const batchIdStart = this.vm.request.batch_id;
    if (!this.vm.batchInput || this.vm.batchInput === '' || this.vm.batchInput === null) {
      this.vm.request.batch_id = undefined;
    } else {
      if (this.FilterObject(this.vm.batch, { description: this.vm.batchInput }).length > 0) {
        const batchId = this.FilterObject(this.vm.batch, { description: this.vm.batchInput }, true)[0].id;
        if (batchId !== '' || batchId !== null) {
          this.vm.request.batch_id = batchId;
        }
      }
    }
    if (batchIdStart !== this.vm.request.batch_id) {
      this.vm.report = [];
    }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  selectInvoice(InvoiceId) {}
}
