import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencyRateModel } from '../../../../DataModels/currency-rate-model';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { Observable } from 'rxjs';
import { CurrencyModel } from '../../../../DataModels/currency-model';
import {BsToastService} from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-currency-rate-form',
  templateUrl: './currency-rate-form.component.html',
  styleUrls: ['./currency-rate-form.component.css'],
})
export class CurrencyRateFormComponent implements OnInit, AfterViewInit {
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  public vm: any = {};

  currencyRateId: string;
  currencyRateCount = 0;
  currencyRateForm: UntypedFormGroup;
  auditTrail: any = [];
  currency$: Observable<CurrencyModel[]>;
  currencyRate: CurrencyRateModel;
  currencyRateData: any = [];

  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    private titleService: Title,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.createForm();
    this.currencyRateId = this.route.snapshot.params.id;
  }

  ngOnInit() {
    if (this.currencyRateId) {
      this.loadCurrencyRate();
      this.loadAuditTrial();
      this.buttonConfig.reset = false;
    }
    this.loadCurrency();
    this.vm.title = $localize`:Currency Rate @@currencyRate:Currency Rate`;
    this.vm.buttonsDisabled = false;
  }

  get f() {
    return this.currencyRateForm.controls;
  }

  createForm() {
    this.currencyRateForm = this.formBuilder.group({
      currency_id: new UntypedFormControl(''),
      rate: new UntypedFormControl(''),
      spot_date: new UntypedFormControl(''),
      from_date: new UntypedFormControl(''),
      to_date: new UntypedFormControl(''),
      active: new UntypedFormControl('true'),
    });
  }

  private loadCurrencyRate() {
    this.tableDataService.getTableData('currency_rate/' + this.currencyRateId).subscribe(
      (result) => {
        this.currencyRate = result;
        this.currencyRateData = result;
        this.currencyRateCount = result.length;
        // this.localStorage.StorageSet('currencyRate', JSON.stringify(result));
      },
      (error: HttpErrorResponse) => {
        this.notification.showWarningToast(error.message);
        console.log(error);
      },
      () => {
        if (this.currencyRate.spot_date !== null) {
          this.currencyRate.spot_date = this.dateFilter.GetDateFormat(this.currencyRate.spot_date);
        }
        if (this.currencyRate.from_date !== null) {
          this.currencyRate.from_date = this.dateFilter.GetDateFormat(this.currencyRate.from_date);
        }
        if (this.currencyRate.to_date !== null) {
          this.currencyRate.to_date = this.dateFilter.GetDateFormat(this.currencyRate.to_date);
        }
        this.currencyRateForm.patchValue(this.currencyRate);
      }
    );
  }

  private loadAuditTrial() {
    this.tableDataService.getTableData('currency_rate/' + this.currencyRateId).subscribe(
      (result) => {
        this.auditTrail = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Currency Rate @@errorLoadingCurrencyRate:Error loading Currency Rate`
        // );
        console.log(error + ' ' + $localize`:Error loading Currency Rate @@errorLoadingCurrencyRate:Error loading Currency Rate`);
      }
    );
  }

  private loadCurrency() {
    this.tableDataService.getApiListData('currency', '?state=&pageNumber=0&pageSize=999999').subscribe((res: any) => {
      this.currency$ = res.Data;
      this.vm.currencyLoaded = true;
    });
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  saveClose() {
    if (this.onSave(true)) {
      this.onClose();
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.currencyRateForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete currency rate? @@areYouSureYouWantToDeleteCurrencyRate:Are you sure you want to delete currency rate?`,
      this.currencyRate.currency_name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.tableDataService.deleteTableDataById('currency_rate', this.currencyRateId).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  resetForm() {
    this.currencyRateForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/currency-rate-list']).then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.currencyRateForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    // validate dates selection
    if (this.f.spot_date.value === null && this.f.from_date.value === null && this.f.to_date.value === null) {
      this.notification.showWarningToast('Enter Spot or From - To dates');
      return;
    }
    if (
      (this.f.spot_date.value !== null && this.f.from_date.value !== null) ||
      (this.f.spot_date.value !== null && this.f.to_date.value !== null)
    ) {
      this.notification.showWarningToast('Enter Spot or From To dates - not both');
      return;
    }
    if (this.f.from_date.value == null && this.f.spot_date.value !== null && this.f.spot_date.value === null) {
      this.notification.showWarningToast('Choose dates');
      return;
    }

    // console.log('spot: ' + this.f.spot_date.value);
    // console.log('from: ' + this.f.from_date.value);
    // console.log('to: ' + this.f.to_date.value);

    //  this.vm.buttonsDisabled = true;
    this.currencyRate = Object.assign({}, this.currencyRateForm.value);
    if (this.currencyRate.spot_date) {
      // this.currencyRate.spot_date = this.dateFilter.GetDateFormat(this.currencyRate.spot_date);
      this.currencyRate.spot_date = new Date(this.currencyRate.spot_date).toISOString();
    }
    if (this.currencyRate.from_date) {
      // this.currencyRate.from_date = this.dateFilter.GetDateFormat(this.currencyRate.from_date);
      this.currencyRate.from_date = new Date(this.currencyRate.from_date).toISOString();
    }
    if (this.currencyRate.to_date) {
      // this.currencyRate.to_date = this.dateFilter.GetDateFormat(this.currencyRate.to_date);
      this.currencyRate.to_date = new Date(this.currencyRate.to_date).toISOString();
    }

    // have to send the api empty and not null for empty dates before posting to api
    if (this.f.spot_date.value === null) {
      this.currencyRateForm.patchValue({
        spot_date: null,
      });
    }
    if (this.f.from_date.value === null) {
      this.currencyRateForm.patchValue({
        from_date: null,
      });
    }
    if (this.f.to_date.value === null) {
      this.currencyRateForm.patchValue({
        to_date: null,
      });
    }

    // console.log('After:');
    // console.log('spot: ' + this.f.spot_date.value);
    // console.log('from: ' + this.f.from_date.value);
    // console.log('to: ' + this.f.to_date.value);
    //

    if (this.currencyRateId === undefined || this.currencyRateId === 'new') {
      // new
      this.tableDataService.post('currency_rate', this.currencyRate).subscribe(
        (item: any) => {
          // stops double posting when clicking Save
          let apiReturn: any = null;
          if (typeof item === 'object') {
            apiReturn = JSON.parse(JSON.stringify(item));
          }
          this.currencyRateId = apiReturn.id;
          // this.notification.success($localize`:Saved @@saved:Saved`);
          // return true;
          if (saveClose) {
            this.onClose();
          }
          return true;
        },
        (error: HttpErrorResponse) => {
          // this.notification.error(
          //   $localize`:Error creating new Currency Rate @@errorCreatingNewCurrencyRate:Error creating new Currency Rate`
          // );
          console.log(error + $localize`:Error creating new Currency Rate @@errorCreatingNewCurrencyRate:Error creating new Currency Rate`);
        }
      );
    } else {
      // edit
      console.log('rate: ' + JSON.stringify(this.currencyRate));
      this.tableDataService.patch('currency_rate', this.currencyRateId, this.currencyRate).then(
        (item: any) => {
          // this.notification.success($localize`:Updated @@updated:Updated`);
          if (saveClose) {
            this.onClose();
          }
          return true;
        },
        (error: HttpErrorResponse) => {
          // this.notification.error(
          //   $localize`:Error updating Currency Rate @@errorupdatingCurrencyRate:Error updating Currency Rate`
          // );
          console.log(error + ' - ' +  $localize`:Error updating Currency Rate @@errorupdatingCurrencyRate:Error updating Currency Rate`);
        }
      );
    }
    this.submitted = false;
    return true;
  }
}
