import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { StorageService } from '../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { TableDataService } from '../../../Services/tableData.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { EmailService } from '../../../Services/email.service';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../DataModels/response-model';
import {
  WorkorderModel,
  WoHoursEntity,
  WoAttachmentsEntity,
  WoTravelEntity,
} from '../../../DataModels/workorder-model';
import { BsToastService } from '../../../Services/bs-toast-service';
import { CustomFilterPipe } from '../../admin/custom-filter.pipe';
import {RoutingTrackerService} from '../../../Services/routing-tracker.service';

@Component({
  selector: 'app-work-orders-form',
  templateUrl: './work-orders-form.component.html',
  styleUrls: ['./work-orders-form.component.css'],
})
export class WorkOrdersFormComponent implements OnInit, AfterViewInit, DoCheck {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private filter: FilterPipe,
    private emailService: EmailService,
    private confirmDialogService: ConfirmDialogService,
    public cFilter: CustomFilterPipe,
    public prevUrl: RoutingTrackerService
  ) {
    // todo turn on when converted to reactive
    // this.createForm();

    this.vm.entity = [];
    this.vm.item.wo_attachments = [];
    this.vm.item.wo_header.description = '';
    this.vm.item.wo_notes = [];

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }

    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  // easy way to access form ctrls
  get f() {
    return this.workorderForm.controls;
  }
  vm: any = {
    title: $localize`:Work Order @@workOrder:Work Order`,
    item: {
      wo_header: [],
    },
    company: [],
    entity: [],
    inventoryItem: [],
    location: [],
    uom: [],
    user: [],
    auditTrail: {},
    history: { Pricing: {} },
    action: {},
    printItem: {},
    permissions: {},
    Preferences: {},
    email: {},
    invoices: [],
    buttonsDisabled: false,
    formLink: '/work-orders/work-orders-form',
    mainListPage: '/work-orders/work-orders-list',
    shipToAddress: [],
    billToAddress: [],
    contact: [],
    payment: {
      batch: [],
      header: [],
      detail: [],
    },
    inventoryItemFilter: [],
    entityFilter: [],
    searchRecentItems: {},
    searchTopItems: {},
    deleted: {},
    is_init: false,
    buttonDisabled: false,
    subLedger: '',
    itemSelectModalId: 1,
  };
  showDelete;
  show = false;
  submitted = false;
  isLoaded = false;
  urlParams: any = [];
  routedFrom = '';
  formLoaded: Promise<boolean>;

  // converting old js form to reactive
  workorder: WorkorderModel;
  workorderForm: UntypedFormGroup;
  woItemsForm: UntypedFormGroup;
  woHoursForm: UntypedFormGroup;
  woAttachmentsForm: UntypedFormGroup;
  woNotesForm: UntypedFormGroup;
  woTravelForm: UntypedFormGroup;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    print: true,
    saveNew: false,
    saveClose: false,
  };

  protected readonly window = window;

  async ngOnInit() {
    this.isLoaded = false;

    this.urlParams = this.route.snapshot.queryParams;
    this.vm.workorderId = this.urlParams.workorderId;
    this.vm.action = this.urlParams.action;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    // loads loadWorkorder when there is an id
    if (!this.vm.workorderId) {
      const x = this.loadEntity().then((value) => {});
    }
    this.loadLookups();

    this.loadWorkorder();
  }

  ngAfterViewInit() {
    // this.titleService.setTitle(this.vm.title  + $localize`:Workorder @@workorder:Workorder ` );
  }

  ngDoCheck() {
    this.isLoaded = true;
  }

  // interim error check for required
  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  // TODO reactive not fully implemented yet - when complete change error trapping for reauired
  createForm() {
    this.workorderForm = this.formBuilder.group({
      id: new UntypedFormControl([0]),
      entity_id: new UntypedFormControl([0]),
      address_id: new UntypedFormControl([0]),
      contact_id: new UntypedFormControl([0]),
      company_id: new UntypedFormControl(null),
      status: new UntypedFormControl([0]),
      work_order_date: new UntypedFormControl([0]),
      required_by_date: new UntypedFormControl([0]),
      next_visit_date: new UntypedFormControl([0]),
      completed_date: new UntypedFormControl([0]),
      assigned_user_id: new UntypedFormControl([0]),
      number: new UntypedFormControl([0]),
      description: new UntypedFormControl([0]),
      dispatch_notes: new UntypedFormControl([0]),
      signature: new UntypedFormControl([0]),
      audit_sequence: new UntypedFormControl([0]),
      wo_items: new UntypedFormArray([]),
      wo_hours: new UntypedFormArray([]),
      wo_attachments: new UntypedFormArray([]),
      wo_notes: new UntypedFormArray([]),
      wo_travel: new UntypedFormArray([]),
    });
  }

  async loadEntity(): Promise<any> {
    this.tableDataService
      .getApiListData('entity?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.entity = data;
        this.spliceReceivableEntities();
      });
    return true;
  }

  loadLookups() {
    this.tableDataService
      .getApiListData('sys_user?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.user = result.Data;
      });

    this.tableDataService
      .getApiListData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });

    this.tableDataService
      .getApiListData('uom?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.uom = result.Data;
      });

    this.tableDataService
      .getApiListData('item?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.inventoryItem = data;
        this.buildInventoryItemName();
      });

    this.tableDataService
      .getApiListData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });

    // this.spliceReceivableEntities();
    // this.buildEntityName();
    return true;
  }

  loadItems() {
    let tempUOM;
    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      this.vm.item.wo_items[x].date = this.datePipe.transform(this.vm.item.wo_items[x].date, 'MM/dd/yyyy');
      // this.vm.item.wo_items[x].itemInput = this.filter.transform(this.vm.inventoryItem, {
      //   id: this.vm.item.wo_items[x].item_id,
      // })[0].code;
      // this.vm.item.wo_items[x].description = this.filter.transform(this.vm.inventoryItem, {
      //   id: this.vm.item.wo_items[x].item_id,
      // })[0].name;

      const item = this.vm.inventoryItem.find(y => y.id === this.vm.item.wo_items[x].item_id);
      if (item !== undefined) {
        this.vm.item.wo_items[x].itemInput = item.code;
        this.vm.item.wo_items[x].description = item.name;
      }
      // const tempuom = this.vm.inventoryItem.find(y => y.id === this.vm.item.wo_items[x].item_id);
      // if (tempuom !== undefined) {
      //   tempUOM = tempuom.uom.id;
      // }


      tempUOM = this.filter.transform(this.vm.inventoryItem, { id: this.vm.item.wo_items[x].item_id })[0].uom_id;

      if (this.filter.transform(this.vm.uom, { id: tempUOM }).length > 0) {
        this.vm.item.wo_items[x].uom_code = this.filter.transform(this.vm.uom, { id: tempUOM })[0].code;
      }
    }
  }

  loadWorkorder(): boolean {
    this.loadEntity().then();
    if (this.vm.workorderId !== undefined) {
      if (!this.vm.permissions.Admin) {
        this.vm.item.wo_header.assigned_user_id = this.filter.transform(this.vm.user, { me: true })[0].id;
      }
      this.vm.item.batch = {};
      this.vm.item.wo_header = {
        status: 'Loading...',
        number: 'Loading...',
        work_order_date: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
        description: '', // setting to empty allows calc of length
        dispatch_notes: '',
      };
      this.vm.item.wo_items = [];
      this.vm.item.wo_hours = [];
      this.vm.item.wo_travel = [];
      this.vm.item.wo_notes = [];
      if (this.vm.Preferences.default_company_id) {
        this.vm.item.wo_header.company_id = this.vm.Preferences.default_company_id;
      } else if (this.vm.company.length === 1) {
        this.vm.item.wo_header.company_id = this.vm.company[0].id;
      }
      this.vm.item.wo_header.entityInput = '';
      this.vm.item.header = this.vm.item.wo_header;
      this.vm.item.header.detail_total = 0;
      // this.updateEntity();

      // this.submitted = true;

      this.tableDataService.getTableData('workorder', this.vm.workorderId).subscribe((result) => {
        // todo turn on when reactive conversion completed
        // this.workorder = result as unknown as WorkorderModel;
        this.vm.item = result;
        // console.log('work order');
        // console.log(result);
        this.isLoaded = true;
        // todo turn on when reactive conversion completed
        // this.workorderForm.patchValue(this.workorder);
        // this.titleService.setTitle(this.vm.title + ' ' + this.vm.item.wo_header.number);
        this.vm.item.header = this.vm.item.wo_header;
        this.vm.item.header.detail_total = 0;
        if (this.vm.item.wo_header.number) {
          this.signaturetext();
          this.addText();
        }

        let ent: any = [];
        ent = this.vm.entity.find((x) => x.id === this.vm.item.wo_header.entity_id);
        // console.log(ent);
        if(ent) {
          this.vm.item.wo_header.entityInput = ent.name;
          this.vm.email.ToEmail = ent.email;
        }
        //     this.tableDataService.getTableData('entity_address/addresses?billing_address=1&entity_id=' + this.vm.item.wo_header.entity_id + '&pageNumber=0&pageSize=999999').subscribe((result1: ResponseModel) => {
        //       this.vm.billToAddress = result1.Data;
        //       if (this.vm.billToAddress.length !== 0) {
        //         this.vm.item.wo_header.bill_address_id = this.vm.billToAddress[0].id;
        //       }
        //     });
        //
        //     this.tableDataService.getTableData('entity_address/addresses?billing_address=0&entity_id=' + this.vm.item.wo_header.entity_id + '&pageNumber=0&pageSize=999999').subscribe((result1: ResponseModel) => {
        //       this.vm.shipToAddress = result1.Data;
        //       if (this.vm.shipToAddress.length !== 0) {
        //         this.vm.item.wo_header.address_id = this.vm.shipToAddress[0].id;
        //       }
        //     });
        //     this.getContact();
        //     // this.getHistory();
        //   } else {
        //     this.notification.error($localize`:The existing entity is no longer valid, please select another entity. @@theexistingentityisnolongervalidpleaseselectanotherentity:The existing entity is no longer valid, please select another entity.` + '  ');
        //     // error entity is no longer the correct receivable or payable
        //     this.vm.entityError = true;
        //     this.vm.item.wo_header.entity_id = null;
        //   }
        // }

        this.getAddresses2();
        this.getContacts2();
        this.getHistory();

        if (this.vm.item.wo_items.length == 0) {
          this.vm.item.wo_items = [];
        } else {
          this.loadItems();
          // let tempUOM;
          // for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
          //   this.vm.item.wo_items[x].date = this.datePipe.transform(this.vm.item.wo_items[x].date, 'MM/dd/yyyy');
          //   this.vm.item.wo_items[x].itemInput = this.filter.transform(this.vm.inventoryItem, {id: this.vm.item.wo_items[x].item_id})[0].code;
          //   this.vm.item.wo_items[x].description = this.filter.transform(this.vm.inventoryItem, {id: this.vm.item.wo_items[x].item_id})[0].name;
          //   tempUOM = this.filter.transform(this.vm.inventoryItem, {id: this.vm.item.wo_items[x].item_id})[0].uom_id;
          //   if (this.filter.transform(this.vm.uom, {id: tempUOM}).length > 0) {
          //     this.vm.item.wo_items[x].uom_code = this.filter.transform(this.vm.uom, {id: tempUOM})[0].code;
          //   }
          // }
        }
        if (this.vm.item.wo_hours == null) {
          this.vm.item.wo_hours = [];
        } else {
          for (const [x, value] of Object.entries(this.vm.item.wo_hours)) {
            this.vm.item.wo_hours[x].date = this.datePipe.transform(this.vm.item.wo_hours[x].date, 'MM/dd/yyyy');
          }
        }
        if (this.vm.item.wo_travel == null) {
          this.vm.item.wo_travel = [];
        } else {
          for (const [x, value] of Object.entries(this.vm.item.wo_travel)) {
            this.vm.item.wo_travel[x].date = this.datePipe.transform(this.vm.item.wo_travel[x].date, 'MM/dd/yyyy');
          }
        }
        if (this.vm.item.wo_notes == null) {
          this.vm.item.wo_notes = [];
        } else {
          for (const [x, value] of Object.entries(this.vm.item.wo_notes)) {
            this.vm.item.wo_notes[x].date = this.datePipe.transform(this.vm.item.wo_notes[x].date, 'MM/dd/yyyy');
          }
        }
        this.vm.item.wo_header.work_order_date = this.datePipe.transform(
          this.vm.item.wo_header.work_order_date,
          'MM/dd/yyyy'
        );
        if (this.vm.item.wo_header.required_by_date != null) {
          this.vm.item.wo_header.required_by_date = this.datePipe.transform(
            this.vm.item.wo_header.required_by_date,
            'MM/dd/yyyy'
          );
        }
        if (this.vm.item.wo_header.completed_date != null) {
          this.vm.item.wo_header.completed_date = this.datePipe.transform(
            this.vm.item.wo_header.completed_date,
            'MM/dd/yyyy'
          );
        }
        if (this.vm.item.wo_header.next_visit_date != null) {
          this.vm.item.wo_header.next_visit_date = this.datePipe.transform(
            this.vm.item.wo_header.next_visit_date,
            'MM/dd/yyyy'
          );
        }
        // this.getInvoices();

        // this.localStorage.StorageSet('formDateWorkorder', JSON.stringify(this.vm.item));
        this.titleService.setTitle(this.vm.title + ' ' + this.vm.item.wo_header.number);
        this.addText();
        // console.log('resolving promise');
        // this.formLoaded = Promise.resolve(true);

        // if (this.vm.action === 'print' && this.formLoaded) {
        //     // this.print();
        //     console.log('then inside prom');
        //   }
      });
      // this.tableDataService.getTableData('audit_trail', 'recId=' + this.vm.workorderId).subscribe((result: any[]) => {
      //   this.vm.auditTrail = result;
      // });
      // return false;
    } else {
      this.updateEntity();
      // console.log(JSON.stringify(this.vm.user));
      if (!this.vm.permissions.Admin) {
        this.vm.item.wo_header.assigned_user_id = this.filter.transform(this.vm.user, { me: true })[0].id;
      }
      this.vm.item.batch = {};
      this.vm.item.wo_header = {
        status: 'Open',
        number: '*** NEW ***',
        work_order_date: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
        description: '', // setting to empty allows calc of length
        dispatch_notes: '',
      };
      this.vm.item.wo_items = [];
      this.vm.item.wo_hours = [];
      this.vm.item.wo_travel = [];
      this.vm.item.wo_notes = [];
      if (this.vm.Preferences.default_company_id) {
        this.vm.item.wo_header.company_id = this.vm.Preferences.default_company_id;
      } else if (this.vm.company.length == 1) {
        this.vm.item.wo_header.company_id = this.vm.company[0].id;
      }
      this.vm.item.wo_header.entityInput = '';
      this.vm.item.header = this.vm.item.wo_header;
      this.vm.item.header.detail_total = 0;

      this.addItemRows(3);
      this.addHourRows(1);
      this.addNoteRows(1);
      this.addTravelRows(1);
    }
    this.spliceReceivableEntities();
    // });

    this.vm.startInventoryItem =
      this.vm.startAccount =
      this.vm.startEntity =
      this.vm.invoiceSubTotal =
      this.vm.invoiceTaxTotal =
      this.vm.invoiceInvoiceTotal =
        0;
    this.vm.inventoryItemFilter.search_string = null;

    // this.getAddresses();
    // this.isLoaded = true;

    // be sure it is ALL loaded before we print
    if (this.vm.action === 'print') {
      this.print();
    }
    return true;
  }

  addItemRows(rows) {
    let maxSortOrder = 1;

    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      maxSortOrder = parseInt(x, 10) + rows + 1;
    }
    for (let m = 0; m < rows; m++) {
      this.vm.item.wo_items.push({
        id: maxSortOrder,
        sort_order: maxSortOrder,
      });
      maxSortOrder++;
    }
  }

  addHourRows(rows) {
    let maxSortOrder = 1;

    for (const [x, value] of Object.entries(this.vm.item.wo_hours)) {
      maxSortOrder = parseInt(x, 10) + rows + 1;
    }
    for (let h = 0; h < rows; h++) {
      this.vm.item.wo_hours.push({
        id: maxSortOrder,
        user_id: this.vm.item.wo_header.assigned_user_id,
        date: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
        sort_order: maxSortOrder,
      });
      maxSortOrder++;
    }
  }

  addTravelRows(rows) {
    let maxSortOrder = 1;
    for (const [x, value] of Object.entries(this.vm.item.wo_travel)) {
      maxSortOrder = parseInt(x, 10) + rows + 1;
    }

    for (let x = 0; x < rows; x++) {
      this.vm.item.wo_travel.push({
        id: maxSortOrder,
        user_id: this.vm.item.wo_header.assigned_user_id,
        date: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
        sort_order: maxSortOrder,
      });
      maxSortOrder++;
    }
  }

  addNoteRows(rows) {
    let maxSortOrder = 1;
    for (const [x, value] of Object.entries(this.vm.item.wo_notes)) {
      maxSortOrder = parseInt(x, 10) + rows + 1;
    }
    for (let x = 0; x < rows; x++) {
      this.vm.item.wo_notes.push({
        id: maxSortOrder,
        date: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
        sort_order: maxSortOrder,
      });
      maxSortOrder++;
    }
  }

  // Entity Functions

  spliceReceivableEntities() {
    for (let y = this.vm.entity.length - 1; y > -1; y--) {
      if (this.vm.entity[y].receivable == '0') {
        if (this.vm.entity[y].id !== this.vm.item.wo_header.entity_id || !this.vm.item.wo_header.entity_id || 1 === 1) {
          this.vm.entity.splice(y, 1);
        }
      }
    }
    this.buildEntityName();
  }

  buildEntityName() {
    let tempName;
    this.vm.entityName = [];

    for (const [y, value] of Object.entries(this.vm.entity)) {
      tempName = this.vm.entity[y].name;
      this.vm.entityName.push({
        name: tempName,
      });
    }
  }

  IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  updateEntity() {
    this.vm.buttonsDisabled = true;
    this.vm.startEntityName = 0;
    this.tableDataService
      .getTableData('entity?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.entity = result.Data;
        // console.log('entites: ' + JSON.stringify(this.vm.entity));
        // console.log('vm: ' + JSON.stringify(this.vm) );
        // this.localStorage.StorageSet('entity', JSON.stringify(result));
        // if (this.vm.billToAddress.length === 1) {
        //   this.vm.item.wo_header.bill_address_id = this.vm.billToAddress[0].id;
        // }
        this.vm.buttonsDisabled = false;
        this.spliceReceivableEntities();
      });
  }

  clearEntity2() {
    this.vm.item.wo_header.entityInput = '';
    this.vm.item.wo_header.ship_address_id = '';
    this.vm.item.wo_header.bill_address_id = '';
    this.vm.item.wo_header.contact_id = '';
  }

  selectEntityModal(entityName) {
    this.vm.item.wo_header.entity_id = null;

    for (const [y, value] of Object.entries(this.vm.entity)) {
      if (this.vm.entity[y].name == entityName) {
        this.vm.item.wo_header.entityInput = entityName;
        this.vm.item.wo_header.entity_id = this.vm.entity[y].id;

        this.tableDataService
          .getTableData(
            'entity_address/addresses?billing_address=1&entity_id=' +
              this.vm.item.wo_header.entity_id +
              '&pageNumber=0&pageSize=999999'
          )
          .subscribe((result: ResponseModel) => {
            this.vm.billToAddress = result.Data;
            if (this.vm.billToAddress.length !== 0) {
              this.vm.item.wo_header.bill_address_id = this.vm.billToAddress[0].id;
            }
          });
        this.tableDataService
          .getTableData(
            'entity_address/addresses?billing_address=0&entity_id=' +
              this.vm.item.wo_header.entity_id +
              '&pageNumber=0&pageSize=999999'
          )
          .subscribe((result: ResponseModel) => {
            this.vm.shipToAddress = result.Data;
            if (this.vm.shipToAddress.length !== 0) {
              this.vm.item.wo_header.address_id = this.vm.shipToAddress[0].id;
            }
          });

        // this.getHistory();
        this.getContact();
        break;
      }
    }
  }

  getContacts2() {
    // this.tableDataService
    //   .getTableData('entity_contact?entity_id=' + this.vm.item.wo_header.entity_id)
    //   .subscribe((result: any[]) => {
    //     this.vm.contact = result;
    //   });
    this.tableDataService
      .getTableData('entity_contact', 'GetContactsByEntityId?entityId=' + this.vm.item.wo_header.entity_id)
      .subscribe((result: any[]) => {
        this.vm.contact = result;
        if (this.vm.contact.length === 1) {
          // this.vm.item.wo_header.address_id = this.vm.contact[0].id;
        }
      });
  }

  getAddresses2() {
    this.tableDataService
      .getTableData(
        'entity_address/addresses?billing_address=1&entity_id=' +
          this.vm.item.wo_header.entity_id +
          '&pageNumber=0&pageSize=999999'
      )
      .subscribe((result: ResponseModel) => {
        this.vm.billToAddress = result.Data;
      });
    this.tableDataService
      .getTableData(
        'entity_address/addresses?billing_address=0&entity_id=' +
          this.vm.item.wo_header.entity_id +
          '&pageNumber=0&pageSize=999999'
      )
      .subscribe((result: ResponseModel) => {
        this.vm.shipToAddress = result.Data;
      });
    // this.getHistory();
  }

  // getAddresses() {
  //   if (this.vm.item.wo_header.entityInput) {
  //     for (const [y, value] of Object.entries(this.vm.entity)) {
  //       if (this.vm.entity[y].name === this.vm.item.wo_header.entityInput) {
  //         this.vm.item.wo_header.entity_id = this.vm.entity[y].id;
  //
  //         this.tableDataService
  //           .getTableData(
  //             'entity_address/addresses?billing_address=1&entity_id=' +
  //               this.vm.item.wo_header.entity_id +
  //               '&pageNumber=0&pageSize=999999'
  //           )
  //           .subscribe((result: ResponseModel) => {
  //             this.vm.billToAddress = result.Data;
  //             if (this.objectKeys(this.vm.billToAddress).length === 1) {
  //               this.vm.item.wo_header.bill_address_id = this.vm.billToAddress[0].id;
  //             }
  //           });
  //
  //         this.tableDataService
  //           .getTableData(
  //             'entity_address/addresses?billing_address=0&entity_id=' +
  //               this.vm.item.wo_header.entity_id +
  //               '&pageNumber=0&pageSize=999999'
  //           )
  //           .subscribe((result: ResponseModel) => {
  //             this.vm.shipToAddress = result.Data;
  //             if (this.objectKeys(this.vm.shipToAddress).length === 1) {
  //               this.vm.item.wo_header.address_id = this.vm.shipToAddress[0].id;
  //             }
  //           });
  //
  //         this.tableDataService
  //           .getTableData(
  //             'entity_contact?entity_id=' + this.vm.item.wo_header.entity_id + '&pageNumber=0&pageSize=999999'
  //           )
  //           .subscribe((result: ResponseModel) => {
  //             this.vm.contact = result.Data;
  //             if (this.objectKeys(this.vm.contact).length === 1) {
  //               this.vm.item.wo_header.address_id = this.vm.contact[0].id;
  //             }
  //           });
  //         // this.getHistory();
  //         // this.getContact();
  //         // break;
  //       }
  //     }
  //   } else if (this.vm.item.wo_header.entity_id) {
  //     this.tableDataService
  //       .getTableData(
  //         'entity_address/addresses?billing_address=1&entity_id=' +
  //           this.vm.item.wo_header.entity_id +
  //           '&pageNumber=0&pageSize=999999'
  //       )
  //       .subscribe((result: ResponseModel) => {
  //         this.vm.billToAddress = result.Data;
  //         if (this.objectKeys(this.vm.billToAddress).length === 1) {
  //           this.vm.item.wo_header.bill_address_id = this.vm.billToAddress[0].id;
  //         }
  //       });
  //     this.tableDataService
  //       .getTableData(
  //         'entity_address/addresses?billing_address=0&entity_id=' +
  //           this.vm.item.wo_header.entity_id +
  //           '&pageNumber=0&pageSize=999999'
  //       )
  //       .subscribe((result: ResponseModel) => {
  //         const data: any = result.Data;
  //         this.vm.shipToAddress = data;
  //         if (this.objectKeys(this.vm.shipToAddress).length === 1) {
  //           this.vm.item.wo_header.address_id = this.vm.shipToAddress[0].id;
  //         }
  //       });
  //
  //     this.tableDataService
  //       .getTableData('entity_contact?entity_id=' + this.vm.item.wo_header.entity_id)
  //       .subscribe((result: any[]) => {
  //         this.vm.contact = result;
  //         if (this.objectKeys(this.vm.contact).length === 1) {
  //           this.vm.item.wo_header.address_id = this.vm.contact[0].id;
  //         }
  //       });
  //     // this.getHistory();
  //   }
  // }

  getAddresses3() {
    let paramString = '';
    if (this.vm.item.header.entityInput) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == this.vm.item.header.entityInput) {
          this.vm.item.header.entity_id = this.vm.entity[y].id;

          paramString = '';
          paramString += 'billing_address=1';
          paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService
            .getTableData('entity_address/addresses', '?' + paramString)
            .subscribe((result: ResponseModel) => {
              const data: any = result.Data;
              this.vm.billToAddress = data;
              if (this.vm.billToAddress.length == 1) {
                this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
              }
            });

          paramString = '';
          paramString += 'billing_address=0';
          paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService
            .getTableData('entity_address/addresses', '?' + paramString)
            .subscribe((result: ResponseModel) => {
              const data: any = result.Data;
              this.vm.shipToAddress = data;
              if (this.vm.shipToAddress.length == 1) {
                this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
              }
            });

          paramString = '';
          paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: ResponseModel) => {
            const data: any = result.Data;
            this.vm.contact = data;
            if (this.vm.contact.length == 1) {
              this.vm.item.header.contact_id = this.vm.contact[0].id;
            }
          });

          this.getHistory();
          this.getContacts2();
          break;
        }
      }
    } else if (this.vm.item.header.entity_id) {
      paramString = '';
      paramString += 'billing_address=1';
      paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
      paramString += '&pageNumber=0';
      paramString += '&pageSize=999999';

      this.tableDataService
        .getTableData('entity_address/addresses', '?' + paramString)
        .subscribe((result: ResponseModel) => {
          const data: any = result.Data;
          this.vm.billToAddress = data;
          if (this.vm.billToAddress.length == 1) {
            this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
          }
        });

      paramString = '';
      paramString += 'billing_address=0';
      paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
      paramString += '&pageNumber=0';
      paramString += '&pageSize=999999';

      this.tableDataService
        .getTableData('entity_address/addresses', '?' + paramString)
        .subscribe((result: ResponseModel) => {
          const data: any = result.Data;
          this.vm.shipToAddress = data;
          if (this.vm.shipToAddress.length == 1) {
            this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
          }
        });

      paramString = '';
      paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
      paramString += '&pageNumber=0';
      paramString += '&pageSize=999999';

      this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.contact = data;
        if (this.vm.contact.length == 1) {
          this.vm.item.header.contact_id = this.vm.contact[0].id;
        }
      });
      this.getHistory();
    }
  }

  getHistory() {
    if (this.vm.item.header.entity_id) {
      this.tableDataService.getTableData('entity_history', this.vm.item.header.entity_id).subscribe((result: any) => {
        this.vm.history = result;
        this.applyDefaults();
      });
    }
  }

  applyDefaults() {
    if (!this.vm.history.Defaults) {
      return;
    }
    if (this.vm.history.Defaults.term_id) {
      this.vm.item.header.term_id = this.vm.history.Defaults.term_id;
    }
    if (this.vm.history.Defaults.location_id) {
      if (this.vm.item.header.location_id && this.vm.item.header.location_id != this.vm.history.Defaults.location_id) {
        this.notification.showWarningToast('Location is not the default');
      } else if (!this.vm.item.header.location_id) {
        this.vm.item.header.location_id = this.vm.history.Defaults.location_id;
      }
    }
    if (this.vm.history.Defaults.payment_type_id) {
      this.vm.payment.header.payment_type_id = this.vm.history.Defaults.payment_type_id;
    }
  }

  getContact() {
    this.tableDataService
      .getTableData('entity_contact', 'GetContactsByEntityId?entityId=' + this.vm.item.wo_header.entity_id)
      .subscribe((result: any[]) => {
        this.vm.contact = result;
        if (this.vm.contact.length === 1) {
          this.vm.item.wo_header.address_id = this.vm.contact[0].id;
        }
      });
  }

  testEntity() {
    if (!this.vm.item.wo_header.entityInput) {
      this.vm.alert.status = 'error';
      // this.vm.alert.message.push({ text: $localize`:Entity is required. @@entityisrequired:Entity is required.` });
      this.notification.showWarningToast($localize`:Entity is required. @@entityisrequired:Entity is required.`);
      this.vm.entityError = true;
    } else {
      if (this.filter.transform(this.vm.entity, { name: this.vm.item.wo_header.entityInput }).length > 0) {
        const entityId = this.filter.transform(this.vm.entity, { name: this.vm.item.wo_header.entityInput })[0].id;
        const entityName = this.filter.transform(this.vm.entity, { name: this.vm.item.wo_header.entityInput })[0].name;
        const entityEmail = this.filter.transform(this.vm.entity, { name: this.vm.item.wo_header.entityInput })[0]
          .email;
        if (entityId !== '' && entityId !== null) {
          this.vm.item.wo_header.entity_id = entityId;
          this.vm.item.wo_header.entityInput = entityName;
          this.vm.email.ToEmail = entityEmail;
        } else {
          this.vm.item.wo_header.entity_id = null;
          this.vm.alert.status = 'error';
          this.vm.alert.message.push({ text: $localize`:Entity not found. @@entitynotfound:Entity not found.` });
          this.vm.entityError = true;
        }
      } else {
        this.vm.item.wo_header.entity_id = null;
        this.vm.email.ToEmail = '';
        this.vm.alert.status = 'error';
        this.vm.alert.message.push({
          text:
            $localize`:Entity @@entity:Entity` +
            ' ' +
            this.vm.item.wo_header.entityInput +
            ' ' +
            $localize`:not found. @@notfound:not found.`,
        });
        this.vm.entityError = true;
      }
    }
  }

  save() {
    this.submitted = true;
    this.vm.companyError = false;
    this.vm.buttonsDisabled = true;
    let errorMessage = '';

    if (!this.vm.item.wo_header.entity_id) {
      errorMessage += $localize`:Entity Required. @@entityRequired:Entity Required.` + '</br>';
      // this.notification.showWarningToast($localize`:Entity Required. @@entityRequired:Entity Required.` + ' ');
    }
    if (!this.vm.item.wo_header.company_id) {
      errorMessage += $localize`:Company Required. @@companyRequired:Company Required.` + '</br>';
    }
    if (!this.vm.item.wo_header.work_order_date) {
      errorMessage += $localize`:Work Order Date Required. @@workOrderDateRequired:Work Order Date Required.` + '</br>';
      this.vm.woDateError = true;
    }

    if (!this.vm.item.wo_header.required_by_date) {
      errorMessage += $localize`:RequiredDate Required. @@requiredRequired:Required Date Required.` + '</br>';
      this.vm.woRequiredDateError = true;
    }

    if (!this.vm.item.wo_header.address_id) {
      errorMessage += $localize`:Address Required. @@addressRequired:Address Required.` + '</br>';
      this.vm.woAddressError = true;
    }

    if (!this.vm.item.wo_header.assigned_user_id) {
      errorMessage += $localize`:Assigned Tech Required. @@assignedTechRequired:Assigned Tech Required.` + '</br>';
      this.vm.woAssignedUserError = true;
    }

    if (!this.vm.item.wo_header.description) {
      errorMessage += $localize`:Description Required. @@descriptionRequired:Description Required.` + '</br>';
      this.vm.woDescriptionError = true;
    }

    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      if (this.vm.item.wo_header.status === 'Open') {
        this.vm.item.wo_header.status = 'In Progress';
      }
      if (this.vm.item.wo_items[x].item_id && !this.vm.item.wo_items[x].quantity) {
        errorMessage +=
          $localize`:An item row is missing quantity. @@anitemrowismissingquantity:An item row is missing quantity.` +
          '</br>';
      }
    }
    for (const [x, value] of Object.entries(this.vm.item.wo_hours)) {
      if (this.vm.item.wo_header.status === 'Open') {
        this.vm.item.wo_header.status = 'In Progress';
      }
      if (this.vm.item.wo_hours[x].user_id && !this.vm.item.wo_hours[x].hours) {
        errorMessage +=
          $localize`:An hours row is missing hours. @@anhoursrowismissinghours:An hours row is missing hours.` +
          '</br>';
      }
    }
    for (const [x, value] of Object.entries(this.vm.item.wo_travel)) {
      if (this.vm.item.wo_header.status === 'Open') {
        this.vm.item.wo_header.status = 'In Progress';
      }
      if (this.vm.item.wo_travel[x].user_id && !this.vm.item.wo_travel[x].distance) {
        errorMessage +=
          $localize`:A travel row is missing mileage. @@atravelrowismissingmileage:A travel row is missing mileage.` +
          '</br>';
      }
    }
    if (errorMessage !== '') {
      this.vm.buttonsDisabled = false;
      this.notification.showWarningToast(errorMessage);
      return;
    }

    const attachmentElement = document.getElementById('attachments') as HTMLInputElement;

    if (attachmentElement !== null) {
      if (!this.vm.item.wo_attachments) {
        this.vm.item.wo_attachments = [];
      }
      const attachments = attachmentElement.value.split('|');
      for (const element of attachments) {
        let found = false;
        for (const [x, value] of Object.entries(this.vm.item.wo_attachments)) {
          if (this.vm.item.wo_attachments[x].attachment == element) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.vm.item.wo_attachments.push({
            type: 'image',
            header_id: this.vm.item.wo_header.id,
            attachment: element,
          });
        }
      }
    }

      if (this.vm.item.wo_header.signature &&
        this.vm.item.wo_header.signature !== '' &&
        this.vm.item.wo_header.signature !== null
      ) {
        // this.vm.item.wo_header.status = 'Completed';
      }
      if (this.vm.item.wo_header.completed_date) {
        this.vm.item.wo_header.status = 'Completed';
      }
      if (this.vm.item.wo_header.status === 'Completed' && !this.vm.item.wo_header.completed_date) {
        this.vm.item.wo_header.completed_date = this.datePipe.transform(new Date(this.vm.item.wo_header.completed_date), 'MM/dd/yyyy');
      }

      if (this.vm.item.wo_header.id === undefined) {
        this.tableDataService.post('workorder', this.vm.item).subscribe(
          (item: any) => {
            // this.notification.success(item.description);
            this.router.navigateByUrl(this.vm.formLink + '?workorderId=' + item.id).then();
          },
          (error: any) => {
            this.vm.buttonsDisabled = false;
            if (error.error.Message !== undefined) {
              // this.notification.error(error.error.Message);
              console.log(error.error.Message);
            } else {
              // this.notification.error(error);
              console.log(error);
            }
          }
        );
      } else {
        const changes = {};
        this.tableDataService
          .patch('workorder', this.vm.item.wo_header.id, this.vm.item)
          .then((item: any) => {
            // this.notification.success(item.description);
            // this.localStorage.StorageSet('workorder', '');
            this.vm.buttonsDisabled = false;
          })
          .catch((error: { description: any[] }) => {
            this.vm.buttonsDisabled = false;
            if (error.description === undefined) {
              this.router.navigateByUrl(this.vm.mainListPage).then();
              // this.notification.success($localize`:Updated@@updated:Updated`);
            } else {
              // this.notification.error(error.description);
              console.log(error.description);
            }
          });
      }
  }

  /**
   * @file upload functions
   */

  syncItemAttachments(attachmentsArray: any) {
    // console.log('attachments array recvd in main');
    // console.log(attachmentsArray);

    // clear existing - will be put back because in array passed back in file upload array
    this.vm.item.attachments = [];

    // use the pieces needed for the api record
    attachmentsArray.forEach((x) => {
      this.vm.item.attachments.push({
        id: x.id,
        header_id: x.header_id,
        file_name: x.fileName,
        type: x.fileType,
        attachment: x.fileData,
        audit_sequence: 0
      });
    });
    // console.log('main vm array after sync to file upload data');
    // console.log(this.vm);
  }


  gotAnUploadFile(file: any) {
    // console.log('in got a file: ' + file);
    console.log('in got a file: ');
  }



  /**
   * @signature functions
   */
  signature(signature) {
    this.vm.item.wo_header.signature = signature;
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  addText() {
    let text = $localize`:Work Order @@workOrder:Work Order` + ' ';
    if (this.vm.item.wo_header.number) {
      text += $localize`:Number @@number:Number` + ': ' + this.vm.item.wo_header.number + '      ';
    }
    this.vm.addTextSignature = text;
  }

  signaturetext() {
    const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    ctx.font = '15px sans-serif';
    if (this.vm.addTextSignature) {
      ctx.fillText(this.vm.addTextSignature, 20, 270);
    }
  }

  formatDescription(): string {
    let str;
    if (this.vm.item.wo_header.description !== undefined) {
      str = this.vm.item.wo_header.description.padEnd(20 - this.vm.item.wo_header.description.length, ' ');
    } else {
      str = this.vm.item.wo_header.description.padEnd(20, ' ');
    }
    this.vm.item.wo_header.description = str;
    return this.vm.item.wo_header.description;
  }

  // deleteSignature() {
  //   this.vm.item.header.signature = '';
  // }

  deleteSignature() {
    this.vm.item.wo_header.signature = '';
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  clearSignature() {
    // this.signaturePad.clear();
    this.addText();
  }

  /**
   * Inventory Functions
   */

  buildInventoryItemName() {
    this.vm.inventoryItemName = [];
    let tempName;
    for (const [y, value] of Object.entries(this.vm.inventoryItem)) {
      let tempUOM = '';
      if (this.filter.transform(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }).length > 0) {
        tempUOM = this.filter.transform(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id })[0].name;
      }
      tempName = this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name + ' ' + tempUOM;
      this.vm.inventoryItemName.push({
        name: tempName,
      });
    }
  }

  updateInventoryItem() {
    this.vm.startInventoryItem = 0;
    // this.localStorage.StorageSet('inventoryItem', '');
    this.tableDataService
      .getTableData('item?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.inventoryItem = data;
        // this.localStorage.StorageSet('inventoryItem', JSON.stringify(this.vm.inventoryItemName));
      });
    this.buildInventoryItemName();
  }

  selectItemDropdown(id) {
    let foundItem = false;
    let maxSortOrder = 0;

    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      if (maxSortOrder < this.vm.item.wo_items[x].sort_order) {
        maxSortOrder = this.vm.item.wo_items[x].sort_order;
      }
    }
    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      this.vm.item.wo_items[x].disableAccount = false;
      if (this.vm.item.wo_items[x].id == id && this.vm.item.wo_items[x].itemInput) {
        this.vm.item.wo_items[x].item_id = '';
        for (const [y, value2] of Object.entries(this.vm.inventoryItem)) {
          let tempUOM = '';
          if (this.filter.transform(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }).length > 0) {
            tempUOM = this.filter.transform(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id })[0].name;
          }
          if (
            this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name + ' ' + tempUOM ===
              this.vm.item.wo_items[x].itemInput ||
            this.vm.inventoryItem[y].code == this.vm.item.wo_items[x].itemInput
          ) {
            this.vm.item.wo_items[x].header_id = this.vm.item.wo_header.id;
            this.vm.item.wo_items[x].description = this.vm.inventoryItem[y].name;
            this.vm.item.wo_items[x].itemInput = this.vm.inventoryItem[y].code;
            this.vm.item.wo_items[x].item_id = this.vm.inventoryItem[y].id;
            // vm.item.wo_items[x].uom_id = this.vm.inventoryItem[y].uom_id;
            this.vm.item.wo_items[x].uom_code = this.filter.transform(this.vm.uom, {
              id: this.vm.inventoryItem[y].uom_id,
            })[0].code;
            if (this.vm.history.Pricing !== undefined) {
              if (
                this.vm.history.Pricing.length > 0 &&
                (this.vm.subLedger === 'ar' || this.vm.subLedger === 'quotes' || this.vm.subLedger === 'salesorders')
              ) {
                for (const [z, value3] of Object.entries(this.vm.history.Pricing)) {
                  if (this.vm.history.Pricing[z].item_id == this.vm.item.wo_items[x].item_id) {
                    this.vm.item.wo_items[x].price = Number(this.vm.history.Pricing[z].price);
                    break;
                  }
                }
              }
            }
            maxSortOrder++;
            // vm.item.wo_items[x].sort_order = maxSortOrder;
            foundItem = true;
            break;
          }
        }

        if (!foundItem) {
          this.vm.item.wo_items[x].itemInput = '';
          this.notification.showWarningToast(
            $localize`:Invalid Item, please try again. @@invalidItempleasetryagain:Invalid Item, please try again.`
          );
        }
        break;
      }
    }
  }

  setItemSelectModalInputId(id) {
    this.vm.itemSelectModalId = id;
  }

  selectItemModal(itemId) {
    let id;
    id = (document.getElementById('itemSelectDetailId') as HTMLInputElement).value;

    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      if (maxSortOrder < this.vm.item.wo_items[x].sort_order) {
        maxSortOrder = this.vm.item.wo_items[x].sort_order;
      }
    }
    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      // console.log('MARGIN FOR STEP: 1+', value);

      if (this.vm.item.wo_items[x] !== undefined && this.vm.item.wo_items[x].id !== undefined) {
        // console.log('MARGIN IF || STEP: 1+', this.vm.item.wo_items[x].id + '++++' + id);
        if (this.vm.item.wo_items[x].id == id) {
          this.vm.item.wo_items[x].item_id = '';
          for (const [y, value2] of Object.entries(this.vm.inventoryItem)) {
            // console.log('MARGIN STEP: +', value2);

            if (this.vm.inventoryItem[y].id == itemId) {
              this.vm.item.wo_items[x].header_id = this.vm.item.wo_header.id;
              this.vm.item.wo_items[x].description = this.vm.inventoryItem[y].name;
              this.vm.item.wo_items[x].itemInput = this.vm.inventoryItem[y].code;
              this.vm.item.wo_items[x].item_id = this.vm.inventoryItem[y].id;
              this.vm.item.wo_items[x].uom_code = this.filter.transform(this.vm.uom, {
                id: this.vm.inventoryItem[y].uom_id,
              })[0].code;

              if (this.vm.item.wo_items[x].quantity == '' || this.vm.item.wo_items[x].quantity === 'undefined') {
                // vm.item.wo_items[x].quantity = 1;
              }
              this.vm.item.wo_items[x].uom_id = this.vm.inventoryItem[y].uom_id;
              this.vm.item.wo_items[x].price = Number(this.vm.inventoryItem[y].price);
              if (this.vm.history.Pricing !== undefined) {
                if (
                  this.vm.history.Pricing.length > 0 &&
                  (this.vm.subLedger === 'ar' || this.vm.subLedger === 'quotes' || this.vm.subLedger === 'salesorders')
                ) {
                  for (const [z, value3] of Object.entries(this.vm.history.Pricing)) {
                    if (this.vm.history.Pricing[z].item_id == this.vm.item.wo_items[x].item_id) {
                      this.vm.item.wo_items[x].price = Number(this.vm.history.Pricing[z].price);
                      break;
                    }
                  }
                }
              }
              maxSortOrder++;
              break;
            }
          }
          break;
        }
      }
    }
  }

  deleteItemLine(id) {
    for (const [x, value] of Object.entries(this.vm.item.wo_items)) {
      if (this.vm.item.wo_items[x].id == id) {
        this.vm.item.wo_items.splice(x, 1);
        break;
      }
    }
  }

  deleteHourLine(id) {
    for (const [x, value] of Object.entries(this.vm.item.wo_hours)) {
      if (this.vm.item.wo_hours[x].id == id) {
        this.vm.item.wo_hours.splice(x, 1);
        break;
      }
    }
  }

  deleteTravelLine(id) {
    for (const [x, value] of Object.entries(this.vm.item.wo_travel)) {
      if (this.vm.item.wo_travel[x].id == id) {
        this.vm.item.wo_travel.splice(x, 1);
        break;
      }
    }
  }

  deleteNoteLine(id) {
    for (const [x, value] of Object.entries(this.vm.item.wo_notes)) {
      if (this.vm.item.wo_notes[x].id == id) {
        this.vm.item.wo_notes.splice(x, 1);
        break;
      }
    }
  }

  FilterObject(value, filterObj, actual = false) {
    return this.cFilter.transform(value, filterObj, actual);
  }

  async delay(ms: number) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), ms)).then();
  }

  print() {
    // no choice - can't make it wait for so many gets. converting to reactive any way - waiting manually until converted.
    this.notification.showInfoToast(
      $localize`:Creating print document @@creatingprintdocument:Creating print document`
    );
    // sigh ... hate doing this ...
    this.delay(3000).then((x) => {
      this.loadPrintVars();
    });
    this.delay(3000).then((x) => {
      window.print();
    });
  }

  loadPrintVars(): boolean {
    if (this.vm.item.header.bill_address_id) {
      if (this.FilterObject(this.vm.billToAddress, { id: this.vm.item.header.bill_address_id }, true)[0]) {
        const tempAddress: any = this.FilterObject(
          this.vm.billToAddress,
          { id: this.vm.item.header.bill_address_id },
          true
        )[0];
        this.vm.printItem.billToAddress1 = tempAddress.address_1;
        if (tempAddress.address_2 && tempAddress.address_2 != 'null') {
          this.vm.printItem.billToAddress1 += ', ' + tempAddress.address_2;
        }
        if (tempAddress.city != null && tempAddress.city) {
          this.vm.printItem.billToAddress2 = tempAddress.city;
        }
        if (tempAddress.state != null && tempAddress.state) {
          this.vm.printItem.billToAddress2 += ', ' + tempAddress.state;
        }
        if (tempAddress.zip != null && tempAddress.zip) {
          this.vm.printItem.billToAddress2 += '  ' + tempAddress.zip;
        }
      }
    }

    if (this.vm.item.header.ship_address_id) {
      if (this.FilterObject(this.vm.shipToAddress, { id: this.vm.item.header.ship_address_id }, true)[0]) {
        const tempAddress: any = this.FilterObject(
          this.vm.shipToAddress,
          { id: this.vm.item.header.ship_address_id },
          true
        )[0];
        if (tempAddress.address_1 && tempAddress.address_1 != 'null') {
          this.vm.printItem.shipToAddress1 = tempAddress.address_1;
        } else {
          this.vm.printItem.shipToAddress1 = '';
        }
        if (tempAddress.address_2 && tempAddress.address_2 != 'null') {
          this.vm.printItem.shipToAddress1 += ', ' + tempAddress.address_2;
        }
        if (tempAddress.city && tempAddress.city != 'null') {
          this.vm.printItem.shipToAddress2 = tempAddress.city;
        } else {
          this.vm.printItem.shipToAddress2 = '';
        }
        if (tempAddress.state && tempAddress.state != 'null') {
          this.vm.printItem.shipToAddress2 += ', ' + tempAddress.state;
        }
        if (tempAddress.zip && tempAddress.zip != 'null') {
          this.vm.printItem.shipToAddress2 += '  ' + tempAddress.zip;
        }
      }
    }

    if (this.vm.item.header.location_id) {
      if (this.FilterObject(this.vm.location, { id: this.vm.item.header.location_id }, true)[0]) {
        const tempAddress: any = this.FilterObject(
          this.vm.shipToAddress,
          { id: this.vm.item.header.ship_address_id },
          true
        )[0];
        const tempLocation: any = this.FilterObject(this.vm.location, { id: this.vm.item.header.location_id }, true)[0];
        this.vm.printItem.shipToName = tempLocation.name;
        this.vm.printItem.shipToAddress1 = tempLocation.address_1;
        if (tempLocation.address_2 && tempLocation.address_2 != 'null') {
          this.vm.printItem.shipToAddress1 += ', ' + tempLocation.address_2;
        }
        if (tempLocation.city && tempLocation.city != null) {
          this.vm.printItem.shipToAddress2 = tempLocation.city;
        } else {
          this.vm.printItem.shipToAddress2 = '';
        }
        if (tempLocation.state && tempLocation.state != null) {
          this.vm.printItem.shipToAddress2 += ', ' + tempLocation.state;
        }
        if (tempLocation.zip && tempLocation.zip != null) {
          this.vm.printItem.shipToAddress2 += ' ' + tempLocation.zip;
        }
      }
    }

    // set company address
    if (this.vm.item.header.company_id) {
      if (this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]) {
        const tempAddress: any = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0];
        this.vm.printItem.companyLogo = tempAddress.logo;
        this.vm.printItem.companyName = tempAddress.name;
        this.vm.printItem.companyAddress1 = tempAddress.address_1;
        this.vm.printItem.companyAddress2 = tempAddress.city;
        if (tempAddress.state) {
          this.vm.printItem.companyAddress2 += ', ' + tempAddress.state;
        }
        this.vm.printItem.companyAddress2 += '  ' + tempAddress.zip;
        this.vm.printItem.companyPhone = tempAddress.phone;
        this.vm.printItem.companyTaxNumber = tempAddress.tax_number;
      }
    }
    return true;
  }

  clearEntity() {
    this.vm.item.wo_header.entity_id = '';
    this.vm.item.wo_header.entityInput = '';
    this.vm.item.wo_header.address_id = '';
    this.vm.item.wo_header.contact_id = '';

    this.vm.item.wo_header.ship_address_id = '';
    this.vm.item.wo_header.bill_address_id = '';
  }

  changeCompany() {
    // do nothing right now
  }

  emailAssigned() {
    this.vm.emailed = true;
    let errorDescription = '';
    if (!this.vm.item.wo_header.assigned_user_id) {
      errorDescription = $localize`:You must select assign a user @@youmustselectassignauser:You must select assign a user`;
    } else {
      this.vm.email.ToEmail = this.filter.transform(this.vm.user, {
        id: this.vm.item.wo_header.assigned_user_id,
      })[0].email;
      this.vm.ToEmailError = false;
      let testEmails = this.vm.email.ToEmail;
      testEmails = testEmails.replace(/ /g, '');
      const ToEmail = testEmails;
      testEmails = testEmails.split(',');
      const regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (const [i, value] of Object.entries(testEmails)) {
        if (testEmails[i] == '' || !regex.test(testEmails[i])) {
          errorDescription = $localize`:Invalid email @@invalidemail:Invalid email`;
        }
      }
    }
    if (errorDescription != '') {
      this.notification.showWarningToast(errorDescription);
      this.vm.buttonsDisabled = false;
    } else {
      this.vm.buttonsDisabled = true;
      this.vm.email.Subject = $localize`:Work Order @@workOrder:Work Order` + ' ' + this.vm.item.wo_header.number;
      const tempEmailName = this.filter.transform(this.vm.user, { id: this.vm.item.wo_header.assigned_user_id })[0].name;
      this.vm.email.Body = $localize`:Hi @@hi:Hi` + ' ' + tempEmailName + ',<br/><br/>';
      this.vm.email.Body +=
        $localize`:I have assigned Work Order @@ihaveassignedWorkOrder:I have assigned Work Order` +
        ' ' +
        this.vm.item.wo_header.number +
        ' ' +
        $localize`:to you @@toyou:to you` + '.<br/><br/>';
      this.vm.email.Body += '<div>';
      this.vm.email.Body += '<a href="' + window.location.href + '">View Workorder</a><br/><br/>';
      this.vm.email.Body += '</div>';
      this.vm.email.Body += $localize`:Thank you @@thankyou:Thank you` + ',<br/><br/><br/>' + this.localStorage.StorageGet('uName'); // add name on server side
      // console.log('email content: ' + JSON.stringify(this.vm.email));
      this.emailService.sendEmail(this.vm.email);
      // this.save();
    }
  }

  deleteWO() {
    this.vm.buttonsDisabled = true;
    this.tableDataService.deleteTableDataById('WorkOrder', this.vm.item.wo_header.id).subscribe(
      (item: any) => {
        // this.notification.success($localize`:Deleted@@deleted:Deleted`);
        this.vm.buttonsDisabled = false;
        this.router.navigateByUrl(this.vm.mainListPage).then();
      },
      (error: { description: any[] }) => {
        this.vm.buttonsDisabled = false;
        // this.notification.error(
        //   error.description + ' ' + $localize`:Server side error @@serversideerror:Server side error`
        // );
        console.log(error.description);
      }
    );
  }

  // getInvoices() {
  //   // this.tableDataService.getTableData('WorkOrder/?id=' + this.vm.item.wo_header.id + '&getInvoices=true').subscribe((result: any[]) => {
  //   //   this.vm.invoices = result;
  //   this.tableDataService
  //     .getTableData('WorkOrder/' + this.vm.item.wo_header.id + '/Invoices')
  //     .subscribe((result: any[]) => {
  //       this.vm.invoices = result;
  //     });
  // }

  // objectKeys(obj) {
  //   if (obj != null || obj != undefined) {
  //     return Object.keys(obj);
  //   }
  //   return { length: 0 };
  // }

  onClose() {
    this.router.navigate([this.vm.mainListPage]).then();
  }

  deleteItem() {
    if (this.vm.workorderId) {
      const brClass = this;
      const messages: string[] = [$localize`:Delete this record? @@deletethisrecord:Delete this record?`, ''];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.deleteWO();
        },
        () => {
          brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
        }
      );
    }
  }
}
