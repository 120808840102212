import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '../../../../Services/email.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../../../../Services/app-config.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../../Services/email-pass-data-service.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {RoutingTrackerService} from '../../../../Services/routing-tracker.service';

@Component({
  selector: 'app-entity-reconciliation',
  templateUrl: './entity-reconciliation.component.html',
  styleUrls: ['./entity-reconciliation.component.css'],
})
export class EntityReconciliationComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  private rptName: string;

  public vm: any = {
    report: [],
    buttonsDisabled: false,
    showSpecificDates: false,
    request: {
      toDate: '',
      fromDate: '',
      entity_id: null,
      entity_type_id: null,
      company_id: null,
      currency_id: null,
      includeUnposted: false,
    },
    totalOwing: null,
    totalCurrent: null,
    total1to30: null,
    total31to60: null,
    total61to90: null,
    total91plus: null,
    subLedger: null,
    payment: {
      total: null,
    },
    startEntityName: null,
    entity: [],
    entityInput: '',
    entity_email: '',
    email: {
      toemail: null,
      fromemail: this.appConfig.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    currency: [],
    company: [],
    entityName: [],
    entity_type: [],
    i: {
      details: [],
    },
    invoicesTotal: null,
    p: {
      details: [],
    },
    payments: [],
    invoices: [],
    startEntity: 0,
    entityFilter: {
      name: null,
    },
    fromDateSelect: [],
    toDateSelect: [],
    monthCount: [],
    entityError: null,
    paymentsTotal: 0,
    showEmail: true,
  };

  public urlParams: any = [];
  public reportHasRun: boolean = false;
  routedFrom = '';

  constructor(
    private titleService: Title,
    public dateFilter: DateFilterService,
    public tableDataService: TableDataService,
    public notification: BsToastService,
    public localStorage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    public aEmail: EmailService,
    public appConfig: AppConfigService,
    public datePipe: DatePipe,
    private emailData: EmailPassDataServiceService,
    public prevUrl: RoutingTrackerService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  ngOnInit() {
    this.vm.subLedger = this.route.snapshot.paramMap.get('subLedger');
    this.urlParams = this.route.snapshot.queryParams;

    if (this.urlParams.entity_id) {
      this.vm.request.entity_id = this.urlParams.entity_id;
    }

    this.FilterOptionsInit().then();
    // this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.rptName =
      this.vm.subLedger.toUpperCase() +
      ' ' +
      $localize`:Entity Reconciliation @@entityReconciliation:Entity Reconciliation`;
    // console.log('from:' + this.vm.request.fromDate + ' ' + 'to:' + this.vm.request.toDate);
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  ngAfterViewChecked() {
    const div = document.getElementById('excel-export');
    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Entity Reconciliation';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  async FilterOptionsInit() {
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });

    this.tableDataService
      .getTableData('entity_type?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.entity_type = result.Data;
      });

    this.vm.entity = await this.getFilterValues('entity', '?state=active');
    for (const [x, value] of Object.entries(this.vm.entity)) {
      this.vm.entityName.push({ name: this.vm.entity[x].name });
    }

    if (this.urlParams.entity_id != null || this.urlParams.entity_id != undefined) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        // console.log('outside if:' + this.vm.entity[y].id);
        if (this.vm.entity[y]) {
          // console.log('inside if:' + this.vm.entity[y].id);
          if (this.vm.entity[y].id == this.urlParams.entity_id) {
            this.vm.entityInput = this.vm.entity[y].name;
            this.vm.request.entity_id = this.vm.entity[y].id;
            this.vm.request.entity_email = this.vm.entity[y].email;
            // console.log('break:' + this.vm.entityInput);
            break;
          }
        }
      }
    }

    if (this.urlParams.fromDate) {
      // this.vm.urlParamValid = true;
      this.vm.request.fromDate = this.urlParams.fromDate;
      // } else if (this.localStorage.StorageGet('fromDate', '')) {
      //   this.vm.request.fromDate = this.localStorage.StorageGet('fromDate', '');
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth() - 3, 1);
      this.vm.request.fromDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
    }

    if (this.urlParams.toDate) {
      // this.vm.urlParamValid = true;
      this.vm.request.toDate = this.urlParams.toDate;
      // } else if (this.localStorage.StorageGet('toDate', '')) {
      //   this.vm.request.toDate = this.localStorage.StorageGet('toDate', '');
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth(), 1);
      d.setDate(d.getDate() - 1);
      this.vm.request.toDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      this.localStorage.StorageSet('toDate', this.vm.request.toDate);
    }

    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);

    if (this.vm.request.toDate && this.vm.request.fromDate && this.vm.request.entity_id) {
      // if (this.vm.urlParamValid) {
      this.update();
      // }
    }
  }

  async getFilterValues(tablename, param) {
    let values;
    // values = this.localStorage.StorageGet(tablename, '');
    // if (values == '' || !this.IsValidJSONString(values)) {
    await this.tableDataService
      .getTableData(tablename + param + '&pageNumber=0&pageSize=99999')
      .toPromise()
      .then((result: ResponseModel) => {
        const data: any = result.Data;
        values = data;
      });
    this.localStorage.StorageSet(tablename, JSON.stringify(values));
    // } else {
    // values = JSON.parse(values);
    // }
    return values;
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  update() {
    this.vm.entityError = false;
    this.vm.invoices = [];
    if (!this.vm.request.entity_id) {
      this.vm.entityError = true;
      this.notification.showWarningToast($localize`:Entity is required. @@entityisrequired:Entity is required.`);
    } else {
      this.vm.buttonsDisabled = true;
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      const urlParamsJson = {
        fromDate: this.vm.request.fromDate,
        toDate: this.vm.request.toDate,
        entity_id: this.vm.request.entity_id,
        // subLedger: this.vm.subLedger,
        entity_type_id: this.vm.request.entity_type_id,
        company_id: this.vm.request.company_id,
        currency_id: this.vm.request.currency_id,
      };
      // console.log(JSON.stringify(urlParamsJson));
      const urlParams: string = this.encodeQueryData(urlParamsJson);
      // console.log(urlParams);
      this.tableDataService.getTableData('EntityReconciliationPayments', '?' + urlParams).subscribe((result) => {
        this.vm.payments = result;
        this.paymentTotal();
        this.localStorage.StorageSet('toDate', this.vm.request.toDate);
        this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
        const urlParamsJson2 = {
          toDate: this.vm.request.toDate,
          fromDate: this.vm.request.fromDate,
          subLedger: this.vm.subLedger,
          entity_id: this.vm.request.entity_id,
          entity_type_id: this.vm.request.entity_type_id,
          company_id: this.vm.request.company_id,
          currency_id: this.vm.request.currency_id,
        };
        const urlParams2: string = this.encodeQueryData(urlParamsJson2);
        this.tableDataService.getTableData('EntityReconciliationInvoices', '?' + urlParams2).subscribe((result2) => {
          this.vm.invoices = result2;
          this.invoiceTotal();
          this.vm.buttonsDisabled = false;
        });
        this.calcTotals();
        this.reportHasRun = true;
      });
    }
  }

  invoiceTotal() {
    this.vm.invoices.forEach((inv_detail) => {
      this.vm.invoicesTotal = this.vm.invoicesTotal + inv_detail.detail_total;
    });
  }

  paymentTotal() {
    this.vm.payments.forEach((item) => {
      this.vm.paymentsTotal = this.vm.paymentsTotal + item.detail_total;
    });
  }

  calcTotals() {
    this.vm.invoicesTotal = 0;

    // for (const [x, value] of Object.entries(this.vm.invoices)) {
    //   this.vm.invoicesTotal += Number(this.vm.invoices[x].amount);
    // }
    // this.vm.paymentsTotal = 0;
    // for (const [x, value] of Object.entries(this.vm.payments)) {
    //   this.vm.paymentsTotal += Number(this.vm.payments[x].amount);
    // }
    if (this.vm.payments.length > 0 || this.vm.invoices.length > 0) {
      this.vm.report = [{ one: 'record' }];
    } else {
      this.vm.report = [];
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.entity_email = this.vm.entity[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput == '' || this.vm.entityInput == null) {
      this.vm.request.entity_id = '';
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.entityInput }).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].name;
        const entityEmail = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].email;
        if (entityId != '' || entityId != null) {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
          this.vm.entity_email = entityEmail;
        } else {
          this.vm.request.entity_id = '';
          this.vm.entity_email = '';
        }
      } else {
        this.vm.request.entity_id = '';
        this.vm.entity_email = '';
      }
    }
    if (entityIdStart != this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }

  updateEntity() {
    this.vm.buttonsDisabled = true;
    this.localStorage.StorageSet('entity', '');
    this.tableDataService
      .getTableData('entity', '?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.entity = result.Data;
        this.vm.buttonsDisabled = false;
      });
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject =
      this.vm.subLedger.toUpperCase() +
      ' ' +
      $localize`:Entity Reconciliation Report @@entityReconciliationReport:Entity Reconciliation Report` +
      ' ' +
      this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  close() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom == undefined) {
      window.close();
    } else {
      this.router.navigateByUrl('/home').then();
    }
  }

  clear() {
    this.vm.request = {
      toDate: null,
      fromDate: null,
      includeUnposted: false,
      entity_id: null,
      entity_type_id: null,
      company_id: null,
      currency_id: null,
    };
    this.vm.report = [];
    this.vm.entityInput = '';
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }
}
