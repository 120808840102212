import {Injectable, OnInit} from '@angular/core';
import {PermissionsModel} from '../Models/PermissionsModel';
import {StorageService} from './storage.service';
import {SharedDataService} from './sharedData.service';

declare var angular: any;

@Injectable({
  providedIn: 'root'
})
export class PermissionsService   {

  private mapPermissions = new Map();

  constructor(private storage: StorageService,
              private sharedData: SharedDataService) {
  }

  LoadPermissions(aPermsJsonObj: any, sender = '') {
   // console.log('map perms: ' + sender + ': ' + JSON.stringify(aPermsJsonObj));
   // this.mapPermissions.clear();
   //  if (this.mapPermissions.size > 0) {
   //    return;
   //  }
    const f1 = JSON.stringify(aPermsJsonObj);
    const x1 = JSON.parse(f1);
    // const x1 = JSON.parse(JSON.stringify(aPermsJsonString)));
    for (const key in x1) {
      if (x1.hasOwnProperty(key)) {
        this.mapPermissions.set(key, x1[key]);
      }
    }
  }

  Get(aName: string): boolean {
    return this.mapPermissions.get(aName);
  }

  ListPermissions() {
    console.log('---Permissions---');
    this.mapPermissions.forEach((value: string, key: string) => {
      // console.log(key + ' => ' + value);
    });
  }

  ClearPermissions() {
    this.mapPermissions.clear();
  }
}
