import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-order-select',
  templateUrl: './order-select.component.html',
  styleUrls: ['./order-select.component.css']
})
export class OrderSelectComponent implements OnInit {
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

}
