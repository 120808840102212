import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BankModel } from '../../../../DataModels/bank-model';
import { CompanyModel } from '../../../../DataModels/company-model';
import { Observable } from 'rxjs';
import { AccountModel } from '../../../../DataModels/account-model';
import { CurrencyModel } from '../../../../DataModels/currency-model';
import { CheckStockModel } from '../../../../DataModels/check-stock-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-banks-form',
  templateUrl: './bank-form.component.html',
  styleUrls: ['./bank-form.component.css'],
})
export class BankFormComponent implements OnInit, AfterViewInit {
  bankID: string;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  bank: BankModel;
  bankForm: UntypedFormGroup;

  companies$: Observable<CompanyModel[]>;
  accounts$: Observable<AccountModel[]>;
  checkStocks$: Observable<CheckStockModel[]>;
  currencies$: Observable<CurrencyModel[]>;

  companyListLength = 1;
  accountListLength = 1;
  currencyListLength = 1;

  itemId = false;
  submitted = false;
  buttonsDisabled = false;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.bankID = this.route.snapshot.params.id;
  }

  createForm() {
    this.bankForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      check_name: new UntypedFormControl(''),
      address_1: new UntypedFormControl(''),
      address_2: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      zip: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      account_number: new UntypedFormControl(''),
      transit_number: new UntypedFormControl(''),
      institution_number: new UntypedFormControl(''),
      routing_number: new UntypedFormControl(''),
      account_id: new UntypedFormControl(''),
      company_id: new UntypedFormControl(''),
      currency_id: new UntypedFormControl(''),
      entity_id: new UntypedFormControl(''),
      stock_id: new UntypedFormControl(''),
      next_check_number: new UntypedFormControl(''),
      audit_sequence: new UntypedFormControl(0),
      active: new UntypedFormControl('true'),
    });
  }

  ngOnInit() {
    if (this.bankID) {
      this.loadBank();
      if (this.bankID) {
        this.buttonConfig.reset = false;
      }
    }

    this.loadLookups();
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Bank @@bank:Bank`);
  }

  async isThisUsed(
    idToCheck: string,
    columnToCheck: string,
    tableToCheck: string
  ) {
    this.buttonsDisabled = false;

    let paramString = '';
    paramString += 'id=' + idToCheck;
    paramString += '&';
    paramString += 'columnName=' + columnToCheck;
    paramString += '&';
    paramString += 'tableName=' + tableToCheck;

    this.table.getTableDataWithParams('in_use', paramString).subscribe(
      (result) => {
        this.buttonsDisabled = result.toString() === 'true';
        // console.log('button:' + this.buttonsDisabled);
        // console.log('res:' + result);
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error validating usage of item @@errorValidatingUsageOfItem:Error validating usage of item.`);
        console.log(error);
      },
      () => {
        // ending stuff here
      }
    );
  }

  private loadBank() {
    this.table.getTableData('bank', this.bankID).subscribe(
      (result) => {
        this.bank = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error Loading Bank @@errorLoadingBank:Error Loading Bank`);
        console.log(error);
      },
      () => {
        this.bankForm.patchValue(this.bank);
      }
    );
  }

  loadLookups() {
    this.table
      .getTableData('company', '?pageNumber=0&pageSize=999999')
      .subscribe((res) => {
        this.companies$ = res.Data;
      });

    this.table
      .getTableData('account', '?pageNumber=0&pageSize=999999')
      .subscribe((res) => {
        this.accounts$ = res.Data;
      });

    this.table
      .getTableData('currency', '?pageNumber=0&pageSize=999999')
      .subscribe((res) => {
        this.currencies$ = res.Data;
      });

    this.table
      .getTableData('check_stock', '?pageNumber=0&pageSize=999999')
      .subscribe((res) => {
        this.checkStocks$ = res.Data;
      });
  }

  get f() {
    return this.bankForm.controls;
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.bankForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete bank? @@areYouSureYouWantToDeleteBank:Are you sure you want to delete bank?`,
      this.bank.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('bank', this.bankID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast(
          $localize`:Delete Canceled @@deleteCanceled:Delete Canceled`
        );
      }
    );
  }

  resetForm() {
    this.bankForm.reset();
  }

  onClose() {
    this.redirectTo('admin/bank-list');
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/home', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.bankForm.invalid) {
      this.notification.showErrorToast(
        $localize`:Form Errors! @@formErrors:Form Errors!`
      );
      return;
    }
    this.bank = Object.assign({}, this.bankForm.value);
    if (this.bankID) {
      if (this.bankForm.invalid === false) {
        this.table.patch('bank', this.bankID, this.bank).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('bank', this.bank).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.bankID = apiReturn.id;
        this.submitted = false;
        if (saveClose) {
          this.onClose();
        }
        // return true;
      });
      // this.notification.success($localize`:Saved @@saved:Saved`);
    }
    this.submitted = false;
    return true;
  }
}
