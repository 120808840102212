import {Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-modal-select-entity',
  templateUrl: './modal-select-entity.component.html',
  styleUrls: ['./modal-select-entity.component.css']
})
export class ModalSelectEntityComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  @Output() selectEntityModalEmit = new EventEmitter<string>();
  @Output() updateEntityEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.vm.startEntity = 0;
    this.vm.entityFilter.name = '';
    // console.log('vm from modal: ' + JSON.stringify(this.vm));

  }

  updateEntity() {
    this.updateEntityEmit.emit();
  }

  selectEntityModal(entity) {
    this.selectEntityModalEmit.emit(entity);
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}
