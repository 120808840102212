import {Component, OnInit} from '@angular/core';
import {PermissionsService} from '../../Services/permissions.service';
import {TableDataService} from '../../Services/tableData.service';
import {StorageService} from '../../Services/storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
// Permissions
  admin = true;
  vm: any = {};

  showSiteMap = true;
  showNavigation;
  showDashboard;
  showManage;
  window;
  showSchedule: boolean;
  showOnboard: boolean;

  constructor(private permissions: PermissionsService,
              private localStorage: StorageService,
              private tableDataService: TableDataService,
              private titleService: Title) {
    this.vm.request = {};
  }


  ngOnInit() {
    const x = this.localStorage.StorageGet('Permissions');
    this.permissions.LoadPermissions(JSON.parse(x), 'home ts');

    const tabTitle =  $localize`:accountXHome: AccountX Home `;
    this.titleService.setTitle(tabTitle);

    this.dashClick();

  }


  navClick() {
    this.AllFalse();
    this.showNavigation = true;
  }

  dashClick() {
    this.AllFalse();
    this.showDashboard = true;
  }

  manageClick() {
    this.AllFalse();
    this.showManage = true;
  }

  AllFalse() {
    this.showNavigation = false;
    this.showDashboard = false;
    this.showManage = false;
    this.showSchedule = false;
    this.showOnboard = false;
  }

  scheduleClick() {
    this.AllFalse();
    this.showSchedule = true;
  }

  onboardClick() {
    this.AllFalse();
    this.showOnboard = true;
  }
}
