import {AfterViewInit, Component, OnInit} from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyAccessModel } from '../../../../DataModels/company-access-model';
import { NotificationService } from '../../../../Services/notification.service';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-company-access-form',
  templateUrl: './company-access-form.component.html',
  styleUrls: ['./company-access-form.component.css'],
})
export class CompanyAccessFormComponent implements OnInit, AfterViewInit {
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  companyAccess: CompanyAccessModel;
  companyAccessForm: UntypedFormGroup;
  companyAccessID;

  companyList: Array<object>;
  userList: Array<object>;

  buttonsDisabled = false;
  batchlocked = 0;
  paid = false;
  showDelete = false;
  submitted = false;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.companyAccessID = this.route.snapshot.params.id;
  }

  createForm() {
    this.companyAccessForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      user_id: new UntypedFormControl(''),
      company_id: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    this.loadCompanies();
    this.LoadUsers();
    if (this.companyAccessID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  ngAfterViewInit() {
    // this.titleService.setTitle($localize`:Company Access @@companyAccess:Company Access`);
  }

  get f() {
    return this.companyAccessForm.controls;
  }

  private LoadData() {
    this.table.getTableData('company_access', this.companyAccessID).subscribe(
      (result) => {
        this.companyAccess = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.showErrorToast($localize`:Error loading Companies @@errorLoadingCompanies:Error loading Companies`);
        console.log(error);
      },
      () => {
        this.companyAccessForm.patchValue(this.companyAccess);
        this.titleService.setTitle($localize`:Company Access @@companyAccess:Company Access`);
      }
    );
  }
  private loadCompanies() {
    this.table
      .getApiListData('company', '?pageNumber=0&pageSize=999999')
      .subscribe(
        (result: any) => {
          this.companyList = result.Data;
          // this.companyListLength = this.companyList.length;
        },
        (error: HttpErrorResponse) => {
          // this.notification.showErrorToast($localize`:Error loading Companies @@errorLoadingCompanies:Error loading Companies`);
          console.log(error);
        },
        () => {}
      );
  }
  private LoadUsers() {
    this.table
      .getApiListData('sys_user', '?pageNumber=0&pageSize=999999')
      .subscribe(
        (result: any) => {
          this.userList = result.Data;
          // this.companyListLength = this.companyList.length;
        },
        (error: HttpErrorResponse) => {
          // this.notification.showErrorToast($localize`:Error loading users @@errorLoadingUsers:Error loading users`);
          console.log(error);
        },
        () => {}
      );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.resetForm();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete company access? @@areYouSureYouWantToDeleteCompanyAccess:Are you sure you want to delete company access?`,
      this.companyAccessForm.get('user_id').value,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table
          .deleteTableDataById('company_access', this.companyAccessID)
          .subscribe(
            (result) => {
              retMsg = result;
              // this.notification.success($localize`:Deleted @@deleted:Deleted`);
            },
            (error: HttpErrorResponse) => {
              // this.notification.showErrorToast($localize`:Error @@error:Error`);
              console.log(error);
            },
            () => {
              this.onClose();
            }
          );
      },
      () => {
        this.notification.showSuccessToast(
          $localize`:Delete Canceled @@deleteCanceled:Delete Canceled`
        );
      }
    );
  }

  resetForm() {
    this.companyAccessForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/company-access-list']);
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.companyAccessForm.invalid) {
      this.notification.showErrorToast(
        $localize`:Form Errors! @@formErrors:Form Errors!`
      );
      return;
    }
    this.companyAccess = Object.assign({}, this.companyAccessForm.value);
    if (this.companyAccessID) {
      // TODO probably can move invalid out side if and capture both
      if (this.companyAccessForm.invalid === false) {
        this.table
          .patch('company_access', this.companyAccessID, this.companyAccess)
          .then(
            (result) => {
              if (saveClose) {
                this.onClose();
              }
            },
            (error: HttpErrorResponse) => {
              //
              console.log(error);
            }
          );
        // return true;
      }
    } else {
      this.table
        .postWithPromise('company_access', this.companyAccess)
        .then((res) => {
          // stops double posting when clicking Save
          let apiReturn: any = null;
          if (typeof res === 'object') {
            apiReturn = JSON.parse(JSON.stringify(res));
          }
          this.companyAccessID = apiReturn.id;
          if (saveClose) {
            this.onClose();
          }
        });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
