import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-modal-entity-history',
  templateUrl: './modal-entity-history.component.html',
  styleUrls: ['./modal-entity-history.component.css']
})
export class ModalEntityHistoryComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() vm: any;
  searchReceivablePayments;
  searchReceivableInvoices;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
