import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal-uninvoiced-quotes',
  templateUrl: './modal-uninvoiced-quotes.component.html',
  styleUrls: ['./modal-uninvoiced-quotes.component.css']
})
export class ModalUninvoicedQuotesComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

  generateQuoteInvoice(id) {
    // this.router.navigate(['transactions/invoice/form?',  {invoiceId: 'newAR', quoteId: id}]);
    this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newAR&quoteId=' + id);
    //  this.router.navigateByUrl('/inventory/count/form?countId=' + item.id);
    //   this.router.navigate(['admin/account-subtotal-form', { id: aID }]);
    // $('#uninvoicedQuotes').modal('hide');
    // $state.go('app.admin.transactions.invoice.form', { invoiceId: 'newAR', quoteId: id });

  }

}

