import {Component, Input, OnInit} from '@angular/core';
import {ActivityFeedModel} from '../../../DataModels/activity-feed-model';
import {TableDataService} from '../../../Services/tableData.service';
import {Observable} from 'rxjs';
import {DateFilterService} from '../../../Services/dateFilter.service';
import {AppConfigService} from '../../../Services/app-config.service';
import {RecentActivity} from '../widget/recent-activity/recent-activity.model';
import {CardDropdownOption} from '../widget/card-title/card-title.model';
import { BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.css']
})
export class ActivityFeedComponent implements OnInit {
  // @Input() recentActivities: RecentActivity[] = [];
  @Input() height = 0;
  @Input() activityEntityID: string;
  vm: any = {
    toDate: Date,
    fromDate: Date
  };

  activity: Observable<ActivityFeedModel[]>;
  newMessage;
  lastActivityDate: any;
  dropdownOptions: CardDropdownOption[] = [{ label: 'Settings' }, { label: 'Action' }];
  recentActivities: RecentActivity[] = [];
  recentActivitiesSave: RecentActivity[] = [];
  searchText: string;

  constructor(private table: TableDataService,
              private dateFilter: DateFilterService,
              private appConfigService: AppConfigService,
              private notificationService: BsToastService) {

  }

  ngOnInit() {
    this.vm.DataFormat = 'MM-DD-YYYY';
    this.vm.addNewFeed = false;
    this.vm.searchFeed = false;

    // set default dates for startup
    let from: any = new Date();
    let to: any = new Date(Date.now());

    // from = to - days in config
    from.setDate(to.getDate() - this.appConfigService.activityFeedDays);


    // format date fields for picker
    to = this.dateFilter.GetDateFormat(to);
    from = this.dateFilter.GetDateFormat(from);

    // now set vars for template use
    this.vm.toDate = to;
    this.vm.fromDate = from;

    this.vm.feedFilter = '';
    this.vm.ActivityFeed = {};
    this.vm.ActivityFeed.location = [];
    this.vm.ActivityFeed.company = [];
    // this.entityId = '';
    this.loadActivity();
  }

  refreshData() {
    this.notificationService.showSuccessToast('Feed Updated');
    this.loadActivity();
  }
  loadActivity() {

    this.vm.ActivityFeed = null;

    // so sql likes date format - datetime field in sql so set midnight by pushing forward a day on to
    const tempDate = new Date(Date.now());
    tempDate.setDate( tempDate.getDate() + 1 );

    const sqlToDate = this.dateFilter.GetDateFormat(tempDate, 'yyyy-MM-dd');
    const sqlFromDate = this.dateFilter.GetDateFormat(this.vm.fromDate, 'yyyy-MM-dd');

    const urlParams = {
      fromDate: sqlFromDate,
      toDate: sqlToDate,
      search: this.vm.feedFilter,
      entity_id: this.activityEntityID
    };

    // console.log('params: ' + JSON.stringify(urlParams));

    const params: string = this.encodeQueryData(urlParams);

    this.table.getTableData('activityfeed/GetActivityFeedByDate', '?' + params).subscribe((result: any[]) => {
      this.vm.ActivityFeed = [];
      this.recentActivities = [];
      this.vm.ActivityFeed = result;
      // console.log('af');
      // console.log(result);
      this.assignIcons(this.vm.ActivityFeed);
    });
  }

  assignIcons(data: any) {
    data.forEach((currentValue, index) => {

      let icon = '';
      const checkVal = currentValue.batch_type_id;
      // console.log(checkVal);
      switch (checkVal) {
        // journal entry
        case 1 : {
          icon = 'mdi mdi-receipt text-success';
          break;
        }
        // sales invoice
        case 2: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // not sure
        case 3: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // purchase invoice
        case 4: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // sales refund
        case 5: {
          icon = 'mdi mdi-cash-refund text-success';
          break;
        }
        // budget
        case 6: {
          icon = 'mdi mdi-airplane text-success';
          break;
        }
        // ap payment
        case 7: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // quote
        case 8: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // sales order
        case 9: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // purchase order
        case 10: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // work order
        case 18: {
          icon = 'mdi mdi-currency-usd text-success';
          break;
        }
        // email
        case 97: {
          icon = 'mdi mdi-cash-register text-success';
          break;
        }
        // other and POS
        case 99: {
          icon = 'mdi mdi-cash-register text-success';
          break;
        }
        default: {
          icon = 'mdi mdi-crosshairs-question text-danger';
          break;
        }
      }
      this.recentActivities.push({
        id: currentValue.id,
        title: currentValue.user_name,
        timelineIcon: icon,
        variant: 'primary',
        text: currentValue.message,
        mainText: currentValue.batch_name,
        time: currentValue.ActivityDate,
        company_name: currentValue.company_name,
        batch_type_id: currentValue.batch_type_id,
        location_name: currentValue.location_name,
        entity_name: currentValue.entity_name,
        detail_total: currentValue.detail_total,
        header_id: currentValue.header_id,
        message: currentValue.message,
        number: currentValue.number,
        user_name: currentValue.user_name,
        batch_name: currentValue.batch_name
      });
    });
    this.recentActivitiesSave = this.recentActivities;
    // console.log(this.recentActivitiesSave);
  }

  filterActivity() {
    if (this.searchText == '') {
      this.recentActivities = this.recentActivitiesSave;
    } else {
      const searchTerm = this.searchText.toLowerCase();
      this.recentActivities = this.recentActivities.filter(activity => {
        // return activity.location_name.toLowerCase().includes(searchTerm ) ||
        if (searchTerm !== '') {
          return activity.location_name?.toLowerCase().includes(searchTerm) ||
            activity.message?.toLowerCase().includes(searchTerm) ||
            activity.mainText?.toLowerCase().includes(searchTerm) ||
            activity.number?.toLowerCase().includes(searchTerm) ||
            activity.entity_name?.toLowerCase().includes(searchTerm) ||
            activity.company_name?.toLowerCase().includes(searchTerm) ||
            activity.user_name?.toLowerCase().includes(searchTerm) ||
            // TODO activity.detail_total?.toLowerCase().includes(searchTerm) ||
            activity.text?.toLowerCase().includes(searchTerm);
        }

        });
      // this.employeeList = this.filteredEmployees;
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      } else if (data[d] != null && data[d] == '') {
        ret.push(encodeURIComponent(d) + '=');
      }
    }
    return ret.join('&');
  }
}
