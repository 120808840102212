import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-report-table-header',
  templateUrl: './report-table-header.component.html',
  styleUrls: ['./report-table-header.component.css']
})
export class ReportTableHeaderComponent implements OnInit {
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

}
