import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import {SharedDataService} from '../../../Services/sharedData.service';
import {ResponseModel} from '../../../DataModels/response-model';

@Component({
  selector: 'app-home-manage-control-dates',
  templateUrl: './home-manage-control-dates.component.html',
  styleUrls: ['./home-manage-control-dates.component.css']
})
export class HomeManageControlDatesComponent implements OnInit {

  vm: any = {};
  showControlDateHelp;

  constructor(
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private sharedData: SharedDataService) { }

  ngOnInit() {
    // if (this.localStorage.StorageGet('company')) {
    //   this.vm.company = JSON.parse(this.localStorage.StorageGet('company'));
    // }
    if (this.sharedData.getCompany()) {
      this.vm.company = JSON.parse(this.localStorage.StorageGet('company'));
    } else {
      this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=99999').subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
        // this.localStorage.StorageSet('company', JSON.stringify(result));
        this.sharedData.addCompany(JSON.stringify(result));
      });
    }

    // if (this.localStorage.StorageGet('location')) {
    //   this.vm.location = JSON.parse(this.localStorage.StorageGet('location'));
    // }
    if (this.sharedData.getLocation()) {
      this.vm.location = JSON.parse(this.sharedData.getLocation());
    } else {
      this.tableDataService.getTableData('location?state=active&pageNumber=0&pageSize=99999').subscribe((locationResult: ResponseModel) => {
        this.vm.location = locationResult.Data;
        // this.localStorage.StorageSet('location', JSON.stringify(locationResult));
        this.sharedData.addLocation(JSON.stringify(locationResult));
      });
    }
  }
}
