import {PrintBatchComponent} from './print-batch/print-batch.component';
import {ModalUninvoicedShipmentsComponent} from './resources/modal-uninvoiced-shipments/modal-uninvoiced-shipments.component';
import {ModalUninvoicedWorkordersComponent} from './resources/modal-uninvoiced-workorders/modal-uninvoiced-workorders.component';
import {ModalUninvoicedQuotesComponent} from './resources/modal-uninvoiced-quotes/modal-uninvoiced-quotes.component';
import {PaymentFormComponent} from './payment-form/payment-form.component';
import {PaymentPrintComponent} from './payment-print/payment-print.component';
import {ModalSelectItemComponent} from './resources/modal-select-item/modal-select-item.component';
import {ModalSelectAccountComponent} from './resources/modal-select-account/modal-select-account.component';
import {ModalSelectSalespersonComponent} from './resources/modal-select-salesperson/modal-select-salesperson.component';
import {ModalNewAccountComponent} from './resources/modal-new-account/modal-new-account.component';
import {ModalNewItemComponent} from './resources/modal-new-item/modal-new-item.component';
import {ModalNewAddressComponent} from './resources/modal-new-address/modal-new-address.component';
import {ModalNewContactComponent} from './resources/modal-new-contact/modal-new-contact.component';
import {ModalSelectEntityComponent} from './resources/modal-select-entity/modal-select-entity.component';
import {ModalNewEntityComponent} from './resources/modal-new-entity/modal-new-entity.component';
import {ModalEntityHistoryComponent} from './resources/modal-entity-history/modal-entity-history.component';
import {ModalSignatureComponent} from './resources/modal-signature/modal-signature.component';
import {InvoiceEmailComponent} from './invoice-email/invoice-email.component';
import {InvoicePrintComponent} from './invoice-print/invoice-print.component';
import {InvoiceRecurringListComponent} from './invoice-recurring-list/invoice-recurring-list.component';
import {AlertComponent} from './resources/alert/alert.component';
import {PaymentEmailComponent} from './payment-email/payment-email.component';
import {InvoiceFormComponent} from './invoice-form/invoice-form.component';
import {CommonModule, DatePipe, DecimalPipe, CurrencyPipe} from '@angular/common';
import {SharedModule} from '../common/shared.module';
import {NgModule} from '@angular/core';
import {ArraySortPipe} from './orderBy';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {ModalInsertItemComponent} from './resources/modal-insert-item/modal-insert-item.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PaymentListComponent} from './payment-list/payment-list.component';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {BankReconciliationListComponent} from './bank-reconciliation/bank-reconciliation-list/bank-reconciliation-list.component';
import {StatisticalRecurringListComponent} from './statistical-recurring-list/statistical-recurring-list.component';
import {BankReconciliationFormComponent} from './bank-reconciliation/bank-reconciliation-form/bank-reconciliation-form.component';
import {EntryFormComponent} from './entry-form/entry-form.component';
import {EntryListComponent} from './entry-list/entry-list.component';
import {EntryRecurringComponent} from './entry-recurring/entry-recurring.component';
import {NoCommaPipe} from './noComma';
import {StatisticalListComponent} from './statistical-list/statistical-list.component';
import {StatisticalFormComponent} from './statistical-form/statistical-form.component';
import {DecimalNewPipe} from '../common/DecimalNewPipe';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {AdminModule} from '../admin/admin.module';
import {ModalItemsToOrderComponent} from './resources/modal-items-to-order/modal-items-to-order.component';
import {ShipmentListComponent} from './shipment-list/shipment-list.component';
import {ShipmentPrintComponent} from './shipment-print/shipment-print.component';
import { ShipmentFormComponent } from './shipment-form/shipment-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DataTablesModule} from 'angular-datatables';
import {NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { PrintTransactionDocument } from './print-transaction-document/print-transaction-document';


@NgModule({
  declarations: [
    ArraySortPipe,
    PrintBatchComponent,
    InvoiceFormComponent,
    ModalUninvoicedShipmentsComponent,
    ModalUninvoicedWorkordersComponent,
    ModalUninvoicedQuotesComponent,
    PaymentFormComponent,
    PaymentPrintComponent,
    PaymentEmailComponent,
    AlertComponent,
    ModalSelectItemComponent,
    ModalSelectAccountComponent,
    ModalSelectSalespersonComponent,
    ModalNewAccountComponent,
    ModalNewItemComponent,
    ModalNewAddressComponent,
    ModalNewContactComponent,
    ModalSelectEntityComponent,
    ModalNewEntityComponent,
    ModalEntityHistoryComponent,
    ModalSignatureComponent,
    InvoiceEmailComponent,
    InvoicePrintComponent,
    InvoiceRecurringListComponent,
    ModalInsertItemComponent,
    PaymentListComponent,
    InvoiceListComponent,
    BankReconciliationListComponent,
    StatisticalRecurringListComponent,
    BankReconciliationFormComponent,
    EntryFormComponent,
    EntryListComponent,
    EntryRecurringComponent,
    NoCommaPipe,
    StatisticalListComponent,
    StatisticalFormComponent,
    ModalItemsToOrderComponent,
    ShipmentListComponent,
    ShipmentPrintComponent,
    ShipmentFormComponent,
    PrintTransactionDocument,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FilterPipeModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule,
    AdminModule,
    ReactiveFormsModule,
    TooltipModule,
    DataTablesModule,
    NgbPaginationModule,
    NgbDropdownModule,
  ],
  exports: [
    TypeaheadModule,
    BsDatepickerModule,
    ModalSelectEntityComponent,
    ModalInsertItemComponent,
    ModalSelectItemComponent,
    ModalSignatureComponent,
    ModalEntityHistoryComponent,
    ModalSelectAccountComponent,
    NoCommaPipe,
    ModalItemsToOrderComponent,
    InvoicePrintComponent,
  ],
  providers: [DatePipe, DecimalPipe, DecimalNewPipe, CurrencyPipe],
})
export class TransactionsModule {}
