import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-work-orders-print',
  templateUrl: './work-orders-print.component.html',
  styleUrls: ['./work-orders-print.component.css']
})
export class WorkOrdersPrintComponent implements OnInit {
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

  // objectKeys(obj) {
  //   if (obj != null) {
  //     return Object.keys(obj);
  //   }
  //   return {length: 0};
  // }
}
