import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {CurrencyModel} from '../../../../DataModels/currency-model';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {Observable} from 'rxjs';
import {AccountModel} from '../../../../DataModels/account-model';
import { BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-currency-form',
  templateUrl: './currency-form.component.html',
  styleUrls: ['./currency-form.component.css']
})
export class CurrencyFormComponent implements OnInit, AfterViewInit {
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };
  accounts$: Observable<AccountModel[]>;
  accounts = [];

  currencyID: string;

  currency: CurrencyModel;
  currencyForm: UntypedFormGroup;

  buttonsDisabled = false;

  submitted = false;

  constructor(private table: TableDataService,
              private notification: BsToastService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDialogService: ConfirmDialogService,
              private titleService: Title) {
    this.createForm();
    this.currencyID = this.route.snapshot.params.id;
  }

  get f() {
    return this.currencyForm.controls;
  }

  createForm() {
    this.currencyForm = this.formBuilder.group({
      code: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      account_id: new UntypedFormControl(''),
      active: new UntypedFormControl('true'),
    });
  }
  ngOnInit() {
    if (this.currencyID) {
      this.LoadData();
      this.loadAccount();
      this.buttonConfig.reset = false;
    } else {
      this.loadAccount();
    }
  }

  ngAfterViewInit() {
    // this.titleService.setTitle($localize`:Currency @@currency:Currency` + ' - ' + this.currencyForm.controls.name.value );
  }

  private LoadData() {
    this.table.getTableData('currency', this.currencyID).subscribe(
      result => {
        this.currency = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Currencies @@errorLoadingCurrencies:Error loading Currencies`);
        console.log(error);
      },
      () => {
        this.currencyForm.patchValue(this.currency);
        this.titleService.setTitle($localize`:Currency @@currency:Currency` + ' - ' + this.currencyForm.controls.code.value );
      });
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.currencyForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete currency? @@areYouSureWouWantToDeleteCurrency:Are you sure you want to delete currency?`, this.currency.name];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('currency', this.currencyID).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          });
      }, () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }

  resetForm() {
    this.currencyForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/currency-list']).then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.currencyForm.invalid) {
      this.notification.showWarningToast('Form Errors');
      return;
    }
    this.currency = Object.assign({}, this.currencyForm.value);
    if (this.currencyID) {
        this.table.patch('currency', this.currencyID, this.currency).then(result => {
            if (saveClose){
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          });
        // return true;
    } else {
      this.table.postWithPromise('currency', this.currency).then(res => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.currencyID = apiReturn.id;
        if (saveClose){
          this.onClose();
        }
      });

      //  this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }

  loadAccount() {
    this.table.getTableData('account', '?pageNumber=0&pageSize=999999').subscribe((res: ResponseModel) => {
      const data: any = res.Data;
      this.accounts = data;
      // console.log('accts: ' + JSON.stringify(data));
    });
  }

}
