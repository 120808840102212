import { Component } from '@angular/core';
// TODO can put layout apecific stuff here if needed - branding sort of stuff fro print docs.
@Component({
  selector: 'print-layout',
  template: `<div class="wrapper">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>`,
  styles: ['@media screen {:host {display: none;}}']
})
export class PrintLayoutComponent {

}
