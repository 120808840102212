import {AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DateFilterService} from '../../../../Services/dateFilter.service';
import {TableDataService} from '../../../../Services/tableData.service';
// import {NotificationService} from '../../../../Services/notification.service';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {StorageService} from '../../../../Services/storage.service';
import {EmailService} from '../../../../Services/email.service';
import {DataExportService} from '../../../../Services/dataExport.service';
import {AppConfigService} from '../../../../Services/app-config.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {EmailPassDataServiceService} from '../../../../Services/email-pass-data-service.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.css']
})
export class TaxComponent implements OnInit, AfterViewInit, AfterViewChecked {
  private rptName: string;
  @ViewChild('EmailContent', {static: false}) EmailContent: ElementRef;
  @ViewChild('ExcelExport', {static: false}) ExcelExport: ElementRef;

  public vm: any = {
    report: [],
    request: {
      includeUnposted: null,
      fromDate: null,
      toDate: null,
      tax_id: null,
      companyId: null,
      company_id: null,
    },
    buttonsDisabled: false,
    showFilters: false,
    company: [],
    currency: [],
    companyName: null,
    taxName: null,
    company_id: null,
    currency_id: null,
    netIncome: null,
    tax: [],
    fromDateSelect: null,
    toDateSelect: null,
    monthCount: null,
    dateError: null,
    taxError: null,
    balanceOwing: null,
    showEmail: true,
    email: {
      toemail: null,
      fromemail: this.appConfig.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    subLedger: null,
    exportToExcel: true,
  };

  constructor(private titleService: Title,
              public dateFilter: DateFilterService,
              public tableDataService: TableDataService,
              public notification: BsToastService,
              public localStorage: StorageService,
              public aEmail: EmailService,
              public excelExporter: DataExportService,
              public appConfig: AppConfigService,
              private router: Router,
              private emailData: EmailPassDataServiceService) {
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.vm.report = [];
    this.vm.request = {
      includeUnposted: null,
      fromDate: null,
      toDate: null,
      tax_id: null,
      companyId: null,
      company_id: null,
    };
  }

  ngOnInit() {
    this.FilterOptionsInit();
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.rptName = 'Tax ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
  }

  ngAfterViewInit() {
    this.titleService.setTitle('Taxes');
  }

  ngAfterViewChecked() {
    if (this.vm.report.length > 0) {
      const div = document.getElementById('excel-export');
       // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Tax Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);

    }
  }

  FilterOptionsInit() {
    this.tableDataService.getTableData('tax?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.tax = result.Data;
    });
    this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
    this.tableDataService.getTableData('currency?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.currency = result.Data;
    });

    if (this.localStorage.StorageGet('fromDate', '')) {
      this.vm.request.fromDate = this.localStorage.StorageGet('fromDate', '');
    }

    if (this.localStorage.StorageGet('toDate', '')) {
      this.vm.request.toDate = this.localStorage.StorageGet('toDate', '');
    }
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  update() {
    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    let errorDescription = '';
    if (tempFromDate > tempToDate) {
      this.vm.dateError = true;
      errorDescription += 'The To Date must be greater than the From Date. <br/>';
    }
    if (!this.vm.request.fromDate || !this.vm.request.toDate) {
      this.vm.dateError = true;
      errorDescription += 'You must select from and to dates.';
    }
    if (!this.vm.request.tax_id) {
      this.vm.taxError = true;
      errorDescription += 'Tax is required.';
    }

    if (errorDescription) {
      this.notification.showWarningToast(errorDescription);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      const urlParamsJson = {
        fromDate: this.vm.request.fromDate,
        toDate: this.vm.request.toDate,
        tax_id: this.vm.request.tax_id,
        company_id: this.vm.request.company_id,
      };

      const urlParams: string = this.encodeQueryData(urlParamsJson);
      this.tableDataService.getTableData('TaxReport', '?' + urlParams).subscribe((result) => {
        this.vm.report = result;
        this.calcTotals();
        this.vm.buttonsDisabled = false;
        this.localStorage.StorageSet('toDate', this.vm.request.toDate);
        this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
      });
    }
  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, 'Tax ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate + '.xlsx');
  }

  calcTotals() {
    this.vm.netIncome = this.vm.balanceOwing = 0;
    for (const [x, value] of Object.entries(this.vm.report)) {
      if ((this.vm.report[x].type_id == '4') || (this.vm.report[x].type_id == '5') || (this.vm.report[x].type_id == '6')) {
        this.vm.netIncome += Number(0 - this.vm.report[x].debit_credit);
      } else {
        this.vm.balanceOwing += Number(0 - this.vm.report[x].debit_credit);
      }
    }
    this.vm.taxName = '';
    for (const [x, value] of Object.entries(this.vm.tax)) {
      if (this.vm.tax[x].id == this.vm.request.tax_id) {
        this.vm.taxName = this.vm.tax[x].code + ' ' + this.vm.tax[x].name;
      }
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = 'Tax ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }
}
