import {Inject, Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateFilterService {
  public monthCount: number;
  public toDateSelect: any[];
  public fromDateSelect: any[];
  private fromDate: string;
  private toDate: string;

  constructor(public datePipe: DatePipe) {
  }

  DateFilterInit(toDate: string, fromDate: string): DFData {
    // spin the dates for sql no matter how they look when they get here
    const preSpinToDate = new Date(toDate);
    const preSpinFromDate = new Date(fromDate);

    this.fromDate = new DatePipe('en-US').transform(preSpinFromDate, 'MM/dd/yyyy');
    this.toDate = new DatePipe('en-US').transform(preSpinToDate, 'MM/dd/yyyy');

    // was existing from here below
    const tempToDate: any = new Date(this.toDate);
    const tempFromDate: any = new Date(this.fromDate);
    // console.log(tempFromDate.toString());
    // console.log(tempToDate.toString());
    this.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;

    let d: any = new Date();
    // console.log('d on new:' + d);
    d = new Date(d.getFullYear(), d.getMonth(), 1);
    d.setMonth(d.getMonth() + 1);
    d.setDate(d.getDate() - 1);
    // console.log('d after set:' + d);
    this.toDateSelect = [];
    this.fromDateSelect = [];
    const months = [$localize`:January @@january:January`, $localize`:February @@february:February`, $localize`:March @@march:March`, $localize`:April @@april:April`, $localize`:May @@may:May`, $localize`:June @@june:June`, $localize`:July @@july:July`, $localize`:August @@august:August`, $localize`:September @@september:September`, $localize`:October @@october:October`, $localize`:November @@november:November`, $localize`:December @@december:December`];
    for (let x = 0; x < 38; x++) {
      const tempName: any = d.getFullYear() + ' ' + months[d.getMonth()];
      let tempMonth: any;
      if ((d.getMonth() + 1) < 10) {
        tempMonth = '0' + (d.getMonth() + 1);
      } else {
        tempMonth = (d.getMonth() + 1);
      }
      if (x < 37) {
        const tempFromDate1: string = tempMonth + '/01/' + d.getFullYear();
        this.fromDateSelect.push({date: tempFromDate1, name: tempName});
      }
      // if (x < 26) {
      const tempToDate1: string = tempMonth + '/' + d.getDate() + '/' + d.getFullYear();
      this.toDateSelect.push({date: tempToDate1, name: tempName});
      // }
      d = new Date(d.getFullYear(), d.getMonth(), 1);
      d.setDate(d.getDate() - 1);
      // console.log('d on new:' + d);

    }
    return new DFData(this.monthCount, this.fromDateSelect, this.toDateSelect);
  }

  // example for single value
  GetFromDateSelect(toDate: string, fromDate: string) {
    this.DateFilterInit(toDate, fromDate);
    return this.fromDateSelect;
  }

  GetDateFormat(value, formatMask: string = '') {
    if (value === '' || value === null) {
      return;
    }
    if (formatMask === '') {
      formatMask = 'MM/dd/yyyy';
    }
    const currentDatetime = new Date(value);
    return this.datePipe.transform(currentDatetime, formatMask);
    // return this.appendLeadingZeroes((currentDatetime.getMonth() + 1)) + '/' + this.appendLeadingZeroes(currentDatetime.getDate()) + '/'
    //   + currentDatetime.getFullYear();
  }

  // appendLeadingZeroes(n) {
  //   if (n <= 9) {
  //     return '0' + n;
  //   }
  //   return n;
  // }
}

class DFData {
  public request: any;
  public monthCount: any;
  public toDateSelect: any[];
  public fromDateSelect: any[];

  constructor( monthCount: any, fromDateSelect: any[], toDateSelect: any[]) {
    this.monthCount = monthCount;
    this.toDateSelect = toDateSelect;
    this.fromDateSelect = fromDateSelect;
  }
}
