import {Component, OnInit, Input} from '@angular/core';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';

@Component({
  selector: 'app-account-type-select',
  templateUrl: './account-type-select.component.html',
  styleUrls: ['./account-type-select.component.css']
})
export class AccountTypeSelectComponent implements OnInit {
  @Input() vm: any;

  constructor(public isFiltered: IsFilterOnService) {
  }

  ngOnInit() {
  }

  checkFiltered(): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    this.vm.filtered = filteredValue;
    return filteredValue;
  }

}
