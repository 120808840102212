import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StorageService } from '../../../../Services/storage.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { EmailService } from '../../../../Services/email.service';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../../../../Services/app-config.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../../Services/email-pass-data-service.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {RoutingTrackerService} from '../../../../Services/routing-tracker.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  public queryParams: any = [];
  private rptName: string;
  public urlParams: any = [];
  public vm: any = {
    report: [],
    request: {
      fromDate: null,
      toDate: null,
      entity_id: null,
      batch_id: null,
    },
    newBatch: {
      audit_sequnece: null,
    },
    fromDateSelect: null,
    toDateSelect: null,
    monthCount: null,
    subLedger: null,
    entity: [],
    entityType: [],
    location: [],
    currency: [],
    rfType: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    entityCity: [],
    entityState: [],
    bank: [],
    paymentType: [],
    company: [],
    buttonsDisabled: false,
    entityFilter: { name: null },
    batchFilter: { description: null },
    batch: [],
    entityInput: '',
    entityName: [],
    startEntity: 0,
    fromDateError: null,
    toDateError: null,
    dateError: null,
    entity_email: '',
    email: {
      toemail: null,
      fromemail: this.appConfig.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    showEmail: true,
    paymentFilter: {
      search_string: null,
    },
    payment: [],
    updateBatch: null,
    batchName: [],
    startBatch: 0,
    startBatchName: 0,
    batchInput: null,
  };

  routedFrom = '';

  constructor(
    public tableDataService: TableDataService,
    public dateFilter: DateFilterService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    public localStorage: StorageService,
    public notification: BsToastService,
    public aEmail: EmailService,
    public appConfig: AppConfigService,
    private emailData: EmailPassDataServiceService,
    public prevUrl: RoutingTrackerService
  ) {
    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  ngOnInit() {
    this.urlParams = this.route.snapshot.queryParams;

    this.vm.subLedger = this.urlParams.subLedger;
    this.vm.batchId = this.urlParams.batch_id;

    this.FilterOptionsInit().then();
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.rptName = $localize`:Payments @@payments:Payments`;
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.subLedger.toUpperCase() + ' ' + this.rptName + ' Report');
    // console.log('Time Out --> ' + this.timeOut);
  }

  ngAfterViewChecked() {
    const div = document.getElementById('excel-export');
    // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Payments Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  async FilterOptionsInit() {
    // this.tableDataService.getTableData('entity?state=active').subscribe((result: any[]) => {
    //   this.vm.entity = result;
    //   for (const [x, value] of Object.entries(this.vm.entity)) {
    //     this.vm.entityName.push({name: this.vm.entity[x].name});
    //   }
    // });
    this.tableDataService
      .getTableData('entity_type?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.entityType = result.Data;
      });
    this.tableDataService
      .getTableData('location?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
        // console.log(JSON.stringify(result));
      });
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field_type?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.rfType = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf1&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf1 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf2&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf2 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf3&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf3 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf4&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf4 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf5&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf5 = result.Data;
      });
    this.vm.entity = await this.getFilterValues('entity', '?state=active');
    for (const [x, value] of Object.entries(this.vm.entity)) {
      this.vm.entityName.push({ name: this.vm.entity[x].name });
    }

    let tempBatchType = null;
    if (this.vm.subLedger === 'ap') {
      tempBatchType = '5';
    } else if (this.vm.subLedger === 'ar') {
      tempBatchType = '3';
    }
    // else if (this.vm.subLedger === 'sq') {
    //   tempBatchType = '8';
    // } else if (this.vm.subLedger === 'so') {
    //   tempBatchType = '9';
    // } else if (this.vm.subLedger === 'po') {
    //   tempBatchType = '10';
    // }

    this.tableDataService.getTableData('t_batch/?batchType=' + tempBatchType).subscribe((result: any[]) => {
      // this.tableDataService.getTableData('t_batch', '?state=active').subscribe((result) => {
      this.vm.batch = result;
      // console.log(JSON.stringify(result));
      for (const [x, value] of Object.entries(this.vm.batch)) {
        this.vm.batchName.push({ name: this.vm.batch[x].description });
      }

      // run this if batch passed in url
      if (this.vm.batchId) {
        this.vm.batch.forEach(x => {
          if (x.id === this.vm.batchId) {
            // console.log(x);
            this.vm.batchInput = x.description;
            this.vm.request.batch_id = x.id;
            this.update();
          }
        });
      }

    });

    if (this.urlParams.entity_id != null || this.urlParams.entity_id != undefined) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        // console.log('outside if:' + this.vm.entity[y].id);
        if (this.vm.entity[y]) {
          // console.log('inside if:' + this.vm.entity[y].id);
          if (this.vm.entity[y].id == this.urlParams.entity_id) {
            this.vm.entityInput = this.vm.entity[y].name;
            this.vm.request.entity_id = this.vm.entity[y].id;
            this.vm.request.entity_email = this.vm.entity[y].email;
            // console.log('break:' + this.vm.entityInput);
            break;
          }
        }
      }
    }

    if (this.urlParams.fromDate) {
      this.vm.request.fromDate = this.urlParams.fromDate;
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth() - 3, 1);
      this.vm.request.fromDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      // this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
    }

    if (this.urlParams.toDate) {
      this.vm.request.toDate = this.urlParams.toDate;
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth(), 1);
      d.setDate(d.getDate() - 1);
      this.vm.request.toDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      // this.localStorage.StorageSet('toDate', this.vm.request.toDate);
    }
    if (this.vm.request.toDate != '' && this.vm.request.fromDate != '' && this.vm.batchId) {
      this.update();
    } else if (this.vm.request.toDate != '' && this.vm.request.fromDate != '' && this.vm.request.entity_id) {
      this.update();
    }
  }

  async getFilterValues(tablename, param) {
    let values: any;
    values = null;
    await this.tableDataService
      .getTableData(tablename + param + '&pageNumber=0&pageSize=99999')
      .toPromise()
      .then((result: ResponseModel) => {
        const data: any = result.Data;
        values = data;
      });
    // this.localStorage.StorageSet(tablename, JSON.stringify(values));
    return values;
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  update() {
    let inputError = false;
    if (!this.vm.request.toDate) {
      this.vm.toDateError = true;
      inputError = true;
      this.notification.showWarningToast($localize`:To Date is required. @@toDateisrequired:To Date is required.`);
    }
    if (!this.vm.request.fromDate) {
      inputError = true;
      this.vm.fromDateError = true;
      this.notification.showWarningToast($localize`:From Date is required. @@fromDateisrequired:From Date is required.`);
    }
    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    if (tempToDate - tempFromDate < 0) {
      this.vm.dateError = true;
      inputError = true;
      this.notification.showWarningToast(
        $localize`:The To Date must be greater than the From Date. @@theToDatemustbegreaterthantheFromDate:The To Date must be greater than the From Date.`
      );
    }
    if (!inputError) {
      this.vm.buttonsDisabled = true;
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      // this.localStorage.StorageSet('toDate', this.vm.request.toDate);
      // this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
      // console.log('co:' + this.vm.request.company_id);
      const urlParamsJson = {
        fromDate: this.vm.request.fromDate,
        toDate: this.vm.request.toDate,
        subLedger: this.vm.subLedger,
        entity_id: this.vm.request.entity_id,
        company_id: this.vm.request.company_id === undefined ? null : this.vm.request.company_id,
        batch_id: this.vm.request.batch_id,
      };

      // console.log(urlParamsJson);
      const urlParams: string = this.encodeQueryData(urlParamsJson);
      this.tableDataService.getTableData('PaymentReport', '?' + urlParams).subscribe((result) => {
        this.vm.report = result;
        // console.log(result);
        if (!this.vm.report.length) {
          this.notification.showWarningToast('No data matching selection criteria');
          this.vm.buttonsDisabled = false;
          return;
        }
        this.vm.buttonsDisabled = false;
        this.vm.toDateError = false;
        this.vm.fromDateError = false;
        this.vm.dateError = false;
        // console.log(JSON.stringify(result));
        let useDate;
        // when batch sent need to use batch items oldest from date - doesnt search on it but looks weird to user if not
        // using batchid only in api
        if (this.vm.batchId && result) {
          // sorted desc so just pick last one
          this.vm.report.forEach(x => {
            useDate = x.header_date;
          });
          // convert so Date understands
          useDate = new DatePipe('en-US').transform(useDate, 'MM/dd/yyyy');
          let oldestDate = new Date(useDate);
          // back up one month and go to first day of month
          oldestDate = new Date(oldestDate.getFullYear(), oldestDate.getMonth() - 1, 1);
          this.vm.request.fromDate = new DatePipe('en-US').transform(oldestDate, 'MM/dd/yyyy');
        }
      });
    }
  }

  updateEntity() {
    this.vm.buttonsDisabled = true;
    // this.localStorage.StorageSet('entity', '');
    this.tableDataService
      .getTableData('entity', '?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.entity = result.Data;
        this.vm.buttonsDisabled = false;
      });
  }

  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.entity_email = this.vm.entity[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput == '' || this.vm.entityInput == null) {
      this.vm.request.entity_id = '';
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.entityInput }).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].name;
        const entityEmail = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].email;
        if (entityId != '' || entityId != null) {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
          this.vm.entity_email = entityEmail;
        } else {
          this.vm.request.entity_id = '';
          this.vm.entity_email = '';
        }
      } else {
        this.vm.request.entity_id = '';
        this.vm.entity_email = '';
      }
    }
    if (entityIdStart != this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }

  updateBatch() {
    this.vm.buttonsDisabled = true;
    this.vm.startBatchName = 0;
    let tempBatchType = null;
    if (this.vm.subLedger === 'ap') {
      tempBatchType = '5';
    } else if (this.vm.subLedger === 'ar') {
      tempBatchType = '3';
    }
    // } else if (this.vm.subLedger === 'sq') {
    //   tempBatchType = '8';
    // } else if (this.vm.subLedger === 'so') {
    //   tempBatchType = '9';
    // } else if (this.vm.subLedger === 'po') {
    //   tempBatchType = '10';
    // }
    this.tableDataService.getTableData('t_batch', '?state=active&batchType=' + tempBatchType).subscribe((result) => {
      // this.tableDataService.getTableData('t_batch', '?state=active').subscribe((result) => {
      this.vm.batch = result;
      this.vm.buttonsDisabled = false;
    });
  }

  selectBatch(batchName) {
    this.vm.request.batch_id = '';
    if (this.vm.request.batch_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.batch)) {
        if (this.vm.batch[y].description == batchName) {
          this.vm.batchInput = batchName;
          this.vm.request.batch_id = this.vm.batch[y].id;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testBatch() {
    const batchIdStart = this.vm.batchId;
    if (!this.vm.batchInput || this.vm.batchInput == '' || this.vm.batchInput == null) {
      this.vm.request.batch_id = undefined;
    } else {
      if (this.FilterObject(this.vm.batch, { description: this.vm.batchInput }).length > 0) {
        const batchId = this.FilterObject(this.vm.batch, { description: this.vm.batchInput }, true)[0].id;
        if (batchId != '' || batchId != null) {
          this.vm.request.batch_id = batchId;
        }
      }
    }
    if (batchIdStart != this.vm.request.batch_id) {
      this.vm.report = [];
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  close() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom == undefined) {
      window.close();
    } else {
      this.router.navigateByUrl('/home').then();
    }
  }

  clear() {
    this.vm.request = {
      fromDate: null,
      toDate: null,
      entity_id: null,
      batch_id: null,
    };
    this.vm.report = [];
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.vm.subLedger.toUpperCase() + ' Payments ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }
}
