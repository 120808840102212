import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountMask'
})
export class AccountMaskPipe implements PipeTransform {

  transform(value: string, showMask: boolean): string {
    if (value != null) {
      if (!showMask) {
        return value;
      }
      // better mask for length as well - make this fixed so user really can't see true length
      return '****' + value.substr(value.length - 4, 4);
    } else {
      return value;
    }
  }

}
