import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FilterPipe } from 'ngx-filter-pipe';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { Title } from '@angular/platform-browser';
import { DecimalNewPipe } from '../../../common/DecimalNewPipe';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService} from '../../../../Services/bs-toast-service';
import {RoutingTrackerService} from '../../../../Services/routing-tracker.service';


@Component({
  selector: 'app-transfer-form',
  templateUrl: './transfer-form.component.html',
  styleUrls: ['./transfer-form.component.css'],
})
export class TransferFormComponent implements OnInit, AfterViewInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private dateFilter: DateFilterService,
    private decimal: DecimalPipe,
    private titleService: Title,
    private decimalPipe: DecimalNewPipe,
    private confirmDialogService: ConfirmDialogService,
    private prevUrl: RoutingTrackerService
  ) {
    this.vm.inventoryItemFilter = { search_string: null };
    this.vm.accountFilter = { search_string: null };
    this.vm.bomFilter = { item_name: null };
    this.vm.startInventoryItem = 0;
    this.vm.startAccount = 0;
    this.vm.tempAccountDetailId = null;
    this.vm.tempItemDetailId = null;
    this.vm.printItem = {};
    this.vm.item = {};
    this.vm.getSignature = false;
    this.vm.title = $localize`:Transfer @@transfer:Transfer`;
    this.vm.deleted = [];
    this.vm.account = [];
    this.vm.company = [];
    this.vm.location = [];
    this.vm.uom = [];
    this.vm.inventoryItem = [];
    this.vm.accountSubtotal = [];
    this.vm.currency = {};
    this.vm.newAssembly = false;
    this.vm.closeAssembly = false;

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }
  @ViewChild('sPad', { static: true }) signaturePadElement;


  vm: any = {
    header: {
      completed: false,
      from_location_id: '',
      to_location_id: '',
    },
    item: {
      attachments: [],
    },
    detail: {
      location_id: '',
    },
    auditTrial: {},
  };

  tempitems: any = {};
  confirmDelete;
  submitted = false;
  fromLocation = '';
  toLocation = '';

  routedFrom = '';

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: true,
    delivered: true,
    print: true,
  };
    saveCloseFlag = false;
  protected readonly window = window;

  ngOnInit() {
    this.vm.auditTrail = true;

    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    if (!this.vm.permissions.EditTransfers && !this.vm.permissions.ViewTransfers && !this.vm.permissions.Admin) {
      document.location.href = '/';
    }

    this.tableDataService
      .getTableData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });

    this.tableDataService
      .getTableData('account?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.account = result.Data;
      });

    this.tableDataService
      .getTableData('account_subtotal_no_parents?pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.accountSubtotal = result.Data;
      });

    this.tableDataService.getTableData('company?pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });

    this.tableDataService
      .getTableData('item?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.inventoryItem = result.Data;
      });

    // this.tableDataService
    //   .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
    //   .subscribe((result: ResponseModel) => {
    //     this.vm.currency = result.Data;
    //   });

    this.tableDataService
      .getTableData('uom?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.uom = result.Data;
      });

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.vm.transferId = params.get('transferId');
      this.vm.batchId = params.get('batchId');

      if (this.vm.transferId != 'new') {
        this.vm.item = {
          batch: {},
          header: {},
          detail: [],
        };

        this.tableDataService.getTableData('entry', this.vm.transferId).subscribe((result: any) => {
          this.vm.item = result;
          this.vm.buttonsDisabled = this.vm.item.header.completed;
          // console.log(result);
          this.tempitems = Object.assign({}, this.vm.item.header);
          // this.signaturetext();
          this.init();
        });
      } else {
        // this is a new one
        this.init(); // timing - keep the else or have to better handle subscribes
      }
    });
    if (this.vm.transferId != 'new') {
      this.buttonConfig.reset = false;
    }
  }

  ngAfterViewInit() {
    // this.signaturetext();
    this.titleService.setTitle(this.vm.title);
  }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  setFromLocation(location: any){
    const loc = this.vm.location.find(x => x.id == location);
    this.fromLocation = loc.name;
  }

  setToLocation(location: any){
    const loc = this.vm.location.find(x => x.id == location);
    this.toLocation = loc.name;
  }

  // detailExists(): boolean {
  //   for (const [x, value] of Object.entries(this.vm.item.detail)) {
  //     if (this.vm.item.detail[x].debit_credit || this.vm.item.detail[x].debit_credit === 0) {
  //       if (this.vm.item.detail[x].control_line == false && this.vm.item.detail[x].item_id) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //   }
  // }

  noDetailExists(): boolean {
    if (this.vm.item.detail.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  init() {
    if (this.vm.transferId == 'new') {
      this.vm.item.batch = {type_id: 15};
      this.vm.item.header = {header_date: new Date()};
      this.vm.item.detail = [];
    } else {
      const tempDate = this.tempitems.header_date;
      this.vm.item.header.header_date = new DatePipe('en-US').transform(tempDate, 'MM/dd/yyyy');
      if (this.vm.item.header.due_date) {
        this.vm.item.header.due_date = new DatePipe('en-US').transform(this.vm.item.header.header_date, 'MM/dd/yyyy');
      }

      for (const [x, value] of Object.entries(this.vm.item.detail)) {
        if (!this.vm.from_location_id && this.vm.item.detail[x].control_line == true) {
          this.vm.from_location_id = this.vm.item.detail[x].location_id;
          this.setFromLocation(this.vm.item.detail[x].location_id);
        }
        if (!this.vm.to_location_id && this.vm.item.detail[x].control_line == false) {
          this.vm.to_location_id = this.vm.item.detail[x].location_id;
          this.setToLocation(this.vm.item.detail[x].location_id);
        }
        this.vm.item.detail[x].quantity = Number(this.vm.item.detail[x].quantity);
        this.vm.item.detail[x].display_quantity = 0 - Number(this.vm.item.detail[x].quantity);
        if (this.vm.item.detail[x].item_id) {
          if (this.FilterObject(this.vm.inventoryItem, {id: this.vm.item.detail[x].item_id}, true).length > 0) {
            this.vm.item.detail[x].itemInput = this.FilterObject(
              this.vm.inventoryItem,
              {id: this.vm.item.detail[x].item_id},
              true
            )[0].code;
          }
        }
        if (this.FilterObject(this.vm.account, {id: this.vm.item.detail[x].account_id}, true).length > 0) {
          this.vm.item.detail[x].accountInput =
            this.FilterObject(this.vm.account, {id: this.vm.item.detail[x].account_id}, true)[0].number +
            ' ' +
            this.FilterObject(this.vm.account, {id: this.vm.item.detail[x].account_id}, true)[0].name;
        }
      }
    }

    this.vm.newItemId = '';

    if (this.vm.company.length === 1) {
      this.vm.item.header.company_id = this.vm.company[0].id;
    }
    if (this.vm.currency.length === 1) {
      this.vm.item.header.currency_id = this.vm.currency[0].id;
    }

    this.vm.accountName = [];

    for (const [y, value] of Object.entries(this.vm.account)) {
      const tempName = this.vm.account[y].number + ' ' + this.vm.account[y].name;
      this.vm.accountName.push({
        name: tempName,
      });
    }

    if (this.vm.transferId === 'new') {
    this.addRows(2);
  }
    this.buildInventoryItemName();
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  accountSelected(id) {
    // console.log('in account selected');
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail[x].account_id = '';
        for (const [y, val] of Object.entries(this.vm.account)) {
          if (this.vm.account[y].number + ' ' + this.vm.account[y].name == this.vm.item.detail[x].accountInput) {
            this.vm.item.detail[x].account_id = this.vm.account[y].id;
            break;
          }
        }
        break;
      }
    }
  }

  addRows(qty) {
    if (this.vm.item.header.signature) {
      return;
    }
    if (qty === '') {
      qty = 0;
    }
    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (maxSortOrder < this.vm.item.detail[x].sort_order) {
        maxSortOrder = this.vm.item.detail[x].sort_order;
      }
    }
    for (let x = 0; x < qty; x++) {
      maxSortOrder++;
      this.vm.item.detail.push({
        id: maxSortOrder,
        sort_order: maxSortOrder,
        quantity: 0,
      });
    }
  }

  calcToDetails() {
    // get the currency
    const companyId = this.getLocationCompanyId(this.vm.from_location_id);
    this.vm.item.header.currency_id = this.getCurrencyId(companyId);

    // set control line values - those attached to from loc are negative vals so lets make control
    for (let x = this.vm.item.detail.length - 1; x >= 0; x--) {
      if (this.vm.item.detail[x].quantity < 0) {
        this.vm.item.detail[x].control_line = true;
      } else {
        this.vm.item.detail[x].control_line = false;
      }
    }

    // set from amd to locs just to be sure
    this.vm.item.header.from_location_id = this.vm.from_location_id;
    this.vm.item.header.to_location_id = this.vm.to_location_id;
  }

  createToRecord() {
    // adds a line for each from record user has entered

    for (const [y, val] of Object.entries(this.vm.item.detail)) {
      // from records carry a negative to decrease the number of items we transfer
      if (this.vm.item.detail[y].quantity < 0) {
        this.vm.item.detail.push({
          id: this.vm.item.detail.length + 1,
          sort_order: this.vm.item.detail.length + 1,
          itemInput: this.vm.item.detail[y].itemInput,
          accountInput: this.vm.item.detail[y].accountInput,
          item_id: this.vm.item.detail[y].item_id,
          uom_id: this.vm.item.detail[y].uom_id,
          description:
            this.getInvItemName(this.vm.item.detail[y].item_id) +
            ' (' +
            this.getLocationName(this.vm.from_location_id) +
            ' --> ' +
            this.getLocationName(this.vm.to_location_id) +
            ')',
          quantity: this.vm.item.detail[y].quantity * -1,
          display_quantity: this.vm.item.detail[y].display_quantity * -1,
          amount: this.vm.item.detail[y].amount * -1,
          debit_credit: this.vm.item.detail[y].debit_credit * -1,
          account_id: this.vm.item.detail[y].account_id,
          control_line: false,
          location_id: this.vm.to_location_id,
        });
      }
    }
  }

  clearLineItems() {
    this.vm.item.detail = [];
    this.addRows(1);
  }

  sumOfDetailLines(): number {
    let sum = 0;

    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      sum = sum + this.vm.item.detail[x].quantity;
    }
    return sum;
  }

  getCurrencyId(companyId: string) {
    let curId = '';

    for (const co of this.vm.company) {
      if (co.id === companyId) {
        curId = co.currency_id;
      }
    }
    return curId;
  }

  getLocationName(id: string): string {
    let name = '';

    if (id === undefined) {
      id = this.vm.from_location_id;
    }
    for (const loc of this.vm.location) {
      if (loc.id === id) {
        name = loc.name;
      }
    }
    return name;
  }

  getLocationCompanyId(locationId: string): string {
    let coId = '';

    for (const loc of this.vm.location) {
      if (loc.id === locationId) {
        coId = loc.company_id;
      }
    }
    return coId;
  }

  getInvItemName(id: string): string {
    let name = '';

    if (id === undefined) {
      return 'empty';
    }
    for (const item of this.vm.inventoryItem) {
      if (item.id === id) {
        name = item.name;
      }
    }
    return name;
  }

  save() {
    this.submitted = true;
    this.calcToDetails();

    if (this.sumOfDetailLines() != 0) {
      this.notification.showWarningToast('Transfer quantities do not balance between Locations.');
      return;
    }

    if (!this.vm.item.attachments) {
      this.vm.item.attachments = [];
    }
    let error = 0;
    let warnMsg = '';
    // const attach: HTMLInputElement = document.getElementById('attachments') as HTMLInputElement;
    // if (attach.value) {
    //   let attachmentsArray = this.vm.item.attachments;
    //   const headerId = this.vm.item.header.id;
    //   const attachments = attach.value.split('|');
    //   if (!this.vm.item.wo_attachments) {
    //     this.vm.item.wo_attachments = [];
    //   }
    //   attachments.forEach((element) => {
    //     let found = false;
    //     for (const [x, value] of Object.entries(attachmentsArray)) {
    //       if (this.vm.item.attachments[x].attachment == element) {
    //         found = true;
    //         break;
    //       }
    //     }
    //     if (!found) {
    //       attachmentsArray.push({
    //         type: 'jpg',
    //         headerId,
    //         attachment: element,
    //       });
    //     }
    //   });
    //   this.vm.item.attachments = attachmentsArray;
    //   attachmentsArray = [];
    // }

    if (!this.vm.item.header.header_date || !this.vm.item.header.company_id) {
      if (!this.vm.item.header.header_date) {
        // this.notification.warning($localize`:Date is required @@dateisrequired:Date is required`);
        this.vm.dateMissing = true;
        warnMsg += $localize`:Date is required @@dateisrequired:Date is required` + '</br>';
        error = 1;
      }
      if (!this.vm.item.header.company_id) {
        // this.notification.warning($localize`:Company is required. @@companyisrequired:Company is required.`);
        this.vm.companyError = true;
        warnMsg += $localize`:Company is required. @@companyisrequired:Company is required.` + '</br>';
        error = 1;
      }
    } // else {

    // dup trap
    // if (!this.vm.item.header.description) {
    //     // this.notification.warning(
    //     //   $localize`:Description is required. @@descriptionisrequired:Description is required.`
    //     // );
    //     this.vm.descriptionError = true;
    //     warnMsg += $localize`:Description is required. @@descriptionisrequired:Description is required.` + '</br>';
    //     error = 1;
    //   }

    let yearEndDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0].year_end_date;
    let cutOffDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0].cut_off_date;
    const tempYearEndDate = new Date(yearEndDate);
    const tempCutOffDate = new Date(cutOffDate);
    const headerDate = new Date(this.vm.item.header.header_date);

    if (headerDate < tempYearEndDate) {
      yearEndDate = this.dateFilter.GetDateFormat(yearEndDate);
      // this.notification.warning(
      //   $localize`:Date must be greater than the year end  @@datemustbegreaterthantheyearend:Date must be greater than the year end ` +
      //     yearEndDate
      // );
      warnMsg +=
        $localize`:Date must be greater than the year end  @@datemustbegreaterthantheyearend:Date must be greater than the year end ` +
        yearEndDate +
        '</br>';
      error = 1;
    }
    if (headerDate < tempCutOffDate) {
      cutOffDate = this.dateFilter.GetDateFormat(cutOffDate);
      // this.notification.warning(
      //   $localize`:Date must be greater than the cut off date  @@datemustbegreaterthanthecutoffdate :Date must be greater than the cut off date ` +
      //     cutOffDate
      // );
      warnMsg +=
        $localize`:Date must be greater than the cut off date  @@datemustbegreaterthanthecutoffdate :Date must be greater than the cut off date ` +
        cutOffDate +
        '</br';
      error = 1;
    }
    this.vm.item.header.header_date = this.dateFilter.GetDateFormat(this.vm.item.header.header_date);
    // }

    if (!this.vm.from_location_id) {
      // this.notification.warning(
      //   $localize`:From Location is required.  @@fromLocationisrequired:From Location is required. `
      // );
      this.vm.locationError = true;
      warnMsg += $localize`:From Location is required.  @@fromLocationisrequired:From Location is required. ` + '</br>';
      error = 1;
    }

    if (!this.vm.to_location_id) {
      // this.notification.warning($localize`:To Location is required.  @@toLocationisrequired:To Location is required. `);
      this.vm.locationError = true;
      warnMsg += $localize`:To Location is required.  @@toLocationisrequired:To Location is required. ` + '</br>';
      error = 1;
    }
    if (!this.vm.item.header.description) {
      // this.notification.warning($localize`:Description is required.  @@descriptionisrequired:Description is required. `);
      warnMsg += $localize`:Description is required.  @@descriptionisrequired:Description is required. ` + '</br>';
      this.vm.descriptionError = true;
      error = 1;
    }

    let detailFound = false;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (!this.vm.item.detail[x].debit_credit) {
        this.vm.item.detail[x].debit_credit = 0;
      }
      if (this.vm.item.detail[x].debit_credit || this.vm.item.detail[x].debit_credit === 0) {
        if (this.vm.item.detail[x].control_line == false && this.vm.item.detail[x].item_id) {
          detailFound = true;
        }
        if (!this.vm.item.detail[x].description && this.vm.item.detail[x].item_id) {
          this.notification.showWarningToast('Detail Description is required.');
          error = 1;
        }
        if (!this.vm.item.detail[x].account_id && this.vm.item.detail[x].item_id) {
          this.notification.showWarningToast('Detail Account is required.');
          error = 1;
        }
      }
    }

    if (!detailFound) {
      // this.notification.warning(
      //   $localize`:At least one Item is required.  @@atleastoneItemisrequired:At least one Item is required. `
      // );
      warnMsg +=
        $localize`:At least one Item is required.  @@atleastoneItemisrequired:At least one Item is required. ` +
        '</br>';
      error = 1;
    }

    for (const det of this.vm.item.detail) {
      if (!det.uom_id || !det.display_quantity || !det.accountInput || !det.itemInput) {
        warnMsg += $localize`:Detail line missing values. @@detaillinemissing:Detail line missing values. ` + '</br>';
        error = 1;
      }
    }

    if (!this.vm.permissions.Admin && !this.vm.permissions.EditTransfers) {
      // this.notification.warning(
      //   $localize`:You do not have access to save. @@youdonothaveaccesstosave:You do not have access to save.`
      // );
      warnMsg +=
        $localize`:You do not have access to save. @@youdonothaveaccesstosave:You do not have access to save.` +
        '</br>';
      error = 1;
    }

    if (error === 1) {
      this.notification.showWarningToast(warnMsg);
      return;
    }

    if (error === 0) {
      this.vm.buttonsDisabled = true;
      if (this.vm.item.header.id === undefined || this.vm.item.header.id === 'new') {
        // new
        this.tableDataService.post('entry', this.vm.item).subscribe(
          (item: any) => {
            // this.notification.success($localize`:Saved @@saved:Saved`);
            this.vm.buttonsDisabled = false;
            // if (this.vm.action == 'print') {
            //   window.print();
            //   this.vm.action = '';
            // } else if (this.vm.action == 'email') {
            //   // this.sendEmail();
            //   this.vm.action = '';
            // } else {
            this.submitted = false;
            if(!this.saveCloseFlag) {
              this.router.navigateByUrl('/inventory/transfer/form?transferId=' + item.id).then();
            }
            // this.router.navigateByUrl('/inventory/transfer/form?transferId=' + item.id).then();
              // this.router.navigateByUrl('/inventory/transfer/list').then();
            // }
          },
          (errors: any) => {
            this.vm.buttonsDisabled = false;
            // this.notification.error($localize`:Error @@error:Error`);
          }
        );
      } else {
        // edit
        // const params = {
        //   id: this.vm.item.header.id,
        //   tablename: 'entry',
        // };

        // diff
        const changes: any = {};
        let tempMonth: any;
        let tempDate: any;

        changes.detail = this.vm.item.detail;
        changes.salesperson = this.vm.item.salesperson;
        changes.attachments = this.vm.item.attachments;

        const headerChanges = {};
        for (const key in this.vm.item.header) {
          if (this.vm.item.header.hasOwnProperty(key)) {
            let value = this.vm.item.header[key];
            if (value !== this.tempitems[key] && key != 'entityInput') {
              if (key == 'header_date') {
                const d = new Date(value);
                if (d.getMonth() + 1 < 10) {
                  tempMonth = '0' + (d.getMonth() + 1);
                } else {
                  tempMonth = d.getMonth() + 1;
                }
                if (d.getDate() < 10) {
                  tempDate = '0' + d.getDate();
                } else {
                  tempDate = d.getDate();
                }
                value = d.getFullYear() + '-' + tempMonth + '-' + tempDate;
              }
              headerChanges[key] = value;
            }
          }
        }
        if (headerChanges) {
          changes.header = headerChanges;
        }

        this.tableDataService.patch('entry', this.vm.item.header.id, changes).then(
          (item: any) => {
            this.vm.buttonsDisabled = false;
            if (item.description == 'Deleted') {
              this.router.navigateByUrl('/inventory/transfer/list').then();
              // this.notification.success($localize`:Deleted @@deleted:Deleted`);
              return;
            }
            // if (this.vm.action === 'print') {
            //   window.print();
            // } else if (this.vm.action == 'email') {
            //   // TODO this.sendEmail();
            // }
            this.vm.action = '';
            // this.notification.success(item.description);
            this.submitted = false;
            if(!this.saveCloseFlag) {
              this.router.navigateByUrl('/inventory/transfer/form?transferId=' + this.vm.item.header.id).then();
            }

            // }
            this.vm.buttonsDisabled = false;
          },
          (er: any) => {
            this.vm.buttonsDisabled = false;
            // this.notification.error($localize`:Error @@error:Error ` + er.message);
            console.log($localize`:Error @@error:Error ` + er.message);
          }
        );
      }
    } else {
      // report error
      this.vm.buttonsDisabled = false;
    }
  }

  saveNew() {
    this.save();
  }

  saveClose() {
    this.saveCloseFlag = true;
    this.save();
    this.onClose();
  }

  deleteLine(id) {
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail.splice(x, 1);
        break;
      }
    }
  }

  // item functions
  buildInventoryItemName() {
    this.vm.inventoryItemName = [];
    for (const [y, value] of Object.entries(this.vm.inventoryItem)) {
      let tempUOM = '';
      if (this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true).length > 0) {
        tempUOM = this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true)[0].name;
      }
      // let tempUOMName = this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true)[0].name;
      const tempName = this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name + ' ' + tempUOM;
      this.vm.inventoryItemName.push({
        name: tempName,
      });
    }
  }

  updateInventoryItem() {
    this.vm.startInventoryItem = 0;
    this.tableDataService
      .getTableData('item?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.inventoryItem = result.Data;
      });
    this.buildInventoryItemName();
  }

  selectItemDropdown(id) {
    // type ahead not sed on this form for inventory - needs the click event for other stuff
    const foundItem = false;
  }

  selectItemModal(event: any) {
    const itemId = event;
    const id = this.vm.tempItemDetailId;
    let maxSortOrder = 0;

    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (maxSortOrder < this.vm.item.detail[x].sort_order) {
        maxSortOrder = this.vm.item.detail[x].sort_order;
      }
    }
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail[x].item_id = '';

        for (const [y, val] of Object.entries(this.vm.inventoryItem)) {
          if (this.vm.inventoryItem[y].id == itemId) {
            this.vm.item.detail[x].header_id = this.vm.item.header.id;
            this.vm.item.detail[x].description =
              this.getInvItemName(this.vm.inventoryItem[y].id) +
              ' (' +
              this.getLocationName(this.vm.from_location_id) +
              ' --> ' +
              this.getLocationName(this.vm.to_location_id) +
              ')';
            this.vm.item.detail[x].itemInput = this.vm.inventoryItem[y].code;
            this.vm.item.detail[x].item_id = this.vm.inventoryItem[y].id;
            this.vm.item.detail[x].uom_id = this.vm.inventoryItem[y].uom_id;
            this.vm.item.detail[x].account_id = 'in';
            this.vm.item.detail[x].display_quantity = -1;
            this.vm.item.detail[x].quantity = -1;
            this.vm.item.detail[x].amount = this.vm.inventoryItem[y].cost * -1;
            this.vm.item.detail[x].debit_credit = 0 - this.vm.inventoryItem[y].cost;
            this.vm.item.detail[x].location_id = this.vm.item.header.from_location_id;
            maxSortOrder++;
            break;
          }
        }
        break;
      }
    }
  }

  print() {
    this.vm.action = 'print';
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.FilterObject(this.vm.uom, { id: this.vm.item.detail[x].uom_id }, true).length > 0) {
        this.vm.item.detail[x].uom = this.FilterObject(
          this.vm.uom,
          { id: this.vm.item.detail[x].uom_id },
          true
        )[0].code;
      }
    }
    if (this.vm.item.header.entity_id) {
      if (this.FilterObject(this.vm.entity, { id: this.vm.item.header.entity_id }, true)) {
        this.vm.item.header.entityInput = this.FilterObject(
          this.vm.entity,
          { id: this.vm.item.header.entity_id },
          true
        )[0].name;
      }
    }
    if (this.vm.from_location_id) {
      if (this.FilterObject(this.vm.location, { id: this.vm.from_location_id }, true)) {
        const tempAddress = this.FilterObject(this.vm.location, { id: this.vm.from_location_id }, true)[0];
        this.vm.printItem.location1Name = tempAddress.name;
        if (tempAddress.address_1) {
          this.vm.printItem.location1Address1 = tempAddress.address_1;
        }
        if (tempAddress.city) {
          this.vm.printItem.location1Address2 = tempAddress.city;
        }
        if (tempAddress.state) {
          this.vm.printItem.location1Address2 += ', ' + tempAddress.state;
        }
        if (tempAddress.zip) {
          this.vm.printItem.location1Address2 = tempAddress.zip;
        }
      }
    }

    if (this.vm.to_location_id) {
      if (this.FilterObject(this.vm.location, { id: this.vm.to_location_id }, true)) {
        const tempAddress = this.FilterObject(this.vm.location, { id: this.vm.to_location_id }, true)[0];
        this.vm.printItem.location2Name = tempAddress.name;
        if (tempAddress.address_1) {
          this.vm.printItem.location2Address1 = tempAddress.address_1;
        }
        if (tempAddress.city) {
          this.vm.printItem.location2Address2 = tempAddress.city;
        }
        if (tempAddress.state) {
          this.vm.printItem.location2Address2 += ', ' + tempAddress.state;
        }
        if (tempAddress.zip) {
          this.vm.printItem.location2Address2 = tempAddress.zip;
        }
      }
    }

    // set company address
    if (this.vm.item.header.company_id) {
      if (this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]) {
        const tempAddress = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0];
        this.vm.printItem.companyLogo = tempAddress.logo;
        this.vm.printItem.companyName = tempAddress.name;
        this.vm.printItem.companyAddress1 = tempAddress.address_1;
        this.vm.printItem.companyAddress2 = tempAddress.city;
        if (tempAddress.state) {
          this.vm.printItem.companyAddress2 += ', ' + tempAddress.state;
        }
        this.vm.printItem.companyAddress2 += '  ' + tempAddress.zip;
        this.vm.printItem.companyTaxNumber = tempAddress.tax_number;
      }
    }
    for (const [x, val] of Object.entries(this.vm.inventoryItem)) {
      for (const [y, value] of Object.entries(this.vm.item.detail)) {
        if (this.vm.item.detail[y].item_id == this.vm.inventoryItem[x].id) {
          this.vm.item.detail[y].itemInput = this.vm.inventoryItem[x].code;
        }
      }
    }

    if (
      !this.vm.item.batch.locked &&
      (this.vm.permissions.Admin ||
        (this.vm.permissions.EditReceivableInvoices && this.vm.subLedger == 'ar') ||
        (this.vm.permissions.EditPayableInvoices && this.vm.subLedger == 'ap'))
    ) {
      this.save();
    } else {
      this.tableDataService
        .getTableData('uom?state=active&pageNumber=0&pageSize=999999')
        .subscribe((result: ResponseModel) => {
          const tempUom = result.Data;
          window.print();
          this.vm.action = '';
        });
    }
  }

  deleteAttachment(id) {
    this.tableDataService.deleteTableDataById('t_header_attachment', id).subscribe(
      (result: any) => {
        // this.notification.error('Deleted');
        this.vm.buttonsDisabled = false;
      },
      (error: any) => {
        // this.notification.error($localize`:Error @@error:Error`);
        this.vm.buttonsDisabled = false;
      }
    );
  }

  updateAccount() {
    this.vm.startAccount = 0;
    this.tableDataService
      .getTableData('account', '?pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.account = result;
      });
  }

  selectAccountModal(accountId) {
    const id = this.vm.tempAccountDetailId;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id == id) {
        for (const [y, val] of Object.entries(this.vm.account)) {
          if (this.vm.account[y].id == accountId) {
            this.vm.item.detail[x].account_id = this.vm.account[y].id;
            this.vm.item.detail[x].accountInput = this.vm.account[y].number + ' ' + this.vm.account[y].name;
            break;
          }
        }
        break;
      }
    }
  }

  // Signature

  signature(signature) {
    this.vm.linkedSignature = signature;
    this.vm.item.header.signature = signature;
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  deleteSignature() {
    this.vm.item.header.signature = '';
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  syncItemAttachments(attachmentsArray: any) {
    // console.log('attachments array recvd in main');
    // console.log(attachmentsArray);

    // clear existing - will be put back because in array passed back in file upload array
    this.vm.item.attachments = [];

    // use the pieces needed for the api record
    attachmentsArray.forEach((x) => {
      this.vm.item.attachments.push({
        id: x.id,
        header_id: x.header_id,
        file_name: x.fileName,
        type: x.fileType,
        attachment: x.fileData,
        audit_sequence: 0
      });
    });
    // console.log('main vm array after sync to file upload data');
    // console.log(this.vm);
  }


  gotAnUploadFile(file: any) {
    // console.log('in got a file: ' + file);
    // console.log('in got a file: ');
  }

  delivered() {
    if (this.vm.transferId != 'new') {
      this.vm.item.header.completed = true;
      this.save();
    }
  }

  onClose() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom == undefined) {
      window.close();
    } else {
      this.router.navigateByUrl('/home').then();
    }
    // this.router.navigate(['/inventory/transfer/list']).then();
  }

  deleteItem() {
    if (this.vm.transferId != 'new') {
      const brClass = this;
      // let retMsg = '';
      const messages: string[] = [
        $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
        '',
      ];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.vm.item.header.deleted = true;
          this.save();
        },
        () => {
          brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
        }
      );
    }
  }
}
