import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TableDataService} from '../../../../Services/tableData.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../../Services/storage.service';
import {AccountMarginModel} from '../../../../DataModels/account-margin-model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-account-margin-form',
  templateUrl: './account-margin-form.component.html',
  styleUrls: ['./account-margin-form.component.css']
})
export class AccountMarginFormComponent implements OnInit, AfterViewInit {
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  accountMargin: AccountMarginModel;
  accountMarginForm: UntypedFormGroup;
  accountMargins$: Observable<AccountMarginModel[]>;
  accountMarginCount = 0;
  marginID;
  submitted = false;

  constructor(private table: TableDataService,
              private notification: BsToastService,
              private aRouter: Router,
              private aStorage: StorageService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDialogService: ConfirmDialogService,
              private titleService: Title) {
    this.createForm();
    this.marginID = this.route.snapshot.params.id;
  }

  createForm() {
    this.accountMarginForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      parent_id: new UntypedFormControl('NULL'),
      sort_order: new UntypedFormControl(''),
     // audit_sequence: new FormControl(''),
      active: new UntypedFormControl('true'),
    });
  }

  ngOnInit() {
    this.loadMargins();
    if (this.marginID) {
      this.loadMargin();
      this.buttonConfig.reset = false;
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Account Margin @@accountMargin:Account Margin`);
  }
  get f(){
    return this.accountMarginForm.controls;
  }
  private loadMargin() {
    this.table.getTableData('account_margin', this.marginID ).subscribe(
      result => {
        this.accountMargin = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.showErrorToast($localize`:Error loading Account Margins @@errorloadingAccountMargins:Error loading Account Margins`);
        console.log(error);
      },
      () => {
        this.accountMarginForm.patchValue(this.accountMargin);
      });
  }

  private loadMargins() {
   this.table.getApiListData('account_margin').subscribe((res: any) => {
      this.accountMargins$ = res.Data;
    });
  }

  saveClose() {
    if ( this.onSave()) {
      this.onClose();
    }
  }

  saveNew() {
    if (this.onSave()) {
      this.accountMarginForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete account margin @@areYouSureYouWantToDeleteAccountMargin:Are you sure you want to delete account margin?`, this.accountMargin.name];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('account_margin', this.marginID).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.showErrorToast($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          });
      }, () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }

  resetForm() {
    this.accountMarginForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/account-margin-list']).then();
  }

  onSave() {
    this.submitted = true;
    if (this.accountMarginForm.invalid) {
      this.notification.showInfoToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.accountMargin = Object.assign({}, this.accountMarginForm.value);
    if (this.marginID) {
      // TODO probably can move invalid out side if and capture both
      if (this.accountMarginForm.invalid === false) {
        this.table.patch('account_margin', this.marginID, this.accountMargin).then();
        // this.notification.success($localize`:Updated @@updated:Updated`);
        return true;
      }
    } else {
      this.table.postWithPromise('account_margin', this.accountMargin).then(res => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.marginID = apiReturn.id;
      });
      // this.notification.success($localize`:Saved @@saved:Saved`);
      return true;
    }
    this.submitted = false;
    return true;
  }

}
