import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-entity-activity-feed',
  templateUrl: './entity-activity-feed.component.html',
  styleUrls: ['./entity-activity-feed.component.css']
})
export class EntityActivityFeedComponent implements OnInit {
  @Input() activityEntityID: string;
  constructor() { }

  ngOnInit() {
  }

}
