import {
  AfterViewChecked,
  ChangeDetectorRef,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { DataExportService } from '../../../../Services/dataExport.service';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { AppConfigService } from '../../../../Services/app-config.service';
import { EmailService } from '../../../../Services/email.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../../Services/email-pass-data-service.service';
import { Router } from '@angular/router';
import { BsToastService } from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-entity-history-report',
  templateUrl: './entity-history-report.component.html',
  styleUrls: ['./entity-history-report.component.css'],
})
export class EntityHistoryReportComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('ExcelExport_1', { static: false }) ExcelExport_1!: ElementRef;
  @ViewChild('ExcelExport_2', { static: false }) ExcelExport_2!: ElementRef;
  @ViewChild('ExcelExport_3', { static: false }) ExcelExport_3!: ElementRef;
  @ViewChild('ExcelExport_4', { static: false }) ExcelExport_4!: ElementRef;
  @ViewChild('ExcelExport_5', { static: false }) ExcelExport_5!: ElementRef;
  @ViewChild('ExcelExport_6', { static: false }) ExcelExport_6!: ElementRef;

  private rptName: string;
  private reportUpdated = false;
  vm: any = {
    ToEmailError: false,
    showSpecificDates: false,
    company: [],
    currency: [],
    email: {
      fromemail: this.appConfigService.SysEmailAddress,
    },
    request: {
      fromDate: null,
      toDate: null,
      entity_id: null,
      report_type_id: 1,
    },
    report: [],
    Preferences: [],
    permissions: [],
    fromDateSelect: [],
    toDateSelect: [],
    monthCount: 0,
    dateError: false,
    buttonsDisabled: false,
    showEmail: true,
    exportToExcel: true,
    showFilters: false,
    entity: [],
    entityName: [],
    entityFilter: { name: '' },
    startEntity: 0,
  };

  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public appConfigService: AppConfigService,
    public dateFilter: DateFilterService,
    public notification: BsToastService,
    public aEmail: EmailService,
    public excelExporter: DataExportService,
    private emailData: EmailPassDataServiceService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    // this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Entity History Report @@entityHistoryReport:Entity History Report`;
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  loadEmailReportValues() {
    // set email data
    this.changeDetectorRef.detectChanges();
    let tempPdfHtml = '';
    let subject = '';
    if (this.objectKeys(this.vm.report).length > 0) {
      if (this.vm.request.report_type_id == 1) {
        tempPdfHtml = this.ExcelExport_1.nativeElement.innerHTML;
        subject = $localize`:Entity History Recent Items @@entityHistoryRecentItems:Entity History Recent Items`;
      } else if (this.vm.request.report_type_id == 2) {
        tempPdfHtml = this.ExcelExport_2.nativeElement.innerHTML;
        subject = $localize`:Entity History Top Items @@entityHistoryTopItems:Entity History Top Items`;
      } else if (this.vm.request.report_type_id == 3) {
        tempPdfHtml = this.ExcelExport_3.nativeElement.innerHTML;
        subject = $localize`:Entity History Receivable Invoices @@entityHistoryReceivableInvoices:Entity History Receivable Invoices`;
      } else if (this.vm.request.report_type_id == 4) {
        tempPdfHtml = this.ExcelExport_4.nativeElement.innerHTML;
        subject = $localize`:Entity History Receivable Payments @@entityHistoryReceivablePayments:Entity History Receivable Payments`;
      } else if (this.vm.request.report_type_id == 5) {
        tempPdfHtml = this.ExcelExport_5.nativeElement.innerHTML;
        subject = $localize`:Entity History Payable Invoices @@entityHistoryPayableInvoices:Entity History Payable Invoices`;
      } else if (this.vm.request.report_type_id == 6) {
        tempPdfHtml = this.ExcelExport_6.nativeElement.innerHTML;
        subject = $localize`:Entity History Payable Payments @@entityHistoryPayablePayments:Entity History Payable Payments`;
      }

      setTimeout(() => {
        this.emailData.clearAllEmailPassData();
        this.emailData.emailInfo.aRecordId = '';
        this.emailData.emailInfo.aMessage = '';
        this.emailData.emailInfo.aEntity = '';
        this.emailData.emailInfo.aBatchTypeId = '99';
        this.emailData.emailInfo.aReportString = tempPdfHtml;
        this.emailData.emailInfo.aReportOrDocName = subject;
        this.emailData.loadEmailDataToPassMailer();
      }, 3000);
    }
  }

  ngAfterViewChecked() {}

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService
      .getTableData('entity?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.entity = data;
        for (const [x, value] of Object.entries(this.vm.entity)) {
          this.vm.entityName.push({ name: this.vm.entity[x].name });
        }
      });

    this.vm.report_types = [
      { id: 1, name: 'Recent Items' },
      { id: 2, name: 'Top Items' },
      { id: 3, name: 'Receivable Invoices' },
      { id: 4, name: 'Receivable Payments' },
      { id: 5, name: 'Payable Invoices' },
      { id: 6, name: 'Payable Payments' },
    ];
    this.vm.request.report_type_id = 1;
  }
  onNavigate(aID: string) {
    this.router.navigate(['admin/item-form', { id: aID }]).then();
  }

  exportToExcel() {
    if (this.vm.request.report_type_id == 1 && this.ExcelExport_1) {
      this.excelExporter.exportToExcel(this.ExcelExport_1, this.rptName + '.xlsx');
    } else if (this.vm.request.report_type_id == 2 && this.ExcelExport_2) {
      this.excelExporter.exportToExcel(this.ExcelExport_2, this.rptName + '.xlsx');
    } else if (this.vm.request.report_type_id == 3 && this.ExcelExport_3) {
      this.excelExporter.exportToExcel(this.ExcelExport_3, this.rptName + '.xlsx');
    } else if (this.vm.request.report_type_id == 4 && this.ExcelExport_4) {
      this.excelExporter.exportToExcel(this.ExcelExport_4, this.rptName + '.xlsx');
    } else if (this.vm.request.report_type_id == 5 && this.ExcelExport_5 ) {
      this.excelExporter.exportToExcel(this.ExcelExport_5, this.rptName + '.xlsx');
    } else if (this.vm.request.report_type_id == 6 && this.ExcelExport_6) {
      this.excelExporter.exportToExcel(this.ExcelExport_6, this.rptName + '.xlsx');
    }
  }

  validateEmpty(value) {
    let returnValue = '-';
    if (value != null) {
      returnValue = value.trim();
      if (value != '') {
        returnValue = value;
      }
    }
    return returnValue;
  }

  update() {
    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    if (tempFromDate > tempToDate) {
      this.vm.dateError = true;
      this.notification.showWarningToast(
        $localize`:The "To-Date" must be greater than the "From-Date". @@theToDatemustbegreaterthantheFromDate:The "To-Date" must be greater than the "From-Date".`
      );
    } else if (!this.vm.request.fromDate || !this.vm.request.toDate) {
      this.vm.dateError = true;
      this.notification.showWarningToast(
        $localize`:You must select from and to dates. @@youmustselectfromandtodates:You must select from and to dates.`
      );
    } else {
      if (!this.vm.request.entity_id) {
        this.notification.showWarningToast(
          $localize`:Entity filter required! @@entityfilterrequired:Entity filter required!`
        );
        return;
      }

      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.buttonsDisabled = true;
      this.vm.dateError = false;
      this.tableDataService
        .getTableData(
          'entity_history/' + this.vm.request.entity_id,
          '?fromDate=' + this.vm.request.fromDate + '&toDate=' + this.vm.request.toDate
        )
        .subscribe((result) => {
          this.vm.report = result;
          this.vm.buttonsDisabled = false;
          // this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
          // this.localStorage.StorageSet('toDate', this.vm.request.toDate);
          this.loadEmailReportValues();
        });
    }
    this.reportUpdated = true;
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.vm.entityFilter.name = '';
    this.vm.report = [];
    this.vm.request = {
      toDate: null,
      fromDate: null,
      entity_id: null,
    };
  }

  Print() {
    return window.print();
  }

  objectKeys(obj) {
    if (obj != null) {
      return Object.keys(obj);
    }
    return { length: 0 };
  }

  updateEntity() {
    this.vm.buttonsDisabled = true;
    this.localStorage.StorageSet('entity', '');
    this.tableDataService
      .getTableData('entity', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.entity = result.Data;
      });
  }

  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput == '' || this.vm.entityInput == null) {
      this.vm.request.entity_id = '';
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.entityInput }).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].name;
        if (entityId != '' || entityId != null) {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
        } else {
          this.vm.request.entity_id = '';
        }
      } else {
        this.vm.request.entity_id = '';
      }
    }
    if (entityIdStart != this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }
}
