import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppLocationService {

  constructor(@Inject(DOCUMENT) private document) {
    console.log(document.location);
  }
  getPort(): string {
    return this.document.location.port;
  }
  getHost(): string {
    return this.document.location.host;
  }
  getOrigin(): string {
    return this.document.location.origin;
  }
}
