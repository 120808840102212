import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { AuthService } from '../../Services/auth.service';
import { StorageService } from '../../Services/storage.service';
import { PermissionsService } from '../../Services/permissions.service';
import { TableDataService } from '../../Services/tableData.service';
import { AppConfigService } from '../../Services/app-config.service';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../../DataModels/response-model';
import { findAllParent, findMenuItem } from '../common/theme-models/utils';
import { MenuItem } from '../common/theme-models/menu.model';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css'],
})
export class SidePanelComponent implements OnInit, AfterViewInit {

  vm: any = {};
  modeOperations: boolean;
  showMessages = false;
  version = environment.appVersion;
  userName = '';
  @Input() hideLogo = false;
  @Input() navClasses: string | undefined;

  menuItems: MenuItem[] = [];
  leftSidebarClass = 'sidebar-enable';
  activeMenuItems: string[] = [];

  constructor(
    private authService: AuthService,
    private storage: StorageService,
    private tableDataService: TableDataService,
    public p: PermissionsService,
    private appConfig: AppConfigService,
  ) {}

  ngOnInit() {

    // do not load side bar id user not logged in
    // if (this.storage.StorageGet('LoggedIn') !== 'true') {
    //   console.log('loggedin from sidebar' + this.storage.StorageGet('LoggedIn'));
    //   return;
    // }

    if (localStorage.getItem('modeOperations') === 'true') {
      this.modeOperations = true;
    } else {
      this.modeOperations = false;
    }

    if (this.storage.StorageGet('company')) {
      this.vm.company = JSON.parse(this.storage.StorageGet('company'));
    }
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
        this.storage.StorageSet('company', JSON.stringify(result));
      });

    const x = this.storage.StorageGet('Permissions');
    // console.log('xperms: ' + x);
    this.p.LoadPermissions(JSON.parse(x), 'header');

  }

  ngAfterViewInit() {
    const leftMenuColor = this.appConfig.leftMenuThemeColor;
    const bodyThemeColor = this.appConfig.bodyThemeColor;
    document.body.setAttribute('data-leftbar-theme', leftMenuColor);
    document.body.setAttribute('data-layout-color', bodyThemeColor);

    setTimeout(() => {
      this.activateMenu();
    });

    // document.body.setAttribute('data-layout-color', leftMenuColor);
  }

  logout() {
    this.authService.logout();
  }

  switchNav() {
    this.modeOperations = !this.modeOperations;
    localStorage.setItem('modeOperations', String(this.modeOperations));
  }

  dropDown(id, selectId = null) {
    // this.removeAllClass('active');
    // this.removeAllClass('show');

    const drop = document.getElementById(id);

    drop.firstElementChild.classList.toggle('collapsed');

    let x = drop.firstElementChild.getAttribute('aria-expanded');
    if (x == 'true') {
      x = 'false';
    } else {
      x = 'true';
    }

    drop.firstElementChild.setAttribute('aria-expanded', x);
    drop.lastElementChild.classList.toggle('show');

    if (selectId != null) {
      const selectDrop = document.getElementById(selectId);
      this.removeAllClass('menuitem-active');
      selectDrop.classList.add('menuitem-active');
      drop.classList.add('menuitem-active');
    }
  }

  removeAllClass(className: any) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  activateMenu(): void {
    const div = document.getElementById('leftside-menu-container');
    let matchingMenuItem = null;

    if (div) {
      const items: any = div.getElementsByClassName('side-nav-link-ref');
      for (let i = 0; i < items.length; ++i) {
        if (window.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }

      if (matchingMenuItem) {
        const mid = matchingMenuItem.getAttribute('data-menu-key');
        const activeMt = findMenuItem(this.menuItems, mid);
        if (activeMt) {
          const matchingObjs = [
            activeMt.key,
            ...findAllParent(this.menuItems, activeMt),
          ];

          this.activeMenuItems = matchingObjs;

          this.menuItems.forEach((menu: MenuItem) => {
            menu.collapsed = !matchingObjs.includes(menu.key!);
          });
        }
      }
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasSubmenu(menu: MenuItem): boolean {
    return menu.children ? true : false;
  }

  /**
   * toggles open menu
   * @param menuItem clicked menuitem
   * @param collapse collpase instance
   */
  toggleMenuItem(menuItem: MenuItem, collapse: NgbCollapse): void {
    collapse.toggle();
    let openMenuItems: string[];
    if (!menuItem.collapsed) {
      openMenuItems = [
        menuItem.key,
        ...findAllParent(this.menuItems, menuItem),
      ];
      this.menuItems.forEach((menu: MenuItem) => {
        if (!openMenuItems.includes(menu.key!)) {
          menu.collapsed = true;
        }
      });
    }
  }
}
