import { Injectable } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';


@Injectable({
  providedIn: 'root'
})
export class GeneratePdfService {

  constructor() { }
  async generatePdf(element: HTMLElement): Promise<Blob> {
    const canvas = await html2canvas(element);

    const pdf = new jspdf.jsPDF({
      unit: 'px',
      format: 'a4'
    });

    const imgData = canvas.toDataURL('image/png');
    console.log('in pdf gen serv');
    console.log(imgData);
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgHeight * pdfWidth) / imgWidth;

    // Ensure image is fully loaded before adding to PDF
    let img = new Image();
    //img.onload = () => {
      // pdf.addImage(imgData, 'PNG', 10, 10, 180, 0);
    pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth-10, pdfHeight);
    // pdf.save('myPDFxxx.pdf');
    //};
    // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    // console.log(pdf.output());
     return pdf.output('blob');
    //return pdf.output('pdfobjectnewwindow');
  }

  // async generatePdf(element: HTMLElement, filename: string): Promise<void> {
  //   const canvas = await html2canvas(element);
  //   const contentDataURL = canvas.toDataURL('image/png');
  //   const pdf = new jspdf.jsPDF('p', 'mm', 'a4');
  //   const imgWidth = 210; // A4 width in mm
  //   const imgHeight = canvas.height * imgWidth / canvas.width;
  //   pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
  //   pdf.save(filename);
  // }
}
