import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-bank-loan-form',
  templateUrl: './bank-loan-form.component.html',
  styleUrls: ['./bank-loan-form.component.css']
})
export class BankLoanFormComponent implements OnInit, AfterViewInit {

  constructor( private titleService: Title) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Bank Loan @@bankLoan:Bank Loan`);
  }
}


