import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EmailModel} from '../../../DataModels/email-model';
import {AppConfigService} from '../../../Services/app-config.service';
import {TableDataService} from '../../../Services/tableData.service';
import {SysUserModel} from '../../../DataModels/sys-user-model';
import {EntityContactListModel} from '../../../DataModels/entity-contact-list-model';
import {ResponseModel} from '../../../DataModels/response-model';
import {NotificationService} from '../../../Services/notification.service';
import {BsToastService} from '../../../Services/bs-toast-service';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.css'],
})
export class SendEmailModalComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  vm: any = {};
  sendEmailForm: UntypedFormGroup;
  submitted = false;
  objEmail = new EmailModel();
  emailsDropdownEntityData: EntityContactListModel[];
  emailsDropdownSysUsrData: SysUserModel[];
  useSysUserTable: boolean;
  useEntityTable: boolean;
  htmlFormattedMsg: string;
  // emailInfo = new EmailModel();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastMsg: BsToastService,
    private appCfg: AppConfigService,
    private tableDataService: TableDataService,
    private notifications: NotificationService,
  ) {
    this.sendEmailForm = this.formBuilder.group(
      {
        emailSourceSelected: new UntypedFormControl('', Validators.required),
        emailSelected: new UntypedFormControl('', Validators.required),
        // have to use a pattern on email, or it will allow a simple a@b with no dot or extension. Bug I guess.
        emailEntered: new UntypedFormControl(), // ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        message: new UntypedFormControl(),
        attachReport: new UntypedFormControl('true'),
      },
      {
        // must match stuff etc.
      }
    );
  }

  @Input() emailObject: EmailModel = new EmailModel();

  emailToSendArray: EmailModel[] = [];

  subscriptions: Subscription = new Subscription();

  ngOnChanges() {
    // console.log('emailsend onchage: ' + JSON.stringify(this.emailObject));
  }

  ngOnInit() {
    // console.log('send mail form called - these value sent: ' + JSON.stringify(this.emailObject));
    this.vm.email = {};
    this.objEmail.includeAttachment = true;

    this.submitted = false;

    if (!this.vm.item) {
      this.vm.item = {};
    }
    if (!this.vm.item.header) {
      this.vm.item.header = {};
    }
    this.onChanges();
  }

  onChanges() {
    let isRequired = false;
    // default list starts on selected so set to required so it inits handlers etc. then flips back
    this.setRequiredAndValidators(true);
    this.subscriptions.add(this.sendEmailForm.controls.emailSourceSelected?.valueChanges.subscribe((val) => {
      // console.log(`Email source is ${val}.`);
      if (val !== 'Entered') {
        this.sendEmailForm.controls.emailEntered.disable();
        this.sendEmailForm.get('emailEntered').setValue(null);
        this.sendEmailForm.get('emailEntered').markAsPristine();
        this.sendEmailForm.get('emailSelected').enable();
        isRequired = false;
      } else if (val === 'Entered') {
        isRequired = true;
        this.sendEmailForm.controls.emailEntered.enable();
        // this.sendEmailForm.get('emailSelected').setValue(null);
        this.sendEmailForm.get('emailEntered').setValue(null);
        this.sendEmailForm.get('emailSelected').disable();
      }
      this.setRequiredAndValidators(isRequired);
    }));
    // checkbox attach
    this.subscriptions.add(this.sendEmailForm.controls.attachReport?.valueChanges.subscribe((val) => {
     // console.log(`IncludeAttachment checkbox val is ${val}.`);
      // this.objEmail.includeAttachment = val;
    }));
  }

  setRequiredAndValidators(required: boolean) {
    if (required) {
      const emailControl = this.sendEmailForm.get('emailEntered');
      emailControl.setValidators([
        Validators.required,
        Validators.pattern('^([\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})(,[\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})*$'),
      ]);
      emailControl.updateValueAndValidity();
    } else {
      const emailControl = this.sendEmailForm.get('emailEntered');
      emailControl.setValidators([
        Validators.pattern('^([\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})(,[\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})*$'),
      ]);
      emailControl.updateValueAndValidity();
    }
  }

  // getter for easy access to form fields
  get f() {
    return this.sendEmailForm.controls;
  }

  onSubmit() {
    // console.log('send mail form called - these value sent: ' + JSON.stringify(this.emailObject));
    this.submitted = true;
    // get values from form fields
    if (this.sendEmailForm.invalid) {
      this.toastMsg.showWarningToast('Form Errors');
      return;
    } else if (!this.emailObject.pdfHtml) {
      this.toastMsg.showWarningToast('No email content to send');
      return;
    } else if (this.sendEmailForm.get('message').value) {
      // format the message with html line breaks when enter occurred in original text
      this.htmlFormattedMsg = this.sendEmailForm.get('message').value;
      this.htmlFormattedMsg = this.htmlFormattedMsg.replace(/\r\n|\r|\n/g, '<br>');
      this.emailObject.body = this.htmlFormattedMsg;
    } else if (this.sendEmailForm.get('message').value === null && !this.emailObject.body) {
        this.emailObject.body = 'Please see the attached document.';
    }

    // load up the obj to pass to email service.

    if (this.sendEmailForm.get('emailEntered').value !== '' && this.sendEmailForm.get('emailSelected').value != null) {
      this.objEmail.toEmail =
        this.sendEmailForm.get('emailSelected').value + ',' + this.sendEmailForm.get('emailEntered').value;
    }

    if (this.sendEmailForm.get('emailSelected').value != null) {
      this.objEmail.toEmail = this.sendEmailForm.get('emailSelected').value;
    }

    if (this.sendEmailForm.get('emailEntered').value !== null) {
      this.objEmail.toEmail = this.sendEmailForm.get('emailEntered').value;
    }

    // these are kind of static so basically hardcoded at this time
    this.objEmail.fromEmail = this.appCfg.SysEmailAddress;

    if (this.emailObject.Subject == undefined || this.emailObject.Subject == '') {
      this.objEmail.Subject = $localize`:Information from AcctXERP @@informationfromAcctXERP:Information from AcctXERP`;
    } else {
      this.objEmail.Subject = this.emailObject.Subject;
    }

    if (this.sendEmailForm.get('message').value === null) {
      this.objEmail.body = '<div><p>' + this.emailObject.body + '</p></div>';
    } else if (this.sendEmailForm.get('message').value !== null) {
      this.objEmail.body = '<div><p>' + this.htmlFormattedMsg + '</p></div>';
    } else {
      this.objEmail.body = this.htmlFormattedMsg;
      // console.log('should be not here - remove when done testing');
      // }
    }

    this.objEmail.header_id = this.emailObject.header_id;

    // spaces got in db somehow - maybe just test data - cleanup to have valid value
    this.objEmail.toEmail = this.objEmail.toEmail.toString().trim();

    if (!this.emailObject.fromName) {
      this.objEmail.fromName = 'AcctXErp';
    } else {
      this.objEmail.fromName = this.emailObject.fromName;
    }

    if (!this.emailObject.activity_message) {
      this.objEmail.activity_message = 'item emailed';
    } else {
      this.objEmail.activity_message = this.emailObject.activity_message;
    }

    this.objEmail.includeAttachment = this.sendEmailForm.get('attachReport').value;

    this.objEmail.pdfHtml = this.emailObject.pdfHtml;

    // TODO - display form values on success for testing purposes
    // console.log(this.objEmail);
    // alert('TEST SUCCESS!! :-)\n\n Turn me off - uncomment line below me to send.\n\n' + JSON.stringify(this.objEmail, null, 4));

    // check various values before sending
    if (!this.objEmail.toEmail) {
      const msg = 'Either no email address has been entered or the system item chosen has no email address stored.';
      this.notifications.warning(msg);
      return;
    } else {
      this.emailToSendArray.push(this.objEmail);
      // console.log(this.objEmail);
      this.tableDataService
        .putTableDataByArray('email/PostByArray', this.emailToSendArray)
        .subscribe((resp: HttpResponse<any>) => {
          if (resp != null) {
          } else {
          }
          this.emailToSendArray = [];
        });
      this.cleanupAfterSend();
    }
  }

  onReset() {
    this.submitted = false;
    this.sendEmailForm.reset();
  }

  loadSelectedSourceEmail() {
    this.useSysUserTable = false;
    this.useEntityTable = false;

    if (this.sendEmailForm.get('emailSourceSelected').value === 'SystemUser') {
      this.tableDataService
        .getTableData('sys_user', '?pageNumber=0&pageSize=999999')
        .subscribe((result: ResponseModel) => {
          this.emailsDropdownSysUsrData = result.Data;
        });
      this.useSysUserTable = true;
    }
    if (this.sendEmailForm.get('emailSourceSelected').value === 'EntityContact') {
      this.tableDataService
        .getTableData('entity_contact_list', 'fulllist?pageNumber=0&pageSize=999999')
        .subscribe((result: ResponseModel) => {
          this.emailsDropdownEntityData = result.Data;
          this.emailsDropdownEntityData = this.emailsDropdownEntityData.sort((a, b) => (a.entity_name > b.entity_name ? 1 : -1));
          // console.log(JSON.stringify(sorted));
        });
      this.useEntityTable = true;
    }
  }

  cleanupAfterSend() {
    this.objEmail = new EmailModel();
    this.htmlFormattedMsg = '';
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.closeButton.nativeElement.click();

  }

}
