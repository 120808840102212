import {Component, Input, OnInit} from '@angular/core';
import { FilterPipe } from 'ngx-filter-pipe';

@Component({
  selector: 'app-shipment-print',
  templateUrl: './shipment-print.component.html',
  styleUrls: ['./shipment-print.component.css']
})
export class ShipmentPrintComponent implements OnInit {

  @Input() vm: any;

  constructor() { }

  ngOnInit() {
  }

}
