import { Component, Input, OnInit } from '@angular/core';
import { TableDataService } from '../../../../../Services/tableData.service';
import { PermissionsService } from '../../../../../Services/permissions.service';
import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountMarginModel } from '../../../../../DataModels/account-margin-model';
import { ConfirmDialogService } from '../../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../../DataModels/response-model';
import { BsToastService } from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-item-quantities',
  templateUrl: './item-quantities.component.html',
  styleUrls: ['./item-quantities.component.css'],
})
export class ItemQuantitiesComponent implements OnInit {
  @Input() itemID: string;
  itemQuantityID = '';
  quantities$: Observable<AccountMarginModel[]>;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private p: PermissionsService,
    private decimal: DecimalPipe,
    private confirmDialogService: ConfirmDialogService
  ) {}

  ngOnInit() {
    this.getQuantities();
  }

  private getQuantities() {
    // this.quantities$ = this.table.getTableData('itemQuantity?item_id=' + this.itemID);
    this.table
      .getTableData('itemQuantity/itemid', '?itemid=' + this.itemID + '&pageNumber=0&pageSize=999999')
      .subscribe((res: ResponseModel) => {
        const data: any = res.Data;
        this.quantities$ = data;
      });
  }

  editItem(id: string) {
    this.itemQuantityID = id;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async parentReloadData() {
    await this.delay(1500);
    this.getQuantities();
  }

  deleteItem(id: string) {
    // alert('Delete clicked');

    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete item re-order? @@areYouSureYouWantToDeleteItemReorder:Are you sure you want to delete item re-order?`,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('itemQuantity', id).subscribe(
          (result) => {
            retMsg = result;
            this.getQuantities();
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            // this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }
}
