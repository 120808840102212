import { Component, OnInit } from '@angular/core';
import {TableDataService} from '../../../Services/tableData.service';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs';
import {PrintService} from '../../../Services/print.service';
import {ResponseModel} from '../../../DataModels/response-model';

@Component({
  selector: 'invoice-print-ar',
  templateUrl: './print-transaction-document.html',
  styleUrls: ['./print-transaction-document.scss']
})
export class PrintTransactionDocument implements OnInit{
  documentId: string;
  companyId: string;

  shipReceive: string;
  soldPurchase: string;

  vm: any = {
    request: {}
  };
  public urlParams: any = [];
  isLoaded = false;

  constructor(
    private tableDataService: TableDataService,
    private route: ActivatedRoute,
    public print: PrintService) {
  }
  ngOnInit(): void {
    // console.log('in print-invoice-ar component');
    this.getData();
  }

  getData() {
    // do nothing with dates right now - get by single item or via report page only for now
    const from = '1980-01-01';
    const to = '2050-12-31';

    this.vm.buttonsDisabled = true;
    this.vm.report_format = true;

    this.urlParams = this.route.snapshot.queryParams;

    this.vm.subLedger = this.urlParams.subLedger;
    this.vm.type = this.urlParams.type;
    this.documentId = this.urlParams.documentId;
    this.companyId = this.urlParams.companyId;

    if (this.urlParams.batch_id) {
      // this.batchRecvdFlag = true;
      this.vm.batch = this.urlParams.batch_id;
      this.vm.batch_id = this.urlParams.batch_id;
      this.vm.subLedger = this.urlParams.subLedger;
    }

    this.vm.request.entity_id = this.urlParams.entity_id;

    this.setDocumentShipRecvSoldPurch();

    const urlParamsJson = {
      fromDate: !this.vm.request.batch_id ? from : '',
      toDate: !this.vm.request.batch_id ? to : '',
      subLedger: this.vm.subLedger,
      entity_id: this.vm.request.entity_id,
      location_id: this.vm.request.location_id,
      company_id: this.vm.request.company_id,
      entity_type_id: this.vm.request.entity_type_id,
      invoice_id: this.documentId ? this.documentId : '',
      batch_id: this.vm.request.batch_id ? this.vm.request.batch_id : '',
    };

    const urlParams: string = this.encodeQueryData(urlParamsJson);
    // console.log('query from invoice print: ' + urlParams);
    // console.log('company passed in was: ' + this.companyId);

    this.tableDataService.getTableData('InvoiceReport', '?' + urlParams)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          this.vm.report = result;
          // console.log(JSON.stringify(this.vm.report));
          if (!this.vm.report.length) {
            // this.notification.showWarningToast('No data matching selection criteria');
            console.log('no data for invoice print');
            return;
          }

          this.tableDataService
            .getTableData('company', this.companyId)
            .pipe(take(1))
            .subscribe({
              next: (result2: any) => {
                this.vm.company = result2;
                // console.log('company data from get data in invoice-email');
                // console.log(this.vm.company);
                this.isLoaded = true;
              },
              error: (err: any) => {
                console.error(err.message);
              },
              complete: () => {
                // do stuff on complete
                this.printScreen();
              },
            });

          this.calcTotals();
          this.vm.showFilters = false;
          this.vm.buttonsDisabled = false;
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {
          // do stuff on complete
        },
      });
  }

  setDocumentShipRecvSoldPurch() {
    if (this.vm.subLedger == 'ap' || this.vm.subLedger == 'ps') {
      this.soldPurchase = 'From';
      this.shipReceive = 'Ship From';
    }

    if (this.vm.subLedger == 'ar'
      || this.vm.subLedger == 'ss'
      || this.vm.subLedger == 'so'
      || this.vm.subLedger == 'quotes'
      || this.vm.subLedger == 'salesquotes'
      || this.vm.subLedger == 'salesorders'
      || this.vm.subLedger == 'po'
      || this.vm.subLedger == 'purchaseorders') {

      this.soldPurchase = 'To';
      this.shipReceive = 'Ship To';
    }
  }

  printScreen() {
    setTimeout(() => {
      window.print();
      window.close();

      // this.isPrinting = false;
      // this.router.navigate([{ outlets: { print: null }}]).then();
    });
  }


  calcTotals() {
    this.vm.reportTotal = 0;
    this.vm.reportSubtotal = 0;
    this.vm.reportTax1Code = '';
    this.vm.reportTax2Code = '';
    this.vm.reportTax3Code = '';
    this.vm.reportTax1Total = 0;
    this.vm.reportTax2Total = 0;
    this.vm.reportTax3Total = 0;
    let reverse = -1;
    if (this.vm.subLedger === 'ap') {
      reverse = 1;
    }

    for (const [x, value] of Object.entries(this.vm.report)) {
      this.vm.reportSubtotal += this.vm.report[x].amount;
      this.vm.reportTotal += this.vm.report[x].amount;
      this.vm.report[x].tax_total_1 = 0;
      this.vm.report[x].tax_total_2 = 0;
      for (const [y, value2] of Object.entries(this.vm.report[x].details)) {
        if (this.vm.reportTax1Code === '') {
          this.vm.reportTax1Code = this.vm.report[x].details[y].tax_1_code;
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.reportTax1Code === this.vm.report[x].details[y].tax_1_code) {
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.reportTax1Code === this.vm.report[x].details[y].tax_2_code) {
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_2;
        }
        if (this.vm.reportTax2Code === '') {
          this.vm.reportTax2Code = this.vm.report[x].details[y].tax_2_code;
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.reportTax2Code === this.vm.report[x].details[y].tax_2_code) {
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.reportTax2Code === this.vm.report[x].details[y].tax_1_code) {
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_1;
        }

        if (!this.vm.report[x].tax_code_1) {
          this.vm.report[x].tax_code_1 = this.vm.report[x].details[y].tax_1_code;
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.report[x].tax_code_1 === this.vm.report[x].details[y].tax_1_code) {
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.report[x].tax_code_1 === this.vm.report[x].details[y].tax_2_code) {
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_2;
        }
        if (!this.vm.report[x].tax_code_2) {
          this.vm.report[x].tax_code_2 = this.vm.report[x].details[y].tax_2_code;
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.report[x].tax_code_2 === this.vm.report[x].details[y].tax_2_code) {
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.report[x].tax_code_2 === this.vm.report[x].details[y].tax_1_code) {
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_1;
        }
        this.vm.reportTotal += this.vm.report[x].details[y].tax_amount_1 + this.vm.report[x].details[y].tax_amount_2;
      }
    }
  }
  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

}
