import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TableDataService } from '../../../Services/tableData.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { BsToastService } from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css'],
})
export class EmployeeFormComponent implements OnInit, AfterViewInit {
  EmployeeForm: UntypedFormGroup;
  vm: any = {
    title: 'Employee Form',
    item: {},
    buttonsDisabled: false,
    Preferences: [],
    permissions: [],
    mainListPage: '/payroll/employee-list',
    formInitiated: false,
    defaultItem: [],
  };

  submitted = false;
  employeeId = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private decimal: DecimalPipe,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    this.formInit();
    this.dataInit();
  }

  dataInit() {
    this.route.params.subscribe((params) => {
      this.employeeId = params.id;
      if (this.employeeId !== undefined) {
        this.tableDataService.getTableData('employee', this.employeeId).subscribe((result: any[]) => {
          this.vm.item = result;
          this.vm.formInitiated = true;

          this.vm.item.start_date = this.datePipe.transform(this.vm.item.start_date, 'MM/dd/yyyy');
          this.vm.item.termination_date = this.datePipe.transform(this.vm.item.termination_date, 'MM/dd/yyyy');

          this.setValues();
          this.vm.defaultItem = result;
          // this.localStorage.StorageSet('formDataEmployee', JSON.stringify(this.vm.item));
        });
      }
    });
  }

  get f() {
    return this.EmployeeForm.controls;
  }

  formInit() {
    this.EmployeeForm = this.formBuilder.group({
      item_name: new UntypedFormControl('', Validators.required),
      item_sin: new UntypedFormControl('', Validators.required),
      item_start_date: new UntypedFormControl(new Date(), Validators.required),
      item_termination_date: new UntypedFormControl(''),
      item_address_1: new UntypedFormControl(''),
      item_address_2: new UntypedFormControl(''),
      item_city: new UntypedFormControl(''),
      item_province: new UntypedFormControl(''),
      item_postal_code: new UntypedFormControl(''),
      item_phone: new UntypedFormControl(''),
      item_email: new UntypedFormControl(''),
      item_active: new UntypedFormControl(true),
    });
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  setValues() {
    this.EmployeeForm.controls.item_name.setValue(this.vm.item.name);
    this.EmployeeForm.controls.item_sin.setValue(this.vm.item.sin);
    this.EmployeeForm.controls.item_start_date.setValue(this.vm.item.start_date);
    this.EmployeeForm.controls.item_termination_date.setValue(this.vm.item.termination_date);
    this.EmployeeForm.controls.item_address_1.setValue(this.vm.item.address_1);
    this.EmployeeForm.controls.item_address_2.setValue(this.vm.item.address_2);
    this.EmployeeForm.controls.item_city.setValue(this.vm.item.city);
    this.EmployeeForm.controls.item_province.setValue(this.vm.item.province);
    this.EmployeeForm.controls.item_postal_code.setValue(this.vm.item.postal_code);
    this.EmployeeForm.controls.item_phone.setValue(this.vm.item.phone);
    this.EmployeeForm.controls.item_email.setValue(this.vm.item.email);
    this.EmployeeForm.controls.item_active.setValue(this.vm.item.active);
  }

  setupValuesForSave() {
    this.vm.item.name = this.EmployeeForm.controls.item_name.value;
    this.vm.item.sin = this.EmployeeForm.controls.item_sin.value;
    // this.vm.item.start_date = this.datePipe.transform(this.EmployeeForm.controls.item_start_date.value);
    this.vm.item.start_date = new Date(this.EmployeeForm.controls.item_start_date.value).toISOString();
    this.vm.item.termination_date = this.datePipe.transform(this.EmployeeForm.controls.item_termination_date.value);
    this.vm.item.address_1 = this.EmployeeForm.controls.item_address_1.value;
    this.vm.item.address_2 = this.EmployeeForm.controls.item_address_2.value;
    this.vm.item.city = this.EmployeeForm.controls.item_city.value;
    this.vm.item.province = this.EmployeeForm.controls.item_province.value;
    this.vm.item.postal_code = this.EmployeeForm.controls.item_postal_code.value;
    this.vm.item.phone = this.EmployeeForm.controls.item_phone.value;
    this.vm.item.email = this.EmployeeForm.controls.item_email.value;
    this.vm.item.active = this.EmployeeForm.controls.item_active.value;
  }

  capitalizeFirstLetter(str): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  save() {
    console.log('save');
    this.submitted = true;
    this.vm.buttonsDisabled = true;

    if (this.EmployeeForm.invalid) {
      this.notification.showWarningToast($localize`: Form Errors! @@formerrors: Form Errors!`);
      this.vm.buttonsDisabled = false;
      return false;
    }

    this.setupValuesForSave();

    if (this.vm.item.id === undefined) {
      this.tableDataService.post('employee', this.vm.item).subscribe(
        (item: any) => {
          this.router.navigateByUrl(this.vm.mainListPage).then();
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          if (error.error.Message !== undefined) {
            console.log(error.error.Message);
          } else {
            console.log(error);
          }
        }
      );
    } else {
      this.tableDataService
        .patch('employee', this.vm.item.id, this.vm.item)
        .then((item: any) => {
          this.router.navigateByUrl(this.vm.mainListPage).then();
        })
        .catch((error: { description: any[] }) => {
          this.vm.buttonsDisabled = false;
          if (error.description === undefined) {
            this.router.navigateByUrl(this.vm.mainListPage).then();
          } else {
            console.log(error.description);
          }
        });
    }
  }
}
