import {Component, Input, OnInit} from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {PermissionsService} from '../../../../Services/permissions.service';
import {HttpErrorResponse} from '@angular/common/http';
import {EntityAddressModel} from '../../../../DataModels/entity-address-model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {EntityModel} from '../../../../DataModels/entity-model';
import {NotificationService} from '../../../../Services/notification.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';

@Component({
  selector: 'app-entity-address',
  templateUrl: './entity-address.component.html',
  styleUrls: ['./entity-address.component.css']
})


export class EntityAddressComponent implements OnInit {

  @Input() entityID: string;
  entityAddressForm: UntypedFormGroup;
  addressList: Array<object>;
  addresses$: Observable<EntityAddressModel[]>;
  addressCount = 0;


  buttonsDisabled = false;
  editAddress = false;
  addAddress = false;
  formAddress = true;
  showAddressMerge = false;
  showAddressMergeButton = true;
  addressItem = new EntityAddressModel();
  deleteId = '';

  constructor(
              private table: TableDataService,
              public p: PermissionsService,
              private formBuilder: UntypedFormBuilder,
              private notification: NotificationService,
              private confirmDialogService: ConfirmDialogService) {
    this.createForm();
  }

  // this.form = this.formBuilder.group({
  //   acceptTerms: [false, Validators.requiredTrue]
  // });

  createForm() {
    this.entityAddressForm = this.formBuilder.group({
      id:  new UntypedFormControl(),
      name: new UntypedFormControl(null),
      entity_id: new UntypedFormControl(null),
      billing_address: new UntypedFormControl(true),
      primary_address: new UntypedFormControl(true),
      address_1: new UntypedFormControl(null),
      address_2: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      state: new UntypedFormControl(null),
      zip: new UntypedFormControl(null),
      phone: new UntypedFormControl(null),
      country: new UntypedFormControl(null),
      instructions: new UntypedFormControl(''),
      active: new UntypedFormControl(''),
      // merge_to_address_id: new FormControl(''),
    });
  }

  ngOnInit() {
    this.loadEntityAddresses();

  }

  private loadEntityAddresses() {
    // this.addressItem = null;
    // this.addressItem = new EntityAddressModel();
    this.table.getApiListData('entity_address_list', this.entityID + '?pageNumber=0&pageSize=999999' ).subscribe((result: ResponseModel) => {
        this.addressList = result.Data;
        this.addressCount = result.Data.length;
        // console.log('adds: ' + JSON.stringify(result.Data));
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
      });
  }

  getAddress(id: any) {

    this.deleteId = id;

    this.table.getTableData('entity_address', id ).subscribe((result) => {

      this.addressItem = result;
      // console.log(JSON.stringify(result));
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
      this.entityAddressForm.patchValue(this.addressItem);
      this.addAddress = true;
      this.formAddress = true;
    });

    // this.isThisUsed(id, 'bill_address_id', 't_header').then();
  }

  saveNewAddress() {
    //
  }

  async isThisUsed(idToCheck: string, columnToCheck: string, tableToCheck: string) {

    this.buttonsDisabled = false;

    let paramString = '';
    paramString += 'id=' + idToCheck;
    paramString += '&';
    paramString += 'columnName=' + columnToCheck;
    paramString += '&';
    paramString += 'tableName=' + tableToCheck;

    this.table.getTableDataWithParams('in_use', paramString).subscribe(
      result => {
        this.buttonsDisabled = (result.toString() == 'true');
        // console.log('button:' + this.buttonsDisabled);
        // console.log('res:' + result);
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error validating usage of item @@errorValidatingUsageOfItem:Error validating usage of item.`);
        console.log(error);
      },
      () => {
        // ending stuff here
      });
  }


  itemClose() {
    this.addressItem = null;
    this.addressItem = new EntityAddressModel();
    /// this.entityAddressForm.patchValue(this.addressItem);
    this.entityAddressForm.reset();
    this.loadEntityAddresses();
  }

  onSave() {
    // only need this for the merge and it screws up posting model if included in normal post patch
    // delete this.entityAddressForm.value.merge_to_address_id;

    this.addressItem = Object.assign({}, this.entityAddressForm.value);

    // alert('json: ' + JSON.stringify(this.addressItem));

    if (this.addressItem.id) {
      if (this.entityAddressForm.invalid === false) {
        this.table.patch('entity_address', this.addressItem.id, this.addressItem).then();
      }
      // this.notification.success($localize`:Saved @@saved:Saved`);
    } else {

      this.addressItem.entity_id = this.entityID;

      this.table.postWithPromise('entity_address', this.addressItem).then();
      // this.notification.success($localize`:Saved @@saved:Saved`);
    }
    this.addressItem = null;
    this.addressItem = new EntityAddressModel();
    this.loadEntityAddresses();
  }

  saveAddress() {
    this.onSave();
  }

  onDelete() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete address? @@areYouSureYouWantToDeleteAddress:Are you sure you want to delete address`, this.addressItem.name];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('entity_address', this.deleteId).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          (success: HttpErrorResponse) => {
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
            this.itemClose();
          });
      }, () => {
        this.notification.success($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }

  setCheck(event: any) {
    if (event.target.id === 'active') {
      // alert('in active');
      this.addressItem.active = event.target.checked;
    } else if (event.target.id === 'primary_address') {
      // alert('in primary');
      this.addressItem.primary_address = event.target.checked;
    } else if (event.target.id === 'billing_address') {
      // alert('in billing');
      this.addressItem.billing_address = event.target.checked;
    } else if (event.target.id === 'activeEdit') {
      // alert('in active edit');
      this.addressItem.billing_address = event.target.checked;
    }
  }
  newAddress() {
    this.addressItem = null;
    this.addressItem = new EntityAddressModel();
    this.addAddress = true;
    this.addressItem.billing_address = true;
    this.addressItem.primary_address = true;
    this.addressItem.active = true;
  }

  mergeAddress() {

    const urlParamsJson = {
      merge_to_address_id: this.addressItem.id,
      delete_address_id: this.entityAddressForm.get('merge_to_address_id').value
    };

    if (this.addressItem.id === this.entityAddressForm.get('merge_to_address_id').value){
      this.notification.warning('Cannot merge to the same address');
    } else {
      const Param: string = this.encodeQueryData(urlParamsJson);
      // console.log(Param);
      this.table.getTableData('MergeEntity/address', '/MergeAddress?' + Param).subscribe(result => {
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {

        });
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
      else if (data[d] != null && data[d] == '') {
        ret.push(encodeURIComponent(d) + '=');
      }
    }
    return ret.join('&');
  }

  loadLookupAddresses() {
    // this.table.getApiListData('entity_address_list', this.entityID + '&pageNumber=0&pageSize=999999').subscribe(
    //   (result: ResponseModel) => {
    //     const data: any = result.Data;
    //     this.addresses$ = data;
    // });

    this.table.getTableData('entity_address_list', this.entityID).subscribe(result => {
      this.addresses$ = result.Data;
    });

  }
}




