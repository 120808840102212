import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-modal-select-item-with-filter',
  templateUrl: './modal-select-item-with-filter.component.html',
  styleUrls: ['./modal-select-item-with-filter.component.css']
})
export class ModalSelectItemWithFilterComponent implements OnInit {

  @Input() vm: any;
  @Output() selectItemModalEmit = new EventEmitter<string>();
  @Output() updateInventoryItemEmit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
    this.vm.inventoryItemFilter = { search_string: '' };
    this.vm.startInventoryItem = 0;
  }

  selectItemModal(item){
    this.selectItemModalEmit.emit(item);
  }

  updateInventoryItem(){
    this.updateInventoryItemEmit.emit();
  }

}
