import { Component, OnInit } from '@angular/core';
import { FilterPipe } from 'ngx-filter-pipe';
import { TableDataService } from '../../../Services/tableData.service';
import { StorageService } from '../../../Services/storage.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ArraySortPipe } from '../../Reports/transaction-reports/orderBy';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../Services/email-pass-data-service.service';
import { ChangeDetectorRef } from '@angular/core';
import { BsToastService } from '../../../Services/bs-toast-service';
import { ViewportScroller } from '@angular/common';
import { ToWords } from '../../../Services/ToWordsService/ToWords';
import { HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { RoutingTrackerService} from '../../../Services/routing-tracker.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css'],
})
export class PaymentFormComponent implements OnInit {
  // paymentsModel = new PaymentsModel();

  vm: any = {};

  paymentId;
  batchId;
  action;
  rptName = '';
  showDelete;
  isBankLoaded = false;
  type = '';

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: true,
    email: true,
    print: true,
    saveClose: true,
  };
  showBatches: boolean;
  submitted = false;
  routedFrom = '';
  words = new ToWords();
  invoicesStillNotPaidFound = false;
  testInvoices = [];
  previousPaidInvoices = [];
  batchType: any;

  buttonsDisabled = false;
  disablePrintButton = false;
  disableEmailButton = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private confirmDialogService: ConfirmDialogService,
    private emailData: EmailPassDataServiceService,
    private changeDetect: ChangeDetectorRef,
    private vps: ViewportScroller,
    public prevUrl: RoutingTrackerService
  ) {


    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  ngOnInit() {

    this.vm.invoices = [];
    this.vm.paymentType = [];
    this.vm.currency = [];
    this.vm.entity = [];
    this.vm.company = [];
    this.vm.bank = [];
    this.vm.terms = [];
    this.vm.header = [];
    this.vm.item = [];
    // this.vm.entityFilter = {name: ''};
    this.vm.entityFilter = {};
    this.vm.applied = [];
    this.vm.Empty = 0;
    this.vm.printLayout = { checkStock: {} };
    this.vm.company = [];
    this.vm.batches = [];
    this.vm.buttonsDisabled = false;
    this.vm.isLoaded = false;
    this.vm.entityIsLoaded = false;
    this.vm.companyIsLoaded = false;
    this.vm.paymentTypeIsLoaded = false;
    this.vm.currencyIsLoaded = false;

    this.buttonsDisabled = false;
    this.disablePrintButton = false;
    this.disableEmailButton = false;

    this.vm.item.batch = false;
    this.vm.item.header = {
      printed: false,
      isLocked: false
    };
    this.vm.item.header.company_id = '';
    this.vm.item.header.printed = '';
    this.vm.item.batch = {
      locked: false
    };
    // this.vm.item.batch.locked = false;
    this.route.queryParamMap.subscribe(async (params: ParamMap) => {
      this.paymentId = params.get('paymentId');
      this.batchId = params.get('batchId');
      this.action = params.get('action');

      // console.log('loading lookups');
      await this.loadLookups().then();
      // console.log('back from loading lookups');

      if (this.paymentId !== 'newAP' && this.paymentId !== 'newAR') {
        this.getPaymentEditData();
      } else {
        this.init();
      }
    });
  }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  isLocked(): boolean {
   if(this.vm.item.header.locked !== undefined ){
     return !!(this.vm.item.header.locked || this.vm.header.printed)

   // } else {
   //    return false;
   }
  }

  isPrinted(): boolean {
    if(this.vm.item.header.printed !== undefined) {
      return this.vm.item.header.printed;
    // } else {
    //  return false;
    }
  }

  init() {
    // this.vm.isLoaded = false;
    this.submitted = false;
    if (this.paymentId === 'newAP' || this.paymentId === 'newAR') {
      // NEW ENTRY
      this.vm.item = {};
      this.vm.item.batch = {};
      this.vm.item.batch.description = $localize`:Payment Batch @@paymentBatch:Payment Batch`;
      this.vm.item.header = {
        locked: false,
        currencyRate: '1',
        header_date: new Date()
      };
      // this.vm.item.header.locked = false;
      // this.vm.item.header.currency_rate = '1';
      // this.vm.item.header.header_date = new Date();
      this.vm.item.detail = [{ id: 1, amount: '' }];
      this.vm.applied = this.vm.item.applied = [];
      if (this.vm.bank.length === 1) {
        this.vm.item.detail[0].account_id = this.vm.bank[0].account_id;
      }
      if (this.paymentId === 'newAP') {
        this.vm.item.batch.type_id = '5';
        this.vm.subLedger = 'ap';
        this.vm.paymentTypeName = $localize`:Payable @@payable:Payable`;
        this.titleService.setTitle($localize`:Payable @@payable:Payable`);
        this.rptName = 'Payable';
      } else {
        this.vm.item.batch.type_id = '3';
        this.vm.subLedger = 'ar';
        this.vm.paymentTypeName = $localize`:Receivable @@receivable:Receivable`;
        this.titleService.setTitle($localize`:Receivable @@receivable:Receivable`);
        this.rptName = 'Receivable';
      }
      if (this.batchId) {
        this.vm.batchId = this.vm.item.batch.id = this.batchId;
      } else {
        this.vm.item.batch.description = 'Batch';
      }
      this.spliceEntities();
      if (this.vm.Preferences.default_company_id) {
        this.vm.item.header.company_id = this.vm.Preferences.default_company_id;
      }
    } else {
      // EDIT ENTRY
      if (this.vm.item.batch.type_id === 3) {
        this.vm.subLedger = 'ar';
        this.vm.paymentTypeName = $localize`:Receivable @@receivable:Receivable`;
        this.titleService.setTitle(
          $localize`:Receivable Payment @@receivablePayment:Receivable Payment` + ' - ' + this.vm.item.header.number
        );
        this.rptName = 'Receivable';
      } else {
        this.vm.subLedger = 'ap';
        this.vm.paymentTypeName = $localize`:Payable @@payable:Payable`;
        this.titleService.setTitle(
          $localize`:Payable Payment @@payablePayment:Payable Payment` + ' - ' + this.vm.item.header.number
        );
        this.rptName = 'Payable';
      }
      this.spliceEntities();

      // this.vm.emailSend.ToEmail = this.FilterObject(this.vm.entity, {id: this.vm.item.header.entity_id}, true)[0].email;

      this.vm.item.header.header_date = this.dateFilter.GetDateFormat(this.vm.item.header.header_date);
      // tempDate.substring(5, 7) + '/' + tempDate.substring(8, 10) + '/' + tempDate.substring(0, 4);

      this.vm.clearedDate = '';
      for (const obj of this.vm.item.detail) {
        obj.amount = Number(obj.amount);
        if (obj.cleared_date) {
          this.vm.clearedDate = obj.cleared_date;
        }
      }
      for (let x = this.vm.item.detail.length - 1; x > -1; x--) {
        if (this.vm.item.detail[x].control_line === 1) {
          this.vm.item.detail.splice(x, 1);
        }
      }
      this.vm.manualAmount = true;
      for (const obj of this.vm.item.applied) {
        obj.payment_applied_total -= obj.applied_amount;
      }
      this.vm.applied = this.vm.item.applied;
      if (this.vm.item.batch.locked !== '1') {
        // stop previous subscribe here
        let paramString = '';
        paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
        paramString += '&company_id=' + (this.vm.item.header.company_id ? this.vm.item.header.company_id : '');
        paramString += '&toDate=' + (this.vm.item.header.header_date ? this.vm.item.header.header_date : '');
        paramString += '&batch_type_id_in=2,4';
        this.tableDataService.getTableData('entry', '?' + paramString).subscribe((result: any) => {
          this.vm.invoices = result.Data;
          // this.vm.this.vm.buttonsDisabled = result.Data.locked;
          this.removeDuplicates();
          this.reverseHeaderAmount();
          this.calcTotals();
        });
      } else {
        this.vm.invoices = [];
        this.removeDuplicates();
        this.reverseHeaderAmount();
        this.calcTotals();
      }
    }

    // set values passed to batch change modal
    if (this.vm.subLedger === 'ap') {
     this.batchType = '5';
    } else {
      this.batchType = '3';
    }

    if (this.vm.bank.length === 1) {
      this.vm.item.header.bank_id = this.vm.bank[0].id;
    }

    if (this.vm.company.length === 1) {
      this.vm.item.header.company_id = this.vm.company[0].id;
    }

    if (this.vm.currency.length === 1) {
      this.vm.item.header.currency_id = this.vm.currency[0].id;
    }
    if (this.vm.paymentType.length === 1) {
      this.vm.item.header.payment_type_id = this.vm.paymentType[0].id;
    }

    if (this.action === 'print' && this.vm.item.header.entity_id) {
      let paramStrg = '';
      paramStrg += 'billing_address=1';
      paramStrg += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

      this.tableDataService.getTableData('entity_address/addresses', '?' + paramStrg).subscribe((result: any) => {
        this.vm.entityAddress = result.Data;
        this.print();
      });
    } else if (this.action === 'email') {
      this.email();
    }
    this.changeDetect.detectChanges();
    this.vm.isLoaded = true;
  }

  async loadEntity() {
    // console.log('calling api');
    const res = await lastValueFrom(
      this.tableDataService.getApiListData('entity', '?state=active&pageNumber=0&pageSize=999999').pipe(take(1))
    );
    this.vm.entity = res.Data;
    if (this.vm.entity.length > 0) {
      // console.log('setting');
      this.vm.entityIsLoaded = true;
    }
    // console.log('returning');
  }

  async loadLookups() {
    // this.vm.isLoaded = false;
    if (this.paymentId === 'newAP') {
      this.vm.item = { batchTypeId: '5' };
    } else if (this.paymentId === 'newAR') {
      this.vm.item = { batchTypeId: '3' };
    }
    this.vm.buttonsDisabled = false;
    this.vm.subLedger = '';
    this.vm.alert = {};
    this.vm.alert.message = [];
    this.vm.emailSend = {};
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences', ''));

    if (
      !this.vm.permissions.EditReceivablePayments &&
      !this.vm.permissions.ViewReceivablePayments &&
      !this.vm.permissions.EditPayablePayments &&
      !this.vm.permissions.ViewPayablePayments &&
      !this.vm.permissions.Admin
    ) {
      document.location.href = '/';
    }
    // wait until entity comes back to many depnedents will error is not here.
    // console.log('loading entity');
    await this.loadEntity().then();
    // console.log('back from loading entity');

    this.tableDataService
      .getApiListData('bank', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: any) => {
        this.vm.bank = result.Data;
        this.isBankLoaded = true;
      });

    this.tableDataService
      .getApiListData('company', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: any) => {
        this.vm.company = result.Data;
        this.vm.companyIsLoaded = true;
      });

    this.tableDataService
      .getApiListData('currency', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: any) => {
        this.vm.currency = result.Data;
        this.vm.currencyIsLoaded = true;
      });

    this.tableDataService
      .getApiListData('check_stock', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: any) => {
        this.vm.checkStock = result.Data;
      });

    this.tableDataService
      .getApiListData('payment_type', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: any) => {
        this.vm.paymentType = result.Data;
        this.vm.paymentTypeIsLoaded = true;
      });

    this.tableDataService
      .getApiListData('entity_type', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        // const data: any = result.Data;
        this.vm.entity_type = result.Data;
      });

    this.tableDataService
      .getApiListData('term/complete', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        // const data: any = result.Data;
        this.vm.terms = result.Data;
      });

    // todo ???
    this.vm.getInvoices = this.vm.manualAmount = false;

    // todo ?????
    this.vm.startEntity = this.vm.paymentSubTotal = this.vm.paymentTaxTotal = this.vm.paymentPaymentTotal = 0;
    this.vm.item = {
      header: {},
      batch: {},
      detail: [{ id: 1, amount: '' }],
    };
    this.vm.isLoaded = true;
  }

  getPaymentEditData() {
    this.tableDataService.getTableData('entry', this.paymentId).subscribe((result: any) => {
      this.vm.item = result;
      // this.paymentsModel = result;
      this.vm.buttonsDisabled = result.locked;
      // this.setDueDateByTerm(this.vm.item.header.entity_id);
      this.filterEntity();
      this.init();
      this.vm.isLoaded = true;
      this.submitted = true;
    });
  }

  filterEntity() {
    if (this.vm.item.header.entity_id) {
      const entityCheck = this.vm.entity.find((x) => x.id === this.vm.item.header.entity_id);
      if (entityCheck) {
        this.vm.item.header.entityInput = entityCheck.name;

        let paramStr = '';
        paramStr += 'billing_address=1';
        paramStr += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
        this.tableDataService.getTableData('entity_address/addresses', '?' + paramStr).subscribe((result: any) => {
          this.vm.entityAddress = result.Data;
          this.vm.buttonsDisabled = result.locked;
        });
      } else {
        this.vm.buttonsDisabled = false;
        // this.vm.item.header.entityInput = $localize`:Entity Not found @@entityNotfound:Entity Not found`;
        this.notification.showWarningToast(
          $localize`:The existing entity is no longer valid, please select another entity. @@theexistingentityisnolongervalidpleaseselectanotherentity:The existing entity is no longer valid, please select another entity.` +
            '  '
        );
        this.vm.entityError = true;
        this.vm.item.header.entity_id = '';
      }
    }
  }

  navigateTo(id, print = false) {
    this.tableDataService.getTableData('entry/' + id).subscribe((result: any[]) => {
      // this.localStorage.StorageSet('paymentItem', JSON.stringify(result));
      this.router.navigateByUrl('/transactions/payment/form?paymentId=' + id + '&batchId=').then();
      if (print) {
        this.router
          .navigate(['/transactions/payment/form'], { queryParams: { paymentId: id, action: 'print' } })
          .then();
      } else {
        this.router.navigate(['/transactions/payment/form'], { queryParams: { paymentId: id } }).then();
      }
    });
  }

  getInvoicesEntity() {
    if (this.vm.item.header.entity_id && this.vm.item.header.company_id && this.vm.item.header.header_date) {
      this.vm.datetemp = this.dateFilter.GetDateFormat(this.vm.item.header.header_date);
      let paramString = '';
      paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
      paramString += '&company_id=' + (this.vm.item.header.company_id ? this.vm.item.header.company_id : '');
      paramString += '&toDate=' + (this.vm.datetemp ? this.vm.datetemp : '');
      paramString += '&batch_type_id_in=' + (this.vm.subLedger === 'ap' ? 4 : 2);

      this.tableDataService.getTableData('entry/invoices', '?' + paramString).subscribe((result) => {
        this.vm.invoices = result;
        if (this.vm.invoices <= 0) {
          this.notification.showWarningToast('No open invoices found.', 'Warning');
        }

        this.reverseHeaderAmount();
        this.calcTotals();
      });
    } else {
      this.notification.showWarningToast(
        $localize`:Entity, date, and company are required @@entitydateandcompanyarerequired:Entity, date, and company are required`
      );
    }
  }

  deleteInvoiceRow(rowIndex) {
    this.vm.invoices.splice(rowIndex, 1);
  }

  deleteAppliedRow(rowIndex) {
    this.vm.applied.splice(rowIndex, 1);
  }

  clearPriorSelections() {
    this.vm.item.header.company_id = '';
    this.vm.applied = [];
    this.vm.invoices = [];
  }

  async getInvoicesStillNotPaid(): Promise<any> {
    this.vm.datetemp = this.dateFilter.GetDateFormat(this.vm.item.header.header_date);
    let paramString = '';
    paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
    paramString += '&company_id=' + (this.vm.item.header.company_id ? this.vm.item.header.company_id : '');
    paramString += '&toDate='; // + (this.vm.datetemp ? this.vm.datetemp : '');
    paramString += '&batch_type_id_in=' + (this.vm.subLedger === 'ap' ? 4 : 2);
    paramString += '&payment_id=' + (this.vm.item.header.id ? this.vm.item.header.id : '');

    // console.log(paramString);
    const res = await lastValueFrom(
      this.tableDataService.getTableData('entry/invoices', '?' + paramString).pipe(take(1))
    );
    this.testInvoices = res;
    // console.log('invoice return:' + JSON.stringify(this.testInvoices));

    const testTF = await this.checkForSelectedInvoices();

    return this.invoicesStillNotPaidFound;
  }

  async checkForSelectedInvoices() {
    if (!this.testInvoices) {
      this.invoicesStillNotPaidFound = false;
      return;
    }

    this.invoicesStillNotPaidFound = true;
    this.previousPaidInvoices = [];
    if (this.vm.item.applied.length) {
      await this.vm.item.applied.forEach((x) => {
        // check applied - this is items saved to this payment already
        if (x.applied_header_id) {
          // console.log('applied array to check: ' + JSON.stringify(this.vm.item.applied));
          // console.log('test applied value: ' + x.applied_header_id);

          this.testInvoices.find((y) => {
            if (x.applied_header_id === y.id) {
              // console.log('found applied value: ' + x.applied_header_id);
            } else {
              // console.log('IN applied ELSE');
              this.invoicesStillNotPaidFound = false;
              this.previousPaidInvoices.push(x.number);
              // return;
            }
          });
        }
      });
    }
    // check invoices too - new items added by Get Invoices but not yet saved to payment
    if (this.vm.invoices.length) {
      await this.vm.invoices.forEach((z) => {
        if (z.id) {
          // console.log('test invoices array from API: ' + JSON.stringify(this.testInvoices));
          // console.log('test invoices value: ' + z.id);
          const value = this.testInvoices.find((y) => {
            if (z.id === y.id) {
              // console.log('found invoices value: ' + z.id);
            } else {
              // console.log('IN invoices ELSE');
              this.invoicesStillNotPaidFound = false;
              this.previousPaidInvoices.push(y.number);
            }
          });
        }

      });
    }
    // the routines above cn hit t - f - t but all f pushed to array therefore if array have anything f was hit
    if (this.previousPaidInvoices.length) {
      // console.log('prev paid: ' + JSON.stringify(this.previousPaidInvoices));
      this.invoicesStillNotPaidFound = false;
    }
    // console.log('all invoices on payment are unpaid: ' + this.invoicesStillNotPaidFound);
    if (!this.invoicesStillNotPaidFound) {
      this.notification.showWarningToast(
        $localize`:Some invoices have been paid since selection - reselect @@someinvoiceshavebeenpaid:Some invoices have been paid since selection: ` +
          this.previousPaidInvoices.toString()
      );
    }

    // console.log('retuning: ' + this.invoicesStillNotPaidFound);
    return this.invoicesStillNotPaidFound;
  }

  reverseHeaderAmount() {
    for (const obj of this.vm.applied) {
      if (obj.account_id.toLowerCase() === 'ap') {
        if (this.vm.subLedger === 'ap') {
          obj.payment_applied_total = Number(obj.payment_applied_total);
          obj.detail_total = Number(obj.detail_total);
        }
        if (this.vm.subLedger === 'ar') {
          obj.payment_applied_total = 0 - Number(obj.payment_applied_total);
          obj.detail_total = 0 - Number(obj.detail_total);
        }
      }
      if (obj.account_id.toLowerCase() === 'ar') {
        if (this.vm.subLedger === 'ap') {
          obj.payment_applied_total = 0 - Number(obj.payment_applied_total);
          obj.detail_total = 0 - Number(obj.detail_total);
        }
        if (this.vm.subLedger === 'ar') {
          obj.payment_applied_total = Number(obj.payment_applied_total);
          obj.detail_total = Number(obj.detail_total);
        }
      }
    }
    for (const obj of this.vm.invoices) {
      if (obj.account_id.toLowerCase() === 'ap') {
        if (this.vm.subLedger === 'ap') {
          obj.payment_applied_total = 0 - Number(obj.payment_applied_total);
          obj.detail_total = Number(obj.detail_total);
        } else if (this.vm.subLedger === 'ar') {
          obj.payment_applied_total = Number(obj.payment_applied_total);
          obj.detail_total = 0 - Number(obj.detail_total);
        }
      }
      if (obj.account_id.toLowerCase() === 'ar') {
        if (this.vm.subLedger === 'ap') {
          obj.payment_applied_total = Number(obj.payment_applied_total);
          obj.detail_total = Number(obj.detail_total);
        } else if (this.vm.subLedger === 'ar') {
          obj.payment_applied_total = Number(obj.payment_applied_total);
          obj.detail_total = Number(obj.detail_total);
        }
      }
    }
  }

  // ENTITIES

  spliceEntities() {
    for (let y = this.vm.entity.length - 1; y > -1; y--) {
      if (
        this.vm.subLedger === 'ar' &&
        this.vm.entity[y].receivable === 0 &&
        (this.vm.entity[y].id !== this.vm.item.header.entity_id || !this.vm.item.header.entity_id)
      ) {
        this.vm.entity.splice(y, 1);
      } else if (
        this.vm.subLedger === 'ap' &&
        this.vm.entity[y].payable === 0 &&
        (this.vm.entity[y].id !== this.vm.item.header.entity_id || !this.vm.item.header.entity_id)
      ) {
        this.vm.entity.splice(y, 1);
      }
    }
  }

  updateEntity() {
    this.vm.startEntityName = 0;

    this.tableDataService
      .getApiListData('entity', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        // const data: any = result.Data;
        this.vm.entity = result.Data;
        this.spliceEntities();
      });
  }

  selectEntityModal(entityName) {
    this.clearPriorSelections();
    this.vm.item.header.entity_id = '';
    for (const obj of this.vm.entity) {
      if (obj.name === entityName) {
        this.vm.item.header.entityInput = entityName;
        this.vm.item.header.entity_id = obj.id;

        let paramString = '';
        paramString += 'billing_address=1';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

        this.tableDataService.getTableData('entity_address/addresses', '?' + paramString).subscribe((result: any) => {
          this.vm.entityAddress = result.Data;
          // console.log(result);
          if (!this.vm.entityAddress.length) {
            this.notification.showWarningToast(
              'No Billing Address. Select an address as Billing Address before continuing'
            );
            this.vm.noBillToError = true;
            return;
          } else {
            this.vm.noBillToError = false;
            const entityID = this.FilterObject(this.vm.entity, { name: this.vm.item.header.entityInput }, true)[0].id;
            // const entityName = this.FilterObject(this.vm.entity, { name: this.vm.item.header.entityInput }, true)[0].name;
            this.vm.emailSend.ToEmail = this.FilterObject(this.vm.entity, { id: entityID }, true)[0].email;
          }
        });

        this.vm.emailSend.ToEmail = this.FilterObject(this.vm.entity, { id: obj.id }, true)[0].email;
        break;
      }
    }
  }

  // UTILITIES

  removeDuplicates() {
    for (const obj of this.vm.applied) {
      for (let y = this.vm.invoices.length - 1; y > -1; y--) {
        if (this.vm.invoices[y].id === obj.applied_header_id) {
          this.vm.invoices.splice(y, 1);
        }
      }
    }
  }

  calcTotals() {
    this.vm.totalOwing = this.vm.totalPaid = this.vm.totalBalance = 0;
    for (const obj of this.vm.applied) {
      if (obj.payment_applied_total) {
        this.vm.totalOwing += Number(obj.detail_total) - Number(obj.payment_applied_total);
      } else {
        this.vm.totalOwing += Number(obj.detail_total);
      }
      if (obj.applied_amount) {
        if (!Number(obj.applied_amount) && obj.applied_amount !== '-') {
          obj.applied_amount = '';
        } else if (obj.applied_amount !== '-') {
          obj.applied_amount = Math.round(Number(obj.applied_amount) * 100) / 100;
          this.vm.totalPaid += Number(obj.applied_amount);
        }
      }
    }
    for (const obj of this.vm.invoices) {
      if (obj.payment_applied_total) {
        this.vm.totalOwing += Number(obj.detail_total) - Number(obj.payment_applied_total);
      } else {
        this.vm.totalOwing += Number(obj.detail_total);
      }
      if (obj.applied_amount) {
        if (!Number(obj.applied_amount) && obj.applied_amount !== '-') {
          obj.applied_amount = '';
        } else if (obj.applied_amount !== '-') {
          obj.applied_amount = Math.round(Number(obj.applied_amount) * 100) / 100;
          this.vm.totalPaid += Number(obj.applied_amount);
        }
      }
    }
    this.vm.totalOwing = Math.round(this.vm.totalOwing * 100) / 100;
    this.vm.totalPaid = Math.round(this.vm.totalPaid * 100) / 100;
    this.vm.totalBalance = this.vm.totalOwing - this.vm.totalPaid;
    this.vm.totalBalance = Math.round(this.vm.totalBalance * 100) / 100;
    if (!this.vm.manualAmount) {
      if (this.vm.totalPaid !== 0) {
        this.vm.item.detail[0].amount = this.vm.totalPaid;
      } else {
        this.vm.item.detail[0].amount = '';
      }
    }
    // this.vm.isLoaded = true;
  }

  removeComma() {
    if (
      !Number(this.vm.item.detail[0].amount) &&
      this.vm.item.detail[0].amount &&
      this.vm.item.detail[0].amount !== '-' &&
      this.vm.item.detail[0].amount !== '0'
    ) {
      let tempAmount = this.vm.item.detail[0].amount;
      tempAmount = tempAmount.replace(',', '');
      if (Number(tempAmount)) {
        this.vm.item.detail[0].amount = tempAmount;
      } else {
        this.vm.item.detail[0].amount = '';
      }
    }
  }

  apply() {
    this.vm.invoices = this.OrderBy(this.vm.invoices, 'header_date', false);
    let paymentAmountRemaining = Math.round(Number(this.vm.item.detail[0].amount) * 100) / 100;
    let owing = 0;
    for (const invoice of this.vm.invoices) {
      invoice.applied_amount = '';
    }

    for (const obj of this.vm.applied) {
      owing = Math.round((obj.detail_total - obj.payment_applied_total) * 100) / 100;
      if (paymentAmountRemaining > 0) {
        if (paymentAmountRemaining > owing) {
          obj.applied_amount = owing;
          paymentAmountRemaining -= owing;
        } else {
          obj.applied_amount = paymentAmountRemaining;
          paymentAmountRemaining = 0;
          break;
        }
      } else if (owing < 0) {
        // apply refund only to credits
        if (paymentAmountRemaining < owing) {
          obj.applied_amount = owing;
          paymentAmountRemaining -= owing;
        } else {
          obj.applied_amount = paymentAmountRemaining;
          paymentAmountRemaining = 0;
          break;
        }
      }
    }
    for (const obj of this.vm.invoices) {
      owing = obj.detail_total - obj.payment_applied_total;
      if (paymentAmountRemaining > 0) {
        if (paymentAmountRemaining > owing) {
          obj.applied_amount = owing;
          paymentAmountRemaining -= owing;
        } else {
          obj.applied_amount = paymentAmountRemaining;
          paymentAmountRemaining = 0;
          break;
        }
      } else if (owing < 0) {
        // apply refund only to credits
        if (paymentAmountRemaining < owing) {
          obj.applied_amount = owing;
          paymentAmountRemaining -= owing;
        } else {
          obj.applied_amount = paymentAmountRemaining;
          paymentAmountRemaining = 0;
          break;
        }
      }
    }
    this.calcTotals();
  }

  buildApplied() {
    this.vm.item.applied = [];
    for (const obj of this.vm.applied) {
      if (obj.applied_amount) {
        this.vm.item.applied.push({
          applied_header_id: obj.applied_header_id,
          applied_amount: obj.applied_amount,
          applied_date: obj.header_date,
        });
      }
    }
    if (this.vm.invoices) {
      for (const obj of this.vm.invoices) {
        if (obj.applied_amount) {
          this.vm.item.applied.push({
            applied_header_id: obj.id,
            applied_amount: obj.applied_amount,
            applied_date: obj.header_date,
          });
        }
      }
    }
    if (!this.vm.item.detail[0].account_id) {
      this.vm.item.detail[0].account_id = this.FilterObject(
        this.vm.bank,
        { id: this.vm.item.header.bank_id },
        true
      )[0].account_id;
    }
  }

  // ACTIONS

  print() {
    if (this.vm.action !== 'email') {
      this.vm.action = 'print';
    }
    if (this.vm.item.header.printed && this.vm.action === 'print') {
      this.notification.showWarningToast($localize`:Already Printed @@alreadyPrinted:Already Printed`);
      this.vm.action = '';
      return;
    }

    if (this.vm.applied === undefined || this.vm.applied.length === 0) {
      this.notification.showWarningToast($localize`:Nothing Applied @@nothingapplied:Nothing Applied` + '.');
      this.vm.action = '';
      return;
    }

    if (this.vm.entityAddress.length < 1) {
      this.notification.showWarningToast(
        $localize`:No printable addresses for this Entity @@noprintaddresses:No printable addresses for this Entity`
      );
      this.vm.action = '';
      return;
    }

    let tempEntityAddress1 = '';
    let tempEntityAddress2 = '';
    let tempEntityAddress3 = '';
    // console.log('address: ' + JSON.stringify(this.vm.check));
    let tempCompanyAddress2 = '';
    let tempBankAddress2 = '';
    const tempCompany: any = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0];
    const tempBank: any = this.FilterObject(this.vm.bank, { id: this.vm.item.header.bank_id }, true)[0];
    const tempCheckStock = this.FilterObject(this.vm.checkStock, { id: tempBank.stock_id }, true)[0];
    this.vm.stub = [{ id: 1 }, { id: 2 }];
    if (tempBank.stock_id) {
      this.vm.printLayout.checkStock = this.FilterObject(this.vm.checkStock, { id: tempBank.stock_id }, true)[0];
    }
    // console.log('address in print: ' + JSON.stringify(this.vm.entityAddress));
    tempEntityAddress1 = this.vm.entityAddress[0].address_1;
    tempEntityAddress2 = this.vm.entityAddress[0].address_2;
    tempEntityAddress3 =
      this.vm.entityAddress[0].city + ', ' + this.vm.entityAddress[0].state + ' ' + this.vm.entityAddress[0].zip;
    // if (this.vm.entityAddress) {
    //   if (this.vm.entityAddress.address_1 && this.vm.entityAddress.address_2) {
    //     tempEntityAddress1 = this.vm.entityAddress.address_1 + '<br>' + this.vm.entityAddress.address_2;
    //   } else {
    //     tempEntityAddress1 = this.vm.entityAddress.address_1;
    //   }
    //   // if (this.vm.entityAddress.city && (this.vm.entityAddress.state || this.vm.entityAddress.zip)) {
    //   tempEntityAddress2 = this.vm.entityAddress.city + ', ' + this.vm.entityAddress.state + ' ' + this.vm.entityAddress.zip;
    //   // } else if (this.vm.entityAddress.city) {
    //   //   tempEntityAddress2 = this.vm.entityAddress.city;
    //   // } else {
    //   //   tempEntityAddress2 = this.vm.entityAddress.state + ' ' + this.vm.entityAddress.zip;
    //   // }
    // // } else {
    //   // this.vm.entityAddress = {};
    // }
    if (tempCompany.city && (tempCompany.state || tempCompany.zip)) {
      tempCompanyAddress2 = tempCompany.city + ', ' + tempCompany.state + ' ' + tempCompany.zip;
    } else if (tempCompany.city) {
      tempCompanyAddress2 = tempCompany.city;
    } else {
      tempCompanyAddress2 = tempCompany.state + ' ' + tempCompany.zip;
    }
    if (tempBank.city && (tempBank.state || tempBank.zip)) {
      tempBankAddress2 = tempBank.city + ', ' + tempBank.state + ' ' + tempBank.zip;
    } else if (tempBank.city) {
      tempBankAddress2 = tempBank.city;
    } else {
      tempBankAddress2 = tempBank.state + ' ' + tempBank.zip;
    }

    let tempAmount = Number(this.vm.item.detail[0].amount);

    // const tempAmountInt = Math.floor(tempAmount);
    // check if refund - flips negative positive for refund which will have a negative detail_total
    if (this.vm.item.detail[0].amount < 0) {
      tempAmount = tempAmount * -1;
    }
    const tempAmountInWords = this.words.convert(tempAmount, { currency: true });

    this.vm.check = [
      {
        companyName: tempCompany.name,
        companyDBA: tempCompany.dba,
        companyAddress1: tempCompany.address_1,
        companyAddress2: tempCompanyAddress2,
        companyPhone: tempCompany.phone,
        bankName: tempBank.check_name,
        bankAddress1: tempBank.address_1,
        bankAddress2: tempBankAddress2,
        bankPhone: tempBank.phone,
        payeeName: this.vm.item.header.entityInput,
        payeeAddress1: tempEntityAddress1,
        payeeAddress2: tempEntityAddress2,
        payeeAddress3: tempEntityAddress3,
        accountNumber: tempBank.account_number,
        transitNumber: tempBank.transit_number,
        institutionNumber: tempBank.institution_number,
        number: this.vm.item.header.number,
        amountInWords: tempAmountInWords,
        amount: tempAmount,
        date: this.vm.item.header.header_date,
      },
    ];

    // console.log('check to print: ' + JSON.stringify(this.vm.check));
    if (this.vm.item.batch.locked !== 1) {
      if (
        !this.vm.permissions.Admin &&
        !this.vm.permissions.EditReceivablePayments &&
        this.vm.subLedger === 'ar' &&
        !this.vm.permissions.EditPayablePayments &&
        this.vm.subLedger === 'ap'
      ) {
        this.notification.showWarningToast('You do not have permissions for this action or Payment is not posted');
        return;
        // this.vm.item.header.printed = true;
        // this.save();
      } else if (this.action === 'print') {
        this.vm.confirmPrint = true;
        this.action = '';
        const z = setTimeout(
          function doPrint() {
            clearTimeout(z);
            window.print();
            this.vm.confirmPrint = true;
          }.bind(this),
          1500
        );
        this.vm.action = '';
      } else if (this.action === 'email') {
        this.action = '';
        this.vm.confirmPrint = false;
        const z = setTimeout(
          function doEmail() {
            clearTimeout(z);
            // this.sendEmail();
          }.bind(this),
          1500
        );
        this.vm.action = '';
      }
    } else if (this.vm.action === 'print') {
      const z = setTimeout(
        function doPrint() {
          clearTimeout(z);
          window.print();
          this.vm.confirmPrint = true;
        }.bind(this),
        1500
      );
      this.vm.action = '';
    } else if (this.vm.action === 'email') {
      this.vm.confirmPrint = false;
      const z = setTimeout(
        function doEmail() {
          clearTimeout(z);
          // this.sendEmail();
        }.bind(this),
        1500
      );
      this.vm.action = '';
    }
    this.vm.confirmPrint = true;
  }

  printActualCheckDocument() {
    window.print();
  }

  scrollToChecks(anchor: string) {
    if (this.vm.action === 'print') {
      this.vps.scrollToAnchor(anchor);
    }
  }

  confirmPostChecks() {
    const messages: string[] = [
      $localize`:Print Checks and mark as Printed? @@printchecksandmarkasprinted:Print Checks and mark as Printed?`,
      'This process is not reversible.',
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.checkForPrinted();
        // TODO uncomment after testing complete - have tested update header previous and
        this.printActualCheckDocument();
        // this.updateHeaderPrinted();
      },
      () => {
        this.notification.showSuccessToast($localize`:Print Canceled @@printCanceled:Print Canceled`);
      }
    );
  }

  confirmPostReceipt() {
    const messages: string[] = [
      $localize`:Print Receipt and mark as Printed? @@printreceiptsandmarkasprinted:Print Receipts and mark as Printed?`,
      'This process is not reversible.',
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        // this.checkForPrinted();
        this.printActualCheckDocument();
      },
      () => {
        this.notification.showSuccessToast($localize`:Print Canceled @@printCanceled:Print Canceled`);
      }
    );
  }

  public sendEmail() {
    this.vm.emailed = true;
    // let errorDescription = '';
    // let ToEmail = '';
    //  if (!this.vm.emailSend.ToEmail || this.vm.emailSend.ToEmail === '') {
    //   errorDescription = $localize`:Email address required @@emailaddressrequired:Email address required`;
    //   this.vm.ToEmailError = true;
    // } else {
    //   this.vm.ToEmailError = false;
    //   let testEmails = this.vm.emailSend.ToEmail;
    //   testEmails = testEmails.replace(/ /g, '');
    //   ToEmail = testEmails;
    //   testEmails = testEmails.split(',');
    //   const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   for (const email of testEmails) {
    //     if (email === '' || !regex.test(email)) {
    //       errorDescription = $localize`:Invalid email @@invalidemail:Invalid email`;
    //     }
    //   }
    // }
    // if (errorDescription !== '') {
    //   this.notification.error(errorDescription);
    //   this.vm.buttonsDisabled = false;
    // } else {
    // this.tableDataService.getTableData('uom').subscribe((result: any) => {
    // geting the uom table is necessary due to asyncronys code execution
    this.vm.emailSend.header_id = this.vm.item.header.id;
    this.vm.buttonsDisabled = true;
    // let tempPdfHtml = document.getElementById('report-html').innerHTML;
    // tempPdfHtml = tempPdfHtml.replace(/\t/g, '');
    // tempPdfHtml = tempPdfHtml.replace(/\n/g, '');
    // this.vm.emailSend.PdfHtml = tempPdfHtml;
    // this.vm.emailSend.ToEmail = ToEmail;
    // this.vm.emailSend.Subject = $localize`:Payment Info @@paymentInfo:Payment Info` + ' ' + this.vm.item.header.number;
    // this.vm.emailSend.Body = $localize`:Hi @@hi:Hi` + ' ' + this.vm.item.header.entityInput + ',<br/><br/>';
    // this.vm.emailSend.Body += $localize`:Please find attached your payment receipt @@pleasefindattachedyourpaymentreceipt:Please find attached your payment receipt` + ' ' + this.vm.item.header.number + '.<br/><br/>';
    // this.vm.emailSend.Body += $localize`:Thank you @@thankyou:Thank you` + ',<br/>'; // add name on server side

    let tempMessage = $localize`:Hi @@hi:Hi` + ' ' + this.vm.item.header.entityInput + ',<br/><br/>';
    tempMessage +=
      $localize`:Please find attached your payment receipt @@pleasefindattachedyourpaymentreceipt:Please find attached your payment receipt` +
      ' ' +
      this.vm.item.header.number +
      '.<br/><br/>';
    tempMessage += $localize`:Thank you @@thankyou:Thank you` + ',<br/><br/><br/>';
    tempMessage += '[FROMNAME]';

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();

      let tempHtml = document.getElementById('report-html').innerHTML;
      tempHtml = tempHtml.replace(/imgend/g, '');

      this.emailData.emailInfo.aRecordId = this.vm.item.header.number;
      this.emailData.emailInfo.aMessage = tempMessage;
      this.emailData.emailInfo.aEntity = this.vm.item.header.entity_id;
      this.emailData.emailInfo.aBatchTypeId = '97';
      this.emailData.emailInfo.aReportString = tempHtml;

      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  checkForPrinted() {
    // check again before printing to be sure no updates to printed status
    const arrayOfItemsToChange = [];
    arrayOfItemsToChange.push({ id: this.vm.item.header.id, printed: true });

    this.tableDataService
      .putTableDataByArraySilent('t_header/VerifyChecks', arrayOfItemsToChange)
      .subscribe((v: HttpErrorResponse) => {
        if (v != null && v.status === 418) {
          this.vm.check = [];
          this.vm.confirmPrint = false;
          this.notification.showWarningToast(v.error);
          this.scrollToChecks('top');
        } else {
          this.printActualCheckDocument();
          this.updateCheckPrintedStatus();
        }
      });
  }

  updateCheckPrintedStatus() {
    const arrayOfItemsToChange = [];
    arrayOfItemsToChange.push({ id: this.vm.item.header.id, printed: true });

    this.tableDataService.putTableDataByArraySilent('t_header', arrayOfItemsToChange).subscribe(
      (item: any) => {
        if (item) {
          // this.notification.success(item.description);
        }
        this.vm.check = [];
        this.vm.confirmPrint = false;
        this.scrollToChecks('top');
        this.vm.item.header.printed = true;
      },
      (error) => {
        this.vm.buttonsDisabled = false;
        console.log(error);
      }
    );
  }

  email() {
    this.vm.action = 'email';
    this.print();
  }

  onClose() {
    // console.log('sl:' + this.vm.subLedger);
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom === undefined) {
      window.close();
    } else if (this.vm.subLedger === 'ap') {
      this.router.navigateByUrl('/transactions/payment/list/ap?type=ap&subLedger=ap').then();
    } else {
      this.router.navigateByUrl('/transactions/payment/list/ar?type=ar&subLedger=ar').then();
    }
  }

  async save() {
    if (this.vm.noBillToError) {
      this.notification.showWarningToast('Cannot save using Entity with no Bill To address');
      return;
    }

    this.submitted = true;

    this.vm.buttonsDisabled = true;
    this.vm.errorDescription = '';
    this.vm.alert = {};
    let error = 0;

    // check errors

    if (!this.vm.item.header.header_date) {
      if (!this.vm.item.header.header_date) {
        // this.notification.warning($localize`:Date is required @@dateisrequired:Date is required`);
        this.vm.dateMissing = true;
        error = 1;
      }
      if (!this.vm.item.header.company_id) {
        // this.notification.warning($localize`:Company is required. @@companyisrequired:Company is required.`);
        this.vm.companyError = true;
        error = 1;
      }
    } else {
      let yearEndDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .year_end_date;
      let cutOffDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0].cut_off_date;
      const tempYearEndDate = new Date(yearEndDate);
      const tempCutOffDate = new Date(cutOffDate);
      const headerDate = new Date(this.vm.item.header.header_date);
      if (headerDate < tempYearEndDate) {
        yearEndDate = this.dateFilter.GetDateFormat(yearEndDate);
        this.notification.showWarningToast(
          $localize`:Date must be greater than the year end @@datemustbegreaterthantheyearend:Date must be greater than the year end` +
            ' ' +
            yearEndDate
        );
        error = 1;
      }
      if (headerDate < tempCutOffDate) {
        cutOffDate = this.dateFilter.GetDateFormat(cutOffDate);
        this.notification.showWarningToast(
          $localize`:Date must be greater than the cut off date @@datemustbegreaterthanthecutoffdate:Date must be greater than the cut off date` +
            ' ' +
            cutOffDate
        );
        error = 1;
      }
      this.vm.item.header.header_date = this.dateFilter.GetDateFormat(this.vm.item.header.header_date);
    }

    if (!this.vm.item.header.currency_id) {
      // this.vm.errorDescription += 'Currency is required.';
      this.vm.currencyError = true;
    }
    if (!this.vm.item.header.bank_id) {
      // this.notification.showWarningToast($localize`:Bank is required. @@bankisrequired:Bank is required.`);
      this.vm.bankError = true;
      error = 1;
    }
    if (!this.vm.item.header.payment_type_id) {
      this.vm.paymentTypeError = true;
      // this.notification.warning(
      //   $localize`:Payment Type is required. @@paymentTypeisrequired:Payment Type is required.`
      // );
      error = 1;
    }

    let reverse = 1;
    if (this.vm.item.batch.type_id === 5) {
      reverse = -1;
    }
    this.vm.item.detail[0].debit_credit = this.vm.item.detail[0].amount * reverse;

    // additional required fields error checking for empty
    if (
      !this.vm.item.header.description ||
      !this.vm.item.header.entityInput ||
      !this.vm.item.header.company_id ||
      !this.vm.item.header.header_date ||
      !this.vm.item.header.payment_type_id ||
      !this.vm.item.header.bank_id
    ) {
      error = 1;
    }

    if (this.vm.entityError === true) {
      error = 1;
    }

    if (error === 1) {
      this.notification.showWarningToast('Form Errors!');
      this.vm.buttonsDisabled = false;
      return;
    }

    // be sure another user has not selected invoices
    // console.log('this.invoicesStillNotPaidFound  BEFORE = ' + this.invoicesStillNotPaidFound);
    await this.getInvoicesStillNotPaid().then((r: boolean) => {
      // console.log('r before eval: ' + r);
      if (r) {
        // console.log('true at orig call');
      } else {
        // console.log('false at orig call');
        error = 1;
        return;
      }
    });
    // console.log('this.invoicesStillNotPaidFound  AFTER = ' + this.invoicesStillNotPaidFound);

    if (error === 0) {
      this.buildApplied();
      if (this.vm.item.header.id === undefined) {
        // new
        // console.log('posting AP: ' + JSON.stringify(this.vm.item));
        this.tableDataService.post('entry', this.vm.item).subscribe(
          (result: any) => {
            // this.notification.success(result.description);
            console.log('action: ' + this.vm.action);
            if (this.vm.action === 'new') {
              this.vm.buttonsDisabled = false;
              this.vm.invoices = [];
              this.vm.applied = [];
              this.vm.action = '';
              this.vm.buttonsDisabled = false;
              this.vm.item.header.id = '';
              this.paymentId = '';
              if (this.vm.subLedger === 'ap') {
                this.router.navigateByUrl('/transactions/payment/form?paymentId=newAp').then();
              } else {
                this.router.navigateByUrl('/transactions/payment/form?paymentId=newAr').then();
              }
            } else {
              this.vm.buttonsDisabled = false;
              this.vm.invoices = [];
              this.vm.applied = [];
              this.vm.action = '';
              this.vm.buttonsDisabled = false;
              this.vm.item.header.id = result.id;
              this.paymentId = result.id;
              this.router.navigateByUrl('/transactions/payment/form?paymentId=' + result.id).then();
            }
          },
          (error2: any) => {
            this.vm.buttonsDisabled = false;
            let ErrorMsg: string;
            if (typeof error2.error.Message !== 'undefined') {
              ErrorMsg = error2.error.Message;
            } else {
              ErrorMsg = error2.statusText;
            }
            this.notification.showErrorToast(ErrorMsg);
          }
        );
      } else {
        // edit

        // diff
        const changes: any = {};

        changes.detail = this.vm.item.detail;
        changes.applied = this.vm.item.applied;

        const headerChanges = {};
        for (const key in this.vm.item.header) {
          if (this.vm.item.header.hasOwnProperty(key)) {
            const value = this.vm.item.header[key];
            if (key != 'entityInput') {
              headerChanges[key] = value;
            }
          }
        }

        if (headerChanges) {
          changes.header = headerChanges;
        }

        this.vm.changes = changes;

        this.tableDataService
          .patch('entry', this.vm.item.header.id, changes)
          .then((item: any) => {
            this.vm.buttonsDisabled = false;
            if (item.Message.includes('Error')) {
              // this.notification.info(item.description);
            } else {
              // this.notification.success(item.description);
            }
            if (item.description === 'Deleted') {
              this.router.navigate(['/transactions/payment/list/' + this.vm.subLedger]).then();
            } else if (this.vm.action === 'new') {
              // this.vm.invoices = [];
              // this.vm.applied = [];
              // this.vm.item = [];
              // this.vm.item.batch.locked = false;
              // this.notification.info($localize`:New @@new:New`);
              // this.vm.action = '';
              // this.vm.buttonsDisabled = false;
              // console.log('ledger: ' + this.vm.subLedger);
              // this.vm.item = {};
              // this.vm.changes = {};
              if (this.vm.subLedger === 'ap') {
                this.paymentId = 'newAP';
                this.vm.isLoaded = false;
                this.router.navigateByUrl('/transactions/payment/form?paymentId=' + this.paymentId + '&batchId=').then();
                this.ngOnInit();
              } else {
                this.paymentId = 'newAR';
                // this.router.navigateByUrl('/transactions/payment/form?paymentId=newAR&batchId=').then();
              }
            }
          })
          .catch((msg: string) => {
            this.vm.buttonsDisabled = false;
            // this.notification.error(msg);
            console.log(msg);
          });
      }
    } else {
      // report error
      this.vm.buttonsDisabled = false;
    }
  }

  saveNew() {
    this.vm.action = 'new';
    this.save().then();
  }

  getOpenBatches() {
    let tempBatchType = '';
    if (this.vm.subLedger === 'ap') {
      tempBatchType = '5';
    } else {
      tempBatchType = '3';
    }
    this.batchType = tempBatchType;
    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&myBatches=true';
    paramString += '&lockedBatches=false';

    // console.log(paramString);
    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any) => {
      this.vm.batches = result;
      // console.log(result);
    });
  }

  changeBatch(id) {
    this.vm.buttonsDisabled = true;
    const changes = { batch_id: id };

    this.tableDataService
      .patch('t_header', this.vm.item.header.id, changes)
      .then((item: any) => {
        this.vm.item.batch.locked = '0';
        this.vm.buttonsDisabled = false;
        this.notification.showSuccessToast($localize`:Moved @@moved:Moved`);
        this.vm.batches = [];
      })
      .catch((msg: any) => {
        this.vm.buttonsDisabled = false;
        console.log(msg);
        // this.notification.error($localize`:Server Side Error @@serverSideError:Server Side Error`);
      });
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  OrderBy(array, field, reverse = true) {
    return new ArraySortPipe().transform(array, field, reverse);
  }

  deleteItem() {
    const brClass = this;
    const messages: string[] = [
      $localize`:Are you sure you want to delete Payment? @@areyousureyouwanttodeletePayment:Are you sure you want to delete Payment?`,
      this.vm.item.header.number,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.vm.item.header.deleted = true;
        // this.save();
        this.deleteItemByArray();
      },
      () => {
        brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  deleteItemByArray() {
    // just one item but stick it in array so api undertands - the old save as delete method above is not good - to slow.
    const deleteMe = [];
    deleteMe.push({ header_id: this.vm.item.header.id });

    this.tableDataService.deleteTableDataByArray('entry', deleteMe).subscribe((item: any) => {
      if (item) {
        if (item.Message === 'Success') {
          this.router.navigate(['/transactions/payment/list/' + this.vm.subLedger]).then();
        }
      }
    });
  }

  saveClose() {
    this.save().then();
    this.onClose();
  }

  toggleBatches() {
    this.showBatches = !this.showBatches;
  }
}
