import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { NotificationService } from '../../../Services/notification.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterPipe } from 'ngx-filter-pipe';
import { AppConfigService } from '../../../Services/app-config.service';
import { ResponseModel } from '../../../DataModels/response-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';

@Component({
  selector: 'app-work-orders-list',
  templateUrl: './work-orders-list.component.html',
  styleUrls: ['./work-orders-list.component.css'],
})
export class WorkOrdersListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: NotificationService,
    private datePipe: DatePipe,
    private decimal: DecimalPipe,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private customFilterBy: FilterPipe,
    private appConfig: AppConfigService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    public confirmDialog: ConfirmDialogService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  recId = '';

  vm: any = {
    title: 'Work Orders',
    formUrl: '/work-orders/work-orders-form',
    invoiceFormUrl: '/transactions/invoice/form',
    workorderFilter: {},
    list: {},
    listWithoutFilter: {},
    Preferences: {},
    permissions: {},
    workorderSearch: '',
    workorderToDate: '',
    workorderFromDate: '',
    workorderNumberOfResults: '25',
    incompleteWorkOrders: '',
    workorderStatus: '',
    notInvoiced: '',
    assignedToMe: '',
    searching: false,
    filterOptions: ['All', 'Completed', 'In Progress', 'Open', 'Emergency'],
  };

  isLoaded = false;

  workOrdersCount = 0;
  today = new Date();

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  selectedFilterOption = null;

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.dataInit();
    this.getList();
    // this.setList(this.vm.list);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([6, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  stringDateConvert(date: string): Date {
    return new Date(date);
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);

    // // used for grid content menu - need to be able to turn back on / off for support
    // if (this.appConfig.disableDefaultContentMenu) {
    //   document.addEventListener('contextmenu', (event) => event.preventDefault());
    // }
  }

  getAssignedToMeData(e) {
    this.vm.workorderFilter.assignedToMe = e;
    this.getPagedApiData();
  }

  geNotInvoicedData(e) {
    this.vm.notInvoiced = e;
    this.getPagedApiData();
  }

  filterByStatus(e, status = 'All') {
    if (e && status !== 'All') {
      const list = [...this.vm.listWithoutFilter];
      const filteredList = list.filter((obj) => obj.status == status);
      this.vm.list = filteredList;
    } else {
      this.vm.list = this.vm.listWithoutFilter;
    }
  }

  dataInit() {
    this.vm.workorderFilter = this.localStorage.StorageGet('workorderFilter', {
      text: '',
      sort: 'number',
      ascending: true,
      assignedToMe: false,
      status: '',
    });
    if (typeof this.vm.workorderFilter === 'string') {
      this.vm.workorderFilter = JSON.parse(this.vm.workorderFilter);
    }
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences', ''));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));

    // this.vm.workorderSearch = this.localStorage.StorageGet('workorderSearch', '');
    // this.vm.workorderToDate = this.localStorage.StorageGet('workorderToDate', '');
    // this.vm.workorderFromDate = this.localStorage.StorageGet('workorderFromDate', '');
    // this.vm.workorderNumberOfResults = '25';
    // this.vm.incompleteWorkOrders = this.localStorage.StorageGet('incompleteWorkOrders', '');
    // this.vm.workorderStatus = this.localStorage.StorageGet('workorderStatus', '');
    // this.vm.notInvoiced = this.localStorage.StorageGet('notInvoiced', '');
    if (this.vm.notInvoiced === 'true') {
      this.vm.notInvoiced = true;
    }
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    if (!this.vm.permissions.Admin) {
      this.vm.assignedToMe = this.localStorage.StorageGet('assignedToMe', true);
    } else {
      this.vm.assignedToMe = this.localStorage.StorageGet('assignedToMe', '');
    }
  }

  encodeQueryData(data, filterEmpty = true) {
    const ret = [];
    for (const d in data) {
      if (filterEmpty) {
        if (data[d] != null && data[d] != '') {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
      } else {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  getList() {
    const listParams = {
      fromDate: this.vm.workorderFromDate,
      toDate: this.vm.workorderToDate,
      search: this.vm.workorderSearch,
      incompleteWorkOrders: this.vm.incompleteWorkOrders,
      notInvoiced: this.vm.notInvoiced,
      numberOfResults: this.vm.workorderNumberOfResults,
      assignedToMe: this.vm.workorderFilter.assignedToMe,
      status: this.vm.workorderStatus,
      pageNumber: this.page !== 0 ? this.page - 1 : 0,
      pageSize: this.pageSize,
    };
    this.tableDataService
      .getApiListData('WorkOrder', '?' + this.encodeQueryData(listParams, false))
      .subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        // console.log(JSON.stringify(this.vm.list));
        this.vm.listWithoutFilter = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;

        this.isLoaded = true;
        this.vm.searching = false;
        this.workOrdersCount = result.totalRecords;
        for (const [x, value] of Object.entries(this.vm.list)) {
          if (this.vm.list[x].required_by_date && !this.vm.list[x].completed_date) {
            const d = new Date();
            const tempRequiredDate = new Date(this.vm.list[x].required_by_date);
            if (tempRequiredDate < d) {
              this.vm.list[x].requiredPast = true;
            }
          }
        }
        // this.setList(this.vm.list);
      });
  }

  generateInvoice(id) {
    const messages: string[] = [
      $localize`:Generate Invoice? @@generateInvoice:Generate Invoice?`,
      '',
    ];
    this.confirmDialog.confirmThis(
      messages,
      () => {
        // fall thru on yes
        this.generateInv(id);
      },
      () => {
        this.notification.success($localize`:Canceled @@Canceled:Canceled`);
        return;
      }
    );
  }


  generateInv(id) {
    this.router.navigate(['/transactions/invoice/form'], { queryParams: { invoiceId: 'newAR', workorderId: id } }).then();
    // [routerLink]="['/transactions/invoice/form']"
    // [queryParams]="{
    // invoiceId: 'newAR',
    // workorderId: item.id}"
  }

  // objectKeys(obj) {
  //   if (obj != null) {
  //     return Object.keys(obj);
  //   }
  //   return { length: 0 };
  // }

  // filter(filter) {
  //   this.vm.workorderFilter = Object.assign(this.vm.workorderFilter, filter);
  //   this.localStorage.StorageSet('workorderFilter', JSON.stringify(this.vm.workorderFilter));
  // }

  search() {
    // this.localStorage.StorageSet('workorderFilter', JSON.stringify(this.vm.workorderFilter));
    // this.localStorage.StorageSet('workorderSearch', this.vm.workorderSearch);
    // this.localStorage.StorageSet('workorderToDate', this.vm.workorderToDate);
    // this.localStorage.StorageSet('workorderFromDate', this.vm.workorderFromDate);
    // this.localStorage.StorageSet('incompleteWorkOrders', this.vm.incompleteWorkOrders);
    // this.localStorage.StorageSet('workorderNumberOfResults', this.vm.workorderNumberOfResults);
    // this.localStorage.StorageSet('assignedToMe', this.vm.assignedToMe);
    // this.localStorage.StorageSet('workorderStatus', this.vm.workorderStatus);
    // this.localStorage.StorageSet('notInvoiced', this.vm.notInvoiced);

    this.getList();
  }

  onNavigate(aID: any, print = false) {
    if (print) {
      this.router.navigate([this.vm.formUrl], { queryParams: { workorderId: aID, action: 'print' } }).then();
    } else {
      this.router.navigate([this.vm.formUrl], { queryParams: { workorderId: aID } }).then();
    }
  }

  clearSearch() {
    // this.localStorage.StorageSet('workorderFilter',JSON.stringify(this.vm.workorderFilter));
    this.vm.workorderSearch = '';
    this.vm.workorderToDate = '';
    this.vm.workorderFromDate = '';
    this.vm.incompleteWorkOrders = '';
    // this.vm.workorderNumberOfResults = '25';
    this.vm.workorderStatus = '';
    this.vm.assignedToMe = false;
    this.vm.notInvoiced = false;
    // this.localStorage.StorageSet('workorderSearch', this.vm.workorderSearch);
    // this.localStorage.StorageSet('workorderToDate', this.vm.workorderToDate);
    // this.localStorage.StorageSet('workorderFromDate', this.vm.workorderFromDate);
    // this.localStorage.StorageSet('incompleteWorkOrders', this.vm.incompleteWorkOrders);
    // this.localStorage.StorageSet('workorderNumberOfResults', this.vm.workorderNumberOfResults);
    // this.localStorage.StorageSet('assignedToMe', this.vm.assignedToMe);
    // this.localStorage.StorageSet('workorderStatus', this.vm.workorderStatus);
    // this.localStorage.StorageSet('notInvoiced', this.vm.notInvoiced);
    this.search();
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    const listParams = {
      fromDate: this.vm.workorderFromDate,
      toDate: this.vm.workorderToDate,
      search: this.vm.workorderSearch,
      incompleteWorkOrders: this.vm.incompleteWorkOrders,
      notInvoiced: this.vm.notInvoiced,
      numberOfResults: this.vm.workorderNumberOfResults,
      assignedToMe: this.vm.workorderFilter.assignedToMe,
      status: this.vm.workorderStatus,
      pageNumber: this.page !== 0 ? this.page - 1 : 0,
      pageSize: this.pageSize,
    };
    this.tableDataService
      .getApiListData('WorkOrder', '?' + this.encodeQueryData(listParams, false))
      .subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.vm.listWithoutFilter = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.isLoaded = true;
        this.vm.searching = false;
        for (const [x, value] of Object.entries(this.vm.list)) {
          if (this.vm.list[x].required_by_date && !this.vm.list[x].completed_date) {
            const d = new Date();
            const tempRequiredDate = new Date(this.vm.list[x].required_by_date);
            if (tempRequiredDate < d) {
              this.vm.list[x].requiredPast = true;
            }
          }
        }
      });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.workorderSearch = value.searchTerm;
    this.vm.workorderFromDate = value.fromDate;
    this.vm.workorderToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.workorderSearch = value.searchTerm;
    this.vm.workorderFromDate = value.fromDate;
    this.vm.workorderToDate = value.toDate;
    this.page = 1;
    this.vm.incompleteWorkOrders = '';
    this.vm.workorderNumberOfResults = '25';
    this.vm.workorderStatus = '';
    this.vm.assignedToMe = false;
    this.vm.notInvoiced = false;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
