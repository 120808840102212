import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-payment-email',
  templateUrl: './payment-email.component.html',
  styleUrls: ['./payment-email.component.css']
})
export class PaymentEmailComponent implements OnInit {

  @Input() vm: any;

  constructor() { }

  ngOnInit() {
  }

}
