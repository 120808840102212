import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../Services/notification.service';
import {TableDataService} from '../../Services/tableData.service';
import {first} from 'rxjs/operators';

enum EmailStatus {
  Verifying,
  Failed
}

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  EmailStatus = EmailStatus;
  emailStatus = EmailStatus.Verifying;

  today: number = Date.now();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: TableDataService,
    private alertService: NotificationService
  ) { }

  ngOnInit() {
    const token = this.route.snapshot.queryParams.token;

    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    // this.accountService.verifyEmail(token)
    //   .pipe(first())
    //   .subscribe({
    //     next: () => {
    //       this.alertService.success('Verification successful, you can now login', { keepAfterRouteChange: true });
    //       this.router.navigate(['../login'], { relativeTo: this.route });
    //     },
    //     error: () => {
    //       this.emailStatus = EmailStatus.Failed;
    //     }
    //   });
  }
}
