import {Component, OnInit, Input, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-model-select-item',
  templateUrl: './model-select-item.component.html',
  styleUrls: ['./model-select-item.component.css']
})
export class ModelSelectItemComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
