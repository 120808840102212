import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../Services/bs-toast-service';
import {DecimalNewPipe} from '../../common/DecimalNewPipe';

@Component({
  selector: 'app-statistical-form',
  templateUrl: './statistical-form.component.html',
  styleUrls: ['./statistical-form.component.css'],
})
export class StatisticalFormComponent implements OnInit, AfterViewInit {
  vm: any = {};
  statisticalId;
  batchId;
  originalItem: any = {};
  showDelete;
  developerView;
  submitted = false;
  accountInput: any = {};
  recurring = '';
  showImportButtons = false;

  public urlParams: any = [];

  @ViewChild('csvReader', { static: false }) csvReader: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private titleService: Title,
    private confirmDialogService: ConfirmDialogService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalNewPipe,
  ) {
    this.vm.item = {};
    this.vm.item.header = {};
    this.vm.item.batch = {};
    this.vm.item.detail = {};
    this.vm.batch = {};
    this.vm.account = [];
    this.vm.location = [];
    // this.vm.currency = [];
    this.vm.company = [];
    this.vm.rfType = [];
    this.vm.accountFilter = {};
    this.vm.isLoaded = false;
    this.vm.companyIsLoaded = false;
    this.vm.locationIsLoaded = false;
    this.vm.currencyIsLoaded = false;
    this.vm.item.detail = [];
    this.vm.item.detail.id = [];
    this.vm.item.detail.accountInput = '';
    this.vm.item.header.description = '';
  }

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: true,
  };

  ngOnInit() {
    this.statisticalId = '';
    this.urlParams = this.route.snapshot.queryParams;
    this.statisticalId = this.urlParams.statisticalId;
    this.batchId = this.urlParams.batchId;
    this.recurring = this.urlParams.recurring;

    this.checkPermissions();

    this.vm.alert = {};
    this.vm.alert.message = [];
    this.vm.newAccount = {};

    this.vm.item.header = {};
    this.vm.item.batch = {};
    this.vm.auditTrail = [];

    if (this.statisticalId === 'new') {
      this.vm.item = { batchTypeId: '16' };
      this.vm.auditTrail = [];
      this.vm.item.header = {};
      this.vm.item.batch = {};
      this.vm.item.batch.type_id = this.vm.item.batchTypeId;
      this.vm.item.detail = [];
      this.loadLookups();
      this.updateAccount();
      this.addRows(2);
      this.vm.item.header.header_date = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
      this.vm.item.header.recurring = this.recurring == 'true';
      this.vm.isLoaded = true;
    } else {
      this.getData();
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  checkPermissions() {
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences', ''));
    if (
      !this.vm.permissions.EditJournalEntries &&
      !this.vm.permissions.ViewJournalEntries &&
      !this.vm.permissions.Admin
    ) {
      document.location.href = '/';
    }
  }

  getData() {
    this.tableDataService.getTableData('entry', this.statisticalId).subscribe((result) => {
      this.vm.item = result;
console.log(result);
      this.getAccounts();
      this.loadLookups();
      // this.init();
      // this.addTextSignature();
      this.init().then((y) => {
        this.addTextSignature();
      });
      this.updateAccount();
      this.formatDate();
      this.addRows(2);
      this.submitted = true;
    });
  }

  formatDate() {
    this.vm.item.header.header_date = this.datePipe.transform(this.vm.item.header.header_date, 'MM/dd/yyyy');
  }

  getAccounts() {
    this.tableDataService
      .getApiListData('account', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        const data: any = value.Data;
        this.vm.account = data.filter((x) => x.type_id === 7);

        for (const det of this.vm.item.detail) {
          // each detail record
          for (const acct of this.vm.account) {
            // each account
            if (det.account_id === acct.id) {
              const acctName = acct.number + ' ' + acct.name;
              det.accountInput = acctName;
            }
          }
        }
      });
  }

  loadLookups() {
    this.tableDataService.getApiListData('uom', '?state=active&pageNumber=0&pageSize=999999').subscribe((value) => {
      this.vm.uom = value.Data;
    });

    this.tableDataService
      .getApiListData('account_subtotal_no_parents', '?pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        this.vm.accountSubtotal = value.Data;
      });

    this.tableDataService.getApiListData('company', '?state=active&pageNumber=0&pageSize=999999').subscribe((value) => {
      this.vm.company = value.Data;
    });

    // this.tableDataService
    //   .getApiListData('currency', '?state=active&pageNumber=0&pageSize=999999')
    //   .subscribe((value) => {
    //     this.vm.currency = value.Data;
    //   });

    this.tableDataService
      .getApiListData('location', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        this.vm.location = value.Data;
      });

    this.tableDataService.getApiListData('reporting_field_type', '?pageNumber=0&pageSize=999999').subscribe((value) => {
      this.vm.rfType = value.Data;
    });

    this.tableDataService
      .getApiListData('reporting_field', '?type_id=rf1&state=active&pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        this.vm.rf1 = value.Data;
      });

    this.tableDataService
      .getApiListData('reporting_field', '?type_id=rf2&state=active&pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        this.vm.rf2 = value.Data;
      });

    this.tableDataService
      .getApiListData('reporting_field', '?type_id=rf3&state=active&pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        this.vm.rf3 = value.Data;
      });

    this.tableDataService
      .getApiListData('reporting_field', '?type_id=rf4&state=active&pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        this.vm.rf4 = value.Data;
      });

    this.tableDataService
      .getApiListData('reporting_field', '?type_id=rf5&state=active&pageNumber=0&pageSize=999999')
      .subscribe((value) => {
        this.vm.rf5 = value.Data;
      });
  }

  async init() {
    if (this.statisticalId === 'new') {
      // NEW ENTRY
      this.vm.item.header.currency_rate = '1';

      if (this.vm.Preferences.default_company_id) {
        this.vm.item.header.company_id = this.vm.Preferences.default_company_id;
      }
    } else {
      // EDIT ENTRY
      for (const obj of this.vm.item.detail) {
        if (obj.item_id) {
          if (this.FilterObject(this.vm.inventoryItem, { id: obj.item_id }, true)) {
            obj.itemInput = this.FilterObject(this.vm.inventoryItem, { id: obj.item_id }, true)[0].code;
          }
        }

        obj.amount = Number(obj.amount);
        obj.quantity = Number(obj.quantity);
        obj.debit_credit = Number(obj.debit_credit);
        if (obj.debit_credit > 0) {
          obj.amountInput = Number(obj.debit_credit);
          obj.dc = '1';
        } else {
          obj.amountInput = 0 - Number(obj.debit_credit);
          obj.dc = '-1';
        }
      }

      this.calcTotal();
      this.vm.isLoaded = true;
    }

    for (const obj of this.vm.item.detail) {
      if (obj.debit_credit) {
        obj.debit_credit = Number(obj.debit_credit);
      }
    }

    if (this.vm.company.length === 1) {
      this.vm.item.header.company_id = this.vm.company[0].id;
    }

    // if (this.vm.currency.length === 1) {
    //   this.vm.item.header.currency_id = this.vm.currency[0].id;
    // }
    if (this.vm.location.length === 1) {
      this.vm.item.header.location_id = this.vm.location[0].id;
    }
    this.createAccountName();

    // this.addRows(5); // create initial detail row
  }

  //  ACCOUNTS
  createAccountName() {
    this.vm.accountName = [];
    for (const obj of this.vm.account) {
      const tempName = obj.number + ' ' + obj.name;
      this.vm.accountName.push({
        name: tempName,
      });
    }
  }

  updateAccount() {
    this.vm.startAccount = 0;

    this.tableDataService.getApiListData('account', '?state=active&pageNumber=0&pageSize=999999').subscribe((value) => {
      // filter out all non stat accounts
      this.vm.account = value.Data.filter((x) => x.type_id === 7);
    });
  }

  selectAccountModal(accountId) {
    const id = this.vm.accountSelectDetailId;
    for (const obj of this.vm.item.detail) {
      if (obj.id == id) {
        for (const obj2 of this.vm.account) {
          if (obj2.id == accountId) {
            obj.account_id = obj2.id;
            obj.accountInput = obj2.number + ' ' + obj2.name;
            break;
          }
        }
        break;
      }
    }
  }

  accountSelected(id) {
    for (const obj of this.vm.item.detail) {
      if (obj.id == id) {
        // obj.account_id = '';
        if (obj.accountInput !== undefined && obj.accountInput.length > 0) {
          let found = false;
          for (const obj2 of this.vm.account) {
            if (obj2.number + ' ' + obj2.name == obj.accountInput) {
              obj.account_id = obj2.id;
              found = true;
              break;
            }
          }
          if (!found) {
            this.notification.showWarningToast($localize`:Account not found @@accountnotfound:Account not found`);
            obj.accountInput = '';
            obj.account_id = undefined;
          }
        } else {
          obj.account_id = undefined;
        }
        break;
      }
    }
  }

  saveNewAccount() {
    let errorDescription = '';
    this.vm.accountButtonsDisabled = true;

    if (!this.vm.newAccount.name) {
      errorDescription += $localize`:Name is required. @@nameisrequired:Name is required.` + '<br/>';
      this.vm.newAccountNameError = true;
    } else if (this.FilterObject(this.vm.account, { name: this.vm.newAccount.name }, true).length > 0) {
      if (
        this.FilterObject(this.vm.account, { name: this.vm.newAccount.name }, true)[0].name == this.vm.newAccount.name
      ) {
        this.vm.newAccountNameError = true;
        errorDescription += $localize`:Name is a duplicate. @@nameisaduplicate:Name is a duplicate.` + '<br/>';
      }
    }
    if (!this.vm.newAccount.number) {
      errorDescription += $localize`:Number is required. @@numberisrequired:Number is required.` + '<br/>';
      this.vm.newAccountNumberError = true;
    } else if (this.FilterObject(this.vm.account, { name: this.vm.newAccount.number }, true).length > 0) {
      if (
        this.FilterObject(this.vm.account, { name: this.vm.newAccount.number }, true)[0].number ==
        this.vm.newAccount.number
      ) {
        this.vm.newAccountNumberError = true;
        errorDescription += $localize`:Number is a duplicate. @@numberisaduplicate:Number is a duplicate.` + '<br/>';
      }
    }

    if (errorDescription === '') {
      this.tableDataService.post('account', this.vm.newAccount).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.vm.accountButtonsDisabled = false;
          this.updateAccount();
          this.vm.newAccount = {};
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.accountButtonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          console.log(ErrorMsg);
        }
      );
    } else {
      // report error
      this.vm.accountButtonsDisabled = false;
      console.log(errorDescription);
    }
  }

  // UTILITIES

  addRows(qty) {
    if (qty == '') {
      qty = 0;
    }
    let maxSortOrder = 0;
    for (const obj of this.vm.item.detail) {
      if (maxSortOrder < obj.sort_order) {
        maxSortOrder = obj.sort_order;
      }
    }
    for (let x = 1; x <= qty; x++) {
      // tempID = this.vm.item.detail.length + 1;
      const tempDate = new Date();
      const tempID =
        tempDate.getMinutes() * (tempDate.getSeconds() + tempDate.getMilliseconds()) + this.vm.item.detail.length;
      maxSortOrder++;
      this.vm.item.detail.push({
        id: tempID,
        quantity: 0,
        sort_order: maxSortOrder,
      });
    }
  }

  deleteLine(id) {
    for (let x = 0; x < this.vm.item.detail.length; x++) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail.splice(x, 1);
        this.calcTotal();
      }
    }
  }

  calcTotal() {
    let total = 0;
    for (const obj of this.vm.item.detail) {
      obj.debit_credit = 0;
      if (obj.amountInput && obj.dc) {
        obj.debit_credit = obj.amountInput * obj.dc;
        if (!Number(obj.debit_credit) && obj.debit_credit != '-') {
          this.notification.showWarningToast('Debit or Credit is not a number, please remove any text.');
        } else if (obj.debit_credit != '-') {
          total += Number(obj.debit_credit);
        }
      }
    }
    this.vm.total = Math.round(total * 100) / 100;
  }

  print() {
    this.vm.action = 'print';
    this.save();
  }

  saveClose() {
    this.save();
    this.onClose();
  }

  save() {
    this.submitted = true;
    this.vm.errorDescription = '';

    // check errors

    if (!this.vm.item.header.header_date) {
      this.vm.errorDescription += $localize`:Date is required. @@dateisrequired:Date is required.` + '<br/>';
      this.vm.dateMissing = true;
    } else {
      const yearEndDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .year_end_date;
      const cutOffDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .cut_off_date;
      const tempYearEndDate = new Date(yearEndDate);
      const tempCutOffDate = new Date(cutOffDate);
      const headerDate = new Date(this.vm.item.header.header_date);
      if (headerDate < tempYearEndDate) {
        this.vm.errorDescription +=
          $localize`:Date must be greater than the the year end @@datemustbegreaterthanthetheyearend:Date must be greater than the the year end` +
          ' ' +
          yearEndDate +
          '<br/>';
      }
      if (headerDate < tempCutOffDate) {
        this.vm.errorDescription +=
          $localize`:Date must be greater than the cut off date @@datemustbegreaterthanthecutoffdate:Date must be greater than the cut off date` +
          ' ' +
          cutOffDate +
          '<br/>';
      }
    }

    if (!this.vm.item.header.company_id) {
      this.vm.errorDescription += $localize`:Company is required. @@companyisrequired:Company is required.` + '<br/>';
      this.vm.companyError = true;
    }
    if (!this.vm.item.header.location_id) {
      this.vm.errorDescription +=
        $localize`:Location is required. @@locationisrequired:Location is required.` + '<br/>';
      this.vm.locationError = true;
    }
    // no currency on statiscal - quantity of uom only
    // if (!this.vm.item.header.currency_id) {
    //   this.vm.errorDescription +=
    //     $localize`:Currency is required. @@currencyisrequired:Currency is required.` + '<br/>';
    //   this.vm.currencyError = true;
    // }

    if (!this.vm.item.header.description) {
      this.vm.errorDescription +=
        $localize`:Description is required. @@descriptionisrequired:Description is required.` + '<br/>';
      this.vm.descriptionError = true;
    }

    if (this.vm.item.detail !== undefined) {
      let rowsFound = false;
      for (const obj of this.vm.item.detail) {
        if (
          obj.account_id !== undefined &&
          obj.uom_id !== undefined &&
          obj.debit_credit !== undefined &&
          obj.accountInput !== undefined
        ) {
          rowsFound = true;
        }
      }
      if (!rowsFound) {
        this.vm.errorDescription += $localize`:No Entry rows found. @@noentryrowsfound:No Entry rows found.` + '<br/>';
        this.vm.descriptionError = true;
      }
    }

    if (this.vm.item.detail !== undefined) {
      for (const obj of this.vm.item.detail) {
        if (
          obj.account_id !== undefined &&
          obj.uom_id !== undefined &&
          (obj.debit_credit === undefined || obj.debit_credit === '')
        ) {
          console.log('dr: ' + obj.debit_credit);
          this.vm.errorDescription += $localize`:Missing Quantity. @@missingquantity:Missing Quantity.` + '<br/>';
          this.vm.descriptionError = true;
        }
      }
    }

    if (this.vm.item.detail !== undefined) {
      for (const obj of this.vm.item.detail) {
        if (obj.account_id !== undefined && (obj.uom_id === undefined || obj.uom_id === '' || obj.uom_id === null)) {
          this.vm.errorDescription += $localize`:Missing UOM. @@missinguom:Missing UOM.` + '<br/>';
          this.vm.descriptionError = true;
        }
      }
    }

    if (!this.vm.permissions.EditJournalEntries && !this.vm.permissions.Admin) {
      this.vm.errorDescription +=
        $localize`:You do not have access to save @@youdonothaveaccesstosave:You do not have access to save` + '<br/>';
      this.vm.descriptionError = true;
    }

    // report error
    if (this.vm.errorDescription !== '') {
      this.vm.buttonsDisabled = false;
      const arr = this.vm.errorDescription.split('<br/>');
      arr.forEach((item) => {
        if (item) {
          this.notification.showWarningToast(item);
        }
      });
      return; // stop post / patch and go back so user can fix since there are errors
    } else {
      this.vm.buttonsDisabled = true;

      if (this.vm.item.header.id === undefined) {
        // new
        this.tableDataService.post('entry', this.vm.item).subscribe(
          (item: any) => {
            this.vm.buttonsDisabled = false;
            // this.notification.showSuccessToast(item.id);
            this.router.navigateByUrl('/transactions/statistical/form?statisticalId=' + item.id).then();
          },
          (error: { error: { Message: string }; statusText: string }) => {
            this.vm.buttonsDisabled = false;
            let ErrorMsg: string;
            if (typeof error.error.Message != 'undefined') {
              ErrorMsg = error.error.Message;
            } else {
              ErrorMsg = error.statusText;
            }
            console.log(ErrorMsg);
          }
        );
      } else {
        // edit

        this.tableDataService
          .patch('entry', this.vm.item.header.id, this.vm.item)
          .then((item: any) => {
            if (item) {
              // this.notification.info(item.description);
            }
            this.vm.buttonsDisabled = false;
            if (item.description == 'Deleted') {
              this.router.navigate(['/transactions/statistical/list/']).then();
            }
          })
          .catch((msg: string) => {
            this.vm.buttonsDisabled = false;
            console.log(msg);
          });
      }
    }
  }

  uploadListener(event): void {
    const result = [];
    const files = event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {
      const input = event.target;
      const reader = new FileReader();
      let headers = [];
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const rows = (csvData as string).split(/\r\n|\n/);
        for (let i = 0; i < rows.length; i++) {
          if (i == 0) {
            headers = rows[i].split(',');
            for (let j = 0; j < headers.length; j++) {
              headers[j] = headers[j].toLowerCase();
              headers[j] = headers[j].replace('\r', '');
              headers[j] = headers[j].trimEnd();
            }
          } else {
            const obj = {};
            const cells = rows[i].split(',');
            for (let j = 0; j < cells.length; j++) {
              cells[j] = cells[j].replace('\r', '');
              //  if (headers[j] === 'debit' || headers[j] === 'credit') {
              //      cells[j] = Number(cells[j]);
              //  }
              obj[headers[j]] = cells[j];
            }
            result.push(obj);
          }
        }
        if (result) {
          this.vm.csvResults = result;
          // document.getElementById('upload-buttons').classList.remove('d-none');
          this.showImportButtons = true;
          this.notification.showSuccessToast('Entries Imported For Review');
        }
      };
      reader.onerror = () => {
        this.notification.showErrorToast(
          $localize`:error is occured while reading file! @@errorisoccuredwhilereadingfile:error is occured while reading file!`
        );
      };
    } else {
      this.notification.showErrorToast(
        $localize`:Please import valid .csv file. @@pleaseimportvalidcsvfile:Please import valid .csv file.`
      );
      this.csvReader.nativeElement.value = '';
      this.vm.csvResults = {};
    }
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  onClose() {
    if (this.vm.item.header.recurring) {
      this.router.navigate(['/transactions/statistical/recurring-list']).then();
    } else {
      this.router.navigate(['/transactions/statistical/list']).then();
    }
  }

  signature(signature) {
    // this.vm.linkedSignature = signature;
    this.vm.item.header.signature = signature;
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  deleteSignature() {
    this.vm.item.header.signature = '';
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  addTextSignature() {
    let text;
    text = 'Entry ';
    text += 'Number: ' + this.vm.item.header.audit_sequence + '      ';
    text += 'Total: $' + this.decimalPipe.transform(this.vm.total);
    this.vm.addTextSignature = text;
  }
  gotAnUploadFile(file: any) {
    // console.log('in got a file: ' + file);
    // console.log('in got a file: ');
  }

  syncItemAttachments(attachmentsArray: any) {
    // console.log('attachments array recvd in main');
    // console.log(attachmentsArray);

    // clear existing - will be put back because in array passed back in file upload array
    this.vm.item.attachments = [];

    // use the pieces needed for the api record
    attachmentsArray.forEach((x) => {
      this.vm.item.attachments.push({
        id: x.id,
        header_id: x.header_id,
        file_name: x.fileName,
        type: x.fileType,
        attachment: x.fileData,
        audit_sequence: 0
      });
    });
    // console.log('main vm array after sync to file upload data');
    // console.log(this.vm);
  }

  deleteItem() {
    if (this.statisticalId != 'new') {
      const brClass = this;
      // let retMsg = '';
      const messages: string[] = [
        $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
        '',
      ];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.vm.item.header.deleted = true;
          this.save();
        },
        () => {
          brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
        }
      );
    }
  }

  protected readonly window = window;
}
