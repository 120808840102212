import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {NotificationService} from './notification.service';
import {lastValueFrom, Observable, throwError} from 'rxjs';
import {StorageService} from './storage.service';
import {ResponseModel} from '../DataModels/response-model';
import {take} from 'rxjs/operators';


@Injectable()
export class TableDataService {

  endpointUrl = null;

  constructor(protected http: HttpClient,
              private appConfigService: AppConfigService,
              private storage: StorageService,
              private toastr: NotificationService) {
    this.endpointUrl = this.appConfigService.apiBaseUrl;
  }

// TODO NOTE - Do NOT surface errors here any longer - interceptor service catches them

  getTableData(endPoint: string, params: string = null): Observable<any> {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    let url = null;
    if (params !== null) {
      url = this.endpointUrl + endPoint + '/' + params;
    } else {
      url = this.endpointUrl + endPoint;
    }
    // console.log('url from get: ' + url);
    // const httpOptions = {
    //   headers: new HttpHeaders()
    //     // .append('Authorization', t)
    //     // .append('Content-Type', 'application/json')
    //     // .append('Accept', '*/*')
    //   // .append('Access-Control-Allow-Origin:', '*')
    // };
    return new Observable((observer) => {
      this.http.get(url).pipe(take(1)).subscribe(
        {
          next: (result: any) => {
            observer.next(result);
          },
          error: (err: any) => {
            console.error(err.message);
          },
          complete: () => {
            observer.complete();
          }
        });
    });
  }

  getApiListData(endPoint: string, params: string = null): Observable<any> {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    let url = null;
    if (params !== null) {
      url = this.endpointUrl + endPoint + '/' + params;
    } else {
      url = this.endpointUrl + endPoint;
    }
    // const httpOptions = {
    //   headers: new HttpHeaders()
    //     .append('Authorization', t)
    //     .append('Content-Type', 'application/json')
    //     .append('Accept', '*/*')
    // };
    return new Observable((observer) => {
      this.http.get<ResponseModel>(url).pipe(take(1)).subscribe(
        {
          next: (result: any) => {
            observer.next(result);
          },
          error: (err: any) => {
            console.error(err.message);
          },
          complete: () => {
            observer.complete();
          }
        });
    });
  }

  getTableDataWithParams(endPoint: string, params: string = null): any {
   //  const t = 'Bearer ' + this.storage.StorageGet('token');
    let url = null;
    if (params !== null) {
      url = this.endpointUrl + endPoint + '?' + params;
    } else {
      url = this.endpointUrl + endPoint;
    }

    // const httpOptions = {
    //   headers: new HttpHeaders()
    //     .append('Authorization', t)
    //     .append('Content-Type', 'application/json')
    //     .append('Accept', '*/*')
    // };

    return new Observable((observer) => {
      this.http.get(url).pipe(take(1)).subscribe(
        {
          next: (result: any) => {
            observer.next(result);
            // console.log(JSON.stringify(result));
          },
          error: (err: any) => {
            console.error(err.message);
          },
          complete: () => {
            observer.complete();
          }
        });
    });
  }

  async patch(endPoint: string, params: string, obj: object) {
   // const t = 'Bearer ' + this.storage.StorageGet('token');
    const url = this.endpointUrl + endPoint + '/' + params;
    // const headers = new HttpHeaders()
    //   .append('Authorization', t)
    //   .append('Content-Type', 'application/json')
    //   .append('Accept', '*/*');

    return lastValueFrom(await this.http.patch(url, obj), )
      .then((result: any) => {
        return result;
      })
      .catch((err: any) => {
        // do error stuff here - but in our case the interceptor catches all errors.
      });
  }

  async patchWithParamsNew(endPoint: string, params: string, obj: object) {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    const url = this.endpointUrl + endPoint + '/' + params;
    // const headers = new HttpHeaders()
    //   .append('Authorization', t)
    //   .append('Content-Type', 'application/json')
    //   .append('Accept', '*/*');

    return lastValueFrom(this.http.patch(url, obj), )
      .then((result: any) => {
        return result;
      })
      .catch((err: any) => {
        // do error stuff here - but in our case the interceptor catches all errors.
      });
  }


  deleteTableDataById(endPoint: string, params: string): any {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    const url = this.endpointUrl + endPoint + '/' + params;

    // const httpOptions = {
    //   headers: new HttpHeaders()
    //     .append('Authorization', t)
    //     .append('Content-Type', 'application/json')
    //     .append('Accept', '/')
    // };

    return new Observable((observer) => {
      this.http.delete(url).pipe(take(1)).subscribe(
        {
          next: (result: any) => {
            observer.next(result);
          },
          error: (err: any) => {
            console.error(err.message);
          },
          complete: () => {
            observer.complete();
          }
        });
    });

  }

  deleteTableDataByArray(endPoint: string, obj: object) {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    const url = this.endpointUrl + endPoint;
    const httpOptions = {
       // headers: new HttpHeaders()
       //  .append('Authorization', t)
       //  .append('Content-Type', 'application/json')
       //  .append('Accept', '/'),
       body: obj
     };
    return new Observable((observer) => {
      this.http.delete(url, httpOptions).pipe(take(1)).subscribe(
        {
          next: (result: any) => {
            observer.next(result);
          },
          error: (err: any) => {
            console.error(err.message);
          },
          complete: () => {
            observer.complete();
          }
        });
    });

  }

  putTableDataByArray(endPoint: string, obj: object) {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    // const url = this.endpointUrl + endPoint + '/' + params;
    const url = this.endpointUrl + endPoint;
    // const headers = new HttpHeaders()
    //   .append('Authorization', t)
    //   .append('Content-Type', 'application/json')
    //   .append('Accept', '*/*');

    return new Observable((observer) => {
      this.http.put(url, obj).pipe(take(1)).subscribe(
        {
          next: (result: any) => {
            observer.next(result);
          },
          error: (err: any) => {
            observer.next(err);
            // console.log(err);
            throwError(() => err);
          },
          complete: () => {
            observer.complete();
          }
        });
    });
  }

  // uses header Silent true to tell api send back a 418 which is picked off by error interceptor and not displayed; hence silent.
  putTableDataByArraySilent(endPoint: string, obj: object) {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    // const url = this.endpointUrl + endPoint + '/' + params;
    const url = this.endpointUrl + endPoint;
    const headers = new HttpHeaders()
      // .append('Authorization', t)
      // .append('Content-Type', 'application/json')
      // .append('Accept', '*/*')
      .append('Silent', 'true');

    return new Observable((observer) => {
      this.http.put(url, obj, {headers}).pipe(take(1)).subscribe(
        {
          next: (result: any) => {
            observer.next(result);
          },
          error: (err: any) => {
            observer.next(err);
            // console.log(err);
            throwError(() => err);
          },
          complete: () => {
            observer.complete();
          }
        });
    });
  }

  post(endPoint: string, obj: object) {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    // console.log('PostRequest: ' + t);
    const url = this.endpointUrl + endPoint;
    // const headers = new HttpHeaders()
    //   .append('Authorization', t)
    //   .append('Content-Type', 'application/json')
    //   .append('Accept', '*/*');

    return new Observable((observer) => {
      this.http.post(url, obj).pipe(take(1)).subscribe(
      {
        next: (result: any) => {
          observer.next(result);
        },
        error: (err: any) => {
          // this.toastr.error(err.message);
        },
        complete: () => {
          observer.complete();
        }
      });
    });
  }

  async postWithPromise(endPoint: string, obj: object) {
    // const t = 'Bearer ' + this.storage.StorageGet('token');
    const url = this.endpointUrl + endPoint;
    // console.log('post url: ' + url);
    // const headers = new HttpHeaders()
    //   .append('Authorization', t)
    //   .append('Content-Type', 'application/json')
    //   .append('Accept', '*/*');

    return lastValueFrom(await this.http.post(url, obj), )
      .then((result: any) => {
        return result;
      })
      .catch((err: any) => {
        // do error stuff here - but in our case the interceptor catches all errors.
      });
  }

}


