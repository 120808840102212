import {Component, Input, OnInit} from '@angular/core';
import {ResponseModel} from '../../../../../DataModels/response-model';
import {HttpErrorResponse} from '@angular/common/http';
import {TableDataService} from '../../../../../Services/tableData.service';
import {PermissionsService} from '../../../../../Services/permissions.service';

@Component({
  selector: 'app-location-item',
  templateUrl: './location-item.component.html',
  styleUrls: ['./location-item.component.css']
})
export class LocationItemComponent implements OnInit {

  @Input() locationID: string;

  isLoaded = false;
  searchTerm = '';

  permissionsAdmin = true;
  balance = [];
  totalQuantity = 0;
  totalBalance = 0;

  vm: any = {
    request: []
  };

  constructor(private tableDataService: TableDataService,
              public p: PermissionsService) { }

  ngOnInit() {
    this.getBalances();
  }

  private getBalances() {
    // this.getting = true;
    const urlParamsJson = {
      location_id: this.locationID,
      batch_type: 4,
      pageNumber: 0,
      pageSize: 999999,
    };

    const Params: string = this.encodeQueryData(urlParamsJson);

    this.tableDataService.getTableData('ItemBalances/ItemsBalancesByLocation', '?' + Params).subscribe((result: any[]) => {
        this.balance = result;
        // console.log('item bal: ' + result);
      },
      (error: HttpErrorResponse) => {
        // this.notificationService.error($localize`:Error loading Location Items @@errorloadingLocationItems:Error loading Location Items`);
        console.log(error + ' - ' + $localize`:Error loading Location Items @@errorloadingLocationItems:Error loading Location Items`);
      },
      () => {
        for (const item of this.balance) {
          this.totalQuantity += Number(item.quantity);
          this.totalBalance += Number(item.balance);
        }
      });
  }

  // getItemHistory(itemId, companyId, locationId) {
  getItemHistory(itemId, locationId) {
    this.vm.itemHistoryId = itemId;

    const asOfDate = new Date().toISOString();
    const coId = this.balance[0].company_id; // all the same co

    const urlParamsJson = {
      company_id: coId,
      location_id: locationId,
      count_date: asOfDate,
      item_id: itemId,
      pageNumber: 0,
      pageSize: 999999
    };

    const CountParam: string = this.encodeQueryData(urlParamsJson);
    // console.log('params: ' + CountParam);
    this.tableDataService.getTableData('Count/system/history', '?' + CountParam).subscribe((result: ResponseModel) => {
      // console.log('hist: ' + JSON.stringify(result));
      this.vm.itemHistory = result.Data;
      this.vm.request = result.Data;
    });
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '' || data[d] === 0) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

}


