import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TableDataService} from '../../../Services/tableData.service';
import {NotificationService} from '../../../Services/notification.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivityFeedModel} from '../../../DataModels/activity-feed-model';

@Component({
  selector: 'app-activity-feed-modal',
  templateUrl: './activity-feed-modal.component.html',
  styleUrls: ['./activity-feed-modal.component.scss']
})
export class ActivityFeedModalComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('closeButton') closeButton: ElementRef;
  searchText: string;

  @Input() src: any;

  activityFeedList: any;
  activityFeedCount = 0;

  showFeed = false;

  constructor(private table: TableDataService) {
  }

  ngOnInit() {
    // this.loadAuditTrail();
  }

  ngOnChanges() {
    this.loadActivityFeed();
  }

  loadActivityFeed() {

    this.activityFeedList = [];
    this.activityFeedCount = 0;

    if (this.src) {
      // console.log('src: ' + JSON.stringify(this.src));
      this.src.forEach((rec) => {
          this.activityFeedList.push(rec);
      });
      this.activityFeedCount = this.activityFeedList.length;
    }

    // if (this.src === '') {
    //   // console.log('no rec: ' + this.src);
    //   this.table.getTableData('audit_trail').subscribe(
    //     result => {
    //       this.activityFeedList = result;
    //       this.activityFeedCount = this.activityFeedList.length;
    //       // console.log('empty src:' + this.src);
    //     },
    //     (error: HttpErrorResponse) => {
    //       console.log(error);
    //       // this.toast.error($localize`:Audit Trail Error @@auditTrailError:Error loading Audit Trail Data`);
    //     },
    //     () => {
    //     }
    //   );
    // }

    // if (this.src !== '') {
    //   // console.log('got a rec: ' + this.src);
    //   this.table.getTableDataWithParams('audit_trail/recid', 'recId=' + this.src).subscribe(
    //     result => {
    //       this.activityFeedList = result;
    //       this.activityFeedCount = this.activityFeedList.length;
    //       // console.log('audit trail recid:' + this.src);
    //       // console.log('audit trail result:' + JSON.stringify(result));
    //     },
    //     (error: HttpErrorResponse) => {
    //       console.log(error);
    //     },
    //     () => {
    //     }
    //   );
    //
    // }
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}
