import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateInput, EventClickArg, EventDropArg, EventInput } from '@fullcalendar/core';
import { DateClickArg, Draggable } from '@fullcalendar/interaction';
import { TableDataService} from '../../../Services/tableData.service';
import { StorageService} from '../../../Services/storage.service';
import { ExternalEvent } from '../shared/event.model';

// component
import { EventComponent } from '../event/event.component';

// data
import { calendarEvents, externalEvents } from '../shared/data';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../../../Services/notification.service';

// calendar plugins
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {Title} from '@angular/platform-browser';
import {SysUserModel} from '../../../DataModels/sys-user-model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {
  calendarVisible = true;

  // pageTitle: BreadcrumbItem[] = [];
  calendarOptions: CalendarOptions = {};
  selectedDay: any = {};
  isEditable = false;
  calendarEventsData: EventInput[] = [];
  dataFromDbEventData: EventInput[] = [];
  testEventsToCal: EventInput[] = [];
  event: EventInput = {};
  externalEventsData: ExternalEvent[] = [];
  user: SysUserModel;

  @ViewChild('calendar')
  calendarComponent!: FullCalendarComponent;

  @ViewChild('eventModal', { static: true }) eventModal!: EventComponent;

  constructor(
    private tableDataService: TableDataService,
    private localStorage: StorageService,
    private notificationService: NotificationService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // this.pageTitle = [{ label: 'Apps', path: '/' }, { label: 'Calendar', path: '/', active: true }];

    // get events data
    this.titleService.setTitle($localize`:Schedule@@schedule:Schedule`);
    this._fetchData();
    this.getSchedulerData();


    // this.calendarEventsData = [...this.dataFromDbEventData];
    // console.log(JSON.stringify(this.calendarEventsData));

    // full calendar config
    this.calendarOptions = {
      themeSystem: 'bootstrap',
      bootstrapFontAwesome: false,
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
        prev: 'Prev',
        next: 'Next'
      },
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          timeGridPlugin,
          listPlugin
        ],
      initialView: 'dayGridMonth',
      handleWindowResize: true,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      },
      events: [...this.calendarEventsData],
      editable: true,
      droppable: true, // this allows things to be dropped onto the calendar
      selectable: true,
      dateClick: this.handleDateClick.bind(this),
      eventClick: this.handleEventClick.bind(this),
      drop: this.onDrop.bind(this),
      eventDrop: this.onEventDrop.bind(this)
    };

    // console.log('Events at init: ' + JSON.stringify(this.calendarEventsData));
  }

  ngAfterViewInit(): void {
    new Draggable(document.getElementById('external-events')!, {
      itemSelector: '.external-event',
    });
  }

  getSchedulerData(): any {
    this.user = JSON.parse(localStorage.getItem('currentUser'))

    this.tableDataService.getTableData('reminder', '?user_id=' +
      this.user.id + '&include_archived=false').subscribe(
      (result: any[]) => {
        this.dataFromDbEventData = result;

        // console.log('old Events: ' + JSON.stringify(result));

        this.dataFromDbEventData.forEach((currentValue, index) => {
          this.calendarEventsData.push({
            id: currentValue.id,
            title: currentValue.description,
            start: currentValue.start_date,
            end: currentValue.end_date,
            classNames: 'bg-success',
            extendedProps: {
              user_id: currentValue.user_id,
              name: currentValue.name,
              message: currentValue.message,
              completed: currentValue.completed,
              archived: currentValue.archived,
              priority: currentValue.priority,
              audit_sequence: currentValue.audit_sequence
            },
          });
        });

        // console.log('new Events from db after foreach: ' + JSON.stringify(this.calendarEventsData));

        // new
          // id: '2',
          //   title: 'Interview - Backend Engineer',
          //   start: new Date(),
          //   end: new Date().setDate(new Date().getDate() + 1),
          //   classNames: 'bg-success',

          // old
          // id: '4237d286-59a2-4f2a-b4cb-ec7a6eb429c1',
          //   user_id: 'd5e2929e-e9c3-44dd-a232-0e7d5026c386',
          //   user_name: 'Lee',
          //   name: 'empty',
          //   description: 'test reminder - one rec test',
          //   start_date: '2020-05-22T12:00:00',
          //   end_date: '2020-05-22T12:30:00',
          //   completed: false,
          //   archived: false,
          //   priority: '1',
          //   audit_sequence: 12
       // });

          },
          (error: HttpErrorResponse) => {
            this.notificationService.error($localize`:Error loading Scheduler Data @@errorloadingSchedulerData:Error loading Scheduler Data`);
            console.log(error);
          },
          () => {
            // console.log('completed: ');
          });
  }

  /**
   *  fetches event data
   */
  _fetchData(): void {
    this.calendarEventsData = [...calendarEvents];
    this.externalEventsData = [...externalEvents];

    // console.log('combined Events: ' + JSON.stringify(this.calendarEventsData));
  }

  /**
   * Opens event modal
   * @param title title of modal
   * @param data data to be used in modal
   */
  openEventModal(title: string = '', data: any = {}) {
    this.eventModal.openModal(title, data);
  }

  /**
   * Creates new event for today
   */
  createNewEvent(): void {
    this.event = { id: String(this.calendarEventsData.length + 1), title: '', classNames: '', category: 'bg-danger', start: new Date() };
    this.isEditable = false;
    this.openEventModal('Add New Event', this.event);
  }


  /**
   * adds external events by Drag n Drop
   * @param event dropped event
   */
  onDrop(event: any): void {
    const draggedEl = event.draggedEl;
    const newEvent = {
      id: String(this.calendarEventsData.length + 1),
      title: draggedEl.innerText,
      start: event.date,
      classNames: 'bg-' + draggedEl.getAttribute('data-type')
    };
    // save new event
    this.calendarEventsData.push(newEvent);
    this.calendarOptions.events = [...this.calendarEventsData];
  }

  /**
   * on event drop between calendar
   */
  onEventDrop(arg: EventDropArg): void {
    let modifiedEvents = [...this.calendarEventsData];
    const idx = modifiedEvents.findIndex((e: any) => e['id'] === arg.event.id);
    modifiedEvents[idx]['title'] = arg.event.title;
    modifiedEvents[idx]['className'] = arg.event.classNames;
    modifiedEvents[idx]['start'] = arg.event.start as DateInput;
    modifiedEvents[idx]['end'] = arg.event.end as DateInput;
    this.calendarEventsData = modifiedEvents;
    this.isEditable = false;
  }


  /**
   * Handling date click on calendar
   * @param arg DateClickArg
   */
  handleDateClick(arg: DateClickArg): void {
    this.selectedDay = arg;
    this.event = { id: String(this.calendarEventsData.length + 1), title: '', classNames: '', category: 'bg-danger', start: this.selectedDay.date };
    this.isEditable = false;
    this.openEventModal('Add New Event', this.event);
  }


  /**
   * Handling click on event on calendar
   * @param arg EventClickArg
   */
  handleEventClick(arg: EventClickArg): void {
    const event = arg.event;
    this.event = { id: String(event.id), title: event.title, classNames: event.classNames, category: event.classNames[0] };
    this.isEditable = true;
    this.openEventModal('Edit Event', this.event);
  }

  /**
   * Handle the event save
   * @param newEvent new event
   */
  handleEventSave(newEvent: EventInput): void {

    if (this.isEditable) {
      let modifiedEvents = [...this.calendarEventsData];
      const eventIndex = modifiedEvents.findIndex((event) => event.id === newEvent.id);
      this.calendarEventsData[eventIndex].title = newEvent.title;
      this.calendarEventsData[eventIndex].classNames = newEvent.category;
      this.calendarEventsData = modifiedEvents;
      this.isEditable = false;
    }
    else {
      let nEvent = {
        id: newEvent.id,
        title: newEvent.title,
        start: newEvent.start,
        classNames: newEvent.category
      };
      this.calendarEventsData.push(nEvent);
    }
    this.calendarOptions.events = [...this.calendarEventsData];
  }

  /**
   * Deletes calendar event
   * @param deleteEvent event to be deleted
   */
  handleEventDelete(deleteEvent: EventInput): void {
    let modifiedEvents = [...this.calendarEventsData];
    const eventIndex = modifiedEvents.findIndex((event) => event.id === deleteEvent.id);
    modifiedEvents.splice(eventIndex, 1);
    this.calendarEventsData = modifiedEvents;
    this.calendarOptions.events = [...this.calendarEventsData];
  }

}
