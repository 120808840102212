import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {StorageService} from '../../../../Services/storage.service';
import {TableDataService} from '../../../../Services/tableData.service';
import {stringify} from 'querystring';
import {AppConfigService} from '../../../../Services/app-config.service';
import {Title} from '@angular/platform-browser';
import {DateFilterService} from '../../../../Services/dateFilter.service';
import {FilterPipe} from 'ngx-filter-pipe';
import {NotificationService} from '../../../../Services/notification.service';
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../../../Services/email.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {EmailPassDataServiceService} from '../../../../Services/email-pass-data-service.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-consolidated-income-statement',
  templateUrl: './consolidated-income-statement.component.html',
  styleUrls: ['./consolidated-income-statement.component.css']
})
export class ConsolidatedIncomeStatementComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('EmailContent', {static: false}) EmailContent: ElementRef;
  @ViewChild('ExcelExport', {static: false}) ExcelExport: ElementRef;
  private rptName: string;
  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    rf1Data: {},
    rf2Data: {},
    rf3Data: {},
    rf4Data: {},
    rf5Data: {},
    request: {
      toDate: null,
      fromDate: null,
      location_group_id: false,
      columns: '',
      company_id: null,
      location_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      account_type_id: null,
      account_parent_id: null,
      account_subtotal_id: null,
      includeUnposted: false,
      currency_id: '',
      item_id: '',
      entity_type_id: '',
      entity_state: '',
      entity_city: ''
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: ''
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    rfType: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    monthCount: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    pdfFileName: null,
    totalCurrentRevenue: null,
    totalPriorRevenue: null,
    marginCurrent: null,
    marginPrior: null,
    fromDateError: null,
    accountTypeName: null,
    accountParentName: null,
    accountSubtotalName: null,
    accountSubtotal: null,
    accountParents: null,
    accountType: null,
    companyName: null,
    locationName: null,
    locationGroupName: null,
    rf1Name: null,
    rf2Name: null,
    rf3Name: null,
    rf4Name: null,
    rf5Name: null,
    accountInput: null,
    entityInput: null,
    Preferences: null,
    permissions: null,
  };

  public submitted = false;
  sub: any;

  constructor(public localStorage: StorageService,
              public tableDataService: TableDataService,
              private titleService: Title,
              public appConfigService: AppConfigService,
              public dateFilter: DateFilterService,
              public notification: NotificationService,
              public aEmail: EmailService,
              private fb: UntypedFormBuilder,
              private emailData: EmailPassDataServiceService,
              private router: Router) {
    // this.initValue();
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  ngOnInit() {
    // this.initRequestObj();
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    // this.EmailInit();
    this.rptName = $localize`:Consolidated Income Statement @@consolidatedIncomeStatement:Consolidated Income Statement`;
  }

  // initValue() {
  //   this.vm.report = [];
  //   this.vm.email = {};
  //   this.vm.email.fromemail = 'contractor11@acctxerp.com';
  // }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  ngAfterViewChecked() {
    if (this.vm.report.length > 0) {
      // const div = this.ExcelExport.nativeElement.innerHTML;
      const div = document.getElementById('excel-export');
      // this.localStorage.StorageSet('reportData', div);
      setTimeout(() => {
        this.emailData.clearAllEmailPassData();
        this.emailData.emailInfo.aRecordId = '';
        this.emailData.emailInfo.aMessage = '';
        this.emailData.emailInfo.aEntity = '';
        this.emailData.emailInfo.aBatchTypeId = '99';
        this.emailData.emailInfo.aReportString = div.outerHTML;
        this.emailData.emailInfo.aReportOrDocName = 'Consolidated Income Statement';
        this.emailData.loadEmailDataToPassMailer();
      }, 3000);
    }
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
    this.tableDataService.getTableData('currency?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.currency = result.Data;
    });
    this.tableDataService.getTableData('location?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.location = result.Data;
    });
    this.tableDataService.getTableData('location_group?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.location_group = result.Data;
    });
    this.tableDataService.getTableData('reporting_field_type?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rfType = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf1&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf1 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf2&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf2 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf3&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf3 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf4&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf4 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf5&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf5 = result.Data;
    });
  }

/*
  EmailInit() {
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail');
  }
*/


  calcTotals() {
    this.vm.totalDebits = this.vm.totalCredits = this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
    for (const [x, value] of Object.entries(this.vm.report)) {
      if (this.vm.report[x].title === 'Revenue') {
        this.vm.totalCurrentRevenue = Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.netIncomeCurrent += this.vm.totalCurrentRevenue;
        this.vm.totalPriorRevenue = Number(this.vm.report[x].totalPriorPeriod);
        this.vm.netIncomePrior += this.vm.totalPriorRevenue;
      } else {
        if (this.vm.report[x].title === 'Costs') {
          this.vm.marginCurrent = this.vm.totalCurrentRevenue - Number(this.vm.report[x].totalCurrentPeriod);
          this.vm.marginPrior = this.vm.totalPriorRevenue - Number(this.vm.report[x].totalPriorPeriod);
        }
        this.vm.netIncomeCurrent -= Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.netIncomePrior -= Number(this.vm.report[x].totalPriorPeriod);
      }
    }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

 /* emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }*/

  close() {
    this.router.navigateByUrl('/home').then();
  }

  update() {
    this.vm.report = [];
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.fromDate) {
      errorDescription += $localize`:A from date is required. @@afromdateisrequired:A from date is required.`+' ';
      this.vm.fromDateError = true;
    }
    if (!this.vm.request.toDate) {
      errorDescription += $localize`:A To date is required. @@aTodateisrequired:A To date is required.`+' ';
      this.vm.toDateError = true;
    }

    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);

    if (tempToDate < tempFromDate) {
      errorDescription += $localize`:From date must be less than the To date @@fromdatemustbelessthantheTodate:From date must be less than the To date`;
      this.vm.toDateError = true;
      this.vm.fromDateError = true;
    }
    if (errorDescription) {
      this.notification.warning(errorDescription);
    } else {

      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;


      let paramString = '';
      if (this.vm.request.fromDate) {
        paramString += '&fromDate=' + this.vm.request.fromDate;
      }
      if (this.vm.request.toDate) {
        paramString += '&toDate=' + this.vm.request.toDate;
      }
      if (this.vm.request.company_id) {
        paramString += '&company_id=' + this.vm.request.company_id;
      }
      if (this.vm.request.location_id) {
        paramString += '&location_id=' + this.vm.request.location_id;
      }
      if (this.vm.request.location_group_id) {
        paramString += '&location_group_id=' + this.vm.request.location_group_id;
      }
      if (this.vm.request.rf_id_1) {
        paramString += '&rf_id_1=' + this.vm.request.rf_id_1;
      }
      if (this.vm.request.rf_id_2) {
        paramString += '&rf_id_2=' + this.vm.request.rf_id_2;
      }
      if (this.vm.request.rf_id_3) {
        paramString += '&rf_id_3=' + this.vm.request.rf_id_3;
      }
      if (this.vm.request.rf_id_4) {
        paramString += '&rf_id_4=' + this.vm.request.rf_id_4;
      }
      if (this.vm.request.rf_id_5) {
        paramString += '&rf_id_5=' + this.vm.request.rf_id_5;
      }

      if (paramString[0] == '&') {
        paramString = paramString.substring(1);
      }

      this.tableDataService.getTableData('income_statement', '?' + paramString).subscribe((result: any[]) => {
        this.vm.report = result;
        this.vm.filtered = false;
        this.vm.showFilters = this.vm.buttonsDisabled = false;
        this.calcTotals();
        paramString = 'fromDate=' + this.vm.request.fromDate + '&toDate=' + this.vm.request.toDate;
        let tempNetIncome = 0;
        let tempMargin = 0;
        if (this.vm.request.columns === 'company') {
          this.vm.filtered = true;
          this.tableDataService.getTableData('IncomeStatementCompanyConsolidation', '?' + paramString).subscribe((companyData: any[]) => {
            this.vm.companyData = companyData;
            for (const [x, value] of Object.entries(this.vm.companyData)) {
              if (this.FilterObject(this.vm.company, {id: this.vm.companyData[x].company_id})[0] != null) {
                // if (angular.isDefined($filter('filter')(this.vm.company, {id: this.vm.companyData[x].company_id}, true)[0])) {
                this.vm.companyData[x].name = this.FilterObject(this.vm.company, {id: this.vm.companyData[x].company_id})[0].name;
              } else {
                this.vm.companyData[x].name = 'unknown';
                console.log(this.vm.companyData[x].company_id);
              }
              for (const [y, value2] of Object.entries(this.vm.companyData[x].subtotals)) {
                this.vm.companyData[x].subtotals[y].amount = 0;
                for (const [z, value3] of Object.entries(this.vm.companyData[x].accounts)) {
                  if (this.vm.companyData[x].accounts[z].subtotal_id == this.vm.companyData[x].subtotals[y].subtotal_id) {
                    this.vm.companyData[x].subtotals[y].amount += this.vm.companyData[x].accounts[z].amount;
                  }
                }
              }

              for (const [y, value2] of Object.entries(this.vm.companyData[x].parents)) {
                this.vm.companyData[x].parents[y].amount = 0;
                this.vm.companyData[x].revenueAmount = 0;
                tempNetIncome = 0;
                tempMargin = 0;
                for (const [z, value3] of Object.entries(this.vm.companyData[x].subtotals)) {
                  if (this.vm.companyData[x].subtotals[z].parent_id == this.vm.companyData[x].parents[y].parent_id) {
                    this.vm.companyData[x].parents[y].amount += this.vm.companyData[x].subtotals[z].amount;
                  }
                  if (this.vm.companyData[x].subtotals[z].parent_id === 'revenue') {
                    tempNetIncome += this.vm.companyData[x].subtotals[z].amount;
                    tempMargin += this.vm.companyData[x].subtotals[z].amount;
                    this.vm.companyData[x].revenueAmount += this.vm.companyData[x].subtotals[z].amount;

                  }
                  if (this.vm.companyData[x].subtotals[z].parent_id === 'costs-of-sales') {
                    tempMargin -= this.vm.companyData[x].subtotals[z].amount;
                  }
                  if (this.vm.companyData[x].subtotals[z].parent_id === 'operating-expenses' ||
                    this.vm.companyData[x].subtotals[z].parent_id === 'costs-of-sales') {
                    tempNetIncome -= this.vm.companyData[x].subtotals[z].amount;
                  }
                }
              }
              this.vm.companyData[x].subtotals.push({
                subtotal_id: 'net-income',
                amount: tempNetIncome
              });
              this.vm.companyData[x].subtotals.push({
                subtotal_id: 'margin',
                amount: tempMargin,
              });
            }
          });
        }
        else if (this.vm.request.columns === 'location') {
          this.vm.filtered = true;
          if (this.vm.request.company_id) {
            paramString += '&company_id=' + this.vm.request.company_id;
          }
          tempNetIncome = 0;
          tempMargin = 0;

          this.tableDataService.getTableData('IncomeStatementLocationConsolidation', '?' + paramString).subscribe((LocationData: any[]) => {
            this.vm.locationData = LocationData;
            for (const [x, value] of Object.entries(this.vm.locationData)) {
              for (const [y, value2] of Object.entries(this.vm.locationData[x].subtotals)) {
                if (typeof this.FilterObject(this.vm.location, {id: this.vm.locationData[x].location_id})[0] !== 'undefined'
                  && this.FilterObject(this.vm.location, {id: this.vm.locationData[x].location_id})[0] != null
                  && typeof this.FilterObject(this.vm.location, {id: this.vm.locationData[x].location_id})[0].name !== 'undefined') {
                  // if (angular.isDefined($filter('filter')(this.vm.location, {id: this.vm.locationData[x].location_id}, true)[0])) {
                  this.vm.locationData[x].name = this.FilterObject(this.vm.location, {id: this.vm.locationData[x].location_id})[0].name;
                } else if (this.vm.locationData[x].location_id == 'NULL') {
                  this.vm.locationData[x].name = 'z-blank';
                } else {
                  this.vm.locationData[x].name = 'unknown';
                  console.log(this.vm.locationData[x].location_id);
                }
                this.vm.locationData[x].subtotals[y].amount = 0;
                for (const [z, value3] of Object.entries(this.vm.locationData[x].accounts)) {
                  if (this.vm.locationData[x].accounts[z].subtotal_id == this.vm.locationData[x].subtotals[y].subtotal_id) {
                    this.vm.locationData[x].subtotals[y].amount += this.vm.locationData[x].accounts[z].amount;
                  }
                }
              }
              for (const [y, value2] of Object.entries(this.vm.locationData[x].parents)) {
                this.vm.locationData[x].parents[y].amount = 0;
                this.vm.locationData[x].revenueAmount = 0;
                tempNetIncome = 0;
                tempMargin = 0;
                for (const [z, value3] of Object.entries(this.vm.locationData[x].subtotals)) {
                  if (this.vm.locationData[x].subtotals[z].parent_id == this.vm.locationData[x].parents[y].parent_id) {
                    this.vm.locationData[x].parents[y].amount += this.vm.locationData[x].subtotals[z].amount;
                  }
                  if (this.vm.locationData[x].subtotals[z].parent_id === 'revenue') {
                    tempNetIncome += this.vm.locationData[x].subtotals[z].amount;
                    tempMargin += this.vm.locationData[x].subtotals[z].amount;
                    this.vm.locationData[x].revenueAmount += this.vm.locationData[x].subtotals[z].amount;
                  }
                  if (this.vm.locationData[x].subtotals[z].parent_id === 'costs-of-sales') {
                    tempMargin -= this.vm.locationData[x].subtotals[z].amount;
                  }
                  if (this.vm.locationData[x].subtotals[z].parent_id === 'operating-expenses' ||
                    this.vm.locationData[x].subtotals[z].parent_id === 'costs-of-sales') {
                    tempNetIncome -= this.vm.locationData[x].subtotals[z].amount;
                  }
                }
              }
              this.vm.locationData[x].subtotals.push({
                subtotal_id: 'net-income',
                amount: tempNetIncome
              });
              this.vm.locationData[x].subtotals.push({
                subtotal_id: 'margin',
                amount: tempMargin,
              });
            }

          });
        }
        this.vm.pdfFileName = 'Income Statement ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
        if (this.vm.request.account_type_id) {
          this.vm.filtered = true;
          this.vm.accountTypeName = this.FilterObject(this.vm.accountType, {id: this.vm.request.account_type_id})[0].name;
        } else if (this.vm.request.account_parent_id) {
          this.vm.filtered = true;
          this.vm.accountParentName = this.FilterObject(this.vm.accountParents, {id: this.vm.request.account_parent_id})[0].name;

        } else if (this.vm.request.account_subtotal_id) {
          this.vm.filtered = true;
          this.vm.accountTypeName = this.FilterObject(this.vm.accountSubtotal, {id: this.vm.request.account_subtotal_id})[0].name;
        }

        if (this.vm.request.company_id) {
          this.vm.filtered = true;
          this.vm.companyName = this.FilterObject(this.vm.company, {id: this.vm.request.company_id})[0].name;
        } else {
          this.vm.companyName = '';
        }
        if (this.vm.request.location_id) {
          this.vm.filtered = true;
          this.vm.locationName = this.FilterObject(this.vm.location, {id: this.vm.request.location_id})[0].name;
        } else {
          this.vm.locationName = '';
        }
        if (this.vm.request.location_group_id) {
          this.vm.filtered = true;
          this.vm.locationGroupName = this.FilterObject(this.vm.locationGroup, {id: this.vm.request.location_group_id})[0].name;
        } else {
          this.vm.locationGroupName = '';
        }
        if (this.vm.request.rf_id_1) {
          this.vm.filtered = true;
          this.vm.rf1Name = this.FilterObject(this.vm.rf1, {id: this.vm.request.rf_id_1})[0].name;
        } else {
          this.vm.rf1Name = '';
        }
        if (this.vm.request.rf_id_2) {
          this.vm.filtered = true;
          this.vm.rf2Name = this.FilterObject(this.vm.rf2, {id: this.vm.request.rf_id_2})[0].name;
        } else {
          this.vm.rf2Name = '';
        }
        if (this.vm.request.rf_id_3) {
          this.vm.filtered = true;
          this.vm.rf3Name = this.FilterObject(this.vm.rf3, {id: this.vm.request.rf_id_3})[0].name;
        } else {
          this.vm.rf3Name = '';
        }
        if (this.vm.request.rf_id_4) {
          this.vm.filtered = true;
          this.vm.rf4Name = this.FilterObject(this.vm.rf4, {id: this.vm.request.rf_id_4})[0].name;
        } else {
          this.vm.rf4Name = '';
        }
        if (this.vm.request.rf_id_5) {
          this.vm.filtered = true;
          this.vm.rf5Name = this.FilterObject(this.vm.rf5, {id: this.vm.request.rf_id_5})[0].name;
        } else {
          this.vm.rf5Name = '';
        }

      });
      return false;
    }
  }

  parseInt(val) {
    return parseInt(val, 10);
  }

  initRequestObj() {
    this.vm.request = {
      toDate: null,
      fromDate: null,
      location_group_id: false,
      columns: '',
      company_id: null,
      location_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      account_type_id: null,
      account_parent_id: null,
      account_subtotal_id: null,
      includeUnposted: false,
      currency_id: '',
      item_id: '',
      entity_type_id: '',
      entity_state: '',
      entity_city: ''
    };
  }

  filterColumnsChange(clear = false) {
    let inventoryItemInput: any;
    this.vm.report = [];
    this.vm.buttonsDisabled = false;
    if (clear) {
      this.vm.showEmail = false;
      this.vm.accountInput = this.vm.entityInput = inventoryItemInput = '';
      this.vm.report = [];
      this.initRequestObj();
    }
  }

  if

  Print() {
    return window.print();
  }
}
