import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '../../../../../Services/email.service';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { DataExportService } from '../../../../../Services/dataExport.service';
import { ResponseModel } from '../../../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../../../Services/email-pass-data-service.service';
import { BsToastService } from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-balance-report',
  templateUrl: './balance-report.component.html',
  styleUrls: ['./balance-report.component.css'],
})
export class BalanceReportComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public notification: BsToastService,
    public appConfigService: AppConfigService,
    public route: ActivatedRoute,
    public router: Router,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    public excelExporter: DataExportService,
    private emailData: EmailPassDataServiceService
  ) {
    this.timeOut = this.appConfigService.toastrWarnTimeOut;
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  emailForm: UntypedFormGroup;
  private rptName: string;
  timeOut = 0;
  public urlParams: any = [];
  public emailInvalidMsg: string;

  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    subLedger: '',
    request: {
      toDate: null,
      fromDate: null,
      location_group_id: null,
      columns: '',
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      balanceInput: null,
    },
    showSpecificDates: null,
    buttonsDisabled: false,
    d: {
      date: '',
      name: '',
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    location_group: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    monthCount: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    Preferences: null,
    permissions: null,
    currentMonthMargin: 0,
    priorMonthMargin: 0,
    currentPeriodMargin: 0,
    priorPeriodMargin: 0,
    account: [],
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    caclutedDate: null,
    toDateTitle: null,
    fromDateTitle: null,
    priorToDateTitle: null,
    priorToDateLink: null,
    priorMonthFromDateLink: null,
    currentMonthFromDateLink: null,
    priorFromDateLink: null,
    priorFromDateTitle: null,
    accountParents: [],
    accountSubtotal: [],
    inventoryItem: [],
    accountType: [],
    inventoryItemName: [],
    entityState: [],
    entityCity: [],
    accountName: [],
    balance: [],
    itemHistory: [],
    itemHistoryId: null,
    accountInput: null,
    accountId: null,
    bankId: null,
    companyId: null,
    currencyId: null,
    entityId: null,
    entityTypeId: null,
    locationId: null,
    locationGroupId: null,
    paymentTypeId: null,
    marginId: null,
    accountTypeName: null,
    accountParentName: null,
    accountSubtotalName: null,
    dataType: null,
    bankName: null,
    companyName: null,
    locationName: null,
    inventoryItemInput: '',
    balanceInput: '',
    startLocationName: null,
    balance_email: '',
    exportToExcel: true,
  };
  public submitted = false;

  ngAfterViewChecked() {
    // sets data for email of report
    const div = document.getElementById('excel-export');

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Balance Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, 'Inventory-Balance-Report.xlsx');
  }

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Inventory Balance Report @@InventoryBalanceReport:Inventory Balance Report`;
  }

  onSubmit() {
    this.emailForm.reset();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.tableDataService
      .getTableData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });

    // form modal - passed in vm
    this.vm.inventoryItem = [];
    this.tableDataService
      .getApiListData('item', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.inventoryItem = result.Data;
      });

    // if (this.localStorage.StorageGet('company', '')) {
    //   this.vm.company = this.localStorage.StorageGet('company', '');
    // } else {
    //   this.localStorage.StorageSet('company', this.vm.company);
    // }
    // if (this.localStorage.StorageGet('location', '')) {
    //   this.vm.location = this.localStorage.StorageGet('location', '');
    // } else {
    //   this.localStorage.StorageSet('location', this.vm.location);
    // }
    // if (this.localStorage.StorageGet('currency', '')) {
    //   this.vm.currency = this.localStorage.StorageGet('currency', '');
    // } else {
    //   this.localStorage.StorageSet('currency', this.vm.currency);
    // }

    if (this.urlParams.locationId) {
      this.vm.request.location_id = this.urlParams.locationId;
    }

    if (
      this.vm.request.toDate &&
      this.vm.request.fromDate &&
      (this.urlParams.companyId || this.urlParams.currencyId || this.urlParams.locationId)
    ) {
      this.update();
    }
  }

  Print() {
    return window.print();
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  update() {
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.toDate) {
      this.vm.toDateError = true;
      errorDescription += $localize`:Date is required. @@dateisrequired:Date is required.` + ' ';
    }
    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    if (errorDescription) {
      this.notification.showWarningToast(errorDescription);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.dateError = false;
      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;

      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
      const urlParamsJson = {
        toDate: this.vm.request.toDate,
        company_id: this.vm.request.company_id,
        location_id: this.vm.request.location_id,
        currency_id: this.vm.request.currency_id,
        pageNumber: 0,
        pageSize: 999999,
      };

      // console.log('report params: ' + JSON.stringify(urlParamsJson));
      const BalanceReportParam: string = this.encodeQueryData(urlParamsJson);

      this.tableDataService
        .getTableData('InventoryBalance', '?' + BalanceReportParam)
        .subscribe((result: ResponseModel) => {
          const data: any = result.Data;
          this.vm.report = data;
          // console.log('report');
          // console.log(this.vm.report);
          this.vm.showFilters = this.vm.buttonsDisabled = false;
          this.vm.emailed = true;
          this.calcTotals();
          if (
            (this.vm.request.company_id && this.vm.company.length > 1) ||
            this.vm.request.location_id ||
            (this.vm.request.currency_id && this.vm.currency.length > 1)
          ) {
            this.vm.filtered = true;
          }
          // console.log(result);
        });
    }
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.vm.inventoryItemInput = '';
    this.vm.report = [];
    this.vm.request = {
      toDate: null,
      fromDate: null,
      location_group_id: null,
      columns: '',
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      balanceInput: null,
    };
    this.vm.filtered = false;
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  FilterObject(value, filterObj, test = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  updateLocation() {
    this.vm.buttonsDisabled = true;
    this.vm.startLocationName = 0;
    this.localStorage.StorageSet('balance', '');
    this.tableDataService.getTableData('balance', '?state=active').subscribe((result) => {
      this.vm.balance = result;
      this.vm.buttonsDisabled = false;
    });
  }

  selectLocationModal(balanceName) {
    this.vm.request.location_id = '';
    if (this.vm.request.location_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.balance)) {
        if (this.vm.balance[y].name == balanceName) {
          this.vm.request.balanceInput = this.vm.balanceInput = balanceName;
          this.vm.request.location_id = this.vm.balance[y].id;
          this.vm.balance_email = this.vm.balance[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  ngOnDestroy() {
    // console.log('destroy');
  }
  getItemHistory(itemId, companyId, locationId) {
    this.vm.itemHistoryId = itemId;

    const urlParamsJson = {
      company_id: companyId,
      location_id: locationId,
      count_date: this.vm.request.toDate,
      item_id: itemId,
      pageNumber: 0,
      pageSize: 999999,
    };

    const CountParam: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getTableData('Count/system/history', '?' + CountParam).subscribe((result: ResponseModel) => {
      this.vm.itemHistory = result.Data;
      // console.log(this.vm.itemHistory);
    });
  }

  testLocation() {
    const balanceIdStart = this.vm.request.location_id;
    if (!this.vm.balanceInput || this.vm.balanceInput == '') {
      this.vm.request.location_id = '';
    } else {
      if (this.FilterObject(this.vm.balance, { name: this.vm.balanceInput }).length > 0) {
        const locationId = this.FilterObject(this.vm.balance, { name: this.vm.balanceInput }, true)[0].id;
        const balanceName = this.FilterObject(this.vm.balance, { name: this.vm.balanceInput }, true)[0].name;
        const balanceEmail = this.FilterObject(this.vm.balance, { name: this.vm.balanceInput }, true)[0].email;
        if (locationId != '' || locationId != null) {
          this.vm.request.location_id = locationId;
          this.vm.balanceInput = balanceName;
          this.vm.balance_email = balanceEmail;
        } else {
          this.vm.request.location_id = '';
          this.vm.balance_email = '';
        }
      } else {
        this.vm.request.location_id = '';
        this.vm.balance_email = '';
      }
    }
    if (balanceIdStart != this.vm.request.location_id) {
      this.vm.report = [];
    }
  }

  calcTotals() {}
}
