import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { AppConfigService } from '../../../Services/app-config.service';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import { EntryListModel } from '../../../DataModels/entry-list-model';
import { ResponseModel } from '../../../DataModels/response-model';
import {ViewportScroller } from '@angular/common';
import {Subject, Subscription} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';
import {BsToastService} from '../../../Services/bs-toast-service';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';

@Component({
  selector: 'app-entry-list',
  templateUrl: './entry-list.component.html',
  styleUrls: ['./entry-list.component.css']
})
export class EntryListComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private vps: ViewportScroller,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    public confirmDialogService: ConfirmDialogService) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    this.vm.entryFilter = { text: '' };
    this.vm.batches = [];
    this.vm.selectItems = false;

  }
  entryList: EntryListModel;
  subscriptionGroup: Subscription[] = [];
  isLocked = '';

  public vm: any = {
    title: $localize`:Journal Entries @@journalEntries:Journal Entries`,
    Preferences: [],
    permissions: [],
    listData: [],
    listInitiated: false,
    sysUserFilter: { text: null },
    formRouter: '/transactions/entry/form'
  };
  isLoaded = false;
  EntryCount = 0;
  serverSearchTerm = '';

  checkAll = false;
  selectedRecords = [];

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngOnInit() {
    this.subscriptionGroup.push(this.route.params.subscribe(params => {
      this.init();
    }));
  }

  init() {
    this.vm.owingSelected = this.vm.totalSelected = 0;
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.batchesHidden = !this.vm.Preferences.ShowBatches;

    if (!this.vm.permissions.EditReceivableInvoices && !this.vm.permissions.ViewReceivableInvoices &&
      !this.vm.permissions.EditPayableInvoices && !this.vm.permissions.ViewPayableInvoices && !this.vm.permissions.EditQuotes &&
      !this.vm.permissions.ViewQuotes && !this.vm.permissions.EditSalesOrders && !this.vm.permissions.ViewSalesOrders &&
      !this.vm.permissions.EditPurchaseOrders && !this.vm.permissions.ViewPurchaseOrders && !this.vm.permissions.Admin) {
      document.location.href = '/';
    }

    this.vm.entryNumberOfResults = this.appConfig.maxRecords;

    this.subscriptionGroup.push(this.tableDataService.getApiListData('account', '?state=active&pageNumber=0&pageSize=999999').subscribe((account: any[]) => {
      // this.localStorage.StorageSet('account', JSON.stringify(account));
    }));
    this.search();
    this.vm.listData = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
  }

  ngOnDestroy(): void {
    this.subscriptionGroup.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([4, 'desc']);
  }

  async getPagerStatus() {
    this.pagerStatus.getPagerState(this.router.url).then(res => {
      if (res) {
        const pagerStuff = res;
        if (pagerStuff.totalRecords !== 0) {
          this.page = pagerStuff.lastPage;
          this.pageSize = pagerStuff.lastPageSize;
          this.startIndex = pagerStuff.startIndex;
          this.endIndex = pagerStuff.endIndex;
        }
      }
    }).catch(err => {
      console.log('pager error: ', err);
    }).then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {

    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.listData.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = ((this.page - 1) * this.pageSize) + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number((this.page) * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize){
      this.endIndex = this.totalRecords;
    }

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  clearServerSearch(value) {
    this.vm.entrySearch = value.searchTerm;
    this.vm.entryFromDate = value.fromDate;
    this.vm.entryToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  getPagedApiData() {
    this.isLoaded = false;
    this.vm.searching = true;
    this.vm.batchId = undefined;
    this.vm.tempDescription = '';

    let paramString = '';
    paramString += 'search=' + ((this.vm.entrySearch) ? this.vm.entrySearch : '');
    paramString += '&batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
    paramString += '&fromDate=' + ((this.vm.entryFromDate) ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
    paramString += '&toDate=' + ((this.vm.entryToDate) ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
    paramString += '&numberOfResults=' + ((this.vm.entryNumberOfResults) ? this.vm.entryNumberOfResults : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;
    paramString += '&recurring=0';

    this.subscriptionGroup.push(this.tableDataService.getApiListData('EntryList', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.listData = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;

      this.convertToNumbers();
      this.vm.searching = false;
      this.vm.listInitiated = true;
      this.isLoaded = true;
    }));

    paramString = '';
    paramString += 'batchType=1';
    paramString += '&search=' + ((this.vm.entrySearch) ? this.vm.entrySearch : '');
    paramString += '&fromDate=' + ((this.vm.entryFromDate) ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
    paramString += '&toDate=' + ((this.vm.entryToDate) ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
    paramString += '&lockedBatches=' + ((this.vm.lockedBatches) ? this.vm.lockedBatches : '');
    paramString += '&numberOfResults=' + ((this.vm.entryNumberOfResults) ? this.vm.entryNumberOfResults : '');
    paramString += '&includePosted=' + ((this.vm.includePosted) ? this.vm.includePosted : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.subscriptionGroup.push(this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
    }));

  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.entrySearch = value.searchTerm;
    this.vm.entryFromDate = value.fromDate;
    this.vm.entryToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  scrollTo(anchor: string): void {
    if (!this.vm.batchesHidden) {
      this.vps.scrollToAnchor(anchor);
    }
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  // selectItem() {
  //   this.vm.owingSelected = this.vm.totalSelected = 0;
  //   let firstEntity = '';
  //   document.getElementById('pay-btn').classList.remove('d-none');
  //   document.getElementById('owingSelected').classList.remove('d-none');
  //   this.vm.itemsSelected = false;
  //   for (const list of this.vm.listData) {
  //     if ((document.getElementById(list.id) as HTMLInputElement).checked) {
  //       if (firstEntity == '') {
  //         this.vm.itemsSelected = true;
  //         firstEntity = list.entity_name;
  //       } else if (firstEntity != list.entity_name) {
  //         document.getElementById('pay-btn').classList.add('d-none');
  //         document.getElementById('owingSelected').classList.add('d-none');
  //       }
  //       this.vm.totalSelected += Number(list.header_total);
  //       this.vm.owingSelected += Number(list.header_owing);
  //       document.getElementById(list.id + '-tr').classList.add('success');
  //     } else {
  //       document.getElementById(list.id + '-tr').classList.remove('success');
  //     }
  //   }
  // }

  selectRow(rec: any, checked: boolean) {
    // console.log('rec val:' + rec + ' - ' + checked);
    if (this.checkAll && rec) {
      this.checkAll = false;
    }
    if (!checked) {
      this.vm.listData.forEach((item) => {
        if (item.id === rec) {
          item.selected = false;
        }
      });
    } else if (checked) {
      this.vm.listData.forEach((item) => {
        if (item.id === rec && !item.locked) {
          item.selected = true;
        }
      });
    }
    this.isAllChecked();

    // console.log('single select records' + JSON.stringify(this.vm.list));
  }

  selectAllRows(event: any) {
    if (!event) {
      this.vm.listData.forEach((item) => (item.selected = false));
    } else if (event) {
      // this.vm.list.forEach((item) => (item.selected = true));
      this.vm.listData.forEach((item) => {
        if (!item.locked) {
          item.selected = true;
        }
      });
    }
    // console.log('all select records: ' + JSON.stringify(this.vm.list));
  }

  isAllChecked(): boolean {
    const retVal = this.vm.listData.every((x) => x.selected === true);
    this.checkAll = retVal;
    return retVal;
  }

  confirmDeleteSelected() {
    if (this.vm.listData.filter(x => x.selected).length === 0) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }

    const messages: string[] = [
      $localize`:Are you sure you want to Delete? @@areyousureyouwanttodelete:Are you sure you want to Delete?`,
      'Selected Count: ' + this.vm.listData.filter(x => x.selected).length,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.deleteSelected();
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }


  deleteSelected() {
    // just need to send the header id to the api
    const arrayOfItemsToDelete = [];
    this.vm.listData.forEach((x) => {
      if (x.selected) {
        arrayOfItemsToDelete.push({header_id: x.id});
      }
    });

    if (!arrayOfItemsToDelete.length) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }
    // console.log('selected recs: ' + JSON.stringify(arrayOfItemsToDelete));

    // makes action button spinner turn while deleting
    this.isLoaded = false;
    // pass array as body in a delete to api entryController
    this.subscriptionGroup.push(this.tableDataService.deleteTableDataByArray('entry', arrayOfItemsToDelete).subscribe((item: any) => {
      if (item) {
        //  console.log(item);
        if (item.Message === 'Success') {
          this.isLoaded = true;
          this.getPagedApiData();
        }
      }
    }));
  }


  clearSearch() {
    this.datatableClearFilter();
    this.vm.entrySearch = this.vm.entryFromDate = this.vm.entryToDate = this.vm.entrySearchLocked = '';
    this.vm.entryNumberOfResults = '';
    this.vm.listData = [];
    this.vm.searching = false;
    this.page = 1;
    this.search();
  }

  search() {
    this.isLoaded = false;
    this.vm.searching = true;
    this.vm.batchId = undefined;
    this.vm.tempDescription = '';

    let paramString = '';
    paramString += 'search=' + ((this.vm.entrySearch) ? this.vm.entrySearch : '');
    paramString += '&fromDate=' + ((this.vm.entryFromDate) ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
    paramString += '&toDate=' + ((this.vm.entryToDate) ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
    paramString += '&numberOfResults=' + ((this.vm.entryNumberOfResults) ? this.vm.entryNumberOfResults : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;
    paramString += '&recurring=0';

    this.subscriptionGroup.push(this.tableDataService.getApiListData('EntryList', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.listData = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dtTrigger.next('');
      this.dataTableReady = true;

      this.convertToNumbers();
      this.vm.searching = false;
      this.vm.listInitiated = true;
      this.isLoaded = true;
    this.EntryCount = this.vm.listData.length;
    }));

    paramString = '';
    paramString += 'batchType=1';
    paramString += '&search=' + ((this.vm.entrySearch) ? this.vm.entrySearch : '');
    paramString += '&fromDate=' + ((this.vm.entryFromDate) ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
    paramString += '&toDate=' + ((this.vm.entryToDate) ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
    paramString += '&lockedBatches=' + ((this.vm.lockedBatches) ? this.vm.lockedBatches : '');
    paramString += '&numberOfResults=' + ((this.vm.entryNumberOfResults) ? this.vm.entryNumberOfResults : '');
    paramString += '&includePosted=' + ((this.vm.includePosted) ? this.vm.includePosted : '');

    this.subscriptionGroup.push(this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
    }));

  }

  filter(filter) {
    this.vm.invoiceFilter = Object.assign(this.vm.invoiceFilter, filter);
    // this.localStorage.StorageSet('invoiceFilter', this.vm.invoiceFilter);
  }

  convertToNumbers() {
    for (const list of this.vm.listData) {
      list.header_amount = Number(list.header_amount);
      list.audit_sequence = Number(list.audit_sequence);
    }
  }

  updateBatchesSelectedInModal(batchId: string) {
    this.getBatchItems(batchId);
    // this.paginate(false);
  }

  getBatchItems(batchId) {
    this.vm.tempDescription = '';
    this.vm.batchId = batchId;
    this.vm.searching = true;
    this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
    this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
    this.subscriptionGroup.push(this.tableDataService.getTableData('EntryList/batch', '?batch_id=' + this.vm.batchId).subscribe((result: any[]) => {
      this.convertToNumbers();
      this.vm.searching = false;
      this.vm.listData = result;
      this.vm.listInitiated = true;
    }));
  }

  getBatches() {
    this.vm.searching = true;
    let paramString = '';
    paramString += 'batchType=1';
    paramString += '&search=' + ((this.vm.invoiceSearch) ? this.vm.invoiceSearch : '');
    paramString += '&fromDate=' + ((this.vm.invoiceFromDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
    paramString += '&toDate=' + ((this.vm.invoiceToDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
    paramString += '&lockedBatches=' + ((this.vm.lockedBatches) ? this.vm.lockedBatches : '');
    paramString += '&entryNumberOfResults=' + ((this.vm.entryNumberOfResults) ? this.vm.entryNumberOfResults : '');

    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      if (this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true).length == 1) {
        this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
        this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
      }
      this.vm.searching = false;
    });
  }

  newBatch() {
    const newBatch = { type_id: 1 };
    this.subscriptionGroup.push(this.tableDataService.post('t_batch', newBatch).subscribe((item: any) => {
      this.getBatches();
      this.vm.batchId = item.id;
      this.vm.tempDescription = 'Batch';;
    }, (error) => {
      this.vm.buttonsDisabled = false;
      console.log(error);
    }));
  }

  updateBatch() {
    this.vm.buttonsDisabled = true;
    const changes = { description: this.vm.tempDescription };
    this.tableDataService.patch('t_batch', this.vm.batchId, changes).then((item: any) => {
      // this.notification.success(this.vm.tempDescription);
      this.getBatches();
    }, (error: any) => {
      this.vm.buttonsDisabled = false;
      console.log(error.description);
    });
  }

  postBatch(batchId) {
    this.vm.buttonsDisabled = true;
    const changes = { locked: 'true' };
    this.tableDataService.patch('t_batch', batchId, changes).then((item: any) => {
      // this.notification.success('success');
      this.getBatches();
      this.vm.buttonsDisabled = false;
    }, (error: any) => {
      this.vm.buttonsDisabled = false;
      console.log(error.description);
    });
  }

  hideBatches() {
    // this.vm.buttonsDisabled = true;
    this.vm.batchesHidden = !this.vm.batchesHidden;
    this.vm.Preferences.ShowBatches = !this.vm.batchesHidden;
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);

  }

  onNavigate(aID: string, print = false) {
    if (this.vm.batchId == undefined || this.vm.batchId == null) {
      this.vm.batchId = '';
    }
    this.router.navigateByUrl(this.vm.formRouter + '?entryId=' + aID + '&thisBatchId=' + this.vm.batchId + '&recurring=false').then();
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

}
