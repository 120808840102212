import {NgModule} from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './Services/auth-guard.service';
import {HomeComponent} from './Components/home/home.component';
import {LoginComponent} from './Components/login/Login.component';
import {ForgotPasswordComponent} from './Components/forgot-password/forgot-password.component';
import {EntityListComponent} from './Components/Entity/entity-list/entity-list.component';
import {EntityFormComponent} from './Components/Entity/entity-form/entity-form.component';
import {AppLayoutComponent} from './Layouts/app-layout.component';
import {LoginLayoutComponent} from './Layouts/login-layout.component';
import {TransactionLayoutComponent} from './Layouts/transaction-layout.component';
import {BankListComponent} from './Components/admin/banks/bank-list/bank-list.component';
import {ItemListComponent} from './Components/admin/item/item-list/item-list.component';
import {ItemFormComponent} from './Components/admin/item/item-form/item-form.component';
import {BankFormComponent} from './Components/admin/banks/bank-form/bank-form.component';
import {LocationListComponent} from './Components/admin/location/location-list/location-list.component';
import {CompanyListComponent} from './Components/admin/company/company-list/company-list.component';
import {CurrencyListComponent} from './Components/admin/currency/currency-list/currency-list.component';
import {AccountListComponent} from './Components/admin/account/account-list/account-list.component';
import {EntityTypeListComponent} from './Components/admin/entity-type/entity-type-list/entity-type-list.component';
import {AccountFormComponent} from './Components/admin/account/account-form/account-form.component';
import {AccountSubtotalListComponent} from './Components/admin/account-subtotal/account-subtotal-list/account-subtotal-list.component';
import {CheckStockListComponent} from './Components/admin/check-stock/check-stock-list/check-stock-list.component';
import {CheckStockFormComponent} from './Components/admin/check-stock/check-stock-form/check-stock-form.component';
import {CurrencyRateListComponent} from './Components/admin/currency-rate/currency-rate-list/currency-rate-list.component';
import {CurrencyRateFormComponent} from './Components/admin/currency-rate/currency-rate-form/currency-rate-form.component';
import {EntityAccessListComponent} from './Components/admin/entity-access/entity-access-list/entity-access-list.component';
import {EntityAccessFormComponent} from './Components/admin/entity-access/entity-access-form/entity-access-form.component';
import {LocationGroupListComponent} from './Components/admin/location-group/location-group-list/location-group-list.component';
import {LocationGroupFormComponent} from './Components/admin/location-group/location-group-form/location-group-form.component';
import {PaymentTypeListComponent} from './Components/admin/payment-type/payment-type-list/payment-type-list.component';
import {PaymentTypeFormComponent} from './Components/admin/payment-type/payment-type-form/payment-type-form.component';
import {TaxListComponent} from './Components/admin/tax/tax-list/tax-list.component';
import {TaxFormComponent} from './Components/admin/tax/tax-form/tax-form.component';
import {TaxRateListComponent} from './Components/admin/tax-rate/tax-rate-list/tax-rate-list.component';
import {TaxRateFormComponent} from './Components/admin/tax-rate/tax-rate-form/tax-rate-form.component';
import {TermListComponent} from './Components/admin/term/term-list/term-list.component';
import {TermFormComponent} from './Components/admin/term/term-form/term-form.component';
import {LocationAccessListComponent} from './Components/admin/location-access/location-access-list/location-access-list.component';
import {LocationAccessFormComponent} from './Components/admin/location-access/location-access-form/location-access-form.component';
import {AccountMarginFormComponent} from './Components/admin/account-margin/account-margin-form/account-margin-form.component';
import {AccountMarginListComponent} from './Components/admin/account-margin/account-margin-list/account-margin-list.component';
import {AccountSubtotalFormComponent} from './Components/admin/account-subtotal/account-subtotal-form/account-subtotal-form.component';
import { BomListComponent } from './Components/inventory/bill-of-materials/bom-list/bom-list.component';
import { CountListComponent } from './Components/inventory/count/count-list/count-list.component';
import { TransferListComponent } from './Components/inventory/transfer/transfer-list/transfer-list.component';
import { AssemblyFormComponent } from './Components/inventory/assembly/assembly-form/assembly-form.component';
import { AssemblyListComponent } from './Components/inventory/assembly/assembly-list/assembly-list.component';
import { BomFormComponent } from './Components/inventory/bill-of-materials/bom-form/bom-form.component';
import { CountFormComponent } from './Components/inventory/count/count-form/count-form.component';
import { TransferFormComponent } from './Components/inventory/transfer/transfer-form/transfer-form.component';

import {CompanyAccessListComponent} from './Components/admin/company-access/company-access-list/company-access-list.component';
import {CompanyAccessFormComponent} from './Components/admin/company-access/company-access-form/company-access-form.component';
import {CurrencyFormComponent} from './Components/admin/currency/currency-form/currency-form.component';
import {UomListComponent} from './Components/admin/uom/uom-list/uom-list.component';
import {UomFormComponent} from './Components/admin/uom/uom-form/uom-form.component';
import {LocationFormComponent} from './Components/admin/location/location-form/location-form.component';

// fs report
import {BalanceSheetComponent} from './Components/Reports/financial/balance-sheet/balance-sheet.component';
import {ConsolidatedIncomeStatementComponent} from './Components/Reports/financial/consolidated-income-statement/consolidated-income-statement.component';
import {CompanyFormComponent} from './Components/admin/company/company-form/company-form.component';
import {MarginReportComponent} from './Components/Reports/financial/margin-report/margin-report.component';
import {TrialBalanceComponent} from './Components/Reports/financial/trial-balance/trial-balance.component';

// misc transaction-reports
import {TransactionDetailComponent} from './Components/Reports/transaction-reports/transaction-detail/transaction-detail.component';
import {AuditTrailViewerFormComponent} from './Components/admin/audit-trail-viewer/audit-trail-viewer-form/audit-trail-viewer-form.component';
import {AgingComponent} from './Components/Reports/transaction-reports/aging/aging.component';
import {InvoicesComponent} from './Components/Reports/transaction-reports/invoices/invoices.component';
import {PaymentsComponent} from './Components/Reports/transaction-reports/payments/payments.component';
import {StatementsComponent} from './Components/Reports/transaction-reports/statements/statements.component';
import {TaxComponent} from './Components/Reports/transaction-reports/tax/tax.component';
import {EntityReconciliationComponent} from './Components/Reports/entity/entity-reconciliation/entity-reconciliation.component';

// inventory transaction-reports
import {AssemblyReportComponent} from './Components/Reports/inventory/reports/assembly-report/assembly-report.component';
import {BalanceReportComponent} from './Components/Reports/inventory/reports/balance-report/balance-report.component';
import {CountReportComponent} from './Components/Reports/inventory/reports/count-report/count-report.component';
import {TransferReportComponent} from './Components/Reports/inventory/reports/transfer-report/transfer-report.component';

// payroll transaction-reports
import {T4ReportComponent} from './Components/Reports/payroll/reports/t4-report/t4-report.component';
import {PayableReportComponent} from './Components/Reports/payroll/reports/payable-report/payable-report.component';
import {EmployeeReportComponent} from './Components/Reports/payroll/reports/employee-report/employee-report.component';
import {EmployeeDetailReportComponent} from './Components/Reports/payroll/reports/employee-detail-report/employee-detail-report.component';

import {CashFlowComponent} from './Components/Reports/financial/cash-flow/cash-flow.component';
import {IncomeStatementComponent} from './Components/Reports/financial/income-statement/income-statement.component';
import {InvoiceRecurringListComponent} from './Components/transactions/invoice-recurring-list/invoice-recurring-list.component';
import {InvoiceFormComponent} from './Components/transactions/invoice-form/invoice-form.component';
import {InvoiceListComponent} from './Components/transactions/invoice-list/invoice-list.component';
import {PaymentListComponent} from './Components/transactions/payment-list/payment-list.component';
import {PaymentFormComponent} from './Components/transactions/payment-form/payment-form.component';
import {ReportingFieldListComponent} from './Components/admin/reporting-field/reporting-field-list/reporting-field-list.component';
import {ReportingFieldFormComponent} from './Components/admin/reporting-field/reporting-field-form/reporting-field-form.component';
import {ReportingFieldTypeListComponent} from './Components/admin/reporting-field-type/reporting-field-type-list/reporting-field-type-list.component';
import {ReportingFieldTypeFormComponent} from './Components/admin/reporting-field-type/reporting-field-type-form/reporting-field-type-form.component';
import {BankReconciliationListComponent} from './Components/transactions/bank-reconciliation/bank-reconciliation-list/bank-reconciliation-list.component';
import {BankReconciliationFormComponent} from './Components/transactions/bank-reconciliation/bank-reconciliation-form/bank-reconciliation-form.component';
import {WorkOrdersFormComponent} from './Components/work-orders/work-orders-form/work-orders-form.component';
import {WorkOrdersListComponent} from './Components/work-orders/work-orders-list/work-orders-list.component';
import {TimesheetListComponent} from './Components/timesheet/timesheet-list/timesheet-list.component';
import {TimesheetFormComponent} from './Components/timesheet/timesheet-form/timesheet-form.component';

import {SysUserListComponent} from './Components/admin/sys-user/sys-user-list/sys-user-list.component';
import {SysUserFormComponent} from './Components/admin/sys-user/sys-user-form/sys-user-form.component';
import {StatisticalRecurringListComponent} from './Components/transactions/statistical-recurring-list/statistical-recurring-list.component';

import {EntryFormComponent} from './Components/transactions/entry-form/entry-form.component';
import {EntryListComponent} from './Components/transactions/entry-list/entry-list.component';
import {EntryRecurringComponent} from './Components/transactions/entry-recurring/entry-recurring.component';
import {StatisticalListComponent} from './Components/transactions/statistical-list/statistical-list.component';
import {StatisticalFormComponent} from './Components/transactions/statistical-form/statistical-form.component';
import {ItemCategoryListComponent} from './Components/admin/item-category/item-category-list/item-category-list.component';
import {ItemPricingListComponent} from './Components/admin/item-pricing/item-pricing-list/item-pricing-list.component';
import {ItemPricingFormComponent} from './Components/admin/item-pricing/item-pricing-form/item-pricing-form.component';
import {ItemCategoryFormComponent} from './Components/admin/item-category/item-category-form/item-category-form.component';
import {EntityTypeFormComponent} from './Components/admin/entity-type/entity-type-form/entity-type-form.component';
import {ItemTypeFormComponent} from './Components/admin/item-type/item-type-form/item-type-form.component';
import {ItemTypeListComponent} from './Components/admin/item-type/item-type-list/item-type-list.component';
import {ItemGroupFormComponent} from './Components/admin/item-group/item-group-form/item-group-form.component';
import {ItemGroupListComponent} from './Components/admin/item-group/item-group-list/item-group-list.component';

// entity report
import {EntityHistoryReportComponent} from './Components/Reports/entity/entity-history-report/entity-history-report.component';
import {EmployeeFormComponent} from './Components/payroll/employee-form/employee-form.component';
import {EmployeeListComponent} from './Components/payroll/employee-list/employee-list.component';
import {EarningDeductionListComponent} from './Components/payroll/earning-deduction-list/earning-deduction-list.component';
import {EarningDeductionFormComponent} from './Components/payroll/earning-deduction-form/earning-deduction-form.component';
import {PayrollPaymentFormComponent} from './Components/payroll/payroll-payment-form/payroll-payment-form.component';
import {PayrollPaymentListComponent} from './Components/payroll/payroll-payment-list/payroll-payment-list.component';

// pos
import {PosDataBrowserListComponent} from './Components/pos/pos-data-browser-list/pos-data-browser-list.component';

// shipping
import {ShipmentListComponent} from './Components/transactions/shipment-list/shipment-list.component';
import {ShipmentFormComponent} from './Components/transactions/shipment-form/shipment-form.component';

// 404
import {Error400PageComponent} from './Components/common/error400-page/error400-page.component';

// timesheet report
import {TimesheetReportComponent} from './Components/Reports/activity/reports/timesheet-report/timesheet-report.component';

// workorder report
import {WorkorderReportComponent} from './Components/Reports/activity/reports/workorder-report/workorder-report.component';
import {Error500PageComponent} from './Components/common/error500-page/error500-page.component';

// reset pw
// OLD import {ResetPasswordComponent} from './Components/reset-password/reset-password.component';
import {PasswordResetComponent} from './Components/password-reset/password-reset.component';

import {VerifyEmailComponent} from './Components/verify-email/verify-email.component';

// printing docs
import { PrintLayoutComponent} from './Layouts/print-layout.component';
import {PrintTransactionDocument} from './Components/transactions/print-transaction-document/print-transaction-document';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      // config - prefs stuff
      {path: 'home', component: HomeComponent},
      {path: 'entity-list', component: EntityListComponent},
      // invoice variants
      {path: 'transactions/invoice/list/ar', component: InvoiceListComponent},
      {path: 'transactions/invoice/list/ap', component: InvoiceListComponent},
      {path: 'transactions/invoice/list/purchaseorders', component: InvoiceListComponent},
      {path: 'transactions/invoice/list/salesorders', component: InvoiceListComponent},
      {path: 'transactions/invoice/list/quotes', component: InvoiceListComponent},
      {path: 'admin/bank-list', component: BankListComponent},
      {path: 'admin/check-stock-list', component: CheckStockListComponent},
      {path: 'admin/currency-rate-list', component: CurrencyRateListComponent},
      {path: 'entity-access-list', component: EntityAccessListComponent},
      {path: 'admin/location-group-list', component: LocationGroupListComponent},
      {path: 'admin/payment-type-list', component: PaymentTypeListComponent},
      {path: 'admin/tax-list', component: TaxListComponent},
      {path: 'admin/tax-rate-list', component: TaxRateListComponent},
      {path: 'admin/term-list', component: TermListComponent},
      {path: 'assembly-list', component: AssemblyListComponent },
      {path: 'admin/bom-list', component: BomListComponent },
      {path: 'transfer-list', component: TransferListComponent },
      {path: 'admin/item-list', component: ItemListComponent},
      {path: 'admin/item-category-list', component: ItemCategoryListComponent},
      {path: 'admin/item-type-list', component: ItemTypeListComponent},
      {path: 'admin/item-type-form', component: ItemTypeFormComponent},
      {path: 'admin/item-group-list', component: ItemGroupListComponent},
      {path: 'admin/item-group-form', component: ItemGroupFormComponent},
      {path: 'password-reset', component: PasswordResetComponent},
      {path: 'admin/location-list', component: LocationListComponent},
      {path: 'admin/company-list', component: CompanyListComponent},
      {path: 'admin/company-access-list', component: CompanyAccessListComponent},
      {path: 'admin/currency-list', component: CurrencyListComponent},
      {path: 'admin/account-list', component: AccountListComponent},
      {path: 'admin/account-margin-list', component: AccountMarginListComponent},
      {path: 'admin/account-subtotal-list', component: AccountSubtotalListComponent},
      {path: 'admin/entity-type-list', component: EntityTypeListComponent},
      {path: 'admin/check-stock-list', component: CheckStockListComponent},
      {path: 'admin/currency-rate-list', component: CurrencyRateListComponent},
      {path: 'admin/entity-access-list', component: EntityAccessListComponent},
      {path: 'admin/location-group-list', component: LocationGroupListComponent},
      {path: 'admin/location-access-list', component: LocationAccessListComponent},
      {path: 'admin/payment-type-list', component: PaymentTypeListComponent},
      {path: 'admin/tax-list', component: TaxListComponent},
      {path: 'admin/tax-rate-list', component: TaxRateListComponent},
      {path: 'admin/term-list', component: TermListComponent},
      {path: 'admin/uom-list', component: UomListComponent},
      {path: 'admin/item-pricing-list', component: ItemPricingListComponent},
      {path: 'admin/reporting-field-type-list', component: ReportingFieldTypeListComponent},
      // transaction-reports
      {path: 'transaction-reports/balance-sheet', component: BalanceSheetComponent},
      {path: 'transaction-reports/consolidated-income-statement', component: ConsolidatedIncomeStatementComponent},
      {path: 'transaction-reports/margin-report', component: MarginReportComponent},
      {path: 'transaction-reports/trial-balance', component: TrialBalanceComponent},
      {path: 'transaction-reports/transaction-detail', component: TransactionDetailComponent},
      {path: 'transaction-reports/aging/ap', component: AgingComponent},
      {path: 'transaction-reports/aging/ar', component: AgingComponent},
      {path: 'transaction-reports/aging/:subLedger', component: AgingComponent},
      {path: 'transaction-reports/invoices/ap', component: InvoicesComponent},
      {path: 'transaction-reports/invoices/ar', component: InvoicesComponent},
      {path: 'transaction-reports/invoices/ss', component: InvoicesComponent},
      {path: 'transaction-reports/invoices/sq', component: InvoicesComponent},
      {path: 'transaction-reports/invoices/ps', component: InvoicesComponent},
      {path: 'transaction-reports/invoices/so', component: InvoicesComponent},
      {path: 'transaction-reports/invoices/po', component: InvoicesComponent},
      {path: 'transaction-reports/payments/ap', component: PaymentsComponent},
      {path: 'transaction-reports/payments/ar', component: PaymentsComponent},
      {path: 'transaction-reports/statements', component: StatementsComponent},
      {path: 'transaction-reports/tax', component: TaxComponent},
      {path: 'transaction-reports/entity-reconciliation/:subLedger', component: EntityReconciliationComponent},
      {path: 'transaction-reports/cash-flow', component: CashFlowComponent},
      {path: 'activity/transaction-reports/timesheet-report', component: TimesheetReportComponent},
      {path: 'activity/transaction-reports/workorder-report', component: WorkorderReportComponent},
      {path: 'transaction-reports/income-statement', component: IncomeStatementComponent},
      // inventory transaction-reports
      {path: 'inventory/transaction-reports/assembly', component: AssemblyReportComponent},
      {path: 'inventory/transaction-reports/balance', component: BalanceReportComponent},
      {path: 'inventory/transaction-reports/count', component: CountReportComponent},
      {path: 'inventory/transaction-reports/transfer', component: TransferReportComponent},
      // payroll transaction-reports
      {path: 'payroll/transaction-reports/employee', component: EmployeeReportComponent},
      {path: 'payroll/transaction-reports/employee-detail', component: EmployeeDetailReportComponent},
      {path: 'payroll/transaction-reports/payable', component: PayableReportComponent},
      {path: 'payroll/transaction-reports/t4', component: T4ReportComponent},
      // entity report
      {path: 'transaction-reports/entity-history-report', component: EntityHistoryReportComponent},
      // Transactions
      {path: 'transactions/payment/list/:type', component: PaymentListComponent},
      {path: 'transactions/invoice/recurring/:type', component: InvoiceRecurringListComponent},
      {path: 'transactions/bank-reconciliation/bank-reconciliation-list', component: BankReconciliationListComponent},
      {path: 'transactions/shipment/list/ar', component: ShipmentListComponent},
      {path: 'transactions/shipment/list/ap', component: ShipmentListComponent},
      // Reporting Field
      {path: 'admin/reporting-field-list', component: ReportingFieldListComponent},
      // Work Orders
      {path: 'work-orders/work-orders-list', component: WorkOrdersListComponent},
      // Time sheet
      {path: 'timesheet/timesheet-list', component: TimesheetListComponent},
      // Sys-User
      {path: 'admin/sys-user/list', component: SysUserListComponent},
      // Ledger
      {path: 'transactions/entry/list', component: EntryListComponent},
      {path: 'transactions/entry/recurring', component: EntryRecurringComponent},
      {path: 'transactions/statistical/list', component: StatisticalListComponent},
      {path: 'transactions/statistical/form', component: StatisticalFormComponent},
      {path: 'transactions/statistical/recurring-list', component: StatisticalRecurringListComponent},
      // Inventory
      {path: 'inventory/assemble/list', component: AssemblyListComponent },
      {path: 'inventory/transfer/list', component: TransferListComponent},
      {path: 'inventory/count/list', component: CountListComponent},
      {path: 'inventory/bill-of-materials/bom-list', component: BomListComponent},
      // Payroll
      {path: 'payroll/employee-list', component: EmployeeListComponent},
      {path: 'payroll/earnings-list', component: EarningDeductionListComponent},
      {path: 'payroll/payment-list', component: PayrollPaymentListComponent},
      // pos
      {path: 'pos/pos-data-browser-list/pos-data-browser-list', component: PosDataBrowserListComponent},
      // audit trail
      {path: 'admin/audit-trail-viewer-form', component: AuditTrailViewerFormComponent},
    ]
  },
  {
    path: '',
    component: TransactionLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      // admin forms
      {path: 'entity-form', component: EntityFormComponent},
      // {path: 'invoice-form', component: InvoiceFormComponent},
      {path: 'admin/bank-form', component: BankFormComponent},
      {path: 'admin/check-stock-form', component: CheckStockFormComponent},
      {path: 'admin/currency-rate-form', component: CurrencyRateFormComponent},
      {path: 'entity-access-form', component: EntityAccessFormComponent},
      {path: 'admin/location-group-form', component: LocationGroupFormComponent},
      {path: 'admin/payment-type-form', component: PaymentTypeFormComponent},
      {path: 'admin/tax-form', component: TaxFormComponent},
      {path: 'admin/tax-rate-form', component: TaxRateFormComponent},
      {path: 'admin/term-form', component: TermFormComponent},
      {path: 'assembly-form', component: AssemblyFormComponent },
      {path: 'admin/bom-form', component: BomFormComponent },
      {path: 'transfer-form', component: TransferFormComponent },
      {path: 'admin/item-form', component: ItemFormComponent},
      {path: 'admin/item-category-form', component: ItemCategoryFormComponent},
      {path: 'password-reset', component: PasswordResetComponent},
      {path: 'admin/location-form', component: LocationFormComponent},
      {path: 'admin/company-form', component: CompanyFormComponent},
      {path: 'admin/company-access-form', component: CompanyAccessFormComponent},
      {path: 'admin/currency-form', component: CurrencyFormComponent},
      {path: 'admin/account-form', component: AccountFormComponent},
      {path: 'admin/account-margin-form', component: AccountMarginFormComponent},
      {path: 'admin/account-subtotal-form', component: AccountSubtotalFormComponent},
      {path: 'admin/entity-type-form', component: EntityTypeFormComponent},
      {path: 'admin/check-stock-form', component: CheckStockFormComponent},
      {path: 'admin/currency-rate-form', component: CurrencyRateFormComponent},
      {path: 'admin/entity-access-form', component: EntityAccessFormComponent},
      {path: 'admin/location-group-form', component: LocationGroupFormComponent},
      {path: 'admin/location-access-form', component: LocationAccessFormComponent},
      {path: 'admin/payment-type-form', component: PaymentTypeFormComponent},
      {path: 'admin/tax-form', component: TaxFormComponent},
      {path: 'admin/tax-rate-form', component: TaxRateFormComponent},
      {path: 'admin/term-form', component: TermFormComponent},
      {path: 'admin/uom-form', component: UomFormComponent},
      {path: 'admin/item-pricing-form', component: ItemPricingFormComponent},
      {path: 'admin/reporting-field-type-form', component: ReportingFieldTypeFormComponent},
      // Transactions
      {path: 'transactions/payment/form', component: PaymentFormComponent},
      {path: 'transactions/invoice/form', component: InvoiceFormComponent},
      // {path: 'transactions/invoice/recurring/ar:type', component: InvoiceRecurringListComponent},
      // {path: 'transactions/invoice/recurring/ap:type', component: InvoiceRecurringListComponent},
      {path: 'transactions/shipment/form', component: ShipmentFormComponent},
      {path: 'transactions/statistical/form', component: StatisticalFormComponent},
      // Reporting Field
      {path: 'admin/reporting-field-form', component: ReportingFieldFormComponent},
      // Workorder
      {path: 'work-orders/work-orders-form', component: WorkOrdersFormComponent},
      // Timesheet
      {path: 'timesheet/timesheet-form', component: TimesheetFormComponent},
      // Sys-User
      {path: 'admin/sys-user-form', component: SysUserFormComponent},
      // Ledger
      {path: 'transactions/bank-reconciliation/bank-reconciliation-form', component: BankReconciliationFormComponent},
      {path: 'transactions/entry/form', component: EntryFormComponent},
      {path: 'transactions/entry/recurring', component: EntryRecurringComponent},
      {path: 'transactions/statistical/form', component: StatisticalFormComponent},
      // Inventory
      {path: 'inventory/assemble/form', component: AssemblyFormComponent},
      {path: 'inventory/transfer/form', component: TransferFormComponent },
      {path: 'inventory/count/form', component: CountFormComponent },
      {path: 'inventory/bill-of-materials/bom-form', component: BomFormComponent },
      // Payroll
      {path: 'payroll/employee-form', component: EmployeeFormComponent},
      {path: 'payroll/earnings-form', component: EarningDeductionFormComponent},
      {path: 'payroll/payment-form', component: PayrollPaymentFormComponent},
    ]
  },
  {
    path: '', component: PrintLayoutComponent, canActivate: [AuthGuard]
  },
  {
    // runs all doc prints - not transaction-reports - through this wrapper - add docs as children
    path: '',
    component: PrintLayoutComponent,
    canActivateChild: [AuthGuard],
    // outlet: 'print',
    children: [
      { path: 'transactions/invoice-print-ar', component: PrintTransactionDocument }
    ]
  },
  {
    path: '', component: LoginLayoutComponent, canActivate: [AuthGuard]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'verify-email', component: VerifyEmailComponent},
    ]
  },
 // 500
  { path: 'common/error500-page', component: Error500PageComponent },

  // 404
  { path: '**', component: Error400PageComponent },


];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    enableTracing: false,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
