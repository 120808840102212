import { Component, Input, OnInit } from '@angular/core';
import { TableDataService } from '../../../../../Services/tableData.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationModel } from '../../../../../DataModels/location-model';
import { NotificationService } from '../../../../../Services/notification.service';
import { Observable } from 'rxjs';
import { LocationGroupModel } from '../../../../../DataModels/location-group-model';
import { ConfirmDialogService } from '../../../../../Services/confirm-dialog.service';
import { CompanyModel } from '../../../../../DataModels/company-model';
import { EntityModel } from '../../../../../DataModels/entity-model';
import { ResponseModel } from '../../../../../DataModels/response-model';
import { BsToastService } from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.css'],
})
export class LocationDetailComponent implements OnInit {
  @Input() locationID: string;
  location: LocationModel;
  locationForm: UntypedFormGroup;
  locationCount = 0;

  locationGroup$: Observable<LocationGroupModel[]>;
  company$: Observable<CompanyModel[]>;
  entity$: Observable<EntityModel[]>;

  submitted = false;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.createForm();
    this.locationID = this.route.snapshot.params.id;
  }

  get f() {
    return this.locationForm.controls;
  }

  createForm() {
    this.locationForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      address_1: new UntypedFormControl(''),
      address_2: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      zip: new UntypedFormControl(''),
      group_id: new UntypedFormControl('', [Validators.required]),
      active: new UntypedFormControl('true'),
      company_id: new UntypedFormControl('', [Validators.required]),
      // entity_id: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.loadLookups();
    if (this.locationID) {
      this.LoadData();
    }
  }

  private LoadData(): void {
    this.table.getTableData('location', this.locationID).subscribe(
      (result) => {
        this.location = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Location @@errorloadingLocation:Error loading Location`);
        console.log(error + ' - ' + $localize`:Error loading Location @@errorloadingLocation:Error loading Location`);
      },
      () => {
        this.locationForm.patchValue(this.location);
      }
    );
  }

  private loadLookups(): void {
    this.table.getApiListData('location_group?pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.locationGroup$ = data;
    });

    this.table.getApiListData('company?pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.company$ = data;
    });

    this.table.getTableData('entity?pageNumber=0&pageSize=999999').subscribe((result: any) => {
      this.entity$ = result.Data;
    });
  }

  // saveClose(): void {
  //   if (this.onSave()) {
  //     this.onClose();
  //   }
  // }
  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew(): void {
    if (this.onSave(false)) {
      this.locationForm.reset();
    }
  }

  deleteItem(): void {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete location? @@areYouSureYouWantToDeleteLocation:Are you sure you want to delete location?`,
      this.location.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('location', this.locationID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
            this.onClose();
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  resetForm(): void {
    this.locationForm.reset();
    this.submitted = false;
  }

  onClose(): void {
    this.router.navigate(['admin/location-list']).then();
  }

  onSave(saveClose: boolean): boolean {
    this.submitted = true;
    if (this.locationForm.invalid) {
      this.notification.showWarningToast('Form Errors!');
      return false;
    }
    this.location = Object.assign({}, this.locationForm.value);
    if (this.locationID) {
      if (this.locationForm.invalid === false) {
        this.table.patch('location', this.locationID, this.location).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      console.log(JSON.stringify(this.location));
      this.table.postWithPromise('location', this.location).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.locationID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });
      // this.notification.success($localize`:Saved @@saved:Saved`);
      return true;
    }
    this.submitted = false;
    return true;
  }

  Reset(): void {
    //
  }
}
