import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log('searching: ' + JSON.stringify(value));
    if (!value) {
      return null;
    }

    if (!args) {
      return value;
    }

    args = args.toLowerCase();

    return value.filter(data => {
      return JSON.stringify(data).toLowerCase().includes(args);
      // return data.toLowerCase().includes(args);
    });
  }

}
