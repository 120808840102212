import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FilterPipe } from 'ngx-filter-pipe';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { Title } from '@angular/platform-browser';
import { DecimalNewPipe } from '../../../common/DecimalNewPipe';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService } from '../../../../Services/bs-toast-service';
import { take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-assembly-form',
  templateUrl: './assembly-form.component.html',
  styleUrls: ['./assembly-form.component.css'],
})
export class AssemblyFormComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private dateFilter: DateFilterService,
    private decimal: DecimalPipe,
    private titleService: Title,
    private decimalPipe: DecimalNewPipe,
    private confirmDialogService: ConfirmDialogService) {
    this.vm.inventoryItemFilter = { search_string: null };
    this.vm.accountFilter = { search_string: null };
    this.vm.bomFilter = { item_name: '' };
    this.vm.startInventoryItem = 0;
    this.vm.startAccount = 0;
    this.vm.tempAccountDetailId = null;
    this.vm.tempItemDetailId = null;
    this.vm.title = $localize`:Assembly @@assembly:Assembly`;
    this.vm.getSignature = false;
    this.vm.deleted = [];
    this.vm.newAssembly = false;
    this.vm.closeAssembly = false;
    this.vm.account = [];
    this.vm.accountSubtotal = [];
    this.vm.BillOfMaterials = [];
    this.vm.company = [];
    this.vm.currency = [];
    this.vm.location = [];
    this.vm.inventoryItem = [];
    this.vm.inventoryItemAll = [];
    this.vm.uom = [];
    this.vm.isLoaded = false;
    this.vm.getSignature = false;
    this.vm.selectedBOMProperties = [];
    this.vm.bomInputSelect = '';
    this.vm.item = {};
    this.vm.item.header = {};
    this.vm.item.detail = [];
    this.vm.item.batch = {};
    // this.vm.item = { header: {}, detail: [], batch: {} };

    this.vm.tempBOM = {};
  }

  @ViewChild('sPad', { static: true }) signaturePadElement: any;

  submitted = false;

  saveDisabled = false;
  deleteDisabled = false;
  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: true,
    saveClose: true,
  };
  vm: any = {};

  public urlParams: any = [];

  protected readonly window = window;

  ngOnInit() {
    this.urlParams = this.route.snapshot.queryParams;
    this.vm.assembleId = this.urlParams.assembleId;
    this.vm.batchId = this.urlParams.batchId;

    this.vm.auditTrail = true;

    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    if (!this.vm.permissions.EditAssembly && !this.vm.permissions.ViewAssembly && !this.vm.permissions.Admin) {
      this.notification.showWarningToast('You do not have permissions for this action');
      document.location.href = '/';
    }

    this.loadLookups();

    // Don't allow edits to a prev saved assembly because they currently update item counts on save
    // the patch won't effect because editable fields are disabled, but don't allow save buttons anayway
    if (this.vm.assembleId != 'new') {
      this.saveDisabled = true;
    }

    // if existing built get it
    if (this.vm.assembleId != 'new') {
      this.tableDataService
        .getTableData('entry', this.vm.assembleId)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.vm.item = result;
            // console.log('Initial get of prev built');
            // console.log(result);
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
          },
          complete: () => {
            this.init();
            // no delete or save for posted.
            if (this.vm.item.batch.locked) {
              this.saveDisabled = true;
              this.deleteDisabled = true;
            }
            this.vm.tempitems = this.vm.item;
          },
        });
    } else {
      this.init();
    }
  }

  loadLookups() {
    this.tableDataService
      .getApiListData('BillOfMaterials', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.BillOfMaterials = result.Data;
        // console.log('bom');
        // console.log(this.vm.BillOfMaterials);
      });

    this.tableDataService
      .getApiListData('account', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.account = result.Data;
      });

    this.tableDataService
      .getTableData('account_subtotal_no_parents', '?pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.accountSubtotal = result.Data;
      });

    this.tableDataService
      .getApiListData('company', '?pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });

    this.tableDataService
      .getApiListData('currency', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });

    this.tableDataService
      .getApiListData('location', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });

    this.tableDataService
      .getApiListData('item/GetBOMList', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.inventoryItem = result.Data;
      });

    this.tableDataService
      .getApiListData('item', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.inventoryItemAll = result.Data;
      });

    this.tableDataService
      .getTableData('uom', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.uom = result.Data;
      });
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    retVal = !value;
    return retVal;
  }

  init() {
    // console.log ('in init');
    if (this.vm.assembleId === 'new') {
      // console.log('in init new');
      if (this.vm.batchId != '') {
        this.vm.item = { batch: { type_id: 14, id: this.vm.batchId } };
      } else {
        this.vm.item = { batch: { type_id: 14 } };
      }
      this.vm.item.header = { header_date: new Date() };
      this.vm.item.detail = [];
      this.vm.item.detail.push({
        id: 1,
        sort_order: 1,
        quantity: 1,
        account_id: 'in',
        control_line: true,
        item_type_id: 'bom',
      });
      // this.vm.selectedBOMProperties.code = this.FilterObject(this.vm.item.detail, { item_type_id: 'bom' }, true)[0].item_name;
    } else {
      // console.log('hdr date before : ' + this.vm.item.header.header_date);
      let tempDate = this.vm.item.header.header_date;
      this.vm.item.header.header_date = new DatePipe('en-US').transform(tempDate, 'MM/dd/yyyy');
      //  tempDate.substring(5, 7) + '/' + tempDate.substring(8, 10) + '/' + tempDate.substring(0, 4);
      if (this.vm.item.header.due_date) {
        tempDate = this.vm.item.header.due_date;
        this.vm.item.header.due_date = new DatePipe('en-US').transform(tempDate, 'MM/dd/yyyy');
        // tempDate.substring(5, 7) + '/' + tempDate.substring(8, 10) + '/' + tempDate.substring(0, 4);
      }

      for (const [x, value] of Object.entries(this.vm.item.detail)) {
        this.vm.item.detail[x].quantity = Number(this.vm.item.detail[x].quantity);

        // flips sign on data from api since it is posted as negative to reduce the items being consumed by the bom
        if (this.vm.item.detail[x].quantity < 0 && this.vm.item.detail[x].account_id === 'in') {
          this.vm.item.detail[x].display_quantity = Number(this.vm.item.detail[x].quantity) * -1;
        } else {
          this.vm.item.detail[x].display_quantity = Number(this.vm.item.detail[x].quantity);
        }

        if (this.vm.item.detail[x].item_id) {
          if (this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.detail[x].item_id }, true).length > 0) {
            this.vm.item.detail[x].itemInput = this.FilterObject(
              this.vm.inventoryItem,
              { id: this.vm.item.detail[x].item_id },
              true
            )[0].code;
          }
          // console.log('item 0 of BOM Array: ' + this.vm.inventoryItem[0].code);
          // console.log(this.vm.inventoryItem);
        }
      }

      if (this.vm.account) {
        this.vm.item.detail.forEach((y: any) => {
          if (y.description == 'Assembly Cost') {
            this.vm.account.forEach((a: any) => {
              if (y.account_id == a.id) {
                y.accountInput = a.number + ' ' + a.name;
                // console.log('account: ' + this.vm.item.detail.accountInput);
              }
            });
            // console.log('detail ' + JSON.stringify(y));
          }
        });
      }

      this.vm.item.detail.forEach((y, index: number) => {
        if (y.item_type_id === 'bom') {
          // console.log(y);
          this.vm.bomInputSelect = y.itemInput;
          this.vm.bomInput = y.item_id;
          this.vm.selectedBOMProperties.code = y.item_name;
          // console.log(this.vm.bomInputSelect);
          // console.log(this.vm.bomInput);
          // console.log(this.vm.selectedBOMProperties.code);
        }
      });

      this.bomSelected(true);
      // console.log('in if 1: ' + this.vm.item.detail.itemInput);
    }

    this.vm.newItemId = '';

    if (this.vm.company.length === 1) {
      this.vm.item.header.company_id = this.vm.company[0].id;
      this.changeCompany();
    }

    if (this.vm.currency.length === 1) {
      this.vm.item.header.currency_id = this.vm.currency[0].id;
    }

    if (this.vm.location.length === 1) {
      this.vm.item.header.location_id = this.vm.location[0].id;
    }

    this.vm.accountName = [];
    for (const [y, val] of Object.entries(this.vm.account)) {
      const tempName = this.vm.account[y].number + ' ' + this.vm.account[y].name;
      this.vm.accountName.push({
        name: tempName,
      });
    }

    this.vm.bomName = [];
    // this.selectBOMModal(this.vm.bomInput);
    for (const [y, val] of Object.entries(this.vm.BillOfMaterials.filter((x) => x.item_name === this.vm.bomInput))) {
      const tempName = this.vm.BillOfMaterials[y].item_name;
      // console.log('tempname: ' + tempName);
      this.vm.bomName.push({
        name: tempName,
      });
    }

    this.buildInventoryItemName();
    this.vm.isLoaded = true;
  }

  changeCompany() {
    // TODO - Doesn't have function on ng-1
  }

  onSave() {
    if (!this.vm.item.attachments) {
      this.vm.item.attachments = [];
    }
    let error = 0;

    if (!this.vm.item.header.header_date || !this.vm.item.header.company_id) {
      if (!this.vm.item.header.header_date) {
        // this.notification.warning($localize`:Date required @@dateRequired:Date is required`);
        this.vm.dateMissing = true;
        error = 1;
      }
      if (!this.vm.item.header.company_id) {
        // this.notification.warning($localize`:Company is required. @@companyisrequired:Company is required.`);
        this.vm.companyError = true;
        error = 1;
      }
    } else {
      let yearEndDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .year_end_date;
      let cutOffDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0].cut_off_date;
      const tempYearEndDate = new Date(yearEndDate);
      const tempCutOffDate = new Date(cutOffDate);
      const headerDate = new Date(this.vm.item.header.header_date);
      // console.log('cut:' + tempCutOffDate);
      // console.log('YE:' + tempYearEndDate);
      // console.log('hdr:' + headerDate);
      // console.log('company id: ' + this.vm.item.header.company_id);
      const currency = this.vm.company.find((x) => x.id === this.vm.item.header.company_id);
      this.vm.item.header.currency_id = currency.currency_id;
      // console.log('currency: ' + this.vm.item.header.currency_id );

      if (headerDate < tempYearEndDate) {
        yearEndDate = this.dateFilter.GetDateFormat(yearEndDate);
        this.notification.showWarningToast(
          $localize`:Date must be greater than the year end  @@datemustbegreaterthantheyearend:Date must be greater than the year end ` +
            yearEndDate
        );
        error = 1;
      }
      if (headerDate < tempCutOffDate) {
        cutOffDate = this.dateFilter.GetDateFormat(cutOffDate);
        this.notification.showWarningToast(
          $localize`:Date must be greater than the cut off date @@datemustbegreaterthanthecutoffdate:Date must be greater than the cut off date ` +
            cutOffDate
        );
        error = 1;
      }
      this.vm.item.header.header_date = this.dateFilter.GetDateFormat(this.vm.item.header.header_date);
      // console.log('hdr date 354 : ' + this.vm.item.header.header_date);
    }

    if (!this.vm.item.header.location_id) {
      // this.notification.warning($localize`:Location is required. @@locationisrequired:Location is required.`);
      this.vm.locationError = true;
      error = 1;
    }
    if (!this.vm.item.header.currency_id) {
      // this.notification.warning($localize`:Currency is required. @@currencyisrequired:Currency is required. `);
      this.vm.currencyError = true;
      error = 1;
    }

    let sourceItemFound = false;
    let asssebledItemFound = false;
    let negativeFound = false;

    // ** NOTE before saving add the quantity to build to the first item in the detail array - it is the actual BOM item being created and
    // pushed to the top of the array in selectBOM() - basically its hard coded to be item 0 of the array at this time
    // way better ways to do this but fixing existing first to be sure BOM concept works
    // console.log('item detail at onsave: length of bom selected: ' + this.vm.selectedBOMProperties.length);
    // console.log(this.vm.item.detail);
    this.vm.item.detail[0].quantity = this.vm.selectedBOMProperties.quantity;

    // now we have to adjust all the qtys based on the number built
    // console.log('item update qty detail at onsave with:' + this.vm.selectedBOMProperties.quantity);
    // console.log(this.vm.item.detail);

    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].item_id) {
        if (isNaN(this.vm.item.detail[x].quantity)) {
          this.notification.showWarningToast(
            $localize`:All Quantities must be numbers.  @@allQuantitiesmustbenumbers:All Quantities must be numbers. `
          );
          error = 1;
        }
        if (this.vm.item.detail[x].control_line == true && this.vm.item.detail[x].account_id == 'in') {
          asssebledItemFound = true;
          if (this.vm.item.detail[x].quantity < 0) {
            negativeFound = true;
          }
        }
        if (this.vm.item.detail[x].control_line == false && this.vm.item.detail[x].account_id == 'in') {
          sourceItemFound = true;
          // if (this.vm.item.detail[x].display_quantity < 0) {
          //   negativeFound = true;
          // }
        }
      }
    }
    if (negativeFound) {
      this.notification.showWarningToast(
        $localize`:All Quantities must be greater than zero. @@allQuantitiesmustbegreaterthanzero:All Quantities must be greater than zero.`
      );
      error = 1;
    }
    if (!asssebledItemFound) {
      this.notification.showWarningToast(
        $localize`:Assembled Item is required.  @@assembledItemisrequired:Assembled Item is required. `
      );
      error = 1;
    }

    if (!sourceItemFound) {
      this.notification.showWarningToast(
        $localize`:At least one Source Item is required. @@atleastoneSourceItemisrequired:At least one Source Item is required.`
      );
      error = 1;
    }

    if (!this.vm.permissions.Admin && !this.vm.permissions.EditAssemblies) {
      this.notification.showWarningToast(
        $localize`:You do not have access to save. @@youdonothaveaccesstosave:You do not have access to save.`
      );
      error = 1;
    }

    if (error === 0) {
      this.vm.buttonsDisabled = true;
      if (this.vm.item.header.id === undefined) {
        // new
        this.tableDataService.post('entry', this.vm.item).subscribe(
          (item: any) => {
            this.vm.buttonsDisabled = false;
            if (this.vm.newAssembly) {
              this.vm.item = {};
              this.vm.newAssembly = false;
              this.init();
              this.vm.item.batchTypeId = 1;
              this.router.navigateByUrl('/inventory/assemble/form?assembleId=new').then();
            } else if (this.vm.closeAssembly) {
              this.router.navigateByUrl('/inventory/assemble/list').then();
              this.vm.closeAssembly = false;
            } else {
              // if (this.vm.action == 'print') {
              //   window.print();
              //   this.vm.action = '';
              // } else if (this.vm.action == 'email') {
              //   // this.sendEmail();
              //   this.vm.action = '';
              // }
              // this.notification.success(item.description);
              this.saveDisabled = true;
              this.router.navigateByUrl('/inventory/assemble/form?assembleId=' + item.id).then();
            }
          },
          (errors: any) => {
            this.vm.buttonsDisabled = false;
            // this.notification.showErrorToast($localize`:Error @@error:Error`);
            console.log(errors);
          }
        );
      } else {
        // edit
        // diff
        const changes: any = {};

        // set the qty to what is shown on screen - no idea why they did separate values
        // clean up empty recs first - no reason to send garbage for API to sort out
        // if it does n0t have a header_id it is not a rec
        this.vm.item.detail = this.vm.item.detail.filter((dtl) => dtl.header_id !== undefined);

        // then format quantity correctly based on the value you see on screen
        this.vm.item.detail.forEach((qty) =>
          qty.control_line ? (qty.quantity = qty.quantity) : (qty.quantity = parseFloat(qty.display_quantity) * -1)
        );

        changes.detail = this.vm.item.detail;
        // console.log('detail obj: ' + JSON.stringify(changes.detail));
        changes.salesperson = this.vm.item.salesperson;
        changes.attachments = this.vm.item.attachments;

        const headerChanges = {};
        for (const key in this.vm.item.header) {
          if (this.vm.item.header.hasOwnProperty(key)) {
            let value = this.vm.item.header[key];
            // console.log('key: ' + key + '  value: ' + value);
            // console.log('if value: ' + this.vm.tempitems.header[key]);
            // if (value !== this.vm.tempitems.header[key] && key !== 'entityInput')
            if (key === 'header_date') {
              const d = new Date(value).toISOString();
              value = d;
            }
            headerChanges[key] = value;
          }
          // }
        }
        if (headerChanges) {
          changes.header = headerChanges;
        }
        // console.log('changes obj: ' + JSON.stringify(changes));
        this.tableDataService.patch('entry', this.vm.item.header.id, changes).then(
          (item: any) => {
            this.vm.buttonsDisabled = false;
            if (item.description == 'Deleted') {
              this.router.navigateByUrl('/inventory/assemble/list').then();
              // this.notification.showSuccessToast($localize`:Delete Canceled@@delete:Delete`);
            } else if (this.vm.newAssembly) {
              // this.notification.success($localize`:Saved @@saved:Saved`);
              this.vm.newAssembly = false;
              this.vm.contact = '';
              this.vm.history = '';
              this.vm.billToAddress = '';
              this.vm.shipToAddress = '';
              this.router.navigateByUrl('/inventory/assemble/form?assembleId=new').then();
            } else if (this.vm.closeAssembly) {
              // this.notification.success($localize`:Saved @@saved:Saved`);
              this.vm.closeAssembly = false;
              this.router.navigateByUrl('/inventory/assemble/list').then();
            } else {
              if (this.vm.action === 'print') {
                window.print();
              } else if (this.vm.action == 'email') {
                // TODO this.sendEmail();
              }
              this.vm.action = '';
            }
            this.vm.buttonsDisabled = false;
          },
          (er: any) => {
            this.vm.buttonsDisabled = false;
            // this.notification.error($localize`:Error @@error:Error:` + er.message);
            console.log(er);
          }
        );
      }
    } else {
      // report error
      this.submitted = true;
      this.vm.buttonsDisabled = false;
      this.notification.showWarningToast($localize`: Form Errors! @@formerror:Form Error`);
    }
  }

  setQuantityConsumed() {
    // vm.selectedBOMProperties.quantity
    this.vm.item.detail.forEach((x) => {
      // bom item line is control line
      if (!x.control_line) {
        // x.display_quantity = this.vm.selectedBOMProperties.quantity;
        x.quantity = this.vm.selectedBOMProperties.quantity * -1;
        x.debit_credit = this.vm.selectedBOMProperties.quantity * -1;
      }
      if (!x.control_line && x.account_id != 'in') {
        // fetch the basis cost - not stored in detail
        const basisRec = this.vm.tempBOM.costs.filter((y) => y.account_id == x.account_id);
        // console.log('basrec: ' + JSON.stringify(basisRec));

        const baseAmount = basisRec[0].amount;
        // console.log('basrecAmt: ' + baseAmount);

        x.quantity = -1;
        x.debit_credit = baseAmount * -1 * this.vm.selectedBOMProperties.quantity;
        x.amount = baseAmount * this.vm.selectedBOMProperties.quantity;
      }
    });
    // console.log('item detail adjusted for amounts built');
    // console.log(this.vm.item);
  }

  deleteSignature() {
    this.vm.item.header.signature = '';
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.onSave();
  }

  signature(signature) {
    this.vm.item.header.signature = signature;
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.onSave();
  }

  syncItemAttachments(attachmentsArray: any) {
    // console.log('attachments array recvd in main');
    // console.log(attachmentsArray);

    // clear existing - will be put back because in array passed back in file upload array
    this.vm.item.attachments = [];

    // use the pieces needed for the api record
    attachmentsArray.forEach((x) => {
      this.vm.item.attachments.push({
        id: x.id,
        header_id: x.header_id,
        file_name: x.fileName,
        type: x.fileType,
        attachment: x.fileData,
        audit_sequence: 0,
      });
    });
    // console.log('main vm array after sync to file upload data');
    // console.log(this.vm);
  }

  gotAnUploadFile(file: any) {
    // console.log('in got a file: ' + file);
    // console.log('in got a file: ');
  }

  saveNew() {
    this.vm.newAssembly = true;
    this.onSave();
  }

  saveClose() {
    this.vm.closeAssembly = true;
    this.onSave();
  }

  // item functions
  buildInventoryItemName() {
    this.vm.inventoryItemName = [];
    for (const [y, value] of Object.entries(this.vm.inventoryItem)) {
      const tempName = this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name;
      this.vm.inventoryItemName.push({
        name: tempName,
      });
    }
  }

  // Bill of Materials
  bomSelected(isExisting: boolean) {
    // console.log('bom exiting flag: ' + isExisting);
    if (this.vm.bomInput) {
      let tempBillOfMaterials: any = {};
      let tempBillOfMaterialsId = '';
      // console.log('BOM raw');
      // console.log(this.vm.BillOfMaterials);
      // console.log('BOMinpout');
      // console.log(this.vm.bomInput);
      let selectedBOMProperties: any;
      if (isExisting) {
        // console.log('here');
        // console.log(this.vm.BillOfMaterials);
        // console.log(this.vm.bomInput);

        selectedBOMProperties = this.vm.BillOfMaterials.filter((x) => x.item_id === this.vm.bomInput);
      } else {
        selectedBOMProperties = this.vm.BillOfMaterials.filter((x) => x.item_name === this.vm.bomInput);
      }
      // const selectedBOMProperties = this.vm.BillOfMaterials.filter((x) => x.item_name === this.vm.bomInput);
      this.vm.selectedBOMProperties = selectedBOMProperties;

      // set the qty that was previously built when in existing item - grab bom item line - has qty built
      const bomLineOfDetail = this.vm.item.detail.filter((x) => x.item_type_id === 'bom');
      // console.log('detail filtered for bom');
      // console.log(bomLineOfDetail);
      //
      // console.log('vm.item before qty filer');
      // console.log(this.vm.item);

      if (isExisting) {
        // now grab the actual qty from line if it is an existing entry
        this.vm.selectedBOMProperties.quantity = this.FilterObject(
          bomLineOfDetail,
          { item_type_id: 'bom' },
          true
        )[0].quantity;
      }

      // console.log('quantity of bom items built: ' + this.vm.selectedBOMProperties.quantity);
      // console.log('filtered bom array: ' + JSON.stringify(selectedBOMProperties));

      tempBillOfMaterialsId = selectedBOMProperties[0].id;
      tempBillOfMaterials = selectedBOMProperties[0].item_name;

      // console.log('id: ' + tempBillOfMaterialsId);
      // console.log('name: ' + tempBillOfMaterials);
      // console.log('final: this.vm.selectedBOMProperties');
      // console.log(this.vm.selectedBOMProperties);
      // }

      if (this.vm.assembleId === 'new') {
        // console.log('new build: ' + this.vm.assembleId);
        this.tableDataService.getTableData('BillOfMaterials', tempBillOfMaterialsId).subscribe((result: any) => {
          this.vm.tempBOM = result;
          // console.log('tempBOM and length:' + this.vm.tempBOM.length);
          // console.log(this.vm.tempBOM);
          this.vm.item.detail = [];
          this.vm.item.detail.push({
            id: 1,
            item_id: this.vm.tempBOM.item_id,
            itemInput: this.FilterObject(this.vm.inventoryItemAll, { id: this.vm.tempBOM.item_id }, true)[0].code,
            description: this.FilterObject(this.vm.inventoryItemAll, { id: this.vm.tempBOM.item_id }, true)[0].name,
            uom_id: this.FilterObject(this.vm.inventoryItemAll, { id: this.vm.tempBOM.item_id }, true)[0].uom_id,
            sort_order: 1,
            display_quantity: tempBillOfMaterials.quantity,
            quantity: tempBillOfMaterials.quantity,
            account_id: 'in',
            control_line: true,
          });
          // console.log('source');
          // console.log(this.vm.tempBOM.source);
          // console.log('item');
          // console.log(this.vm.inventoryItemAll);
          for (let x = 0; x < this.vm.tempBOM.source.length; x++) {
            // console.log('in for 3: ' + this.vm.tempBOM.source.length);
            // console.log(JSON.stringify(this.vm.tempBOM.source));
            this.vm.item.detail.push({
              id: x + 2,
              item_id: this.vm.tempBOM.source[x].item_id,
              itemInput: this.FilterObject(this.vm.inventoryItemAll, { id: this.vm.tempBOM.source[x].item_id }, true)[0]
                .code,
              description: this.FilterObject(
                this.vm.inventoryItemAll,
                { id: this.vm.tempBOM.source[x].item_id },
                true
              )[0].name,
              uom_id: this.FilterObject(this.vm.inventoryItemAll, { id: this.vm.tempBOM.source[x].item_id }, true)[0]
                .uom_id,
              sort_order: x + 2,
              display_quantity: this.vm.tempBOM.source[x].quantity,
              quantity: 0 - this.vm.tempBOM.source[x].quantity,
              debit_credit: 0 - this.vm.tempBOM.source[x].quantity,
              account_id: 'in',
              control_line: false,
            });
          }
          // console.log(this.vm.item.detail);
          for (let x = 0; x < this.vm.tempBOM.costs.length; x++) {
            this.vm.item.detail.push({
              id: x + this.vm.tempBOM.source.length + 2,
              description: $localize`:Assembly Cost @@assemblyCost:Assembly Cost`,
              account_id: this.vm.tempBOM.costs[x].account_id,
              accountInput:
                this.FilterObject(this.vm.account, { id: this.vm.tempBOM.costs[x].account_id }, true)[0].number +
                ' ' +
                this.FilterObject(this.vm.account, { id: this.vm.tempBOM.costs[x].account_id }, true)[0].name,
              sort_order: x + 2,
              amount: this.vm.tempBOM.costs[x].amount,
              debit_credit: 0 - this.vm.tempBOM.costs[x].amount,
              control_line: false,
            });
          }
        });
      }
      // console.log('item detail');
      // console.log(this.vm.item);
    }
  }

  // }

  selectBOMModal(id) {
    // console.log('BOM at selectBOMModal');
    // console.log(this.vm.BillOfMaterials);
    this.vm.bomInput = this.FilterObject(this.vm.BillOfMaterials, { id }, true)[0].item_name;
    this.vm.bomInputSelect = this.FilterObject(this.vm.BillOfMaterials, { id }, true)[0].item_name;
    this.bomSelected(false);
  }

  filterBOM() {}

  updateBOM() {
    this.tableDataService
      .getApiListData('BillOfMaterials', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.BillOfMaterials = data;
        this.vm.bomName = [];
        for (const [y, val] of Object.entries(this.vm.BillOfMaterials)) {
          const tempName = this.vm.BillOfMaterials[y].item_name;
          this.vm.bomName.push({
            name: tempName,
          });
        }
      });
  }

  setDecimal(character) {
    // console.log('value: ' + character);
    if (Number(character)) {
      this.vm.item.detail.display_quantity = this.decimalPipe.transform(character);
      // console.log('return value: ' + this.decimalPipe.transform(character));
    }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  onClose() {
    this.router.navigate(['/inventory/assemble/list']).then();
  }

  deleteItem() {
    if (this.vm.assembleId != 'new') {
      const brClass = this;
      // let retMsg = '';
      const messages: string[] = [
        $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
        '',
      ];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.vm.item.header.deleted = true;
          this.deleteItemByArray()
        },
        () => {
          brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
        }
      );
    }
  }
  deleteItemByArray() {
    // just one item but stick it in array so api understands - the old save as delete method above is not good - to slow.
    const deleteMe = [];
    deleteMe.push({ header_id: this.vm.item.header.id });

    this.tableDataService.deleteTableDataByArray('entry', deleteMe).subscribe((item: any) => {
      if (item) {
        if (item.Message === 'Success') {
          this.router.navigate(['/inventory/assemble/list']).then();
        }
      }
    });
  }

}
