export class TaxEngineOuputModelData {
//   taxData: TaxData;
// }
//
// export class TaxData {
    taxIdUsedForLookup: string;
    taxMessage: string;
    taxRate: number;
    taxEpiryDate: string;
    taxType: string;

    entityIdUsedForDefaults: string;
    entityDefaultRTaxId1: string;
    entityDefaultRTaxRate1: string;
    entityDefaultRTaxId2: string;
    entityDefaultRTaxRate2: string;
    entityDefaultPTaxId1: string;
    entityDefaultPTaxRate1: string;
    entityDefaultPTaxId2: string;
    entityDefaultPTaxRate2: string;
  }

