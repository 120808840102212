import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { DatePipe, DecimalPipe, ViewportScroller } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterPipe } from 'ngx-filter-pipe';
import { ResponseModel } from '../../../DataModels/response-model';
import { AppConfigService } from '../../../Services/app-config.service';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';
import {BsToastService} from '../../../Services/bs-toast-service';

// import {ArraySortPipe} from '../../orderBy';

@Component({
  selector: 'app-statistical-recurring-list',
  templateUrl: './statistical-recurring-list.component.html',
  styleUrls: ['./statistical-recurring-list.component.css'],
})
export class StatisticalRecurringListComponent implements OnInit, AfterViewInit, OnDestroy {

  vm: any = {
    title: $localize`:Recurring Statistical Entries @@recurringStatisticalEntries:Recurring Statistical Entries`,
    Preferences: {},
    permissions: {},
    list: {},
    dateFormat: 'MM/dd/yyyy',
    owingSelected: 0,
    totalSelected: 0,
    statisticalToDateOpen: false,
    statisticalFromDateOpen: false,
    statisticalFilter: {},
    statisticalSearch: {},
    statisticalToDate: {},
    statisticalFromDate: {},
    numberOfResults: {},
    buttonsDisabled: false,
    advancedSearch: false,
    searching: false,
  };
  advancedSearch;
  isLoaded = false;
  StatisticalRecurCount = 0;

  checkAll = false;
  selectedRecords = [];

  serverSearchTerm = '';
  gridPageNumber = 0;
  gridPageSize = this.appConfig.ListPageSize;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private decimal: DecimalPipe,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private customFilterBy: FilterPipe,
    private appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngOnInit() {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.dataInit();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([6, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  dataInit() {
    this.vm.statisticalFilter = this.localStorage.StorageGet('statisticalFilter', {
      text: '',
      sort: 'audit_sequence',
      ascending: true,
    });

    this.vm.statisticalSearch = this.localStorage.StorageGet('statisticalSearch', '');
    this.vm.statisticalToDate = this.localStorage.StorageGet('statisticalToDate', '');
    this.vm.statisticalFromDate = this.localStorage.StorageGet('statisticalFromDate', '');
    this.vm.numberOfResults = this.localStorage.StorageGet('numberOfResults', '25');
    this.vm.statisticalSearch = this.vm.statisticalFromDate = this.vm.statisticalToDate = this.vm.unappliedOnly = '';
    const listParams = {
      batchType: '16', //16
      recurring: '1',
      search: this.localStorage.StorageGet('statisticalSearch', ''),
      fromDate: this.localStorage.StorageGet('statisticalFromDate', ''),
      toDate: this.localStorage.StorageGet('statisticalToDate', ''),
      locked: this.localStorage.StorageGet('unappliedOnly', ''),
      numberOfResults: this.localStorage.StorageGet('numberOfResults', ''),
      pageNumber: this.page !== 0 ? this.page - 1 : 0,
      pageSize: this.pageSize,
    };
    this.tableDataService
      .getApiListData('entry?' + this.encodeQueryData(listParams, false))
      .subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;

        this.isLoaded = true;
        this.StatisticalRecurCount = result.totalRecords;
        // this.vm.list = this.orderBy.transform(this.vm.list, 'header_date'); // TODO: order by..
      });
  }

  convertToNumbers() {
    // for (x = 0; x <this.vm.list.length; x++) {
    //    this.vm.list[x].header_amount = Number(this.vm.list[x].header_amount) * this.vm.reverse;
    //     this.vm.list[x].audit_sequence = Number(this.vm.list[x].audit_sequence);
    //     if (this.vm.list[x].header_applied) {
    //         this.vm.list[x].header_applied = Number(this.vm.list[x].header_applied) * this.vm.reverse;
    //     } else {
    //         this.vm.list[x].header_applied = 0;
    //     }
    // }
  }

  checkSelected() {
    const selectedIds = this.vm.list.filter((rec) => rec.selected).map((rec) => rec.id);
    this.vm.recurring = [];
    for (const selectedId of selectedIds) {
      this.vm.recurring.push({
        header_id: selectedId,
      });
    }
    if (!this.vm.recurring) {
      this.notification.showWarningToast(
        $localize`:You must select at least one entry @@youmustselectatleastoneentry:You must select at least one entry`
      );
    }
  }

  selectRow(rec: any, checked: boolean) {
    if (this.checkAll && rec) {
      this.checkAll = false;
    }
    if (!checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec) {
          item.selected = false;
        }
      });
    } else if (checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec && !item.locked) {
          item.selected = true;
        }
      });
    }
    this.isAllChecked();
  }

  selectAllRows(event: any) {
    if (!event) {
      this.vm.list.forEach((item) => (item.selected = false));
    } else if (event) {
      // this.vm.list.forEach((item) => (item.selected = true));
      this.vm.list.forEach((item) => {
        if (!item.locked) {
          item.selected = true;
        }
      });
    }
  }

  isAllChecked(): boolean {
    const retVal = this.vm.list.every((x) => x.selected === true);
    this.checkAll = retVal;
    return retVal;
  }

  //
  clearSearch() {
    this.vm.statisticalSearch = this.vm.statisticalFromDate = this.vm.statisticalToDate = this.vm.unappliedOnly = '';
    this.vm.numberOfResults = '25';
    this.vm.list = {};
    this.vm.searching = false;
    this.localStorage.StorageSet('statisticalSearch', '');
    this.localStorage.StorageSet('statisticalToDate', '');
    this.localStorage.StorageSet('statisticalFromDate', '');
    this.localStorage.StorageSet('numberOfResults', '25');
    this.getPagedApiData();
  }

  getPagedApiData() {
    this.vm.searching = true;
    if (this.vm.unappliedOnly) {
      this.vm.numberOfResults = 'All';
    }

    const listParams = {
      batchType: '16',
      recurring: '1',
      search: this.vm.statisticalSearch,
      fromDate: this.dateFilter.GetDateFormat(this.vm.statisticalFromDate),
      toDate: this.dateFilter.GetDateFormat(this.vm.statisticalToDate),
      unappliedOnly: this.vm.unappliedOnly,
      numberOfResults: this.vm.numberOfResults,
      pageNumber: this.gridPageNumber,
      pageSize: this.gridPageSize,
    };
    this.tableDataService
      .getApiListData('entry?' + this.encodeQueryData(listParams, false))
      .subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        // this.vm.list = this.orderBy.transform(this.vm.list, 'header_date'); // TODO: order by..
        this.isLoaded = true;
        this.convertToNumbers();
      });
  }

  filter(filter) {
    this.vm.statisticalFilter = Object.assign(this.vm.statisticalFilter, filter);
    this.localStorage.StorageSet('statisticalFilter', JSON.stringify(this.vm.statisticalFilter));
  }

  generate() {
    this.vm.alert = {};
    this.vm.alert.message = [];
    
    this.checkSelected();

    if (this.vm.recurring.length > 0) {
      this.tableDataService.post('recurring', this.vm.recurring).subscribe(
        (item: any) => {
          this.notification.showSuccessToast(item.description);
          this.vm.buttonsDisabled = false;
          this.getPagedApiData();
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          this.vm.alert.status = 'error';
          // vm.alert = {message: [{text:'server side error'}],status:'error'};
          this.vm.alert.message.push({ text: this.vm.recurring });
        }
      );
    } else {
      this.notification.showErrorToast(
        $localize`:You must select at least one statistical @@youmustselectatleastonestatistical:You must select at least one statistical`
      );
    }
  }

  encodeQueryData(data, filterEmpty = true) {
    const ret = [];
    for (const d in data) {
      if (filterEmpty) {
        if (data[d] != null && data[d] != '') {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
      } else {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  // Server side paging ---------------------------------------------------------------------------------

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.statisticalSearch = value.searchTerm;
    this.vm.statisticalFromDate = value.fromDate;
    this.vm.statisticalToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.statisticalSearch = value.searchTerm;
    this.vm.statisticalFromDate = value.fromDate;
    this.vm.statisticalToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  onNavigate(aID: string, print = false) {
    this.router.navigate(['/transactions/statistical/form'], { queryParams: { statisticalId: aID } });
  }
}
