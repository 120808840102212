import {Component, OnInit, Input, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';

@Component({
  selector: 'app-account-subtotal-select',
  templateUrl: './account-subtotal-select.component.html',
  styleUrls: ['./account-subtotal-select.component.css']
})
export class AccountSubtotalSelectComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;

  constructor(public isFiltered: IsFilterOnService) {
  }

  ngOnInit() {
  }
  checkFiltered(): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    this.vm.filtered = filteredValue;
    return filteredValue;
  }

  ngOnDestroy() {
    if(this.closeButton?.nativeElement) {
      this.closeButton.nativeElement.click();
    }

  }

}
