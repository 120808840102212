export class CashFlowModel {
  cf_section: string;
  accounts: Account[];
  // totalCurrentPeriod: number;
  // totalPriorPeriod: number;
  // subtotal_id: string;
  // parent_id: string;
  // sort_order: number;
}

  export class Account {
  account_id: string;
  account: string;
  current_period: number;
  prior_period: number;
  budget: number;
  current_stat: number;
  prior_stat: number;
  subtotal_id: string;
  debit: boolean;
  acctCF?: any;
}

