import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../../../../Services/email.service';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { Router } from '@angular/router';
import { DataExportService } from '../../../../../Services/dataExport.service';
import {CustomFilterPipe} from '../../../../admin/custom-filter.pipe';
import {ResponseModel} from '../../../../../DataModels/response-model';
import {EmailPassDataServiceService} from '../../../../../Services/email-pass-data-service.service';
import {BsToastService} from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-employee-detail-report',
  templateUrl: './employee-detail-report.component.html',
  styleUrls: ['./employee-detail-report.component.css']
})

export class EmployeeDetailReportComponent implements OnInit, AfterViewInit, AfterViewChecked {

  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    private router: Router,
    public notification: BsToastService,
    public appConfigService: AppConfigService, public route: ActivatedRoute,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    public excelExporter: DataExportService,
    private cFilterPipe: CustomFilterPipe,
    private emailData: EmailPassDataServiceService) {

    this.timeOut = this.appConfigService.toastrWarnTimeOut;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');

  }

  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  emailForm: UntypedFormGroup;
  private rptName: string;
  timeOut = 0;
  public urlParams: any = [];
  public emailInvalidMsg: string;

  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    earning: [],
    request: {
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      employee_id: null,
      employeeInput: null
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: ''
    },
    columns: false,
    company: [],
    location: [],
    currency: [],
    filtered: true,
    showFilters: true,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    monthCount: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    startInventoryItem: 0,
    Preferences: null,
    permissions: null,
    currentMonthMargin: 0,
    priorMonthMargin: 0,
    currentPeriodMargin: 0,
    priorPeriodMargin: 0,
    account: [],
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    caclutedDate: null,
    toDateTitle: null,
    fromDateTitle: null,
    priorToDateTitle: null,
    priorToDateLink: null,
    priorMonthFromDateLink: null,
    currentMonthFromDateLink: null,
    priorFromDateLink: null,
    priorFromDateTitle: null,
    accountParents: [],
    accountSubtotal: [],
    bank: [],
    entity: [],
    entityAddress: [],
    entityType: [],
    paymentType: [],
    inventoryItem: [],
    accountType: [],
    inventoryItemName: [],
    companyId: null,
    currencyId: null,
    locationId: null,
    companyName: null,
    locationName: null,
    employee: [],
    inventoryItemInput: '',
    employeeName: [],
    startEmployee: 0,
    employeeFilter: { name: '' },
    employee_email: [],
    employeeInput: '',
    reportEmployees: [],
    reportTemp: [],
    exportToExcel: true,
    header: []
  };
  public submitted = false;

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Employee Detail Report @@employeeDetailReport:Employee Detail Report`;
  }

  ngAfterViewChecked() {
    // alert('viewChecked fired');
    // sets data for email of report
    const div = document.getElementById('excel-export');

    // console.log(typeof div);
    // console.log(div.toString());
    // console.log(typeof div.outerHTML);
    // console.log('viewchk: ' + div.outerHTML);
     // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Employee Detail Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);

  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, 'Payroll-Employee-Report.xlsx');
  }

  onSubmit() {
    this.emailForm.reset();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {

    this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
    this.tableDataService.getTableData('currency?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.currency = result.Data;
    });
    this.tableDataService.getTableData('earning?state=active').subscribe((result: ResponseModel) => {
      this.vm.earning = result.Data;
    });

    this.vm.employeeName = [];
    this.tableDataService.getTableData('employee?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.vm.employee = data;
      for (const [x, value] of Object.entries(this.vm.employee)) {
        this.vm.employeeName.push({name: this.vm.employee[x].name});
      }
    });

    // if (this.localStorage.StorageGet('company', '')) {
    //   this.vm.company = this.localStorage.StorageGet('company', '');
    // } else {
    //   this.localStorage.StorageSet('company', this.vm.company);
    // }
    // if (this.localStorage.StorageGet('employee', '')) {
    //   this.vm.employee = this.localStorage.StorageGet('employee', '');
    // } else {
    //   this.localStorage.StorageSet('employee', this.vm.employee);
    // }
    // if (this.localStorage.StorageGet('currency', '')) {
    //   this.vm.currency = this.localStorage.StorageGet('currency', '');
    // } else {
    //   this.localStorage.StorageSet('currency', this.vm.currency);
    // }

    if (this.urlParams.employeeId) {
      this.vm.request.employee_id = this.urlParams.employeeId;
    }

    if (this.urlParams.currency_id) {
      this.vm.request.currency_id = this.urlParams.currency_id;
    }

    // if (this.urlParams.toDate) {
    //   this.vm.request.toDate = this.urlParams.toDate;
    // } else if (this.localStorage.StorageGet('toDate', '')) {
    //   this.vm.request.toDate = this.localStorage.StorageGet('toDate', '');
    // } else {
    //   let d = new Date();
    //   d = new Date(d.getFullYear(), d.getMonth(), 1);
    //   d.setDate(d.getDate() - 1);
    //   this.vm.request.fromDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
    //   this.localStorage.StorageSet('toDate', this.vm.request.toDate);
    // }

    if (this.vm.request.toDate
      && (this.urlParams.companyId
        || this.urlParams.currencyId
        || this.urlParams.employeeID
        || this.urlParams.itemId
      )
    ) {
      this.update();
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data.hasOwnProperty(d)){
        if (data[d] != null && data[d] != '') {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
      }
    }
    return ret.join('&');
  }

  update() {
    this.vm.toDateError = this.vm.fromDateError = false;

    if (!this.vm.request.toDate || !this.vm.request.employee_id) {

      if (!this.vm.request.employee_id) {
        this.notification.showWarningToast($localize`:Employee is required. @@employeeIsRequired:Employee is required.`);
      }
      if (!this.vm.request.toDate) {
        this.vm.dateError = true;
        this.notification.showWarningToast($localize`:Date is required. @@dateIsRequired: Date is required.`);
      }
      return;
    }

    this.vm.dateError = this.vm.emailed = false;
    this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
    this.vm.buttonsDisabled = true;
    this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
    const urlParamsJson = {
      toDate: this.vm.request.toDate,
      company_id: this.vm.request.company_id,
      employee_id: this.vm.request.employee_id,
      currency_id: this.vm.request.currency_id,
      pageNumber: 0,
      pageSize: 999999,
    };

    const EmployeeDetailReportParam: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getTableData('EmployeeDetail', '?' + EmployeeDetailReportParam).subscribe((result: ResponseModel) => {
      const data: any = result.Data;

      if (data.length == 0) {
        this.vm.buttonsDisabled = false;
        this.notification.showInfoToast('No data to report using these parameters!');
        return;
      } else {
        this.vm.report = data;
      }

      this.calcTotals();
      this.vm.showFilters = this.vm.buttonsDisabled = false;
      if (this.vm.employeeInput) {
        this.vm.filtered = true;
      } else {
        this.vm.filtered = false;
      }

      // if ((this.vm.request.company_id && this.vm.company.length > 1)
      //   || this.vm.request.employee_id
      //   || (this.vm.request.currency_id && this.vm.currency.length > 1)
      // ) {
      //   this.vm.filtered = true;
      // }
      // if (this.vm.request.company_id) {
      //   this.vm.companyName = this.FilterObject(this.vm.company, { id: this.vm.request.company_id })[0].name;
      // } else {
      //   this.vm.companyName = [];
      // }

      // if (this.vm.request.location_id) {
      //   this.vm.employeeName = this.FilterObject(this.vm.employee, { id: this.vm.request.employee_id })[0].name;
      // } else {
      //   this.vm.employeeName = [];
      // }
    });

  }

  calcTotals() {
    const headers = [];
    let tempHeaderId = '';
    let tempRecords = [];
    let grossPay = 0;
    let withHeld = 0;
    let x;
    let y;
    let a;
    let z;

    for (x = 0; x < this.vm.report.length; x++) {
      if (this.vm.report[x].header_id !=  tempHeaderId) {
        tempHeaderId = this.vm.report[x].header_id;
        tempRecords = this.FilterObject(this.vm.report, { header_id: tempHeaderId }, true);
        grossPay = withHeld = 0;

        for (y = 0; y < tempRecords.length; y++) {
          if (tempRecords[y].deduction ==  '1') {
            withHeld += tempRecords[y].amount;
          } else {
            grossPay += (tempRecords[y].amount * tempRecords[y].quantity);
          }
        }
        headers.push({
          id: tempHeaderId,
          header_date: this.vm.report[x].header_date,
          employee_id: this.vm.report[x].employee_id,
          number: this.vm.report[x].number,
          grossPay,
          withHeld
        });
      }
      this.vm.headers = headers;
      if (this.FilterObject(this.vm.employee, { id: this.vm.report[x].employee_id }).length > 0) {
        const employeeName = this.FilterObject(this.vm.employee, { id: this.vm.report[x].employee_id }, true)[0].name;
        this.vm.report[x].employee_name = employeeName;
        if (this.FilterObject(this.vm.reportEmployees, { name: employeeName }, true).length ==  0) {
          this.vm.reportEmployees.push({
            id: this.vm.report[x].employee_id,
            name: this.vm.report[x].employee_name
          });
        }
      } else {
        this.updateEmployee();
      }
    }
    let tempAmount;
    let employeeDetails;
    for (x = 0; x < this.vm.employee.length; x++) {
      employeeDetails = this.FilterObject(this.vm.headers, { employee_id: this.vm.employee[x].id }, true);
      grossPay = withHeld = 0;
      for (y = 0; y < employeeDetails.length; y++) {
        if (this.vm.employee[x].id === employeeDetails[y].employee_id) {
          grossPay += employeeDetails[y].grossPay;
          withHeld += employeeDetails[y].withHeld;
        }
      }

      for (y = 0; y < this.vm.reportEmployees.length; y++) {
        if (this.vm.employee[x].id === this.vm.reportEmployees[y].id) {
          this.vm.reportEmployees[y].grossPay = grossPay;
          this.vm.reportEmployees[y].withHeld = withHeld;
          this.vm.reportTemp = this.FilterObject(this.vm.report, { employee_id: this.vm.employee[x].id }, true);
          this.vm.reportEmployees[y].details = [];
          for (a = 0; a < this.vm.earning.length; a++) {
            tempAmount = 0;
            for (z = 0; z < this.vm.reportTemp.length; z++) {
              if (this.vm.reportTemp[z].earning_id ==  this.vm.earning[a].id) {
                if (this.vm.earning[a].id.length < 30) {
                  tempAmount += this.vm.reportTemp[z].amount;
                } else {
                  tempAmount += this.vm.reportTemp[z].amount * this.vm.reportTemp[z].quantity;
                }
              }
            }
            this.vm.reportEmployees[y].details.push({
              amount: tempAmount,
              earning_id: this.vm.earning[a].id
            });
          }

        }
      }
    }
  }

  updateEmployee() {
    this.vm.employeeName = [];
    this.localStorage.StorageSet('employee', '');

    this.tableDataService.getTableData('employee', '?state=active').subscribe((result) => {
      this.vm.employee = result;
      this.vm.employeeName = [];
      for (const [ x, value] of Object.entries(this.vm.employee)) {
        this.vm.employeeName.push({ name: this.vm.employee[x].name });
      }
    });
  }

  selectEmployeeModal(employeeName) {
    if (this.vm.request.employee_id === '' || 1 ==  1) {
      for (const [ y , value] of Object.entries(this.vm.employee)) {
        if (this.vm.employee[y].name ==  employeeName) {
          this.vm.request.employeeInput = this.vm.employeeInput = employeeName;
          this.vm.request.employee_id = this.vm.employee[y].id;
          this.vm.employee_email = this.vm.employee[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEmployee() {
    const employeeIdStart = this.vm.request.employee_id;
    if (!this.vm.employeeInput || this.vm.employeeInput ==  '') {
      this.vm.request.employee_id = '';
    } else {
      if (this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }).length > 0) {
        const employeeId = this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }, true)[0].id;
        const employeeName = this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }, true)[0].name;
        const employeeEmail = this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }, true)[0].email;
        if (employeeId !=  '') {
          this.vm.request.employee_id = employeeId;
          this.vm.employeeInput = employeeName;
          this.vm.employee_email = employeeEmail;
        } else {
          this.vm.request.employee_id = '';
          this.vm.employee_email = [];
        }
      } else {
        this.vm.request.employee_id = '';
        this.vm.employee_email = [];
      }
    }
    if (employeeIdStart !=  this.vm.request.employee_id) {
      this.vm.report = [];
    }
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.vm.employeeInput = '';
    this.vm.report = [];
    this.vm.request = {
      employee_id: null,
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      employeeInput: null
    };
    this.vm.filtered = false;
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' - ' + $localize`:Date @@date:Date` + ' ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  FilterObject(value, filterObj, actual = false) {
    return this.cFilterPipe.transform(value, filterObj, actual);
  }

  MathRound(int) {
    Math.round(int);
  }

  parseInt(val) {
    return parseInt(val, 0 );
  }

  Print() {
    return window.print();
  }

  setCompanyId(event) {
    this.vm.report = [];
    this.vm.request.company_id = event;
  }

  filterReport() {
    this.vm.report = [];
  }

}

