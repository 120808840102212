import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-modal-insert-item',
  templateUrl: './modal-insert-item.component.html',
  styleUrls: ['./modal-insert-item.component.css']
})
export class ModalInsertItemComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
