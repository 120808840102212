import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-account-subtotal-select',
  templateUrl: './account-subtotal-select.component.html',
  styleUrls: ['./account-subtotal-select.component.css']
})
export class AccountSubtotalSelectComponent implements OnInit {
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

}
