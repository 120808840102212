import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TableDataService} from '../../../Services/tableData.service';
import {BsToastService} from '../../../Services/bs-toast-service';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
// import {HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'app-change-batch-modal',
  templateUrl: './change-batch-modal.component.html',
  styleUrls: ['./change-batch-modal.component.scss']
})
export class ChangeBatchModalComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('closeButton') closeButton: ElementRef;

  searchText: string;
  @Input() idSrc: any;
  @Input() batchType: any;

  batchList: any;
  batchCount = 0;
  vm = {
    batches: []
  };


  showFeed = false;
  constructor(
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private confirmDialog: ConfirmDialogService
    ) {
  }

  ngOnInit() {
    // this.loadAuditTrail();

  }

  ngOnChanges() {
   // this.loadBatches();
    this.loadBatchesByType();
  }

  ngAfterViewInit() {
   // this.loadBatches();
  }

  loadBatchesByType() {
    // console.log('batch src new called for batch type: ' + this.batchType);

    if (this.batchType) {
      // this.batchList = [];

      let paramString = '';
      paramString += 'batchType=' + (this.batchType ? this.batchType : '');
      paramString += '&myBatches=false';
      paramString += '&lockedBatches=false';

      // console.log(paramString);
      this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any) => {
        // this.vm.batches = result.filter(x => x.my_batch && !x.locked);
        // this.batchList = result.filter(x => x.my_batch && !x.locked);
        this.vm.batches = result;
        this.batchList = result;
        this.batchCount = this.batchList.length;
        // console.log('batch src new: ' + JSON.stringify(this.vm.batches));
      });

    }
  }
    changeBatch(id) {

    const changes = { batch_id: id };
    const messages: string[] = [$localize`:Are you sure you want to reassign this transaction to the selected batch? @@areYouSureYouWantToReassignThisBatch:Are you sure you want to reassign this transaction to the selected batch?`, ''];
    this.confirmDialog.confirmThis(messages,
        () => {
          // alert(id);
      this.tableDataService
            .patch('t_header', this.idSrc.item.header.id, changes)
            .then((item: any) => {
              // this.notification.showSuccessToast($localize`:Moved @@moved:Moved`);
              this.loadBatchesByType();
            })
            .catch((msg: any) => {
              console.log(msg);
            });
        }, () => {
          this.notification.showSuccessToast($localize`:Transaction Batch Reassign Cancelled@@batchReassignCancelled:Transaction Batch Reassign Cancelled`);
        });

  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}
