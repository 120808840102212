import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TableDataService} from '../../../Services/tableData.service';
import {NotificationService} from '../../../Services/notification.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-audit-trail-modal',
  templateUrl: './audit-trail-modal.component.html',
  styleUrls: ['./audit-trail-modal.component.css']
})
export class AuditTrailModalComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('closeButton') closeButton: ElementRef;

  searchText: string;

  @Input() src: string;

  auditTrailList: Array<object>;
  auditTrailCount = 0;

  showAudit = false;

  constructor(private table: TableDataService,
              private toast: NotificationService) {
  }

  ngOnInit() {
    // this.loadAuditTrail();
  }

  ngOnChanges() {
    this.loadAuditTrail();
  }

  loadAuditTrail() {

    if (this.src === '') {
     // console.log('no rec: ' + this.src);
      this.table.getTableData('audit_trail').subscribe(
        result => {
          this.auditTrailList = result;
          this.auditTrailCount = this.auditTrailList.length;
          // console.log('empty src:' + this.src);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          // this.toast.error($localize`:Audit Trail Error @@auditTrailError:Error loading Audit Trail Data`);
        },
        () => {
        }
      );
    }

    if (this.src !== '') {
      // console.log('got a rec: ' + this.src);
      this.table.getTableDataWithParams('audit_trail/recid', 'recId=' + this.src).subscribe(
        result => {
          this.auditTrailList = result;
          this.auditTrailCount = this.auditTrailList.length;
          // console.log('audit trail recid:' + this.src);
          // console.log('audit trail result:' + JSON.stringify(result));
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {
        }
      );

    }
  }

  toggleAudit() {
    this.showAudit = !this.showAudit;
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
