import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
// import {EmailService} from '../../../../Services/email.service';

@Component({
  selector: 'app-buttons-email',
  templateUrl: './buttons-email.component.html',
  styleUrls: ['./buttons-email.component.css']
})
export class ButtonsEmailComponent implements OnInit {
  @Input() vm: any;

  @Input() buttonConfig: any;

  @Output() updateEmit = new EventEmitter<string>();
  @Output() setReportDataEmit = new EventEmitter<string>();
  @Output() clearEmit = new EventEmitter<string>();
  @Output() closeEmit = new EventEmitter<string>();
  @Output() exportToExcelEmit = new EventEmitter<string>();
  @Output() reportFormatEmit = new EventEmitter<string>();

  public emailForm: UntypedFormGroup;
  public emailInvalidMsg: string;
  public submitted = false;

  buttons: any = {
    reportFormat: false,
    print: true,
    email: true,
    update: true,
    exportExcel: true,
  };

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.emailInvalidMsg = $localize`:Use commas to separate multiple email addresses @@usecommastoseparatemultipleemailaddresses:Use commas to separate multiple email addresses`;
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });

    if (this.buttonConfig) {
      this.buttons = this.buttonConfig;
    }
  }

  onSubmit() {
    this.emailForm.reset();
  }

  update() {
    this.updateEmit.emit();
  }

  exportToExcel() {
    this.exportToExcelEmit.emit();
  }

  clear() {
    this.vm.monthCount = 0;
    this.clearEmit.emit();
  }

  close() {
    this.vm.monthCount = 0;
    this.closeEmit.emit();
  }

  get fields() {
    return this.emailForm.controls;
  }

  setReportData() {
    this.setReportDataEmit.emit();
  }

  reportFormat(event) {
    this.vm.report_format = event;
    this.reportFormatEmit.emit(event);

  }
}
