import {Injectable} from '@angular/core';
import {SharedDataService} from './sharedData.service';
import {EncrDecrService} from './encr-decr.service';

declare var angular: any;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private sharedData: SharedDataService,
    private encryptDecrypt: EncrDecrService) {
  }
  // Note 20210209 rlp - this looks odd, but the orig app made extensive use of localstorage as a local cache. bad idea.
  // i hijacked it here to implement a new shared data via service. just allows the old code in each ts to
  // be changed by attrition and still works as is. when we work on a ts with localstorage fix the code.
  // we can still cache big sets to shared data - just make the adjustments here - setup a var in storage
  // and mod add - get here.
  // add as may values to the service as we needed. make objects where it makes sense to aggregate.

  // *** NOTE *** permissions, preferences, and token are in auth service and saved as encrypted from there.

  StorageSet(aKey: string, aValue: any): void {
   if (aKey !== 'location'
     && aKey !== 'company'
     && aKey !== 'token'
     && aKey !== 'entity'
     && aKey !== 'account'
     && aKey !== 'subtotalAccount'
     && aKey !== 'subAccount'
     && aKey !== 'accountSubtotal'
     && aKey !== 'inventoryItem'
     // && aKey !== 'user'
     && aKey !== 'entityType'
     && aKey !== 'currency'
     && aKey !== 'uom'
     && aKey !== 'paymentType'
     && aKey !== 'tax'
     && aKey !== 'taxRate'
     && aKey !== 'term'
     && aKey !== 'rfType'
     && aKey !== 'rf1'
     && aKey !== 'rf2'
     && aKey !== 'rf3'
     && aKey !== 'rf4'
     && aKey !== 'rf5'
     && aKey !== 'salesperson'
     && aKey !== 'reportData'
     && aKey !== 'account_subtotal'
     && aKey !== 'sendEmailInfoObj'
     && aKey !== 'modeOperations') {
     // test code to find those items we need to trap and add to shared service
     if (aKey !== 'Permissions'
       && aKey !== 'Preferences'
       && aKey !== 'token'
       && aKey !== 'uId'
       && aKey !== 'uName'
       && aKey !== 'uDb'
       && !aKey.startsWith('trend') ){
       // console.log('setting local - key: ' + aKey + ' value: ' + (aValue ? 'has data' : 'empty'));
     }

     // * * * NOTE * * * these are the only ls items we allow. they are encrypted before stored
     // they are necessary to be in ls for app to func as is currently structured and due to using new browser tabs
     // for drill down transaction-reports etc. there is no good way to pass vars between browser tabs and we must do drill down
     if (aKey === 'Permissions'
       || aKey === 'Preferences'
       || aKey === 'token'
       || aKey === 'uId'
       || aKey === 'uName'
       || aKey === 'uDb'
       || aKey.startsWith('trend')) {
       localStorage.setItem(aKey, aValue);
     }
   } else if (aKey === 'location') {
     this.sharedData.addLocation(aValue);
   } else if (aKey === 'modeOperations') {
     this.sharedData.addModeOperations(aValue);
   } else if (aKey === 'reportData') {
     this.sharedData.addReportData(aValue);
   } else if (aKey === 'rfType') {
     this.sharedData.addRFType(aValue);
   } else if (aKey === 'rf1') {
     this.sharedData.addRf1(aValue);
   } else if (aKey === 'rf2') {
     this.sharedData.addRf2(aValue);
   } else if (aKey === 'rf3') {
     this.sharedData.addRf1(aValue);
   } else if (aKey === 'rf4') {
     this.sharedData.addRf4(aValue);
   } else if (aKey === 'rf5') {
     this.sharedData.addRf5(aValue);
   } else if (aKey === 'salesperson') {
     this.sharedData.addSalesperson(aValue);
   } else if (aKey === 'company') {
     this.sharedData.addCompany(aValue);
   } else if (aKey === 'token') {
     this.sharedData.addToken(aValue);
     localStorage.setItem(aKey, aValue);
   } else if (aKey === 'entity') {
     this.sharedData.addEntity(aValue);
   } else if (aKey === 'entityType') {
     this.sharedData.addEntityType(aValue);
   } else if (aKey === 'account') {
     this.sharedData.addAccount(aValue);
   } else if (aKey === 'subtotalAccount'
     || aKey === 'accountSubtotal'
     || aKey === 'subAccount'
     || aKey === 'account_subtotal' ) {
     this.sharedData.addSubAccount(aValue);
   // } else if (aKey === 'user') {
   //   this.sharedData.addUsers(aValue);
   } else if (aKey === 'inventoryItem') {
     this.sharedData.addInventoryItems(aValue);
   } else if (aKey === 'paymentType') {
     this.sharedData.addPaymentType(aValue);
   } else if (aKey === 'uom') {
     this.sharedData.addUOM(aValue);
   } else if (aKey === 'term') {
     this.sharedData.addTerm(aValue);
   } else if (aKey === 'taxRate') {
     this.sharedData.addTaxRate(aValue);
   } else if (aKey === 'tax') {
     this.sharedData.addTax(aValue);
   } else if (aKey === 'currency') {
     this.sharedData.addCurrency(aValue);
   } else if (aKey === 'sendEmailInfoObj') {
     this.sharedData.addSendEmailInfoObj(aValue);
   }
  }

  // Note 20210209 rlp - see note above for why this looks odd - just a way to fix an old bad idea.
  // the get should just load the value from shared data service created by the above add(s)
  StorageGet(aKey: string, aDefaultValue: any = null): any {
    if ((aKey !== 'Permissions'
      && aKey !== 'Preferences'
      && aKey !== 'token'
      && aKey !== 'LoggedIn'
      && aKey !== 'location'
      && aKey !== 'company'
      && aKey !== 'uDb'
      && aKey !== 'entity'
      && aKey !== 'account'
      && aKey !== 'subAccount'
      && aKey !== 'subtotalAccount'
      && aKey !== 'accountSubtotal'
      && aKey !== 'inventoryItem'
      && aKey !== 'uId'
      && aKey !== 'uName'
      && aKey !== 'entityType '
      && aKey !== 'currency'
      && aKey !== 'uom'
      && aKey !== 'paymentType'
      && aKey !== 'tax'
      && aKey !== 'taxRate'
      && aKey !== 'term'
      && aKey !== 'rfype'
      && aKey !== 'rf1'
      && aKey !== 'rf2'
      && aKey !== 'rf3'
      && aKey !== 'rf4'
      && aKey !== 'rf5'
      && aKey !== 'salesperson'
      && aKey !== 'reportData'
      && aKey !== 'account_subtotal'
      && aKey !== 'sendEmailInfoObj'
      && aKey !== 'modeOperations') && localStorage.getItem(aKey)) {
      // *** NOTE - leave this return so old code works - will then hit api if it gets nothing back.
      // this will cause app to refresh values via api on when nothing returned since we only store specific items in ls now
      return localStorage.getItem(aKey);
    } else if (aKey === 'Permissions') {
      const decryptValue = this.encryptDecrypt.decrypt(localStorage.getItem(aKey));
      return decryptValue;
    } else if (aKey === 'Preferences') {
      const decryptValue = this.encryptDecrypt.decrypt(localStorage.getItem(aKey));
      return decryptValue;
    } else if (aKey === 'token') {
      // NOTE encrypted by server as of 20210507 so no need to decrypt here since it not encrpyted when stored
      // const decryptValue = this.encryptDecrypt.decrypt(localStorage.getItem(aKey));
      // return decryptValue;
      return localStorage.getItem(aKey);
    } else if (aKey === 'uId') {
      const decryptValue = this.encryptDecrypt.decrypt(localStorage.getItem(aKey));
      return decryptValue;
    } else if (aKey === 'uName') {
      const decryptValue = this.encryptDecrypt.decrypt(localStorage.getItem(aKey));
      return decryptValue;
    } else if (aKey === 'uDb') {
        const decryptValue = this.encryptDecrypt.decrypt(localStorage.getItem(aKey));
        return decryptValue;
    } else if (aKey === 'LoggedIn') {
      return this.sharedData.getLoggedIn();
    } else if (aKey === 'modeOperations') {
      return this.sharedData.getModeOperations();
    } else if (aKey === 'reportData') {
      return this.sharedData.getReportData();
    } else if (aKey === 'entityType') {
      return this.sharedData.getEntityType();
    } else if (aKey === 'paymentType') {
      return this.sharedData.getPaymentType();
    } else if (aKey === 'rfType') {
      return this.sharedData.getRfType();
    } else if (aKey === 'rf1') {
      return this.sharedData.getRf1();
    } else if (aKey === 'rf2') {
      return this.sharedData.getRf2();
    } else if (aKey === 'rf3') {
      return this.sharedData.getRf3();
    } else if (aKey === 'rf4') {
      return this.sharedData.getRf4();
    } else if (aKey === 'rf5') {
      return this.sharedData.getRf5();
    } else if (aKey === 'salesperson') {
      return this.sharedData.getSalesperson();
    } else if (aKey === 'tax') {
      return this.sharedData.getTax();
    } else if (aKey === 'taxRate') {
      return this.sharedData.getTaxRate();
    } else if (aKey === 'uom') {
      return this.sharedData.getUOM();
    } else if (aKey === 'term') {
      return this.sharedData.getTerm();
    } else if (aKey === 'currency') {
      return this.sharedData.getCurrency();
    } else if (aKey === 'UserDb') {
      return this.sharedData.getUserDb();
    } else if (aKey === 'location') {
      return this.sharedData.getLocation();
    } else if (aKey === 'company') {
      return this.sharedData.getCompany();
    } else if (aKey === 'entity') {
      return this.sharedData.getEntity();
    } else if (aKey === 'account') {
      return this.sharedData.getAccount();
    } else if (aKey === 'subtotalAccount'
      || aKey === 'accountSubtotal'
      || aKey === 'subAccount'
      || aKey === 'account_subtotal') {
      return this.sharedData.getSubAccount();
    // } else if (aKey === 'user') {
    //   return this.sharedData.getUsers();
    } else if (aKey === 'inventoryItem') {
      return this.sharedData.getInventoryItems();
    } else if (aKey === 'sendEmailInfoObj') {
      return this.sharedData.getSendEmailInfoObj();
    } else {
      return aDefaultValue;
    }
  }

  Clear() {
    localStorage.clear();
  }

  ClearKey(aKey: string){
    localStorage.removeItem(aKey);
  }
}

