import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-report-table-header-v2',
  templateUrl: './report-table-header-v2.component.html',
  styleUrls: ['./report-table-header-v2.component.css']
})
export class ReportTableHeaderV2Component implements OnInit {
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

}
