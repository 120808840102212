import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal-bom-select',
  templateUrl: './modal-bom-select.component.html',
  styleUrls: ['./modal-bom-select.component.css']
})
export class ModalBomSelectComponent implements OnInit {

  @Input() vm: any;
  @Output() selectBOMModalEmit = new EventEmitter<string>();
  @Output() updateBOMEmit = new EventEmitter<string>();
  @Output() filterBOMEmit = new EventEmitter<string>();
  @Output() bomSelectedEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  selectBOMModal(id) {
    this.selectBOMModalEmit.emit(id);
  }

  updateBOM() {
    this.updateBOMEmit.emit();
  }

  filterBOM(){
    this.filterBOMEmit.emit();
  }

  bomSelected(){
    this.bomSelectedEmit.emit();
  }
}
