import { AfterViewChecked, Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { StorageService } from '../../../../Services/storage.service';
import { AppConfigService } from '../../../../Services/app-config.service';
import { Title } from '@angular/platform-browser';
import { TableDataService } from '../../../../Services/tableData.service';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { NotificationService } from '../../../../Services/notification.service';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '../../../../Services/email.service';
import { DataExportService } from '../../../../Services/dataExport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseModel } from '../../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../../Services/email-pass-data-service.service';
import {DatePipe} from '@angular/common';
import {CustomArrayFilterPipe} from '../../../common/custom-array-filter.pipe';

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.css'],
})
export class BalanceSheetComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;
  private rptName: string;
  sub;
  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      fromDatePrior: null,
      location_group_id: false,
      columns: '',
      company_id: null,
      location_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: '',
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    location_group: [],
    rfType: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    Preferences: null,
    permissions: null,
    exportToExcel: true,
  };

  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public appConfigService: AppConfigService,
    public dateFilter: DateFilterService,
    public notification: NotificationService,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public excelExporter: DataExportService,
    public route: ActivatedRoute,
    public router: Router,
    private emailData: EmailPassDataServiceService,
    public datePipe: DatePipe
  ) {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.toDate !== undefined) {
        this.vm.request.toDate = params.toDate;
        this.vm.showSpecificDates = true;
      }
      if (params.fromDate !== undefined) {
        this.vm.request.fromDate = params.fromDate;
        this.vm.showSpecificDates = true;
      }
    });


    if (!this.vm.request.toDate && !this.vm.request.fromDate) {
      this.newDateFilter(new Date(), new Date());
    }

    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Balance Sheet Report @@balanceSheetReport:Balance Sheet Report`;

    if (this.vm.request.toDate) {
      this.BalanceSheetUpdate();
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  ngAfterViewChecked() {
    if (this.vm.report.length > 0) {
      const div = document.getElementById('excel-export');
      // this.localStorage.StorageSet('reportData', div.outerHTML);

      setTimeout(() => {
        this.emailData.clearAllEmailPassData();
        this.emailData.emailInfo.aRecordId = '';
        this.emailData.emailInfo.aMessage = '';
        this.emailData.emailInfo.aEntity = '';
        this.emailData.emailInfo.aBatchTypeId = '99';
        this.emailData.emailInfo.aReportString = div.outerHTML;
        this.emailData.emailInfo.aReportOrDocName = 'Balance Sheet';
        this.emailData.loadEmailDataToPassMailer();
      }, 3000);
    }
  }

  newDateFilter(toDate, fromDate) {
    // console.log('todate: ' + toDate);
    // console.log('fromdate: ' + fromDate);
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.fromDateSelect = ret.fromDateSelect;
    // console.log('fromdate:' + JSON.stringify(this.vm.fromDateSelect));
  }

  // FilterOptionsInit() {
  //   this.tableDataService.getTableData('company?state=active').subscribe((result: any[]) => {
  //     this.vm.company = result;
  //   });
  //   this.tableDataService.getTableData('currency?state=active').subscribe((result: any[]) => {
  //     this.vm.currency = result;
  //   });
  //   this.tableDataService.getTableData('location?state=active').subscribe((result: any[]) => {
  //     this.vm.location = result;
  //   });
  //   this.tableDataService.getTableData('location_group?state=active').subscribe((result: any[]) => {
  //     this.vm.locationGroup = result;
  //   });
  //   this.tableDataService.getTableData('reporting_field_type?state=active').subscribe((result: any[]) => {
  //     this.vm.rfType = result;
  //   });
  //   this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf1').subscribe((result: any[]) => {
  //     this.vm.rf1 = result;
  //   });
  //   this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf2').subscribe((result: any[]) => {
  //     this.vm.rf2 = result;
  //   });
  //   this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf3').subscribe((result: any[]) => {
  //     this.vm.rf3 = result;
  //   });
  //   this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf4').subscribe((result: any[]) => {
  //     this.vm.rf4 = result;
  //   });
  //   this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf5').subscribe((result: any[]) => {
  //     this.vm.rf5 = result;
  //   });
  //
  // }
  FilterOptionsInit() {
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.tableDataService
      .getTableData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });
    this.tableDataService
      .getTableData('location_group?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location_group = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field_type?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rfType = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf1&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf1 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf2&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf2 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf3&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf3 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf4&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf4 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf5&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf5 = result.Data;
      });
  }

  makeNumber(value, sub) {
    if (typeof value !== 'undefined') {
      if (typeof value[sub] !== 'undefined') {
        return value[sub];
      }
    }
    return 0;
  }

  parseInt(val) {
    return parseInt(val, 10);
  }

  calcTotals() {
    this.vm.totalDebits = this.vm.totalCredits = this.vm.liabEquityCurrent = this.vm.liabEquityPrior = 0;

    for (const [x, value] of Object.entries(this.vm.report)) {
      if (this.vm.report[x].title === 'Assets') {
        this.vm.totalCurrentAssets = Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.totalPriorAssets = Number(this.vm.report[x].totalPriorPeriod);
      } else {
        this.vm.liabEquityCurrent += Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.liabEquityPrior += Number(this.vm.report[x].totalPriorPeriod);
      }
    }
  }

  FilterObject(value, filterObj, except = false) {
    // return new FilterPipe().transform(value, filterObj);
    return new CustomArrayFilterPipe().transform(value, filterObj);
  }

  // exportReport() {
  //   this.notification.warning('Email address required.');
  // }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, this.rptName + '.xlsx');
  }

  BalanceSheetUpdate() {
    this.vm.report = [];
    if (!this.vm.request.toDate) {
      this.vm.toDateError = true;
      this.notification.warning($localize`:Date is required. @@dateisrequired:Date is required.`);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      // this.vm.buttonsDisabled = true;
      this.vm.toDateError = false;
      // this.localStorage.StorageSet('toDate', this.vm.request.toDate);

      const originalToDate = new Date(this.vm.request.toDate);
      const fromPrior = new Date(originalToDate.getFullYear() - 2, originalToDate.getMonth(), 1);
      this.vm.request.fromDatePrior = this.datePipe.transform(fromPrior, 'MM/dd/yyyy');
      const from = new Date(originalToDate.getFullYear() - 1, originalToDate.getMonth(), 1);
      this.vm.request.fromDate = this.datePipe.transform(from, 'MM/dd/yyyy');

      // console.log('prior col date: ' + this.vm.request.fromDatePrior);

      // console.log('fromdate: ' + this.vm.request.fromDate);



      let paramString = '';
      if (this.vm.request.fromDate) {
        paramString += '&fromDate=' + this.vm.request.fromDate;
      }
      if (this.vm.request.toDate) {
        paramString += '&toDate=' + this.vm.request.toDate;
      }
      if (this.vm.request.company_id) {
        paramString += '&company_id=' + this.vm.request.company_id;
      } else if (this.vm.Preferences.default_company_id) {
        this.vm.request.company_id = this.vm.Preferences.default_company_id;
      }
      if (this.vm.request.location_id) {
        paramString += '&location_id=' + this.vm.request.location_id;
      }
      if (this.vm.request.location_group_id) {
        paramString += '&location_group_id=' + this.vm.request.location_group_id;
      }
      if (this.vm.request.rf_id_1) {
        paramString += '&rf_id_1=' + this.vm.request.rf_id_1;
      }
      if (this.vm.request.rf_id_2) {
        paramString += '&rf_id_2=' + this.vm.request.rf_id_2;
      }
      if (this.vm.request.rf_id_3) {
        paramString += '&rf_id_3=' + this.vm.request.rf_id_3;
      }
      if (this.vm.request.rf_id_4) {
        paramString += '&rf_id_4=' + this.vm.request.rf_id_4;
      }
      if (this.vm.request.rf_id_5) {
        paramString += '&rf_id_5=' + this.vm.request.rf_id_5;
      }

      if (paramString[0] == '&') {
        paramString = paramString.substring(1);
      }

      // determine prior period flag to send api
      if (this.vm.request.columns == 'month') {
        paramString += '&priorPeriod=' + 'm';
      } else {
        paramString += '&priorPeriod=' + '';
      }
      // console.log('bs params: ' + paramString);
      this.tableDataService.getTableData('balance_sheet', '?' + paramString).subscribe((result: any[]) => {
        this.vm.report = result;
        // console.log('bs data: ' + JSON.stringify(result));
        this.calcTotals();
        this.vm.buttonsDisabled = false;
        if (this.vm.request.columns === 'company') {
          this.vm.filtered = true;
          this.tableDataService
            .getTableData('BalanceSheetCompanyConsolidation', '?toDate=' + this.vm.request.toDate)
            .subscribe((CData) => {
              this.vm.companyData = CData;
              // console.log('data: ' + JSON.stringify(CData));
              for (const [x, value] of Object.entries(this.vm.companyData)) {
                if (this.FilterObject(this.vm.company, { id: this.vm.companyData[x].company_id })[0] !== null) {
                  this.vm.companyData[x].name = this.FilterObject(this.vm.company, {
                    id: this.vm.companyData[x].company_id,
                  })[0].name;
                } else {
                  this.vm.companyData[x].name = 'unknown';
                }
                for (const [y, value2] of Object.entries(this.vm.companyData[x].subtotals)) {
                  this.vm.companyData[x].subtotals[y].amount = 0;
                  for (const [z, value3] of Object.entries(this.vm.companyData[x].accounts)) {
                    if (
                      this.vm.companyData[x].accounts[z].subtotal_id == this.vm.companyData[x].subtotals[y].subtotal_id
                    ) {
                      this.vm.companyData[x].subtotals[y].amount += this.vm.companyData[x].accounts[z].amount;
                    }
                  }
                }
                let tempNetIncome = 0;
                let tempMargin = 0;

                for (const [y, value2] of Object.entries(this.vm.companyData[x].parents)) {
                  this.vm.companyData[x].parents[y].amount = 0;
                  this.vm.companyData[x].revenueAmount = 0;

                  for (const [z, value3] of Object.entries(this.vm.companyData[x].subtotals)) {
                    if (this.vm.companyData[x].subtotals[z].parent_id === this.vm.companyData[x].parents[y].parent_id) {
                      this.vm.companyData[x].parents[y].amount += this.vm.companyData[x].subtotals[z].amount;
                    }
                    if (this.vm.companyData[x].subtotals[z].parent_id === 'Assets') {
                      tempNetIncome += this.vm.companyData[x].subtotals[z].amount;
                      tempMargin += this.vm.companyData[x].subtotals[z].amount;
                      this.vm.companyData[x].revenueAmount += this.vm.companyData[x].subtotals[z].amount;
                    }
                  }
                }
                this.vm.companyData[x].subtotals.push({
                  subtotal_id: 'Assets',
                  amount: tempNetIncome,
                });
              }
            });
        } else if (this.vm.request.columns === 'location') {
          this.vm.filtered = true;
          let paramStr = '?toDate=' + this.vm.request.toDate;
          if (this.vm.request.company_id != null) {
            paramStr += '&company_id=' + this.vm.request.company_id;
          }
          this.tableDataService
            .getTableData('BalanceSheetLocationConsolidation', paramStr)
            .subscribe((lData: any[]) => {
              this.vm.locationData = lData;
              // console.log('ldata: ' + JSON.stringify(lData));

              for (const [x, value] of Object.entries(this.vm.locationData)) {
                for (const [y, value2] of Object.entries(this.vm.locationData[x].subtotals)) {
                  if (
                    typeof this.FilterObject(this.vm.location, { id: this.vm.locationData[x].location_id })[0] !==
                      'undefined' &&
                    this.FilterObject(this.vm.location, { id: this.vm.locationData[x].location_id })[0] !== null &&
                    typeof this.FilterObject(this.vm.location, { id: this.vm.locationData[x].location_id })[0].name !==
                      'undefined'
                  ) {
                    this.vm.locationData[x].name = this.FilterObject(this.vm.location, {
                      id: this.vm.locationData[x].location_id,
                    })[0].name;
                  } else if (this.vm.locationData[x].location_id === 'NULL') {
                    this.vm.locationData[x].name = 'z-blank';
                  } else {
                    this.vm.locationData[x].name = 'unknown';
                  }
                  this.vm.locationData[x].subtotals[y].amount = 0;

                  for (const [z, value3] of Object.entries(this.vm.locationData[x].accounts)) {
                    if (
                      this.vm.locationData[x].accounts[z].subtotal_id ===
                      this.vm.locationData[x].subtotals[y].subtotal_id
                    ) {
                      this.vm.locationData[x].subtotals[y].amount += this.vm.locationData[x].accounts[z].amount;
                    }
                  }
                }
                let tempNetIncome = 0;
                let tempMargin = 0;
                for (const [y, value2] of Object.entries(this.vm.locationData[x].parents)) {
                  this.vm.locationData[x].parents[y].amount = 0;
                  this.vm.locationData[x].revenueAmount = 0;

                  for (const [z, value3] of Object.entries(this.vm.locationData[x].subtotals)) {
                    if (
                      this.vm.locationData[x].subtotals[z].parent_id === this.vm.locationData[x].parents[y].parent_id
                    ) {
                      this.vm.locationData[x].parents[y].amount += this.vm.locationData[x].subtotals[z].amount;
                    }
                    if (this.vm.locationData[x].subtotals[z].parent_id === 'Assets') {
                      tempNetIncome += this.vm.locationData[x].subtotals[z].amount;
                      tempMargin += this.vm.locationData[x].subtotals[z].amount;
                      this.vm.locationData[x].revenueAmount += this.vm.locationData[x].subtotals[z].amount;
                    }
                  }
                }
                this.vm.locationData[x].subtotals.push({
                  subtotal_id: 'Assets',
                  amount: tempNetIncome,
                });
              }
            });
        }
        const d = new Date();
        this.vm.calculatedTime = d;
        const tempToDate = new Date(this.vm.request.toDate);
        this.vm.priorToDate = tempToDate.setFullYear(tempToDate.getFullYear() - 1);
      });
      return false;
    }
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  filterColumnsChange(clear = false) {
    this.vm.report = [];
    this.vm.buttonsDisabled = false;
    if (clear) {
      this.vm.showEmail = false;
      this.vm.report = [];
      this.vm.request = {
        toDate: null,
        location_group_id: false,
        columns: '',
        company_id: null,
        location_id: null,
        rf_id_1: null,
        rf_id_2: null,
        rf_id_3: null,
        rf_id_4: null,
        rf_id_5: null,
      };
    }
  }

  Print() {
    return window.print();
  }

//   setFromDateForTransDetail(someDate): any {
//     // let transFromDate: string = '12/01/' + (new Date(someDate).getFullYear() - 1).toString();
//     // console.log('set ' + someDate);
//     const aDate = new Date(someDate);
//     let firstDay = new Date(aDate.getFullYear() - 1, aDate.getMonth(), 1).toISOString();
//     firstDay = this.datePipe.transform(firstDay, 'MM/dd/yyyy');
//    //  console.log('ret ' + firstDay);
//     return firstDay;
//     // const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//     // transFromDate = new Date(transFromDate).toISOString();
//     // return transFromDate;
//   }


 }
