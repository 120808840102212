import {Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-modal-select-item',
  templateUrl: './modal-select-item.component.html',
  styleUrls: ['./modal-select-item.component.css']
})
export class ModalSelectItemComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  @Output() selectItemModalEmit = new EventEmitter<string>();
  @Output() updateInventoryItemEmit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
    this.vm.inventoryItemFilter = { search_string: '' };
    this.vm.startInventoryItem = 0;
  }

  selectItemModal(item){
    this.selectItemModalEmit.emit(item);
  }

  updateInventoryItem(){
    this.updateInventoryItemEmit.emit();
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}
