export class PaymentModel {
  header: Header;
  batch: Batch;
  detail: Detail[];
  applied: Applied[];
}

export class Applied {
  applied_header_id: string;
  applied_amount: number;
  applied_date: Date;
}

export class Batch {
  description: string;
  type_id: string;
}

export class Detail {
  id: number;
  amount: number;
  debit_credit: number;
  account_id: string;
}

export class Header {
  currency_rate: string;
  header_date: string;
  entity_id: string;
  entityInput: string;
  company_id: string;
  description: string;
  bank_id: string;
  payment_type_id: string;
}
