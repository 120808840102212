import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TableDataService } from '../../../Services/tableData.service';
import { StorageService } from '../../../Services/storage.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FilterPipe } from 'ngx-filter-pipe';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { Title } from '@angular/platform-browser';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../DataModels/response-model';
import { BsToastService } from '../../../Services/bs-toast-service';
import { RoutingTrackerService} from '../../../Services/routing-tracker.service';

@Component({
  selector: 'app-entry-form',
  templateUrl: './entry-form.component.html',
  styleUrls: ['./entry-form.component.css'],
})
export class EntryFormComponent implements OnInit, AfterViewInit {
  vm: any = {
    recurring: false
  };
  public records: any[] = [];
  public buttonsDisabled = false;
  public lockedBatch = 'false';
  public thisBatchId = '';
  public deleteMode = false;
  public urlParams: any = [];
  showImportButtons = false;
  value = '';
  routedFrom = '';
  saveCloseUsed = false;

  @ViewChild('csvReader', { static: false }) csvReader: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private dateFilter: DateFilterService,
    private decimal: DecimalPipe,
    private titleService: Title,
    private confirmDialogService: ConfirmDialogService,
    private prevUrl: RoutingTrackerService
  ) {
    this.vm.isLoaded = false;
    this.vm.startAccount = this.vm.entryTotal = 0;
    this.vm.newAccount = {};
    this.vm.Empty = 0;
    this.vm.accountFilter = { search_string: '' };
    this.vm.batches = 0;
    this.vm.thisBatch = null;
    this.vm.rfTypeLoaded = false;

    this.vm.item = {};
    this.vm.item.header = {};
    this.vm.item.batch = '';
    this.vm.recurring = false;
    // this.vm.item.header.audit_sequence = '';

    //  this.vm.rfType = [];
    //  this.vm.rf1 = [];
    //  this.vm.rf2 = [];
    //  this.vm.rf3 = [];
    //  this.vm.rf4 = [];
    //  this.vm.rf5 = [];
    this.vm.csvResults = [];
    this.vm.account = [];
    //  this.vm.location = [];
    this.vm.accountSubtotal = [];

    this.vm.title = $localize`:Journal Entry @@journalEntry:Journal Entry`;

    this.urlParams = this.route.snapshot.queryParams;
    this.vm.entry_id = this.urlParams.entryId;
    this.vm.batchId = this.urlParams.batchId;
    this.vm.recurring = this.urlParams.recurring;
    this.thisBatchId = this.urlParams.thisBatchId;

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
        this.routedFrom = prevUrl2;
      } else {
        this.routedFrom = '';
      }
  }

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: false,
    saveClose: true,
    email: false,
    print: false,
    delivered: false,
    locked: false,
  };
  showBatches = false;
  submitted = false;
  pageLoadedFromPost = false;

  ngOnInit() {
    // this.vm.isLoaded = false;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';

    this.tableDataService
      .getApiListData('account', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((accountResult: ResponseModel) => {
        this.vm.account = accountResult.Data;
        this.createAccountName();
      });

    this.tableDataService
      .getApiListData('location', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((locationResult: ResponseModel) => {
        this.vm.location = locationResult.Data;
      });

    this.tableDataService
      .getApiListData(
        'account_subtotal_no_parents',
        '?pageNumber=0&pageSize=999999'
      )
      .subscribe((accountSubtotal: ResponseModel) => {
        this.vm.accountSubtotal = accountSubtotal.Data;
      });

    this.vm.company = [];
    this.tableDataService
      .getApiListData('company', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });

    this.tableDataService
      .getApiListData('currency', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });

    this.tableDataService
      .getApiListData('reporting_field_type', '?pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rfType = result.Data;
        this.vm.rfTypeLoaded = true;
      });

    this.tableDataService
      .getApiListData(
        'reporting_field',
        '?state=active&type_id=rf1&pageNumber=0&pageSize=999999'
      )
      .subscribe((reportingField: ResponseModel) => {
        this.vm.rf1 = reportingField.Data;
      });

    this.tableDataService
      .getApiListData(
        'reporting_field',
        '?state=active&type_id=rf2&pageNumber=0&pageSize=999999'
      )
      .subscribe((reportingField: ResponseModel) => {
        this.vm.rf2 = reportingField.Data;
      });

    this.tableDataService
      .getApiListData(
        'reporting_field',
        '?state=active&type_id=rf3&pageNumber=0&pageSize=999999'
      )
      .subscribe((reportingField: ResponseModel) => {
        this.vm.rf3 = reportingField.Data;
      });

    this.tableDataService
      .getApiListData(
        'reporting_field',
        '?state=active&type_id=rf4&pageNumber=0&pageSize=999999'
      )
      .subscribe((reportingField: ResponseModel) => {
        this.vm.rf4 = reportingField.Data;
      });

    this.tableDataService
      .getApiListData(
        'reporting_field',
        '?state=active&type_id=rf5&pageNumber=0&pageSize=999999'
      )
      .subscribe((reportingField: ResponseModel) => {
        this.vm.rf5 = reportingField.Data;
      });

    this.vm.permissions = JSON.parse(
      this.localStorage.StorageGet('Permissions', '')
    );
    this.vm.Preferences = JSON.parse(
      this.localStorage.StorageGet('Preferences', '')
    );

    if (
      !this.vm.permissions.EditReceivablePayments &&
      !this.vm.permissions.ViewReceivablePayments &&
      !this.vm.permissions.EditPayablePayments &&
      !this.vm.permissions.ViewPayablePayments &&
      !this.vm.permissions.Admin
    ) {
      this.notification.showWarningToast('You do not have permissions for this action');
      document.location.href = '/';
    }

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.vm.entry_id = params.get('entryId');
      this.vm.batchId = params.get('batchId');
      this.vm.recurring = params.get('recurring');

      if (this.vm.entry_id === 'new') {
        this.vm.batchTypeId = '1';
      } else if (this.vm.entry_id === 'newBudget') {
        this.vm.batchTypeId = '6';
      } else {
        this.vm.Empty = 1;
      }
      if (this.vm.Empty == 1) {
        this.vm.item = {
          header: {},
          batch: { type_id: '1', locked: 'false' },
        };
        // console.log('entryid: ' + this.vm.entry_id);
        this.tableDataService
          .getTableData('entry', this.vm.entry_id)
          .subscribe((result: any[]) => {
            this.vm.item = result;
            // console.log('entry: ');
            // console.log(result);
            this.vm.item.header.header_date = this.dateFilter.GetDateFormat(
              this.vm.item.header.header_date
            );
            // console.log('locked:' + this.lockedBatch);
            if (this.vm.item.batch.locked) {
              this.buttonsDisabled = true;
            } else {
              this.buttonsDisabled = false;
            }
            this.init();
            this.vm.isLoaded = true;
          });
      } else {
        this.init();
      }
    });
    // this.vm.isLoaded = true;
  }

  ngAfterViewInit() {
    if (this.vm.entry_id === 'new' || this.vm.entry_id === 'newBudget') {
      this.vm.title = $localize`:New @@new:New` + ' ' + this.vm.title;
    } else {
      this.vm.title = $localize`:Edit @@edit:Edit` + ' ' + this.vm.title;
    }

    this.titleService.setTitle(this.vm.title);

    // this.vm.isLoaded = true;
  }

  // async getThisBatch(batchid) {
  //     // console.log('id:' + batchid);
  //   await this.tableDataService.getTableData('EntryList', '?batch_id=' + batchid).subscribe((result: any[]) => {
  //     // console.log('res:' + JSON.stringify(result));
  //     this.lockedBatch = result.map(item => item.locked).toString();
  //     // console.log('locked:' + this.lockedBatch);
  //     if (this.lockedBatch == 'true') {
  //       this.buttonsDisabled = true;
  //     } else {
  //       this.buttonsDisabled = false;
  //     }
  //   });
  // }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  init() {
    if (this.vm.entry_id === 'new') {
      //  NEW ENTRY
      this.vm.item = {
        batchTypeId: this.vm.batchTypeId,
        batch: { type_id: this.vm.batchTypeId },
        header: {
          currency_rate: '1',
          header_date: new Date(),
          audit_sequence: 0,
          detail_total: 0,
        },
        detail: [],
      };

      // if (this.vm.entry_id === 'new') {
      //   this.vm.item = {
      //     header: {audit_sequence: '*** NEW ***'},
      //     // batch: {type_id: '1', locked: 'false'},
      //   };
      // }
      this.vm.entryTypeName = '';
      if (this.vm.recurring === 'true') {
        this.vm.item.header.recurring = this.vm.recurring;
      }
      if (this.vm.batchTypeId == '1') {
        const x = $localize`:Journal @@journal:Journal`;
        this.vm.entryTypeName = x;
      } else {
        const y = $localize`:Budget @@budget:Budget`;
        this.vm.entryTypeName = y;
      }
      if (this.vm.batchId) {
        this.vm.batchId = this.vm.item.batch.id = this.vm.batchId;
      } else {
        this.vm.item.batch = {
          type_id: this.vm.batchTypeId,
          description: 'Batch',
        };
      }
      if (this.vm.Preferences.default_company_id) {
        this.vm.item.header.company_id = this.vm.Preferences.default_company_id;
      }
    } else {
      //  EDIT ENTRY
      if (this.vm.item.batch.type_id == '1') {
        this.vm.entryTypeName = $localize`:Journal @@journal:Journal`;
      } else {
        this.vm.entryTypeName = $localize`:Budget @@budget:Budget`;
      }

      for (const detail of this.vm.item.detail) {
        if (detail.item_id) {
          if (
            this.FilterObject(
              this.vm.inventoryItem,
              { id: detail.item_id },
              true
            )
          ) {
            detail.itemInput = this.FilterObject(
              this.vm.inventoryItem,
              { id: detail.item_id },
              true
            )[0].code;
          }
        }
        if (this.vm.account) {
          if (
            this.FilterObject(this.vm.account, { id: detail.account_id }, true)
              .length > 0
          ) {
            detail.accountInput =
              this.FilterObject(
                this.vm.account,
                { id: detail.account_id },
                true
              )[0].number +
              ' ' +
              this.FilterObject(
                this.vm.account,
                { id: detail.account_id },
                true
              )[0].name;
          }
          detail.amount = Number(detail.amount);
          detail.quantity = Number(detail.quantity);
          detail.debit_credit = Number(detail.debit_credit);
          if (detail.debit_credit > 0) {
            detail.amountInput = Number(detail.debit_credit).toFixed(2);
            detail.dc = '1';
          } else {
            // console.log('in else');
            detail.amountInput = (0 - Number(detail.debit_credit)).toFixed(2);
            detail.dc = '-1';
          }
        }
        //  vm.item.detail[x].sort_order = 0;
      }
      this.calcTotal();
    }
    if (this.vm.item.detail) {
      for (const detail of this.vm.item.detail) {
        if (detail.debit_credit) {
          detail.debit_credit = Number(detail.debit_credit).toFixed(2);
        }
      }
    }

    if (this.vm.company && this.vm.company.length === 1) {
      this.vm.item.header.company_id = this.vm.company[0].id;
    }

    if (this.vm.currency && this.vm.currency.length === 1) {
      this.vm.item.header.currency_id = this.vm.currency[0].id;
    }
    if (this.vm.location && this.vm.location.length === 1) {
      this.vm.item.header.location_id = this.vm.location[0].id;
    }
    if (this.vm.account && this.vm.account.length) {
      this.createAccountName();
    }
    this.addRows(5); //  create initial detail row

    // this.vm.isLoaded = true;
  }

  //   ACCOUNTS
  createAccountName() {
    this.vm.accountName = [];
    let tempName = '';

    // if(this.vm.account.length){
    for (const account of this.vm.account) {
      tempName = account.number + ' ' + account.name;
      this.vm.accountName.push({
        name: tempName,
      });
    }
    // }
  }

  updateAccount() {
    this.vm.startAccount = 0;
    // this.localStorage.StorageGet('account', '');
    this.tableDataService
      .getApiListData('account', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((accountResult: any) => {
        this.vm.account = accountResult.Data;
        this.createAccountName();
      });
  }

  selectAccountModal(accountId) {
    //  let id = document.getElementById('accountSelectDetailId').value;
    const id = this.vm.tempAccountDetailId;
    for (const detail of this.vm.item.detail) {
      if (detail.id == id) {
        for (const account of this.vm.account) {
          if (account.id == accountId) {
            detail.account_id = account.id;
            detail.accountInput = account.number + ' ' + account.name;
            break;
          }
        }
        break;
      }
    }
  }

  accountSelected(id) {
    for (const detail of this.vm.item.detail) {
      if (detail.id == id) {
        //  this.vm.item.detail[x].account_id = '';
        if (detail.accountInput?.length !== 0) {
          let found = false;
          for (const account of this.vm.account) {
            if (account.number + ' ' + account.name == detail.accountInput) {
              detail.account_id = account.id;
              found = true;
              break;
            }
          }
          if (!found) {
            this.notification.showErrorToast(
              $localize`:Account not found @@accountnotfound:Account not found`
            );
            detail.accountInput = '';
            detail.account_id = undefined;
          }
        } else {
          detail.account_id = undefined;
        }
        break;
      }
    }
  }

  saveNewAccount() {
    const errorDescription = '';
    this.vm.error = 0;
    this.vm.accountButtonsDisabled = true;

    if (!this.vm.newAccount.name) {
      this.notification.showWarningToast(
        $localize`:Name is required. @@nameisrequired:Name is required.`
      );
      this.vm.newAccountNameError = true;
      this.vm.error = 1;
    } else if (
      this.FilterObject(
        this.vm.account,
        { name: this.vm.newAccount.name },
        true
      ).length > 0
    ) {
      if (
        this.FilterObject(
          this.vm.account,
          { name: this.vm.newAccount.name },
          true
        )[0].name == this.vm.newAccount.name
      ) {
        this.vm.newAccountNameError = true;
        this.notification.showErrorToast(
          $localize`:Name is a duplicate. @@nameisaduplicate:Name is a duplicate.`
        );
        this.vm.error = 1;
      }
    }
    if (!this.vm.newAccount.number) {
      this.notification.showErrorToast(
        $localize`:Number is required. @@numberisrequired:Number is required.`
      );
      this.vm.newAccountNumberError = true;
      this.vm.error = 1;
    } else if (
      this.FilterObject(
        this.vm.account,
        { name: this.vm.newAccount.number },
        true
      ).length > 0
    ) {
      if (
        this.FilterObject(
          this.vm.account,
          { name: this.vm.newAccount.number },
          true
        )[0].number == this.vm.newAccount.number
      ) {
        this.vm.newAccountNumberError = true;
        this.notification.showWarningToast(
          $localize`:Number is a duplicate. @@numberisaduplicate:Number is a duplicate.`
        );
        this.vm.error = 1;
      }
    }
    if (this.vm.error == 1) {
      return;
    }
    if (errorDescription === '') {
      this.tableDataService.post('account', this.vm.newAccount).subscribe(
        (item: any) => {
          // this.notification.success($localize`:Saved@@saved:Saved`);
          this.vm.accountButtonsDisabled = false;
          this.updateAccount();
          this.vm.newAccount = {};
          //  this.createAccountName();
        },
        (error: any) => {
          this.vm.accountButtonsDisabled = false;
          // this.notification.error(error.description);
        }
      );
    } else {
      //  report error
      this.vm.accountButtonsDisabled = false;
      // this.notification.error(errorDescription);
    }
  }

  //  UTILITIES

  addRows(qty) {
    if (qty == '') {
      qty = 1;
    }
    let maxSortOrder = 0;
    for (const detail of this.vm.item.detail) {
      if (maxSortOrder < detail.sort_order) {
        maxSortOrder = detail.sort_order;
      }
    }
    for (let x = 1; x <= qty; x++) {
      //  tempID =this.vm.item.detail.length + 1;
      const tempDate = new Date();
      const tempID =
        tempDate.getMinutes() *
          (tempDate.getSeconds() + tempDate.getMilliseconds()) +
        this.vm.item.detail.length;
      maxSortOrder++;
      this.vm.item.detail.push({
        id: tempID,
        quantity: 1,
        sort_order: maxSortOrder,
      });
    }
  }

  deleteLine(id) {
    for (let x = 0; x < this.vm.item.detail.length; x++) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail.splice(x, 1);
        this.calcTotal();
      }
    }
  }

  changeToDecimal(event: any) {
    // console.log('chg deci:' + event);
    event = parseFloat(event);
    return event.toFixed(2);
  }

  calcTotal() {
    let total = 0;
    for (const detail of this.vm.item.detail) {
      detail.debit_credit = 0;
      if (detail.amountInput && detail.dc) {
        detail.debit_credit = detail.amountInput * detail.dc;
        if (
          !Number(detail.debit_credit) &&
          detail.debit_credit != 0 &&
          detail.debit_credit != '-'
        ) {
          this.notification.showErrorToast(
            $localize`:Amount @@amount:Amount` +
              ' ' +
              detail.debit_credit +
              ' ' +
              $localize`:is not a number, please remove any text. @@isnotanumberpleaseremoveanytext:is not a number, please remove any text.`
          );
        } else if (detail.debit_credit != '-') {
          total += Number(detail.debit_credit);
        }
      }
    }
    this.vm.total = Math.round(total * 100) / 100;
  }

  print() {
    this.vm.action = 'print';
    this.vm.save();
  }

  async save() {
    this.vm.errorDescription = '';

    //  check errors
    this.vm.error = 0;
    if (!this.vm.item.header.header_date) {
      // this.notification.showErrorToast(
      //   $localize`:Date is required. @@dateisrequired:Date is required.`
      // );
      this.vm.error = 1;
      this.vm.dateMissing = true;
    } else {
      const yearEndDate = this.FilterObject(
        this.vm.company,
        { id: this.vm.item.header.company_id },
        true
      )[0].year_end_date;
      const cutOffDate = this.FilterObject(
        this.vm.company,
        { id: this.vm.item.header.company_id },
        true
      )[0].cut_off_date;
      const tempYearEndDate = new Date(yearEndDate);
      const tempCutOffDate = new Date(cutOffDate);
      const headerDate = new Date(this.vm.item.header.header_date);
      // console.log(this.deleteMode);
      if (headerDate < tempYearEndDate && !this.deleteMode) {
        this.notification.showWarningToast(
          $localize`:Date must be greater than the the year end @@datemustbegreaterthantheyearend:Date must be greater than the year end` +
            ' ' +
            yearEndDate
        );
        this.vm.error = 1;
      }
      if (headerDate < tempCutOffDate && !this.deleteMode) {
        this.notification.showWarningToast(
          $localize`:Date must be greater than the cut off date @@datemustbegreaterthanthecutoffdate:Date must be greater than the cut off date` +
            ' ' +
            cutOffDate
        );
        this.vm.error = 1;
      }
      this.vm.item.header.header_date = this.dateFilter.GetDateFormat(
        this.vm.item.header.header_date
      );
    }

    if (!this.vm.item.header.company_id) {
      // this.notification.showWarningToast(
      //   $localize`:Company is required. @@companyisrequired:Company is required.`
      // );
      this.vm.companyError = true;
      this.vm.error = 1;
    }

    if (!this.vm.item.header.location_id) {
      // this.notification.showWarningToast(
      //   $localize`:Location is required. @@locationisrequired:Location is required.`
      // );
      this.vm.locationError = true;
      this.vm.error = 1;
    }

    if (!this.vm.item.header.description) {
      // this.notification.showWarningToast(
      //   $localize`:Must enter Description. @@mustEnterDescription :Must enter Description.`
      // );
      this.vm.descriptionError = true;
      this.vm.error = 1;
    }

    //  if (!this.vm.item.header.currency_id) {
    //     this.vm.errorDescription += 'Currency is required.<br/>';
    //     this.vm.currencyError = true;
    //  }
    // console.log(JSON.stringify(this.vm.item.detail));
    // console.log('length: ' + this.vm.item.detail[1].length);

    if (this.vm.item.detail[1].amountInput === undefined && !this.deleteMode) {
      this.notification.showWarningToast(
        $localize`:Must at least one full entry. @@mustbeatleastonefull entry:Must at least one full entry.`
      );
      this.vm.error = 1;
    }


    for (const detail of this.vm.item.detail) {
      if (detail.debit_credit !== 0 && detail.debit_credit && !this.deleteMode) {
        // console.log(detail.debit_credit);
        if (!detail.account_id && detail.debit_credit ) {
          this.notification.showWarningToast(
            $localize`:Detail account is required. @@detailaccountisrequired:Detail account is required.`
          );
          this.vm.error = 1;
        }
      }
    }

    if (this.vm.total !== 0 && !this.deleteMode) {
      this.notification.showWarningToast(
        $localize`:Entry must be balanced. @@entrymustbebalanced:Entry must be balanced.`
      );
      this.vm.error = 1;
    }

    if (!this.vm.permissions.EditJournalEntries && !this.vm.permissions.Admin) {
      this.notification.showErrorToast(
        $localize`:You do not have access to save. @@youdonothaveaccesstosave:You do not have access to save.`
      );
      this.vm.error = 1;
    }

    if (this.vm.error == 1) {
      return;
    }

    // if we got this far entry is balanced so just add the positive side of balanced entry to get total
    // for entry value display.

    // clear so it recalcs number
    this.vm.item.header.detail_total = 0;

    for (const detail of this.vm.item.detail) {
      if (detail.amount > 0) {
        this.vm.item.header.detail_total += Number(detail.amount);
      }
      this.vm.item.header.detail_total = this.changeToDecimal(this.vm.item.header.detail_total);
    }

    if (this.vm.errorDescription === '') {
      // console.log('post data: ' + JSON.stringify(this.vm.item));
      if (this.vm.item.header.id === undefined) {
        //  new entry
        // this.vm.item.header.audit_sequence = 0;
        // console.log('post data2: ' + JSON.stringify(this.vm.item));
        await this.tableDataService.post('entry', this.vm.item).subscribe(
          (item: any) => {
            // this.notification.success(item.description);
            let apiReturn;
            if (this.saveCloseUsed) {
              if (this.vm.recurring === 'true') {
                this.router.navigateByUrl('/transactions/entry/recurring');
              } else {
                this.router.navigateByUrl('/transactions/entry/list');
              }
            }

            if (typeof item === 'object') {
              apiReturn = JSON.parse(JSON.stringify(item));
              if (this.vm.recurring === 'true') {
                this.router.navigateByUrl(
                  '/transactions/entry/form?entryId=' + apiReturn.id + '&recurring=' + this.vm.recurring
                );
              } else {
                this.router.navigateByUrl(
                  '/transactions/entry/form?entryId=' + apiReturn.id + '&recurring=' + this.vm.recurring
                );
              }
              this.pageLoadedFromPost = true;
            } else {
              if (this.vm.recurring === 'true') {
                this.router.navigateByUrl('/transactions/entry/recurring');
              } else {
                this.router.navigateByUrl('/transactions/entry/list');
              }
            }
          },
          (error: any) => {
            console.log(error);
            this.vm.buttonsDisabled = false;
            if (error.message) {
              // this.notification.error(error.message);
            }
          }
        );
      } else {
        // edit
        const changes: any = {};

        changes.detail = this.vm.item.detail;
        changes.salesperson = this.vm.item.salesperson;
        changes.header = this.vm.item.header;

        await this.tableDataService
          .patch('entry', this.vm.item.header.id, changes)
          .then(
            (item: any) => {
              this.vm.buttonsDisabled = false;
              // this.notification.success(item.description);
              if (this.vm.item.header.deleted) {
                this.onClose();
              } else {
                this.pageLoadedFromPost = true;

                if (this.saveCloseUsed) {
                  if (this.vm.recurring === 'true') {
                    this.router.navigateByUrl('/transactions/entry/recurring');
                  } else {
                    this.router.navigateByUrl('/transactions/entry/list');
                  }
                } else {
                  this.router.navigateByUrl(
                    '/transactions/entry/form?entryId=' +
                    this.vm.item.header.id +
                    '&thisBatchId=&recurring=' + this.vm.recurring);
                }
              }
            },
            (error: any) => {
              console.log(error);
              this.vm.buttonsDisabled = false;
              if (error.message) {
                this.notification.showErrorToast(error.message);
              }
            }
          );
      }
    } else {
      //  report error
      this.vm.buttonsDisabled = false;
      this.notification.showErrorToast(this.vm.errorDescription);
    }

    if (this.vm.item.header.id === 0) {
      this.onClose(); // close it so the entry is saved - should change this to just reset value from api return for id
    }
  }

  generateEntryCsv(clear: boolean) {
    let maxSortOrder = 0;

    this.vm.clearEntry = clear;

    if (this.vm.clearEntry) {
      this.vm.item.detail = [];
      this.vm.clearEntry = false;
    }

    for (const detail of this.vm.item.detail) {
      if (maxSortOrder < detail.sort_order) {
        maxSortOrder = detail.sort_order.sort_order;
      }
    }

    for (let x = 0; x < this.vm.csvResults.length; x++) {
      if (this.vm.csvResults[x].account) {
        let tempAccountId = '';
        let tempAccountInput = '';
        let tempLocationId = '';
        for (const account of this.vm.account) {
          const tempAccount = this.vm.csvResults[x].account;
          const indexOfSpace = tempAccount.indexOf(' ');
          const tempAccountNumber = tempAccount.substring(0, indexOfSpace);
          if (
            account.number + ' ' + account.name ==
            this.vm.csvResults[x].account
          ) {
            tempAccountInput = this.vm.csvResults[x].account;
            tempAccountId = account.id;
            break;
          } else if (account.number == tempAccountNumber) {
            tempAccountInput = account.number + ' ' + account.name;
            tempAccountId = account.id;
            break;
          }
        }
        if (this.vm.csvResults[x].location) {
          for (const location of this.vm.location) {
            if (location.name == this.vm.csvResults[x].location) {
              tempLocationId = location.id;
              break;
            }
          }
        }
        for (const account of this.vm.account) {
          if (account.number == this.vm.csvResults[x].account) {
            tempAccountInput =
              this.vm.csvResults[x].account + ' ' + account.name;
            tempAccountId = account.id;
            break;
          }
        }
        let tempAmount = 0;
        let tempDebitCredit = 0;
        let dc1 = '1';
        if (this.vm.csvResults[x].debit > 0) {
          tempAmount = Number(this.vm.csvResults[x].debit);
          tempDebitCredit = tempAmount;
        } else {
          tempAmount = Number(this.vm.csvResults[x].credit);
          dc1 = '-1';
          tempDebitCredit = 0 - tempAmount;
        }
        if (tempAmount != 0 && this.vm.csvResults[x].account != ' ') {
          this.vm.item.detail.push({
            id: x,
            accountInput: tempAccountInput,
            account_id: tempAccountId,
            amount: tempAmount,
            amountInput: tempAmount,
            debit_credit: tempDebitCredit,
            dc: dc1,
            quantity: 1,
            sort_order: x + maxSortOrder,
            location_id: tempLocationId,
            description: this.vm.csvResults[x].description,
          });
        }
      }
    }
    this.calcTotal();
    // show import buttons since we have valid data
    this.showImportButtons = true;
  }

  gotAnUploadFile(file: any) {
    // console.log('in got a file: ' + file);
    // console.log('in got a file: ');
  }

  syncItemAttachments(attachmentsArray: any) {
    // console.log('attachments array recvd in main');
    // console.log(attachmentsArray);

    // clear existing - will be put back because in array passed back in file upload array
    this.vm.item.attachments = [];

    // use the pieces needed for the api record
    attachmentsArray.forEach((x) => {
      this.vm.item.attachments.push({
        id: x.id,
        header_id: x.header_id,
        file_name: x.fileName,
        type: x.fileType,
        attachment: x.fileData,
        audit_sequence: 0
      });
    });
    // console.log('main vm array after sync to file upload data');
    // console.log(this.vm);
  }



  // getOpenBatches() {
  //   this.tableDataService
  //     .getTableData(
  //       't_batch',
  //       '?batchType=1&myBatches=true&lockedBatches=false'
  //     )
  //     .subscribe((batches: any[]) => {
  //       this.vm.batches = batches;
  //     });
  // }

  // changeBatch(id) {
  //   this.vm.buttonsDisabled = true;
  //   const changes = { batch_id: id };
  //
  //   this.tableDataService
  //     .patch('t_header', this.vm.item.header.id, changes)
  //     .then(
  //       (item: any) => {
  //         this.vm.item.batch.locked = false;
  //         this.vm.buttonsDisabled = false;
  //         // this.notification.success($localize`:Moved @@moved:Moved`);
  //         this.vm.batches = [];
  //       },
  //       (error: any) => {
  //         this.vm.buttonsDisabled = false;
  //         // this.notification.error(error);
  //       }
  //     );
  // }

  FilterObject(value, filterObj, except = false) {
    // console.log('filer: ' + value);
    return new FilterPipe().transform(value, filterObj);
  }

  uploadListener(event): void {
    const result = [];
    const files = event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {
      const input = event.target;
      const reader = new FileReader();
      let headers = [];
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const rows = (csvData as string).split(/\r\n|\n/);
        for (let i = 0; i < rows.length; i++) {
          if (i == 0) {
            headers = rows[i].split(',');
            for (let j = 0; j < headers.length; j++) {
              headers[j] = headers[j].toLowerCase();
              headers[j] = headers[j].replace('\r', '');
              headers[j] = headers[j].trimEnd();
            }
          } else {
            const obj = {};
            const cells = rows[i].split(',');
            for (let j = 0; j < cells.length; j++) {
              cells[j] = cells[j].replace('\r', '');
              //  if (headers[j] === 'debit' || headers[j] === 'credit') {
              //      cells[j] = Number(cells[j]);
              //  }
              obj[headers[j]] = cells[j];
            }
            result.push(obj);
          }
        }
        if (result) {
          this.vm.csvResults = result;
          // document.getElementById('upload-buttons').classList.remove('d-none');
          this.showImportButtons = true;
          this.notification.showSuccessToast('Entries Imported For Review');
        }
      };
      reader.onerror = () => {
        this.notification.showErrorToast(
          $localize`:error is occured while reading file! @@errorisoccuredwhilereadingfile:error is occured while reading file!`
        );
      };
    } else {
      this.notification.showErrorToast(
        $localize`:Please import valid .csv file. @@pleaseimportvalidcsvfile:Please import valid .csv file.`
      );
      this.csvReader.nativeElement.value = '';
      this.vm.csvResults = {};
    }
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  onClose() {
    // console.log('at on close: ' + this.routedFrom);
    if (this.routedFrom === '') {
      window.close();
    } else if (this.vm.recurring === 'true') {
      this.router.navigate(['/transactions/entry/recurring']).then();
    } else if (this.vm.recurring === 'false') {
      this.router.navigate(['/transactions/entry/list']).then();
    } else if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    }
  }

  onSaveClose() {
    this.saveCloseUsed = true;
    this.save().then();
    // this.onClose();

    // setTimeout(() => {
    //   this.save();
    // }, 9000);

    // if (this.vm.recurring === true) {
    //   this.router.navigate(['/transactions/entry/recurring']).then();
    // } else {
    //   this.router.navigate(['/transactions/entry/list']).then();
    // }
  }

  deleteItem() {
    this.deleteMode = true;
    if (this.vm.entry_id != 'new') {
      const brClass = this;
      // let retMsg = '';
      const messages: string[] = [
        $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord?:Are you sure you want to delete record?`,
        '',
      ];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.vm.item.header.deleted = true;
          // this.save().then(() => { this.onClose(); });
          this.save().then();
          this.deleteMode = false;
        },
        () => {
          brClass.notification.showErrorToast(
            $localize`:Delete Canceled @@deleteCanceled:Delete Canceled`
          );
        }
      );
    }
  }

  // toggleBatches() {
  //   this.showBatches = !this.showBatches;
  // }
}
