import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TableDataService} from '../../../../Services/tableData.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {UomModel} from '../../../../DataModels/uom-model';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-uom-form',
  templateUrl: './uom-form.component.html',
  styleUrls: ['./uom-form.component.css']
})
export class UomFormComponent implements OnInit {

  uomID;
  private uom: UomModel;
  uomForm: UntypedFormGroup;
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(private table: TableDataService,
              private notification: BsToastService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDialogService: ConfirmDialogService,
              private titleService: Title) {
    this.createForm();
    this.uomID = this.route.snapshot.params.id;
  }

  get f(){
    return this.uomForm.controls;
  }

  createForm() {
    this.uomForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      code: new UntypedFormControl(''),
      plural: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
      metric: new UntypedFormControl(true)
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:UOM@@uom:UOM`);
    if (this.uomID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('uom', this.uomID).subscribe(
      result => {
        this.uom = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Location Access @@errorloadingLocationAccess:Error loading Location Access`);
        console.log(error);
      },
      () => {
        this.uomForm.patchValue(this.uom);
      });
  }

  saveClose() {
    if ( this.onSave()) {
      this.onClose();
    }
  }

  saveNew() {
    if ( this.onSave()) {
      this.uomForm.reset();
    }
  }

  resetForm() {
    this.uomForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/uom-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete this UOM? @@areYouSureYouWantToDeleteThisUOM:Are you sure you want to delete this UOM?`, this.uom.name];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('uom', this.uomID).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          });
      }, () => {
        this.notification.showSuccessToast($localize`:Delete Canceled@@deleteCanceled:Delete Canceled`);
      });
  }

  onSave() {
    this.submitted = true;
    if (this.uomForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.uom = Object.assign({}, this.uomForm.value);
    if (this.uomID) {
      if (this.uomForm.invalid === false) {
        this.table.patch('uom', this.uomID, this.uom).then();
        // this.notification.success($localize`:Updated@@updated:Updated`);
        return true;
      }
    } else {
      this.table.postWithPromise('uom', this.uom).then(res => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.uomID = apiReturn.id;
      });
      // this.notification.success($localize`:Saved@@saved:Saved`);
      return true;
    }
    this.submitted = false;
    return true;
  }
}
