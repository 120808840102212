import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { Router } from '@angular/router';
import { StorageService } from '../../../../Services/storage.service';
import { AppConfigService } from '../../../../Services/app-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../../../Services/notification.service';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../../Services/ngbpagination-config.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-term-list',
  templateUrl: './term-list.component.html',
  styleUrls: ['./term-list.component.css'],
})
export class TermListComponent implements OnInit, OnDestroy {
  termCount = 0;
  isLoaded = false;
  constructor(
    private aTable: TableDataService,
    private aToastr: NotificationService,
    private aRoute: Router,
    private aStorage: StorageService,
    private appConfig: AppConfigService,
    private dateFilter: DateFilterService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    private titleService: Title
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  vm: any = [];

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngOnInit() {
    this.titleService.setTitle($localize`:Terms List@@termList:Terms List`);
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.getData();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([1, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.aRoute.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.aRoute.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  getData() {
    let paramString =
      '?search=' + (this.vm.searchTerm ? this.vm.searchTerm : '');
    paramString +=
      '&fromDate=' +
      (this.vm.fromDate ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
    paramString +=
      '&toDate=' +
      (this.vm.toDate ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.aTable.getApiListData('term', paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;

        this.termCount = result.totalRecords;
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => {
        this.aToastr.error(
          $localize`:Error loading Payment Type Data @@errorloadingPaymentTypeData:Error loading Payment Type Data`
        );
        console.log(error);
      },
      () => {}
    );
  }

  onNavigate(aID: string) {
    this.aRoute.navigate(['admin/term-form', { id: aID }]).then();
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    let paramString =
      '?search=' + (this.vm.searchTerm ? this.vm.searchTerm : '');
    paramString +=
      '&fromDate=' +
      (this.vm.fromDate ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
    paramString +=
      '&toDate=' +
      (this.vm.toDate ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.aTable.getApiListData('term', paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.termCount = result.totalRecords;
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => {
        this.aToastr.error(
          $localize`:Error loading Payment Type Data @@errorloadingPaymentTypeData:Error loading Payment Type Data`
        );
        console.log(error);
      },
      () => {}
    );
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
