import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TableDataService } from '../../../../Services/tableData.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityModel } from '../../../../DataModels/entity-model';
import { EntityAddressModel } from '../../../../DataModels/entity-address-model';
import { StorageService } from '../../../../Services/storage.service';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ResponseModel } from '../../../../DataModels/response-model';
import {BsToastService} from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-entity-detail',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.css'],
})
export class EntityDetailComponent implements OnInit {
  @Input() entityID: string;
  entity: EntityModel;
  entityForm: UntypedFormGroup;

  EntityTypeList: Array<object>;
  LocationList: Array<object>;
  AccountList: Array<object>;
  BankList: Array<object>;
  CurrencyList: Array<object>;
  CompanyList: Array<object>;
  TermList: Array<object>;
  PaymentTypeList: Array<object>;
  entityContactList: Array<object>;
  entities$: Observable<EntityModel[]>;
  entitiesList: EntityModel[];
  entityAddressList: Array<EntityAddressModel>;
  TaxList: Array<object>;

  TaxLength = 0;
  contactLength = 1;
  addressLength = 0;
  itemdescriptionlength = 0;
  permissions: any;

  EntityValid = false;
  showMergeEntity = false;
  errorMessage: string;
  submitted = false;
  apArChecked = false;

  paid = false;

  reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private datePipe: DatePipe
  ) {
    this.createForm();
  }

  get f() {
    return this.entityForm.controls;
  }

  // ArOrApNotChecked(): boolean {
  //   // console.log('pay:' + this.f.payable.value);
  //   if (this.f.payable.value !== true && this.f.receivable.value !== true) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  ArOrApNotChecked(event: any) {
    // console.log('cb: ' + event.target.checked);
    this.apArChecked = event.target.checked;
    // console.log('checked var: ' + this.apArChecked);
  }

  createForm() {
    this.entityForm = this.formBuilder.group({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      type_id: new UntypedFormControl(null),
      // receivable: [false, Validators.requiredTrue],
      // payable: [false, Validators.requiredTrue],
      receivable: new UntypedFormControl(null),
      payable: new UntypedFormControl(null),
      tax_number: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      phone: new UntypedFormControl(null),
      fax: new UntypedFormControl(null),
      website: new UntypedFormControl(null),
      account_id: new UntypedFormControl(null),
      bank_id: new UntypedFormControl(null),
      company_id: new UntypedFormControl(null),
      currency_id: new UntypedFormControl(null),
      location_id: new UntypedFormControl(null),
      payable_tax_1_id: new UntypedFormControl(null),
      payable_tax_1_rate: new UntypedFormControl(null),
      payable_tax_2_id: new UntypedFormControl(null),
      payable_tax_2_rate: new UntypedFormControl(null),
      payment_type_id: new UntypedFormControl(null),
      receivable_tax_1_id: new UntypedFormControl(null),
      receivable_tax_1_rate: new UntypedFormControl(null),
      receivable_tax_2_id: new UntypedFormControl(null),
      receivable_tax_2_rate: new UntypedFormControl(null),
      term_id: new UntypedFormControl(null),
      email: new UntypedFormControl(null),
      email_name: new UntypedFormControl(null),
      statement_last_sent_date: new UntypedFormControl(null),
      credit_limit: new UntypedFormControl(null),
      active: new UntypedFormControl(true),
      account: new UntypedFormControl(null),
      banks: new UntypedFormControl(null),
      currency: new UntypedFormControl(null),
      entity_address: new UntypedFormControl([]),
      entity_contact: new UntypedFormControl([]),
      entity_type: new UntypedFormControl(null),
      location: new UntypedFormControl(null),
      payment_type: new UntypedFormControl(null),
      entity_salesperson: new UntypedFormControl([]),
      tax: new UntypedFormControl(null),
      tax1: new UntypedFormControl(null),
      tax2: new UntypedFormControl(null),
      tax3: new UntypedFormControl(null),
      term: new UntypedFormControl(null),
      locations: new UntypedFormControl(null),
      mergeEntityInput: new UntypedFormControl(null),
    });
  }

  ngOnInit() {
    this.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    if (this.entityID) {
      this.LoadEntity();
    }
    this.LoadEntity_Types();
    this.loadLocation();
    this.loadAccounts();
    this.loadTax();
    this.loadPaymentType();
    this.loadEntityContacts();
    this.loadBank();
    this.loadCurrency();
    this.loadCompany();
    this.loadTerm();
  }

  LoadEntity() {
    this.table.getTableData('entity', this.entityID).subscribe(
      (result: any) => {
        this.entity = result;
        // console.log(JSON.stringify(this.entityID));
        // console.log(JSON.stringify(result));
        if (result.payable || result.receivable) {
          this.apArChecked = true;
        }
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Entity Access @@errorloadingEntityAccess:Error loading Entity Access`
        // );
        console.log(error);
      },
      () => {
        this.entityForm.patchValue(this.entity);
      }
    );
  }

  LoadEntity_Types() {
    this.table.getApiListData('Entity_type', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.EntityTypeList = result.Data;
        this.EntityValid = true;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Entity Types: ' + error);
      },
      () => {
        //
      }
    );
  }

  loadLocation() {
    this.table.getTableData('location?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.LocationList = result.Data;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Locations: ' + error);
      },
      () => {
        //
      }
    );
  }

  loadBank() {
    this.table.getTableData('bank?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.BankList = result.Data;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Load Bank error: ' + error);
        // this.notification.error($localize`:Load Bank error @@LoadBankerror:Load Bank error: ` + error);
      },
      () => {
        //
      }
    );
  }

  loadCurrency() {
    this.table.getTableData('currency?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.CurrencyList = result.Data;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Load Currency error: ' + error);
      },
      () => {
        //
      }
    );
  }

  loadCompany() {
    this.table.getTableData('company?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.CompanyList = result.Data;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Load Company error: ' + error);
       },
      () => {
        //
      }
    );
  }

  loadTerm() {
    this.table.getTableData('term?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.TermList = result.Data;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Load Term error: ' + error);
        // this.notification.error('Load Term error: ' + error);
      },
      () => {
        //
      }
    );
  }

  loadEntityContacts() {
    this.table.getApiListData('entity_contact', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.entityContactList = result.Data;
        this.contactLength = this.entityContactList.length;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Contacts: ' + error);
      },
      () => {
        //
      }
    );
  }

  private loadTax() {
    this.table.getTableData('tax?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.TaxList = result.Data;
        this.TaxLength = this.TaxList.length;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Tax: ' + error);
      },
      () => {
        //
      }
    );
  }

  private loadAccounts() {
    this.table.getTableData('account?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.AccountList = result.Data;
        // this.companyListLength = this.companyList.length;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Accounts: ' + error);
      },
      () => {
        //
      }
    );
  }

  private loadPaymentType() {
    this.table.getTableData('payment_type?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.PaymentTypeList = result.Data;
        // this.companyListLength = this.companyList.length;
      },
      (error: HttpErrorResponse) => {
        //
        console.log('Payment Type: ' + error);
      },
      () => {
        //
      }
    );
  }

  loadEntities() {
    this.table.getApiListData('entity', '?pageNumber=0&pageSize=999999').subscribe((res: ResponseModel) => {
      const data: any = res.Data;
      this.entities$ = data;
      this.entitiesList = [];
      data.forEach((x) => {
        if (x.id !== this.entityID) {
          this.entitiesList.push(x);
        }
      });
    });
  }

  private CheckForErrors(): boolean {
    this.submitted = true;
    this.errorMessage = '';
    if (
      !this.permissions.AllEntities &&
      !this.permissions.EditReceivableEntities &&
      !this.permissions.EditPayableEntities &&
      !this.permissions.Admin
    ) {
      this.errorMessage = $localize`:You do not have access to create or edit entities.  @@youdonothaveaccesstocreateoreditentities:You do not have access to create or edit entities. `;
      this.notification.showWarningToast(this.errorMessage);
      return false;
    } else if (this.entityForm.invalid || this.apArChecked === false) {
      this.errorMessage += $localize`:Form errors. @@formerrors:Form errors.`;
      this.notification.showWarningToast(this.errorMessage);
      return false;
    } else {
      return true;
    }
  }

  onSave(): boolean {
    // zap all the stuff on the form not a part of the model because object assign sucks and ignores model.
    this.submitted = true;
    delete this.entityForm.value.banks;
    delete this.entityForm.value.entity_address;
    delete this.entityForm.value.locations;
    delete this.entityForm.value.entity_salesperson;
    delete this.entityForm.value.entity_contact;
    // this.entity = Object.assign({}, this.entityForm.value);

    const result: EntityModel = Object.assign({}, this.entityForm.value);
    this.entity = result;

    if (this.CheckForErrors()) {
      if (this.entity.id != null) {
        this.table.patch('entity', this.entity.id, this.entity).then();
        // this.notification.success($localize`:Succeeded! @@succeeded:Succeeded!`);
      } else {
        this.table.postWithPromise('entity', this.entity).then();
        // this.notification.success($localize`:Succeeded! @@succeeded:Succeeded!`);
      }
      return true;
    }
  }

  merge() {
    const ParamsJson = {
      merge_to_entity_id: this.entity.id,
      delete_entity_id: this.entityForm.get('mergeEntityInput').value,
    };

    if (this.entity.id === this.entityForm.get('mergeEntityInput').value) {
      this.notification.showWarningToast(
        $localize`:Cannot merge to the same address @@cannotmergetothesameaddress:Cannot merge to the same address`
      );
    } else {
      const Param: string = this.encodeQueryData(ParamsJson);
      this.table.getTableData('MergeEntity', '?' + Param).subscribe(
        (result) => {},
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }
}
