import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TableDataService} from './tableData.service';
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})

export class IsRecordInUseService {

  recIsUsed = false;

  constructor(
    private table: TableDataService,
    private notification: NotificationService
  ) {
  }

  isThisUsed(idToCheck: string, columnToCheck: string, tableToCheck: string): boolean {

    let paramString = '';
    paramString += 'id=' + idToCheck;
    paramString += '&';
    paramString += 'columnName=' + columnToCheck;
    paramString += '&';
    paramString += 'tableName=' + tableToCheck;

    this.table.getTableDataWithParams('in_use', paramString).subscribe(
      result => {
        this.recIsUsed = result.toString() == 'true';
        // console.log('button:' + this.buttonsDisabled);
        // console.log('res:' + this.recIsUsed);
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error validating usage of item @@errorValidatingUsageOfItem:Error validating usage of item.`);
        console.log(error);
      },
      () => {
        // ending stuff here

      });
    return this.recIsUsed;
  }
}
