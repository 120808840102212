export class BankReconciliationModel {
    bank_name: string;
    account: string;
    // reconciliation_date: string;
    // balance: string;
    // account_id: string;
    account_id?: string;
    reconciliation_date?: string;
    balance?: string;
    company_id?: string;
    currency_id?: string;
    reconciled?: boolean;
    reconItems?: ReconItem[];
}

export class ReconItem {
   isReconciled?: boolean;
   datePickerClearedDate?: string | null;
   clearedId?: null | string;
   enteredDate?: string;
   detailId?: string;
   deposit?: number;
   withdrawal?: number;
 }
