import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../../Services/notification.service';
import {TableDataService} from '../../../Services/tableData.service';
import {StorageService} from '../../../Services/storage.service';
import {ArraySortPipe} from '../../Reports/transaction-reports/orderBy';
import {CustomFilterPipe} from '../../admin/custom-filter.pipe';
import {SharedDataService} from '../../../Services/sharedData.service';
import {ResponseModel} from '../../../DataModels/response-model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-home-manage',
  templateUrl: './home-manage.component.html',
  styleUrls: ['./home-manage.component.css']
})
export class HomeManageComponent implements OnInit {

  vm: any = {};
  showTipsHelp;

  constructor(
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: NotificationService,
    private cFilterPipe: CustomFilterPipe,
    private shareData: SharedDataService,
    private titleService: Title) {
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.reminder = {};
    this.vm.company = [];
    this.vm.location = [];
  }

  ngOnInit() {
    // if (this.localStorage.StorageGet('location')) {
    //   this.vm.location = JSON.parse(this.localStorage.StorageGet('location'));
    // }
    this.titleService.setTitle($localize`:Manage@@manage:Manage`);
    if (this.shareData.getLocation()) {
      this.vm.location = JSON.parse(this.shareData.getLocation());
    } else {
      this.tableDataService.getTableData('location?state=active&pageNumber=0&pageSize=99999').subscribe((locationResult: ResponseModel) => {
        this.vm.location = locationResult.Data;
        // this.localStorage.StorageSet('location', JSON.stringify(locationResult));
        this.shareData.addLocation(JSON.stringify(locationResult));
      });
    }

    this.onLoad();
    // this.queryReminder();
  }

  onLoad() {
    // queryFeed();
    if (this.vm.permissions.Admin) {
      // init();
    } else {
      if (this.vm.permissions.ReceivableAging) {
        // queryDelinquentReceivables();
      }
      // - this.queryReminder();
      // queryFeed();
    }
  }


  FilterObject(value, filterObj, actual = false) {
    return this.cFilterPipe.transform(value, filterObj, actual);
  }

  OrderBy(array, field, reverse = true) {
    return new ArraySortPipe().transform(array, field, reverse);
  }
}
