import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AccountModel } from '../../../DataModels/account-model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountSubtotalModel } from '../../../DataModels/account-subtotal-model';
import { AccountMarginModel } from '../../../DataModels/account-margin-model';
import { UomModel } from '../../../DataModels/uom-model';
import { TableDataService } from '../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseModel } from '../../../DataModels/response-model';
import { Observable } from 'rxjs';
import { AccountTypeModel } from '../../../DataModels/account-type-model';

import { ReportDefinitionModel } from '../../../DataModels/report-definition-model';
import { take } from 'rxjs/operators';
import {BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.css'],
})
export class AccountModalComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() editAccountId: Observable<string>;
  @Output() recordSavedEvent = new EventEmitter<string>();
  @ViewChild('closeButton') closeButton: ElementRef;

  account: AccountModel;
  accountForm: UntypedFormGroup;
  accountID;

  subTotal: AccountSubtotalModel[];
  margin: AccountMarginModel[];
  uom: UomModel[];
  accountType: AccountTypeModel[];
  reportSection: ReportDefinitionModel[];
  submitted = false;
  stopEdit = false;
  field: object;
  hierarchicalAccounts: any;
  items: any;
  searchText: string;
  modalTitle: string;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
  ) {
    this.createForm();
    this.loadLookups().then();
  }

  createForm() {
    this.accountForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      number: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      subtotal_id: new UntypedFormControl(''),
      cash_flow_section: new UntypedFormControl(''),
      margin_id: new UntypedFormControl(''),
      uom_id: new UntypedFormControl(''),
      debit: new UntypedFormControl(true),
      search: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
    });
  }

  ngOnInit() {
    this.loadLookups().then();
  }

  ngAfterViewInit() {

  }

  ngOnChanges() {
    // careful here - will fire once when modal loads so if that out
    // console.log('in onChange: ' + this.editAccountId);

    this.accountID = this.editAccountId;
    // this.subTotal = [];
    // this.margin = [];
    // this.uom = [];
    // this.reportSection = [];


    if (this.accountID === 'New') {
      this.accountID = '';
      this.modalTitle = 'New';
      this.accountForm.reset();
      this.account = new AccountModel();
      this.loadLookups().then();
    } else if (this.accountID !== undefined) {
      this.loadAccount().then();
    }
  }

  get f() {
    return this.accountForm.controls;
  }

  async loadAccount() {
    await this.table
      .getTableData('account', this.accountID)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          this.account = result;
          // console.log('account data: ' + JSON.stringify(result));
          this.modalTitle = this.account.name;
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {
          this.accountForm.patchValue(this.account);
        },
      });
  }

  async loadLookups(){
     await this.table
      .getApiListData('account_subtotal/children?pageNumber=0&pageSize=999999')
       .pipe(take(1)).subscribe(
       {
         next: (result: any) => {
           this.subTotal = result.Data;
           // console.log('margin: ' + JSON.stringify(result.Data));
         },
         error: (err: any) => {
           console.error(err.message);
         },
         complete: () => {
           // do stuff
         }
       });

     await this.table.getApiListData('account_margin?pageNumber=0&pageSize=999999').pipe(take(1)).subscribe(
       {
         next: (result: any) => {
           this.margin = result.Data;
           // console.log('margin: ' + JSON.stringify(result.Data));
         },
         error: (err: any) => {
           console.error(err.message);
         },
         complete: () => {
           // do stuff
         }
    });

     await this.table.getApiListData('uom?pageNumber=0&pageSize=999999')
       .pipe(take(1)).subscribe(
       {
         next: (result: any) => {
           this.uom = result.Data;
           // console.log('uom: ' + JSON.stringify(result.Data));
         },
         error: (err: any) => {
           console.error(err.message);
         },
         complete: () => {
           // do stuff
         }
       });

     await this.table.getApiListData('report_definition?pageNumber=0&pageSize=999999').pipe(take(1)).subscribe(
       {
         next: (result: any) => {
           this.reportSection = result.Data;
           // console.log('margin: ' + JSON.stringify(result.Data));
         },
         error: (err: any) => {
           console.error(err.message);
         },
         complete: () => {
           // do stuff
         }
       });
  }

  saveClose() {
    if (this.onSave()) {
      this.onClose();
    }
  }

  recSaved(value: string) {
    this.recordSavedEvent.emit(value);
  }

  saveModal() {
    if (this.onSave()) {
      // this.accountForm.reset();
    }
  }

  cancel() {
    // trigger on change
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete account? @@areYouSureYouWantToDeleteAccount:Are you sure you want to delete account?`,
      this.account.number + ' : ' + this.account.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('account', this.accountID).subscribe(
          (result) => {
            retMsg = result;
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          },
          () => {
            // stuff
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  resetForm() {
    this.accountForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/account-list']).then();
  }

  onSave() {
    this.submitted = true;
    if (this.accountForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }

    // check the cb vals and set to a false if null provided by form - due to setting init value to false has bug in ng
    if (this.accountForm.get('active').value == null) {
      this.accountForm.patchValue({active: false});
    }
    if (this.accountForm.get('debit').value == null) {
      this.accountForm.patchValue({debit: false});
    }
    // push form values to post obj
    this.account = Object.assign({}, this.accountForm.value);

    // console.log(JSON.stringify(this.account));
    if (this.accountID) {
      if (this.accountForm.invalid === false) {
        this.table.patch('account', this.accountID, this.account).then();
        this.recSaved('acct-saved');
        return true;
      }
    } else {
      this.table.postWithPromise('account', this.account).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
          this.accountID = apiReturn.id;
        } else {
          this.accountID = '';
        }
        this.recSaved('acct-saved');
      });
      return true;
    }
    this.submitted = false;
    // this.ngOnChanges();
    return true;
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}
