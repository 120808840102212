import {Component, Input, OnChanges, OnInit, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {NotificationService} from '../../../Services/notification.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BarChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input() primeBarChartDataFromDashboard: any;

  databar: any;
  chartOptions: any;
  chartImg: any;

  constructor( private messages: NotificationService) {
  }

  ngOnInit() {
    // Bar Chart
    this.databar = this.primeBarChartDataFromDashboard;

    // TODO - test data if needed;
    // this.databar = {
    //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //   datasets: [
    //     {
    //       label: 'My First dataset',
    //       backgroundColor: '#42A5F5',
    //       data: [65, 59, 80, 81, 56, 55, 40]
    //     },
    //     {
    //       label: 'My Second dataset',
    //       backgroundColor: '#FFA726',
    //       data: [28, 48, 40, 19, 86, 27, 90]
    //     },
    //     {
    //       label: 'My third dataset',
    //       backgroundColor: '#fffb26',
    //       data: [23, 41, 46, 9, 89, 17, 80]
    //     }
    //   ]
    // };
    // console.log('json for chart demo: ' + JSON.stringify(this.databar));
  }

  ngOnChanges() {
    this.databar = this.primeBarChartDataFromDashboard;
  }

  ngOnDestroy() {
    // console.log('hello from destroy');
  }
  exportChartImage(img) {
    this.chartImg = img.getBase64Image().replace('data:image/png;base64,' , '');
    // this.chartImg = img.getBase64Image();
    // console.log(this.chartImg);

    const blob = this.base64ToBlob(this.chartImg);
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = 'ChartImage.png';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

    this.messages.info('Chart image downloaded as PNG');
  }

  public base64ToBlob(b64Data, contentType= 'image/png', sliceSize= 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }
}
