export class SendEmailInfoModel {
  userName: string;
  aRecordId: string;
  aCompanyId: string;
  aLocationId: string;
  aEntity: string;
  aUserId: string;
  aValue1: string;
  aValue2: string;
  aMessage: string;
  aCustomerName: string;
  aVendorName: string;
  MiscString1: string;
  MiscString2: string;
  aReportString: string;
  aBatchTypeId: string;
  aSubject: string;
  aReportOrDocName: string;
  aToEmail: string;
  aFromEmail: string;
}
