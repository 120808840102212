import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ArraySortPipe } from '../orderBy';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { AppConfigService } from '../../../Services/app-config.service';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import { ResponseModel } from '../../../DataModels/response-model';
import { ActivatedRoute, Router } from '@angular/router';
import {ViewportScroller } from '@angular/common';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import {Subject, Subscription} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';
import { BsToastService } from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-entry-recurring',
  templateUrl: './entry-recurring.component.html',
  styleUrls: ['./entry-recurring.component.css'],
})
export class EntryRecurringComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private vps: ViewportScroller,
    private decimalPipe: DecimalNewPipe,
    private router: Router,
    private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    this.vm.list = [];
    this.vm.selectAllItems = false;
  }

  vm: any = {
    items: {
      list: [],
    },
  };
  advancedSearch;
  isLoaded = false;
  EntryRecurCount = 0;
  serverSearchTerm = '';
  gridPageNumber = 0;
  gridPageSize = this.appConfig.ListPageSize;
  EntryCount = 0;

  resultOld: any = {};
  selected = false;

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  checkAll = false;
  selectedRecords = [];
  subscriptionGroup: Subscription[] = [];

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();

    this.titleService.setTitle('Recurring Entries');

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    this.vm.numberOfResults = this.appConfig.maxRecords;

    // this.route.params.subscribe(params => {
    //   this.init();
    // });

    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptionGroup.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([2, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records

    // clear the selected aray since page changes and its no longer relational to array.
    this.selectedRecords = [];

    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  ngAfterViewInit() {
    // this.titleService.setTitle(this.vm.title);
    // this.myGrid.updatebounddata();
  }

  init() {
    let paramString = '';
    paramString += 'batchType=1';
    paramString += '&recurring=1';
    paramString += '&search=' + (this.vm.entrySearch ? this.vm.entrySearch : '');
    paramString += '&fromDate=' + (this.vm.entryFromDate ? this.vm.entryFromDate : '');
    paramString += '&toDate=' + (this.vm.entryToDate ? this.vm.entryToDate : '');
    paramString += '&locked=' + (this.vm.locked ? this.vm.locked : '');
    paramString += '&numberOfResults=' + this.vm.numberOfResults;
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.subscriptionGroup.push(this.tableDataService.getApiListData('EntryList', '?' + paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;

        this.convertToNumbers();
        this.vm.searching = false;
        this.isLoaded = true;
        this.EntryRecurCount = result.totalRecords;

        this.vm.owingSelected = this.vm.totalSelected = 0;

        this.vm.entryToDateOpen = this.vm.entryFromDateOpen = false;
      },
      (err) => {
        console.log('err: ' + err.value);
      },
      () => {
        // console.log('done with data');
      }
    ));
  }

  convertToNumbers() {
    for (const items of this.vm.items.list) {
      this.vm.items.header_amount = 0;
      this.vm.items.header_amount = items.detail_total;
    }
  }

  selectItem() {
    this.vm.owingSelected = this.vm.totalSelected = 0;
    let firstEntity = '';
    // document.getElementById('pay-btn').classList.remove('d-none');
    // document.getElementById('owingSelected').classList.remove('d-none');
    this.vm.itemsSelected = false;
    for (const obj of this.vm.list) {
      if ((document.getElementById(obj.id) as HTMLInputElement).checked) {
        if (firstEntity == '') {
          this.vm.itemsSelected = true;
          firstEntity = obj.entity_name;
        } else if (firstEntity != obj.entity_name) {
          // document.getElementById('pay-btn').classList.add('d-none');
          // document.getElementById('owingSelected').classList.add('d-none');
        }
        this.vm.totalSelected += Number(obj.header_total);
        this.vm.owingSelected += Number(obj.header_owing);
        document.getElementById(obj.id + '-tr').classList.add('success');
      } else {
        document.getElementById(obj.id + '-tr').classList.remove('success');
      }
    }
  }

  clearSearch() {
    this.vm.entrySearch = this.vm.entryFromDate = this.vm.entryToDate = this.vm.unappliedOnly = '';
    this.vm.numberOfResults = '25';
    this.vm.list = [];
    this.vm.searching = false;
    // this.localStorage.StorageSet('entrySearch', '');
    // this.localStorage.StorageSet('entryToDate', '');
    // this.localStorage.StorageSet('entryFromDate', '');
    // this.localStorage.StorageSet('numberOfResults', '25');
    this.getPagedApiData();
  }

  getPagedApiData() {
    this.vm.searching = true;

    this.checkAll = false;

    if (this.vm.unappliedOnly) {
      this.vm.numberOfResults = 'All';
    }

    let paramString = '';
    paramString += 'batchType=1';
    paramString += '&recurring=1';
    paramString += '&search=' + (this.vm.entrySearch ? this.vm.entrySearch : '');
    paramString += '&fromDate=' + (this.vm.entryFromDate ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
    paramString += '&toDate=' + (this.vm.entryToDate ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
    paramString += '&locked=' + this.vm.unappliedOnly;
    paramString += '&numberOfResults=' + this.vm.numberOfResults;
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.subscriptionGroup.push(this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;

      this.vm.list = this.OrderBy(this.vm.list, 'header_date', false);
    }));
  }

  filter(filter) {
    this.vm.entryFilter = Object.assign(this.vm.entryFilter, filter);
    this.localStorage.StorageSet('entryFilter', JSON.stringify(this.vm.entryFilter));
  }

  checkPost() {
    if (this.vm.recurring.length === 0) {
      this.notification.showWarningToast(
        $localize`:You must select at least one entry @@youmustselectatleastoneentry:You must select at least one entry`
      );
      return;
    }
    // console.log(JSON.stringify(this.vm.recurring));
    const messages: string[] = [
      $localize`:Are you sure you want to Generate Entries? @@areyousureyouwanttogenerat4eentries:Are you sure you want to Generate Entries?`,
      'Selected Count: ' + this.vm.recurring.length,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.postEntries();
        // this.notification.success($localize`:Entries Created@@entriesCreated:Entries Created`);
      },
      () => {
        this.notification.showSuccessToast($localize`:Generate Canceled @@GenerateCanceled:Generate Canceled`);
      }
    );
  }

  selectRow(rec: any, checked: boolean) {
    // console.log('rec val:' + rec + ' - ' + checked);
    if (this.checkAll && rec) {
      this.checkAll = false;
    }
    if (!checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec) {
          item.selected = false;
        }
      });
    } else if (checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec && !item.locked) {
          item.selected = true;
        }
      });
    }
    this.isAllChecked();

    // console.log('single select records' + JSON.stringify(this.vm.list));
  }

  selectAllRows(event: any) {
    if (!event) {
      this.vm.list.forEach((item) => (item.selected = false));
    } else if (event) {
      // this.vm.list.forEach((item) => (item.selected = true));
      this.vm.list.forEach((item) => {
        if (!item.locked) {
          item.selected = true;
        }
      });
    }
    // console.log('all select records: ' + JSON.stringify(this.vm.list));
  }

  isAllChecked(): boolean {
    const retVal = this.vm.list.every((x) => x.selected === true);
    this.checkAll = retVal;
    return retVal;
  }

  confirmDeleteSelected() {
    if (this.vm.list.filter(x => x.selected).length === 0) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }

    const messages: string[] = [
      $localize`:Are you sure you want to Delete? @@areyousureyouwanttodelete:Are you sure you want to Delete?`,
      'Selected Count: ' + this.vm.list.filter(x => x.selected).length,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.deleteSelected();
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }


  deleteSelected() {
    // just need to send the header id to the api
    const arrayOfItemsToDelete = [];
    this.vm.list.forEach((x) => {
      if (x.selected) {
        arrayOfItemsToDelete.push({header_id: x.id});
      }
    });

    if (!arrayOfItemsToDelete.length) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }
    // console.log('selected recs: ' + JSON.stringify(arrayOfItemsToDelete));

    // makes action button spinner turn while deleting
    this.isLoaded = false;
    // pass array as body in a delete to api entryController
    this.subscriptionGroup.push(this.tableDataService.deleteTableDataByArray('entry', arrayOfItemsToDelete).subscribe((item: any) => {
      if (item) {
        //  console.log(item);
        if (item.Message === 'Success') {
          this.isLoaded = true;
          this.getPagedApiData();
        }
      }
    }));
  }

  generate() {
    this.checkSelected();
    this.checkPost();
  }

  checkSelected() {
    const selectedIds = this.vm.list.filter((rec) => rec.selected).map((rec) => rec.id);
    // const selectedRows = this.myGrid.getselectedrowindexes();
    this.vm.recurring = [];
    for (const selectedId of selectedIds) {
      this.vm.recurring.push({
        header_id: selectedId,
      });
    }
    if (!this.vm.recurring) {
      this.notification.showWarningToast(
        $localize`:You must select at least one entry @@youmustselectatleastoneentry:You must select at least one entry`
      );
    }
  }

  postEntries() {
    if (this.vm.recurring.length > 0) {
      this.subscriptionGroup.push(this.tableDataService.post('recurring', this.vm.recurring).subscribe(
        (item: any) => {
          //  this.notification.success(item.description);
          this.vm.entityButtonsDisabled = false;
          this.checkAll = false;
          this.getPagedApiData();
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.buttonsDisabled = false;
          this.checkAll = false;
          this.vm.alert.status = error;
          this.vm.alert.message.push({ text: this.vm.recurring });
        }
      ));
    }
  }

  OrderBy(array, field, reverse = true) {
    return new ArraySortPipe().transform(array, field, reverse);
  }

  // Server side paging ---------------------------------------------------------------------------------

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.entrySearch = value.searchTerm;
    this.vm.entryFromDate = value.fromDate;
    this.vm.entryToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.entrySearch = value.searchTerm;
    this.vm.entryFromDate = value.fromDate;
    this.vm.entryToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  onNavigate(aID: string, print = false) {
    this.router.navigate(['/transactions/entry/form'], { queryParams: { entryId: aID, recurring: true } }).then();
  }
}
