import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../../../../Services/storage.service';
import { TableDataService } from '../../../../Services/tableData.service';
import { AppConfigService } from '../../../../Services/app-config.service';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { NotificationService } from '../../../../Services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '../../../../Services/email.service';
import { DataExportService } from '../../../../Services/dataExport.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../../Services/email-pass-data-service.service';
import { CashFlowModel } from '../../../../DataModels/cash-flow-model';
import { CashFlowDetailModel } from '../../../../DataModels/cash-flow-detail-model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.css'],
})
export class CashFlowComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  private rptName: string;
  lapCount = 0;
  cashFlowData: CashFlowModel[];
  tempCashFlowData: CashFlowModel[];
  finalCashFlowData: CashFlowModel[];
  finalCashFlowDetailData: CashFlowDetailModel[];
  // saveTheseIndexesForCFReport: Array<{index: number}>;

  assetLiabilitySections: any;
  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      location_group_id: false,
      columns: '',
      company_id: null,
      location_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      account_type_id: null,
      account_parent_id: null,
      account_subtotal_id: null,
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: '',
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    rfType: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    priorFromDate: 0,
    ToEmailError: null,
    showEmail: true,
    emailed: null,

    currentAR: null,
    priorAR: null,
    totalCurrentAR: null,
    totalPriorAR: null,
    deltaAR: null,

    currentAP: null,
    priorAP: null,
    totalCurrentAP: null,
    totalPriorAP: null,
    deltaAP: null,

    netCashFromOps: null,

    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    monthCount: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    pdfFileName: null,
    totalCurrentRevenue: null,
    totalPriorRevenue: null,
    marginCurrent: null,
    marginPrior: null,
    fromDateError: null,
    accountTypeName: null,
    accountParentName: null,
    accountSubtotalName: null,
    accountSubtotal: null,
    accountParents: null,
    accountType: null,
    companyName: null,
    locationName: null,
    locationGroupName: null,
    rf1Name: null,
    rf2Name: null,
    rf3Name: null,
    rf4Name: null,
    rf5Name: null,
    accountInput: null,
    entityInput: null,
    Preferences: null,
    permissions: null,
    inventoryItem: [],
    entityState: [],
    entityCity: [],
    entityType: [],
    user: [],
    paymentType: [],
    bank: [],
    entityFilter: [],
    entity: [],
    inventoryItemFilter: [],
    exportToExcel: true,

    costCurent: null,
    costPrior: null,
  };

  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public appConfigService: AppConfigService,
    public dateFilter: DateFilterService,
    public notification: NotificationService,
    public route: ActivatedRoute,
    public excelExporter: DataExportService,
    public aEmail: EmailService,
    private emailData: EmailPassDataServiceService,
    private router: Router,
    public decimalPipe: DecimalPipe
  ) {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.toDate !== undefined) {
        this.vm.request.toDate = params.toDate;
        this.vm.showSpecificDates = true;
      }
      if (params.fromDate !== undefined) {
        this.vm.request.fromDate = params.fromDate;
        this.vm.showSpecificDates = true;
      }
      if (this.vm.request.toDate && this.vm.request.fromDate) {
        this.update();
      }
      this.vm.request.columns = 'actual';
    });

    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    // this.EmailInit();
    this.rptName = $localize`:Cash Flow Statement @@cashFlowStatement:Cash Flow Statement`;
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  ngAfterViewChecked() {
    const div = document.getElementById('excel-export');
    // this.localStorage.StorageSet('reportData', div.outerHTML);

    if (this.lapCount === 1) {
      setTimeout(() => {
        this.emailData.clearAllEmailPassData();
        this.emailData.emailInfo.aRecordId = '';
        this.emailData.emailInfo.aMessage = '';
        this.emailData.emailInfo.aEntity = '';
        this.emailData.emailInfo.aBatchTypeId = '99';
        this.emailData.emailInfo.aReportString = div.outerHTML;
        this.emailData.emailInfo.aReportOrDocName = 'Cash Flow Statement';
        this.emailData.loadEmailDataToPassMailer();
      }, 3000);
      // console.log(div.outerHTML);
      this.lapCount++;
    }
  }

  setEmailData() {
    const div = document.getElementById('excel-export');
    // this.localStorage.StorageSet('reportData', div.outerHTML);
    // console.log(div.outerHTML);
    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Cash Flow Statement';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, this.rptName + '.xlsx');
  }

  emailReport(toEmail) {
    // if (this.vm.report.length > 0) {
    //   this.setEmailData();
    //   console.log('here');
    // }
    // const tempPdfHtml = this.ExcelExport.nativeElement.innerHTML;
    // this.vm.email.toemail = toEmail;
    // this.vm.email.PdfHtml = tempPdfHtml;
    // this.vm.email.Subject = this.rptName + ' ' + this.vm.request.toDate;
    // this.aEmail.sendEmail(this.vm.email);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.tableDataService
      .getTableData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
        // console.log('locs: ' +  this.vm.location);
      });
    this.tableDataService
      .getTableData('location_group?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.locationGroup = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field_type?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rfType = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf1&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf1 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf2&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf2 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf3&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf3 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf4&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf4 = result.Data;
      });
    this.tableDataService
      .getTableData('reporting_field', '?state=active&type_id=rf5&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.rf5 = result.Data;
      });
  }

  EmailInit() {
    // this.vm.email.toemail = this.localStorage.StorageGet('ToEmail');
  }

  filterEmptyCFData(data, filterValue: string) {
    const myStuff = data.filter((a) => a.parents.length > 0);
    console.log('values :' + JSON.stringify(myStuff));
    this.getCFData(myStuff);
  }

  getCFData(data: CashFlowDetailModel) {
    // const saveTheseIndexesForCFReport = [{index: number}];
    // let saveTheseIndexesForCFReport: Array<{index: number}> = [];
    let saveTheseIndexesForCFReport = [];
    console.log('cf detail model:' + JSON.stringify(data));
    for (const [x, value] of Object.entries(data)) {
      console.log('cf detail values:' + JSON.stringify(value));
      for (const [p, valuesParents] of Object.entries(data[x].parents)) {
        console.log('cf detail parent[] values:' + JSON.stringify(valuesParents));
        for (const [s, valuesSubs] of Object.entries(data[x].parents[p].subtotals)) {
          console.log('cf detail sub[] values:' + JSON.stringify(valuesSubs));
          const sub = data[x].parents[p].subtotals[s].title;
          // console.log('cf detail account[] values:' + JSON.stringify(valuesSubs));
          for (const [a, valuesAccts] of Object.entries(data[x].parents[p].subtotals[s].accounts)) {
            console.log('cf detail acct[] values:' + JSON.stringify(valuesAccts));
            for (const [aa, values] of Object.entries(data[x].parents[p].subtotals[s].accounts[a])) {
              // FINALLY, get values we want to use to calc CF stuff
              const subTotalCFValue = data[x].parents[p].subtotals[s].accounts[a].subTotCF;
              const priorValue = data[x].parents[p].subtotals[s].accounts[a].prior_period;
              const currentValue = data[x].parents[p].subtotals[s].accounts[a].current_period;
              const accountValue = data[x].parents[p].subtotals[s].accounts[a].account;

              if (subTotalCFValue !== null) {
                console.log(
                  'FINALLY *** cf account properties values:' +
                    sub +
                    ' -- ' +
                    subTotalCFValue +
                    ' -- Prior: ' +
                    priorValue +
                    ' -- Current: ' +
                    currentValue +
                    ' -- ' +
                    accountValue +
                    ' -- data index: ' +
                    x
                );
                saveTheseIndexesForCFReport.push(Number(x));
              }
            }
          }
        }
      }
    }
    // knock out the dups since we just let each occurence of them in above.
    const distinctThings = saveTheseIndexesForCFReport.filter(
      (thing, i, arr) => arr.findIndex((t) => t === thing) === i
    );

    // replace the array with the distinct array
    saveTheseIndexesForCFReport = distinctThings;

    console.log('***FINAL array elements to use for report: ' + JSON.stringify(saveTheseIndexesForCFReport));

    // now filter main array based on those items we want to keep and ditch all the others
    const res = this.vm.report.filter((el, i) => distinctThings.some((j) => i === j));
    // const res = this.vm.report.filter((obj, index) => distinctThings.includes(index));
    console.log('++++++++' + JSON.stringify(res));
  }

  getWithObjKey(data) {
    for (const { key, value } of data) {
      console.log(`Map key is:${key} and value is:${value}`);
    }
  }

  calcTotals() {
    // sets and gets the CF section prior and current data we need for report.
    this.filterEmptyCFData(this.vm.report, 'cash-flow-financing');

    this.vm.totalDebits = this.vm.totalCredits = this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
    for (const [x, value] of Object.entries(this.vm.report)) {
      // ar
      if (this.vm.report[x].type_id === 1) {
        this.vm.currentAR = Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.totalCurrentAR += this.vm.currentAR;
        this.vm.priorAR = Number(this.vm.report[x].totalPriorPeriod);
        this.vm.totalPriorAR += this.vm.priorAR;
      }
      // ap
      if (this.vm.report[x].type_id === 2) {
        this.vm.currentAP = Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.totalCurrentAP += this.vm.currentAP;
        this.vm.priorAP = Number(this.vm.report[x].totalPriorPeriod);
        this.vm.totalPriorAP += this.vm.priorAP;
      }

      // rev
      if (this.vm.report[x].type_id === 4) {
        this.vm.totalCurrentRevenue = Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.netIncomeCurrent += this.vm.totalCurrentRevenue;
        this.vm.totalPriorRevenue = Number(this.vm.report[x].totalPriorPeriod);
        this.vm.netIncomePrior += this.vm.totalPriorRevenue;
      } else {
        // costs
        if (this.vm.report[x].type_id === 6) {
          this.vm.marginCurrent = this.vm.totalCurrentRevenue - Number(this.vm.report[x].totalCurrentPeriod);
          this.vm.marginPrior = this.vm.totalPriorRevenue - Number(this.vm.report[x].totalPriorPeriod);
          this.vm.COGSCurrent = Number(this.vm.report[x].totalCurrentPeriod);
          this.vm.COGSPrior = Number(this.vm.report[x].totalPriorPeriod);
        } else {
          if (this.vm.report[x].type_id === 5) {
            console.log('cost log: ' + Number(this.vm.report[x].totalCurrentPeriod));

            this.vm.costCurrent = Number(this.vm.report[x].totalCurrentPeriod);
            this.vm.costCurrentTotal += this.vm.costCurrent;

            this.vm.costPrior = Number(this.vm.report[x].totalPriorPeriod);
            this.vm.costPriorTotal += this.vm.costPrior;
          }
        }
        // this.vm.netIncomeCurrent -= Number(this.vm.report[x].totalCurrentPeriod);
        // this.vm.netIncomePrior -= Number(this.vm.report[x].totalPriorPeriod);
      }
    }
    // this.vm.netIncomeCurrent -= this.vm.costCurrent;
    // this.vm.netIncomePrior -= this.vm.costPrior;
    // console.log('rev: ' + this.vm.netIncomeCurrent);
    // console.log('cost: ' + this.vm.costCurrent);
    // console.log('cogs: ' + this.vm.COGSCurrent);
    // console.log('marg: ' + this.vm.marginCurrent);
    // console.log('NI: ' +  (this.vm.netIncomeCurrent - this.vm.COGSCurrent - this.vm.costCurrent ));
    console.log('total current AP: ' + this.vm.totalCurrentAP + ' - ' + 'total prior AP: ' + this.vm.totalPriorAP);
    console.log('total current AR: ' + this.vm.totalCurrentAR + ' - ' + 'total prior AR: ' + this.vm.totalPriorAR);

    if (this.vm.totalCurrentAR > this.vm.totalPriorAR) {
      this.vm.deltaAR = (this.vm.totalCurrentAR - this.vm.totalPriorAR) * -1;
    } else {
      this.vm.deltaAR = this.vm.totalCurrentAR - this.vm.totalPriorAR;
    }

    if (this.vm.totalCurrentAP > this.vm.totalPriorAP) {
      this.vm.deltaAP = (this.vm.totalCurrentAP - this.vm.totalPriorAP) * -1;
    } else {
      this.vm.deltaAP = this.vm.totalCurrentAP - this.vm.totalPriorAP;
    }

    console.log('AR delta: ' + this.vm.deltaAR);
    console.log('AP delta: ' + this.vm.deltaAP);

    this.vm.netIncomeCurrent = this.vm.netIncomeCurrent - this.vm.COGSCurrent - this.vm.costCurrent;
    this.vm.netIncomePrior = this.vm.netIncomePrior - this.vm.COGSPrior - this.vm.costPrior;

    this.vm.netCashFromOps = this.decimalPipe.transform(
      this.vm.deltaAR + this.vm.deltaAP + this.vm.netIncomeCurrent,
      '1.2-2'
    );

    console.log('net ops cash: ' + this.vm.netCashFromOps);
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  clearTotalizers() {
    this.vm.costCurent = null;
    this.vm.costPrior = null;

    this.vm.currentAR = null;
    this.vm.priorAR = null;
    this.vm.totalCurrentAR = null;
    this.vm.totalPriorAR = null;
    this.vm.deltaAR = null;

    this.vm.currentAP = null;
    this.vm.priorAP = null;
    this.vm.totalCurrentAP = null;
    this.vm.totalPriorAP = null;
    this.vm.deltaAP = null;
  }

  update() {
    this.lapCount++;
    this.clearTotalizers();
    this.vm.report = [];
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.fromDate) {
      errorDescription += $localize`:A from date is required. @@afromdateisrequired:A from date is required.` + ' ';
      this.vm.fromDateError = true;
    }
    if (!this.vm.request.toDate) {
      errorDescription += $localize`:A To date is required. @@aTodateisrequired:A To date is required.` + ' ';
      this.vm.toDateError = true;
    }

    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);

    if (tempToDate < tempFromDate) {
      errorDescription += $localize`:From date must be less than the To date @@fromdatemustbelessthantheTodate:From date must be less than the To date`;
      this.vm.toDateError = true;
      this.vm.fromDateError = true;
    }
    if (errorDescription) {
      this.notification.warning(errorDescription);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;

      let paramString = '';
      if (this.vm.request.fromDate) {
        paramString += '&fromDate=' + this.vm.request.fromDate;
      }
      if (this.vm.request.toDate) {
        paramString += '&toDate=' + this.vm.request.toDate;
      }
      if (this.vm.request.company_id) {
        paramString += '&company_id=' + this.vm.request.company_id;
      }
      if (this.vm.request.location_id) {
        paramString += '&location_id=' + this.vm.request.location_id;
      }
      if (this.vm.request.location_group_id) {
        paramString += '&location_group_id=' + this.vm.request.location_group_id;
      }
      if (this.vm.request.rf_id_1) {
        paramString += '&rf_id_1=' + this.vm.request.rf_id_1;
      }
      if (this.vm.request.rf_id_2) {
        paramString += '&rf_id_2=' + this.vm.request.rf_id_2;
      }
      if (this.vm.request.rf_id_3) {
        paramString += '&rf_id_3=' + this.vm.request.rf_id_3;
      }
      if (this.vm.request.rf_id_4) {
        paramString += '&rf_id_4=' + this.vm.request.rf_id_4;
      }
      if (this.vm.request.rf_id_5) {
        paramString += '&rf_id_5=' + this.vm.request.rf_id_5;
      }

      if (paramString[0] === '&') {
        paramString = paramString.substring(1);
      }
      this.tableDataService.getTableData('cashflow', '?' + paramString).subscribe((result: any[]) => {
        this.vm.report = result;
        console.log(JSON.stringify(this.vm.report));
        this.vm.filtered = false;
        this.vm.showFilters = this.vm.buttonsDisabled = false;
        this.calcTotals();
        this.fillCFModel();
        this.lapCount = 0;
      });
      // return false;
    }
  }

  fillCFModel() {
    this.cashFlowData = [];
    this.tempCashFlowData = [];
    this.finalCashFlowData = [];

    this.vm.report.forEach((x) => {
      // console.log('parent length: ' + x.parents.length);
      if (x.parents.length > 0) {
        x.parents.forEach((parents) => {
          // console.log('parents :' + JSON.stringify(parents));
          parents.subtotals.forEach((subs) => {
            // console.log('subs :' + JSON.stringify(subs));
            subs.accounts.forEach((accts) => {
              // console.log('accts: ' + JSON.stringify(accts));
              if (accts.acctCF != null) {
                this.tempCashFlowData.push(accts);
              }
            });
          });
        });
      }
    });

    console.log('cf model data before reduce: ' + JSON.stringify(this.tempCashFlowData));

    // use reduce to sectionalize it out of the larger json file from api
    this.cashFlowData = this.groupByCFSection(this.tempCashFlowData);

    // now add the cf_section key, so we can group in html
    const resultArray = Object.keys(this.cashFlowData).map((index) => {
      const section = { cf_section: 'change_me', accounts: this.cashFlowData[index] };
      return section;
    });

    // finally, set the correct value of the cf_section key
    resultArray.forEach((obj) => {
      if (obj.cf_section === 'change_me') {
        obj.cf_section = obj.accounts[0].acctCF; // all obj elements props have same value so just reading first
      }
    });
    // console.log('resultarray : ' + JSON.stringify(resultArray));
    this.finalCashFlowData = resultArray;

    // now lets build JSON to populates the assets / liabs sections - just pulling it out of main data
    this.assetLiabilitySections = this.vm.report.filter((a) => a.type_id < 3);
    // console.log('assetLiabSection : ' + JSON.stringify(this.assetLiabilitySections));

    console.log('finalCF : ' + JSON.stringify(this.finalCashFlowData));
    console.log('done');
  }

  groupArrayOfObjects(list, key) {
    return list.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  groupByCFSection(array) {
    return array.reduce((r, a) => {
      r[a.acctCF] = r[a.acctCF] || [];
      r[a.acctCF].push(a);
      return r;
    }, Object.create(CashFlowModel));
  }

  parseInt(val) {
    return parseInt(val, 10);
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear(clear = false) {
    let inventoryItemInput: any;
    this.vm.report = [];
    this.vm.buttonsDisabled = false;
    if (clear) {
      this.vm.showEmail = false;
      this.vm.accountInput = this.vm.entityInput = inventoryItemInput = '';
      this.vm.report = [];
      this.vm.request = {
        toDate: null,
        fromDate: null,
        location_group_id: false,
        columns: '',
        company_id: null,
        location_id: null,
        rf_id_1: null,
        rf_id_2: null,
        rf_id_3: null,
        rf_id_4: null,
        rf_id_5: null,
        account_type_id: null,
        account_parent_id: null,
        account_subtotal_id: null,
      };
    }
  }

  Print() {
    return window.print();
  }
}
