import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { distinctUntilChanged, pairwise, Subscription } from 'rxjs';

@Directive({
  selector: '[onlyDecimal]'
})
export class OnlyDecimalDirective implements OnInit {
  @Input() decimals = 2;
  @Input() negative = 0;
  @Input() separator = ',';
  valueSubscription: Subscription;

  constructor(public ngControl: NgControl) {}

  ngOnInit(): void {
    this.ngControl.valueChanges.pipe(distinctUntilChanged(), pairwise()).subscribe(([oldValue, newValue]) => {
      this.runCheck(oldValue, newValue);
    });
  }

  private runCheck(oldValue, newValue) {
    const allowNegative = this.negative > 0 ? true : false;

    if (allowNegative) {
      if (!['', '-'].includes(newValue) && !this.checkAllowNegative(newValue)) {
        this.ngControl.control.setValue(oldValue);
      }
    } else {
      if (newValue !== '' && !this.check(newValue)) {
        this.ngControl.control.setValue(oldValue);
        // this.ngControl.control.patchValue(newValue.replace(/[^0-9].[^0-9]/g, ''));
      }
    }
  }

  private checkAllowNegative(value: string) {
    if (this.decimals <= 0) {
      return new RegExp(/^-?\d+$/).exec(String(value));
    } else {
      const regExpString =
        '^-?\\s*((\\d+(\\' + this.separator + '\\d{0,' + this.decimals + '})?)|((\\d*(\\' + this.separator + '\\d{1,' + this.decimals + '}))))\\s*$';
      console.log('allow neg');
      console.log(new RegExp(regExpString).exec(String(value)));
      return new RegExp(regExpString).exec(String(value));
    }
  }

  private check(value: string) {
    console.log('value to check: ' + value);
    if (this.decimals <= 0) {
      //.replace(/[^0-9].[^0-9]/g, '')
      //  this.ngControl.control.setValue(newValue.replace(/[^0-9].[^0-9]/g, ''));
      return new RegExp(/^[0-9]+\.[0-9]{2}$/).exec(String(value));
    } else {
      const regExpString =
        '^\\s*((\\d+(\\' + this.separator + '\\d{0,' + this.decimals + '})?)|((\\d*(\\' + this.separator + '\\d{1,' + this.decimals + '}))))\\s*$';
      console.log('check');
      console.log(new RegExp(regExpString).exec(String(value)));
      return new RegExp(regExpString).exec(String(value));
    }
  }
}
