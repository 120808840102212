import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmailService} from '../../../Services/email.service';
import {debounceTime, throttleTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Prevent2xClickDirective} from '../prevent-2xClick.directive';

@Component({
  selector: 'app-list-buttons',
  templateUrl: './list-buttons.component.html',
  styleUrls: ['./list-buttons.component.css']
})
export class ListButtonsComponent implements OnInit {

  @Input() vm: any;
  @Input() ButtonConfig: any;
  @Input() buttonsDisabled: boolean;
  @Input() deleteButtonDisabled: boolean;
  @Input() saveButtonDisabled: boolean;
  @Input() saveCloseButtonDisabled: boolean;
  @Input() saveNewButtonDisabled: boolean;
  @Input() printButtonDisabled: boolean;
  @Input() emailButtonDisabled: boolean;
  @Input() Disabled: any;
  @Input() emailObj: any;

  @Output() childSave = new EventEmitter();
  @Output() childSaveClose = new EventEmitter();
  @Output() childSaveNew = new EventEmitter();
  @Output() childDeleteItem = new EventEmitter();
  @Output() childResetForm = new EventEmitter();
  @Output() childClose = new EventEmitter<true>();
  @Output() childEmail = new EventEmitter();
  @Output() childPrint = new EventEmitter();
  @Output() childDelivered = new EventEmitter();
  @Output() childLocked = new EventEmitter();

  clickEvent = new Subject();

  Buttons: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    email: false,
    print: false,
    delivered: false,
    locked: false
  };

  constructor(private aEmail: EmailService) {
  }

  ngOnInit() {
    if (this.ButtonConfig) {
      this.Buttons = this.ButtonConfig;
    }
  }

  close() {
    this.childClose.emit(true);
  }

  resetForm() {
    this.childResetForm.emit(true);
  }

  deleteItem() {
    this.childDeleteItem.emit(true);
  }

  saveNew() {
    this.childSaveNew.emit(true);
  }

  saveClose() {
    this.childSaveClose.emit(true);
  }

  save() {
    this.childSave.emit(true);
  }

  print() {
    this.childPrint.emit(true);
  }

  email() {
    return this.childEmail.emit(true);
  }

  delivered(){
    this.childDelivered.emit(true);
  }

  locked(){
    this.childLocked.emit(true);
  }
  logIt() {
   // console.log('got a click!');
  }
}
