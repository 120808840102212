import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DateFilterService} from '../../../Services/dateFilter.service';
import {NotificationService} from '../../../Services/notification.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  serverSearchTerms = {
    fromDate: '',
    toDate: '',
    searchTerm: ''
  };
  fromDate: string;
  toDate: string;
  searchTerm: string;
  dateError: boolean;
  searchForm: UntypedFormGroup;

  constructor(
    private datePipe: DatePipe,
    private dateFilter: DateFilterService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.createSearchForm();
  }

  @Output() searchClick = new EventEmitter();
  @Output() clearSearchClick = new EventEmitter();
  @Input() stringSearchOnly: boolean;

  ngOnInit(): void {

  }
  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      searchTerm: [''],
      fromDate: [''],
      toDate: [''],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.searchForm.controls; }

  serverSearch() {
    // convenience - didnt feel like re-writing from old form style - just set old vars to reactive form values
    this.toDate = this.f.toDate.value;
    this.fromDate = this.f.fromDate.value;
    this.searchTerm = this.f.searchTerm.value;

    this.dateError = false;
    if (new Date(this.toDate) < new Date(this.fromDate)) {
      this.dateError = true;
      this.notificationService.error('To Date must be > From Date.');
      return;
    }
    // this.fromDate = (this.fromDate ? this.dateFilter.GetDateFormat(this.fromDate) : '');
    // this.toDate = (this.toDate ? this.dateFilter.GetDateFormat(this.toDate) : '');
    //
    // this.serverSearchTerms.fromDate = (this.fromDate ? new Date(this.fromDate).toISOString() : '');
    // this.serverSearchTerms.toDate = (this.toDate ? new Date(this.toDate).toISOString() : '');
    this.serverSearchTerms.fromDate = this.fromDate;
    this.serverSearchTerms.toDate = this.toDate;
    this.serverSearchTerms.searchTerm = this.searchTerm;

    this.searchClick.emit(this.serverSearchTerms);
  }

  clearSearch() {
    // alert('clear search clicked in component');
    // clear the form
    this.f.searchTerm.setValue('');
    this.f.fromDate.setValue('');
    this.f.toDate.setValue('') ;

    // clear the search terms emitted
    this.serverSearchTerms.fromDate = '';
    this.serverSearchTerms.toDate = '';
    this.serverSearchTerms.searchTerm = '';

    // alert('clear search clicked in component:' + JSON.stringify(this.serverSearchTerms));

    this.clearSearchClick.emit(this.serverSearchTerms);
  }

}
