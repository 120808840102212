import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-subtotal-parent-select',
  templateUrl: './subtotal-parent-select.component.html',
  styleUrls: ['./subtotal-parent-select.component.css']
})
export class SubtotalParentSelectComponent implements OnInit {
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

}
