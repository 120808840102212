import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {AppConfigService} from './app-config.service';
import {PagerStateModel} from '../DataModels/pager-state-model';

@Injectable({
  providedIn: 'root'
})
export class PagerStateService {
  constructor(private router: Router,
              private appConfig: AppConfigService) {}


  async getPagerState(pageUrl: string): Promise<any> {
    /// use raw page url as pass in
    if (localStorage.getItem('pager_state_' + pageUrl.split('?')[0])) {
      const now = +new Date();
      const pagerState: PagerStateModel = JSON.parse(localStorage.getItem('pager_state_' + this.router.url.split('?')[0]));
      const lastDateTime = pagerState.dateTime;
      if (now <= lastDateTime + this.appConfig.pagerStateSaveTime) {
        // console.log('if:' + JSON.stringify(pagerState));

        return pagerState;
      }
    } else {
      // expired so delete key - no need to keep
      localStorage.removeItem('pager_state_' + this.router.url.split('?')[0]);
      const pagerState = new PagerStateModel();
      pagerState.lastPage = 0;
      pagerState.lastPageSize = 0;
      pagerState.startIndex = 0;
      pagerState.endIndex = 0;
      pagerState.totalRecords = 0;
      pagerState.dateTime = 0;
      // console.log('else:' + JSON.stringify(pagerState));
      return pagerState;
    }
  }

  storePagerState(pageUrl: string, pagerState: PagerStateModel) {
    localStorage.setItem('pager_state_' + pageUrl.split('?')[0], JSON.stringify(pagerState));
  }
}

