import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {StorageService} from '../../../../Services/storage.service';
import {TableDataService} from '../../../../Services/tableData.service';
import {NotificationService} from '../../../../Services/notification.service';
import {Title} from '@angular/platform-browser';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../DataModels/response-model';

@Component({
  selector: 'app-tax-form',
  templateUrl: './tax-form.component.html',
  styleUrls: ['./tax-form.component.css']
})
export class TaxFormComponent implements OnInit {

  taxForm: UntypedFormGroup;

  vm: any = {};
  taxId;
  submitted = false;
  private originalItem = {};

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private localStorage: StorageService,
              private tableDataService: TableDataService,
              private notification: NotificationService,
              private titleService: Title,
              private formBuilder: UntypedFormBuilder,
              private confirmDialogService: ConfirmDialogService ) {

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.taxId = params.get('taxId');
    });
    // this.taxId = this.route.snapshot.params.id;

    this.vm.item = {};
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Tax @@tax:Tax`);

    this.taxForm = this.formBuilder.group({
      code: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      collected_account_id: new UntypedFormControl('', Validators.required),
      paid_account_id: new UntypedFormControl('true'),
      active: new UntypedFormControl('true')
    });

    if (this.taxId === 'new') {
      this.vm.item = {};
      this.vm.item.id = 'new';
      this.vm.auditTrail = {};
    } else {
      this.buttonConfig.reset = false;
      this.tableDataService.getTableData('tax', this.taxId).toPromise().then((result: any) => {
        this.vm.item = result;
        this.vm.auditTrail = Object.assign({}, this.vm.item);
        this.originalItem = Object.assign({}, this.vm.item);
        this.taxForm.patchValue(this.vm.item);
      });
    }

    this.tableDataService.getApiListData('account', '?state=active&pageNumber=0&pageSize=999999').toPromise().then((result: ResponseModel) => {
      this.vm.account = result.Data;
    });
  }

  loadAfterSave() {
    this.tableDataService.getTableData('tax', this.taxId).toPromise().then((result: any) => {
      this.vm.item = result;
      this.vm.auditTrail = Object.assign({}, this.vm.item);
      this.originalItem = Object.assign({}, this.vm.item);
      this.taxForm.patchValue(this.vm.item);
    });
  }


  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.resetForm();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete tax? @@areYouSureYouWantToDeleteTax:Are you sure you want to delete tax?`, this.vm.item.name];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.tableDataService.deleteTableDataById('tax', this.taxId).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted@@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            //  this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.success($localize`:Delete Canceled@@deleteCanceled:Delete Canceled`);
      }
    );
  }

  resetForm() {
    this.taxForm.reset();
  }

  onClose() {
    // this.router.navigateByUrl('admin/tax-list');
    this.router.navigate(['admin/tax-list']);
  }

  onSave(saveClose: boolean) {
    let retval = false;
    if (!this.taxForm.valid) {
      this.notification.error($localize`:Please fill required fields! @@pleasefillrequiredfields:Please fill required fields!`);
      return;
    }

    this.vm.item = Object.assign(this.vm.item, this.taxForm.value);
    this.vm.buttonsDisabled = true;
    const errorMessage = '';

    if (errorMessage === '') {

      if (this.vm.item.id === 'new') {
        // console.log('id: ' + this.vm.item.id );
        this.tableDataService.post('tax', this.vm.item).subscribe((item: any) => {
          // this.localStorage.StorageGet('tax', '');
          // this.notification.success($localize`:Saved@@saved:Saved`);
          // res => {
            // stops double posting when clicking Save
          let apiReturn: any = null;
          if (typeof item === 'object') {
              apiReturn = JSON.parse(JSON.stringify(item));
            }
          this.vm.item.id = apiReturn.id;
          retval = true;
        }, (error: { error: { Message: string }, statusText: string }) => {
          this.vm.buttonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          this.notification.error(ErrorMsg);
          retval = false;
          if (saveClose){
            this.onClose();
          }
        });
      } else {
        const changes = {};
        for (const key in this.vm.item) {
          if (this.vm.item.hasOwnProperty(key)) {
            const value = this.vm.item[key];
            if (value != this.originalItem[key] && typeof(value) != 'object') {
              changes[key] = value;
            }
          }
        }

        this.tableDataService.patch('tax', this.vm.item.id, changes)
          .then((item: any) => {
            // if (item) { this.notification.success(item.description); }
            // this.localStorage.StorageSet('tax', '');
            // this.notification.success($localize`:Updated@@updated:Updated`);
            retval = true;
            if (saveClose){
              this.onClose();
            }
          })
          .catch((msg: string) => {
            this.vm.buttonsDisabled = false;
            // this.notification.error(msg);
            retval = false;
          });
      }
    } else {
      this.vm.buttonsDisabled = false;
      // this.notification.error(errorMessage);
      console.log(errorMessage);
    }
    this.submitted = false;

    // if (this.vm.item.id === 'new') {
    //   this.resetForm();
    // }
    return retval;
  }
}
