import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';
@Component({
  selector: 'app-batch-select',
  templateUrl: './batch-select.component.html',
  styleUrls: ['./batch-select.component.css']
})
export class BatchSelectComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() vm: any;

  @Output() testBatchEmit = new EventEmitter<string>();
  @Output() updateBatchEmit = new EventEmitter<string>();
  @Output() selectBatchEmit = new EventEmitter<string>();
  public detail = {id: null};

  constructor(public isFiltered: IsFilterOnService) {
  }

  ngOnInit() {
  }

  testBatch() {
    this.testBatchEmit.emit();
  }

  updateBatch() {
    this.updateBatchEmit.emit();
  }

  selectBatch(batchId) {
    // $('#batchSelect').modal('hide');
    this.selectBatchEmit.emit(batchId);
  }

  checkFiltered(): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    this.vm.filtered = filteredValue;
    return filteredValue;
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
