import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-modal-item-select',
  templateUrl: './modal-item-select.component.html',
  styleUrls: ['./modal-item-select.component.css']
})
export class ModalItemSelectComponent implements OnInit {

  @Input() vm: any;
  @Output() updateItemEmit = new EventEmitter<string>();
  @Output() selectItemModalEmit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  updateItem() {
    this.updateItemEmit.emit();
  }
  selectItemModal(itemId) {
    this.selectItemModalEmit.emit(itemId);
  }


}
