import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BsToastService } from './bs-toast-service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authUser: AuthService,
    private notification: BsToastService,
    private router: Router
  ) {}

  errStatus: any;
  errStatusText: any;
  errText: any;
  errDesc: any;
  errMsgLog: string;
  errMsgScreen: string;
  errUrl: string;
  errMessage: string;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;
    // console.log('hdr from interceptor:' + JSON.stringify(headers));
    // console.log('method from interceptor:' + JSON.stringify(method));
    // console.log('request.body from interceptor:' + JSON.stringify(request.body));

    let handled = false;
    return next.handle(request).pipe(
      tap((evt) => {
        // console.log('I am an evt item: ' + JSON.stringify(evt));
        // console.log('request: ' + JSON.stringify(request));
        if (evt instanceof HttpResponse) {
           // console.log('i am an httpResponse: ' + JSON.stringify(evt));
           if (evt.url.includes('activityfeed') || (evt.url.includes('email') && evt.body.Message === 'Preflight')) {
           // if (evt.url.includes('activityfeed')) {
            handled = true;
            return;
          }
           if (
            (evt.status === 200 || evt.status === 201) &&
            (evt.statusText === 'OK' || evt.statusText === 'Created') &&
            (request.method.toUpperCase() === 'POST' ||
              request.method.toUpperCase() === 'PATCH' ||
              request.method.toUpperCase() === 'PUT' ||
              request.method.toUpperCase() === 'DELETE' ||
              request.method.toUpperCase() === 'UPDATE') &&
              !request.url.toUpperCase().includes('IDENTITY')
          ) {
            if (request.method.toUpperCase() === 'PATCH') {
              this.notification.showSuccessToast(
                evt.status + '-' + evt.statusText + ' ' + 'Updated!'
              );
            } else if (request.method.toUpperCase() === 'PUT' && evt.url.includes('email')) {
              this.notification.showSuccessToast(
                evt.status + '-' + evt.statusText + ' ' + 'Email Sent!'
              );
            } else if (request.method.toUpperCase() === 'PUT') {
                if (!headers.get('Silent')) {
                  this.notification.showSuccessToast(
                      evt.status + '-' + evt.statusText + ' ' + 'Updated!'
                    );
                } else {
                  // console.log('silent http return: ' + evt.status + '-' + evt.statusText + ' ' + 'Updated!');
                }
            } else if (request.method.toUpperCase() === 'DELETE') {
              this.notification.showSuccessToast(
                evt.status + '-' + evt.statusText + ' ' + 'Deleted!'
              );
            } else if (request.method.toUpperCase() === 'UPDATE') {
              this.notification.showSuccessToast(
                evt.status + '-' + evt.statusText + ' ' + 'Updated!'
              );
            } else {
              this.notification.showSuccessToast(
                evt.status + '-' + evt.statusText + ' ' + 'Saved!'
              );
            }
          }

           handled = true;
           return;
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // console.log('i am an httpErrorResponse: ' + JSON.stringify(err));
          this.errUrl = err?.url;
          this.errStatus = err?.status;
          this.errStatusText = err?.statusText;
          this.errText = err?.message;
          this.errMessage = err?.message;

          const errorResponse = err?.error?.toString().split(',').join(' <br>');

          try {
            this.errMsgScreen = this.errStatus + ' - ' + this.errStatusText + '<br><br>' + errorResponse;
            this.errMsgLog = this.errStatus + ' - ' + this.errStatusText + ' - ' + this.errMessage + ' - ' + JSON.stringify(err?.error);

            // NOTE - not using storage service (uses shared variables not ls) here so this actually
            // gets written to browser storage for support review via localstorage.XXX...

            const lastErr = this.getStoredValues('lastErrorTrapped');

            // show the error only once for each page - this checks if it fired same within time frame.
            // happens when you have a bunch of gets on same page and it crashes mid stream
            const currErrorDateTime = new Date();
            const lastErrorDateTime = new Date(this.getStoredValues('lastErrorDateTime'));
            const errorDiffTime = currErrorDateTime.getTime() - lastErrorDateTime.getTime();
            const errorLastPage = this.getStoredValues('lastErrorUrl');

            // for testing
            // console.log('diff: ' + errorDiffTime);
            // console.log('last: ' + lastErrorDateTime);
            // console.log('curr: ' + currErrorDateTime);

            // console.log('this err: ' + this.errStatus);
            // console.log('last err: ' + lastErr);
            // 409 - Conflict
            // Http failure response for https://localhost:5001/api/bank/cb38ef45-4a41-49ba-b0c4-30f7da1652a4: 409 Conflict
            // if (this.errStatus === lastErr && errorDiffTime <= 3) {

            // 401 will recur for each api call on page - just show them one.
            if (this.errStatus === 401 && lastErr === '401' && errorDiffTime <= 1) {
              // console.log('in if for ditch errors already surfaced: ' + this.errStatus);
              handled = true;
              return;
            }

            if ([400].includes(this.errStatus)) {
              this.notification.showErrorToast(this.errMsgScreen);
              this.logIt();
              handled = true;
            } else if ([401].includes(this.errStatus)) {
              if (url.includes('/login')) {
                this.notification.showWarningToast(
                  'Incorrect Username or Password.'
                );
                this.errMsgLog = this.errMsgLog + ' ' + 'Incorrect Username or Password';
                this.logIt();
              } else if (url.includes('/refresh')) {
                this.notification.showWarningToast('Login has expired.');
                this.errMsgLog = this.errMsgLog + ' ' + 'Login has expired';
                this.logIt(); // do not change order - logout clears vars and these need to be there to stop multi notify
                this.authUser.logout();
              } else {
                // this.notification.showWarningToast('Login has expired.');
                // this.errMsgLog = this.errMsgLog + ' ' + 'Login has expired';
                // this.logIt(); // do not change order - logout clears vars and these need to be there to stop multi notify
                // this.authUser.logout();
              }
              handled = true;
            } else if ([403].includes(this.errStatus)) {
              this.notification.showErrorToast(this.errMsgScreen);
              this.logIt();
              handled = true;
              // this.authUser.logout();
            } else if ([404].includes(this.errStatus)) {
              // routed to 404 page from app routes via last item in list and ** route
              this.logIt();
              handled = true;
              this.router.navigate(['common/error404-page']).then();
            } else if ([405].includes(this.errStatus)) {
              this.notification.showErrorToast(this.errMsgScreen);
              this.logIt();
              handled = true;
            } else if ([409].includes(this.errStatus)) {
              this.notification.showWarningToastStayOnScreen(this.errStatus + ' - ' + 'if deleting this item is use in other dependent records.'
                + '<br>' + '<br>' + ' Those records must be deleted before you can delete this item.'
                + '<br>' + '<br>' + ' If adding a column requiring unique is not unique. Change text to be unique.');
              this.logIt();
              handled = true;
            } else if ([411].includes(this.errStatus)) {
              this.notification.showErrorToast(this.errMsgScreen);
              this.logIt();
              handled = true;
            } else if ([418].includes(this.errStatus)) {
              // UI message and handling at componenet level since this is the value we use to say a reord has changed since pulled
              // each instance will likely be handled differently, so its best to do this one at the calling component.
              // error is re thrown at the end when it is a 418
              handled = true;
            } else if ([500].includes(this.errStatus)) {
              // routed to 500 page from app routes  ** route
              this.logIt();
              this.router.navigate(['common/error500-page']).then();
              handled = true;
              // this.router.navigate(['common/error500-page']).then();
            } else if ([503].includes(this.errStatus)) {
              // routed to 500 page from app routes via ** route
              this.logIt();
              this.router.navigate(['common/error500-page']).then();
              handled = true;
            } else if ([0].includes(this.errStatus)) {
              // routed to 500 page from app - web server not answering error
              console.log(
                'Server down or not responding to requests correctly - ' +
                  this.errStatus +
                  ' - ' +
                  this.errStatusText +
                  ' - ' +
                  this.errMessage
              );
              this.logIt();
              this.router.navigate(['common/error500-page']).then();
              handled = true;
            } else {
              // catches all others not defined above and displays
              this.notification.showErrorToast(this.errMsgScreen);
              this.router.navigate(['common/error500-page']).then();
              this.logIt();
              handled = true;
            }
          } catch (e) {
            console.log('App error occurred in http message interceptor');
            this.logIt();
            this.notification.showErrorToast(
              'Some undefined application error occurred.'
            );
          }
        }
        if (handled && [418].includes(this.errStatus)) {
          return throwError(() => err);
        } else {
          // console.log('forwarded error:' + JSON.stringify(err));
          return throwError(() => err);
          // return of (err);
        }
      })
    );
  }

  logIt(): void {
    console.log(this.errMsgLog);
    localStorage.setItem('lastErrorTrapped', this.errStatus);
    localStorage.setItem('lastErrorUrl', this.errUrl);
    localStorage.setItem('lastErrorDateTime', new Date().toISOString());
  }

  getStoredValues(value: string): string  {
    return localStorage.getItem(value);
  }
}
