import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '../../../../../Services/email.service';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { EmailPassDataServiceService } from '../../../../../Services/email-pass-data-service.service';
import { ResponseModel } from '../../../../../DataModels/response-model';
import { BsToastService } from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-transfer-report',
  templateUrl: './transfer-report.component.html',
  styleUrls: ['./transfer-report.component.css'],
})
export class TransferReportComponent implements OnInit, AfterViewInit, AfterViewChecked {
  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public appConfigService: AppConfigService,
    public route: ActivatedRoute,
    public aEmail: EmailService,
    public notification: BsToastService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    private emailData: EmailPassDataServiceService,
    public router: Router
  ) {
    this.timeOut = this.appConfigService.toastrWarnTimeOut;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  emailForm: UntypedFormGroup;
  private rptName: string;
  timeOut = 0;
  public urlParams: any = [];
  public emailInvalidMsg: string;

  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      company_id: null,
      from_location_id: null,
      to_location_id: null,
      currency_id: null,
      includeUnposted: null,
      item_id: null,
      columns: null,
      location_id: null,
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: '',
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    monthCount: 0,
    companyData: null,
    locationData: null,
    inventoryItem: [],
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    Preferences: null,
    permissions: null,
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    companyId: null,
    currencyId: null,
    locationId: null,
    companyName: null,
    locationName: null,
    inventoryItemName: [],
    inventoryItemInput: '',
    inventoryItemFilter: { search_string: null },
  };
  public submitted = false;

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = 'Inventory Transfer Report';
  }

  ngAfterViewChecked() {
    // alert('viewChecked fired');
    // sets data for email of report
    const div = document.getElementById('excel-export');

    // console.log(typeof div);
    // console.log(div.toString());
    // console.log(typeof div.outerHTML);
    // console.log('viewchk: ' + div.outerHTML);
    // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Transfer Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  onSubmit() {
    this.emailForm.reset();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getTableData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });
    this.tableDataService
      .getTableData('item?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.inventoryItem = data;
        for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
          this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
        }
      });
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });

    // if (this.localStorage.StorageGet('company', '')) {
    //   this.vm.company = this.localStorage.StorageGet('company', '');
    // } else {
    //   this.localStorage.StorageSet('company', this.vm.company);
    // }
    // if (this.localStorage.StorageGet('location', '')) {
    //   this.vm.location = this.localStorage.StorageGet('location', '');
    // } else {
    //   this.localStorage.StorageSet('location', this.vm.location);
    // }
    // if (this.localStorage.StorageGet('inventoryItem', '')) {
    //   this.vm.inventoryItem = this.localStorage.StorageGet('inventoryItem', '');
    // } else {
    //   this.localStorage.StorageSet('inventoryItem', this.vm.inventoryItem);
    // }
    // if (this.localStorage.StorageGet('currency', '')) {
    //   this.vm.currency = this.localStorage.StorageGet('currency', '');
    // } else {
    //   this.localStorage.StorageSet('currency', this.vm.currency);
    // }

    if (this.urlParams.to_locationId) {
      this.vm.request.to_location_id = this.urlParams.to_locationId;
    }

    if (this.urlParams.from_locationId) {
      this.vm.request.from_location_id = this.urlParams.from_locationId;
    }
    if (this.urlParams.currency_id) {
      this.vm.request.currency_id = this.urlParams.currency_id;
    }

    for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
      this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
    }

    if (
      this.vm.request.toDate &&
      this.vm.request.fromDate &&
      (this.urlParams.companyId || this.urlParams.currencyId || this.urlParams.locationId)
    ) {
      this.update();
    }
  }

  Print() {
    return window.print();
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  update() {
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.fromDate) {
      this.vm.dateError = true;
      errorDescription += $localize`:From date is required. @@From date is required.:From date is required.` + ' ';
    }
    if (!this.vm.request.toDate) {
      this.vm.dateError = true;
      errorDescription += $localize`:To date is required. @@todateisrequired:To date is required.` + ' ';
    }

    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    if (tempToDate < tempFromDate) {
      errorDescription += $localize`:From date must be less than the To date @@fromdatemustbelessthantheTodate:From date must be less than the To date`;
      this.vm.toDateError = true;
      this.vm.fromDateError = true;
    }
    if (errorDescription) {
      this.notification.showWarningToast(errorDescription);
    } else {
      this.vm.dateError = false;
      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;

      const urlParamsJson = {
        currency_id: this.vm.request.currency_id,
        fromDate: this.vm.request.fromDate,
        toDate: this.vm.request.toDate,
        company_id: this.vm.request.company_id,
        from_location_id: this.vm.request.from_location_id,
        to_location_id: this.vm.request.to_location_id,
        pageSize: 99999999,
      };

      const TransferRerpotParam: string = this.encodeQueryData(urlParamsJson);

      this.tableDataService
        .getTableData('TransferReport', '?' + TransferRerpotParam)
        .subscribe((result: ResponseModel) => {
          this.vm.report = result.Data;
          this.vm.showFilters = this.vm.buttonsDisabled = false;
          this.vm.emailed = true;
          this.calcTotals();
          if (
            (this.vm.request.company_id && this.vm.company.length > 1) ||
            this.vm.request.from_location_id ||
            this.vm.request.to_location_id ||
            (this.vm.request.currency_id && this.vm.currency.length > 1)
          ) {
            this.vm.filtered = true;
          }
        });
    }
  }

  calcTotals() {
    for (const [x, value] of Object.entries(this.vm.report)) {
    }
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' - ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.vm.report = [];
    this.vm.request = {
      toDate: null,
      fromDate: null,
      company_id: null,
      from_location_id: null,
      to_location_id: null,
      currency_id: null,
      includeUnposted: null,
      item_id: null,
      columns: null,
      location_id: null,
    };
    this.vm.filtered = false;
  }

  FilterObject(value, filterObj, test = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  updateInventoryItem() {
    this.vm.inventoryItemName = [];
    this.localStorage.StorageSet('inventoryItem', '');
    this.tableDataService.getTableData('item', '?state=active').subscribe((result) => {
      this.vm.inventoryItem = result;
      console.log(result);
      for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
        this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
      }
    });
  }

  selectItemModal(itemId) {
    this.vm.request.item_id = itemId;
    for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
      if (this.vm.inventoryItem[x].id === itemId) {
        this.vm.inventoryItemInput = this.vm.inventoryItem[x].code + ' ' + this.vm.inventoryItem[x].name;
        break;
      }
    }
  }

  testItem() {
    const itemIdStart = this.vm.request.item_id;
    if (!this.vm.inventoryItemInput || this.vm.inventoryItemInput == '') {
      this.vm.request.item_id = '';
    } else {
      if (this.FilterObject(this.vm.inventoryItem, { search_string: this.vm.inventoryItemInput }, true).length > 0) {
        this.vm.request.item_id = this.FilterObject(
          this.vm.inventoryItem,
          { search_string: this.vm.inventoryItemInput },
          true
        )[0].id;
      } else {
        this.vm.request.item_id = '';
        this.vm.inventoryItemInput = '';
      }
    }
    if (itemIdStart != this.vm.request.item_id) {
      this.vm.report = [];
    }
  }
}
