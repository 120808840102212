import {Injectable} from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;
  private httpClient: HttpClient;

  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  loadAppConfig() {
    return this.httpClient.get('/assets/scss/AppConfiguration.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  // This is an example property ... you can make it however you want.
  get apiBaseUrl() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.apiBaseUrl;
  }

  get tokenPath() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.tokenPath;
  }

  get ssrsUrl() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.ssrsUrl;
  }

  get toastrWarnTimeOut() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.toastrWarnTimeOut;
  }

  get ListPageSize() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.ListPageSize;
  }

  get gridRecsPerListPage() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.gridRecsPerListPage;
  }

  get gridPageSizeSelection(): [] {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    // prepend the page size onto the selections so it is always in the selections if it is not
    // if(this.appConfig.gridPageSizeSelection.find(x => x === this.appConfig.gridRecsPerListPage) === undefined ){
    if (!this.appConfig.gridPageSizeSelection.includes(this.appConfig.gridRecsPerListPage)) {
      this.appConfig.gridPageSizeSelection.unshift(this.appConfig.gridRecsPerListPage);
      return this.appConfig.gridPageSizeSelection;
    } else {
      return this.appConfig.gridPageSizeSelection;
    }
  }

  get SysEmailAddress() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.SysEmailAddress;
  }

  get decimalPlaces() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.decimalPlaces;
  }

  get maxRecords() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.maxRecords;
  }

  get chartBorderColor() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.chartBorderColor;
  }

  get disableDefaultContentMenu() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.disableDefaultContentMenu;
  }

  get activityFeedDays() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.activityFeedDays;
  }

  get leftMenuThemeColor() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.leftMenuThemeColor;
  }

  get bodyThemeColor() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.bodyThemeColor;
  }

  get gridThemeColor() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.gridThemeColor;
  }

  get supportSite() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.supportSite;
  }

  get pagerStateSaveTime() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.pagerStateSaveTime;
  }

  get bsToastOnScreenTime() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.bsToastOnScreenTime;
  }

  get useLastPageAutoRouting(): boolean {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.useLastPageAutoRouting;
  }

  get userIdleTimeOutOn(): boolean {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.userIdleTimeOutOn;
  }
  get userIdleTimeOut() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.userIdleTimeOut;
  }

  get userIdleCountdownLength() {
    if (!this.appConfig) {
      throw Error('Config not found!');
    }
    return this.appConfig.userIdleCountdownLength;
  }

}


