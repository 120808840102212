import {Component, OnInit, AfterViewChecked, forwardRef} from '@angular/core';
import { TableDataService } from '../../../Services/tableData.service';
import { StorageService } from '../../../Services/storage.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FilterPipe } from 'ngx-filter-pipe';
import { Title } from '@angular/platform-browser';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../DataModels/response-model';
import { BsToastService } from '../../../Services/bs-toast-service';
import { RoutingTrackerService} from '../../../Services/routing-tracker.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-shipment-form',
  templateUrl: './shipment-form.component.html',
  styleUrls: ['./shipment-form.component.css'],
})
export class ShipmentFormComponent implements OnInit, AfterViewChecked {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private titleService: Title,
    private confirmDialogService: ConfirmDialogService,
    public prevUrl: RoutingTrackerService
  ) {
    this.vm.buttonsDisabled = false;
    this.vm.shipmentInvoices = [];
    this.vm.permissions = [];
    this.vm.accountFilter = [];
    this.vm.OrderList = [];
    this.vm.account = [];
    this.vm.currency = [];
    this.vm.is_init = false;
    this.vm.printItem = [];
    this.vm.shipmentAttachments = [];
    this.vm.orderInput = [];
    this.vm.isLoaded = false;

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }
  vm: any = {
    item: {
      detail: {
        quantity: 0,
        shipped_quantity: 0,
        linked_detail_id: 0,
        backOrder_quantity: 0
      },
    },
  };
  shipmentId: string;
  sales: string | boolean;
  batchId: string;
  orderId: string;
  action: string;
  type: string;

  backOrder: number;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: false,
    print: true,
  };

  submitted = false;
  locked = false;
  routedFrom = '';

  protected readonly window = window;

  ngOnInit() {
    this.vm.isLoaded = false;
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.shipmentId = params.get('shipmentId');
      this.batchId = params.get('batchId');
      this.orderId = params.get('orderId');
      this.action = params.get('action');
      this.type = params.get('type');
      this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
      if (this.shipmentId === 'newSS') {
        this.vm.item = {};
        this.vm.buttonsDisabled = false;
        this.vm.item = { batchTypeId: '11' };
      } else if (this.shipmentId === 'newPS') {
        this.vm.buttonsDisabled = false;
        this.vm.item = {};
        this.vm.item = { batchTypeId: '12' };
      } else {
        this.vm.is_init = true;
        this.vm.item = {};
        this.loadItem().then();
      }

      this.vm.item.header = [];
      this.vm.item.detail = [];
      this.vm.item.attachments = [];

      this.loadCompany();
      this.loadCurrency();
      this.loadEntity().then();
      this.loadInventoryItem();
      this.loadLocation();
      this.loadUOM();
      if (!this.vm.is_init) {
        this.init();
      }
    });
  }

  ngAfterViewChecked() {
    this.titleService.setTitle(this.vm.title);
  }

  onClose() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom === undefined) {
      window.close();
    } else if (this.type == 'ar') {
      this.router.navigate(['transactions/shipment/list/ar']).then();
    } else {
      this.router.navigate(['transactions/shipment/list/ap']).then();
    }
  }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    retVal = !value;
    return retVal;
  }

  async loadItem() {
    this.submitted = true;
    await this.getTableDataAsync('entry', this.shipmentId, false, (result) => {
      this.vm.item = result;
      // console.log(result);
      this.locked = this.vm.item.batch.locked;
      this.init();
      this.vm.title = this.vm.shipmentTypeName + ' ' + 'Shipment' + ' ' + this.vm.item.header.number;
    });
  }

  loadUOM() {
    this.getTableData('uom', '?state=active&pageNumber=0&pageSize=999999', 'uom', (value) => {
      this.vm.uom = value.Data;
    });
  }

  loadCompany() {
    this.getTableData('company', '?state=active&pageNumber=0&pageSize=999999', 'company', (value) => {
      this.vm.company = value.Data;
    });
  }

  loadCurrency() {
    this.getTableData('currency', '?state=active&pageNumber=0&pageSize=999999', 'currency', (value) => {
      this.vm.currency = value.Data;
    });
  }

  async loadEntity() {
    this.getTableData(
      'entity',
      '?state=active&pageNumber=0&pageSize=999999',
      'entity',
      (value: ResponseModel) => {
        this.vm.entity = value.Data;
      }
    );
  }

  loadLocation() {
    this.getTableData('location', '?state=active&pageNumber=0&pageSize=999999', 'location', (value) => {
      this.vm.location = value.Data;
    });
  }

  loadInventoryItem() {
    this.getTableData('item', '?state=active&pageNumber=0&pageSize=999999', 'item', (value) => {
      this.vm.inventoryItem = value.Data;
    });
  }

  async getTableDataAsync(tableName, params, cacheName, fun) {
    {
      await this.tableDataService
        .getTableData(tableName, params)
        .toPromise()
        .then((result: any) => {
          fun(result);
          if (cacheName) {
            // this.localStorage.StorageSet(cacheName, JSON.stringify(result));
          }
        });
    }
  }

  init() {
    if (this.vm.item.batchTypeId) {
      // NEW ENTRY
      this.vm.subLedger = 'ap';
      this.vm.shipmentTypeClass = 'payables';
      this.vm.shipmentTypeName = $localize`:Purchase @@purchase:Purchase` + ' ';
      this.sales = false;
      if (this.vm.item.batchTypeId == 11) {
        this.vm.subLedger = 'ar';
        this.vm.shipmentTypeClass = 'receivables';
        this.vm.shipmentTypeName = $localize`:Sales @@sales:Sales` + ' ';
        this.sales = true;
      }
      this.vm.item.batch = {};
      this.vm.item.batch.type_id = this.vm.item.batchTypeId; // '2';
      this.vm.item.header = {};
      this.vm.item.header.currency_rate = '1';
      // this.vm.item.header.completed = true;
      this.vm.item.detail = [];
      if (this.vm.item.batchTypeId == '11') {
        this.vm.shipmentTypeName = $localize`:Sales @@sales:Sales`;
      } else {
        this.vm.shipmentTypeName = $localize`:Purchase @@Purchase:Purchase`;
      }
      if (this.batchId) {
        this.vm.batchId = this.vm.item.batch.id = this.batchId;
      } else {
        this.vm.item.batch.description = 'Batch';
      }

      const d = new Date();
      this.vm.item.header.header_date = this.datePipe.transform(d, 'MM/dd/yyyy');

      this.tableDataService
        .getApiListData('OrderList', '?sales=' + this.sales + '&pageNumber=0&pageSize=999999')
        .subscribe((value: ResponseModel) => {
          this.vm.OrderList = value.Data;
          // console.log('OrderList: ' + JSON.stringify(this.vm.OrderList));

          if (this.orderId) {
            this.loadOrder(this.orderId);
          }
        });
    } else {
      // EDIT ENTRY
      this.vm.subLedger = 'ap';
      this.vm.shipmentTypeClass = 'payables';
      this.vm.shipmentTypeName = $localize`:Purchase @@Purchase:Purchase` + ' ';
      this.sales = false;
      if (this.vm.item.batch.type_id == 11) {
        this.vm.subLedger = 'ar';
        this.vm.shipmentTypeClass = 'receivables';
        this.vm.shipmentTypeName = $localize`:Sales @@Sales:Sales` + ' ';
        this.sales = true;
      }

      this.tableDataService.getTableData('OrderList', this.vm.item.header.linked_header_id).subscribe((result) => {
        this.vm.OrderList = result;
        // console.log(this.vm.OrderList);
        this.vm.orderDisplay = this.vm.OrderList.entity_name + ' ' + this.vm.OrderList.number;
      });

      this.vm.item.header.header_date = this.datePipe.transform(this.vm.item.header.header_date, 'MM/dd/yyyy');
      // TODO: Need to verify below 3 lines.
      // if (
      //   this.FilterObject(this.vm.entity, { entity_id: this.vm.item.header.entity_id }, true) &&
      //   this.FilterObject(this.vm.entity, { entity_id: this.vm.item.header.entity_id }, true).length != 0 &&
      //   this.vm.item.header.entity_id
      // ) {
      //   this.vm.entity_name = this.FilterObject(
      //     this.vm.entity,
      //     { entity_id: this.vm.item.header.entity_id },
      //     true
      //   )[0].name;
      // }
      for (const [x, val] of Object.entries(this.vm.item.detail)) {
        if (this.vm.item.detail[x].item_id) {
          if (
            this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.detail[x].item_id }, true) &&
            this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.detail[x].item_id }, true)[0]
          ) {
            this.vm.item.detail[x].item_code = this.FilterObject(
              this.vm.inventoryItem,
              { id: this.vm.item.detail[x].item_id },
              true
            )[0].code;
          }
        }
        this.vm.item.detail[x].amount = Number(this.vm.item.detail[x].amount);
        this.vm.item.detail[x].quantity = Number(this.vm.item.detail[x].quantity);
        this.vm.item.detail[x].shipped_quantity = Number(this.vm.item.detail[x].quantity);
        // this.vm.item.detail[x].sort_order = 0;
      }

      let paramString: string;

      paramString = '';
      paramString += 'billing_address=1';
      paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

      this.tableDataService.getApiListData('entity_address/addresses', '?' + paramString).subscribe((result: any) => {
        this.vm.billToAddress = result.Data;
        if (this.vm.billToAddress.length == 1) {
          this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
        }
      });

      paramString = '';
      paramString += 'billing_address=0';
      paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

      this.tableDataService.getApiListData('entity_address/addresses', '?' + paramString).subscribe((result: any) => {
        this.vm.shipToAddress = result.Data;
        if (this.vm.shipToAddress.length == 1) {
          this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
        }
      });

      paramString = '';
      paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

      this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: any) => {
        this.vm.contact = result;
      });

      this.getOrderId();
      this.getPriorShipments();
      this.calcTotal();
      // this.countItems('0');

      if (this.vm.item.header.linked_header_id) {
        paramString = '';
        paramString += 'id=' + (this.vm.item.header.linked_header_id ? this.vm.item.header.linked_header_id : '');
        this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: any) => {
          const tempLinked = result.Data;
          if (tempLinked && tempLinked.header) {
            this.vm.orderNumber = tempLinked.header.number;
            this.vm.orderId = tempLinked.header.id;
          }
          this.countItems('0');
        });
      }
      this.addText();

      this.vm.isLoaded = true;
    }

    for (const [x, val] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].debit_credit) {
        this.vm.item.detail[x].debit_credit = Number(this.vm.item.detail[x].debit_credit);
      }
    }
    if (this.vm.company && this.vm.company.length == 1) {
      this.vm.item.header.company_id = this.vm.company[0].id;
    }
    if (this.vm.currency && this.vm.currency.length == 1) {
      this.vm.item.header.currency_id = this.vm.currency[0].id;
    }
    if (this.vm.location && this.vm.location.length == 1) {
      this.vm.item.header.location_id = this.vm.location[0].id;
    }
    this.vm.isLoaded = true;
  }

  // loadEntity() {
  //   for (const [x, val] of Object.entries(this.vm.entity)) {
  //     if (this.vm.order.header.entity_id == this.vm.entity[x].id) {
  //       const formatedDate = this.datePipe.transform(this.vm.order.header.header_date, 'MM/dd/yyyy');
  //       let tempDesc = '';
  //       if (this.vm.order.header.description) {
  //         tempDesc = this.vm.order.header.description;
  //       }
  //       this.vm.orderInput = this.vm.entity[x].name + ' ' + tempDesc + ' ' + formatedDate + ' ' + this.vm.order.header.number;
  //       console.log('orderinput: ' + JSON.stringify(this.vm.orderInput));
  //       break;
  //     }
  // }

  loadOrder(orderId: string) {
    this.tableDataService
      .getTableData('entry', orderId)
      .toPromise()
      .then((result: any) => {
        this.vm.order = result;
        // console.log('order: ' + JSON.stringify(this.vm.order));
        this.vm.item.detail = {};
        this.vm.item.detail = this.vm.order.detail;
        this.vm.orderNumber = this.vm.order.header.number;

        for (const [x, val] of Object.entries(this.vm.entity)) {
          if (this.vm.order.header.entity_id == this.vm.entity[x].id) {
            const formatedDate = this.datePipe.transform(this.vm.order.header.header_date, 'MM/dd/yyyy');
            let tempDesc = '';
            if (this.vm.order.header.description) {
              tempDesc = this.vm.order.header.description;
            }
            this.vm.orderInput =
              this.vm.entity[x].name + ' ' + tempDesc + ' ' + formatedDate + ' ' + this.vm.order.header.number;
            // console.log('orderinput: ' + JSON.stringify(this.vm.orderInput));
            break;
          }
        }
        this.vm.item.header.company_id = this.vm.order.header.company_id;
        this.vm.item.header.currency_id = this.vm.order.header.currency_id;
        this.vm.item.header.location_id = this.vm.order.header.location_id;
        this.vm.item.header.entity_id = this.vm.order.header.entity_id;
        this.vm.item.header.bill_address_id = this.vm.order.header.bill_address_id;
        this.vm.item.header.ship_address_id = this.vm.order.header.ship_address_id;
        this.vm.item.header.term_id = this.vm.order.header.term_id;
        this.vm.item.header.linked_header_id = this.vm.order.header.id;
        this.vm.item.header.comment = this.vm.order.header.comment;
        this.vm.item.header.reference = this.vm.order.header.reference;
        this.vm.item.header.description = this.vm.order.header.description;
        // batch info for new
        this.vm.item.batch.description = '';
        this.vm.item.batch.id = '';
        this.vm.item.batch.type_id = this.type === 'ar' ? 11 : 12; // this.vm.item.batch.type_id; // 11 or 12
        // set the batch value in header
        this.vm.item.header.batch_id = '';

        // have to pass the control the order id because that is value it is set from OrderList record when selected in ui
        this.vm.orderInput = this.vm.order.header.id;

        // for (const [y, val] of Object.entries(this.vm.order.detail)) {
        //   this.vm.item.detail[y].order_quantity = {};
        //   this.vm.item.detail[y].shipped_quantity = {};
        //   this.vm.item.detail[y].linked_detail_id = {};
        //   this.vm.item.detail[y].backOrder_quantity = {};
        //   this.vm.item.detail[y].order_quantity = this.vm.item.detail[y].quantity;
        //   this.vm.item.detail[y].shipped_quantity = this.vm.item.detail[y].quantity;
        //   this.vm.item.detail[y].backOrder_quantity = this.vm.item.detail[y].quantity - this.vm.item.detail[y].shipped_quantity;
        //   this.vm.item.detail[y].linked_detail_id = this.vm.item.detail[y].id;
        // }

        // console.log('order AFTER detail insert: ' + JSON.stringify(this.vm.order));

        let paramString;
        paramString = '';
        paramString += 'billing_address=1';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

        this.tableDataService.getApiListData('entity_address/addresses', '?' + paramString).subscribe((res: any) => {
          this.vm.billToAddress = res.Data;
          if (this.vm.billToAddress.length == 1) {
            this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
          }
        });

        paramString = '';
        paramString += 'billing_address=0';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

        this.tableDataService.getApiListData('entity_address/addresses', '?' + paramString).subscribe((r: any) => {
          this.vm.shipToAddress = r.Data;
          if (this.vm.shipToAddress.length == 1) {
            this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
          }
        });

        paramString = '';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

        this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((data: any) => {
          this.vm.contact = data;
        });
      });
  }

  getOrderId() {
    if (this.vm.item.header.id) {
      let paramString = '';
      paramString += this.vm.item.header.linked_header_id;
      this.tableDataService.getTableData('entry', paramString).subscribe((result: any) => {
        this.vm.order = result;
        for (const [y, valY] of Object.entries(this.vm.item.detail)) {
          for (const [z, valZ] of Object.entries(this.vm.order.detail)) {
            if (this.vm.order.detail[z].item_id == this.vm.item.detail[y].item_id) {
              this.vm.item.detail[y].order_quantity = this.vm.order.detail[z].quantity;
            }
          }
        }
      });
      return;
    }
    let tempBatchID = 11;
    if (this.vm.item.batchTypeId) {
      tempBatchID = Number(this.vm.item.batchTypeId);
    }
    for (const [x, valX] of Object.entries(this.vm.OrderList)) {
      let tempDescription = '';
      const tempDate = this.vm.item.header.header_date;
      if (this.vm.OrderList[x].description) {
        tempDescription = this.vm.OrderList[x].description;
      }
      if (this.vm.OrderList[x].entity_name == this.vm.orderInput) {
        this.vm.order_id = this.vm.OrderList[x].id;
        this.tableDataService.getTableData('entry', this.vm.order_id).subscribe((result: any) => {
          this.vm.order = result;
          this.vm.item = this.vm.order;
          this.vm.item.batch.id = undefined;
          this.vm.item.batch.type_id = tempBatchID;
          this.vm.item.header.id = undefined;
          this.vm.item.header.batch_id = undefined;
          this.vm.item.header.header_date = tempDate;
          this.vm.item.header.entity_id = this.vm.order.header.entity_id;
          this.vm.item.header.location_id = this.vm.order.header.location_id;
          this.vm.item.header.bill_address_id = this.vm.order.header.bill_address_id;
          this.vm.item.header.ship_address_id = this.vm.order.header.ship_address_id;
          this.vm.item.header.currency_id = this.vm.order.header.currency_id;
          this.vm.item.header.term_id = this.vm.order.header.term_id;
          this.vm.item.header.linked_header_id = this.vm.order_id;
          for (const [y, val] of Object.entries(this.vm.item.detail)) {
            this.vm.item.detail[y].order_quantity = this.vm.item.detail[y].quantity;
            this.vm.item.detail[y].shipped_quantity = this.vm.item.detail[y].quantity;
            this.vm.item.detail[y].linked_detail_id = this.vm.item.detail[y].id;
          }
        });
        let paramString;
        paramString = '';
        paramString += 'billing_address=1';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
        this.tableDataService.getTableData('entity_address/addresses', '?' + paramString).subscribe((result: any) => {
          this.vm.billToAddress = result;
        });

        paramString = '';
        paramString += 'billing_address=0';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

        this.tableDataService.getTableData('entity_address/addresses', '?' + paramString).subscribe((result: any) => {
          this.vm.shipToAddress = result;
        });

        paramString = '';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');

        this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: any) => {
          this.vm.contact = result;
        });
        break;
      }
    }
  }

  getPriorShipments() {
    let paramString = '';
    paramString += 'shipment_id=' + this.vm.item.header.id;
    paramString += '&order_id=' + this.vm.item.header.linked_header_id;
    this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: any) => {
      this.vm.priorShipments = result.Data;
      for (const [x, val] of Object.entries(this.vm.item.detail)) {
        for (const [y, valY] of Object.entries(this.vm.priorShipments)) {
          if (this.vm.priorShipments[y].item_id == this.vm.item.detail[x].item_id) {
            this.vm.item.detail[x].prior_shipped_quantity = this.vm.priorShipments[y].prior_shipped_quantity;
            this.vm.item.detail[x].backOrder_quantity = this.vm.item.detail[x].quantity - (this.vm.item.detail[x].prior_shipped_quantity + this.vm.item.detail[x].quantity);
          // console.log(this.vm.item.detail[x].backOrder_quantity);
          }
        }
      }
    });
  }

  private calcTotal() {
    let total = 0;
    for (const [x, val] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].debit_credit) {
        if (!Number(this.vm.item.detail[x].debit_credit) && this.vm.item.detail[x].debit_credit != '-') {
          this.notification.showErrorToast(
            $localize`:Amount @@amount:Amount` +
              '" <strong>' +
              this.vm.item.detail[x].debit_credit +
              '</strong>" ' +
              $localize`:is not a number, please remove any text. @@isnotanumberpleaseremoveanytext:is not a number, please remove any text.` +
              '<br/>'
          );
        } else if (this.vm.item.detail[x].debit_credit != '-') {
          total += Number(this.vm.item.detail[x].debit_credit);
        }
      }
    }
    this.vm.total = Math.round(total * 100) / 100;
  }

  countItems(event: any) {
    this.vm.itemCount = 0;
    for (const [x, val] of Object.entries(this.vm.item.detail)) {

      if (!this.vm.item.detail[x].prior_shipped_quantity) {
        this.vm.item.detail[x].prior_shipped_quantity = 0;
      }
      // console.log('order: ' + this.vm.item.detail[x].order_quantity);
      // console.log('prior: ' + this.vm.item.detail[x].prior_shipped_quantity);
      // console.log('shipped: ' + this.vm.item.detail[x].shipped_quantity);

      this.vm.item.detail[x].backOrder_quantity = Number(this.vm.item.detail[x].order_quantity) - Number((this.vm.item.detail[x].prior_shipped_quantity)
        + Number(this.vm.item.detail[x].shipped_quantity));

      if (this.vm.item.detail[x].backOrder_quantity <= 0) {
        this.vm.item.detail[x].backOrder_quantity = 0;
      }
      // console.log('backorder: ' + this.vm.item.detail[x].backOrder_quantity);
      this.vm.itemCount += Number(this.vm.item.detail[x].shipped_quantity);
    }

  }

  print() {
    this.vm.action = 'print';
    for (const [x, val] of Object.entries(this.vm.item.detail)) {
      if (this.FilterObject(this.vm.uom, { id: this.vm.item.detail[x].uom_id }, true)[0] != undefined) {
        this.vm.item.detail[x].uom = this.FilterObject(
          this.vm.uom,
          { id: this.vm.item.detail[x].uom_id },
          true
        )[0].code;
      }
    }
    if (this.vm.item.header.entity_id) {
      if (this.FilterObject(this.vm.entity, { id: this.vm.item.header.entity_id }, true)[0] != undefined) {
        this.vm.item.header.entityInput = this.FilterObject(
          this.vm.entity,
          { id: this.vm.item.header.entity_id },
          true
        )[0].name;
      }
    }
    // this.vm.item.header.entityInput
    if (this.vm.item.header.bill_address_id) {
      if (this.FilterObject(this.vm.billToAddress, { id: this.vm.item.header.bill_address_id }, true)[0] != undefined) {
        const tempAddress = this.FilterObject(
          this.vm.billToAddress,
          { id: this.vm.item.header.bill_address_id },
          true
        )[0];
        this.vm.printItem.billToAddress1 = tempAddress.address_1;
        this.vm.printItem.billToAddress2 = tempAddress.city;
        if (tempAddress.state) {
          this.vm.printItem.billToAddress2 += ', ' + tempAddress.state;
        }
        this.vm.printItem.billToAddress2 += '  ' + tempAddress.zip;
      }
    }

    if (this.vm.item.header.ship_address_id) {
      if (this.FilterObject(this.vm.shipToAddress, { id: this.vm.item.header.ship_address_id }, true)[0] != undefined) {
        const tempAddress = this.FilterObject(
          this.vm.shipToAddress,
          { id: this.vm.item.header.ship_address_id },
          true
        )[0];
        this.vm.printItem.shipToAddress1 = tempAddress.address_1;
        this.vm.printItem.shipToAddress2 = tempAddress.city;
        if (tempAddress.state) {
          this.vm.printItem.shipToAddress2 += ', ' + tempAddress.state;
        }
        this.vm.printItem.shipToAddress2 += '  ' + tempAddress.zip;
      }
    }

    // set company address
    if (this.vm.item.header.company_id) {
      if (this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0] != undefined) {
        const tempAddress = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0];
        this.vm.printItem.companyLogo = tempAddress.logo;
        this.vm.printItem.companyName = tempAddress.name;
        this.vm.printItem.companyAddress1 = tempAddress.address_1;
        this.vm.printItem.companyAddress2 = tempAddress.city;
        if (tempAddress.state) {
          this.vm.printItem.companyAddress2 += ', ' + tempAddress.state;
        }
        this.vm.printItem.companyAddress2 += '  ' + tempAddress.zip;
        this.vm.printItem.companyTaxNumber = tempAddress.tax_number;
        this.vm.printItem.companyPhone = tempAddress.phone;
      }
    }
    for (const [x, valX] of Object.entries(this.vm.inventoryItem)) {
      for (const [y, valY] of Object.entries(this.vm.item.detail)) {
        if (this.vm.item.detail[y].item_id == this.vm.inventoryItem[x].id) {
          this.vm.item.detail[y].itemInput = this.vm.inventoryItem[x].code;
        }
      }
    }

    if (
      this.action &&
      !this.vm.item.batch.locked &&
      (this.vm.permissions.Admin ||
        (this.vm.permissions.EditReceivableInvoices && this.vm.subLedger == 'ar') ||
        (this.vm.permissions.EditPayableInvoices && this.vm.subLedger == 'ap'))
    ) {
      this.save();
    } else {
      this.action = '';

      this.getTableData('uom', '?state=active&pageNumber=0&pageSize=999999', 'uom', (value) => {
        window.print();
        this.vm.action = '';
      });
    }
  }

  save() {
    this.submitted = true;
    this.vm.buttonsDisabled = true;
    this.vm.errorDescription = '';
    if (this.vm.tempCompleted) {
      this.vm.item.header.completed = this.vm.tempCompleted;
    }

    for (const [x, valX] of Object.entries(this.vm.item.detail)) {
      this.vm.item.detail[x].quantity = this.vm.item.detail[x].shipped_quantity;
    }

    // check errors
    if (!this.vm.item.header.header_date) {
      this.vm.errorDescription += $localize`:Date is required. @@dateisrequired:Date is required.` + '<br/>';
      this.vm.dateMissing = true;
    } else {
      const yearEndDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .year_end_date;
      const cutOffDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .cut_off_date;
      const tempYearEndDate = new Date(yearEndDate);
      const tempCutOffDate = new Date(cutOffDate);
      const headerDate = new Date(this.vm.item.header.header_date);
      if (headerDate < tempYearEndDate) {
        this.vm.errorDescription +=
          $localize`:Date must be greater than the year end @@datemustbegreaterthantheyearend:Date must be greater than the year end` +
          ' ' +
          yearEndDate +
          '<br/>';
        this.vm.dateError = true;
      }
      if (headerDate < tempCutOffDate) {
        this.vm.errorDescription +=
          $localize`:Date must be greater than the cut off date @@datemustbegreaterthanthecutoffdate:Date must be greater than the cut off date` +
          ' ' +
          cutOffDate +
          '<br/>';
        this.vm.dateError = true;
      }
    }

    if (!this.vm.item.header.company_id) {
      this.vm.errorDescription += $localize`:Company is required. @@companyisrequired:Company is required.` + '<br/>';
      this.vm.companyError = true;
    }
    if (!this.vm.item.header.location_id) {
      this.vm.errorDescription +=
        $localize`:Location is required. @@locationisrequired:Location is required.` + '<br/>';
      this.vm.locationError = true;
    }
    if (!this.vm.item.header.currency_id) {
      this.vm.errorDescription += $localize`:Currency is required. @@currencyisrequired:Currency is required.` + '<br/>';
      this.vm.currencyError = true;
    }

    for (const [x, valX] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].rowTotal) {
        if (!this.vm.item.detail[x].description) {
          this.vm.errorDescription +=
            $localize`:Detail description is required. @@detaildescriptionisrequired:Detail description is required.` +
            '<br/>';
        }
        if (!this.vm.item.detail[x].account_id) {
          this.vm.errorDescription +=
            $localize`:Detail account is required. @@detailaccountisrequired:Detail account is required.` + '<br/>';
        }
      }
    }

    if (
      !this.vm.permissions.EditSalesShipments &&
      !this.vm.permissions.EditPurchaseShipments &&
      !this.vm.permissions.Admin
    ) {
      this.vm.errorDescription = $localize`:You do not have access to save @@youdonothaveaccesstosave:You do not have access to save`;
    }

    if (this.vm.errorDescription === '') {
      if (!this.vm.item.header.signature || this.vm.item.header.signature == '') {
        // TODO maybe force sig? not sure. Maybe base on setting?
      }

      if (this.vm.item.header.id == undefined || this.vm.item.header.id == '') {
        // new

        // console.log('posting: ' + JSON.stringify(this.vm.item));

        this.tableDataService.post('entry', this.vm.item).subscribe(
          (item: any) => {
            if (this.vm.action !== 'invoice' && this.vm.action !== 'print') {
              // this.notification.success($localize`:Saved@@saved:Saved`);
            }
            this.vm.buttonsDisabled = false;
            this.router.navigate(['transactions/shipment/form'], {
              queryParams: { shipmentId: item.id, type: this.type },
            }).then();
          },
          (error: { error: { Message: string }; statusText: string }) => {
            this.vm.buttonsDisabled = false;
            let ErrorMsg: string;
            if (typeof error.error.Message != 'undefined') {
              ErrorMsg = error.error.Message;
            } else {
              ErrorMsg = error.statusText;
            }
            // this.notification.error(ErrorMsg);
            console.log(ErrorMsg);
            if (this.vm.action == 'print') {
              window.print();
              this.vm.action = '';
            }
          }
        );
      } else {
        // edit
        const params = {
          id: this.vm.item.header.id,
          tablename: 'entry',
        };

        // diff
        const changes: any = {};

        changes.detail = this.vm.item.detail;
        changes.salesperson = this.vm.item.salesperson;
        changes.attachments = this.vm.item.attachments;

        const headerChanges: any = {};
        for (const key in this.vm.item.header) {
          if (this.vm.item.header.hasOwnProperty(key)) {
            const value = this.vm.item.header[key];
            if (value !== key && key !== 'entityInput') {
              headerChanges[key] = value;
            }
          }
        }

        if (headerChanges) {
          changes.header = headerChanges;
        }

        this.tableDataService.patch('entry', this.vm.item.header.id, changes).then(
          (item: any) => {
            this.vm.buttonsDisabled = false;
            // this.notification.success(item.description);
            if (this.vm.action === 'print') {
              window.print();
              this.vm.action = '';
            } else if (this.vm.action === 'invoice') {
              if (this.vm.subLedger == 'ar') {
                //  this.router.navigate(['/contact'],{queryParams: {part: 'navbar',search: 'contact', year: 2021 }})
                this.router.navigate(['transactions/invoice/form'], {
                  queryParams: { invoiceId: 'newAR', shipmentId: this.vm.item.header.id },
                }).then();
              } else {
                this.router.navigate(['transactions/invoice/form'], {
                  queryParams: { invoiceId: 'newAP', shipmentId: this.vm.item.header.id },
                }).then();
              }
              // this.vm.action = '';
            } else {
              // this.updateAttachments();
            }
            if (item.description == 'Deleted') {
              this.router.navigate(['transactions/shipment/list/ar']).then();
            }
          },
          (error: { error: { Message: string }; statusText: string }) => {
            this.vm.buttonsDisabled = false;
            let ErrorMsg: string;
            if (typeof error.error.Message != 'undefined') {
              ErrorMsg = error.error.Message;
            } else {
              ErrorMsg = error.statusText;
            }
            console.log(ErrorMsg);
            // this.notification.error(ErrorMsg);
          }
        );
      }
    } else {
      // report error
      this.vm.buttonsDisabled = false;
      const arr = this.vm.errorDescription.split('<br/>');
      arr.forEach((item) => {
        if (item) {
          // this.notification.warning(item);
          console.log(item);
        }
      });
    }
  }

  signature(signature) {
    this.vm.item.header.signature = signature;
    this.vm.getSignature = false;
    this.vm.tempCompleted = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  deleteSignature() {
    this.vm.item.header.signature = '';
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  syncItemAttachments(attachmentsArray: any) {
    // console.log('attachments array recvd in main');
    // console.log(attachmentsArray);

    // clear existing - will be put back because in array passed back in file upload array
    this.vm.item.attachments = [];

    // use the pieces needed for the api record
    attachmentsArray.forEach((x) => {
      this.vm.item.attachments.push({
        id: x.id,
        header_id: x.header_id,
        file_name: x.fileName,
        type: x.fileType,
        attachment: x.fileData,
        audit_sequence: 0
      });
    });
    // console.log('main vm array after sync to file upload data');
    // console.log(this.vm);
  }


  gotAnUploadFile(file: any) {
    // console.log('in got a file: ' + file);
    // console.log('in got a file: ');
  }


  generateInvoice() {
    const brClass = this;
    const messages: string[] = [
      $localize`:Generate Invoice for this Order? @@generateInvoiceForThisOrder:Generate Invoice for this Order?`,
      '',
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.vm.action = 'invoice';
        this.vm.item.header.completed = true;
        this.save();
      },
      () => {
        brClass.notification.showSuccessToast($localize`:Deliver and Generate Canceled @@deliverAndGenerateCanceled:Deliver and Generate Canceled`);
        return;
      }
    );
  }

  delete() {
    const brClass = this;
    const messages: string[] = [
      $localize`:Delete this record? @@deletethisrecord:Delete this record?`,
      '',
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.vm.item.header.deleted = true;
        this.save();
      },
      () => {
        brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  private getTableData(tableName, params, cacheName, fun) {
    // const cache = this.localStorage.StorageGet(cacheName);
    // if (cache) {
    //   fun(JSON.parse(cache));
    // }
    // else {

    this.tableDataService.getApiListData(tableName, params).subscribe((result: any) => {
      fun(result);
      // this.localStorage.StorageSet(cacheName, JSON.stringify(result));
      // console.log('table: ' + tableName + ' params: ' + params + ' return: ' + JSON.stringify(result));
    });
    // }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  addText() {
    let text = $localize`:Shipment @@shipment:Shipment` + ' ';
    if (this.vm.item.header.number) {
      text += $localize`:Number @@number:Number` + ': ' + this.vm.item.header.number + '      ';
    }

    let itemCount = 0;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      itemCount += Number(this.vm.item.detail[x].quantity);
    }

    text += $localize`:Item Count @@itemCount:Item Count` + ': ' + this.vm.itemCount;
    this.vm.addTextSignature = text;
    const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.font = '15px sans-serif';
      if (this.vm.addTextSignature) {
        ctx.fillText(this.vm.addTextSignature, 20, 270);
      }
    }
  }

  fetchOrderData(event: any) {
    this.orderId = event;
    this.loadOrder(this.orderId);
  }

  selectAccountModal(id) {
    // TODO
  }

  updateAccount() {
    // TODO
  }
}
