import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { NotificationService } from '../../../../../Services/notification.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { DataExportService } from '../../../../../Services/dataExport.service';
import {EmailPassDataServiceService} from '../../../../../Services/email-pass-data-service.service';
import {CustomFilterPipe} from '../../../../admin/custom-filter.pipe';
import {ConfirmDialogService} from '../../../../../Services/confirm-dialog.service';
import {ResponseModel} from '../../../../../DataModels/response-model';

@Component({
  selector: 'app-count-report',
  templateUrl: './count-report.component.html',
  styleUrls: ['./count-report.component.css']
})

export class CountReportComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('EmailContent', {static: false}) EmailContent: ElementRef;
  @ViewChild('ExcelExport', {static: false}) ExcelExport: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: NotificationService,
    private dateFilter: DateFilterService,
    private excelExporter: DataExportService,
    private datePipe: DatePipe,
    private titleService: Title,
    private cFilterPipe: CustomFilterPipe,
    private confirmDialogService: ConfirmDialogService,
    private emailData: EmailPassDataServiceService) {
    this.vm.title = $localize`:Inventory Count Report @@inventoryCountReport:Inventory Count Report`;
    this.vm.item = {};
    this.vm.tempitems = {};
    this.vm.countSearch = null;
    this.vm.showAdjustmensOnly = false;
    this.vm.systemCount = [];
    this.vm.systemCount.locations = [];
    this.vm.systemCount.locations.items = [];
    this.vm.exportToExcel = true;
  }

  itemBalances = [];
  vm: any = {
    report: [],
    request: [],
    exportToExcel: true,
    item: {},
    showFilters: false
  };
  isLoaded = false;
  counts: [];


  ngOnInit() {

    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    if (!this.vm.permissions.EditCounts && !this.vm.permissions.ViewCounts && !this.vm.permissions.Admin) {
      document.location.href = '/';
    }

    this.loadLookupOptions();
    this.loadData();
    // this.loadCount();
    // this.init();
    this.vm.buttonsDisabled = false;
    this.isLoaded = true;
    this.vm.report = this.vm.item;
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  ngAfterViewChecked() {
    const div = document.getElementById('excel-export');

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Count Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  loadData() {
    this.vm.inventoryItem = [];
    this.tableDataService.getApiListData('item', '?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.inventoryItem = result.Data;
    });

    this.vm.company = [];
    this.tableDataService.getApiListData('company', '?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });

    this.vm.currency = [];
    this.tableDataService.getApiListData('currency', '?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.currency = result;
    });

    this.vm.location = [];
    this.tableDataService.getApiListData('location', '?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.location = result.Data;
    });

    this.vm.category = [];
    this.tableDataService.getApiListData('item_category', '?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.category = result.Data;
    });
  }

  loadLookupOptions() {
    this.tableDataService.getApiListData('Count', '?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.counts = result.Data;
    });
  }

  loadCount() {
    if (!this.vm.request.count_id) {
      this.notification.info('Select a Count');
      return;
    }
    // delete this.vm.item.count;
    // delete this.vm.report;
    this.tableDataService.getTableData('Count', this.vm.request.count_id).subscribe((result: any) => {
      this.vm.item = result;
      this.vm.tempitems = this.vm.item;
      this.init();
    });
  }

  init(){
    if (!this.vm.request.count_id) {
      return;
    }

    if (this.vm.item.id === undefined) {
      this.vm.adjustment = {};
      this.vm.item = {count_date: new Date(), count: this.vm.inventoryItem };
    } else {
      this.vm.item.count_date = this.datePipe.transform(this.vm.item.count_date, 'MM/dd/yyyy');
      const tempCount = this.vm.item.count;
      this.vm.item.count = this.vm.inventoryItem;

      for (const [ x , value] of  Object.entries(this.vm.item.count)) {
        this.vm.item.count[x].item_id = this.vm.item.count[x].id;
        for (const [ y , val] of  Object.entries(tempCount)) {
          if (this.vm.item.count[x].item_id == tempCount[y].item_id) {
            this.vm.item.count[x].physical_count = tempCount[y].physical_count;
          }
        }
      }
      this.getSystemCount();
    }

    this.vm.invoiceSearch = '';

    if (this.vm.company.length === 1) {
      this.vm.item.company_id = this.vm.company[0].id;
      // changeCompany();
    }
    if (this.vm.currency.length === 1) {
      this.vm.item.currency_id = this.vm.currency[0].id;
    }
    if (this.vm.location.length === 1) {
      this.vm.item.location_id = this.vm.location[0].id;
    }
  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, 'Inventory-Count-Report.xlsx');
  }

  getSystemCount() {
    if (!this.vm.item.count || this.vm.item.category_id) {
      if (this.vm.item.category_id) {
        this.vm.item.count = this.FilterObject(this.vm.inventoryItem, { category_id: this.vm.item.category_id }, true);
      } else {
        this.vm.item.count = this.vm.inventoryItem;
      }
      for (const [ x , value] of  Object.entries(this.vm.item.count)) {
        this.vm.item.count[x].search_string = this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.count[x].id }, true)[0].code + ' ' + this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.count[x].id }, true)[0].name;
      }
    }

    this.vm.buttonsDisabled = false;
    const urlParamsJson = {
      // count_date: this.datePipe.transform(this.vm.item.count_date, 'MM/dd/yyyy'),
      count_date: new Date(this.vm.item.count_date).toISOString(),
      company_id: this.vm.item.company_id,
      location_id: this.vm.item.location_id,
      category_id: this.vm.item.category_id,
      // countId: this.vm.countId,
      // adjustmentsRequired: true,
      pageNumber: 0,
      pageSize: 999999
    };
    const sysCountParam: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getApiListData('count/system?' + sysCountParam).subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.vm.systemCount = data;

      for (const [y, value] of Object.entries(this.vm.item.count)) {
        this.vm.item.count[y].count = 0;
        for (const [x, val] of Object.entries(this.vm.systemCount)) {
          if (this.vm.systemCount[x].item_id == this.vm.item.count[y].id) {
            if (!this.vm.item.id && !this.vm.item.count[y].physical_count) {
              this.vm.item.count[y].physical_count = this.vm.systemCount[x].count;
            }
            // this.vm.item.count[y].count = this.vm.systemCount[x].count;
            this.vm.item.count[y].count = Math.round(this.vm.systemCount[x].count * 1000) / 1000;
            this.vm.item.count[y].cost = this.vm.systemCount[x].cost;
            break;
          }
        }
      }

      this.calcAdjustment();
      this.vm.buttonsDisabled = false;
      this.isLoaded = true;
      this.vm.report = this.vm.item;
    });
  }

  getItemHistory(item_id) {
    this.vm.itemHistoryId = item_id;
    this.vm.itemHistory = [];

    const urlParamsJson = {
      count_date: this.datePipe.transform(this.vm.item.count_date, 'MM/dd/yyyy'),
      company_id: this.vm.item.company_id,
      location_id: this.vm.item.location_id,
      item_id,
      pageNumber: 0,
      pageSize: 999999
    };

    const CountParam: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getApiListData('Count/system/history?' + CountParam).subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.vm.itemHistory = data;
    });
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  calcAdjustment() {
    this.vm.adjustment = {};
    this.vm.adjustment.batch = {};
    this.vm.adjustment.header = {};
    this.vm.adjustment.detail = [];
    this.vm.adjustment.batch.type_id = 1;
    this.vm.adjustment.header.header_date = this.vm.item.count_date;
    this.vm.adjustment.header.company_id = this.vm.item.company_id;
    this.vm.adjustment.header.location_id = this.vm.item.location_id;
    this.vm.adjustment.header.currency_id = this.vm.item.currency_id;
    this.vm.adjustment.header.description = this.vm.item.description;
    for (let x = 0; x < this.vm.item.count.length; x++) {
      if (isNaN(this.vm.item.count[x].physical_count) || this.vm.item.count[x].physical_count < 0) {
        this.vm.item.count[x].physical_count = '';
      }
      if (this.vm.item.count[x].count - this.vm.item.count[x].physical_count != 0 && this.vm.item.count[x].physical_count >= 0) {
        const adjQuantity = this.vm.item.count[x].physical_count - this.vm.item.count[x].count;
        let adjAmount = 0;
        if (this.vm.item.count[x].count && this.vm.item.count[x].count != 0) {
          adjAmount = Math.abs(Math.round(this.vm.item.count[x].cost / this.vm.item.count[x].count * 100) / 100);
        } else {
          adjAmount = this.vm.item.count[x].cost;
        }
        const adjDebitCredit = Math.round(adjAmount * adjQuantity * 100) / 100;
        if (adjDebitCredit != 0) {
          this.vm.adjustment.detail.push({
            item_id: this.vm.item.count[x].item_id,
            account_id: 'in',
            quantity: adjQuantity,
            amount: adjAmount,
            debit_credit: adjDebitCredit,
            sort_order: x
          });
          this.vm.adjustment.detail.push({
            item_id: this.vm.item.count[x].item_id,
            account_id: 'in-adj',
            quantity: 0 - adjQuantity,
            amount: adjAmount,
            debit_credit: 0 - adjDebitCredit,
            control_line: true,
            sort_order: x
          });
        }
      }
    }
  }

  FilterObject(value, filterObj, actual = false) {
    return this.cFilterPipe.transform(value, filterObj, actual);
  }

  print(){
    window.print();
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }


  clearFilters() {
    // do clear stuff for filters clear but we are not setting any at this time.
    this.notification.info('No Filters to Clear.');
  }
}
