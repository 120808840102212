import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {PermissionsService} from '../../../../../Services/permissions.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TableDataService} from '../../../../../Services/tableData.service';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseModel} from '../../../../../DataModels/response-model';
import {BsToastService} from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-item-balance',
  templateUrl: './item-balance.component.html',
  styleUrls: ['./item-balance.component.css']
})
export class ItemBalanceComponent implements OnInit, AfterViewInit {
  @Input() itemID: string;
  permissionsAdmin = true;
  balance = [];
  totalQuantity = 0;
  totalBalance = 0;
  vm: any = [];
  constructor(private table: TableDataService,
              private toast: BsToastService,
              public p: PermissionsService) {
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.getBalances();
  }

  private getBalances() {
    // this.getting = true;
    const urlParamsJson = {
      item_id: this.itemID,
      batch_type: 0,
      pageNumber: 0,
      pageSize: 999999,
    };

    const Params: string = this.encodeQueryData(urlParamsJson);
    // need filter by {'batch_type_id' : 4 } ???
    // console.log('params: ' + Params);
    this.table.getTableData('ItemBalances/batchtype', '?' + Params).subscribe((result) => {
        this.balance = result;
        // console.log('item bal: ' + JSON.stringify(result));
        this.vm.inventoryItem = result;

        // if there is a rec grab a name to display
        if (this.vm.inventoryItem.length > 0) {
          this.vm.itemName = this.vm.inventoryItem[0].item_name; // name is same for all items - just grab the first one.
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
        for (const item of this.balance) {
          this.totalQuantity += Number(item.quantity);
          this.totalBalance += Number(item.balance);
        }
      });
  }

  getItemHistory(companyId, locationId) {
    // console.log('companyId: ' + companyId + ' locationId: ' + locationId);
    const itemId = this.itemID;
    this.vm.itemHistoryId = itemId;

    const asOfDate = new Date().toISOString();
    const coId = companyId;

    const urlParamsJson = {
      company_id: coId,
      location_id: locationId,
      count_date: asOfDate,
      item_id: itemId,
      pageNumber: 0,
      pageSize: 999999
    };
    const CountParam: string = this.encodeQueryData(urlParamsJson);
    // console.log('params: ' + CountParam);
    this.table.getTableData('Count/system/history', '?' + CountParam).subscribe((result: ResponseModel) => {
      // console.log('hist: ' + JSON.stringify(result));
      this.vm.itemHistory = result.Data;
      this.vm.request = result.Data;
    });
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '' || data[d] === 0) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

}
