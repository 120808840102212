import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-shipments-list',
  templateUrl: './sales-shipments-list.component.html',
  styleUrls: ['./sales-shipments-list.component.css']
})
export class SalesShipmentsListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
