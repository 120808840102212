import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationAccessModel } from '../../../../DataModels/location-access-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-location-access-form',
  templateUrl: './location-access-form.component.html',
  styleUrls: ['./location-access-form.component.css'],
})
export class LocationAccessFormComponent implements OnInit {
  locationAccessID;
  locationAccess: LocationAccessModel;
  locationAccessForm: UntypedFormGroup;

  userList: Array<object>;
  locationList: Array<object>;

  batchlocked = 0;

  paid = false;
  showDelete = false;
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.locationAccessID = this.route.snapshot.params.id;
  }

  get f() {
    return this.locationAccessForm.controls;
  }

  createForm() {
    this.locationAccessForm = this.formBuilder.group({
      user_id: new UntypedFormControl(''),
      location_id: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Location Access @@locationAccess:Location Access`);
    this.LoadUsers();
    this.LoadEntities();
    if (this.locationAccessID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('locationAccess', this.locationAccessID).subscribe(
      (result) => {
        this.locationAccess = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Location Access @@errorloadingLocationAccess:Error loading Location Access`
        // );
        console.log(error + ' - ' + $localize`:Error loading Location Access @@errorloadingLocationAccess:Error loading Location Access`);
      },
      () => {
        this.locationAccessForm.patchValue(this.locationAccess);
      }
    );
  }

  private LoadUsers() {
    this.table.getApiListData('sys_user', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.userList = result.Data;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading users @@errorloadingUsers:Error loading users`);
        console.log(error + ' - ' + $localize`:Error loading users @@errorloadingUsers:Error loading users`);
      },
      () => {
      }
    );
  }

  private LoadEntities() {
    this.table.getApiListData('location', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.locationList = result.Data;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Location @@errorloadingLocation:Error loading Location`);
        console.log(error + ' - ' + $localize`:Error loading Location @@errorloadingLocation:Error loading Location`);
      },
      () => {
      }
    );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.locationAccessForm.reset();
    }
  }

  resetForm() {
    this.locationAccessForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/location-access-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete location access? @@areYouSureYouWantToDeleteLocationAccess:Are you sure you want to delete location access?`,
      this.locationAccess.location_id,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('locationAccess', this.locationAccessID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.locationAccessForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.locationAccess = Object.assign({}, this.locationAccessForm.value);
    if (this.locationAccessID) {
      if (this.locationAccessForm.invalid === false) {
        this.table.patch('locationAccess', this.locationAccessID, this.locationAccess).then((res) => {
          if (saveClose) {
            this.onClose();
          }
        });
      }
    } else {
      this.table.postWithPromise('locationAccess', this.locationAccess).then((res) => {
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.locationAccessID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });
      this.submitted = false;
      return true;
    }
  }
}
