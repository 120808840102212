import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../Services/storage.service';
import { TableDataService } from '../../../../Services/tableData.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { AppConfigService } from '../../../../Services/app-config.service';
import { DecimalNewPipe } from '../../../common/DecimalNewPipe';
import { DatePipe, ViewportScroller } from '@angular/common';
import { ResponseModel } from '../../../../DataModels/response-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../../Services/ngbpagination-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import { BsToastService} from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styleUrls: ['./transfer-list.component.css'],
})
export class TransferListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private vps: ViewportScroller,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    this.vm.batchId = '';
    this.vm.batchesHidden = true;
    this.vm.transferFilter = { sort: null, text: null };
    this.vm.shipmentFilter = { sort: null };
    this.vm.batches = {};
    this.getPagerStatus().then();
  }

  vm: any = {
    list: {},
    title: 'Transfers',
    Preferences: [],
    permissions: [],
    formRoute: '/inventory/transfer/form',
  };
  transferCount = 0;
  isLoaded = false;

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  isDeliverButtonInitiated = false;

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    // this.vm.numberOfResults = this.pageSize;
    this.init();
    this.search();
    this.getBatches(false);
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  scrollTo(anchor: string): void {
    if (!this.vm.batchesHidden) {
      this.vps.scrollToAnchor(anchor);
    }
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([2, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  init() {
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    if (!this.vm.permissions.EditTransfers && !this.vm.permissions.ViewTransfers && !this.vm.permissions.Admin) {
      document.location.href = '/';
    }

    // this.vm.owingSelected = this.vm.totalSelected = 0;
    // this.vm.transferToDateOpen = this.vm.transferFromDateOpen = false;
    //
    // if (this.vm.undeliveredTransfers == 'true') {
    //   this.vm.undeliveredTransfers = true;
    // }
  }

  signature(signature) {
    this.vm.linkedSignature = signature;
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    // this.save();
  }

  convertToNumbers() {
    for (const [x, value] of Object.entries(this.vm.list)) {
      this.vm.list[x].detail_total = Number(this.vm.list[x].detail_total);
      this.vm.list[x].payment_applied_total = Number(this.vm.list[x].payment_applied_total);
      this.vm.list[x].audit_sequence = Number(this.vm.list[x].audit_sequence);
    }
  }

  openTransferToDate() {
    this.vm.transferToDateOpen = true;
  }

  openTransferFromDate() {
    this.vm.transferFromDateOpen = true;
  }

  // selectAll() {
  //   for (const list of this.vm.list) {
  //     (document.getElementById(list.id) as HTMLInputElement).checked = this.vm.selectAllItems;
  //   }
  //   this.selectItem();
  // }
  //
  // selectItem() {
  //   this.vm.owingSelected = this.vm.totalSelected = 0;
  //   let firstEntity = '';
  //   document.getElementById('pay-btn').classList.remove('d-none');
  //   document.getElementById('owingSelected').classList.remove('d-none');
  //   this.vm.itemsSelected = false;
  //   for (const [x, value] of Object.entries(this.vm.list)) {
  //     if ((document.getElementById(this.vm.list[x].id) as HTMLInputElement).checked) {
  //       if (firstEntity == '') {
  //         this.vm.itemsSelected = true;
  //         firstEntity = this.vm.list[x].entity_name;
  //       } else if (firstEntity != this.vm.list[x].entity_name) {
  //         document.getElementById('pay-btn').classList.add('d-none');
  //         document.getElementById('owingSelected').classList.add('d-none');
  //       }
  //       this.vm.totalSelected += Number(this.vm.list[x].header_total);
  //       this.vm.owingSelected += Number(this.vm.list[x].header_owing);
  //       document.getElementById(this.vm.list[x].id + '-tr').classList.add('success');
  //     } else {
  //       document.getElementById(this.vm.list[x].id + '-tr').classList.remove('success');
  //     }
  //   }
  // }

  clearSearch() {
    this.vm.transferSearch = this.vm.transferFromDate = this.vm.transferToDate = this.vm.undeliveredTransfers = '';
    // this.vm.transferNumberOfResults = '25';
    this.vm.list = [];
    this.isLoaded = false;
    this.search();
  }

  search() {
    this.vm.searching = true;
    this.vm.batchId = '';
    this.vm.tempDescription = '';
    let paramString = '';

    // const tempBatchType = '15';
    //
    // let paramString = '';
    // paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    // paramString += '&search=' + (this.vm.transferSearch ? this.vm.transferSearch : '');
    // paramString +=
    //   '&fromDate=' + (this.vm.transferFromDate ? this.dateFilter.GetDateFormat(this.vm.transferFromDate) : '');
    // paramString += '&toDate=' + (this.vm.transferToDate ? this.dateFilter.GetDateFormat(this.vm.transferToDate) : '');
    // paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    // paramString +=
    //   '&transferNumberOfResults=' + (this.vm.transferNumberOfResults ? this.vm.transferNumberOfResults : '');
    // paramString += '&includePosted=' + (this.vm.includePosted ? this.vm.includePosted : '');
    //
    // this.tableDataService.getTableData('t_batch?' + paramString).subscribe((result: any[]) => {
    //   this.vm.batches = result;
    // });
    paramString = '';
    paramString += 'search=' + (this.vm.transferSearch ? this.vm.transferSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.transferFromDate ? this.dateFilter.GetDateFormat(this.vm.transferFromDate) : '');
    paramString += '&toDate=' + (this.vm.transferToDate ? this.dateFilter.GetDateFormat(this.vm.transferToDate) : '');
    paramString += '&undeliveredTransfers=' + (this.vm.undeliveredTransfers ? this.vm.undeliveredTransfers : '');
    // paramString +=
    //   '&transferNumberOfResults=' + (this.vm.transferNumberOfResults ? this.vm.transferNumberOfResults : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('ListTransfers?' + paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = [];
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;

        this.transferCount = this.vm.list.length;
        // this.convertToNumbers();
        this.vm.searching = false;
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => {
        // this.toastr.error(error.message, $localize`:Error loading Bank Data @@errorLoadingBankData:Error loading Bank Data`, {
        //   timeOut: 1000
        // });
        console.log(error);
      },
      () => {}
    );
  }

  getUndeliveredTransfers(event): void {
    if (event) {
      this.vm.searching = true;
      this.vm.batchId = '';
      this.vm.tempDescription = '';
      let paramString = '';
      this.getBatches(true);
      // const tempBatchType = '15';
      //
      // let paramString = '';
      // paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
      // paramString += '&search=' + (this.vm.transferSearch ? this.vm.transferSearch : '');
      // paramString +=
      //   '&fromDate=' + (this.vm.transferFromDate ? this.dateFilter.GetDateFormat(this.vm.transferFromDate) : '');
      // paramString += '&toDate=' + (this.vm.transferToDate ? this.dateFilter.GetDateFormat(this.vm.transferToDate) : '');
      // paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
      // paramString += '&undeliveredTransfers=true';
      // paramString += '&includePosted=' + (this.vm.includePosted ? this.vm.includePosted : '');
      //
      // this.tableDataService.getTableData('t_batch?' + paramString).subscribe((result: any[]) => {
      //   this.vm.batches = result;
      // });

      // paramString = '';
      paramString += 'search=' + (this.vm.transferSearch ? this.vm.transferSearch : '');
      paramString +=
        '&fromDate=' + (this.vm.transferFromDate ? this.dateFilter.GetDateFormat(this.vm.transferFromDate) : '');
      paramString += '&toDate=' + (this.vm.transferToDate ? this.dateFilter.GetDateFormat(this.vm.transferToDate) : '');
      paramString += '&undeliveredTransfers=true';
      paramString +=
        '&transferNumberOfResults=' + (this.vm.transferNumberOfResults ? this.vm.transferNumberOfResults : '');
      paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.tableDataService.getApiListData('ListTransfers?' + paramString).subscribe(
        (result: ResponseModel) => {
          this.vm.list = result.Data;
          this.totalRecords = result.totalRecords;
          this.paginate(false);
          this.dtTrigger.next('');
          this.dataTableReady = true;

          // this.transferCount = this.vm.list.length;
          // this.convertToNumbers();
          // this.vm.searching = false;
          this.isLoaded = true;
        },
        (error: any) => {
          // this.notification.error(error.statusText);
          console.log(error.statusText);
        }
      );
    } else {
      this.getPagedApiData();
    }
  }

  filter(filter) {
    this.vm.transferFilter = Object.assign(this.vm.transferFilter, filter);
    // this.localStorage.StorageSet('transferFilter', JSON.stringify(this.vm.transferFilter));
  }

  getBatchItems(batchId) {
    this.vm.tempDescription = '';
    this.vm.batchId = batchId;
    this.vm.searching = true;
    const obj: any = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true);
    if (obj.length == 0) {
      obj[0] = { description: '', locked: '' };
    }
    this.vm.tempDescription = (obj) ? obj[0].description : '';
    this.vm.tempBatchLocked = (obj) ? obj[0].locked : false;
    let paramString = '';
    paramString += 'batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
    paramString += '&batchType=' + 15;
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.transferCount = result.totalRecords;
      this.convertToNumbers();
      this.vm.searching = false;
    });
  }

  deleteBatch(batchId: string) {
    // this.tableDataService.deleteTableDataById('t_batch', batchId).subscribe(
    //   (item: any) => {
    //     this.getBatches(false);
    //   },
    //   (error: any) => {
    //     // this.vm.buttonsDisabled = false;
    //     this.notification.error(error);
    //     console.log(error);
    //   }
    // );
    // let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
      '',
    ];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.tableDataService.deleteTableDataById('t_batch', batchId).subscribe(
          result => {
            // retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.showErrorToast($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            // this.onClose();
            // alert('hello');
          });
      }, () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }

  getBatches(undelivered: boolean) {
    this.vm.searching = true;
    const tempBatchType = '15';

    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&search=' + (this.vm.transferSearch ? this.vm.transferSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.transferFromDate ? this.dateFilter.GetDateFormat(this.vm.transferFromDate) : '');
    paramString += '&toDate=' + (this.vm.transferToDate ? this.dateFilter.GetDateFormat(this.vm.transferToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    paramString +=
      '&transferNumberOfResults=' + (this.vm.transferNumberOfResults ? this.vm.transferNumberOfResults : '');
    paramString += '&includePosted=' + (this.vm.includePosted ? this.vm.includePosted : '');
    paramString += undelivered ? '&undeliveredTransfers=true' : '';

    this.tableDataService.getTableData('t_batch?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      if (this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true).length == 1) {
        this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
        this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
      }
      this.vm.searching = false;
    });
  }

  newBatch() {
    if (this.vm.permissions.Admin || this.vm.permissions.EditTransfers) {
      const newBatch = { type_id: 15 };

      this.tableDataService.post('t_batch', newBatch).subscribe(
        (item: any) => {
          this.getBatches(false);
          this.vm.batchId = item.id;
          this.vm.tempDescription = $localize`:Batch @@batch:Batch`;
          // this.notification.success($localize`:Success @@success:Success`);
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error);
          console.log(error);
        }
      );
    }
  }

  updateBatch() {
    if (this.vm.permissions.Admin || this.vm.permissions.EditTransfers) {
      this.vm.buttonsDisabled = true;
      const changes = { description: this.vm.tempDescription };
      this.tableDataService.patch('t_batch', this.vm.batchId, changes).then(
        (item: any) => {
          if (item) {
            // this.notification.success(this.vm.tempDescription);
          }
          this.getBatches(false);
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error);
          console.log(error);
        }
      );
    }
  }

  postBatch(batchId) {
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableTransfers) {
      this.vm.buttonsDisabled = true;
      const changes = { locked: 'true' };

      this.tableDataService.patch('t_batch', batchId, changes).then(
        (item: any) => {
          if (item) {
            // this.notification.success($localize`:Success @@success:Success`);
          }
          this.getBatches(false);
          this.vm.buttonsDisabled = false;
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error.description);
          console.log(error);
        }
      );
    }
  }

  completeTransfer(id) {
    if (this.vm.permissions.Admin || this.vm.permissions.EditTransfers) {
      this.vm.buttonsDisabled = true;
      const changes = { completed: 'true' };
      this.tableDataService.patch('t_header', id, changes).then(
        (item: any) => {
          if (item) {
            // this.notification.success($localize`:Completed @@completed:Completed`);
          }
          // this.search();
          this.getPagedApiData();
          this.vm.buttonsDisabled = false;
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error);
          console.log(error);
        }
      );
    }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  onNavigate(aID: string) {
    this.router.navigateByUrl(this.vm.formRoute + '?transferId=' + aID).then();
  }

  hideBatches() {
    this.vm.batchesHidden = !this.vm.batchesHidden;
    this.vm.Preferences.ShowBatches = !this.vm.batchesHidden;
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    this.vm.searching = true;
    this.vm.batchId = '';
    this.vm.tempDescription = '';
    const tempBatchType = '15';

    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&search=' + (this.vm.transferSearch ? this.vm.transferSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.transferFromDate ? this.dateFilter.GetDateFormat(this.vm.transferFromDate) : '');
    paramString += '&toDate=' + (this.vm.transferToDate ? this.dateFilter.GetDateFormat(this.vm.transferToDate) : '');
    paramString += '&undeliveredTransfers=false';
    paramString +=
      '&transferNumberOfResults=' + (this.vm.transferNumberOfResults ? this.vm.transferNumberOfResults : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;
    this.tableDataService.getApiListData('ListTransfers?' + paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.transferCount = this.vm.list.length;
        this.convertToNumbers();
        this.vm.searching = false;
        this.isLoaded = true;
      },
      (error: any) => {
        // this.notification.error(error.statusText);
        console.log(error.statusText);
      }
    );
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.transferSearch = value.searchTerm;
    this.vm.transferFromDate = value.fromDate;
    this.vm.transferToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.transferSearch = value.searchTerm;
    this.vm.transferFromDate = value.fromDate;
    this.vm.transferToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
