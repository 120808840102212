import {Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-modal-new-address',
  templateUrl: './modal-new-address.component.html',
  styleUrls: ['./modal-new-address.component.css']
})
export class ModalNewAddressComponent implements OnInit, OnDestroy {

  @Input() vm: any;
  @Output() saveNewAddressEmit = new EventEmitter<string>();
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  saveNewAddress(){
    this.saveNewAddressEmit.emit();
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
