import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AppConfigService } from '../../../../Services/app-config.service';
import { TableDataService } from '../../../../Services/tableData.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ResponseModel } from '../../../../DataModels/response-model';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../../Services/ngbpagination-config.service';
import { BsToastService } from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-bank-reconciliation-list',
  templateUrl: './bank-reconciliation-list.component.html',
  styleUrls: ['./bank-reconciliation-list.component.css'],
})
export class BankReconciliationListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private table: TableDataService,
    private router: Router,
    private toastr: BsToastService,
    private titleService: Title,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private dateFilter: DateFilterService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    // ngb paginate
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  bankReconciliationList: Array<any>;
  bankReconciliationCount = 0;
  isLoaded = false;
  serverSearchTerm = '';
  vm: any = {};

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Bank Reconciliation List @@bankReconciliationList:Bank Reconciliation List`);
    // this.getPagedApiData();
  }

  ngOnInit() {
    this.vm.searchHidden = false;
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.getData();
  }

  // datatable stuff
  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([2, 'asc']);
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }

    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  getData() {
    let paramString = '';
    paramString = 'search=' + (this.vm.bankRecSearch ? this.vm.bankRecSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.bankRecFromDate ? this.dateFilter.GetDateFormat(this.vm.bankRecFromDate) : '');
    paramString += '&toDate=' + (this.vm.bankRecToDate ? this.dateFilter.GetDateFormat(this.vm.bankRecToDate) : '');
    paramString += '&pageNumber=' + (this.page - 1);
    paramString += '&pageSize=' + this.pageSize;

    this.table.getApiListData('bank_reconciliation', '?' + paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;
        this.bankReconciliationCount = result.totalRecords;
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {}
    );
  }

  onNavigate(aID: string) {
    this.router.navigate(['/transactions/bank-reconciliation/bank-reconciliation-form', { id: aID }]).then();
  }

  // Server side paging ---------------------------------------------------------------------------------

  getPagedApiData() {
    let paramString = '';
    paramString = 'search=' + (this.vm.bankRecSearch ? this.vm.bankRecSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.bankRecFromDate ? this.dateFilter.GetDateFormat(this.vm.bankRecFromDate) : '');
    paramString += '&toDate=' + (this.vm.bankRecToDate ? this.dateFilter.GetDateFormat(this.vm.bankRecToDate) : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.table.getApiListData('bank_reconciliation', '?' + paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.isLoaded = true;
        this.dataTableReady = true;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // this.toastr.success('', 'Bank Reconciliation Data Loaded', {
        //   timeOut: 1000
        // });
      }
    );
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.bankRecSearch = value.searchTerm;
    this.vm.bankRecFromDate = value.fromDate;
    this.vm.bankRecToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.bankRecSearch = value.searchTerm;
    this.vm.bankRecFromDate = value.fromDate;
    this.vm.bankRecToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
