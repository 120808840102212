import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnChanges, OnInit, ViewChild} from '@angular/core';
import {StorageService} from '../../../../Services/storage.service';
import {TableDataService} from '../../../../Services/tableData.service';
import {stringify} from 'querystring';
import {AppConfigService} from '../../../../Services/app-config.service';
import {Title} from '@angular/platform-browser';
import {DateFilterService} from '../../../../Services/dateFilter.service';
import {FilterPipe} from 'ngx-filter-pipe';
import {NotificationService} from '../../../../Services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailService} from '../../../../Services/email.service';
import {DataExportService} from '../../../../Services/dataExport.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {EmailPassDataServiceService} from '../../../../Services/email-pass-data-service.service';

@Component({
  selector: 'app-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: ['./income-statement.component.css']
})
export class IncomeStatementComponent implements OnInit, AfterViewInit, AfterViewChecked{
  @ViewChild('ExcelExport', {static: false}) ExcelExport: ElementRef;
  private rptName: string;
  lapCount = 0;
  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      location_group_id: false,
      columns: '',
      company_id: null,
      location_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      account_type_id: null,
      account_parent_id: null,
      account_subtotal_id: null
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: ''
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    rfType: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    priorFromDate: 0,
    ToEmailError: null,
    showEmail: true,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    monthCount: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    pdfFileName: null,
    totalCurrentRevenue: null,
    totalPriorRevenue: null,
    marginCurrent: null,
    marginPrior: null,
    fromDateError: null,
    accountTypeName: null,
    accountParentName: null,
    accountSubtotalName: null,
    accountSubtotal: null,
    accountParents: null,
    accountType: null,
    companyName: null,
    locationName: null,
    locationGroupName: null,
    rf1Name: null,
    rf2Name: null,
    rf3Name: null,
    rf4Name: null,
    rf5Name: null,
    accountInput: null,
    entityInput: null,
    Preferences: null,
    permissions: null,
    inventoryItem: [],
    entityState: [],
    entityCity: [],
    entityType: [],
    user: [],
    paymentType: [],
    bank: [],
    entityFilter: [],
    entity: [],
    inventoryItemFilter: [],
    exportToExcel: true,
  };

  constructor(public localStorage: StorageService,
              public tableDataService: TableDataService,
              private titleService: Title,
              public appConfigService: AppConfigService,
              public dateFilter: DateFilterService,
              public notification: NotificationService,
              public route: ActivatedRoute,
              public excelExporter: DataExportService,
              public aEmail: EmailService,
              private emailData: EmailPassDataServiceService,
              private router: Router) {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.toDate !== undefined) {
        this.vm.request.toDate = params.toDate;
        this.vm.showSpecificDates = true;
      }
      if (params.fromDate !== undefined) {
        this.vm.request.fromDate = params.fromDate;
        this.vm.showSpecificDates = true;
      }
      if (this.vm.request.toDate && this.vm.request.fromDate) {
        this.update();
      }
      this.vm.request.columns = 'actual';
    });

    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    // this.EmailInit();
    this.rptName = $localize`:Income Statement @@incomeStatement:Income Statement`;
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  ngAfterViewChecked() {
    const div = document.getElementById('excel-export');
     // this.localStorage.StorageSet('reportData', div.outerHTML);

    if (this.lapCount === 1){
      setTimeout(() => {
        this.emailData.clearAllEmailPassData();
        this.emailData.emailInfo.aRecordId = '';
        this.emailData.emailInfo.aMessage = '';
        this.emailData.emailInfo.aEntity = '';
        this.emailData.emailInfo.aBatchTypeId = '99';
        this.emailData.emailInfo.aReportString = div.outerHTML;
        this.emailData.emailInfo.aReportOrDocName = 'Income Statement';
        this.emailData.loadEmailDataToPassMailer();
      }, 3000);
     // console.log(div.outerHTML);
      this.lapCount++;
    }
  }

  setEmailData() {
    const div = document.getElementById('excel-export');
    // this.localStorage.StorageSet('reportData', div.outerHTML);
    // console.log(div.outerHTML);
    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Income Statement';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, this.rptName + '.xlsx');
  }
  emailReport(toEmail) {

    // if (this.vm.report.length > 0) {
    //   this.setEmailData();
    //   console.log('here');
    // }
    // const tempPdfHtml = this.ExcelExport.nativeElement.innerHTML;
    // this.vm.email.toemail = toEmail;
    // this.vm.email.PdfHtml = tempPdfHtml;
    // this.vm.email.Subject = this.rptName + ' ' + this.vm.request.toDate;
    // this.aEmail.sendEmail(this.vm.email);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
    this.tableDataService.getTableData('currency?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.currency = result.Data;
    });
    this.tableDataService.getTableData('location?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.location = result.Data;
      // console.log('locs: ' +  this.vm.location);
    });
    this.tableDataService.getTableData('location_group?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.locationGroup = result.Data;
    });
    this.tableDataService.getTableData('reporting_field_type?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rfType = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf1&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf1 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf2&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf2 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf3&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf3 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf4&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf4 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf5&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf5 = result.Data;
    });
  }

  EmailInit() {
    // this.vm.email.toemail = this.localStorage.StorageGet('ToEmail');
  }

  calcTotals() {
    this.vm.totalDebits = this.vm.totalCredits = this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
    for (const [x, value] of Object.entries(this.vm.report)) {
      if (this.vm.report[x].title === 'Revenue') {
        this.vm.totalCurrentRevenue = Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.netIncomeCurrent += this.vm.totalCurrentRevenue;
        this.vm.totalPriorRevenue = Number(this.vm.report[x].totalPriorPeriod);
        this.vm.netIncomePrior += this.vm.totalPriorRevenue;
      } else {
        if (this.vm.report[x].title === 'Costs') {
          this.vm.marginCurrent = this.vm.totalCurrentRevenue - Number(this.vm.report[x].totalCurrentPeriod);
          this.vm.marginPrior = this.vm.totalPriorRevenue - Number(this.vm.report[x].totalPriorPeriod);
        }
        this.vm.netIncomeCurrent -= Number(this.vm.report[x].totalCurrentPeriod);
        this.vm.netIncomePrior -= Number(this.vm.report[x].totalPriorPeriod);
      }
    }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  update() {
    this.lapCount++;

    this.vm.report = [];
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.fromDate) {
      errorDescription += $localize`:A from date is required. @@afromdateisrequired:A from date is required.` + ' ';
      this.vm.fromDateError = true;
    }
    if (!this.vm.request.toDate) {
      errorDescription += $localize`:A To date is required. @@aTodateisrequired:A To date is required.` + ' ';
      this.vm.toDateError = true;
    }

    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);

    if (tempToDate < tempFromDate) {
      errorDescription += $localize`:From date must be less than the To date @@fromdatemustbelessthantheTodate:From date must be less than the To date`;
      this.vm.toDateError = true;
      this.vm.fromDateError = true;
    }
    if (errorDescription) {
      this.notification.warning(errorDescription);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;

      let paramString = '';
      if (this.vm.request.fromDate) {
        paramString += '&fromDate=' + this.vm.request.fromDate;
      }
      if (this.vm.request.toDate) {
        paramString += '&toDate=' + this.vm.request.toDate;
      }
      if (this.vm.request.company_id) {
        paramString += '&company_id=' + this.vm.request.company_id;
      }
      if (this.vm.request.location_id) {
        paramString += '&location_id=' + this.vm.request.location_id;
      }
      if (this.vm.request.location_group_id) {
        paramString += '&location_group_id=' + this.vm.request.location_group_id;
      }
      if (this.vm.request.rf_id_1) {
        paramString += '&rf_id_1=' + this.vm.request.rf_id_1;
      }
      if (this.vm.request.rf_id_2) {
        paramString += '&rf_id_2=' + this.vm.request.rf_id_2;
      }
      if (this.vm.request.rf_id_3) {
        paramString += '&rf_id_3=' + this.vm.request.rf_id_3;
      }
      if (this.vm.request.rf_id_4) {
        paramString += '&rf_id_4=' + this.vm.request.rf_id_4;
      }
      if (this.vm.request.rf_id_5) {
        paramString += '&rf_id_5=' + this.vm.request.rf_id_5;
      }

      if (paramString[0] === '&') {
        paramString = paramString.substring(1);
      }

      this.tableDataService.getTableData('income_statement', '?' + paramString).subscribe((result: any[]) => {
        this.vm.report = result;
        // console.log(JSON.stringify(result));
        this.vm.filtered = false;
        this.vm.showFilters = this.vm.buttonsDisabled = false;
        this.calcTotals();
        this.lapCount = 0;
      });
      // return false;
    }
  }

  parseInt(val) {
    return parseInt(val, 10);
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear(clear = false) {
    let inventoryItemInput: any;
    this.vm.report = [];
    this.vm.buttonsDisabled = false;
    if (clear) {
      this.vm.showEmail = false;
      this.vm.accountInput = this.vm.entityInput = inventoryItemInput = '';
      this.vm.report = [];
      this.vm.request = {
        toDate: null,
        fromDate: null,
        location_group_id: false,
        columns: '',
        company_id: null,
        location_id: null,
        rf_id_1: null,
        rf_id_2: null,
        rf_id_3: null,
        rf_id_4: null,
        rf_id_5: null,
        account_type_id: null,
        account_parent_id: null,
        account_subtotal_id: null
      };
    }
  }

  Print() {
    return window.print();
  }
}
