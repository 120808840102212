import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataService } from '../../../../Services/tableData.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { StorageService } from '../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import {BsToastService} from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-reporting-field-form',
  templateUrl: './reporting-field-form.component.html',
  styleUrls: ['./reporting-field-form.component.css'],
})
export class ReportingFieldFormComponent implements OnInit, AfterViewInit {
  ReportingFieldForm: UntypedFormGroup;
  reportingFieldId;
  vm: any = {
    title: 'Reporting Field',
    item: [],
    rfType: [],
    defaultItem: [],
    auditTrail: [],
    buttonsDisabled: false,
    Preferences: [],
    permissions: [],
    mainListPage: 'admin/reporting-field-list',
    formInitiated: false,
  };
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private decimal: DecimalPipe,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private confirmDialogService: ConfirmDialogService
  ) {}

  ngOnInit() {
    this.vm.auditTrail = [];
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.formInit();
    this.dataInit();
  }

  dataInit() {
    this.reportingFieldId = this.route.snapshot.params.id;
    if (this.reportingFieldId) {
      this.vm.item.id = this.reportingFieldId;
      this.buttonConfig.reset = false;
    }

    if (this.reportingFieldId !== undefined) {
      this.tableDataService.getTableData('reporting_field/' + this.reportingFieldId).subscribe((result: any[]) => {
        this.vm.item = result;
        this.vm.title = this.vm.title + ' : ' + this.vm.item.name;
        this.localStorage.StorageSet('formDataReportingField', JSON.stringify(result));
        // this.titleService.setTitle(this.vm.title);
        this.titleService.setTitle($localize`:Reporting Field@@reportingField:Reporting Field`);
        this.ReportingFieldForm.controls.item_code.setValue(this.vm.item.code);
        this.ReportingFieldForm.controls.item_name.setValue(this.vm.item.name);
        this.ReportingFieldForm.controls.item_type_id.setValue(this.vm.item.type_id);
        this.ReportingFieldForm.controls.item_active.setValue(this.vm.item.active);
        // });
        // this.tableDataService.getTableData('reporting_field?id=' + this.reportingFieldId).subscribe((result: any[]) => {
        //   this.vm.auditTrail = result;
        // });
        // }
      });
    }
    this.tableDataService
      .getApiListData('reporting_field_type', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: any) => {
        this.vm.rfType = result.Data;
        this.vm.formInitiated = true;
        if (this.vm.rfType.length == 1) {
          this.vm.item.type_id = this.vm.rfType[0].id;
        }
      });
  }

  get f() {
    return this.ReportingFieldForm.controls;
  }

  formInit() {
    this.ReportingFieldForm = this.formBuilder.group({
      item_code: new UntypedFormControl('', Validators.required),
      item_name: new UntypedFormControl('', Validators.required),
      item_type_id: new UntypedFormControl('', Validators.required),
      item_active: new UntypedFormControl('true'),
    });
  }

  ngAfterViewInit() {
    // this.titleService.setTitle(this.vm.title);

  }

  saveClose() {
    if (this.onSave(true)) {
      this.router.navigate(['admin/reporting-field-list']).then();
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.ReportingFieldForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete Reporting Field? @@areYouSureYouWantToDeleteReportingField:Are you sure you want to delete reporting field?`,
      this.vm.item.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.tableDataService.deleteTableDataById('reporting_field', this.reportingFieldId).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled@@deleteCanceled:Delete Canceled`);
      }
    );
  }

  resetForm() {
    this.ReportingFieldForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/reporting-field-list']).then();
  }

  onSave(saveClose: boolean): boolean {
    this.submitted = true;
    if (this.ReportingFieldForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    const formData = this.ReportingFieldForm.value;

    this.vm.buttonsDisabled = true;
    const errorMessage = '';
    if (!this.vm.item.id) {
      this.vm.item.code = formData.item_code;
      this.vm.item.name = formData.item_name;
      this.vm.item.type_id = formData.item_type_id;
      this.vm.item.active = formData.item_active;
      this.vm.item.code = this.vm.item.code.toUpperCase();
      // this.ReportingFieldForm.patchValue(this.vm.item);
      this.vm.item = {
        code: this.vm.item.code,
        name: this.vm.item.name,
        type_id: this.vm.item.type_id,
        active: this.vm.item.active,
      };
      this.tableDataService.post('reporting_field', this.vm.item).subscribe(
        (item: any) => {
          let apiReturn: any = null;
          if (typeof item === 'object') {
            apiReturn = JSON.parse(JSON.stringify(item));
          }
          this.vm.item.id = apiReturn.id;
          if (saveClose) {
            this.onClose();
          }
          this.router.navigateByUrl('admin/reporting-field-form;id=' + item.id).then();
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.buttonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message !== 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          console.log(ErrorMsg);
        });
      return true;
    } else {
      this.vm.item.code = formData.item_code;
      this.vm.item.name = formData.item_name;
      this.vm.item.type_id = formData.item_type_id;
      this.vm.item.active = formData.item_active;

      this.tableDataService
        .patch('reporting_field', this.vm.item.id, this.vm.item)
        .then((item: any) => {
          if (saveClose) {
            this.onClose();
          }
        })
        .catch((msg: { description: any[] }) => {
          this.vm.buttonsDisabled = false;
          if (msg.description !== undefined) {
            console.log(msg.description);
            this.vm.buttonsDisabled = false;
            console.log(errorMessage);
          }
          this.submitted = false;
          return true;
        });
    }
  }
}
