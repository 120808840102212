import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router) { }

  printDocument(componentName: string, documentId: string, companyId: string, subLedger: number) {
    // console.log('print service called with these params: ' + componentName + ' : ' + documentId + ' : ' + companyId + ' : ' + subLedger);
    this.isPrinting = true;

    const link = this.router.serializeUrl(this.router.createUrlTree(['/transactions/' + componentName], { queryParams: { documentId, companyId, subLedger}}));
    window.open(link, '_blank');

  }

}


