import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { PermissionsService } from '../../../../Services/permissions.service';
import { LocationDetailComponent } from '../resources/location-detail/location-detail.component';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.css'],
})
export class LocationFormComponent implements OnInit, AfterViewInit {
  locationID;
  showDetails = true;
  showItems = false;

  @ViewChild(LocationDetailComponent, { static: false }) detail: LocationDetailComponent;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };
  constructor(
    private table: TableDataService,
    private toast: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private p: PermissionsService,
    private titleService: Title
  ) {
    this.locationID = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Location @@Location:Location`);
    this.showDetailsClick();
    if(this.locationID) {
      this.buttonConfig.reset = false;
    }
  }

  ngAfterViewInit() {}

  showDetailsClick() {
    this.AllFalse();
    this.showDetails = true;
  }

  showItemsClick() {
    this.AllFalse();
    // vm.getHistory()
    this.showItems = true;
  }

  AllFalse() {
    this.showDetails = false;
    this.showItems = false;
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.detail.Reset();
    }
  }

  deleteItem() {
    this.detail.deleteItem();
  }

  resetForm() {
    this.detail.Reset();
  }

  onSave(saveClose: boolean) {
    return this.detail.onSave(saveClose);
  }

  onClose() {
    this.router.navigate(['admin/location-list']).then();
  }
}
