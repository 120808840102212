import {Component, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import { StorageService } from '../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { NotificationService } from '../../../../Services/notification.service';
import { TableDataService } from '../../../../Services/tableData.service';
import { AppConfigService } from '../../../../Services/app-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {PagerStateModel} from '../../../../DataModels/pager-state-model';
import {PagerStateService} from '../../../../Services/pager-state.service';
import {DatatableConfigService} from '../../../../Services/datatable-config.service';
import {NgbpaginationConfigService} from '../../../../Services/ngbpagination-config.service';

@Component({
  selector: 'app-reporting-field-type-list',
  templateUrl: './reporting-field-type-list.component.html',
  styleUrls: ['./reporting-field-type-list.component.css']
})
export class ReportingFieldTypeListComponent implements OnInit, OnDestroy, AfterViewInit {
  ReportingFieldTypeCount = 0;
  title: string;
  isLoaded = false;

  constructor(
    private localStorage: StorageService,
    private titleService: Title,
    private toastr: NotificationService,
    private router: Router,
    private tableDataService: TableDataService,
    private appConfig: AppConfigService,
    private dateFilter: DateFilterService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService) {
      this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  // datatables stuff
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;
  vm: any = [];

  ngOnInit() {
    this.title = $localize`:Reporting Field Type @@reportingFieldType:Reporting Field Type`;
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.getData();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([1, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus.getPagerState(this.router.url).then(res => {
      if (res) {
        const pagerStuff = res;
        if (pagerStuff.totalRecords !== 0) {
          this.page = pagerStuff.lastPage;
          this.pageSize = pagerStuff.lastPageSize;
          this.startIndex = pagerStuff.startIndex;
          this.endIndex = pagerStuff.endIndex;
        }
      }
    }).catch(err => {
      console.log('pager error: ', err);
    }).then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {

    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = ((this.page - 1) * this.pageSize) + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number((this.page) * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize){
      this.endIndex = this.totalRecords;
    }

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.title);
  }

  getData() {
    // let paramString = '?search=' + ((this.vm.searchTerm) ? this.vm.searchTerm : '');
    // paramString += '&fromDate=' + ((this.vm.fromDate) ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
    // paramString += '&toDate=' + ((this.vm.toDate) ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
    let paramString = '?pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('reporting_field_type', paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;

        this.ReportingFieldTypeCount = result.totalRecords;
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => {
        // this.toastr.error($localize`:Error loading Bank Data @@errorloadingBankData:Error loading Bank Data`);
        console.log(error);
      },
      () => { }
    );
  }

  onNavigate(aID: string) {
    this.router.navigate(['admin/reporting-field-type-form', { id: aID }]).then();
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    let paramString = '?pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('reporting_field_type', paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.ReportingFieldTypeCount = result.totalRecords;
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => {
        // this.toastr.error($localize`:Error loading Bank Data @@errorloadingBankData:Error loading Bank Data`);
        console.log(error);
      },
      () => { }
    );
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

}
