import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from './custom-validators';
import {NotificationService} from '../../Services/notification.service';
import {PwChangeModel} from '../../DataModels/pw-change-model';
import {TableDataService} from '../../Services/tableData.service';
import {StorageService} from '../../Services/storage.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  pwForm: UntypedFormGroup;
  submitted = false;
  pwChangeInfo: PwChangeModel;

  constructor(private fb: UntypedFormBuilder,
              private notification: NotificationService,
              private table: TableDataService,
              private localStorage: StorageService) {
    this.pwForm = this.fb.group({
      currentPassword: new UntypedFormControl('', [Validators.required, CustomValidators.checkOldPassword]),
      newPassword: new UntypedFormControl('', [Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_@#\\$%\\^&\\*])(?=.{6,})/)]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, {
      validator: this.checkPasswords,
    });
  }

  ngOnInit() {
    this.onReset();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.pwForm.controls;
  }

  checkPasswords(group: UntypedFormGroup) {
    const oldPwd = group.controls.currentPassword.value;
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : {notSame: true};
  }

  onSubmit() {
    this.submitted = true;

    if (this.pwForm.invalid) {
      this.notification.error($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.pwChangeInfo = Object.assign({}, this.pwForm.value);
    // assign manually - not a form field
    // this.pwChangeInfo.userId = this.localStorage.StorageGet('uId'); - api handles now
    this.pwChangeInfo.userId = null;

    this.table.postWithPromise('identity/account/changepw', this.pwChangeInfo).then((res) => {
      // console.log('res:' + res);
      const lee: any = res;
      // console.log('lee:' + lee);
      if (!lee.contains('Failed')) {
        this.notification.success($localize`:Password Changed! @@passwordChanged:Password Changed!`);
      }
      return res;
    });

    // TODO for testing - display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.pwForm.value, null, 4));

    return true;
  }

  onReset() {
    this.submitted = false;
    this.pwForm.reset();
  }
}
