import { Component, Input, OnInit, OnChanges, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailService } from '../../../Services/email.service';
import { EmailModel } from '../../../DataModels/email-model';
import { AppConfigService } from '../../../Services/app-config.service';
import { TableDataService } from '../../../Services/tableData.service';
import { SysUserModel } from '../../../DataModels/sys-user-model';
import { StorageService } from '../../../Services/storage.service';
import { ActivityFeedModel } from '../../../DataModels/activity-feed-model';
import { DatePipe } from '@angular/common';
import { SendEmailInfoModel } from '../../../DataModels/send-email-info-model';
import { EntityContactListModel } from '../../../DataModels/entity-contact-list-model';
import { ResponseModel } from '../../../DataModels/response-model';
import { NotificationService } from '../../../Services/notification.service';
import {BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
})
export class SendEmailComponent implements OnInit, OnChanges, AfterViewInit {
  vm: any = {};
  sendEmailForm: UntypedFormGroup;
  submitted = false;
  objEmail = new EmailModel();
  emailsDropdownEntityData: EntityContactListModel[];
  emailsDropdownSysUsrData: SysUserModel[];
  reportDataPassedIn: any;
  useSysUserTable: boolean;
  useEntityTable: boolean;
  htmlFormattedMsg: string;
  af = new ActivityFeedModel();
  emailInfo = new SendEmailInfoModel();
  user: SysUserModel;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private emailer: EmailService,
    private toastMsg: BsToastService,
    private appCfg: AppConfigService,
    private tableDataService: TableDataService,
    private localStorage: StorageService,
    private datepipe: DatePipe,
    private notifications: NotificationService
  ) {
    this.sendEmailForm = this.formBuilder.group(
      {
        emailSourceSelected: new UntypedFormControl('', Validators.required),
        emailSelected: new UntypedFormControl('', Validators.required),
        // have to use a pattern on email, or it will allow a simple a@b with no dot or extension. Bug I guess.
        emailEntered: new UntypedFormControl(), // ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        message: new UntypedFormControl(),
        attachReport: new UntypedFormControl(),
      },
      {
        // must match stuff etc.
      }
    );
  }

  @Input() recievedReportToAttachToEmail: string;
  @Input() emailObject: object;

  ngOnChanges() {}

  ngOnInit() {
    // console.log('send mail form called');
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.vm.email = {};

    if (!this.vm.item) {
      this.vm.item = {};
    }
    if (!this.vm.item.header) {
      this.vm.item.header = {};
    }

    this.onChanges();
  }

  ngAfterViewInit() {}

  onChanges() {
    // console.log('on change fired');
    this.sendEmailForm.controls.emailSourceSelected.valueChanges.subscribe((selectedSource) => {
      if (selectedSource === 'Entered') {
        this.sendEmailForm.get('emailSelected').setValue(null);
        this.sendEmailForm.get('emailSelected').disable();
        this.setRequiredAndValidated();
      } else {
        this.sendEmailForm.get('emailSelected').enable();
        this.setValidated();
      }
    });
  }

  setRequiredAndValidated() {
    const emailControl = this.sendEmailForm.get('emailEntered');
    emailControl.setValidators([
      Validators.required,
      Validators.pattern('^([\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})(,[\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})*$'),
    ]);
    emailControl.updateValueAndValidity();
  }

  setValidated() {
    const emailControl = this.sendEmailForm.get('emailEntered');
    emailControl.setValidators([
      Validators.pattern('^([\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})(,[\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})*$'),
    ]);
    emailControl.updateValueAndValidity();
  }

  // getter for easy access to form fields
  get f() {
    return this.sendEmailForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // get values from form fields
    if (this.sendEmailForm.invalid) {
      return;
    } else {
      // load up vars passed
      this.loadEmailParams();
      this.loadReportStringToObj();

      // this.submitted = true;

      // format the message with html line breaks when enter occurred in original text
      this.htmlFormattedMsg = this.sendEmailForm.get('message').value;
      if (this.htmlFormattedMsg) {
        this.htmlFormattedMsg = this.htmlFormattedMsg.replace(/(?:\r\n|\r|\n)/g, '<br>');
      } else {
        this.htmlFormattedMsg = this.emailInfo.aMessage;
      }

      // load up the obj to pass to email service.
      this.objEmail.body = this.htmlFormattedMsg;

      if (
        this.sendEmailForm.get('emailEntered').value !== '' &&
        this.sendEmailForm.get('emailSelected').value != null
      ) {
        this.objEmail.toEmail =
          this.sendEmailForm.get('emailSelected').value + ',' + this.sendEmailForm.get('emailEntered').value;
      }

      if (this.sendEmailForm.get('emailSelected').value != null) {
        this.objEmail.toEmail = this.sendEmailForm.get('emailSelected').value;
      }

      if (this.sendEmailForm.get('emailEntered').value !== null) {
        this.objEmail.toEmail = this.sendEmailForm.get('emailEntered').value;
      }

      // these are kind of static so basically hardcoded at this time
      this.objEmail.fromEmail = this.appCfg.SysEmailAddress;

      if (this.emailInfo.aSubject == undefined || this.emailInfo.aSubject == '') {
        this.objEmail.Subject = $localize`:Information from AcctXERP @@informationfromAcctXERP:Information from AcctXERP`;
      } else {
        this.objEmail.Subject = this.emailInfo.aSubject;
      }

      // include message and report or just report or just message or just free form message and no attachments
      // message is passed in from caller code and may or may not be used
      if (
        this.sendEmailForm.get('attachReport').value === true &&
        this.sendEmailForm.valid &&
        this.sendEmailForm.get('message').value === null
      ) {
        this.objEmail.body = '<div><p>' + '' + '</p></div>' + this.reportDataPassedIn;
        this.objEmail.pdfHtml = this.reportDataPassedIn;
      } else if (
        this.sendEmailForm.get('message').value !== null &&
        this.sendEmailForm.get('attachReport').value === true
      ) {
        this.objEmail.body = '<div><p>' + this.htmlFormattedMsg + '</p></div>' + this.reportDataPassedIn;
        this.objEmail.pdfHtml = this.reportDataPassedIn;
      } else {
        this.objEmail.body = this.htmlFormattedMsg;
        // console.log('should be not here - remove when done testing');
      }
    }

    this.objEmail.header_id = this.emailInfo.aRecordId;

    // spaces got in db from form somehow - maybe just test data - cleanup to have valid value
    this.objEmail.toEmail = this.objEmail.toEmail.toString().trim();

    // TODO - display form values on success for testing purposes
    // alert('TEST SUCCESS!! :-)\n\n Turn me off - uncomment line below me to send.\n\n' + JSON.stringify(this.objEmail, null, 4));

    // check various values before sending
    if (!this.objEmail.toEmail) {
      const msg = 'Either no email address has been entered or the system item chosen has no email address stored.';
      this.notifications.warning(msg);
    } else {
      // TODO TURN THIS ON TO SEND EMAIL - OFF FOR TESTING. NOTE: the email-service handles err msgs etc back from api
      this.emailer.sendEmail(this.objEmail);
      // clear form after send
      // this.sendEmailForm.reset();
      this.updateActivityFeed();
      this.cleanupAfterSend();
    }
  }

  loadReportStringToObj() {
    // do stuff to load up the body with html string from somewhere - uses shared vars - not localStorage as name suggests
    // have to check multiple places for data due to legacy code.
    // console.log('length:' + this.localStorage.StorageGet('reportData').length);
    /* if (this.localStorage.StorageGet('reportData') && this.localStorage.StorageGet('reportData').length > 1) {
       this.reportDataPassedIn = this.localStorage.StorageGet('reportData');
       // this.localStorage.StorageSet('reportData', '');
     } else {*/
    this.reportDataPassedIn = this.emailInfo.aReportString;
    // this.emailInfo.aReportString = '';
    // }
  }

  loadEmailParams() {
    const emailValues = this.localStorage.StorageGet('sendEmailInfoObj');
    if (emailValues) {
      this.emailInfo = JSON.parse(emailValues);
      // console.log(emailValues);
    } else {
      // this.toastMsg.showWarningToast('No email content to send');
      return;
    }
  }

  updateActivityFeed() {
    // console.log(JSON.stringify(this.emailInfo));
    // this.af.user_id = this.localStorage.StorageGet('uId'); no longer need to do this api handles

    if (this.emailInfo.aBatchTypeId === '97') {
      this.af.batch_type_id = '97';
      this.af.message =
        'Document Emailed by: ' + this.user.username + ' to: ' + this.objEmail.toEmail;
    } else if (this.emailInfo.aBatchTypeId === '99' || !this.emailInfo.aBatchTypeId) {
      this.af.batch_type_id = '99';
      if (this.emailInfo.aReportOrDocName) {
        this.af.message =
          this.emailInfo.aReportOrDocName +
          ' was emailed by: ' +
          this.user.username +
          ' to: ' +
          this.objEmail.toEmail;
      } else {
        this.af.message =
          'Report or Other Emailed by: ' + this.user.username + ' to: ' + this.objEmail.toEmail;
      }
    }

    this.af.header_id = this.emailInfo.aRecordId;
    this.af.entity_id = this.emailInfo.aEntity;
    const d = new Date(Date.now());
    this.af.ActivityDate = this.datepipe.transform(d, 'yyyy-MM-ddThh:mm:ss');
    this.tableDataService.postWithPromise('activityfeed', this.af).then();
  }

  onReset() {
    this.submitted = false;
    this.sendEmailForm.reset();
  }

  loadSelectedSourceEmail() {
    this.useSysUserTable = false;
    this.useEntityTable = false;

    if (this.sendEmailForm.get('emailSourceSelected').value === 'SystemUser') {
      this.tableDataService
        .getTableData('sys_user', '?pageNumber=0&pageSize=999999')
        .subscribe((result: ResponseModel) => {
          this.emailsDropdownSysUsrData = result.Data;
        });
      this.useSysUserTable = true;
    }
    if (this.sendEmailForm.get('emailSourceSelected').value === 'EntityContact') {
      this.tableDataService
        .getTableData('entity_contact_list', 'fulllist?pageNumber=0&pageSize=999999')
        .subscribe((result: ResponseModel) => {
          this.emailsDropdownEntityData = result.Data;
          const sorted = this.emailsDropdownEntityData.sort((a, b) => (a.entity_name > b.entity_name ? 1 : -1));
          this.emailsDropdownEntityData = sorted;
          // console.log(JSON.stringify(sorted));
        });
      this.useEntityTable = true;
    }
  }

  cleanupAfterSend() {
    this.localStorage.StorageSet('reportData', '');
    this.localStorage.StorageSet('sendEmailInfoObj', '');
    Object.keys(this.emailInfo).forEach((key) => delete this.emailInfo[key]);
    Object.keys(this.objEmail).forEach((key) => delete this.objEmail[key]);
    // console.log('objemail :' + JSON.stringify(this.objEmail));
    // console.log('emailinfo :' + JSON.stringify(this.emailInfo));
  }
}
