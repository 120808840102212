import {Component, Input, Output, OnChanges, OnInit, SimpleChange, SimpleChanges, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {CompanyModel} from '../../../../../DataModels/company-model';
import {LocationModel} from '../../../../../DataModels/location-model';
import {TableDataService} from '../../../../../Services/tableData.service';
import {BankModel} from '../../../../../DataModels/bank-model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ItemQuantityModel} from '../../../../../DataModels/item-quantity-model';
import {NotificationService} from '../../../../../Services/notification.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-modal-new-item-quantity',
  templateUrl: './modal-new-item-quantity.component.html',
  styleUrls: ['./modal-new-item-quantity.component.css']
})
export class ModalNewItemQuantityComponent implements OnInit, OnChanges {
  @Input() itemID: string;
  @Input() itemQuantityID: string;
  @Output('parentReloadData') parentReloadData: EventEmitter<any> = new EventEmitter();
  companies$: Observable<CompanyModel[]>;
  locations$: Observable<LocationModel[]>;

  itemQuantity: ItemQuantityModel;
  itemQuantityForm: UntypedFormGroup;
  constructor(private table: TableDataService,
              private formBuilder: UntypedFormBuilder,
              private notification: NotificationService) {
    this.createForm();
  }

  ngOnInit() {
    this.loadLookups();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.itemQuantityID;
    // console.log('prev value: ', currentItem.previousValue);
    // console.log('got item: ', currentItem.currentValue);
    if (this.itemQuantityID != '')
    {
      this.loadReorder();
    } else {
      this.itemQuantityForm.reset();
    }
  }

  createForm() {
    this.itemQuantityForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      item_id: new UntypedFormControl(''),
      location_id: new UntypedFormControl(''),
      minimum_on_hand_quantity: new UntypedFormControl(''),
      maximum_on_hand_quantity: new UntypedFormControl(''),
      minimum_order_quantity: new UntypedFormControl(''),
      bin: new UntypedFormControl(''),
    });
  }

  private loadReorder() {
    this.table.getTableData('itemquantity', this.itemQuantityID).subscribe(result => {
        this.itemQuantity = result;
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error loading Entity Access @@errorLoadingEntityAccess:Error loading Entity Access`);
        console.log(error);
      },
      () => {
        this.itemQuantityForm.patchValue(this.itemQuantity);
      });
  }

  private loadLookups() {
    // this.companies$ = this.table.getTableData('company');
    // this.locations$ = this.table.getTableData('location');
    this.table.getApiListData('company', '?pageNumber=0&pageSize=999999').subscribe((res: any) => {
      this.companies$ = res.Data;
    });
    this.table.getApiListData('location', '?pageNumber=0&pageSize=999999').subscribe((res: any) => {
      this.locations$ = res.Data;
    });
  }

  saveItemQuantity() {
    let retVal = false;
    this.itemQuantity = Object.assign({}, this.itemQuantityForm.value);
    this.itemQuantity.item_id = this.itemID;
    if (this.itemQuantityID) {
      if (this.itemQuantityForm.invalid === false) {
        this.table.patch('itemquantity', this.itemQuantityID, this.itemQuantity).then();

        retVal = true;
      }
    } else {
      this.table.postWithPromise('itemquantity', this.itemQuantity).then();
      // this.notification.success($localize`:Saved @@saved:Saved`);

      retVal =  true;
    }
    // this.notification.success($localize`:Updated @@updated:Updated`);
    this.parentReloadData.emit();
    return retVal;
  }
}
