import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {SharedDataService} from './sharedData.service';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  private mapPreferences = new Map();

  constructor(public localStorage: StorageService,
              private sharedData: SharedDataService ) {
  }

  LoadPreferences(aPrefsJsonObj: any, sender = '') {
    // this.mapPreferences.clear();
    // if (this.mapPreferences.size > 0) {
    //   return;
    // }
    const f1 = JSON.stringify(aPrefsJsonObj);
    const x1 = JSON.parse(f1);
    for (const key in x1) {
      if (x1.hasOwnProperty(key)) {
        this.mapPreferences.set(key, x1[key]);
      }
    }
  }

  ListPreferences() {
    // console.log('---Preferences---');
    this.mapPreferences.forEach((value: string, key: string) => {
    console.log(key + ' => ' + value);
    });
  }

  // rlp
  GetByName(aName: string): boolean {
    return this.mapPreferences.get(aName);
  }

  GetDbNameFromPrefs(aName: string): string {
    return this.mapPreferences.get(aName);
  }

  // rlp

  LoadByLocalStorage(name: string) {
    const pref = this.localStorage.StorageGet('Preferences', '');
    let matched = '';
    this.LoadPreferences(JSON.parse(pref));
    this.mapPreferences.forEach((value: string, key: string) => {
      if (key == name) {
        matched = value;
      }
    });
    return matched;
  }
}
