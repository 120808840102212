import { Component, OnInit } from '@angular/core';
import {StorageService} from '../../../Services/storage.service';
import {TableDataService} from '../../../Services/tableData.service';
import {NotificationService} from '../../../Services/notification.service';
import {EncrDecrService} from '../../../Services/encr-decr.service';

@Component({
  selector: 'app-quick-link',
  templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.css']
})
export class QuickLinkComponent implements OnInit {

  permissionsAdmin = true;
  showAllLinks = false;
  vm: any = {};

  constructor(
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: NotificationService,
    private crypt: EncrDecrService) {
      // this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
      // this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
      // if (this.vm.Preferences.QuickLinks){
      //   this.vm.quickLinks = this.vm.Preferences.QuickLinks;
      // }
    }

  ngOnInit() {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    if (this.vm.Preferences.QuickLinks){
      this.vm.quickLinks = this.vm.Preferences.QuickLinks;
    }
  }

  updateQuickLinks(){
    this.showAllLinks = false;
    this.vm.buttonsDisabled = true;
    // this.localStorage.StorageSet('Preferences', '');
    // console.log(this.vm.Preferences.QuickLinks);
    this.tableDataService.patch('Preferences/quicklinks', '', this.vm.Preferences.QuickLinks)
    .then((item: any) => {
      this.vm.buttonsDisabled = false;
      // console.log('pref after save:' + JSON.stringify(this.vm.Preferences));
      // this.localStorage.StorageSet('Preferences', JSON.stringify(this.vm.Preferences));
      this.localStorage.StorageSet('Preferences', this.crypt.encrypt(JSON.stringify(this.vm.Preferences)));
    }, (er: any ) => {
      this.vm.buttonsDisabled = false;
      this.notification.error($localize`:errorSavingQuickLinks @@errorSavingQuickLinks:Error Saving Quick Links`);
    });
  }

}
