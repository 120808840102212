import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ItemPricingModel } from '../../../../DataModels/item-pricing-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { DecimalPipe } from '@angular/common';
import { AppConfigService } from '../../../../Services/app-config.service';
import { ItemModel } from '../../../../DataModels/item-model';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-item-pricing-form',
  templateUrl: './item-pricing-form.component.html',
  styleUrls: ['./item-pricing-form.component.css'],
})
export class ItemPricingFormComponent implements OnInit, AfterViewInit {
  vm: any = [];
  itemPricingID: string;
  itemPricing: ItemPricingModel;
  itemSpecificDetail: ItemModel;
  itemPricingForm: UntypedFormGroup;

  entityList: Array<object>;
  itemList: Array<object>;
  showDelete = false;
  stopEdit = false;

  submitted = false;

  stdPrice = 0.0;
  discPrice = 0.0;
  percentDisc = 0.0;
  valueDisc = 0.0;

  itemSpecificStdPrice = 0.0;
  itemDiscountPrice = 0.0;

  itemToQuery = '';

  lastCost = 0.0;
  standarCost = 0.0;
  averageCost = 0.0;

  standardPrice = 0.0;

  currentAdjustedPrice = 0.0;

  pricingExists = false;
  checkData: any[] = [];

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private decimal: DecimalPipe,
    private confirmDialogService: ConfirmDialogService,
    private appConfig: AppConfigService,
    private titleService: Title
  ) {
    this.createForm();
    this.itemPricingID = this.route.snapshot.params.id;
    if (this.itemPricingID) {
      // TODO think about this - allow changes after price set?
      // this.itemPricingForm.get('entity_id').disable();
      // this.itemPricingForm.get('item_id').disable();
      // this.itemPricingForm.get('old_price').disable();
    }
  }

  get f() {
    return this.itemPricingForm.controls;
  }

  createForm() {
    this.itemPricingForm = this.formBuilder.group({
      entity_id: new UntypedFormControl('', Validators.required),
      item_id: new UntypedFormControl('', Validators.required),
      old_price: new UntypedFormControl(0.0, Validators.required),
      price: new UntypedFormControl(0.0, Validators.min(0.01)),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Item Pricing @@itemPricing:Item Pricing`);
    this.loadEntity();
    this.loadItem();
    if (this.itemPricingID) {
      this.loadData();
      this.buttonConfig.reset = false;
    }
  }

  ngAfterViewInit() {}

  private SetFormValues() {
    this.itemPricingForm
      .get('price')
      .patchValue(parseFloat(this.itemPricing.price.toString()).toFixed(this.appConfig.decimalPlaces));
  }

  setCurrentPriceValue(event: any) {
    this.itemDiscountPrice = this.itemPricingForm.get('price').value;
  }

  zeroNewPrice() {
    this.itemDiscountPrice = 0.0;
    this.itemPricingForm.get('price').setValue(0.0);
  }

  getItemData(event: any) {
    this.loadItemSpecific(event.target.value);
    this.checkPriceExits();
  }

  private loadData() {
    this.table.getTableData('item_pricing', this.itemPricingID).subscribe(
      (result: ItemPricingModel) => {
        this.itemPricing = result;

        this.itemToQuery = this.itemPricing.item_id;
        this.itemPricingForm.get('old_price').setValue(this.itemPricing.price.toFixed(2));

        this.currentAdjustedPrice = this.itemPricing.price;

        // fetch some detail data once we have an id to use - beware subscribe
        this.loadItemSpecific(this.itemToQuery);
      },
      (error: HttpErrorResponse) => {
        console.log(
          $localize`:Error loading Item Pricing @@errorLoadingItemPricing:Error loading Item Pricing`
        );
        console.log(error);
      },
      () => {
        this.itemPricingForm.patchValue(this.itemPricing);
        // set the values we want now that we have a form and values assigned from the GET
        this.SetFormValues();
      }
    );
  }

  private loadEntity() {
    this.table.getApiListData('entity', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.entityList = result.Data;
        // removing the entities that are NOT customers
        this.entityList.forEach((receivable, index) => {
          if (!receivable) {
            this.entityList.splice(index, 1);
          }
        });
      },
      (error: HttpErrorResponse) => {
       console.log(error);
      },
      () => {}
    );

  }

  private checkPriceExits() {
    this.table
      .getTableData(
        'item_pricing/doespriceexist',
        '?entity_id=' +
          this.itemPricingForm.get('entity_id').value +
          '&item_id=' +
          this.itemPricingForm.get('item_id').value
      )
      .subscribe(
        (result: any[]) => {
          this.checkData = result;

          if (this.checkData.length > 0) {
            this.pricingExists = true;
            this.notification.showWarningToast('Pricing for this Entity - Item already exists');
            return;
          } else {
            this.pricingExists = false;
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
  }

  private loadItem() {
    this.table.getApiListData('item', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.itemList = result.Data;
      },
      (error: HttpErrorResponse) => {
       // this.notification.showErrorToast($localize`:Error loading items @@errorLoadingItems:Error loading items`);
        console.log(error);
      },
      () => {}
    );
  }

  private loadItemSpecific(id: string) {
    this.table.getTableData('item', id).subscribe(
      (result: ItemModel) => {
        this.itemSpecificDetail = result;

        this.averageCost = result.average_cost;
        this.standarCost = result.cost;
        this.lastCost = result.last_cost;

        this.standardPrice = result.price;

        // use the standard price on the item when setting up a new pricing
        if (!this.itemPricingID) {
          this.itemPricingForm.get('old_price').setValue(this.standardPrice.toFixed(2));
        }
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading item specifics @@errorLoadingItemSpecifics:Error loading item specifics`
        // );
        console.log(error);
      },
      () => {}
    );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.itemPricingForm.reset();
    }
  }

  resetForm() {
    this.itemPricingForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/item-pricing-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete item price? @@areYouSureYouWantToDeleteItemPrice:Are you sure you want to delete item price?`,
      this.itemPricing.item_name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('item_pricing', this.itemPricingID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  // getFormValidationErrors() {
  //   Object.keys(this.itemPricingForm.controls).forEach((key) => {
  //     const controlErrors: ValidationErrors = this.itemPricingForm.get(key).errors;
  //     if (controlErrors != null) {
  //       Object.keys(controlErrors).forEach((keyError) => {
  //         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
  //       });
  //     }
  //   });
  // }

  onSave(saveClose: boolean) {

    this.submitted = true;

    // if (this.pricingExists) {
    //   this.notification.error('Pricing for this Entity - Item already exists');
    //   return;
    // }
    // this.submitted = true;

    if (this.itemPricingForm.invalid) {
      // this.getFormValidationErrors();
      this.notification.showWarningToast('Form Errors!');
      return;
    }
    this.itemPricing = Object.assign({}, this.itemPricingForm.value);

    if (this.itemPricingID) {
      if (this.itemPricingForm.invalid === false) {
        this.table.patch('item_pricing', this.itemPricingID, this.itemPricing).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('item_pricing', this.itemPricing).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.itemPricingID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
        // this.notification.success($localize`:Saved @@saved:Saved`);
      });
      return true;
    }
    this.submitted = false;
    return true;
  }
}
