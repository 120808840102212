import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-tax-select',
  templateUrl: './tax-select.component.html',
  styleUrls: ['./tax-select.component.css']
})
export class TaxSelectComponent implements OnInit {
  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

}
