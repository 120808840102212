export class SharedDataUserInfoModel {

  Perms: string;
  Prefs: string;
  Password: string;
  Username: string;
  UserDb: string;
  Token: string;
  LoggedIn: boolean;
}
