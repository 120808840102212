import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TableDataService } from '../../../../../Services/tableData.service';
import { PermissionsService } from '../../../../../Services/permissions.service';
import { ItemEntityModel } from '../../../../../DataModels/item-entity-model';
import { DecimalPipe } from '@angular/common';
import {BsToastService} from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-item-customer',
  templateUrl: './item-customer.component.html',
  styleUrls: ['./item-customer.component.css'],
})
export class ItemCustomerComponent implements OnInit, AfterViewInit {
  @Input() itemID: string;
  customers: [ItemEntityModel];
  totalCustomerQuantity = 0;
  totalCustomerBalance = 0;

  constructor(
    private table: TableDataService,
    private toast: BsToastService,
    private p: PermissionsService,
    private decimal: DecimalPipe
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.getCustomers();
  }

  private getCustomers() {
    // this.getting = true;
    const urlParamsJson = {
      item_id: this.itemID,
      type_id: 2,
      pageNumber: 0,
      pageSize: 999999,
    };

    const Params: string = this.encodeQueryData(urlParamsJson);
    this.table.getTableData('ItemEntity', '?' + Params).subscribe(
      (result) => {
        this.customers = result;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
        this.totalCustomerQuantity = 0;
        this.totalCustomerBalance = 0;
        for (const item of this.customers) {
          this.totalCustomerQuantity += Number(item.quantity);
          this.totalCustomerBalance += Number(item.balance);
        }
      }
    );
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if ((data[d] != null && data[d] != '') || data[d] === 0) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }
}
