import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-home-schedule',
  templateUrl: './home-schedule.component.html',
  styleUrls: ['./home-schedule.component.css']
})
export class HomeScheduleComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
