export class EntityAddressModel {
  id: string;
  name: string;
  entity_id: string;
  billing_address: boolean;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  country: string;
  primary_address: boolean;
  instructions: string;
  active: boolean;

}
