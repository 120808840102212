export class ClearedDataModel {
  item: Item;
}

export class Item {
  cleared_date: string;
  detail_id: string;
  recon_id: string;
  id: string;
}
