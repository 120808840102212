import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DateFilterService} from '../../../../Services/dateFilter.service';
import {TableDataService} from '../../../../Services/tableData.service';
import {StorageService} from '../../../../Services/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailService} from '../../../../Services/email.service';
import {FilterPipe} from 'ngx-filter-pipe';
import {DataExportService} from '../../../../Services/dataExport.service';
import {DatePipe} from '@angular/common';
import {AppConfigService} from '../../../../Services/app-config.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {PaymentModel} from '../../../../DataModels/payment-model';
import {take} from 'rxjs/operators';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import {EmailModel} from '../../../../DataModels/email-model';
import { RoutingTrackerService} from '../../../../Services/routing-tracker.service';
import {Modal} from 'bootstrap';
import {SysUserModel} from '../../../../DataModels/sys-user-model';

@Component({
  selector: 'app-aging',
  templateUrl: './aging.component.html',
  styleUrls: ['./aging.component.css']
})
export class AgingComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('EmailContent', {static: false}) EmailContent: ElementRef;
  @ViewChild('ExcelExport', {static: false}) ExcelExport: ElementRef;

  public urlParams: any = [];
  hideDetails;
  private rptName: string;
  public vm: any = {
    report: [],
    subLedger: null,
    request: {
      toDate: null,
      includeUnposted: false,
      entity_id: null,
      entity_type_id: null,
      company_id: null,
      currency_id: null,
    },
    entityType: [],
    company: [],
    location: [],
    payTotals: null,
    buttonsDisabled: false,
    payment: {
      total: null,
    },
    showFilters: false,
    entityFilter: {
      name: null,
    },
    currency: [],
    entity: [],
    entity_type: [],
    entityInput: null,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.applicationConfig.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    ToEmailEntity: false,
    permissions: {
      Admin: null,
      AllEntities: null,
    },
    totalOwing: null,
    totalCurrent: null,
    total1to30: null,
    total31to60: null,
    total61to90: null,
    total91plus: null,
    entity_email: null,
    entityName: [],
    startEntity: 0,
    batchName: [],
    batchFilter: {search_string: ''},
    toDateSelect: [],
    monthCount: [],
    exportToExcel: true,
  };

  checkAllCheckBox = false;
  printDocIdList: {
    id: string,
    bank_id: string
  }[] = [];

  printDocIds = [];
  selectItem; any = [];
  user: SysUserModel;
  routedFrom = '';
  checkAll = false;

  invoicesToPay: PaymentModel = new PaymentModel();
  invoiceArrayToSendToApi = [];
  invoiceSelectedCount = 0;
  currentEntityForErrorReporting = '';
  invoiceFound = false;
  // const category: Category = new Category();

  // email stuff - add app-button-email-new to html also
  buttonConfig: any = {
    reportFormat: false,
    print: true,
    email: true,
    update: true,
    exportExcel: true,
    close: true,
  };
  reportDataToSendInEmail: EmailModel = new EmailModel();
  // end email

  constructor(
    private titleService: Title,
    public dateFilter: DateFilterService,
    public tableDataService: TableDataService,
    public notification: BsToastService,
    public localStorage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    public aEmail: EmailService,
    public excelExporter: DataExportService,
    public datePipe: DatePipe,
    public applicationConfig: AppConfigService,
    private confirmDialogService: ConfirmDialogService,
    public prevUrl: RoutingTrackerService
  ) {

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }


  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('currentUser'));

    this.invoicesToPay.applied = [];

    this.urlParams = this.route.snapshot.queryParams;

    this.vm.request.entity_id = this.urlParams.entity_id;
    if (this.vm.request.entity_id) {
      this.vm.filtered = true;
    }
    this.vm.request.toDate = this.urlParams.toDate;

    this.vm.subLedger = this.urlParams.subLedger;

    this.FilterOptionsInit().then();
    this.rptName = this.vm.subLedger.toUpperCase() + ' ' + $localize`:Aging Report @@agingReport:Aging Report`;

    if (this.vm.subLedger === 'ap') {
      // sets Report Fromat checkbox on off
      this.vm.report_format = true;
    }

  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, this.vm.subLedger.toUpperCase() + ' Aging Report.xlsx');
  }

  async FilterOptionsInit() {
    this.tableDataService.getTableData('currency?state=active&pageNumber=0&pageSize=99999').subscribe((result: ResponseModel) => {
      this.vm.currency = result;
    });
    this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=99999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
    this.tableDataService.getTableData('bank?state=active&pageNumber=0&pageSize=99999').subscribe((result: ResponseModel) => {
      this.vm.bank = result.Data;
    });
    this.tableDataService.getTableData('entity_type?state=active&pageNumber=0&pageSize=99999').subscribe((result: ResponseModel) => {
      // const data: any = result.Data;
      this.vm.entityType = result.Data;
    });

    this.vm.entity = await this.getFilterValues('entity', '?state=active');
    for (const [x, value] of Object.entries(this.vm.entity)) {
      this.vm.entityName.push({name: this.vm.entity[x].name});
    }

    if (this.urlParams.entity_id != null || this.urlParams.entity_id != undefined) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y]) {
          if (this.vm.entity[y].id == this.urlParams.entity_id) {
            this.vm.entityInput = this.vm.entity[y].name;
            this.vm.request.entity_id = this.vm.entity[y].id;
            this.vm.request.entity_email = this.vm.entity[y].email;
            break;
          }
        }
      }
    }
    if (!this.urlParams.toDate) {
      this.vm.request.toDate = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
    }
    if (this.vm.request.toDate && this.urlParams.entity_id) {
      this.update();
    }
  }

  async getFilterValues(tablename, param) {
    let values: any;
    values = null;
    await this.tableDataService.getTableData(tablename + param + '&pageNumber=0&pageSize=99999').toPromise().then((result: ResponseModel) => {
      const data: any = result.Data;
      values = data;
    });
    // this.localStorage.StorageSet(tablename, JSON.stringify(values));
    return values;
  }


  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  ngAfterViewChecked() {

  }

  setEmailData() {
    // do stuff to send email to via sendmail modal input
    this.getReportData(true);
    const element = document.getElementById('sendEmailModal') as HTMLElement;
    const emailModal = new Modal(element);
    emailModal.show();
  }

  getReportData(event: any) {
    const eventSent: any = event;
    const emailDocObj = {} as EmailModel;
    let reportName = 'Aging Report';

    if (this.vm.subLedger === 'ap') {
      reportName = 'AP Aging Report';
    } else if (this.vm.subLedger === 'ar') {
      reportName = 'AR Aging Report';
    }

    emailDocObj.fromEmail = '';
    emailDocObj.fromName = 'AcctXErp';
    emailDocObj.header_id = '';
    emailDocObj.toEmail = '';
    emailDocObj.body = 'Please see the attached ' + reportName + '.<br><br><br>'
      + 'Best regards,'
      + '<br><br>'
      + 'AcctXErp'
      + '<br><br>';
    emailDocObj.Subject = 'AcctXErp ' + reportName;
    emailDocObj.pdfHtml = this.ExcelExport.nativeElement.outerHTML;
    emailDocObj.activity_message = reportName;

    this.reportDataToSendInEmail = emailDocObj;
  }

  update() {
    this.vm.buttonsDisabled = true;
    this.vm.report = [];
    this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
    const urlParamsJson = {
      toDate: this.vm.request.toDate,
      subLedger: this.vm.subLedger,
      entity_id: this.vm.request.entity_id,
      entity_type_id: this.vm.request.entity_type_id,
      company_id: this.vm.request.company_id,
      currency_id: this.vm.request.currency_id
    };
    const urlParams: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getTableData('aging', '?' + urlParams).subscribe((result) => {
      this.vm.report = result;
      // console.log(this.vm.report);
      this.calcReportTotals();
      this.vm.buttonsDisabled = false;
    });
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  createPaymentApplyRecords() {

    try {
      // new obj each run - also reset inside each loop run - see code below
      this.invoicesToPay = new PaymentModel();
      this.invoicesToPay.applied = [];
      this.invoicesToPay.detail = [];
      this.invoiceArrayToSendToApi = [];
      this.invoiceSelectedCount = 0;
      this.currentEntityForErrorReporting = '';
      this.invoiceFound = false;

      this.vm.report.forEach((item) => {

        if (item.selected === true) {
          const hdrEntityData = this.vm.entity.filter(x => x.id === item.id);
          this.currentEntityForErrorReporting = item.name;
          this.invoiceFound = true;
          let detailTotal = 0;
          const postDesc = this.datePipe.transform(Date.now(), 'medium');

          this.invoicesToPay.header = {
            header_date: this.datePipe.transform(Date.now(), 'MM/dd/yyyy'),
            currency_rate: '1',
            entity_id: item.id,
            entityInput: item.name,
            company_id: hdrEntityData[0].company_id,
            description: 'Selected for payment from Aging by user: ' + this.user.name + ' on ' + postDesc,
            bank_id: hdrEntityData[0].bank_id,
            payment_type_id: hdrEntityData[0].payment_type_id
          };

          this.invoicesToPay.batch = {
            type_id: '5',
            description: 'Batch'
          };

          item.invoices.forEach((inv) => {
            if (inv.selected === true && item.id === inv.entity_id) {
              // persists to get TOTAL count of invoices selected
              this.invoiceSelectedCount++;
              // resets on each unique loop from above
              detailTotal = detailTotal + inv.amount;

              const applied = {
                applied_header_id: inv.id,
                applied_amount: inv.amount,
                applied_date: inv.header_date
              };
              this.invoicesToPay.applied.push(applied);
            }
          });

          const cashAccount = this.vm.bank.filter(x => x.id === hdrEntityData[0].bank_id);

          const detail = {
            id: 1,
            amount: detailTotal,
            debit_credit: detailTotal,
            account_id: cashAccount[0].account_id
          };
          this.invoicesToPay.detail.push(detail);

          this.invoiceArrayToSendToApi.push(this.invoicesToPay);
          // cleanup after write to array and before next loop
          this.invoicesToPay = new PaymentModel();
          this.invoicesToPay.applied = [];
          this.invoicesToPay.detail = [];
        }

      });
    } catch (e) {
      this.notification.showWarningToast('Entity ' + this.currentEntityForErrorReporting + ' does not have all default values set, ' +
        ' or one of its default items is not completely setup with its defaults. Entries for this Entity cannot be generated until ' +
        ' all default values are set. De-select the Entity or complete the setup.');
      console.log(e.message + ' Entity: ' + this.currentEntityForErrorReporting);
      // uncheck all rows since we are not sure
      this.selectAllRows(false);
      return;
    }

    if (!this.invoiceFound) {
      this.notification.showWarningToast('No Invoices found. Please be sure to select at least one invoice.');
      return;
    }

    // good to here - verify they want to post and post on affirm
    this.confirmPost(this.invoiceSelectedCount);
  }

  postPayment() {
    // console.log('invoice array at post: ' + JSON.stringify(this.invoiceArrayToSendToApi));
    this.tableDataService.post('entry/PostByArray', this.invoiceArrayToSendToApi).pipe(take(1))
      .subscribe({
        next: (result: any) => {
          // console.log('posted: ' + result.Message);
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {
          this.update();
        },
      });
    //
    //
    // console.log('posting AP: ' + JSON.stringify(this.vm.item));
    // this.tableDataService.post('entry', this.vm.item).subscribe(
    //   (result: any) => {
    //     // this.notification.success(result.description);
    //     if (this.vm.action === 'new') {
    //       this.vm.buttonsDisabled = false;
    //       this.vm.invoices = [];
    //       this.vm.applied = [];
    //       // this.notification.info($localize`:New @@new:New`);
    //       this.vm.action = '';
    //       //   const str = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    //       //   if (this.vm.subLedger === 'ap') {
    //       //     this.router.navigateByUrl('/transactions/payment/form?paymentId=newAP&new=' + str).then();
    //       //   } else {
    //       //     this.router.navigateByUrl('/transactions/payment/form?paymentId=newAR&action=' + str).then();
    //       //   }
    //       // } else {
    //       this.vm.buttonsDisabled = false;
    //       this.router.navigateByUrl('/transactions/payment/form?paymentId=' + result.id).then();
    //     }
    //   },
    //   (error2: any) => {
    //     this.vm.buttonsDisabled = false;
    //     let ErrorMsg: string;
    //     if (typeof error2.error.Message !== 'undefined') {
    //       ErrorMsg = error2.error.Message;
    //     } else {
    //       ErrorMsg = error2.statusText;
    //     }
    //     this.notification.showErrorToast(ErrorMsg);
    //   }
    // );

  }

  confirmPost(invoiceCount: any) {
    const messages: string[] = [
      $localize`:Post selected Invoices to Payments? @@postselectedinvoicestopayments?:Post selected Invoices to Payments?`,
      'Invoice selected count = ' + invoiceCount
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
       this.postPayment();
        // alert('at post');
        // console.log('invoice array at post: ' + JSON.stringify(this.invoiceArrayToSendToApi));
      },
      () => {
        this.notification.showSuccessToast($localize`:Post Canceled @@postCanceled:Post Canceled`);
      }
    );
  }

  cleanUpCancelledInvoicePost() {

  }

  selectRow(rec: any, checked: boolean) {
    // console.log('select single rec val:' + rec + ' - ' + checked);
    if (this.checkAll && rec) {
      this.checkAll = false;
    }
    if (!checked) {
      this.vm.report.forEach((item) => {
        item.invoices.forEach((inv) => {
          if (inv.id === rec) {
            inv.selected = false;
            item.selected = false;
          }
        });
      });
    } else if (checked) {
      this.vm.report.forEach((item) => {
        item.invoices.forEach((inv) => {
          if (inv.id === rec) {
            inv.selected = true;
            item.selected = true;
          }
        });
      });
      }

    this.isAllChecked();
    // console.log ('after single row select: ' + JSON.stringify(this.vm.report));
  }

  selectAllRows(event: any) {
    // console.log('select all rec val:' + event);
    if (!event) {
      this.vm.report.forEach((item) => {
        item.invoices.forEach((inv) => {
          inv.selected = false;
          item.selected = false;
        });
      });
      this.checkAll = false;
    } else if (event) {
      this.vm.report.forEach((item) => {
        item.invoices.forEach((inv) => {
          inv.selected = true;
          item.selected = true;
        });
      });
      this.checkAll = true;
    }
    // console.log('all select records: ' + JSON.stringify(this.vm.report));
  }

  isAllChecked(): boolean {
    let retVal = true;

    this.vm.report.forEach((item) => {
      item.invoices.forEach((inv) => {
       if (inv.selected === false || inv.sellected === undefined) {
         retVal = false;
         // console.log('not all checked');
       }
     });
    });
    this.checkAll = retVal;
    // console.log('is all  return: ' + retVal);
    return retVal;
  }

  close() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom === undefined) {
      window.close();
    } else {
      this.router.navigateByUrl('/home').then();
    }
  }

  clear() {
    this.vm.request = {
      toDate: null,
      includeUnposted: false,
      entity_id: null,
      entity_type_id: null,
      company_id: null,
      currency_id: null,
    };
    this.vm.report = [];
    this.vm.entityInput = '';
  }

  calcReportTotals() {
    let reportBalance = 0;
    let reportCurrent = 0;
    let report1 = 0;
    let report30 = 0;
    let report60 = 0;
    let report90 = 0;

    for (const [x, value] of Object.entries(this.vm.report)) {
      let entityOwing = 0;
      let entityCurrent = 0;
      let entity1 = 0;
      let entity30 = 0;
      let entity60 = 0;
      let entity90 = 0;
      for (const [y, value2] of Object.entries(this.vm.report[x].invoices)) {
        entityOwing += this.vm.report[x].invoices[y].owing;
        if (this.vm.report[x].invoices[y].days_late <= 0) {
          entityCurrent += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 30) {
          entity1 += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 60) {
          entity30 += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 90) {
          entity60 += this.vm.report[x].invoices[y].owing;
        } else {
          entity90 += this.vm.report[x].invoices[y].owing;
        }
      }
      reportBalance += entityOwing;
      reportCurrent += entityCurrent;
      report1 += entity1;
      report30 += entity30;
      report60 += entity60;
      report90 += entity90;
      this.vm.report[x].totalOwing = entityOwing;
      this.vm.report[x].totalCurrent = entityCurrent;
      this.vm.report[x].total1to30 = entity1;
      this.vm.report[x].total31to60 = entity30;
      this.vm.report[x].total61to90 = entity60;
      this.vm.report[x].total91plus = entity90;
    }
    this.vm.totalOwing = reportBalance;
    this.vm.totalCurrent = reportCurrent;
    this.vm.total1to30 = report1;
    this.vm.total31to60 = report30;
    this.vm.total61to90 = report60;
    this.vm.total91plus = report90;
    // console.log(this.vm.report);
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.vm.subLedger.toUpperCase() + ' ' + $localize`:Aging Report @@agingReport:Aging Report` + ' ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.entity_email = this.vm.entity[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput == '' || this.vm.entityInput == null || this.vm.entityInput === undefined) {
      this.vm.request.entity_id = '';
    } else {
      if (this.FilterObject(this.vm.entity, {name: this.vm.entityInput}).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, {name: this.vm.entityInput}, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, {name: this.vm.entityInput}, true)[0].name;
        const entityEmail = this.FilterObject(this.vm.entity, {name: this.vm.entityInput}, true)[0].email;
        if (entityId != '' || entityId != null) {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
          this.vm.entity_email = entityEmail;
        } else {
          this.vm.request.entity_id = '';
          this.vm.entity_email = '';
        }
      } else {
        this.vm.request.entity_id = '';
        this.vm.entity_email = '';
      }
    }
    if (entityIdStart != this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }

  updateEntity() {
    this.vm.buttonsDisabled = true;
    this.localStorage.StorageSet('entity', '');
    this.tableDataService.getTableData('entity', '?state=active&pageNumber=0&pageSize=99999').subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.vm.entity = data;
      this.vm.buttonsDisabled = false;
    });
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }
}
