import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-print-v2',
  templateUrl: './print-v2.component.html',
  styleUrls: ['./print-v2.component.css']
})
export class PrintV2Component implements OnInit {

  @Input() vm: any;
  
  constructor() { }

  ngOnInit() {
  }

}
