import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';

@Component({
  selector: 'app-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.css']
})
export class EntitySelectComponent implements OnInit {
  @Input() vm: any;

  @Output() testEntityEmit = new EventEmitter<string>();
  @Output() selectEntityModalEmit = new EventEmitter<string>();
  @Output() updateEntityEmit = new EventEmitter<string>();

  constructor(public isFiltered: IsFilterOnService) {
  }

  ngOnInit() {
  }

  checkFiltered(fromEvent: string): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    // console.log('filter set to:' + filteredValue);
    this.vm.filtered = filteredValue;
    // console.log('changed:' + fromEvent);
    return filteredValue;
  }

  testEntity() {
    this.testEntityEmit.emit();
  }

  updateEntity() {
    this.updateEntityEmit.emit();
  }

  selectEntityModal(entity) {
    // $('#entitySelect').modal('hide');
    // console.log('entity selected:' + entity);
    this.selectEntityModalEmit.emit(entity);
  }

}
