import { BrowserModule, Title } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { LoginLayoutComponent } from './Layouts/login-layout.component';
import { PrintLayoutComponent} from './Layouts/print-layout.component';
import { HeaderComponent } from './Components/common/header/header.component';
import { LoginComponent } from './Components/login/Login.component';
import {ForgotPasswordComponent} from './Components/forgot-password/forgot-password.component';
import { EntityFormComponent } from './Components/Entity/entity-form/entity-form.component';
import { EntityListComponent } from './Components/Entity/entity-list/entity-list.component';
import { TableDataService } from './Services/tableData.service';
import { AppConfigService } from './Services/app-config.service';
import { PermissionsService } from './Services/permissions.service';
import { PreferencesService } from './Services/preferences.service';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { AppLayoutComponent } from './Layouts/app-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { EntityContactComponent } from './Components/Entity/resources/entity-contact/entity-contact.component';
import { EntityAddressComponent } from './Components/Entity/resources/entity-address/entity-address.component';


import { QuickLinkComponent } from './Components/home/quick-link/quick-link.component';
import { HomeNavigationComponent } from './Components/home/home-navigation/home-navigation.component';
import { HomeDashboardComponent } from './Components/home/home-dashboard/home-dashboard.component';
import { HomeManageComponent } from './Components/home/home-manage/home-manage.component';
import { EntityHistoryComponent } from './Components/Entity/resources/entity-history/entity-history.component';
import { EntityActivityFeedComponent } from './Components/Entity/resources/entity-activity-feed/entity-activity-feed.component';
import { EntityDetailComponent } from './Components/Entity/resources/entity-detail/entity-detail.component';

import { SharedModule } from './Components/common/shared.module';
import { AdminModule } from './Components/admin/admin.module';
import { InventoryModule } from './Components/inventory/inventory.module';
import { AppLocationService } from './Services/appLocation.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReportsModule } from './Components/Reports/reports.module';
import { DateFilterService } from './Services/dateFilter.service';
import { SalesOrderFormComponent } from './Components/sales/sales-order/sales-order-form/sales-order-form.component';
import { SaleOrderListComponent } from './Components/sales/sales-order/sale-order-list/sale-order-list.component';
import { PurchaseOrderListComponent } from './Components/purchases/purchase-order/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderFormComponent } from './Components/purchases/purchase-order/purchase-order-form/purchase-order-form.component';
import { PurchaseShipmentListComponent } from './Components/purchases/purchase-shipment/purchase-shipment-list/purchase-shipment-list.component';
import { PurchaseShipmentFormComponent } from './Components/purchases/purchase-shipment/purchase-shipment-form/purchase-shipment-form.component';
import { SalesShipmentsFormComponent } from './Components/sales/sales-shipment/sales-shipment-form/sales-shipments-form.component';
import { SalesShipmentsListComponent } from './Components/sales/sales-shipment/sales-shipment-list/sales-shipments-list.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PasswordResetComponent } from './Components/password-reset/password-reset.component';
import { TransactionsModule } from './Components/transactions/transactions.module';
import { ArraySortPipe } from './Services/ArraySortPipe';
import { BarChartComponent } from './Components/home/bar-chart/bar-chart.component';
import { WorkOrdersListComponent } from './Components/work-orders/work-orders-list/work-orders-list.component';
import { WorkOrdersFormComponent } from './Components/work-orders/work-orders-form/work-orders-form.component';
import { TimesheetFormComponent } from './Components/timesheet/timesheet-form/timesheet-form.component';
import { TimesheetListComponent } from './Components/timesheet/timesheet-list/timesheet-list.component';
import { WorkOrdersPrintComponent } from './Components/work-orders/work-orders-print/work-orders-print.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ConfirmDialogService } from './Services/confirm-dialog.service';
import { HomeOnboardComponent } from './Components/home/home-onboard/home-onboard.component';
import { HomeScheduleComponent } from './Components/home/home-schedule/home-schedule.component';
import { EmployeeListComponent } from './Components/payroll/employee-list/employee-list.component';
import { EmployeeFormComponent } from './Components/payroll/employee-form/employee-form.component';
import { EarningDeductionListComponent } from './Components/payroll/earning-deduction-list/earning-deduction-list.component';
import { EarningDeductionFormComponent } from './Components/payroll/earning-deduction-form/earning-deduction-form.component';
import { PayrollPaymentListComponent } from './Components/payroll/payroll-payment-list/payroll-payment-list.component';
import { PayrollPaymentFormComponent } from './Components/payroll/payroll-payment-form/payroll-payment-form.component';
import { CustomFilterPipe } from './Components/admin/custom-filter.pipe';
import { ModalSelectEmployeeComponent } from './Components/payroll/modal-select-employee/modal-select-employee.component';
import { PayrollPaymentPrintComponent } from './Components/payroll/payroll-payment-print/payroll-payment-print.component';

// Manage - Resources
import { HomeManageControlDatesComponent } from './Components/home/home-manage-control-dates/home-manage-control-dates.component';
import { HomeManageDelinquentReceivablesComponent } from './Components/home/home-manage-delinquent-receivables/home-manage-delinquent-receivables.component';

// pos browser
import { PosDataBrowserListComponent } from './Components/pos/pos-data-browser-list/pos-data-browser-list.component';
import { HomeManageDelinquentPayablesComponent } from './Components/home/home-manage-delinquent-payables/home-manage-delinquent-payables.component';


// modules to share data across components
import { SharedDataService } from './Services/sharedData.service';

// encryption
import { EncrDecrService } from './Services/encr-decr.service';

// error interceptor
import { HttpInterceptorService } from './Services/http-interceptor.service';
import { JwtInterceptorService} from './Services/jwt-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// side panel
import { SidePanelComponent } from './Components/side-panel/side-panel.component';

// tooltips
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// prime ng
import { ChartModule } from 'primeng/chart';

import { TransactionLayoutComponent } from './Layouts/transaction-layout.component';
import {NgbAlertModule, NgbCollapseModule, NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';

// widgets

import { WidgetModule } from './Components/common/widget/widget.module';
import { WidgetsComponent } from './Components/widgets/widgets.component';

// full calendar
import {CalendarModule} from './Components/calendar/calendar.module';
import { VerifyEmailComponent } from './Components/verify-email/verify-email.component';

// angular datatables
import {DataTablesModule} from 'angular-datatables';

// ng-idle
import {NgIdleModule} from '@ng-idle/core';

@NgModule({
  declarations: [
    AppComponent,
    ArraySortPipe,
    LoginLayoutComponent,
    ForgotPasswordComponent,
    AppLayoutComponent,
    PrintLayoutComponent,
    LoginComponent,
    HeaderComponent,

    EntityListComponent,
    EntityFormComponent,
    EntityHistoryComponent,
    EntityActivityFeedComponent,
    EntityDetailComponent,

    HomeComponent,

    EntityContactComponent,
    EntityAddressComponent,

    QuickLinkComponent,
    HomeNavigationComponent,
    HomeDashboardComponent,
    HomeManageComponent,
    SalesOrderFormComponent,
    SaleOrderListComponent,
    PurchaseOrderListComponent,
    PurchaseOrderFormComponent,
    SalesShipmentsFormComponent,
    SalesShipmentsListComponent,
    PurchaseShipmentListComponent,
    PurchaseShipmentFormComponent,
    PasswordResetComponent,
    BarChartComponent,
    WorkOrdersListComponent,
    WorkOrdersFormComponent,
    TimesheetFormComponent,
    TimesheetListComponent,
    WorkOrdersPrintComponent,
    HomeOnboardComponent,
    EmployeeListComponent,
    EmployeeFormComponent,
    EarningDeductionListComponent,
    EarningDeductionFormComponent,
    PayrollPaymentListComponent,
    PayrollPaymentFormComponent,
    ModalSelectEmployeeComponent,
    PayrollPaymentPrintComponent,
    HomeScheduleComponent,
    HomeManageControlDatesComponent,
    HomeManageDelinquentReceivablesComponent,
    PosDataBrowserListComponent,
    HomeManageDelinquentPayablesComponent,
    SidePanelComponent,
    TransactionLayoutComponent,
    WidgetsComponent,
    VerifyEmailComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AdminModule,
    ReportsModule,
    InventoryModule,
    // PayrollModule,
    InventoryModule,
    TypeaheadModule.forRoot(),
    BrowserAnimationsModule,
    TransactionsModule,
    FilterPipeModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    ChartModule,
    WidgetModule,
    NgbCollapseModule,
    SimplebarAngularModule,
    CalendarModule,
    NgbDropdownModule,
    NgbAlertModule,
    DataTablesModule,
    NgbPaginationModule,
    NgIdleModule.forRoot()
  ],
  providers: [
    TableDataService,
    Title,
    AppConfigService,
    PreferencesService,
    PermissionsService,
    AppLayoutComponent,
    LoginLayoutComponent,
    PrintLayoutComponent,
    DateFilterService,
    AppLocationService,
    ConfirmDialogService,
    // for shared data
    SharedDataService,
    // end
    // encryption
    EncrDecrService,
    // end
    CustomFilterPipe,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          return appConfigService.loadAppConfig();
        };
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    PasswordResetComponent,
    ArraySortPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
