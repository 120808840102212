import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalanceSheetComponent } from './financial/balance-sheet/balance-sheet.component';
import { SharedModule } from '../common/shared.module';
import { ConsolidatedIncomeStatementComponent } from './financial/consolidated-income-statement/consolidated-income-statement.component';
import { MarginReportComponent } from './financial/margin-report/margin-report.component';
import { TrialBalanceComponent } from './financial/trial-balance/trial-balance.component';
import { TransactionDetailComponent } from './transaction-reports/transaction-detail/transaction-detail.component';
import { IncomeStatementComponent } from './financial/income-statement/income-statement.component';
import { InvoicesComponent } from './transaction-reports/invoices/invoices.component';
import { PaymentsComponent } from './transaction-reports/payments/payments.component';
import { StatementsComponent } from './transaction-reports/statements/statements.component';
import { TaxComponent } from './transaction-reports/tax/tax.component';
import { AgingComponent } from './transaction-reports/aging/aging.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
// extra transaction-reports
// import {TimesheetReportComponent} from './Components/Reports/activity/transaction-reports/timesheet/timesheet.component';
// inventory report - temprory
import { BalanceReportComponent } from './inventory/reports/balance-report/balance-report.component';
import { AssemblyReportComponent } from './inventory/reports/assembly-report/assembly-report.component';
import { TransferReportComponent } from './inventory/reports/transfer-report/transfer-report.component';
import { CountReportComponent } from './inventory/reports/count-report/count-report.component';
import { EntityReconciliationComponent } from './entity/entity-reconciliation/entity-reconciliation.component';

import { CashFlowComponent } from './financial/cash-flow/cash-flow.component';

// Payroll - Report
import { T4ReportComponent } from './payroll/reports/t4-report/t4-report.component';
import { PayableReportComponent } from './payroll/reports/payable-report/payable-report.component';
import { EmployeeReportComponent } from './payroll/reports/employee-report/employee-report.component';
import { EmployeeDetailReportComponent } from './payroll/reports/employee-detail-report/employee-detail-report.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '../../app-routing.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AuditTrailComponent } from '../common/audit-trail/audit-trail.component';


// common components
import { CompanySelectComponent } from './report-resources/company-select/company-select.component';
import { ArraySortPipe } from './transaction-reports/orderBy';
import { FromToDateSelectComponent } from './report-resources/from-to-date-select/from-to-date-select.component';
import { ReportTableHeaderComponent } from './report-resources/report-table-header/report-table-header.component';
import { LocationSelectComponent } from './report-resources/location-select/location-select.component';
import { LocationGroupSelectComponent } from './report-resources/location-group-select/location-group-select.component';
import { CurrencySelectComponent } from './report-resources/currency-select/currency-select.component';
import { ReportingFieldSelectComponent } from './report-resources/reporting-field-select/reporting-field-select.component';
import { AccountSelectComponent } from './report-resources/account-select/account-select.component';
import { AccountSubtotalSelectComponent } from './report-resources/account-subtotal-select/account-subtotal-select.component';
import { SubtotalParentSelectComponent } from './report-resources/subtotal-parent-select/subtotal-parent-select.component';
import { AccountTypeSelectComponent } from './report-resources/account-type-select/account-type-select.component';
import { ItemSelectComponent } from './report-resources/item-select/item-select.component';
import { EntitySelectComponent } from './report-resources/entity-select/entity-select.component';
import { EntityTypeSelectComponent } from './report-resources/entity-type-select/entity-type-select.component';
import { EntityCitySelectComponent } from './report-resources/entity-city-select/entity-city-select.component';
import { EntityStateSelectComponent } from './report-resources/entity-state-select/entity-state-select.component';
import { BankSelectComponent } from './report-resources/bank-select/bank-select.component';
import { PaymentSelectComponent } from './report-resources/payment-select/payment-select.component';
import { BatchSelectComponent } from './report-resources/batch-select/batch-select.component';
import { EntitySelectModalComponent } from './report-resources/entity-select-modal/entity-select-modal.component';
import { EntitySelectOnlyComponent } from './report-resources/entity-select-only/entity-select-only.component';
import { InvoiceSelectComponent } from './report-resources/invoice-select/invoice-select.component';
import { MarginSelectComponent } from './report-resources/margin-select/margin-select.component';
import { ModelSelectItemComponent } from './report-resources/model-select-item/model-select-item.component';
import { OrderSelectComponent } from './report-resources/order-select/order-select.component';
import { ReportTableHeaderV2Component } from './report-resources/report-table-header-v2/report-table-header-v2.component';
import { TaxSelectComponent } from './report-resources/tax-select/tax-select.component';
import { ButtonsEmailComponent } from './report-resources/buttons-email/buttons-email.component';
import { ReportButtonsComponent} from './report-resources/report-buttons/report-buttons.component';
import { ButtonsEmailV2Component } from './report-resources/buttons-email-v2/buttons-email-v2.component';
import { PaymentTypeSelectComponent } from './report-resources/payment-type-select/payment-type-select.component';
import { TimesheetReportComponent } from './activity/reports/timesheet-report/timesheet-report.component';
import { EntityHistoryReportComponent } from './entity/entity-history-report/entity-history-report.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalItemHistoryComponent } from './report-resources/modal-item-history/modal-item-history.component';
import { WorkorderReportComponent } from './activity/reports/workorder-report/workorder-report.component';
import { AdminModule } from '../admin/admin.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ArraySortPipe,
    BalanceSheetComponent,
    ConsolidatedIncomeStatementComponent,
    MarginReportComponent,
    TrialBalanceComponent,
    TransactionDetailComponent,
    IncomeStatementComponent,
    BalanceReportComponent,
    AssemblyReportComponent,
    TransferReportComponent,
    CountReportComponent,
    InvoicesComponent,
    PaymentsComponent,
    StatementsComponent,
    TaxComponent,
    AgingComponent,
    EntityReconciliationComponent,
    CashFlowComponent,

    // payroll
    T4ReportComponent,
    PayableReportComponent,
    EmployeeReportComponent,
    EmployeeDetailReportComponent,

    // common components
    CompanySelectComponent,
    FromToDateSelectComponent,
    ReportTableHeaderComponent,
    LocationSelectComponent,
    LocationGroupSelectComponent,
    CurrencySelectComponent,
    ReportingFieldSelectComponent,
    AccountSelectComponent,
    AccountSubtotalSelectComponent,
    SubtotalParentSelectComponent,
    AccountTypeSelectComponent,
    ItemSelectComponent,
    EntitySelectComponent,
    EntityTypeSelectComponent,
    EntityCitySelectComponent,
    EntityStateSelectComponent,
    BankSelectComponent,
    PaymentSelectComponent,
    BatchSelectComponent,
    EntitySelectModalComponent,
    EntitySelectOnlyComponent,
    InvoiceSelectComponent,
    MarginSelectComponent,
    ModelSelectItemComponent,
    OrderSelectComponent,
    ReportTableHeaderV2Component,
    TaxSelectComponent,
    ButtonsEmailComponent,
    ReportButtonsComponent,
    ButtonsEmailV2Component,
    PaymentTypeSelectComponent,
    TimesheetReportComponent,
    EntityHistoryReportComponent,
    ModalItemHistoryComponent,
    WorkorderReportComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule, // required animations module
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    FilterPipeModule,
    TypeaheadModule,
    AdminModule,
    TooltipModule,
    NgbDropdownModule,
  ],
  exports: [
    AuditTrailComponent,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ToastrModule,
    BrowserAnimationsModule,
    BsDatepickerModule,
    CompanySelectComponent,
    FromToDateSelectComponent,
    ReportTableHeaderComponent,
    LocationSelectComponent,
    LocationGroupSelectComponent,
    CurrencySelectComponent,
    ReportingFieldSelectComponent,
    AccountSelectComponent,
    AccountSubtotalSelectComponent,
    SubtotalParentSelectComponent,
    AccountTypeSelectComponent,
    ItemSelectComponent,
    EntitySelectComponent,
    EntityTypeSelectComponent,
    EntityCitySelectComponent,
    EntityStateSelectComponent,
    BankSelectComponent,
    PaymentSelectComponent,
    BatchSelectComponent,
    EntitySelectModalComponent,
    EntitySelectOnlyComponent,
    InvoiceSelectComponent,
    MarginSelectComponent,
    ModelSelectItemComponent,
    OrderSelectComponent,
    ReportTableHeaderV2Component,
    TaxSelectComponent,
    TypeaheadModule,
    ButtonsEmailComponent,
    ReportButtonsComponent,
    ButtonsEmailV2Component,
    PaymentTypeSelectComponent,
    ModalItemHistoryComponent,
  ],
})
export class ReportsModule {}
