import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AppConfigService} from './app-config.service';
import {BsToastService} from './bs-toast-service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public configuration: any = {
    timeOut: 1000,
    positionClass: 'toast-top-right',
    enableHtml: true,
    extendedTimeOut: 0,
    closeButton: false,
    closeTime: 1000
  };

  timeout: number;
  close: number;

  constructor(
    public aToastr: ToastrService,
    public appConfigService: AppConfigService,
    public bsToast: BsToastService) {

    this.timeout = this.appConfigService.toastrWarnTimeOut;
    this.close = this.appConfigService.toastrWarnTimeOut;
  }

  // TODO NOTES - this service can be removed at some point when old toastr is assured removed.
  //  It is being used to run any toast coming across old system and to save researching the usage of
  //  the service now. In the future you can run a find replace on the this service and replace with bstoastservice
  //  which is imported here for now - just so we can sort jump between the two service temporarily as we migrate to bestoastservice

  success(message) {
    this.bsSuccess(message);
    this.configuration.closeButton = false;
    this.configuration.timeOut = this.timeout;
    this.configuration.closeTime = this.close;
    // this.aToastr.success(message, 'Success', this.configuration);
  }

  bsSuccess(message: string) {
    this.bsToast.showSuccessToast(message, 'Success');
  }

  error(message) {
    // toss the undefineds - will surface when throwing other http errors the interceptor picks off
    if (message.toUpperCase().includes('UNDEFINED')) {
      return;
    } else {
      this.configuration.closeButton = false;
      this.configuration.timeOut = this.timeout;
      this.configuration.closeTime = this.close;
      this.bsError(message);
      // console.log(message);
      // this.aToastr.error(message, 'Error', this.configuration);
    }
  }

  bsError(message: string) {
    // console.log(message);
    this.bsToast.showErrorToast(message, 'Error' );
  }

  errorStayOnScreen(message) {
    this.configuration.closeButton = true;
    this.configuration.extendedTimeOut = 0;
    this.configuration.timeOut = 0;
    this.configuration.tapToDismiss = true;
    // console.log(message);
    this.bsError(message);
    // this.aToastr.error(message, 'Error' , this.configuration);
  }

  warning(message) {
    this.configuration.closeButton = false;
    this.configuration.timeOut = this.timeout;
    this.configuration.closeTime = this.close;
    // this.aToastr.warning(message, 'Warning', this.configuration);
    this.bsWarning(message);
  }

  bsWarning(message: string) {
    this.bsToast.showWarningToast(message, 'Warning' );
  }

  warningStayOnScreen(message) {
    this.configuration.closeButton = true;
    this.configuration.extendedTimeOut = 0;
    this.configuration.timeOut = 0;
    this.configuration.tapToDismiss = false;
    this.bsWarning(message);
    // this.aToastr.warning(message, 'Warning', this.configuration);
  }

  info(message) {
    this.configuration.closeButton = false;
    this.configuration.timeOut = this.timeout;
    this.configuration.closeTime = this.close;
    // this.aToastr.info(message, 'Info', this.configuration);
    this.bsInfo(message);
  }

  bsInfo(message: string) {
    this.bsToast.showInfoToast(message, 'Info');
  }
}
