import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EntityAccessModel} from '../../../../DataModels/entity-access-model';
import {TableDataService} from '../../../../Services/tableData.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SysUserModel} from '../../../../DataModels/sys-user-model';
import {EntityModel} from '../../../../DataModels/entity-model';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import { BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-entity-access-form',
  templateUrl: './entity-access-form.component.html',
  styleUrls: ['./entity-access-form.component.css']
})
export class EntityAccessFormComponent implements OnInit {
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  entityAccessID;
  private entityAccess: EntityAccessModel;
  entityAccessForm: UntypedFormGroup;

  user$: Observable<SysUserModel[]>;
  entity$: Observable<EntityModel[]>;

  batchlocked = 0;

  paid = false;
  showDelete = false;
  submitted = false;

  constructor(private table: TableDataService,
              private notification: BsToastService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDialogService: ConfirmDialogService,
              private titleService: Title) {
    this.createForm();
    this.entityAccessID = this.route.snapshot.params.id;
  }

  get f() {
    return this.entityAccessForm.controls;
  }

  createForm() {
    this.entityAccessForm = this.formBuilder.group({
      user_id: new UntypedFormControl(''),
      entity_id: new UntypedFormControl(''),
    });
  }
  ngOnInit() {
    this.loadLookups();
    if (this.entityAccessID) {
      this.LoadData();
      this.titleService.setTitle($localize`:Entity Access @@entityAccess:Entity Access`);
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('EntityAccess', this.entityAccessID).subscribe(
      result => {
        this.entityAccess = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Entity Access @@errorLoadingEntityAccess:Error loading Entity Access`);
        console.log(error + ' - ' + $localize`:Error loading Entity Access @@errorLoadingEntityAccess:Error loading Entity Access`);
      },
      () => {
        this.entityAccessForm.patchValue(this.entityAccess);
      });
  }

  private loadLookups(){
    this.table.getApiListData('entity', '?state=active&pageNumber=0&pageSize=999999').subscribe((res: any) => {
      const data: any = res.Data;
      this.entity$ = data;
    });
    this.table.getApiListData('sys_user', '?state=active&pageNumber=0&pageSize=999999').subscribe((res: any) => {
      const data: any = res.Data;
      this.user$ = data;
    });
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.entityAccessForm.reset();
    }
  }

  resetForm() {
    this.entityAccessForm.reset();
  }

   onClose() {
    this.router.navigate(['admin/entity-access-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete entity access? @@areYouSureYouWantToDeleteEntityAccess:Are you sure you want to delete entity access?`, this.entityAccess.entity_id];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('EntityAccess', this.entityAccessID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.entityAccessForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.entityAccess = Object.assign({}, this.entityAccessForm.value);
    if (this.entityAccessID) {
      if (this.entityAccessForm.invalid === false) {
        this.table.patch('EntityAccess', this.entityAccessID, this.entityAccess).then(result => {
            if (saveClose){
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          });
        // return true;
      }

    } else {
      this.table.postWithPromise('EntityAccess', this.entityAccess).then(res => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.entityAccessID = apiReturn.id;
        if (saveClose){
          this.onClose();
        }
      });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
