import {Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-modal-new-account',
  templateUrl: './modal-new-account.component.html',
  styleUrls: ['./modal-new-account.component.css']
})
export class ModalNewAccountComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() vm: any;
  @Output() saveNewAccountEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  saveNewAccount(){
    this.saveNewAccountEmit.emit();
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
