import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../Services/notification.service';
import {finalize, first} from 'rxjs/operators';
import {TableDataService} from '../../Services/tableData.service';
import {ActivatedRoute} from '@angular/router';
import {ResetPasswordModel} from '../../DataModels/reset-password-model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  // form: FormGroup;
  loading = false;
  submitted = false;
  showPassword = false;

  resetPassswordForm!: UntypedFormGroup;
  formSubmitted: boolean = false;
  successMessage: string = '';

  today: number = Date.now();

  urlParams: any = [];
  resetCode = '';
  resetId = '';
  resetVals: ResetPasswordModel;

  constructor(private fb: UntypedFormBuilder,
              private alertService: NotificationService,
              private tableDataService: TableDataService,
              public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.resetPassswordForm = this.fb.group({
        code: new UntypedFormControl(''),
        id: new UntypedFormControl(''),
        password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
        confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])]
    },
      { validators: this.mustMatch('password', 'confirmPassword')
      });

    // get code and email from url and place in field to post to api
    this.urlParams = this.route.snapshot.queryParams;

    // console.log('code: ' + this.urlParams.code);
    // console.log('id: ' + this.urlParams.id);

    this.resetCode = this.urlParams.code;
    this.resetId = this.urlParams.id;

    this.resetPassswordForm.controls.code.setValue(this.resetCode);
    this.resetPassswordForm.controls.id.setValue(this.resetId);
  }

  ngAfterViewInit(): void {
   //  document.body.classList.add('authentication-bg');
  }

  getUser() {
  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() {
    return this.resetPassswordForm.controls;
  }

  /**
   * validator
   */
  mustMatch(password: any, confirmPassword: any) {
    // console.log ('mustmatch:' + password + ' -- ' + confirmPassword);
    return (formGroup: UntypedFormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.mustMatch){
        return;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({mustMatch: true});
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.resetPassswordForm.valid) {
       this.successMessage = 'Your Password has been successfully reset!';
       // return;
    }
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPassswordForm.invalid) {
      this.alertService.error('There are form errors!');
      return;
    }

    this.loading = true;
    // pull the vars from form into the model we send
    this.resetVals = Object.assign({}, this.resetPassswordForm.value);

    // console.log('posting code and pw to reset: ' + JSON.stringify(this.resetVals));

    this.tableDataService.post('identity/account/reset-password', this.resetVals)
      .pipe(first())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => this.alertService.success('Password successfully reset'),
        error: error => this.alertService.error(error)
      });
  }
}
