import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal-uninvoiced-workorders',
  templateUrl: './modal-uninvoiced-workorders.component.html',
  styleUrls: ['./modal-uninvoiced-workorders.component.css']
})
export class ModalUninvoicedWorkordersComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }

  generateWorkOrderInvoice(id) {
    // $('#uninvoicedWorkOrders').modal('hide');
    // $state.go('app.admin.transactions.invoice.form', { invoiceId: 'newAR', workorderId: id });
    this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newAR&workorderId=' + id);
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
