import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../../../../Services/email.service';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../../Services/notification.service';
import { EmailPassDataServiceService } from '../../../../../Services/email-pass-data-service.service';
import { ResponseModel } from '../../../../../DataModels/response-model';
import { BsToastService } from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-assembly-report',
  templateUrl: './assembly-report.component.html',
  styleUrls: ['./assembly-report.component.css'],
})
export class AssemblyReportComponent implements OnInit, AfterViewInit, AfterViewChecked {
  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    public aToastr: BsToastService,
    private titleService: Title,
    private router: Router,
    public notification: NotificationService,
    public appConfigService: AppConfigService,
    public route: ActivatedRoute,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    private emailData: EmailPassDataServiceService
  ) {
    this.timeOut = this.appConfigService.toastrWarnTimeOut;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;

  emailForm: UntypedFormGroup;
  rptName: any;
  timeOut = 0;
  public urlParams: any = [];
  public emailInvalidMsg: string;

  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      columns: null,
      location_group_id: null,
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: '',
    },
    columns: false,
    company: [],
    location: [],
    currency: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    monthCount: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    startInventoryItem: 0,
    Preferences: null,
    permissions: null,
    currentMonthMargin: 0,
    priorMonthMargin: 0,
    currentPeriodMargin: 0,
    priorPeriodMargin: 0,
    account: [],
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    caclutedDate: null,
    toDateTitle: null,
    fromDateTitle: null,
    priorToDateTitle: null,
    priorToDateLink: null,
    priorMonthFromDateLink: null,
    currentMonthFromDateLink: null,
    priorFromDateLink: null,
    priorFromDateTitle: null,
    accountParents: [],
    accountSubtotal: [],
    bank: [],
    entity: [],
    entityAddress: [],
    entityType: [],
    paymentType: [],
    inventoryItem: [],
    accountType: [],
    inventoryItemName: [],
    companyId: null,
    currencyId: null,
    locationId: null,
    companyName: null,
    locationName: null,
    inventoryItemInput: '',
    inventoryItemFilter: { search_string: '' },
  };
  public submitted = false;

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Assembly Report @@assemblyReport:Assembly Report`;
  }

  onSubmit() {
    this.emailForm.reset();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  // pass it this on call
  // to = this.urlParams.toDate
  // from = this.urlParams.fromDate
  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService
      .getApiListData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getApiListData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.tableDataService
      .getApiListData('location?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });

    this.tableDataService
      .getApiListData('item?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.inventoryItem = data;
        for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
          this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
        }
      });

    // if (this.localStorage.StorageGet('company', '')) {
    //   this.vm.company = JSON.parse(this.localStorage.StorageGet('company'));
    // } else {
    //   this.localStorage.StorageSet('company', this.vm.company);
    // }
    // if (this.localStorage.StorageGet('location', '')) {
    //   this.vm.location = this.localStorage.StorageGet('location', '');
    // } else {
    //   this.localStorage.StorageSet('location', this.vm.location);
    // }
    // if (this.localStorage.StorageGet('currency', '')) {
    //   this.vm.currency = this.localStorage.StorageGet('currency', '');
    // } else {
    //   this.localStorage.StorageSet('currency', this.vm.currency);
    // }
    // if (this.localStorage.StorageGet('inventoryItem', '')) {
    //   this.vm.inventoryItem = this.localStorage.StorageGet('inventoryItem', '');
    //   for (let x = 0; x < this.vm.inventoryItem.length; x++) {
    //     this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
    //   }
    // } else {
    //   this.localStorage.StorageSet('inventoryItem', this.vm.inventoryItem);
    // }

    if (this.urlParams.locationId) {
      this.vm.request.location_id = this.urlParams.locationId;
    }

    if (this.urlParams.currency_id) {
      this.vm.request.currency_id = this.urlParams.currency_id;
    }

    if (
      this.vm.request.toDate &&
      this.vm.request.fromDate &&
      (this.urlParams.companyId || this.urlParams.currencyId || this.urlParams.locationId || this.urlParams.itemId)
    ) {
      this.update();
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  update() {
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.fromDate) {
      this.vm.dateError = true;
      errorDescription += $localize`:A from date is required. @@afromdateisrequired:A from date is required.` + ' ';
    }
    if (!this.vm.request.toDate) {
      this.vm.dateError = true;
      errorDescription += $localize`:A To date is required @@aTodateisrequired:A To date is required` + ' ';
    }

    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    if (tempToDate < tempFromDate) {
      errorDescription += $localize`:From date must be less than the To date @@fromdatemustbelessthantheTodate:From date must be less than the To date`;
      this.vm.toDateError = true;
      this.vm.fromDateError = true;
    }
    if (errorDescription) {
      this.notification.warning(errorDescription);
    } else {
      this.vm.dateError = this.vm.emailed = false;
      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.buttonsDisabled = true;
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      const urlParamsJson = {
        fromDate: this.vm.request.fromDate,
        toDate: this.vm.request.toDate,
        company_id: this.vm.request.company_id,
        location_id: this.vm.request.location_id,
        item_id: this.vm.request.item_id,
        currency_id: this.vm.request.currency_id,
      };

      const AssemblyReportParam: string = this.encodeQueryData(urlParamsJson);
      this.tableDataService.getTableData('AssemblyReport', '?' + AssemblyReportParam).subscribe((result) => {
        this.vm.report = result;
        this.vm.showFilters = this.vm.buttonsDisabled = false;
        if (
          (this.vm.request.company_id && this.vm.company.length > 1) ||
          this.vm.request.location_id ||
          (this.vm.request.currency_id && this.vm.currency.length > 1) ||
          this.vm.request.item_id
        ) {
          this.vm.filtered = true;
        }

        if (this.vm.request.company_id) {
          this.vm.companyName = this.FilterObject(this.vm.company, { id: this.vm.request.company_id })[0].name;
        } else {
          this.vm.companyName = '';
        }

        if (this.vm.request.location_id) {
          this.vm.locationName = this.FilterObject(this.vm.location, { id: this.vm.request.location_id })[0].name;
        } else {
          this.vm.locationName = '';
        }

        if (this.vm.request.item_id) {
          this.vm.inventoryItemName = this.FilterObject(this.vm.inventoryItem, { id: this.vm.request.item_id })[0].name;
        } else {
          this.vm.inventoryItemName = [];
        }
      });
    }
  }

  updateInventoryItem() {
    this.vm.inventoryItemName = [];
    this.localStorage.StorageSet('inventoryItem', '');
    this.tableDataService.getTableData('item', '?state=active').subscribe((result) => {
      this.vm.inventoryItem = result;
      console.log(result);
      for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
        this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
      }
    });
  }

  ngAfterViewChecked() {
    // alert('viewChecked fired');
    // sets data for email of report
    const div = document.getElementById('excel-export');

    // console.log(typeof div);
    // console.log(div.toString());
    // console.log(typeof div.outerHTML);
    // console.log('viewchk: ' + div.outerHTML);
    // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Assembly Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  selectItemModal(itemId) {
    this.vm.request.item_id = itemId;
    for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
      if (this.vm.inventoryItem[x].id === itemId) {
        this.vm.inventoryItemInput = this.vm.inventoryItem[x].code + ' ' + this.vm.inventoryItem[x].name;
        break;
      }
    }
  }

  testItem() {
    const itemIdStart = this.vm.request.item_id;
    if (!this.vm.inventoryItemInput || this.vm.inventoryItemInput == '') {
      this.vm.request.item_id = '';
    } else {
      if (this.FilterObject(this.vm.inventoryItem, { search_string: this.vm.inventoryItemInput }, true).length > 0) {
        this.vm.request.item_id = this.FilterObject(
          this.vm.inventoryItem,
          { search_string: this.vm.inventoryItemInput },
          true
        )[0].id;
      } else {
        this.vm.request.item_id = '';
        this.vm.inventoryItemInput = '';
      }
    }
    if (itemIdStart != this.vm.request.item_id) {
      this.vm.report = [];
    }
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.vm.inventoryItemInput = '';
    this.vm.report = [];
    this.vm.request = {
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      columns: null,
      location_group_id: null,
    };
    this.vm.filtered = false;
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' - ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  FilterObject(value, filterObj, test = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  MathRound(int: number) {
    return Math.round(int);
  }

  parseInt(val) {
    return parseInt(val, 10);
  }

  Print() {
    return window.print();
  }

  setCompanyId(event) {
    this.vm.report = [];
    this.vm.request.company_id = event;
  }

  filterReport() {
    this.vm.report = [];
  }
}
