import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-invoice-select',
  templateUrl: './invoice-select.component.html',
  styleUrls: ['./invoice-select.component.css']
})
export class InvoiceSelectComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;

  @Output() testInvoiceEmit = new EventEmitter<string>();
  @Output() updateInvoiceEmit = new EventEmitter<string>();
  @Output() selectInvoiceEmit = new EventEmitter<string>();
  public detail = {id: null};

  constructor() {
  }

  ngOnInit() {
  //  console.log(this.vm);
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
  testInvoice() {
    this.testInvoiceEmit.emit();
  }

  updateInvoice() {
    this.updateInvoiceEmit.emit();
  }

  selectInvoice(InvoiceId) {
    // $('#InvoiceSelect').modal('hide');
    this.selectInvoiceEmit.emit(InvoiceId);
  }
}
