import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { StorageService } from '../Services/storage.service';

@Component({
  selector: 'app-layout-layout',
  template: ` <div class="wrapper">
    <app-side-panel></app-side-panel>

    <div class="content-page">
      <div class="content">
        <app-header></app-header>
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer footer-alt text-muted text-center">
    2020 - {{today | date : 'yyyy'}} AccountX <a class="text-sm align-text-top">&copy;</a>
  </footer>`,
  styles: [],
})
export class AppLayoutComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  private sidePanelLoaded: boolean;
  today: number = Date.now();

  constructor() {
    this.sidePanelLoaded = true;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {}

  ngAfterContentChecked(): void {
    this.sidePanelLoaded = false;
  }
}
