import {AfterViewInit, Component, OnInit} from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountModel } from '../../../../DataModels/account-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { AccountSubtotalModel } from '../../../../DataModels/account-subtotal-model';
import { AccountMarginModel } from '../../../../DataModels/account-margin-model';
import { UomModel } from '../../../../DataModels/uom-model';
import { ResponseModel } from '../../../../DataModels/response-model';
import { take } from 'rxjs/operators';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

declare var $: any; // popover stuff

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.css'],
})
export class AccountFormComponent implements OnInit, AfterViewInit {
  account: AccountModel;
  accountForm: UntypedFormGroup;
  accountID;

  subTotal: AccountSubtotalModel[];
  margin: AccountMarginModel[];
  uom: UomModel[];
  submitted = false;
  stopEdit = false;
  field: object;
  hierarchicalAccounts: any;
  items: any;
  searchText: string;

  editSubtotalId: string;
  deleteSubtotalId: string;
  deleteSubtotalName: string;

  editAccountId: any;

  auditRecId: string;

  buttonConfig: any = {
    save: false,
    close: true,
    reset: false,
    delete: false,
    saveNew: false,
    saveClose: false,
    print: false,
  };

  constructor (
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();

    this.searchText = this.route.snapshot.params.id;
    this.accountForm.patchValue({ search: this.searchText });
  }

  createForm() {
    this.accountForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      number: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      subtotal_id: new UntypedFormControl(''),
      margin_id: new UntypedFormControl(''),
      uom_id: new UntypedFormControl(''),
      debit: new UntypedFormControl(true),
      search: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
    });
  }

  ngOnInit() {
    $('[data-toggle="popover"]').popover();

    this.account = new AccountModel();

    this.loadAccountHierarchy().then();
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Accounts @@accounts:Accounts`);
  }

  getChildListPopover(subId: string) {
    // alert('get children for: ' + subId);
    let matchingSubs = [];
    let subNameList = '';
    matchingSubs = this.hierarchicalAccounts.filter((record) => {
      return record.parent_id === subId;
    });
    subNameList += matchingSubs.map(
      // (element) => '&#x2022; <a>' + element.subtotal_name + '<a></br>'// for ngx toast format
      (element) => ' &#x2022; <a> ' + element.subtotal_name + '<a><br>'
    );
    const finalList = subNameList.replace(/,/g, '');

    // console.log(JSON.stringify(finalList));

    this.notification.showInfoToast(finalList);
  }

  get f() {
    return this.accountForm.controls;
  }

  private async loadAccountHierarchy() {
    // load the main table
    await this.table
      .getTableData('account/nested?pageNumber=0&pageSize=999999')
      .pipe(take(1))
      .subscribe({
        next: (result: ResponseModel) => {
          this.hierarchicalAccounts = result.Data;
          // console.log('data: ' + JSON.stringify(result.Data));
          this.accountForm.patchValue(this.account);
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {
          // do stuff on complete
        },
      });
  }

  loadLookups() {
    this.table
      .getApiListData('account_subtotal?pageNumber=0&pageSize=999999')
      .pipe(take(1))
      .subscribe((res: ResponseModel) => {
        this.subTotal = res.Data;
      });

    this.table
      .getApiListData('account_margin?pageNumber=0&pageSize=999999')
      .pipe(take(1))
      .subscribe((res: ResponseModel) => {
        this.margin = res.Data;
      });

    this.table
      .getApiListData('uom?pageNumber=0&pageSize=999999')
      .pipe(take(1))
      .subscribe((res: ResponseModel) => {
        this.uom = res.Data;
      });
  }

  setEditAccountId(id: any) {
    this.editAccountId = id;
  }

  setEditSubtotalId(id: any) {
    this.editSubtotalId = id;
  }

  setDeleteSubtotalId(id: any) {
    this.deleteSubtotalId = id;
  }

  checkModalReturn(retVal: any) {
    // console.log('retVal: ' + retVal);
    if (retVal === 'acct-saved') {
      this.loadAccountHierarchy().then();
    } else if (retVal === 'sub-saved') {
      this.loadAccountHierarchy().then();
    }
  }

  saveClose() {
    // currently still tied to button bar - just turned off button in config for now
  }

  saveNew() {
    // currently still tied to button bar - just turned off button in config for now
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete account? @@areYouSureYouWantToDeleteAccount:Are you sure you want to delete account?`,
      this.account.number + ' : ' + this.account.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table
          .deleteTableDataById('account', this.accountID)
          .pipe(take(1))
          .subscribe(
            (result) => {
              retMsg = result;
              // success
            },
            (error: HttpErrorResponse) => {
              console.log(error);
            },
            () => {
              this.loadAccountHierarchy().then();
            }
          );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  deleteSubtotalItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Confirmation for Delete Account Subtotal @@confirmDeleteAccountSubtotal:Are you sure you want to delete account subtotal?`,
      this.deleteSubtotalName,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table
          .deleteTableDataById('account_subtotal', this.deleteSubtotalId)
          .pipe(take(1))
          .subscribe(
            (result) => {
              retMsg = result;
              // success
            },
            (error: HttpErrorResponse) => {
              // this.notification.error($localize`:Error @@error:Error`);
              console.log(error);
            },
            () => {
              // this.onClose();
              this.loadAccountHierarchy().then();
            }
          );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  resetForm() {
    this.accountForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/account-list']).then();
  }

  onSave() {
    // currently still tied to button bar - just turned off button in config for now
  }
}
