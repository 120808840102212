import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';
import {FilterPipeModule} from 'ngx-filter-pipe';
import { SendEmailComponent } from './send-email/send-email.component';
import { SendEmailModalComponent} from './send-email-modal/send-email-modal.component';
import { DecimalNewPipe } from './DecimalNewPipe';
import { CustomFilterPipe } from '../admin/custom-filter.pipe';

import { ListButtonsComponent } from './list-buttons/list-buttons.component';
import { ActivityFeedComponent } from './activity-feed/activity-feed.component';
import { ArraySortPipe } from './orderBy';
import { Prevent2xClickDirective } from './prevent-2xClick.directive';
import { SearchComponent } from './search/search.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { Error400PageComponent } from './error400-page/error400-page.component';
import { SearchFilterPipe } from './search-filter/search-filter.pipe';
import { AccountModalComponent } from './account-modal/account-modal.component';
import { SubtotalForAccountModalComponent } from './subtotal-for-account-modal/subtotal-for-account-modal.component';
import { AuditTrailModalComponent } from './audit-trail-modal/audit-trail-modal.component';
import {WidgetModule} from './widget/widget.module';
import { Error500PageComponent } from './error500-page/error500-page.component';
import {ResetPasswordComponent} from '../reset-password/reset-password.component';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { BsToastComponent } from './bs-toast/bs-toast-component';
import { BsToasterComponent } from './bs-toaster/bs-toaster.component';
import { ActivityFeedModalComponent } from './activity-feed-modal/activity-feed-modal.component';
import { CustomArrayFilterPipe } from './custom-array-filter.pipe';
import { ChangeBatchModalComponent } from './change-batch-modal/change-batch-modal.component';
import { ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import { BatchNewAndPostModalComponent } from './batch-new-and-post-modal/batch-new-and-post-modal.component';
import {FormButtonsComponent} from './form-buttons/form-buttons.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { OnlyNumberDirective } from './only-number.directive';
import { OnlyDecimalDirective} from './only-decimal.directive';


@NgModule({
  declarations: [
    AuditTrailComponent,
    SendEmailComponent,
    SendEmailModalComponent,
    DecimalNewPipe,
    CustomFilterPipe,
    ListButtonsComponent,
    FormButtonsComponent,
    ActivityFeedComponent,
    ArraySortPipe,
    Prevent2xClickDirective,
    SearchComponent,
    FileUploadComponent,
    Error400PageComponent,
    SearchFilterPipe,
    AccountModalComponent,
    SubtotalForAccountModalComponent,
    AuditTrailModalComponent,
    Error500PageComponent,
    ResetPasswordComponent,
    BsToastComponent,
    BsToasterComponent,
    ActivityFeedModalComponent,
    CustomArrayFilterPipe,
    ChangeBatchModalComponent,
    ConfirmDialogComponent,
    BatchNewAndPostModalComponent,
    OnlyNumberDirective,
    OnlyDecimalDirective,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule, // required animations module
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    TypeaheadModule,
    FilterPipeModule,
    WidgetModule,
    NgbAlertModule,
    TooltipModule,
  ],
  exports: [
    AuditTrailComponent,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ToastrModule,
    BrowserAnimationsModule,
    SendEmailComponent,
    SendEmailModalComponent,
    DecimalNewPipe,
    CustomFilterPipe,
    ListButtonsComponent,
    ActivityFeedComponent,
    Prevent2xClickDirective,
    SearchComponent,
    FileUploadComponent,
    SearchFilterPipe,
    AccountModalComponent,
    SubtotalForAccountModalComponent,
    AuditTrailModalComponent,
    BsToasterComponent,
    ActivityFeedModalComponent,
    CustomArrayFilterPipe,
    ChangeBatchModalComponent,
    ConfirmDialogComponent,
    BatchNewAndPostModalComponent,
    FormButtonsComponent,
    FilterPipeModule,
    OnlyNumberDirective,
    OnlyDecimalDirective,
  ],
})
export class SharedModule {}
