import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {AppConfigService} from '../../../Services/app-config.service';

@Component({
  selector: 'app-home-onboard',
  templateUrl: './home-onboard.component.html',
  styleUrls: ['./home-onboard.component.css']
})
export class HomeOnboardComponent implements OnInit, AfterViewInit {

  vm: any = [];

  accountSetup = false;
  companySetup = false;
  locationSetup = false;
  entityTypeSetup = false;
  entitySetup = false;
  checkStockSetup = false;
  currencySetup = false;
  currencyRateSetup = false;
  bankSetup = false;
  paymentTypeSetup = false;
  termsSetup = false;
  reportingFieldsTypeSetup = false;
  reportingFieldsSetup = false;
  taxesSetup = false;
  taxRatesSetup = false;
  uomSetup = false;
  inventoryItemSetup = false;

  darkMode = false;


  constructor( private titleService: Title,
               private appConfig: AppConfigService
  ) { }

  ngOnInit() {

    // TODO testing and need to complete get data to test each item abd set value based on data being reruend or not
    // example - use data.length to set t/f so ui indicates completed or not
    this.accountSetup = true;
    this.bankSetup = true;
    this.taxRatesSetup = true;
    //

    // values are dark or light
    if (this.appConfig.bodyThemeColor === 'dark') {
      this.darkMode = true;
    }

  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Onboard - Getting Started @@gettingStarted:Onboard - Getting Started`);
  }

}
