import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AppConfigService } from '../../../Services/app-config.service';
import { StorageService } from '../../../Services/storage.service';
import { DatePipe} from '@angular/common';
import { Title } from '@angular/platform-browser';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { TableDataService } from '../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomFilterPipe } from '../../admin/custom-filter.pipe';
import { NotificationService } from '../../../Services/notification.service';
import { ResponseModel } from '../../../DataModels/response-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';

@Component({
  selector: 'app-payroll-payment-list',
  templateUrl: './payroll-payment-list.component.html',
  styleUrls: ['./payroll-payment-list.component.css'],
})
export class PayrollPaymentListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: NotificationService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private datePipe: DatePipe,
    private cFilter: CustomFilterPipe,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    this.vm.includePosted = false;
    this.vm.batchFilter = [];
    this.vm.paymentFilter = [];
    this.vm.list = [];
    this.vm.batches = [];
    this.vm.paymentSearch = '';
    this.vm.paymentFromDate = '';
    this.vm.paymentToDate = '';
    this.vm.numberOfResults = 25;
    this.vm.batchesHidden = true;
    this.vm.isLoaded = false;
  }

  vm: any = {
    formRoute: '/payroll/payment-form',
    title: $localize`:Payroll Payment List @@payrollPaymentList:Payroll Payment List`,
  };

  selectItems = false;

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.search();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([2, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  convertToNumbers() {
    for (const [x, value] of Object.entries(this.vm.list)) {
      this.vm.list[x].detail_total = Number(this.vm.list[x].detail_total);
      this.vm.list[x].audit_sequence = Number(this.vm.list[x].audit_sequence);
    }
  }


  selectAll() {
    for (const [x, value] of Object.entries(this.vm.list)) {
      (document.getElementById(this.vm.list[x].id) as HTMLInputElement).checked = this.vm.selectAllItems;
    }
    this.selectItem();
  }

  selectItem() {
    this.vm.owingSelected = this.vm.totalSelected = 0;
    let firstEntity = '';
    document.getElementById('pay-btn').classList.remove('d-none');
    document.getElementById('owingSelected').classList.remove('d-none');
    this.vm.itemsSelected = false;
    for (const [x, value] of Object.entries(this.vm.list)) {
      if ((document.getElementById(this.vm.list[x].id) as HTMLInputElement).checked) {
        if (firstEntity == '') {
          this.vm.itemsSelected = true;
          firstEntity = this.vm.list[x].entity_name;
        } else if (firstEntity != this.vm.list[x].entity_name) {
          document.getElementById('pay-btn').classList.add('d-none');
          document.getElementById('owingSelected').classList.add('d-none');
        }
        this.vm.totalSelected += Number(this.vm.list[x].header_total);
        this.vm.owingSelected += Number(this.vm.list[x].header_owing);
        document.getElementById(this.vm.list[x].id + '-tr').classList.add('success');
      } else {
        document.getElementById(this.vm.list[x].id + '-tr').classList.remove('success');
      }
    }
  }

  encodeQueryData(data, filterEmpty = true) {
    const ret = [];
    for (const d in data) {
      if (filterEmpty) {
        if (data[d] != null && data[d] != '') {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
      } else {
        if (data[d] !== undefined) {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
      }
    }
    return ret.join('&');
  }

  search() {
    this.vm.lockedBatches = true;
    this.vm.includePosted = false;
    this.vm.searching = true;
    this.vm.batchId = undefined;
    this.vm.tempDescription = '';
    this.vm.list = [];
    const params = {
      batchType: '7',
      search: this.vm.paymentSearch,
      fromDate: this.vm.paymentFromDate,
      toDate: this.vm.paymentToDate,
      lockedBatches: this.vm.lockedBatches,
      numberOfResults: this.vm.numberOfResults,
      includePosted: this.vm.includePosted,
    };
    // console.log(params);
    this.tableDataService.getTableData('t_batch?' + this.encodeQueryData(params, false)).subscribe((result: any[]) => {
      this.vm.batches = result;
    });
    const params2 = {
      batchType: '7',
      search: this.vm.paymentSearch,
      fromDate: this.vm.paymentFromDate,
      toDate: this.vm.paymentToDate,
      unpaidPayments: this.vm.unpaidPayments,
      numberOfResults: this.vm.numberOfResults,
      includePosted: this.vm.includePosted,
      pageNumber: this.page !== 0 ? this.page - 1 : 0,
      pageSize: this.pageSize,
    };
    // console.log('entry params: ' + JSON.stringify(params2));
    this.tableDataService
      .getApiListData('entry?' + this.encodeQueryData(params2, false))
      .subscribe((result2: ResponseModel) => {
        this.vm.list = result2.Data;
        this.vm.isLoaded = true;
        // console.log(this.vm.list);
        this.totalRecords = result2.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;
        this.convertToNumbers();
        this.vm.searching = false;
      });
  }

  filter(filter) {
    this.vm.paymentFilter = Object.assign(this.vm.paymentFilter, filter);
  }

  updateBatchesSelectedInModal(batchId: string) {
    this.getBatchItems(batchId);
    // this.paginate(false);
  }

  getBatchItems(batchId: string) {
    this.vm.tempDescription = '';
    this.vm.batchId = batchId;
    this.vm.searching = true;
    // this.vm.tempDescription = 'Batch';
    this.vm.tempDescription = this.cFilter.transform(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
    this.vm.tempBatchLocked = this.cFilter.transform(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;

    this.tableDataService
      .getApiListData(
        'entry?' +
          this.encodeQueryData({
            batch_id: this.vm.batchId,
            batchType: '7',
            lockedBatches: this.vm.lockedBatches,
            includePosted: this.vm.includePosted,
            pageNumber: 0,
            pageSize: 999999,
          })
      )
      .subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.convertToNumbers();
        this.vm.searching = false;
      });
  }

  getBatches() {
    this.setIncludePostedValue();
    this.vm.includePosted = false;
    this.vm.searching = true;
    this.tableDataService
      .getTableData(
        't_batch?' +
          this.encodeQueryData({
            batchType: '7',
            search: this.vm.paymentSearch,
            fromDate: this.vm.paymentFromDate,
            toDate: this.vm.paymentToDate,
            lockedBatches: this.vm.lockedBatches,
            numberOfResults: this.vm.numberOfResults,
            includePosted: this.vm.includePosted,
          })
      )
      .subscribe((result: any[]) => {
        this.vm.batches = result;
        if (this.cFilter.transform(this.vm.batches, { id: this.vm.batchId }, true).length == 1) {
          this.vm.tempDescription = this.cFilter.transform(
            this.vm.batches,
            { id: this.vm.batchId },
            true
          )[0].description;
          this.vm.tempBatchLocked = this.cFilter.transform(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
        }
        this.vm.searching = false;
      });
  }

  setIncludePostedValue() {
    if (this.vm.includePosted == false) {
      this.vm.includePosted = true;
    } else {
      this.vm.includePosted = false;
    }
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    this.vm.lockedBatches = true;
    this.vm.includePosted = false;
    this.vm.searching = true;
    this.vm.batchId = undefined;
    this.vm.tempDescription = '';
    this.vm.list = [];

    const params2 = {
      batchType: '7',
      search: this.vm.paymentSearch,
      fromDate: this.vm.paymentFromDate,
      toDate: this.vm.paymentToDate,
      unpaidPayments: this.vm.unpaidPayments,
      numberOfResults: this.vm.numberOfResults,
      includePosted: this.vm.includePosted,
      pageNumber: this.page !== 0 ? this.page - 1 : 0,
      pageSize: this.pageSize,
    };

    this.tableDataService
      .getApiListData('entry?' + this.encodeQueryData(params2, false))
      .subscribe((result2: ResponseModel) => {
        // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //   dtInstance.clear();
        // });
        this.vm.list = result2.Data;
        this.totalRecords = result2.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.convertToNumbers();
        this.vm.searching = false;
      });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.paymentSearch = value.searchTerm;
    this.vm.paymentFromDate = value.fromDate;
    this.vm.paymentToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.paymentSearch = value.searchTerm;
    this.vm.paymentFromDate = value.fromDate;
    this.vm.paymentToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.clear();
      dtInstance.destroy(false);
    });
  }

  onNavigate(aID: string, print = false) {
    if (print) {
      this.router.navigateByUrl(this.vm.formRoute + '?paymentId=' + aID + '&print=true').then();
    } else {
      this.router.navigateByUrl(this.vm.formRoute + '?paymentId=' + aID).then();
    }
  }

  hideBatches() {
    this.vm.batchesHidden = !this.vm.batchesHidden;
  }
}
