import {Component, Input, OnInit} from '@angular/core';
import {TableDataService} from '../../../../../Services/tableData.service';
import {PermissionsService} from '../../../../../Services/permissions.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ItemEntityModel} from '../../../../../DataModels/item-entity-model';
import {DecimalPipe} from '@angular/common';
import {BsToastService} from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-item-supplier',
  templateUrl: './item-supplier.component.html',
  styleUrls: ['./item-supplier.component.css']
})
export class ItemSupplierComponent implements OnInit {

  @Input() itemID: string;

  suppliers: [ItemEntityModel];
  totalSupplierQuantity;
  totalSupplierBalance;

  constructor(private table: TableDataService,
              private toast: BsToastService,
              public p: PermissionsService,
              private decimal: DecimalPipe) {
  }


  ngOnInit() {
    this.getSuppliers();
  }

  private getSuppliers() {
    // this.getting = true;
    const urlParamsJson = {
      item_id: this.itemID,
      type_id: 4,
      pageNumber: 0,
      pageSize: 999999,
    };

    const Params: string = this.encodeQueryData(urlParamsJson);
    this.table.getTableData('ItemEntity', '?' + Params).subscribe((result) => {
        this.suppliers = result;

      },
      (error: HttpErrorResponse) => {
        // this.toast.error(error.message, $localize`:Error loading balance @@errorLoadingbalance:Error loading balance`, {
        //   timeOut: 4000
        // });
        console.log(error);
      },
      () => {
        this.totalSupplierQuantity = 0;
        this.totalSupplierBalance = 0;
        for (const item of this.suppliers) {
          this.totalSupplierQuantity += Number(item.quantity);
          this.totalSupplierBalance += Number(item.balance);
        }
      });
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '' || data[d] === 0) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

}
