import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-bank-loan-list',
  templateUrl: './bank-loan-list.component.html',
  styleUrls: ['./bank-loan-list.component.css']
})
export class BankLoanListComponent implements OnInit, AfterViewInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Bank Loan List @@bankLoanList:Bank Loan List`);
  }

}
