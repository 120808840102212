import {ElementRef, Injectable} from '@angular/core';
import * as xlsx from 'xlsx';
import {BsToastService} from './bs-toast-service';

@Injectable({
  providedIn: 'root'
})

export class DataExportService {

  constructor(private bsToast: BsToastService) {
  }
  /// be sure to send .nativeElement for psuedoDom
  exportToExcel(pseudoDOM: ElementRef, excelFileName: string, sheetName: string = '') {
    let domToExport;

    // some older transaction-reports are using an older signature for XLSX - whole psuedo dom and we need it in nativelement format
    // for the new XLSX export.
    // this if just sorts that out so older stuff keeps working without code changes required to all those classes
    if (pseudoDOM.nativeElement) {
      // if it has nE then it is the whole psuedo so take .nativeElement only
      domToExport = pseudoDOM.nativeElement;
    } else {
      // those already sending nativeElement only
      domToExport = pseudoDOM;
    }

    try {
      const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(domToExport);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      if (sheetName == '') {
        xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
      } else {
        xlsx.utils.book_append_sheet(wb, ws, sheetName);
      }
      xlsx.writeFile(wb, excelFileName);
      this.bsToast.showSuccessToast('Success', 'Exported to download directory as: ' + excelFileName);
    } catch (ex) {
      this.bsToast.showErrorToast(ex, 'Error');
      console.log(ex);
    }
  }
}
