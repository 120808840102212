import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatatableConfigService {

  constructor() { }
  dataTableReady = false;  // placeholder so it can be passed back in config to caller.
  dtOptions: DataTables.Settings = {};

   getTableConfig(sortArray: [number?, string?]) {
    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: this.appConfig.gridRecsPerListPage,
      // lengthMenu: this.appConfig.gridPageSizeSelection,
      destroy: true,
      processing: false,
      paging: false,
      searching: true,
      // order: [1, 'desc'],
      order: sortArray,
      // dom: '<"float-left"B><"float-end"f>rt<"row"<"col-sm-6 float-right"l><"col-sm-4 float-right"i><"float-end"p>>',
      stateSave: false,
      language: {
        paginate: {
          first: '<i class="bi bi-skip-start" aria-hidden="true"></i>',
          last: '<i class="bi bi-skip-end" aria-hidden="true"></i>',
          next: '<i class="bi bi-skip-forward" aria-hidden="true"></i>',
          previous: '<i class="bi bi-skip-backward" aria-hidden="true"></i>'
        },
        // lengthMenu: '_MENU_',
        // info: '_START_ - _END_ of _TOTAL_',
        info: '',
        zeroRecords: '',
        searchPlaceholder: 'filter ...',
        search: 'Filter Records: ',
      },
      // turn off search for first column which is id and is a guid
      columnDefs: [{
        targets: [0],
        searchable: false
      }],
      drawCallback: () => {
        // this.dtSearchValue = $('#transactions').dataTable().api().search();
        this.dataTableReady = true;
      },
      initComplete: (oSettings, json) => {
        // some stuff here if needed
      },
      infoCallback: (settings, start, end, max, total, pre) => {
        // some stuff here if needed
      },
    };
    return this.dtOptions;
  }
}
