import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IsFilterOnService {

  constructor() {
  }

  isFiltered(objectToCheckForValues: any): boolean {
    let retVal = false;

    if (
      objectToCheckForValues.request.entity_id ||
      objectToCheckForValues.request.entity_type_id ||
      objectToCheckForValues.request.company_id ||
      objectToCheckForValues.request.currency_id ||
      objectToCheckForValues.request.company_id ||
      objectToCheckForValues.request.rf_id_1 ||
      objectToCheckForValues.request.rf_id_2 ||
      objectToCheckForValues.request.rf_id_3 ||
      objectToCheckForValues.request.rf_id_4 ||
      objectToCheckForValues.request.rf_id_5 ||
      objectToCheckForValues.request.margin_id ||
      objectToCheckForValues.request.location_id ||
      objectToCheckForValues.request.location_group_id ||
      objectToCheckForValues.request.accountInput ||
      objectToCheckForValues.request.accountParentId ||
      objectToCheckForValues.request.accountSubtotalId ||
      objectToCheckForValues.request.accountTypeId ||
      objectToCheckForValues.request.dataType ||
      objectToCheckForValues.request.account_id ||
      objectToCheckForValues.request.account_parent_id ||
      objectToCheckForValues.request.account_subtotal_id ||
      objectToCheckForValues.request.account_type_id ||
      objectToCheckForValues.request.bank_id ||
      objectToCheckForValues.request.entity_id ||
      objectToCheckForValues.request.entity_city ||
      objectToCheckForValues.request.entity_state ||
      objectToCheckForValues.request.entity_type_id ||
      objectToCheckForValues.request.item_id ||
      objectToCheckForValues.request.payment_type_id ||
      objectToCheckForValues.request.currency_id) {
      retVal = true;
    }

    if (objectToCheckForValues.entityInput) {
      // console.log('entity value passed in: ' + objectToCheckForValues.entityInput);
      retVal = true;
    }
    return retVal;
  }
}
