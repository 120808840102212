import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-modal-item-history',
  templateUrl: './modal-item-history.component.html',
  styleUrls: ['./modal-item-history.component.css']
})
export class ModalItemHistoryComponent implements OnInit, OnDestroy {
@ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  // @Output() Emits = new EventEmitter<string> ();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
