import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { PermissionsService } from '../../../../Services/permissions.service';
import { ItemDetailComponent } from '../resources/item-detail/item-detail.component';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';
import {RoutingTrackerService} from '../../../../Services/routing-tracker.service';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.css'],
})
export class ItemFormComponent implements OnInit, AfterViewInit {
  itemID;
  showBalances = true;
  showCustomers = false;
  showSuppliers = false;
  showQuantities = false;
  showDetails = true;
  routedFrom = '';

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };
  // @ViewChild(ItemDetailComponent, { static: false }) detail: ItemDetailComponent;

  constructor(
    private table: TableDataService,
    private toast: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private p: PermissionsService,
    private titleService: Title,
    public prevUrl: RoutingTrackerService
  ) {
  this.itemID = this.route.snapshot.params.id;
  const prevUrl2 = this.prevUrl.getPreviousUrl();

  if (prevUrl2 !== null && prevUrl !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }
  @ViewChild(ItemDetailComponent, { static: false }) detail: ItemDetailComponent;

  ngOnInit() {
    this.titleService.setTitle($localize`:Item @@item:Item`);
    this.showDetailsClick();
    if (this.itemID) {
      this.buttonConfig.reset = false;
    }

  }

  ngAfterViewInit() {

  }

  showDetailsClick() {
    this.AllFalse();
    this.showDetails = true;
  }

  showBalanceClick() {
    this.AllFalse();
    // vm.getHistory()
    this.showBalances = true;
  }

  showCustomersClick() {
    // vm.getActivityFeed()
    this.AllFalse();
    this.showCustomers = true;
  }

  showSuppliersClick() {
    // vm.getActivityFeed()
    this.AllFalse();
    this.showSuppliers = true;
  }

  showQuantitiesClick() {
    // vm.getActivityFeed()
    this.AllFalse();
    this.showQuantities = true;
  }

  AllFalse() {
    this.showDetails = false;
    this.showBalances = false;
    this.showCustomers = false;
    this.showSuppliers = false;
    this.showQuantities = false;
  }

  // saveClose() {
  //   if (this.onSave()) {
  //     this.onClose();
  //   }
  // }

  saveClose() {
    if (this.onSave(true)) {
      // detail from close and navs back
      // this.detail.onClose();
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.detail.Reset();
    }
  }

  deleteItem() {
    this.detail.deleteItem();
  }

  resetForm() {
    this.detail.Reset();
  }

  onSave(saveClose: boolean) {
    return this.detail.onSave(saveClose);
  }

  onClose() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom === undefined) {
      window.close();
    } else {
      this.router.navigateByUrl('/home').then();
    }
  }
}
