import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TableDataService} from '../../../Services/tableData.service';
import {BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.css']
})
export class AuditTrailComponent implements OnInit {

  public searchText: any = {column_name: '', original_value: '', new_value: ''};

  @Input() src: string;

  auditTrailList: Array<object>;
  auditTrailCount = 0;

  showAudit = false;



  constructor(private table: TableDataService,
              private toast: BsToastService) {
  }

  ngOnInit() {
    this.loadAuditTrail();
  }

  loadAuditTrail() {
    if (this.src == '') {
      // this.table.getTableData('audit_trail').subscribe(
      //   result => {
      //     this.auditTrailList = result;
      //     this.auditTrailCount = this.auditTrailList.length;
      //     // console.log('empty src:' + this.src);
      //   },
      //   (error: HttpErrorResponse) => {
      //     this.toast.error($localize`:Audit Trail Error @@auditTrailError:Error loading Audit Trail Data`);
      //     console.log(error);
      //     // this.toast.error($localize`:Audit Trail Error @@auditTrailError:Error loading Audit Trail Data`);
      //   },
      //   () => {
      //   }
      // );
      const msg = $localize`:No Audit Trail record Id Provided@@noAuditTrailIdProvided:No Audit Trail record Id Provided`;
      this.toast.showWarningToast(msg);
      console.log(msg);
    }


    if (this.src != '') {
      this.table.getTableDataWithParams('audit_trail/recid', 'recId=' + this.src).subscribe(
        result => {
          this.auditTrailList = result;
          this.auditTrailCount = this.auditTrailList.length;
          // console.log('audit trail recid:' + this.src);
          // console.log('audit trail result:' + JSON.stringify(result));
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {
        }
      );

    }
  }

  toggleAudit() {
    this.showAudit = !this.showAudit;
  }
}
