import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {BsToastEventTypes} from '../DataModels/bs-toast-event-types';
import {BsToastEvent} from '../DataModels/bs-toast-event';

@Injectable({
  providedIn: 'root',
})
export class BsToastService {
  toastEvents: Observable<BsToastEvent>;
  private _toastEvents = new Subject<BsToastEvent>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  showSuccessToast(message: string, title?: string) {
    if (title === '' || title === undefined) {
      title = 'Success';
    }
    this._toastEvents.next({
      message,
      title,
      type: BsToastEventTypes.Success,
    });
  }

  showInfoToast(message: string, title?: string) {
    if (title === '' || title === undefined) {
      title = 'Info';
    }
    this._toastEvents.next({
      message,
      title,
      type: BsToastEventTypes.Info,
    });
  }

  showWarningToast(message: string, title?: string) {
    if (title === '' || title === undefined) {
      title = 'Warning';
    }
    this._toastEvents.next({
      message,
      title,
      type: BsToastEventTypes.Warning,
    });
  }

  showWarningToastStayOnScreen(message: string, title?: string) {
    if (title === '' || title === undefined) {
      title = 'Warning';
    }
    this._toastEvents.next({
      message,
      title,
      type: BsToastEventTypes.WarningStayOnScreen,
    });
  }

  showErrorToast(message: string, title?: string) {
    if (title === '' || title === undefined) {
      title = 'Error';
    }
    this._toastEvents.next({
      message,
      title,
      type: BsToastEventTypes.Error,
    });
  }
  showErrorToastStayOnScreen(message: string, title?: string) {
    if (title === '' || title === undefined) {
      title = 'Error';
    }
    this._toastEvents.next({
      message,
      title,
      type: BsToastEventTypes.ErrorStayOnScreen,
    });
  }
}
