import {Component, OnInit, Input, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';

@Component({
  selector: 'app-entity-select-only',
  templateUrl: './entity-select-only.component.html',
  styleUrls: ['./entity-select-only.component.css']
})
export class EntitySelectOnlyComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  constructor(public isFiltered: IsFilterOnService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

  checkFiltered(): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    this.vm.filtered = filteredValue;
    return filteredValue;
  }

}
