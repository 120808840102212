import { Injectable} from '@angular/core';
import { TableDataService } from './tableData.service';
import { Subscription } from 'rxjs';
import { TaxEngineOuputModelData} from '../DataModels/tax-engine-ouput-model';

@Injectable({
  providedIn: 'root',
})
export class TaxEngineService {
  entities: any;
  taxRates: any;
  taxRateToFindId = '';
  entityToFindDefaults = '';
  taxType = '';
  taxData: TaxEngineOuputModelData;

  taxRatesSubscription: Subscription;

  constructor(public tableDataService: TableDataService) {
    this.loadTaxRates();
    this.loadEntity();
  }

  getTaxRate(taxRateId: string, transactionDate: string, entityIdForDefaults: string, taxType: string): TaxEngineOuputModelData {

    this.taxData = new TaxEngineOuputModelData();
    this.taxData = this.createValues();

    const retVal = [];

    this.entityToFindDefaults = entityIdForDefaults;
    this.taxRateToFindId = taxRateId;
    this.taxType = taxType;


    // id = id.substring(id.indexOf(' ') +1 , id.length + id.indexOf(' '));
    // id = id.split(' ')[1];

    console.log('this ID to match:' + this.taxRateToFindId);
    console.log('this entity ID to match:' +  this.entityToFindDefaults);
    console.log('this taxType:' +  this.taxType);

    this.taxData.taxIdUsedForLookup = this.taxRateToFindId;
    this.taxData.entityIdUsedForDefaults = this.entityToFindDefaults;
    this.taxData.taxType = this.taxType;

    // if (this.entityToFindDefaults !== '') {
    this.getDefaultsFromEntity(this.entityToFindDefaults);
    // }
    return this.taxData;
  }

  createValues(): any {
    const taxRecords = this.taxRates
      .filter((x) => x.tax_tax_id === this.taxRateToFindId)
      .sort((a, b) => new Date(b.to_date).getTime() - new Date(a.to_date).getTime());

    // TODO REMOVE AFTER TESTING DONE
    const baseDate = new Date(); // this to remove
    // const baseDate = new Date(transactionDate); // remove comments this one

    let taxRecord;

    if (taxRecords.length) {
      taxRecord = taxRecords.reduce((a, b) =>
        new Date(b.to_date).valueOf() - baseDate.valueOf() < new Date(a.to_date).valueOf() - baseDate.valueOf() ? a : b
      );
    } else {
      // no tax recs found
      this.taxData.taxMessage = 'no unexpired rates found';
      this.taxData.taxRate = 0;
      console.log('no rates found in array');
      console.log('taxdata array:' + JSON.stringify(this.taxData));
      return this.taxData;
    }

    if (new Date(taxRecord.to_date) > new Date()) {
      console.log('tax rate in reduced record (based on valid by date): ' + taxRecord.rate);
      this.taxData.taxMessage = 'unexpired rate found';
      this.taxData.taxRate = taxRecord.rate;
      console.log('taxdata array:' + JSON.stringify(this.taxData));
      return this.taxData;
    } else {
      console.log('nothing matched id.');
      this.taxData.taxMessage = 'no matching id';
      this.taxData.taxRate = taxRecord.rate;
      console.log('taxdata array:' + JSON.stringify(this.taxData));
      return this.taxData;
    }
  }

  getDefaultsFromEntity(entityId: string) {
    const thisEntity = this.entities.find(x => x.id === entityId);
    // console.log('ent tax def rec id: ' + JSON.stringify(thisEntity));
    // console.log('ent recv tax rec id 1: ' + thisEntity.receivable_tax_1_id);
  }

  loadEntity() {
    this.tableDataService.getApiListData('entity', '??state=active&pageNumber=0&pageSize=999999').subscribe((result: any) => {
      this.entities = result.Data;
      // console.log(this.entities);
    });
  }

  loadTaxRates() {
    this.tableDataService.getApiListData('tax_rate', '?pageNumber=0&pageSize=999999').subscribe((result: any) => {
      this.taxRates = result.Data;
      // console.log(JSON.stringify(result.Data));
    });
  }
}
