import {Component, OnInit, Input} from '@angular/core';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';

@Component({
  selector: 'app-reporting-field-select',
  templateUrl: './reporting-field-select.component.html',
  styleUrls: ['./reporting-field-select.component.css']
})
export class ReportingFieldSelectComponent implements OnInit {
  @Input() vm: any;

  constructor(public isFiltered: IsFilterOnService) {
  }

  ngOnInit() {
  }

  checkFiltered(): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    this.vm.filtered = filteredValue;
    return filteredValue;
  }

  parseInt(val) {
    return parseInt(val, 10);
  }

}
