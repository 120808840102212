import {Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-modal-new-entity',
  templateUrl: './modal-new-entity.component.html',
  styleUrls: ['./modal-new-entity.component.css']
})
export class ModalNewEntityComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  @Output() saveNewEntityEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  saveNewEntity(){
    this.saveNewEntityEmit.emit();
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
