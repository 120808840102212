import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.css']
})
export class ItemSelectComponent implements OnInit {

  @Input() vm: any;

  @Output() updateInventoryItemEmit = new EventEmitter<string>();
  @Output() testItemEmit = new EventEmitter<string>();
  @Output() selectItemModalNewEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  testItem() {
    this.testItemEmit.emit();
  }

  updateInventoryItem() {
    this.updateInventoryItemEmit.emit();
  }

  selectItemModalNew(itemId) {
    this.selectItemModalNewEmit.emit(itemId);
  }

}
