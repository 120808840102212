import { Injectable } from '@angular/core';
import {SendEmailInfoModel} from '../DataModels/send-email-info-model';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EmailPassDataServiceService {
  emailInfo = new SendEmailInfoModel();
  emptyObjDoNotModify = new SendEmailInfoModel(); // used to set storage to empty values - had issue with data hanging around

  constructor(private localStorage: StorageService) { }

  loadEmailDataToPassMailer() {
    this.localStorage.StorageSet('sendEmailInfoObj', JSON.stringify(this.emailInfo));
    // console.log(JSON.stringify(this.emailInfo));
  }

  clearAllEmailPassData() {
    this.localStorage.StorageSet('sendEmailInfoObj', JSON.stringify(this.emptyObjDoNotModify));
  }
}


