import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal-uninvoiced-shipments',
  templateUrl: './modal-uninvoiced-shipments.component.html',
  styleUrls: ['./modal-uninvoiced-shipments.component.css']
})
export class ModalUninvoicedShipmentsComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() vm: any;


  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
generateInvoice(id, subLedger) {
   //  $('#uninvoicedShipments').modal('hide');
    if (subLedger == 'ar') {
      // $state.go('app.admin.transactions.invoice.form', { invoiceId: 'newAR', shipmentId: id });
      this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newAR&shipmentId=' + id);
    } else {
      this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newAP&shipmentId=' + id);
      // $state.go('app.admin.transactions.invoice.form', { invoiceId: 'newAP', shipmentId: id });
    }
  }
}
