import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {
  static checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
  static checkOldPassword(control: AbstractControl) {
    const pass = control.value;

    // testing
    // return pass === '1234' ? null : { notOld: true };
    return pass !== '' ?  null : {notOld: true};
  }
}
