import {Component, OnInit} from '@angular/core';
import {TermModel} from '../../../../DataModels/term-model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TableDataService} from '../../../../Services/tableData.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../../../../Services/notification.service';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-term-form',
  templateUrl: './term-form.component.html',
  styleUrls: ['./term-form.component.css']
})
export class TermFormComponent implements OnInit {


  termID;
  term: TermModel;
  termForm: UntypedFormGroup;
  retMsg = '';
  submitted = false;
  message = '';

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };
  constructor(private table: TableDataService,
              private notification: NotificationService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDialogService: ConfirmDialogService,
              private titleService: Title) {
    this.createForm();
    this.termID = this.route.snapshot.params.id;
  }

  // easy way to access form ctrls
  get f() {
    return this.termForm.controls;
  }

  createForm() {
    this.termForm = this.formBuilder.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      discount_days: ['0'],
      discount_percent: ['0'],
      due_days: ['', Validators.required],
      active: ['false']
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Terms@@terms:Terms`);
    if (this.termID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  LoadData() {
    this.table.getTableData('term', this.termID).subscribe(
      result => {
        this.term = result;
        // console.log('loadcalled');
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error loading Term @@errorloadingTerm:Error loading Term`);
        console.log(error);
      },
      () => {
        this.termForm.patchValue(this.term);
      });
  }

  resetForm() {
    this.termForm.reset();
    this.submitted = false;
  }

  onClose() {
    this.router.navigate(['admin/term-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete account? @@areYouSureYouWantToDeleteThisTerm:Are you sure you want to delete this Term?`, this.term.name + ' : ' + this.term.code];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('term', this.termID).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          });
      }, function() {
        this.notification.success($localize`:Delete Canceled@@deleteCanceled:Delete Canceled`);
      });
  }

  saveClose() {
   if (this.onSave()) {
     this.onClose();
   }
  }

  saveNew() {
    this.onSave();
    this.termForm.reset();
  }

  onSave() {
    this.submitted = true;
    if (this.termForm.invalid) {
      this.notification.error($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.term = Object.assign({}, this.termForm.value);
    if (this.termID) {
          this.table.patch('term', this.termID, this.term).then();
      // this.notification.success($localize`:Updated@@updated:Updated`);
      } else {
        this.table.postWithPromise('term', this.term).then(res => {
          // stops double posting when clicking Save
          let apiReturn: any = null;
          if (typeof res === 'object') {
            apiReturn = JSON.parse(JSON.stringify(res));
          }
          this.termID = apiReturn.id;
        });
      // this.notification.success($localize`:Saved@@saved:Saved`);
      }
    this.submitted = false;
    return true;
  }
}
