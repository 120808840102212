import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class ArraySortPipe implements PipeTransform {
  transform(array: any, field: any, reverse: boolean = false): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    if (Array.isArray(field) && field.length == 2) {
      array.sort((a: any, b: any) => {
        return a[field[0]] - b[field[0]] || a[field[1]] - b[field[1]];
      });
      return array;
    }
    array.sort((a: any, b: any) => {
      if (!a || !b) {
        return 0;
      }
      const aValue = (typeof a[field] == 'string') ? a[field].trim() : a[field];
      const bValue = (typeof b[field] == 'string') ? b[field].trim() : b[field];

      if (aValue < bValue) {
        return (reverse) ? 1 : -1;
      } else if (aValue > bValue) {
        return (reverse) ? -1 : 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
