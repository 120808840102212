import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../Services/auth.service';
import { from, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { PermissionsService } from '../../../Services/permissions.service';
import { PasswordResetComponent } from '../../password-reset/password-reset.component';
import { TableDataService } from '../../../Services/tableData.service';
import { AppConfigService } from '../../../Services/app-config.service';
import { environment } from '../../../../environments/environment';
import { ResponseModel } from '../../../DataModels/response-model';
import { NotificationService } from '../../../Services/notification.service';
import { EventService } from '../theme-models/event.service';
import {Language} from '../../../DataModels/language.model';
import {SysUserModel} from '../../../DataModels/sys-user-model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() layoutType: string = '';

  isLoggedIn$: Observable<boolean>;
  public collapsed: boolean;

  vm: any = {};
  modeOperations: boolean;
  showMessages = false;
  version = environment.appVersion;
  ngVersion = environment.ngVersion;
  userName = '';
  topnavCollapsed: boolean = false;
  user: SysUserModel;

  languages: Language[] = [];
  selectedLanguage?: Language;
  public supportSite = this.appConfig.supportSite;

  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private authService: AuthService,
    private storage: StorageService,
    private tableDataService: TableDataService,
    public p: PermissionsService,
    private appConfig: AppConfigService,
    private notificationService: NotificationService,
    private localStorage: StorageService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    this.vm.Preferences = JSON.parse(
      this.localStorage.StorageGet('Preferences')
    );

    this.userName = this.user.name;

    if (this.vm.Preferences.QuickLinks) {
      this.vm.quickLinks = this.vm.Preferences.QuickLinks;
    } else {
      this.vm.quickLinks = [];
    }

    // this will run on init and act to carry the setting around from page to page
    if (localStorage.getItem('sidePanelStatus') === 'true') {
      this.collapsed = true;
    } else {
      this.collapsed = false;
      // just in case it return a null set it for next check
      // localStorage.setItem('sidePanelStatus', String(this.collapsed));
    }
    // get supported languages
    this.fetchLanguages();
    this.getCompanyInfo();
  }

  getCompanyInfo() {
    this.tableDataService.getApiListData('company?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
  }

  logout() {
    this.authService.logout();
  }

  switchNav() {
    this.modeOperations = !this.modeOperations;
    localStorage.setItem('modeOperations', String(this.modeOperations));
  }

  getDbName() {
    // return this.storage.StorageGet('uDb');
    return this.user.companyDbName;
  }

  getuName() {
    // return this.storage.StorageGet('uName');
    return this.user.name;

  }

  toggleClick() {}

  notifyMessengerStatus() {
    this.notificationService.info(
      'Messenger Service TBC - API complete and html mostly complete.'
    );
  }

  toggleSidebarWidth(): void {

    if (
      document.body.hasAttribute('data-leftbar-compact-mode') ||
      document.body.getAttribute('data-leftbar-compact-mode') ===
        'condensed' && !document.body.classList.contains('sidebar-enable')
    ) {
      document.body.removeAttribute('data-leftbar-compact-mode');
      document.body.classList.toggle('hide-menu');
      document.body.classList.toggle('sidebar-enable');
    }else{
      document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
      document.body.classList.toggle('sidebar-enable');
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any): void {
    this.topnavCollapsed = !this.topnavCollapsed;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  changeLanguage(language: Language) {
    this.selectedLanguage = language;
  }

  fetchLanguages(): void {
    this.languages = [{
      id: 1,
      name: 'English',
      flag: 'assets/images/flags/us.jpg',
    },
      {
        id: 2,
        name: 'Spanish',
        flag: 'assets/images/flags/spain.jpg',
      },
      {
        id: 3,
        name: 'French',
        flag: 'assets/images/flags/french.jpg',
      },
      {
        id: 4,
        name: 'German',
        flag: 'assets/images/flags/germany.jpg',
      },
      {
        id: 5,
        name: 'Italian',
        flag: 'assets/images/flags/italy.jpg',
      }];
    this.selectedLanguage = this.languages[0];
  }
}
