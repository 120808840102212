import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FilterPipe} from 'ngx-filter-pipe';
import {TableDataService} from '../../../../Services/tableData.service';

@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.css']
})
export class AccountSelectComponent implements OnInit {
  @Input() vm: any;
  @Output() selectAccountEmit = new EventEmitter<string>();
  @Output() updateAccountEmit = new EventEmitter<string>();
  @Output() testAccountEmit = new EventEmitter<string>();
  public accounts: any;

  constructor(public tableDataService: TableDataService) {
  }

  ngOnInit() {
  }

  updateAccount() {
    this.updateAccountEmit.emit();
  }

  selectAccount(accountId) {
    // $('#accountSelect').modal('hide');
    this.selectAccountEmit.emit(accountId);
  }

  testAccount($event = null) {
    this.testAccountEmit.emit($event);
  }
}
