import {OnInit, Component, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {Router} from '@angular/router';
import {StorageService} from '../../../../Services/storage.service';
import {AppConfigService} from '../../../../Services/app-config.service';
import {NotificationService} from '../../../../Services/notification.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {DateFilterService} from '../../../../Services/dateFilter.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {PagerStateModel} from '../../../../DataModels/pager-state-model';
import {PagerStateService} from '../../../../Services/pager-state.service';
import {DatatableConfigService} from '../../../../Services/datatable-config.service';
import {NgbpaginationConfigService} from '../../../../Services/ngbpagination-config.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-account-subtotal-list',
  templateUrl: './account-subtotal-list.component.html',
  styleUrls: ['./account-subtotal-list.component.css']
})
export class AccountSubtotalListComponent implements OnInit, OnDestroy, AfterViewInit {
  subTotalCount = 0;
  checkedInfo: any;
  isLoaded = false;

  constructor(
    private table: TableDataService,
    private toastr: NotificationService,
    private router: Router,
    private storage: StorageService,
    private appConfig: AppConfigService,
    private dateFilter: DateFilterService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    private titleService: Title) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  // datatables stuff
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = 10;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  vm: any = [];

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.vm.parentData = false;
    this.vm.childrenData = false;
    this.getPagerStatus().then();

    this.getData();
  }

  ngAfterViewInit(): void {
    this.titleService.setTitle($localize`:Account Subtotal List @@accountSubtotalList:Account Subtotal List`);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([1, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus.getPagerState(this.router.url).then(res => {
      if (res) {
        const pagerStuff = res;
        if (pagerStuff.totalRecords !== 0) {

          this.page = pagerStuff.lastPage;
          this.pageSize = pagerStuff.lastPageSize;
          this.startIndex = pagerStuff.startIndex;
          this.endIndex = pagerStuff.endIndex;
        }
      }
    }).catch(err => {
      console.log('pager error: ', err);
    }).then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {

    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = ((this.page - 1) * this.pageSize) + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number((this.page) * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      if (this.vm.parentData === true) {
        // this.datatableClearFilter();
        this.getParentSubAccountData(true);
      } else if (this.vm.childrenData === true) {
        // this.datatableClearFilter();
        this.getChildSubAccountData(true);
      } else {
        // this.datatableClearFilter();
        this.getPagedApiData();
      }
    }
  }

  getData(): void {
    let paramString = '?search=' + ((this.vm.searchTerm) ? this.vm.searchTerm : '');
    paramString += '&fromDate=' + ((this.vm.fromDate) ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
    paramString += '&toDate=' + ((this.vm.toDate) ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
    paramString += '&pageNumber=' + (this.page - 1);
    paramString += '&pageSize=' + this.pageSize;

    this.table.getApiListData('account_subtotal' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;

      // ngb paginate
      this.totalRecords = result.totalRecords;
      this.paginate(false);

      this.dataTableReady = true;

      this.isLoaded = true;
      this.subTotalCount = result.totalRecords;

      // datatable stuff
      this.dtTrigger.next('');
    });
  }

  getParentSubAccountData(event): void {
    if (event) {
      let paramString = '?search=' + ((this.vm.searchTerm) ? this.vm.searchTerm : '');
      paramString += '&fromDate=' + ((this.vm.fromDate) ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
      paramString += '&toDate=' + ((this.vm.toDate) ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
      paramString += '&pageNumber=' + (this.page - 1);
      paramString += '&pageSize=' + this.pageSize;
      this.table.getApiListData('account_subtotal/parents' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.isLoaded = true;
      });
    } else {
      this.getPagedApiData();
    }
  }

  getChildSubAccountData(event): void {
    if (event) {
      let paramString = '?search=' + ((this.vm.searchTerm) ? this.vm.searchTerm : '');
      paramString += '&fromDate=' + ((this.vm.fromDate) ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
      paramString += '&toDate=' + ((this.vm.toDate) ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
      paramString += '&pageNumber=' + (this.page - 1);
      paramString += '&pageSize=' + this.pageSize;
      this.table.getApiListData('account_subtotal/children' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.isLoaded = true;
      });
    } else {
      this.getPagedApiData();
    }
  }

  onNavigate(aID: string) {
    // remove the page state tracking so user defaults to table based on Action items checked as returning to page will
    // not know checkbox status when to set and relate the page states tracking stored. Page 6 with actions checked may
    // not equal page 6 with no options set. Therefore, just default to nothing checked when we leave the page and wipe out
    // page that may have been stored based on an Action item being checked. Possible later to do to track selections.
    // todo maybe track checkbox state in page state stored at some later time, but not now - 01172023 lp
    if (this.vm.parentData || this.vm.childrenData) {
      localStorage.removeItem('pager_state_' + this.router.url);
    }
    this.router.navigate(['admin/account-subtotal-form', {id: aID}]).then();
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    let paramString = '?search=' + ((this.vm.searchTerm) ? this.vm.searchTerm : '');
    paramString += '&fromDate=' + ((this.vm.fromDate) ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
    paramString += '&toDate=' + ((this.vm.toDate) ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
    paramString += '&pageNumber=' + (this.page - 1);
    paramString += '&pageSize=' + this.pageSize;

    this.table.getApiListData('account_subtotal' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;
      this.isLoaded = true;
    });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    if (this.vm.parentData) {
      this.getParentSubAccountData(true);
    } else if (this.vm.childrenData) {
      this.getChildSubAccountData(true);
    } else {
      this.getPagedApiData();
    }
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    if (this.vm.parentData) {
      this.getParentSubAccountData(true);
    } else if (this.vm.childrenData) {
      this.getChildSubAccountData(true);
    } else {
      this.getPagedApiData();
    }
  }

// ----------------- End server side paging --------------------


  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  // datatableClearData() {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     this.dtLastFilterValue = dtInstance.search();
  //     dtInstance.destroy(false);
  //   });
  // }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      // dtInstance.clear().draw();
      dtInstance.destroy(false);
    });
  }

}
