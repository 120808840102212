import {Component, OnInit, Input, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-entity-select-modal',
  templateUrl: './entity-select-modal.component.html',
  styleUrls: ['./entity-select-modal.component.css']
})
export class EntitySelectModalComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  constructor() { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
