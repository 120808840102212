import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-modal-select-salesperson',
  templateUrl: './modal-select-salesperson.component.html',
  styleUrls: ['./modal-select-salesperson.component.css']
})
export class ModalSelectSalespersonComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() vm: any;
  deleteSalesperson;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
