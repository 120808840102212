import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PermissionsService} from '../../../../../Services/permissions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TableDataService} from '../../../../../Services/tableData.service';
import {ItemModel} from '../../../../../DataModels/item-model';
import {debounceTime} from 'rxjs/operators';
import {ConfirmDialogService} from '../../../../../Services/confirm-dialog.service';
import {ResponseModel} from '../../../../../DataModels/response-model';
import {BsToastService} from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.css'],
})
export class ItemDetailComponent implements OnInit, AfterViewInit {
  @Input() itemID: string;
  uomList: Array<object>;
  itemCategoryList: Array<object>;
  itemTypeList: Array<object>;
  itemGroupList: Array<object>;
  taxList: Array<object>;
  accountList: Array<object>;
  inventoryAccountList: any = [];
  uomCount = 0;
  itemCategoryCount = 0;
  itemTypeCount = 0;
  itemGroupCount = 0;
  taxListCount = 0;
  accountListCount = 0;
  average_cost;
  margin = 0.0;
  profit = 0.0;
  markup = 0.0;

  itemInventory = false;
  submitted = false;

  public vm = {
    toDateSelect: [],
    dateError: false,
    report: [],
    itemInventory: false,
    item: {
      average_cost: 0,
      cost: 0,
      price: 0,
      most_recent_cost: 0,
      company_id: null,
      category_id: null,
      expense_account_id: null,
    },
    uomCountLoaded: false,
  };
  item: ItemModel;
  itemForm: UntypedFormGroup;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public p: PermissionsService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.createForm();
  }

  createForm() {
    this.itemForm = this.formBuilder.group(
      {
        id: new UntypedFormControl(null),
        code: new UntypedFormControl(null),
        name: new UntypedFormControl(null),
        description: new UntypedFormControl(null),
        // inventory_item: new FormControl(null),
        price: new UntypedFormControl(null),
        cost: new UntypedFormControl(null),
        category_id: new UntypedFormControl(null),
        item_type_id: new UntypedFormControl(null),
        item_group_id: new UntypedFormControl(null),
        uom_id: new UntypedFormControl(''),
        expense_account_id: new UntypedFormControl(null),
        inventory_account_id: new UntypedFormControl(null),
        payable_tax_1_id: new UntypedFormControl(null),
        payable_tax_2_id: new UntypedFormControl(null),
        receivable_tax_1_id: new UntypedFormControl(null),
        receivable_tax_2_id: new UntypedFormControl(null),
        revenue_account_id: new UntypedFormControl(null),
        UNNumber: new UntypedFormControl(null),
        ShippingName: new UntypedFormControl(null),
        PrimaryClass: new UntypedFormControl(null),
        SubsidiaryClass: new UntypedFormControl(null),
        PackingGroup: new UntypedFormControl(null),
        content_height: new UntypedFormControl(null),
        account: new UntypedFormControl(null),
        account1: new UntypedFormControl(null),
        account2: new UntypedFormControl(null),
        tax: new UntypedFormControl(null),
        tax1: new UntypedFormControl(null),
        tax2: new UntypedFormControl(null),
        tax3: new UntypedFormControl(null),
        uom: new UntypedFormControl(null),
        active: new UntypedFormControl(true),
        last_cost: new UntypedFormControl(null),
        average_cost: new UntypedFormControl(null),
      },
      {
        // must match stuff etc.
      }
    );
  }

  ngOnInit() {
    this.LoadUOM();
    this.loadTaxes();
    this.LoadItemCategory();
    this.LoadItemType();
    this.LoadItemGroup();
    this.loadAccount();
    if (this.itemID) {
      this.LoadData();
    }

    this.itemForm
      .get('price')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((data) => {
        this.calcMarginAndProfit();
      });

    this.itemForm
      .get('cost')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((data) => {
        this.calcMarginAndProfit();
      });

    // make sure they select and expense account if it is an inventory item
    this.itemForm.get('inventory_account_id').valueChanges.subscribe((val) => {
      if (this.itemForm.get('inventory_account_id').value !== '') {
        // for setting validations
        this.itemForm.get('expense_account_id').setValidators(Validators.required);
      }
      if (this.itemForm.get('inventory_account_id').value === '') {
        // for clearing validations
        this.itemForm.get('expense_account_id').clearValidators();
      }
      this.itemForm.get('expense_account_id').updateValueAndValidity();
    });
  }

  ngAfterViewInit() {}

  get f() {
    return this.itemForm.controls;
  }

  Reset() {
    this.itemForm.reset();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete item? @@areYouSureYouWantToDeleteItem:Are you sure you want to delete item?`,
      this.item.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('item', this.itemID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
            this.router.navigateByUrl('/admin/item-list').then();
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            // this.OnClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onClose() {
    this.router.navigate(['admin/item-list']).then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.itemForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      // this.submitted = false;
      return;
    }
    this.item = Object.assign({}, this.itemForm.value);
    if (this.itemID) {
      if (this.itemForm.invalid === false) {
        this.table.patch('item', this.itemID, this.item).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('item', this.item).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = [];
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
          // console.log('api return: ' + apiReturn);
          // console.log('id value: ' + apiReturn.id);
        }
        this.itemID = apiReturn.id;
        this.router.navigateByUrl('admin/item-form;id=' + this.itemID).then();
        if (saveClose) {
          this.onClose();
        }
      });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }

  private LoadData() {
    this.table.getTableData('item', this.itemID).subscribe(
      (result) => {
        this.item = result;
        // console.log(result);
        this.vm.item.most_recent_cost = this.item.last_cost;
        this.vm.item.average_cost = this.item.average_cost;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error Item Data @@errorItemData:Error Item Data`);
        console.log(error);
      },
      () => {
        this.itemForm.patchValue(this.item);
      }
    );
  }

  private LoadUOM() {
    this.table.getTableData('uom?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.uomList = result.Data;
        this.uomCount = this.uomList.length;
        // this.vm.uomCountLoaded = true;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(error.message);
        console.log(error);
      },
      () => {}
    );
  }

  private LoadItemCategory() {
    this.table.getTableData('item_category?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.itemCategoryList = result.Data;
        this.itemCategoryCount = this.itemCategoryList.length;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Item Categories @@errorLoadingItemCategories:Error loading Item Categories`
        // );
        console.log(error);
      },
      () => {}
    );
  }

  private LoadItemType() {
    this.table.getTableData('item_type?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.itemTypeList = result.Data;
        this.itemTypeCount = this.itemTypeList.length;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Item Categories @@errorLoadingItemCategories:Error loading Item Categories`
        // );
        console.log(error);
      },
      () => {}
    );
  }

  private LoadItemGroup() {
    this.table.getTableData('item_group?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.itemGroupList = result.Data;
        this.itemGroupCount = this.itemGroupList.length;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Item Categories @@errorLoadingItemCategories:Error loading Item Categories`
        // );
        console.log(error);
      },
      () => {}
    );
  }

  private loadTaxes() {
    this.table.getTableData('tax?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.taxList = result.Data;
        this.taxListCount = this.taxList.length;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading users @@errorLoadingUsers:Error loading users`);
        console.log(error);
      },
      () => {}
    );
  }

  private loadAccount() {
    this.table.getTableData('account?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.accountList = result.Data.sort((a: any, b: any) => a.number - b.number);
        // this.accountList = result.Data;
        this.accountListCount = this.accountList.length;
        // filter list down to inv accounts for inv acct dropdown
        for (const item of this.accountList as any) {
          if (item.id === 'in') {
            this.inventoryAccountList.push(item);
          }
        }
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading users @@errorLoadingUsers:Error loading users`);
        console.log(error);
      },
      () => {}
    );
  }

  /*
      () => {
        this.totalQuantity = 0;
        this.totalBalance = 0;
        // vm.getting = false;
        //  for (let x = 0; x < result.length; x++) {
        //    this.totalQuantity += Number(temp.balances[x].quantity);
        //     this.totalBalance += Number(temp.balances[x].balance);
      };
*/
  /*

  Profit
   <!--span *ngIf="!vm.item.average_cost">{{(item.price - item.cost) | number:'1.2-2' }}</span>
        <span *ngIf="vm.item.average_cost">{{(item.price - average_cost) | number:'2' }}</span -->

   margin
      <!--span *ngIf="!vm.item.average_cost">{{(item.price - item.cost) / item.price * 100 | number:'1.2-2'}}%</span>
        <span *ngIf="vm.item.average_cost">{{(item.price - item.average_cost) / item.price * 100 | number:'1.2-2'}}
          %</span -->
   markup
     <!--span *ngIf="!vm.item.average_cost">{{(item.price - item.cost) / item.cost * 100 | number:'1.2-2'}}%</span>
        <span *ngIf="vm.item.average_cost">{{(item.price - item.average_cost) / item.cost * 100 | number:'1.2-2'}}
          %</span-->
   */

  private calcMarginAndProfit() {
    this.profit = this.itemForm.get('price').value - this.itemForm.get('cost').value;
    this.margin =
      ((this.itemForm.get('price').value - this.itemForm.get('cost').value) / this.itemForm.get('price').value) * 100;
    this.markup =
      ((this.itemForm.get('price').value - this.itemForm.get('cost').value) / this.itemForm.get('cost').value) * 100;
  }
}
