import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {AppConfigService} from '../../../../Services/app-config.service';
import {StorageService} from '../../../../Services/storage.service';
import {Title} from '@angular/platform-browser';
import {FilterPipe} from 'ngx-filter-pipe';
import {DataExportService} from '../../../../Services/dataExport.service';

import {DateFilterService} from '../../../../Services/dateFilter.service';
import {NotificationService} from '../../../../Services/notification.service';
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../../../Services/email.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {EmailPassDataServiceService} from '../../../../Services/email-pass-data-service.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-trial-balance',
  templateUrl: './trial-balance.component.html',
  styleUrls: ['./trial-balance.component.css']
})
export class TrialBalanceComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('EmailContent', {static: false}) EmailContent: ElementRef;
  @ViewChild('ExcelExport', {static: false}) ExcelExport: ElementRef;

  private rptName: string;
  vm: any = {
    ToEmailError: false,
    showSpecificDates: false,
    company: [],
    currency: [],
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    request: {
      fromDate: null,
      toDate: null,
      company_id: '',
    },
    report: [],
    totalDebits: null,
    Preferences: [],
    permissions: [],
    netIncome: null,
    fromDateSelect: [],
    toDateSelect: [],
    monthCount: 0,
    totalCredits: null,
    dateError: false,
    buttonsDisabled: false,
    companyName: null,
    filtered: null,
    showEmail: true,
    exportToExcel: true,
    showFilters: false
  };

  constructor(public localStorage: StorageService,
              public tableDataService: TableDataService,
              private titleService: Title,
              public appConfigService: AppConfigService, public dateFilter: DateFilterService,
              public notification: NotificationService,
              private fb: UntypedFormBuilder,
              private router: Router,
              public aEmail: EmailService,
              public excelExporter: DataExportService,
              private emailData: EmailPassDataServiceService) {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Trial Balance Sheet Report @@trialBalanceSheetReport:Trial Balance Sheet Report`;
    // if (this.vm.request.company_id == '') {
    //   if (this.vm.Preferences.default_company_id) {
    //     this.vm.request.company_id = this.vm.Preferences.default_company_id;
    //   }
    // }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  ngAfterViewChecked() {
    if (this.vm.report.length > 0) {
      const div = document.getElementById('excel-export');
       // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Trial Balance';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);

    }
  }

  FilterOptionsInit() {
    this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
    this.tableDataService.getTableData('currency?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.currency = result.Data;
    });

  }

  makeNumber(value, sub) {
    if (typeof value !== 'undefined') {
      if (typeof value[sub] !== 'undefined') {
        return value[sub];
      }
    }
    return 0;
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.ExcelExport.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, this.rptName + '.xlsx');
  }

  calcTotals() {
    this.vm.totalDebits = this.vm.totalCredits = this.vm.netIncome = 0;

    for (const [x, value] of Object.entries(this.vm.report)) {
      if ((this.vm.report[x].type_id == '4') || (this.vm.report[x].type_id == '5') || (this.vm.report[x].type_id == '6')) {
        this.vm.netIncome += Number(0 - this.vm.report[x].debit_credit);
      }
      if (this.vm.report[x].debit_credit > 0) {
        this.vm.totalDebits += Number(this.vm.report[x].debit_credit);
      } else {
        this.vm.totalCredits += Number(this.vm.report[x].debit_credit);
      }
    }
  }

  update() {
    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    if (tempFromDate > tempToDate) {
      this.vm.dateError = true;
      this.notification.warning($localize`:The To date must greater than the From date. @@theTodatemustgreaterthantheFromdate:The To date must greater than the From date.`);
    } else if (!this.vm.request.fromDate || !this.vm.request.toDate) {
      this.vm.dateError = true;
      this.notification.warning($localize`:You must select From and To dates. @@youmustselectFromandTodates:You must select From and To dates.`);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.buttonsDisabled = true;
      this.vm.dateError = false;
      let param = '';
      if (this.vm.request.company_id != null || this.vm.request.company_id != '') {
        param += '&company_id=' + this.vm.request.company_id;
      }
      this.tableDataService.getTableData('trial_balance', '?fromDate=' + this.vm.request.fromDate + '&toDate=' + this.vm.request.toDate + param)
        .subscribe((result) => {
          this.vm.report = result;
          if (this.vm.request.company_id) {
            this.vm.filtered = true;
            this.vm.companyName = this.FilterObject(this.vm.company, {id: this.vm.request.company_id})[0].name;
          } else {
            this.vm.companyName = '';
          }
          this.calcTotals();
          this.vm.buttonsDisabled = false;
          this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
          this.localStorage.StorageSet('toDate', this.vm.request.toDate);
        });
    }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }
  close() {
    this.router.navigateByUrl('/home').then();
  }
  clear() {
    // let tempFromDate: string = this.vm.request.fromDate;
    // let tempToDate: string = this.vm.request.toDate;
    this.vm.report = [];
    this.vm.request = {
      toDate: null,
      fromDate: null,
      company_id: null,
    };
  }

  MathRound(int: number) {
    return Math.round(int);
  }

  parseInt(val) {
    return parseInt(val, 10);
  }

  Print() {
    return window.print();
  }

  setCompanyId(event) {
    this.vm.report = [];
    this.vm.request.company_id = event;
  }

  filterReport() {
    this.vm.report = [];
  }
}
