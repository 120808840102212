import {Component, Input, Output, AfterViewInit, OnInit, ViewChild, EventEmitter, ElementRef, OnDestroy} from '@angular/core';
import SignaturePad from 'signature_pad';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';

@Component({
  selector: 'app-modal-signature',
  templateUrl: './modal-signature.component.html',
  styleUrls: ['./modal-signature.component.css']
})
export class ModalSignatureComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() vm: any;
  @Output() signatureEmit = new EventEmitter<string>();
  @Output() signatureDeleteEmit = new EventEmitter<boolean>();

  @ViewChild('sPad', {static: true}) signaturePadElement;
  signaturePad: any;

  constructor(private notification: BsToastService, private confirmDialogService: ConfirmDialogService) {
  }

  ngOnInit() {
    this.signaturePadElement.config = {penColor: 'red'};
    this.signaturetext();
  }

  signaturetext() {
    this.vm.today = new Date();
    const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // ctx.fillText('', 20, 270);
    ctx.font = '12px sans-serif';
    ctx.fillStyle = 'dodgerblue';
    if (this.vm.addTextSignature) {
      ctx.fillText(this.vm.addTextSignature, 20, 260);
      ctx.fillText(this.vm.today, 20, 290);
    } else {
      ctx.fillText(this.vm.today, 20, 290);
    }
  }

  addSignatureName(signatureName) {
    this.vm.signatureName = signatureName;
    const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    ctx.font = '12px sans-serif';
    ctx.fillStyle = 'dodgerblue';
    // ctx.fillStyle = 'lightgray';
    if (!this.vm.signatureName){
      this.notification.showWarningToast($localize`:Enter Signer Name. @@Enter Signer Name:Enter Signer Name.`);
    } else if (this.vm.signatureName) {
      ctx.fillText($localize`:Signed By @@Signed By:Signed By` + ' - ' + this.vm.signatureName, 20, 275);
    }
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.signaturePad.penColor = 'rgb(66, 133, 244)';
    this.signaturetext();
  }

  clearSignature() {
    this.signaturePad.clear();
    this.vm.signatureName = '';
    this.vm.today = new Date();
    this.signaturetext();
  }

  signature() {
    if (this.signaturePad.isEmpty()) {
      this.notification.showWarningToast($localize`:Must provide a signature. @@Must provideasignature:Must provide a signature.`);
    } else if (!this.vm.signatureName) {
      this.notification.showWarningToast($localize`:Enter Signer Name. @@Enter Signer Name:Enter Signer Name.`);
    } else {
      const dataURL = this.signaturePad.toDataURL();
      this.signatureEmit.emit(dataURL);
      this.clearSignature();
    }
  }

  deleteItem() {
    const brClass = this;
    const retMsg = '';
    const messages: string[] = ['Are you sure you want to remove signature', retMsg];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.deleteSignature();
      },
      () => {
        brClass.notification.showSuccessToast('Delete Canceled');
      }
    );
  }

  deleteSignature() {
    this.signatureDeleteEmit.emit(true);
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}
