import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-print-batch',
  templateUrl: './print-batch.component.html',
  styleUrls: ['./print-batch.component.css']
})
export class PrintBatchComponent implements OnInit {

  @Input() vm: any;

  constructor() {
  }

  ngOnInit() {
  }

}
