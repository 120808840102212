import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankFormComponent } from './banks/bank-form/bank-form.component';
import { BankListComponent } from './banks/bank-list/bank-list.component';
import { SharedModule } from '../common/shared.module';
import { AccountListComponent } from './account/account-list/account-list.component';
import { AccountFormComponent } from './account/account-form/account-form.component';
import { LocationFormComponent } from './location/location-form/location-form.component';
import { LocationListComponent } from './location/location-list/location-list.component';
import { ItemListComponent } from './item/item-list/item-list.component';
import { ItemFormComponent } from './item/item-form/item-form.component';
import { ItemDetailComponent } from './item/resources/item-detail/item-detail.component';
import { ItemBalanceComponent } from './item/resources/item-balance/item-balance.component';
import { ItemCustomerComponent } from './item/resources/item-customer/item-customer.component';
import { ItemSupplierComponent } from './item/resources/item-supplier/item-supplier.component';
import { CompanyFormComponent } from './company/company-form/company-form.component';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { CurrencyListComponent } from './currency/currency-list/currency-list.component';
import { CurrencyFormComponent } from './currency/currency-form/currency-form.component';
import { AccountMarginListComponent } from './account-margin/account-margin-list/account-margin-list.component';
import { AccountMarginFormComponent } from './account-margin/account-margin-form/account-margin-form.component';
import { AccountSubtotalListComponent } from './account-subtotal/account-subtotal-list/account-subtotal-list.component';
import { AccountSubtotalFormComponent } from './account-subtotal/account-subtotal-form/account-subtotal-form.component';
import { CheckStockListComponent } from './check-stock/check-stock-list/check-stock-list.component';
import { CheckStockFormComponent } from './check-stock/check-stock-form/check-stock-form.component';
import { CompanyAccessListComponent } from './company-access/company-access-list/company-access-list.component';
import { CompanyAccessFormComponent } from './company-access/company-access-form/company-access-form.component';
import { CurrencyRateListComponent } from './currency-rate/currency-rate-list/currency-rate-list.component';
import { CurrencyRateFormComponent } from './currency-rate/currency-rate-form/currency-rate-form.component';
import { EntityAccessListComponent } from './entity-access/entity-access-list/entity-access-list.component';
import { EntityAccessFormComponent } from './entity-access/entity-access-form/entity-access-form.component';
import { LocationAccessListComponent } from './location-access/location-access-list/location-access-list.component';
import { LocationAccessFormComponent } from './location-access/location-access-form/location-access-form.component';
import { LocationGroupListComponent } from './location-group/location-group-list/location-group-list.component';
import { LocationGroupFormComponent } from './location-group/location-group-form/location-group-form.component';
import { PaymentTypeListComponent } from './payment-type/payment-type-list/payment-type-list.component';
import { PaymentTypeFormComponent } from './payment-type/payment-type-form/payment-type-form.component';
import { EntityTypeFormComponent } from './entity-type/entity-type-form/entity-type-form.component';
import { EntityTypeListComponent } from './entity-type/entity-type-list/entity-type-list.component';
import { BankLoanListComponent } from './bank-loan/bank-loan-list/bank-loan-list.component';
import { BankLoanFormComponent } from './bank-loan/bank-loan-form/bank-loan-form.component';
import { TaxListComponent } from './tax/tax-list/tax-list.component';
import { TaxFormComponent } from './tax/tax-form/tax-form.component';
import { TaxRateListComponent } from './tax-rate/tax-rate-list/tax-rate-list.component';
import { TaxRateFormComponent } from './tax-rate/tax-rate-form/tax-rate-form.component';
import { TermListComponent } from './term/term-list/term-list.component';
import { TermFormComponent } from './term/term-form/term-form.component';

import { UomFormComponent } from './uom/uom-form/uom-form.component';
import { UomListComponent } from './uom/uom-list/uom-list.component';
import { ItemCategoryFormComponent } from './item-category/item-category-form/item-category-form.component';
import { ItemCategoryListComponent } from './item-category/item-category-list/item-category-list.component';
import { ItemPricingFormComponent } from './item-pricing/item-pricing-form/item-pricing-form.component';
import { ItemPricingListComponent } from './item-pricing/item-pricing-list/item-pricing-list.component';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderByPipe } from './reporting-field/orderBy';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { ArraySortPipe } from './orderBy';
import { ReportingFieldFormComponent } from './reporting-field/reporting-field-form/reporting-field-form.component';
import { ReportingFieldTypeListComponent } from './reporting-field-type/reporting-field-type-list/reporting-field-type-list.component';
import { ReportingFieldTypeFormComponent } from './reporting-field-type/reporting-field-type-form/reporting-field-type-form.component';
import { ReportingFieldListComponent } from './reporting-field/reporting-field-list/reporting-field-list.component';

import { SysUserListComponent } from './sys-user/sys-user-list/sys-user-list.component';
import { SysUserFormComponent } from './sys-user/sys-user-form/sys-user-form.component';
// import { ConfirmDialogComponent } from '../common/confirm-dialog/confirm-dialog.component';
import { ItemQuantitiesComponent } from './item/resources/item-quantities/item-quantities.component';
import { ModalNewItemQuantityComponent } from './item/resources/modal-new-item-quantity/modal-new-item-quantity.component';
import { LocationItemComponent } from './location/resources/location-item/location-item.component';
import { LocationDetailComponent } from './location/resources/location-detail/location-detail.component';
import { AuditTrailViewerFormComponent } from './audit-trail-viewer/audit-trail-viewer-form/audit-trail-viewer-form.component';
import { TreeviewModule } from 'ngx-treeview';
import { ModalItemHistoryComponent } from './resources/modal-item-history/modal-item-history.component';
import { AccountSubtotalSelectComponent } from './resources/account-subtotal-select/account-subtotal-select.component';
import { AccountSelectComponent } from './resources/account-select/account-select.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DataTablesModule } from 'angular-datatables';
import {NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { AccountMaskPipe } from './banks/bank-list/accountMask.pipe';
import { ItemTypeListComponent } from './item-type/item-type-list/item-type-list.component';
import { ItemTypeFormComponent } from './item-type/item-type-form/item-type-form.component';
import { ItemGroupListComponent } from './item-group/item-group-list/item-group-list.component';
import { ItemGroupFormComponent } from './item-group/item-group-form/item-group-form.component';

@NgModule({
  declarations: [
    ArraySortPipe,
    BankFormComponent,
    BankListComponent,
    AccountListComponent,
    AccountFormComponent,
    LocationFormComponent,
    LocationListComponent,
    ItemListComponent,
    ItemFormComponent,
    ItemDetailComponent,
    ItemBalanceComponent,
    ItemCustomerComponent,
    ItemSupplierComponent,
    CompanyFormComponent,
    CompanyListComponent,
    CurrencyListComponent,
    CurrencyFormComponent,
    AccountMarginListComponent,
    AccountMarginFormComponent,
    AccountSubtotalListComponent,
    AccountSubtotalFormComponent,
    CheckStockListComponent,
    CheckStockFormComponent,
    CompanyAccessListComponent,
    CompanyAccessFormComponent,
    CurrencyRateListComponent,
    CurrencyRateFormComponent,
    EntityAccessListComponent,

    EntityAccessFormComponent,
    LocationAccessListComponent,
    LocationAccessFormComponent,
    LocationGroupListComponent,
    LocationGroupFormComponent,
    LocationGroupFormComponent,
    PaymentTypeListComponent,
    PaymentTypeFormComponent,

    EntityTypeFormComponent,
    EntityTypeListComponent,
    BankLoanListComponent,
    BankLoanFormComponent,
    TaxListComponent,
    TaxFormComponent,
    TaxRateListComponent,
    TaxRateFormComponent,
    TermListComponent,
    TermFormComponent,
    UomListComponent,
    UomFormComponent,
    ItemCategoryFormComponent,
    ItemCategoryListComponent,
    ItemPricingFormComponent,
    ItemPricingListComponent,

    ReportingFieldListComponent,
    ReportingFieldFormComponent,
    OrderByPipe,
    ReportingFieldTypeListComponent,
    ReportingFieldTypeFormComponent,
    SysUserListComponent,
    SysUserFormComponent,
   // ConfirmDialogComponent,
    ItemQuantitiesComponent,
    ModalNewItemQuantityComponent,
    LocationItemComponent,
    LocationDetailComponent,
    AuditTrailViewerFormComponent,
    ModalItemHistoryComponent,
    AccountSubtotalSelectComponent,
    AccountSelectComponent,
    AccountMaskPipe,
    ItemTypeListComponent,
    ItemTypeFormComponent,
    ItemGroupListComponent,
    ItemGroupFormComponent,
  ],
    imports: [
        SharedModule,
        CommonModule,
        FilterPipeModule,
        BsDatepickerModule.forRoot(),
        TreeviewModule.forRoot(),
        PopoverModule,
        TooltipModule,
        NgxSliderModule,
        DataTablesModule,
        NgbPaginationModule,
        NgbDropdownModule,
    ],
  exports: [
    BankFormComponent,
    BankListComponent,
    AccountListComponent,
    AccountFormComponent,

    LocationListComponent,
    ItemListComponent,
    ItemFormComponent,
    ItemDetailComponent,
    ItemBalanceComponent,
    ItemCustomerComponent,
    ItemSupplierComponent,
    CompanyFormComponent,
    CompanyListComponent,
    CurrencyListComponent,
    CurrencyFormComponent,
    AccountMarginListComponent,
    AccountMarginFormComponent,
    AccountSubtotalListComponent,
    AccountSubtotalFormComponent,
    CheckStockListComponent,
    CheckStockFormComponent,
    CompanyAccessListComponent,
    CompanyAccessFormComponent,
    CurrencyRateListComponent,
    CurrencyRateFormComponent,
    EntityAccessListComponent,

    EntityAccessFormComponent,
    LocationAccessListComponent,
    LocationAccessFormComponent,
    LocationGroupListComponent,
    LocationGroupFormComponent,
    LocationGroupFormComponent,
    PaymentTypeListComponent,
    PaymentTypeFormComponent,

    EntityTypeFormComponent,
    EntityTypeListComponent,
    FilterPipeModule,
    BsDatepickerModule,
    // ConfirmDialogComponent,
    AuditTrailViewerFormComponent,
  ],
  providers: [DatePipe],
})
export class AdminModule { }
