import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { TableDataService } from '../../../Services/tableData.service';
import { BsToastService } from '../../../Services/bs-toast-service';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { CustomArrayFilterPipe } from '../custom-array-filter.pipe';
import { AppConfigService } from '../../../Services/app-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { PermissionsService } from '../../../Services/permissions.service';

@Component({
  selector: 'app-batch-new-and-post-modal',
  templateUrl: './batch-new-and-post-modal.component.html',
  styleUrls: ['./batch-new-and-post-modal.component.scss'],
})
export class BatchNewAndPostModalComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('closeButton') closeButton: ElementRef;
  searchText: string;
  @Input() subLedger: any;
  @Input() batchType: any;
  @Output() updateItemsDisplayedByBatch = new EventEmitter<any>();

  batchList: any;
  batchCount = 0;
  vm = {
    batches: [],
    tempBatchLocked: false,
    tempDescription: '',
    tempMyBatch: false,
    lockedBatches: false,
    tempLength: 0,
    batchId: '',
    list: [],
    subLedger: '',
  };
  batchesHidden = true;
  pageSize = this.appConfig.ListPageSize;
  batch: {
    header_count: 0;
    my_batch: boolean;
  };
  isAdmin = false;
  reportBatchId = '';
  batchBeingDisplayed = false;
  permissions: any;

  constructor(
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private confirmDialog: ConfirmDialogService,
    private appConfig: AppConfigService,
    private router: Router,
    private localStorage: StorageService,
    private perms: PermissionsService
  ) {
    this.permissions = {};
  }

  ngOnInit() {
    // TODO - add more permissions etc. as overall permissions vission becomes more clear
    this.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    if (this.permissions) {
      this.isAdmin = this.permissions.Admin;
    }
  }

  ngOnChanges() {
    this.getBatches();
  }

  ngAfterViewInit() {}

  getBatches() {
    if (this.batchType) {
      let tempBatchType;
      tempBatchType = this.batchType;

      let paramString = '';
      paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
      // paramString += '&search=' + ((this.vm.paymentSearch) ? this.vm.paymentSearch : '');
      // paramString += '&fromDate=' + ((this.vm.paymentFromDate) ? this.dateFilter.GetDateFormat(this.vm.paymentFromDate) : '');
      // paramString += '&toDate=' + ((this.vm.paymentToDate) ? this.dateFilter.GetDateFormat(this.vm.paymentToDate) : '');
      paramString += '&lockedBatches=' + this.vm.lockedBatches;
      // paramString += '&includePosted=' + ((this.vm.includePosted) ? this.vm.includePosted : '');
      // console.log(paramString);
      this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
        this.vm.batches = result;
        this.batchList = result;
        this.batchCount = this.batchList.length;
        // console.log(this.batchList);
        this.setDisplayValues('');
      });
    }
  }

  setReportBatchId(batchId) {
    this.reportBatchId = batchId;
  }

  setDisplayValues(batchId) {
    if (!batchId) {
      return;
    }

    // check if users batch and if not notify they can't mod.
    const chkOwner: any = this.batchList.filter((x) => x.id == batchId);

    if (!chkOwner[0].my_batch && !this.perms.Get('Admin')) {
      this.notification.showWarningToast('Not batch owner. Cannot modify');
    }

    // todo do we let them even see whats in batches if NOT admin?
    this.vm.tempDescription = '';

    this.vm.batchId = batchId;

    // this.vm.tempDescription = 'Batch';
    const obj: any = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true);
    if (obj.length == 0) {
      obj[0] = { description: '', locked: '', tempMyBatch: false, header_count: 0 };
    }
    this.vm.tempDescription = obj ? obj[0].description : '';
    this.vm.tempBatchLocked = obj ? obj[0].locked : false;
    this.vm.tempMyBatch = obj ? obj[0].my_batch : false;
    this.vm.tempLength = obj ? obj[0].header_count : 0;
  }

  includePosted(event: any) {
    // console.log('checked: ' + event.target.checked);
    // console.log('vm locked: ' + this.vm.lockedBatches);
    this.getBatches();
  }

  emitUpdateToDisplayedRecords(batchId) {
    this.updateItemsDisplayedByBatch.emit(batchId);
  }

  deleteBatch(batchId: string) {
    const messages: string[] = [
      $localize`:Are you sure you want to delete this Batch? @@areyousureyouwanttodeleterthisbatch:Are you sure you want to delete this Batch?`,
      '',
    ];
    this.confirmDialog.confirmThis(
      messages,
      () => {
        this.tableDataService.deleteTableDataById('t_batch', batchId).subscribe(
          (result) => {
            this.getBatches();
            // retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.showErrorToast($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            // this.onClose();
            // alert('hello');
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  FilterObject(value, filterObj, except = false) {
    return new CustomArrayFilterPipe().transform(value, filterObj);
  }

  postBatchById(batchId: string) {
    const messages: string[] = [
      $localize`:Are you sure you want to Post this Batch? @@areyousureyouwanttopostrthisbatch:Are you sure you want to Post this Batch?`,
      '',
    ];
    this.confirmDialog.confirmThis(
      messages,
      () => {
        const changes = { locked: 'true' };
        this.tableDataService
          .patch('t_batch', batchId, changes)
          .then((item: any) => {
            if (item) {
              // this.notification.success(item.description);
            }
            this.getBatches();
          })
          .catch((msg: string) => {
            console.log(msg);
          });
      },
      () => {
        this.notification.showSuccessToast($localize`:Post Canceled @@postCanceled:Post Canceled`);
      }
    );
  }

  postBatch(batchId) {
    const changes = { locked: 'true' };
    this.tableDataService
      .patch('t_batch', batchId, changes)
      .then((item: any) => {
        if (item) {
          // this.notification.success(item.description);
        }
        this.getBatches();
      })
      .catch((msg: string) => {
        console.log(msg);
      });
  }

  updateBatch() {
    const changes = { description: this.vm.tempDescription };

    this.tableDataService
      .patch('t_batch', this.vm.batchId, changes)
      .then((item: any) => {
        // this.notification.success(this.vm.tempDescription);
        this.getBatches();
      })
      .catch((msg: string) => {
        console.log(msg);
      });
  }

  newBatch() {
    let newBatch;
    // if (this.batchType === 3) {
    //   newBatch = { type_id: 3 };
    // } else {
    //   newBatch = { type_id: 5 };
    // }
    newBatch = { type_id: this.batchType };

    this.tableDataService.post('t_batch', newBatch).subscribe(
      (item: any) => {
        this.getBatches();
        this.vm.batchId = item.id;
      },
      (error: { error: { Message: string }; statusText: string }) => {
        let ErrorMsg: string;
        if (typeof error.error.Message != 'undefined') {
          ErrorMsg = error.error.Message;
        } else {
          ErrorMsg = error.statusText;
        }
        console.log(ErrorMsg);
      }
    );
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}
