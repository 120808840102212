import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren, ChangeDetectorRef,
} from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { StorageService } from '../../../../Services/storage.service';
import { DataExportService } from '../../../../Services/dataExport.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../../../../Services/app-config.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { SendEmailInfoModel } from '../../../../DataModels/send-email-info-model';
import { EmailModel } from '../../../../DataModels/email-model';
import { ActivityFeedModel } from '../../../../DataModels/activity-feed-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import { take } from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {Modal} from 'bootstrap';
import {RoutingTrackerService} from '../../../../Services/routing-tracker.service';


@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.css'],
})
export class StatementsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  private rptName: string;
  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  @ViewChildren('statement') statementsList: QueryList<any>;

  // email statements
  @ViewChildren('Email') EmailFromDoc: QueryList<ElementRef>;
  @ViewChildren('EntityId') EntityFromDoc: QueryList<ElementRef>;
  @ViewChildren('StatementForm') FormDocument: QueryList<ElementRef>;

  hideDetails;
  public vm: any = {
    dateError: null,
    showFilters: false,
    report: [],
    buttonsDisabled: false,
    entityType: [],
    company: [],
    location: [],
    entity: [],
    currency: [],
    request: {
      toDate: null,
      fromDate: null,
      includePaid: null,
      entity_id: null,
      entity_type_id: null,
      company_id: null,
      currency_id: null,
    },
    entityFilter: {
      name: null,
    },
    email: {
      toemail: null,
      fromemail: this.appConfig.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    totalOwing: null,
    totalCurrent: null,
    total1to30: null,
    total31to60: null,
    total61to90: null,
    total91plus: null,
    payment: {},
    entityInput: '',
    filtered: false,
    subLedger: null,
    showEmail: true,
    entity_email: null,
    entityName: [],
    startEntity: 0,
    exportToExcel: true,
    payTotals: null,
  };
  company_id;
  days_late;

  public urlParams: any = [];

  emailInfo = new SendEmailInfoModel();
  objEmail = new EmailModel();
  af = new ActivityFeedModel();

  buttonConfig: any = {
    reportFormat: true,
    print: true,
    email: true,
    update: true,
    exportExcel: true,
  };

  emailToSendArray: EmailModel[] = [];
  entityIdArray = [];
  emailAddressesFromDocArray = [];
  reportFormatCheckBoxValue = true;
  reportDataToSendInEmail: EmailModel = new EmailModel();

  routedFrom = '';

  constructor(
    public tableDataService: TableDataService,
    public dateFilter: DateFilterService,
    private titleService: Title,
    public localStorage: StorageService,
    public excelExporter: DataExportService,
    private route: ActivatedRoute,
    private router: Router,
    public appConfig: AppConfigService,
    public confirmDialogService: ConfirmDialogService,
    public notificationService: BsToastService,
    public changeDetector: ChangeDetectorRef,
    public prevUrl: RoutingTrackerService
  ) {
    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  ngOnInit() {

    this.urlParams = this.route.snapshot.queryParams;

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
      this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

      if (!this.vm.permissions.ReceivableStatements && !this.vm.permissions.Admin) {
        document.location.href = '/';
      }

      this.vm.print = [];
      if (this.urlParams.entity_id) {
        this.FilterOptionsInit().then(x => {
          this.update().then();
        });
      } else {
        this.FilterOptionsInit().then();
      }

      this.rptName = $localize`:Statement Report @@statementReport:Statement Report`;
    });
    this.vm.report_format = true;
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    this.statementsList.changes.subscribe((r) => {
    });
  }

  ngAfterViewChecked() {
    // let reportData = '';
    if (this.vm.report.length > 0 && this.vm.report_format == true) {
      const divReport = document.getElementById('excel-export');
      this.reportDataToSendInEmail.pdfHtml = divReport.outerHTML;
      this.reportDataToSendInEmail.activity_message = 'Statement Report Sent';
    } else if (this.vm.report.length > 0 && this.vm.report_format == false) {
      const divForm = document.getElementById('statement-form');
      this.reportDataToSendInEmail.pdfHtml = divForm.outerHTML;
      this.reportDataToSendInEmail.activity_message = 'Statement Sent';
    }

    this.reportDataToSendInEmail.header_id = '';
    this.reportDataToSendInEmail.entity_id = '';
  }

  async FilterOptionsInit() {
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.vm.entity = await this.getFilterValues('entity', '?state=active');
    for (const [x, value] of Object.entries(this.vm.entity)) {
      this.vm.entityName.push({ name: this.vm.entity[x].name });
    }
    // console.log('entity:' + JSON.stringify(this.vm.entity));
    if (this.urlParams.entity_id != null || this.urlParams.entity_id != undefined) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y]) {
          if (this.vm.entity[y].id == this.urlParams.entity_id) {
            this.vm.entityInput = this.vm.entity[y].name;
            this.vm.request.entity_id = this.vm.entity[y].id;
            this.vm.request.entity_email = this.vm.entity[y].email;
            break;
          }
        }
      }
    }

    if (this.urlParams.fromDate) {
      this.vm.request.fromDate = this.urlParams.fromDate;
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth() - 3, 1);
      this.vm.request.fromDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      // this.localStorage.StorageSet('fromDate', this.vm.request.fromDate);
    }

    if (this.urlParams.toDate) {
      this.vm.request.toDate = this.urlParams.toDate;
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth(), 1);
      d.setDate(d.getDate() - 1);
      this.vm.request.toDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
      // this.localStorage.StorageSet('toDate', this.vm.request.toDate);
    }
  }

  async getFilterValues(tablename, param) {
    let values: any;
    values = null;
    await this.tableDataService
      .getTableData(tablename + param + '&pageNumber=0&pageSize=99999')
      .toPromise()
      .then((result: ResponseModel) => {
        const data: any = result.Data;
        values = data;
      });
    // this.localStorage.StorageSet(tablename, JSON.stringify(values));
    return values;
  }

  async getEmail() {
    // because ng .toArray makes elementref array - I have to build my own regular array - index will match main doc since in same doc
    this.emailAddressesFromDocArray = [];
    this.EmailFromDoc.changes.pipe(take(1)).subscribe((r) => {
      this.EmailFromDoc.forEach((el, index) => {
        // console.log(`index ${index} :` + el.nativeElement.innerText);
        this.emailAddressesFromDocArray.push(el.nativeElement.innerText);
      });
    });
    // console.log('recid array: ' + JSON.stringify(this.recIdArray));
    return this.emailAddressesFromDocArray;
  }

  async getEntityId() {
    // because ng .toArray makes elementref array - I have to build my own regular array - index will match main doc since in same doc
    this.entityIdArray = [];
    this.EntityFromDoc.changes.pipe(take(1)).subscribe((r) => {
      this.EntityFromDoc.forEach((el, index) => {
        // console.log(`index ${index} :` + el.nativeElement.innerText);
        this.entityIdArray.push(el.nativeElement.innerText);
      });
    });
    // console.log('ent id array: ' + JSON.stringify(this.entityIdArray));
    return this.entityIdArray;
  }

  sendStatements() {
    this.tableDataService
      .putTableDataByArray('email/PostByArray', this.emailToSendArray)
      .subscribe((resp: HttpResponse<any>) => {
        if (resp != null) {
          // this.notificationService.showWarningToast(resp.status?.toString());
        } else {
        }
        this.emailToSendArray = [];
      });
  }

  setEmailData() {
    // do stuff to send email to via sendmail modal inoput
    this.reportFormat(this.reportFormatCheckBoxValue);
    const element = document.getElementById('sendEmailModal') as HTMLElement;
    const emailModal = new Modal(element);
    emailModal.show();
  }

  reportFormat(event: any) {
    // console.log('report format: ' + event);
    this.emailToSendArray = [];
    this.reportFormatCheckBoxValue = event;
    const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (this.vm.report.length > 0 && !event) {
      // console.log('subscribing');
      this.getEmail().then();
      this.getEntityId().then();
      this.FormDocument.changes.pipe(take(1)).subscribe(async (r) => {


        // console.log('email array before subscribe main doc: ' + JSON.stringify(this.emailAddressesFromDocArray));
        let noEmailAddress = false;
        let noEmailAddressCount = 0;

        this.FormDocument.forEach((element, index) => {

          const emailDocObj = {} as EmailModel;
          emailDocObj.entity_id = this.entityIdArray[index];
          emailDocObj.header_id = '';
          emailDocObj.activity_message = 'Statement emailed';
          emailDocObj.fromEmail = '';
          emailDocObj.FileName = 'Statement.pdf';
          emailDocObj.fromName = 'AcctXErp';
          emailDocObj.toEmail = this.emailAddressesFromDocArray[index];
          emailDocObj.body = 'Please see the attached Statement.' + '<br><br><br>'
            + 'Best regards,'
            + '<br><br>'
            + 'AcctXErp'
            + '<br><br>';
          emailDocObj.Subject = 'AcctXErp Statement';
          emailDocObj.pdfHtml = element.nativeElement.outerHTML;

          // if (emailDocObj.toEmail) {
          if (emailRegExp.exec(emailDocObj.toEmail)) {
            this.emailToSendArray.push(emailDocObj);
          } else {
            noEmailAddress = true;
            noEmailAddressCount += 1;
          }

          // console.log(this.emailToSendArray.push(emailDocObj));
        });
        // console.log('testArray of email and html: ' + JSON.stringify(this.emailToSendArray));

        if (noEmailAddress) {
          this.notificationService.showWarningToast(
            noEmailAddressCount + ' item(s) have no To email address and will not be sent if emailed'
          );
        }
      });
    }
  }

  async update() {
    this.vm.buttonsDisabled = true;
    this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
    const urlParamsJson = {
      subLedger: 'ar',
      toDate: this.dateFilter.GetDateFormat(this.vm.request.toDate, 'yyyy-MM-dd'),
      entity_id: this.vm.request.entity_id,
      entity_type_id: this.vm.request.entity_type_id,
      company_id: this.vm.request.company_id,
      currency_id: this.vm.request.currency_id,
      includePaid: this.vm.request.includePaid,
    };
    const urlParams: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getTableData('aging', '?' + urlParams).subscribe((result) => {
      this.vm.report = result;
      // console.log('statement data: ' + JSON.stringify(result));
      this.calcReportTotals();
      this.vm.showFilters = false;
      this.vm.buttonsDisabled = false;
      this.vm.payment = {};
      if (this.vm.entityInput || this.vm.request.includePaid) {
        this.vm.filtered = true;
      } else {
        this.vm.filtered = false;
      }
      this.reportFormat(this.reportFormatCheckBoxValue);
      // this.localStorage.StorageSet('toDate', this.vm.request.toDate);
    });
    this.changeDetector.detectChanges();
  }

  exportToExcel() {
    this.vm.buttonsDisabled = true;
    if (this.vm.report_format == false) {
      this.vm.report_format = true;
    }
    setTimeout(() => {
      this.excelExporter.exportToExcel(this.ExcelExport, 'Statement Report.xlsx');
      this.vm.buttonsDisabled = false;
    }, 1000);
  }

  setReportData() {
    console.log('here');
  }

  calcReportTotals() {
    let reportBalance = 0;
    let reportCurrent = 0;
    let report1 = 0;
    let report30 = 0;
    let report60 = 0;
    let report90 = 0;
    for (const [x, value] of Object.entries(this.vm.report)) {
      let entityOwing = 0;
      let entityCurrent = 0;
      let entity1 = 0;
      let entity30 = 0;
      let entity60 = 0;
      let entity90 = 0;
      for (const [y, value2] of Object.entries(this.vm.report[x].invoices)) {
        entityOwing += this.vm.report[x].invoices[y].owing;
        if (this.vm.report[x].invoices[y].days_late <= 0) {
          entityCurrent += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 30) {
          entity1 += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 60) {
          entity30 += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 90) {
          entity60 += this.vm.report[x].invoices[y].owing;
        } else {
          entity90 += this.vm.report[x].invoices[y].owing;
        }
      }
      reportBalance += entityOwing;
      reportCurrent += entityCurrent;
      report1 += entity1;
      report30 += entity30;
      report60 += entity60;
      report90 += entity90;
      this.vm.report[x].totalOwing = entityOwing;
      this.vm.report[x].totalCurrent = entityCurrent;
      this.vm.report[x].total1to30 = entity1;
      this.vm.report[x].total31to60 = entity30;
      this.vm.report[x].total61to90 = entity60;
      this.vm.report[x].total91plus = entity90;
    }
    this.vm.totalOwing = reportBalance;
    this.vm.totalCurrent = reportCurrent;
    this.vm.total1to30 = report1;
    this.vm.total31to60 = report30;
    this.vm.total61to90 = report60;
    this.vm.total91plus = report90;
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] !== '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  emailReport(toEmail) {
    // console.log('here');
    // let tempPdfHtml = '';
    // let msgString = '';
    // if (this.vm.report_format) {
    //   tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    //   msgString =  $localize`:Statement Report @@statementReport:Statement Report`;
    // } else if(!this.vm.report_format ) {
    //   this.FormDocument.forEach((element, index) => {
    //     tempPdfHtml += element.nativeElement.outerHTML;
    //   });
    //   msgString =  $localize`:Statement @@statement:Statement`;
    // }
    //
    // // console.log('report fromat: ' + this.vm.report_format);
    // // console.log('report fromat: ' + tempPdfHtml);
    //
    // this.vm.email.toemail = toEmail;
    // this.vm.email.PdfHtml = tempPdfHtml;
    // this.vm.email.Subject = msgString + ' ' + this.vm.request.toDate;
    // if (tempPdfHtml != '') {
    //   // this.aEmail.sendEmail(this.vm.email);
    // } else {
    //   this.notificationService.showWarningToast('There is no email content to send');
    // }
  }

  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.entity_email = this.vm.entity[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput == '' || this.vm.entityInput == null) {
      this.vm.request.entity_id = '';
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.entityInput }).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].name;
        const entityEmail = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].email;
        if (entityId != '' || entityId != null) {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
          this.vm.entity_email = entityEmail;
        } else {
          this.vm.request.entity_id = '';
          this.vm.entity_email = '';
        }
      } else {
        this.vm.request.entity_id = '';
        this.vm.entity_email = '';
      }
    }
    if (entityIdStart != this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }

  updateEntity() {
    this.vm.buttonsDisabled = true;
    // this.localStorage.StorageSet('entity', '');
    this.tableDataService
      .getTableData('entity', '?state=active&pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        this.vm.entity = result.Data;
        this.vm.buttonsDisabled = false;
      });
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  close() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom == undefined) {
      window.close();
    } else {
      this.router.navigateByUrl('/home').then();
    }
  }

  clear() {
    this.vm.request = {};
    this.vm.report = [];
    this.vm.entityInput = '';
  }
}
