import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { AssemblyListComponent } from './assembly/assembly-list/assembly-list.component';
import { AssemblyFormComponent } from './assembly/assembly-form/assembly-form.component';
import { BomFormComponent } from './bill-of-materials/bom-form/bom-form.component';
import { BomListComponent } from './bill-of-materials/bom-list/bom-list.component';
import { CountFormComponent } from './count/count-form/count-form.component';
import { CountListComponent } from './count/count-list/count-list.component';
import { TransferFormComponent } from './transfer/transfer-form/transfer-form.component';
import { TransferListComponent } from './transfer/transfer-list/transfer-list.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { OrderByPipe } from './orderBy';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { DecimalNewPipe } from '../common/DecimalNewPipe';
import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  CurrencyPipe,
} from '@angular/common';
import { TransactionsModule } from './../transactions/transactions.module';
import { ItemSelectComponent } from './resources/item-select/item-select.component';
import { ModalBomSelectComponent } from './resources/modal-bom-select/modal-bom-select.component';
import { ModalItemHistoryComponent } from './resources/modal-item-history/modal-item-history.component';
import { ModalItemSelectComponent } from './resources/modal-item-select/modal-item-select.component';
import { CustomFilterPipe } from '../admin/custom-filter.pipe';
import { PrintV2Component } from './resources/print-v2/print-v2.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AdminModule } from '../admin/admin.module';
import { DataTablesModule } from 'angular-datatables';
import {
  NgbDropdownModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import {ModalSelectItemWithFilterComponent} from './resources/modal-select-item-with-filter/modal-select-item-with-filter.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    AssemblyListComponent,
    AssemblyFormComponent,
    BomFormComponent,
    BomListComponent,
    CountFormComponent,
    CountListComponent,
    TransferFormComponent,
    TransferListComponent,
    OrderByPipe,
    ItemSelectComponent,
    ModalBomSelectComponent,
    ModalItemHistoryComponent,
    ModalItemSelectComponent,
    PrintV2Component,
    ModalSelectItemWithFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FilterPipeModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule,
    TransactionsModule,
    AdminModule,
    DataTablesModule,
    NgbPaginationModule,
    NgbDropdownModule,
    TooltipModule,
  ],
  exports: [
    TypeaheadModule,
    BsDatepickerModule,
    ItemSelectComponent,
    ModalBomSelectComponent,
    ModalItemHistoryComponent,
    ModalItemSelectComponent,
  ],
  providers: [DatePipe, DecimalPipe, DecimalNewPipe, CurrencyPipe, CustomFilterPipe],
})
export class InventoryModule {}
