export class EntityContactModel {
  id: string;
  entity_id: string;
  name: string;
  position: string;
  other: string;
  cell: string;
  email: string;
  phone: string;
  description: string;
  address_id: string;
  active: boolean;
}
