export class AccountModel{
  id: string;
  name: string;
  number: number;
  subtotal_id: string;
  margin_id: string;
  uom_id: string;
  debit: boolean;
  audit_sequence: number;
  active: boolean;
}
