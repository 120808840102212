import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { Title } from '@angular/platform-browser';
import { NotificationService } from '../../../Services/notification.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { AppConfigService } from '../../../Services/app-config.service';
import { DatePipe, ViewportScroller } from '@angular/common';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import { ResponseModel } from '../../../DataModels/response-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';

@Component({
  selector: 'app-statistical-list',
  templateUrl: './statistical-list.component.html',
  styleUrls: ['./statistical-list.component.css'],
})
export class StatisticalListComponent implements OnInit, OnDestroy{

  vm: any = {};
  statisticalCount = 0;
  isLoaded = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: NotificationService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private decimalPipe: DecimalNewPipe,
    private vps: ViewportScroller,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    this.vm.list = [];
    this.vm.statisticalSearch = [];
    this.vm.batches = [];
    this.vm.batchFilter = {};
  }

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  show = false;
  selectItems;

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;

    this.titleService.setTitle($localize`:Statistical Entries @@statisticalEntries:Statistical Entries`);

    this.vm.owingSelected = this.vm.totalSelected = 0;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences', ''));
    this.vm.batchesHidden = !this.vm.Preferences.ShowBatches;

    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    if (
      !this.vm.permissions.EditJournalEntries &&
      !this.vm.permissions.ViewJournalEntries &&
      !this.vm.permissions.Admin
    ) {
      document.location.href = '/';
    }

    this.vm.statisticalFilter = this.localStorage.StorageGet('statisticalFilter', {
      text: '',
      sort: 'audit_sequence',
      ascending: true,
    });

    this.vm.statisticalSearch = this.localStorage.StorageGet('statisticalSearch', '');
    this.vm.statisticalToDate = this.localStorage.StorageGet('statisticalToDate', '');
    this.vm.statisticalFromDate = this.localStorage.StorageGet('statisticalFromDate', '');
    this.vm.statisticalNumberOfResults = '25';
    this.vm.statisticalSearchLocked = this.localStorage.StorageGet('statisticalSearchLocked', '');

    this.configDataTable();
    this.getPagerStatus().then();
    this.init();
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([3, 'desc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  init() {
    this.vm.searching = true;
    this.vm.batchId = undefined;
    this.vm.tempDescription = '';

    let paramString = '';
    paramString += 'batchType=16';
    paramString += '&search=' + (this.vm.statisticalSearch ? this.vm.statisticalSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.statisticalFromDate ? this.dateFilter.GetDateFormat(this.vm.statisticalFromDate) : '');
    paramString +=
      '&toDate=' + (this.vm.statisticalToDate ? this.dateFilter.GetDateFormat(this.vm.statisticalToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    paramString += '&numberOfResults=' + (this.vm.statisticalNumberOfResults ? this.vm.statisticalNumberOfResults : '');
    paramString += '&includePosted=' + (this.vm.includePosted ? this.vm.includePosted : '');

    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      paramString += 'search=' + (this.vm.statisticalSearch ? this.vm.statisticalSearch : '');
      paramString +=
        '&fromDate=' + (this.vm.statisticalFromDate ? this.dateFilter.GetDateFormat(this.vm.statisticalFromDate) : '');
      paramString +=
        '&toDate=' + (this.vm.statisticalToDate ? this.dateFilter.GetDateFormat(this.vm.statisticalToDate) : '');
      paramString +=
        '&numberOfResults=' + (this.vm.statisticalNumberOfResults ? this.vm.statisticalNumberOfResults : '');
      paramString += '&recurring=false';
      paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.tableDataService.getApiListData('StatisticalList', '?' + paramString).subscribe((result2: ResponseModel) => {
        this.vm.list = result2.Data;
        // console.log('sysusr data:' + JSON.stringify(this.vm.list));
        this.totalRecords = result2.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;

        this.convertToNumbers();
        this.vm.searching = false;
        this.statisticalCount = this.vm.list.length;
        this.isLoaded = true;
      });
    });

    this.localStorage.StorageSet('statisticalSearch', this.vm.statisticalSearch);
    this.localStorage.StorageSet('statisticalToDate', this.vm.statisticalToDate);
    this.localStorage.StorageSet('statisticalFromDate', this.vm.statisticalFromDate);
    this.localStorage.StorageSet('statisticalSearchLocked', this.vm.statisticalSearchLocked);
    this.localStorage.StorageSet('statisticalNumberOfResults', this.vm.statisticalNumberOfResults);
  }

  filter(filter) {
    this.vm.statisticalFilter = Object.assign(this.vm.statisticalFilter, filter);
    this.localStorage.StorageSet('statisticalFilter', JSON.stringify(this.vm.statisticalFilter));
  }

  convertToNumbers() {
    for (const obj of this.vm.list) {
      obj.header_amount = Number(obj.header_amount);
      obj.audit_sequence = Number(obj.audit_sequence);
    }
  }

  updateBatchesSelectedInModal(batchId: string) {
    this.getBatchItems2(batchId);
    // this.paginate(false);
  }

  getBatchItems(batchId) {
    console.log('batchid:' + batchId);
    this.vm.tempDescription = '';
    this.vm.batchId = batchId;
    this.vm.searching = true;
    this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
    this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;

    let paramString = '';
    paramString += 'batch_id=' + (this.vm.batchId ? this.vm.batchId : '');
    this.tableDataService.getApiListData('StatisticalList', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.convertToNumbers();
      this.vm.searching = false;
      // this.setList(this.vm.list);
    });
  }

  getBatchItems2(batchId) {
    this.vm.tempDescription = '';
    this.vm.batchId = batchId;
    this.vm.searching = true;
    this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
    this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
    this.tableDataService.getTableData('StatisticalList', '?batchId=' + this.vm.batchId + '&recurring=false').pipe().subscribe((result: any) => {
      this.convertToNumbers();
      this.vm.searching = false;
      console.log(result);
      this.vm.list = result.Data;
      this.vm.listInitiated = true;
    });
  }

  getBatches() {
    this.vm.searching = true;

    let paramString = '';
    paramString += 'batchType=16';
    paramString += 'recurring=false';
    paramString += '&search=' + (this.vm.statisticalSearch ? this.vm.statisticalSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.statisticalFromDate ? this.dateFilter.GetDateFormat(this.vm.statisticalFromDate) : '');
    paramString +=
      '&toDate=' + (this.vm.statisticalToDate ? this.dateFilter.GetDateFormat(this.vm.statisticalToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    paramString += '&numberOfResults=' + (this.vm.statisticalNumberOfResults ? this.vm.statisticalNumberOfResults : '');

    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      if (this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true).length === 1) {
        this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
        this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
      }
      this.vm.searching = false;
    });
  }

  // newBatch() {
  //   const newBatch = { type_id: 1 };
  //
  //   this.tableDataService.post('t_batch', newBatch).subscribe(
  //     (item: any) => {
  //       this.getBatches();
  //       this.vm.batchId = item.id;
  //       this.vm.tempDescription = 'Batch';
  //       this.notification.info($localize`:success @@success:success`);
  //     },
  //     (error: { error: { Message: string }; statusText: string }) => {
  //       let ErrorMsg: string;
  //       if (typeof error.error.Message !== 'undefined') {
  //         ErrorMsg = error.error.Message;
  //       } else {
  //         ErrorMsg = error.statusText;
  //       }
  //       this.vm.buttonsDisabled = false;
  //       this.notification.error(error);
  //     }
  //   );
  // }
  //
  // updateBatch() {
  //   this.vm.buttonsDisabled = true;
  //   const changes = { description: this.vm.tempDescription };
  //   this.tableDataService
  //     .patch('t_batch', this.vm.batchId, changes)
  //     .then((item: any) => {
  //       if (item) {
  //         this.notification.info(this.vm.tempDescription);
  //       }
  //       this.getBatches();
  //     })
  //     .catch((msg: string) => {
  //       this.vm.buttonsDisabled = false;
  //       this.notification.error(msg);
  //     });
  // }
  //
  // postBatch(batchId) {
  //   this.vm.buttonsDisabled = true;
  //   const changes = { locked: 'true' };
  //
  //   this.tableDataService
  //     .patch('t_batch', batchId, changes)
  //     .then((item: any) => {
  //       if (item) {
  //         this.notification.info(item.description);
  //       }
  //       this.getBatches();
  //     })
  //     .catch((msg: string) => {
  //       this.vm.buttonsDisabled = false;
  //       this.notification.error(msg);
  //     });
  // }

  hideBatches() {
    // this.vm.buttonsDisabled = true;
    this.vm.batchesHidden = !this.vm.batchesHidden;
    this.vm.Preferences.ShowBatches = !this.vm.batchesHidden;
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  // Server side paging ---------------------------------------------------------------------------------
  getPagedApiData() {
    this.vm.searching = true;
    this.vm.batchId = undefined;
    this.vm.tempDescription = '';

    let paramString = '';
    paramString += 'batchType=16';
    paramString += '&search=' + (this.vm.statisticalSearch ? this.vm.statisticalSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.statisticalFromDate ? this.dateFilter.GetDateFormat(this.vm.statisticalFromDate) : '');
    paramString +=
      '&toDate=' + (this.vm.statisticalToDate ? this.dateFilter.GetDateFormat(this.vm.statisticalToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    paramString += '&numberOfResults=' + (this.vm.statisticalNumberOfResults ? this.vm.statisticalNumberOfResults : '');
    paramString += '&includePosted=' + (this.vm.includePosted ? this.vm.includePosted : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('StatisticalList', '?' + paramString).subscribe((result2: ResponseModel) => {
      this.vm.list = result2.Data;
      this.totalRecords = result2.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;

      this.convertToNumbers();
      this.vm.searching = false;
      //this.setList(this.vm.list);
      this.isLoaded = true;
    });
  }

  scrollTo(anchor: string): void {
    if (!this.vm.batchesHidden) {
      this.vps.scrollToAnchor(anchor);
    }
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.statisticalSearch = value.searchTerm;
    this.vm.statisticalFromDate = value.fromDate;
    this.vm.statisticalToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.statisticalSearch = value.searchTerm;
    this.vm.statisticalFromDate = value.fromDate;
    this.vm.statisticalToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  onNavigate(aID: string, print = false) {
    this.router.navigate(['/transactions/statistical/form'], { queryParams: { statisticalId: aID } });
  }
}
