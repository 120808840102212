import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { AppConfigService } from '../../../Services/app-config.service';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import {DatePipe, ViewportScroller } from '@angular/common';
import { ResponseModel } from '../../../DataModels/response-model';
import { PreferencesService } from '../../../Services/preferences.service';
import { PermissionsService } from '../../../Services/permissions.service';
import {Subject, Subscription} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';
import {BsToastService} from '../../../Services/bs-toast-service';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})

export class InvoiceListComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    public appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private vps: ViewportScroller,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    private confirmDialogService: ConfirmDialogService) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  vm: any = {};
  invoiceCount = 0;
  isLoaded = false;
  numberOfResults = this.appConfig.maxRecords;
  public urlParams: any = [];
  serverSearchTerm = '';
  getRecurring = 0; // 0=false 1=true

  checkAll = false;
  selectedRecords = [];

  subscriptionGroup: Subscription[] = [];

  @ViewChild('UninvoicedQuotesClose', { static: false }) UninvoicedQuotesClose;
  @ViewChild('UninvoicedWorkOrdersClose', { static: false }) UninvoicedWorkOrdersClose;
  @ViewChild('UninvoicedShipmentsClose', { static: false }) UninvoicedShipmentsClose;

  // datatables stuff
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;
  batchType = '';
  documentType = '';

  formModal: any;

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();

    this.getPagerStatus().then();

    this.vm.request = {};
    // router has a hard time determining where to go with so much going on and very subtle url changes
    // too many urls in one file - should be one for one - this needs to be broken apart at some point
    // the next 2 lines fix it for now.
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';

    this.urlParams = this.route.snapshot.queryParams;

    this.vm.type = this.urlParams.type;
    if (!this.urlParams.subLedger) {
      this.vm.subLedger = this.urlParams.type;
    } else {
      this.vm.subLedger = this.urlParams.subLedger;
    }

    // batch type for batch new post modal
    if (this.vm.subLedger == 'ap') {
      this.batchType = '4';
      this.documentType = 'documentId';
    } else if (this.vm.subLedger == 'ar') {
      this.batchType = '2';
      this.documentType = 'documentId';
    } else if (this.vm.subLedger == 'quotes') {
      this.batchType = '8';
      this.documentType = 'quoteId';
    } else if (this.vm.subLedger == 'salesorders') {
      this.batchType = '9';
      this.documentType = 'salesorderId';
    } else if (this.vm.subLedger == 'purchaseorders') {
      this.batchType = '10';
      this.documentType = 'purchaseorderId';
    }

    // show search on load
    this.vm.searchHidden = false;


    this.vm.batchId = '';
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    if (this.vm.Preferences.ShowBatches) {
      this.vm.batchesHidden = !this.vm.Preferences.ShowBatches;
    } else {
      this.vm.batchesHidden = true;
    }

    if (
      !this.vm.permissions.EditReceivableInvoices
      && !this.vm.permissions.ViewReceivableInvoices
      && !this.vm.permissions.EditPayableInvoices
      && !this.vm.permissions.ViewPayableInvoices
      && !this.vm.permissions.Admin) {
    }

    this.vm.title = '';

    if (this.vm.type === 'ap') {
      this.vm.title = $localize`:Payable Invoices @@payableInvoices:Payable Invoices`;
    } else if (this.vm.type === 'ar') {
      this.vm.title = $localize`:Receivable Invoices @@receivableInvoices:Receivable Invoices`;
    } else if (this.vm.type === 'quotes') {
      this.vm.title = $localize`:Quotes @@quotes:Quotes`;
    } else if (this.vm.type === 'salesorders') {
      this.vm.title = $localize`:Sales Orders @@salesOrders:Sales Orders`;
    } else if (this.vm.type === 'purchaseorders') {
      this.vm.title = $localize`:Purchase Orders @@purchaseOrders:Purchase Orders`;
    }

    this.vm.owingSelected = this.vm.totalSelected = 0;
    this.vm.invoiceToDateOpen = this.vm.invoiceFromDateOpen = false;

    if (this.vm.unshippedOrders === 'true') {
      this.vm.unshippedOrders = true;
    }
    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptionGroup.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.dtTrigger.unsubscribe();
  }

  init() {
    // not really a search - more of a load data
    this.search();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([7, 'desc']);
  }

  async getPagerStatus() {
    this.pagerStatus.getPagerState(this.router.url).then(res => {
      if (res) {
        const pagerStuff = res;
        if (pagerStuff.totalRecords !== 0) {
          this.page = pagerStuff.lastPage;
          this.pageSize = pagerStuff.lastPageSize;
          this.startIndex = pagerStuff.startIndex;
          this.endIndex = pagerStuff.endIndex;
        }
      }
    }).catch(err => {
      console.log('pager error: ', err);
    }).then();
  }

  paginate(withDataFetch: boolean): void {

    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = ((this.page - 1) * this.pageSize) + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number((this.page) * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize){
      this.endIndex = this.totalRecords;
    }

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  scrollTo(anchor: string): void {
    if (!this.vm.batchesHidden) {
      this.vps.scrollToAnchor(anchor);
    }
  }

  updateBatchesSelectedInModal(batchId: string) {
    this.getBatchItems(batchId);
    // this.paginate(false);
  }
  getPagedApiData() {
    this.checkAll = false;

    const unPaidInput = document.getElementById('unPaid') as HTMLInputElement;

    this.vm.unpaidInvoices = unPaidInput ? unPaidInput.checked : false;
    this.vm.searching = true;
    this.vm.buttonsDisabled = true;
    this.vm.batchId = '';
    this.vm.tempDescription = '';

    let tempBatchType = '';
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '4';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '2';
    } else if (this.vm.subLedger == 'quotes') {
      tempBatchType = '8';
    } else if (this.vm.subLedger == 'salesorders') {
      tempBatchType = '9';
    } else if (this.vm.subLedger == 'purchaseorders') {
      tempBatchType = '10';
    }

    // batch type for batch new post modal
    this.batchType = tempBatchType;

    if (tempBatchType == '9' || tempBatchType == '10') {

      let paramString = '?';
      paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
      // paramString += '&batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
      paramString += '&search=' + ((this.vm.invoiceSearch) ? this.vm.invoiceSearch : '');
      paramString += '&fromDate=' + ((this.vm.invoiceFromDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
      paramString += '&toDate=' + ((this.vm.invoiceToDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
      paramString += '&unshippedOrders=' + ((this.vm.unshippedOrders) ? this.vm.unshippedOrders : '');
      paramString += '&backOrders=' + ((this.vm.backOrders) ? this.vm.backOrders : '');
      // paramString += '&numberOfResults=' + ((this.vm.invoiceNumberOfResults) ? this.vm.invoiceNumberOfResults : '');
      // paramString += '&pageNumber=' + this.gridPageNumber;
      paramString += '&pageNumber=' + (this.page - 1);
      paramString += '&pageSize=' + this.pageSize;
      paramString += '&recurring=0';

      // console.log('using getApiPagedDate method for orders with these params: ' + paramString);

      this.subscriptionGroup.push(this.tableDataService.getApiListData('ListOrders', paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;

        // ngb paginate
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.invoiceCount = result.totalRecords;
        this.convertToNumbers();
        this.vm.searching = false;
        this.vm.buttonsDisabled = false;
        this.isLoaded = true;
      }));

    } else {
      let paramString = '';
      paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
      // paramString += '&batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
      paramString += '&search=' + ((this.vm.invoiceSearch) ? this.vm.invoiceSearch : '');
      paramString += '&fromDate=' + ((this.vm.invoiceFromDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
      paramString += '&toDate=' + ((this.vm.invoiceToDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
      paramString += '&unpaidInvoices=' + ((this.vm.unpaidInvoices) ? this.vm.unpaidInvoices : false);
      // paramString += '&numberOfResults=' + ((this.vm.invoiceNumberOfResults) ? this.vm.invoiceNumberOfResults : '');
      // paramString += '&pageNumber=' + this.gridPageNumber;
      paramString += '&pageNumber=' + (this.page - 1);
      paramString += '&pageSize=' + this.pageSize;
      paramString += '&recurring=' + this.getRecurring;
      this.subscriptionGroup.push(this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.convertToNumbers();
        this.vm.searching = false;
        this.vm.buttonsDisabled = false;
        this.isLoaded = true;
      }));
    }
  }

  includeUnItems(event: any = null) {
    if (event) {
      this.vm.unpaidInvoices = true;
    } else {
      this.vm.unpaidInvoices = false;
    }
    this.pageSize = this.appConfig.ListPageSize;
    this.page = 1;
    this.getPagedApiData();
  }

  convertToNumbers() {
    for (const [x, value] of Object.entries(this.vm.list)) {
      this.vm.list[x].detail_total = Number(this.vm.list[x].detail_total);
      this.vm.list[x].payment_applied_total = Number(this.vm.list[x].payment_applied_total);
      this.vm.list[x].audit_sequence = Number(this.vm.list[x].audit_sequence);
    }
  }

  openInvoiceToDate() {
    this.vm.invoiceToDateOpen = true;
  }

  openInvoiceFromDate() {
    this.vm.invoiceFromDateOpen = true;
  }

  selectRow(rec: any, checked: boolean) {
    // console.log('rec val:' + rec + ' - ' + checked);
    if (this.checkAll && rec) {
      this.checkAll = false;
    }
    if (!checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec) {
          item.selected = false;
        }
      });
    } else if (checked) {
      this.vm.list.forEach((item) => {
          if (item.id === rec && !item.locked) {
            item.selected = true;
          }
        });
    }
    this.isAllChecked();

    // console.log('single select records' + JSON.stringify(this.vm.list));
  }

  selectAllRows(event: any) {
    if (!event) {
      this.vm.list.forEach((item) => (item.selected = false));
    } else if (event) {
      // this.vm.list.forEach((item) => (item.selected = true));
      this.vm.list.forEach((item) => {
        if (!item.locked) {
        item.selected = true;
        }
      });
    }
    // console.log('all select records: ' + JSON.stringify(this.vm.list));
  }

  isAllChecked(): boolean {
    const retVal = this.vm.list.every((x) => x.selected === true);
    this.checkAll = retVal;
    return retVal;
  }

  confirmDeleteSelected() {
    if (this.vm.list.filter(x => x.selected).length === 0) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }
    const messages: string[] = [
      $localize`:Are you sure you want to Delete? @@areyousureyouwanttodelete:Are you sure you want to Delete?`,
      'Selected Count: ' + this.vm.list.filter(x => x.selected).length,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.deleteSelected();
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }


  deleteSelected() {
    // just need to send the header id to the api
    const arrayOfItemsToDelete = [];
    this.vm.list.forEach((x) => {
      if (x.selected) {
        arrayOfItemsToDelete.push({header_id: x.id});
      }
    });

    if(!arrayOfItemsToDelete.length) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }
    // console.log('selected recs: ' + JSON.stringify(arrayOfItemsToDelete));

    // makes action button spinner turn while deleting
    this.isLoaded = false;
    // pass array as body in a delete to api entryController
    this.subscriptionGroup.push(this.tableDataService.deleteTableDataByArray('entry', arrayOfItemsToDelete).subscribe((item: any) => {
        if (item) {
         if (item.Message === 'Success') {
            this.getPagedApiData();
          }
         this.isLoaded = true;
        }
      }));
  }

  unshippedCheckBoxChange(e) {
    if (e.target.checked) {
      this.vm.unshippedOrders = true;
    } else {
      this.vm.unshippedOrders = false;
    }
    this.getPagedApiData();
  }

  backordersCheckBoxChange(e) {
    if (e.target.checked) {
      // console.log(e.target.checked);
      this.vm.backOrders = true;
    } else {
      // console.log(e.target.checked);
      this.vm.backOrders = false;
    }
    this.getPagedApiData();
  }

  search() {

    const unPaidInput = document.getElementById('unPaid') as HTMLInputElement;

    this.vm.unpaidInvoices = unPaidInput ? unPaidInput.checked : false;
    this.vm.searching = true;
    this.vm.buttonsDisabled = true;
    this.vm.batchId = '';
    this.vm.tempDescription = '';
    let tempBatchType = '';
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '4';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '2';
    } else if (this.vm.subLedger == 'quotes') {
      tempBatchType = '8';
    } else if (this.vm.subLedger == 'salesorders') {
      tempBatchType = '9';
    } else if (this.vm.subLedger == 'purchaseorders') {
      tempBatchType = '10';
    }

    // alert ('sub type: ' + this.vm.subLedger + ' batchtype: ' + tempBatchType);

    let paramString = '';
    paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
    paramString += '&search=' + ((this.vm.invoiceSearch) ? this.vm.invoiceSearch : '');
    paramString += '&fromDate=' + ((this.vm.invoiceFromDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
    paramString += '&toDate=' + ((this.vm.invoiceToDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
    paramString += '&lockedBatches=' + ((this.vm.lockedBatches) ? this.vm.lockedBatches : '');
    paramString += '&numberOfResults=' + ((this.vm.invoiceNumberOfResults) ? this.vm.invoiceNumberOfResults : '');
    paramString += '&includePosted=' + ((this.vm.includePosted) ? this.vm.includePosted : '');
    // paramString += 'recurring=' + this.getRecurring;

    this.subscriptionGroup.push(this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
    }));

    if (tempBatchType == '9' || tempBatchType == '10') {

      paramString = '';
      paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
      paramString += '&batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
      paramString += '&search=' + ((this.vm.invoiceSearch) ? this.vm.invoiceSearch : '');
      paramString += '&fromDate=' + ((this.vm.invoiceFromDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
      paramString += '&toDate=' + ((this.vm.invoiceToDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
      paramString += '&unshippedOrders=' + ((this.vm.unshippedOrders) ? this.vm.unshippedOrders : '');
      paramString += '&backOrders=' + ((this.vm.backOrders) ? this.vm.backOrders : '');
      paramString += '&numberOfResults=' + ((this.vm.invoiceNumberOfResults) ? this.vm.invoiceNumberOfResults : '');
      paramString += '&pageNumber=0';
      paramString += '&pageSize=' + this.pageSize;
      paramString += 'recurring=' + this.getRecurring;

      // console.log('orders get:' + paramString);

      this.subscriptionGroup.push(this.tableDataService.getApiListData('ListOrders', '?' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.invoiceCount = result.totalRecords;
        this.convertToNumbers();
        this.vm.searching = false;
        this.vm.buttonsDisabled = false;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.dataTableReady = true;
        this.convertToNumbers();
        this.vm.searching = false;
        this.vm.buttonsDisabled = false;
        this.isLoaded = true;
      }));

    } else {

      paramString = '';
      paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
      // paramString += 'batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
      paramString += '&search=' + ((this.vm.invoiceSearch) ? this.vm.invoiceSearch : '');
      paramString += '&fromDate=' + ((this.vm.invoiceFromDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
      paramString += '&toDate=' + ((this.vm.invoiceToDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
      paramString += '&unpaidInvoices=' + ((this.vm.unpaidInvoices) ? this.vm.unpaidInvoices : false);
      // paramString += '&numberOfResults=' + ((this.vm.invoiceNumberOfResults) ? this.vm.invoiceNumberOfResults : '');
      paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;
      paramString += '&recurring=' + this.getRecurring;

      this.subscriptionGroup.push(this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.invoiceCount = result.totalRecords;

        // ngb paginate
        this.totalRecords = result.totalRecords;
        this.paginate(false);

        // datatable stuff
        this.dtTrigger.next('');
        this.dataTableReady = true;
        this.convertToNumbers();
        this.vm.searching = false;
        this.vm.buttonsDisabled = false;
        this.isLoaded = true;
        // console.log('invoices :' + JSON.stringify(this.vm.list));
      }));

    }
    this.isLoaded = true;
  }

  queryDelinquentReceivables() {
    let paramString;
    if (this.vm.daysLate) {
      paramString = 'reportName=dashboard-delinquent-receivables';
      paramString += '&daysLate=' + ((this.vm.daysLate) ? this.vm.daysLate : '');
      paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.subscriptionGroup.push(this.tableDataService.getTableData('entry', '?' + paramString).subscribe((result: any[]) => {
        this.vm.delinquentReceivables = result;
      }));

    } else {
      paramString = 'reportName=dashboard-delinquent-receivables';
      this.subscriptionGroup.push(this.tableDataService.getTableData('entry', '?' + paramString).subscribe((result: any[]) => {
        this.vm.delinquentReceivables = result;
      }));
    }
  }

  getBatchItems(batchId) {
    this.vm.tempDescription = '';
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '4';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '2';
    } else if (this.vm.subLedger == 'quotes') {
      tempBatchType = '8';
    } else if (this.vm.subLedger == 'salesorders') {
      tempBatchType = '9';
    } else if (this.vm.subLedger == 'purchaseorders') {
      tempBatchType = '10';
    }
    this.vm.batchId = batchId;
    this.vm.searching = true;
    const obj: any = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true);
    if (obj.length == 0) {
      obj[0] = { description: '', locked: '' };
    }
    this.vm.tempDescription = (obj) ? obj[0].description : '';
    this.vm.tempBatchLocked = (obj) ? obj[0].locked : false;
    let paramString = '';
    paramString += 'batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
    paramString += '&batchType=' + ((tempBatchType) ? tempBatchType : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.subscriptionGroup.push(this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.invoiceCount = result.totalRecords;
      this.convertToNumbers();
      this.vm.searching = false;

      this.totalRecords = result.totalRecords;
      this.paginate(false);
    }));

  }

  getBatches() {
    this.vm.searching = true;
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '4';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '2';
    } else if (this.vm.subLedger == 'quotes') {
      tempBatchType = '8';
    } else if (this.vm.subLedger == 'salesorders') {
      tempBatchType = '9';
    } else if (this.vm.subLedger == 'purchaseorders') {
      tempBatchType = '10';
    }

    let paramString = '';
    paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
    paramString += '&lockedBatches=' + ((this.vm.lockedBatches) ? this.vm.lockedBatches : '');
    // console.log('getBatches params:' + paramString);

    this.subscriptionGroup.push(this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      if (this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true).length == 1) {
        this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
        this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
      }
      this.vm.searching = false;
    }));
  }

  newBatch() {
    let newBatch;
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableInvoices) {
      if (this.vm.subLedger == 'ar') {
        newBatch = { type_id: 2 };
      } else if (this.vm.subLedger == 'ap') {
        newBatch = { type_id: 4 };
      } else if (this.vm.subLedger == 'quotes') {
        newBatch = { type_id: 8 };
      } else if (this.vm.subLedger == 'salesorders') {
        newBatch = { type_id: 9 };
      } else if (this.vm.subLedger == 'purchaseorders') {
        newBatch = { type_id: 10 };
      }

      this.subscriptionGroup.push(this.tableDataService.post('t_batch', newBatch).subscribe((item: any) => {
        this.getBatches();
        this.vm.batchId = item.id;
        this.vm.tempDescription = 'Batch';
        // this.notification.success($localize`:Batch Added @@batchAdded:Batch Added`);
      }, (error: { error: { Message: string }, statusText: string }) => {
        this.vm.buttonsDisabled = false;
        let ErrorMsg: string;
        if (typeof error.error.Message !== 'undefined') {
          ErrorMsg = error.error.Message;
        } else {
          ErrorMsg = error.statusText;
        }
        // this.notification.error(ErrorMsg);
        console.log(ErrorMsg);
      }));
    }
  }

  updateBatch() {
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableInvoices) {
      this.vm.buttonsDisabled = true;
      const changes = { description: this.vm.tempDescription };

      this.tableDataService.patch('t_batch', this.vm.batchId, changes)
        .then((item: any) => {
          if (item) {
            // this.notification.success(this.vm.tempDescription);
          }
          this.getBatches();
        })
        .catch((msg: string) => {
          this.vm.buttonsDisabled = false;
          console.log(msg);
        });
    }
  }

  postBatch(batchId) {
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableInvoices) {
      this.vm.buttonsDisabled = true;
      const changes = { locked: 'true' };

      this.tableDataService.patch('t_batch', batchId, changes)
        .then((item: any) => {
          if (item) {
          }
          this.getBatches();
        })
        .catch((msg: string) => {
          this.vm.buttonsDisabled = false;
          console.log(msg);
        });
    }
  }

  generateShipment(id) {
    // if (this.vm.subLedger == 'salesorders') {
    //   $state.go('app.admin.transactions.shipment.form', {shipmentId: 'newSS', orderId: id});
    // } else {
    //   $state.go('app.admin.transactions.shipment.form', {shipmentId: 'newPS', orderId: id});
    // }
  }

  generateOrder(id) {
    this.router.navigate(['/transactions/invoice/form'], { queryParams: { invoiceId: 'newSalesOrder', quoteId: id } }).then();
  }

  completeOrder(id) {
    if (this.vm.permissions.Admin || this.vm.permissions.EditSalesOrders || this.vm.permissions.EditPurchaseOrders) {
      this.vm.buttonsDisabled = true;
      const changes = { completed: 'true' };

      this.tableDataService.patch('t_header', id, changes)
        .then(() => {
          this.search();
        })
        .catch((msg: string) => {
          this.vm.buttonsDisabled = false;
          console.log(msg);
        });
    }
  }

  getShipments() {
    let tempBatchType = '12';
    if (this.vm.subLedger === 'ar') {
      tempBatchType = '11';
    }
    let paramString = '';
    paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
    paramString += '&search=&fromDate=&toDate=&unshipped=&uninvoiced=true';
    paramString += '&pageNumber=0';
    paramString += '&pageSize=999999';
    this.subscriptionGroup.push(this.tableDataService.getTableData('ListShipments', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.shipments = result.Data;
    }));
  }

  getWorkOrders() {
    // console.log('yep');
    let paramString = '';
    paramString += 'incompleteWorkOrders=false&numberOfResults=' + this.numberOfResults + '&assignedToMe=false'
      + '&status=Completed&search=&fromDate=&toDate=&notInvoiced=true';
    paramString += '&pageNumber=0';
    paramString += '&pageSize=999999';
    this.subscriptionGroup.push(this.tableDataService.getTableData('WorkOrder', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.workorders = result.Data;
    }));
  }

  getQuotes() {
    const tempBatchType = '8';

    let paramString = '';
    paramString += 'batchType=' + ((tempBatchType) ? tempBatchType : '');
    paramString += '&search=&fromDate=&toDate=&unshipped=&uninvoiced=true&numberOfResults=' + this.numberOfResults;
    paramString += '&pageNumber=0';
    paramString += '&pageSize=999999';
    this.subscriptionGroup.push(this.tableDataService.getTableData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.quotes = result.Data;
    }));
  }

  generateInvoice(id) {
    if (this.vm.subLedger === 'ar') {
      this.router.navigate(['/transactions/invoice/form/', { invoiceId: 'newAR', shipmentId: id }]).then();
    } else {
      this.router.navigate(['/transactions/invoice/form/', { invoiceId: 'newAP', shipmentId: id }]).then();
    }
  }

  generateWorkOrderInvoice(id) {
    this.router.navigate(['/transactions/invoice/form/', { invoiceId: 'newAR', workorderId: id }]).then();
  }

  generateQuoteInvoice(id) {
    this.router.navigate(['/transactions/invoice/form/', { invoiceId: 'newAR', quoteId: id }]).then();
  }

  hideBatches() {
    this.vm.batchesHidden = !this.vm.batchesHidden;
    // this.vm.Preferences.ShowBatches = !this.vm.batchesHidden;
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  onNavigate(id: any, print = false) {
    if (print) {
     this.router.navigate(['/transactions/invoice/form'], { queryParams: { documentId: id, action: 'print' } }).then();
    } else {
      this.router.navigate(['/transactions/invoice/form'], { queryParams: { documentId: id } }).then();
    }
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.invoiceSearch = value.searchTerm;
    this.vm.invoiceFromDate = value.fromDate;
    this.vm.invoiceToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.invoiceSearch = value.searchTerm;
    this.vm.invoiceFromDate = value.fromDate;
    this.vm.invoiceToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

  filter(filter) {
    this.vm.invoiceFilter = Object.assign(this.vm.invoiceFilter, filter);
    // this.localStorage.StorageSet('assembleFilter', JSON.stringify(this.vm.assembleFilter));
  }
}
