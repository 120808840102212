import {Component, ViewChild, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {Router} from '@angular/router';
import {StorageService} from '../../../../Services/storage.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AppConfigService} from '../../../../Services/app-config.service';
import {NotificationService} from '../../../../Services/notification.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {DateFilterService} from '../../../../Services/dateFilter.service';
import { Subject } from 'rxjs';
import { DataTableDirective} from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../../Services/ngbpagination-config.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-account-margin-list',
  templateUrl: './account-margin-list.component.html',
  styleUrls: ['./account-margin-list.component.css']
})
export class AccountMarginListComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private table: TableDataService, private toastr: NotificationService,
              private router: Router,
              private storage: StorageService,
              private appConfig: AppConfigService,
              private dateFilter: DateFilterService,
              private ngbConfig: NgbPaginationConfig,
              private pagerStatus: PagerStateService,
              private dtConfig: DatatableConfigService,
              private pagerConfig: NgbpaginationConfigService,
              private titleService: Title) {
                this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  accountMarginCount = 0;
  isLoaded = false;

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  vm: any = [];

  ngOnInit(): void {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.getData();

  }
  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Account Margin List @@accountMarginList:Account Margin List`);
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([1, 'desc']);
  }

  async getPagerStatus() {
    this.pagerStatus.getPagerState(this.router.url).then(res => {
      if (res) {
        const pagerStuff = res;
        if (pagerStuff.totalRecords !== 0) {
          this.page = pagerStuff.lastPage;
          this.pageSize = pagerStuff.lastPageSize;
          this.startIndex = pagerStuff.startIndex;
          this.endIndex = pagerStuff.endIndex;
        }
        // console.log('pager stuff returned: ', JSON.stringify(res));
      }
    }).catch(err => {
      console.log('pager error: ', err);
    }).then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {

    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = ((this.page - 1) * this.pageSize) + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number((this.page) * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize){
      this.endIndex = this.totalRecords;
    }

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  getData() {

    let paramString = '?search=' + ((this.vm.searchTerm) ? this.vm.searchTerm : '');
    paramString += '&fromDate=' + ((this.vm.fromDate) ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
    paramString += '&toDate=' + ((this.vm.toDate) ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
    paramString += '&pageNumber=' + ((this.page !== 0 ) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.table.getApiListData('account_margin' + paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dtTrigger.next('');
        this.accountMarginCount = result.totalRecords;
        this.isLoaded = true;
        this.dataTableReady = true;
      },
      (error: HttpErrorResponse) => {
        // this.toastr.error($localize`:Error loading Account Data @@errorLoadingAccountData:Error loading Account Data`);
        console.log(error);
      },
      () => {
      }
    );
  }

  onNavigate(aID: string) {
    this.router.navigate(['admin/account-margin-form', {id: aID}]);
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    let paramString = '?search=' + ((this.vm.searchTerm) ? this.vm.searchTerm : '');
    paramString += '&fromDate=' + ((this.vm.fromDate) ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
    paramString += '&toDate=' + ((this.vm.toDate) ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
    paramString += '&pageNumber=' + ((this.page !== 0 ) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.table.getApiListData('account_margin' + paramString).subscribe(
      (result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.accountMarginCount = result.totalRecords;
        this.isLoaded = true;
        this.dataTableReady = true;

      },
      (error: HttpErrorResponse) => {
        // this.toastr.error($localize`:Error loading Account Data @@errorloadingAccountData:Error loading Account Data`);
        console.log(error);
      },
      () => {
      }
    );
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

}
