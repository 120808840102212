import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TaxRateModel } from '../../../../DataModels/tax-rate-model';
import { DatePipe } from '@angular/common';
import { ResponseModel } from '../../../../DataModels/response-model';
import { NotificationService } from '../../../../Services/notification.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { Observable } from 'rxjs';
import { BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-tax-rate-form',
  templateUrl: './tax-rate-form.component.html',
  styleUrls: ['./tax-rate-form.component.css'],
})
export class TaxRateFormComponent implements OnInit {
  taxRateID;
  taxRate: TaxRateModel;
  taxRateForm: UntypedFormGroup;

  taxRateCount = 0;
  taxCount = 0;
  taxList: Array<object>;
  paid = false;
  showDelete = false;
  submitted = false;
  isLoaded = false;
  isTaxLoaded = false;
  taxRates$: Observable<TaxRateModel[]>;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private bsDatepickerConfig: BsDatepickerConfig,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.taxRateID = this.route.snapshot.params.id;
    this.bsDatepickerConfig.dateInputFormat = 'YYYY-MM-DD';
  }

  createForm() {
    const pipe = new DatePipe('en-US');
    this.taxRateForm = this.formBuilder.group({
      tax_id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      rate: new UntypedFormControl(''),
      // from_date: null,
      // to_date: null,
      from_date: new UntypedFormControl(null),
      to_date: new UntypedFormControl(''),
      active: new UntypedFormControl('true'),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Tax Rate@@taxRate:Tax Rate`);
    this.loadTaxes();
    if (this.taxRateID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('tax_rate', this.taxRateID).subscribe((result) => {
        this.taxRate = result;
        this.taxRates$ = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Tax Rates @@errorloadingTaxRates:Error loading Tax Rates`);
        console.log(error);
      },
      () => {
        this.taxRateForm.patchValue(this.taxRate);
      }
    );
  }

  private loadTaxes() {
    this.table.getApiListData('tax', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.taxList = result.Data;
        this.taxCount = this.taxList.length;
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Tax Rates @@errorloadingTaxRates:Error loading Tax Rates`);
        console.log(error);
      },
      () => {}
    );
  }

  saveClose() {
    if (this.onSave()) {
      this.onClose();
    }
  }

  saveNew() {
    if (this.onSave()) {
      this.taxRateForm.reset();
    }
  }

  resetForm() {
    this.taxRateForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/tax-rate-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete tax rate? @@areYouSureYouWantToDeleteTaxrate:Are you sure you want to delete tax rate?`,
      this.taxRate.tax_id,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('tax_rate', this.taxRateID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled@@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onSave() {
    this.submitted = true;

    if (this.taxRateForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }

    this.taxRate = Object.assign({}, this.taxRateForm.value);
    // console.log('rate: ' + JSON.stringify(this.taxRate));

    if (this.taxRateID) {
      this.table.patch('tax_rate', this.taxRateID, this.taxRate).then();
      // this.notification.success($localize`:Saved @@saved:Saved`);
      return true;
    } else {
      this.table.postWithPromise('tax_rate', this.taxRate).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.taxRateID = apiReturn.id;
      });
      // this.notification.success($localize`:Saved @@saved:Saved`);
    }
    return true;
  }
}
