import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-modal-new-contact',
  templateUrl: './modal-new-contact.component.html',
  styleUrls: ['./modal-new-contact.component.css']
})
export class ModalNewContactComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  @Output() saveNewContactEmit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  saveNewContact() {
    this.saveNewContactEmit.emit();
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }
}


