import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { StorageService } from '../../../../Services/storage.service';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService } from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-bom-form',
  templateUrl: './bom-form.component.html',
  styleUrls: ['./bom-form.component.css'],
})
export class BomFormComponent implements OnInit, AfterViewInit {
  vm: any = [];

  itemId: string;
  submitted = false;
  itemList: Array<object>;
  permissionsAdmin = true;
  showDelete = false;
  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: false,
  };



  constructor(
    private table: TableDataService,
    private toast: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private localStorage: StorageService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.itemId = this.route.snapshot.params.id;
    this.vm.itemFilter = {};
    this.vm.item = {};
    this.vm.startInventoryItem = 0;
    this.vm.inventoryItemFilter = [];
    this.vm.inventoryItem = [];
    this.vm.uom = [];
    this.vm.account = [];
    this.vm.accountFilter = [];
    this.vm.item.source = [];
    this.vm.item.costs = [];
    this.vm.BillOfMaterials = [];
    this.vm.showBOMItems = '';
  }

  ngOnInit() {
    this.vm.title = $localize`:Bill of Materials @@billofMaterials:Bill of Materials`;
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    if (!this.vm.permissions.EditAssembly && !this.vm.permissions.ViewAssembly && !this.vm.permissions.Admin) {
      document.location.href = '/';
    }

    this.vm.itemFilter.name = null;
    this.vm.startAccount = 0;
    this.vm.inventoryItemFilter.search_string = null;
    this.vm.accountFilter.search_string = null;
    this.loadInventoryItems();
    this.loadUom();
    this.loadBillOfMaterials();
    this.addRows(2);
    this.addCostRows(2);
  }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  private loadItem() {
    this.table.getTableData('BillOfMaterials', this.itemId).subscribe(
      (result) => {
        this.vm.item = result;
        this.vm.item.itemInput = this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.item_id }, true)[0].code;
        this.vm.item.item_description = this.FilterObject(
          this.vm.inventoryItem,
          { id: this.vm.item.item_id },
          true
        )[0].name;
        for (const [x, value] of Object.entries(this.vm.item.source)) {
          this.vm.item.source[x].quantity = Number(this.vm.item.source[x].quantity);
          if (this.vm.item.source[x].item_id) {
            if (this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.source[x].item_id }, true).length > 0) {
              this.vm.item.source[x].itemInput = this.FilterObject(
                this.vm.inventoryItem,
                { id: this.vm.item.source[x].item_id },
                true
              )[0].code;
              this.vm.item.source[x].description = this.FilterObject(
                this.vm.inventoryItem,
                { id: this.vm.item.source[x].item_id },
                true
              )[0].name;
            }
          }
        }
        for (const [x, value] of Object.entries(this.vm.item.costs)) {
          this.vm.item.costs[x].quantity = Number(this.vm.item.costs[x].quantity);
          if (this.FilterObject(this.vm.account, { id: this.vm.item.costs[x].account_id }, true).length > 0) {
            this.vm.item.costs[x].accountInput =
              this.FilterObject(this.vm.account, { id: this.vm.item.costs[x].account_id }, true)[0].number +
              ' ' +
              this.FilterObject(this.vm.account, { id: this.vm.item.costs[x].account_id }, true)[0].name;
          }
        }
      },
      (error: HttpErrorResponse) => {
        // this.toast.error($localize`:Error loading Bill of Materials @@errorloadingBillofMaterials:Error loading Bill of Materials`);
        console.log(error);
      },
      () => {}
    );
  }

  showme(msg: string) {
    console.log(msg);
  }

  setItemFilter(showBOM: boolean) {
    if (showBOM) {
      this.vm.showBOMItems = 'bom';
    } else {
      this.vm.showBOMItems = null;
    }
    // vm.itemFilter ='{item_type_id: 'bom'}:false'
  }
  private loadInventoryItems() {
    this.table.getTableData('item?state=active&pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.vm.inventoryItem = result.Data;
        this.loadAccount();
        this.buildInventoryItemName();
        this.buildInventoryItemNameBOM();
      },
      (error: HttpErrorResponse) => {
        // this.toast.error($localize`:Error loading Item @@errorloadingItem:Error loading Item`);
        console.log(error);
      },
      () => {}
    );
  }

  private loadUom() {
    this.table.getTableData('uom?state=active&pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.vm.uom = result.Data;
      },
      (error: HttpErrorResponse) => {
        // this.toast.error($localize`:Error loading UOM @@errorloadingUOM:Error loading UOM`);
        console.log(error);
      },
      () => {}
    );
  }

  private loadBillOfMaterials() {
    this.table.getTableData('BillOfMaterials?state=active').subscribe(
      (result) => {
        this.vm.BillOfMaterials = result.Data;
      },
      (error: HttpErrorResponse) => {
        // this.toast.error($localize`:Error loading UOM @@errorloadingUOM:Error loading UOM`);
        console.log(error);
      },
      () => {}
    );
  }

  private loadAccount() {
    this.table.getTableData('account?state=active&pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.vm.account = result.Data;
        if (this.itemId) {
          this.loadItem();
        }
        this.vm.accountName = [];
        for (const [y, value] of Object.entries(this.vm.account)) {
          const tempName = this.vm.account[y].number + ' ' + this.vm.account[y].name;
          this.vm.accountName.push({
            name: tempName,
          });
        }
      },
      (error: HttpErrorResponse) => {
        // this.toast.error($localize`:Error loading Accounts @@errorloadingAccounts:Error loading Accounts`);
        console.log(error);
      },
      () => {}
    );
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  selectItemDropdownNew(id) {
    for (const [y, value] of Object.entries(this.vm.inventoryItem)) {
      if (
        this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name == this.vm.item.itemInput ||
        this.vm.inventoryItem[y].code == this.vm.item.itemInput
      ) {
        this.vm.item.itemInput = this.vm.inventoryItem[y].code;
        this.vm.item.item_id = this.vm.inventoryItem[y].id;
        this.vm.item.item_description = this.vm.inventoryItem[y].name;
        this.vm.item.quantity = 1;
        break;
      }
    }
  }

  selectItemDropdown(id) {
    let foundItem = false;
    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(this.vm.item.source)) {
      if (maxSortOrder < this.vm.item.source[x].sort_order) {
        maxSortOrder = this.vm.item.source[x].sort_order;
      }
    }
    for (const [x, value] of Object.entries(this.vm.item.source)) {
      this.vm.item.source[x].disableAccount = false;
      if (this.vm.item.source[x].id == id && this.vm.item.source[x].itemInput) {
        this.vm.item.source[x].item_id = '';
        for (const [y, val] of Object.entries(this.vm.inventoryItem)) {
          if (
            this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name == this.vm.item.source[x].itemInput ||
            this.vm.inventoryItem[y].code == this.vm.item.source[x].itemInput
          ) {
            this.vm.item.source[x].bom_id = this.vm.item.id;
            this.vm.item.source[x].itemInput = this.vm.inventoryItem[y].code;
            this.vm.item.source[x].item_id = this.vm.inventoryItem[y].id;
            this.vm.item.source[x].description = this.vm.inventoryItem[y].name;
            this.vm.item.source[x].quantity = 1;
            // vm.item.source[x].sort_order = maxSortOrder;
            // maxSortOrder++;
            foundItem = true;
            break;
          }
        }
        if (!foundItem) {
          this.vm.item.source[x].itemInput = '';
          // this.toast.error('Invalid Item, please try again.');
        }
        break;
      }
    }
  }

  selectItemModal(itemId) {
    let id = this.vm.tempItemDetailId;
    if (id.toString().substring(0, 3) == 'new' && typeof id === 'string') {
      id = id.substring(3, 100);
      for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
        if (this.vm.inventoryItem[x].id === itemId) {
          this.vm.inventoryItemInput = this.vm.inventoryItem[x].code + ' ' + this.vm.inventoryItem[x].name;
          this.vm.item.item_id = this.vm.inventoryItem[x].id;
          this.vm.item.itemInput = this.vm.inventoryItem[x].code;
          this.vm.item.item_description = this.vm.inventoryItem[x].name;
          this.vm.item.quantity = 1;
          break;
        }
      }
    } else {
      for (const [x, value] of Object.entries(this.vm.item.source)) {
        if (this.vm.item.source[x].id == id) {
          this.vm.item.source[x].item_id = '';
          for (const [y, val] of Object.entries(this.vm.inventoryItem)) {
            if (this.vm.inventoryItem[y].id == itemId) {
              this.vm.item.source[x].bom_id = this.vm.item.id;
              this.vm.item.source[x].itemInput = this.vm.inventoryItem[y].code;
              this.vm.item.source[x].description = this.vm.inventoryItem[y].name;
              this.vm.item.source[x].item_id = this.vm.inventoryItem[y].id;
              this.vm.item.source[x].quantity = 1;
              break;
            }
          }
          break;
        }
      }
    }
  }

  testItem() {
    const itemIdStart = this.vm.newItemId;
    if (!this.vm.inventoryItemInput || this.vm.inventoryItemInput == '') {
      this.vm.newItemId = '';
    } else {
      if (this.FilterObject(this.vm.inventoryItem, { search_string: this.vm.inventoryItemInput }, true).length > 0) {
        this.vm.newItemId = this.FilterObject(
          this.vm.inventoryItem,
          { search_string: this.vm.inventoryItemInput },
          true
        )[0].id;
      } else {
        this.vm.newItemId = '';
        this.vm.inventoryItemInput = '';
      }
    }
  }

  updateAccount() {
    this.vm.startAccount = 0;
    this.loadAccount();
  }

  selectAccountModal(accountId) {
    // var id = document.getElementById('accountSelectDetailId').value;
    const id = this.vm.tempAccountDetailId;
    // $('#accountSelect').modal('hide');
    for (const [x, value] of Object.entries(this.vm.item.costs)) {
      if (this.vm.item.costs[x].id == id) {
        for (const [y, val] of Object.entries(this.vm.account)) {
          if (this.vm.account[y].id == accountId) {
            this.vm.item.costs[x].bom_id = this.vm.item.id;
            this.vm.item.costs[x].account_id = this.vm.account[y].id;
            this.vm.item.costs[x].accountInput = this.vm.account[y].number + ' ' + this.vm.account[y].name;
            break;
          }
        }
        break;
      }
    }
  }

  onClose() {
    this.router.navigate(['admin/bom-list']).then();
  }

  accountSelected(id) {
    for (const [x, value] of Object.entries(this.vm.item.costs)) {
      if (this.vm.item.costs[x].id == id) {
        this.vm.item.costs[x].account_id = '';
        for (const [y, val] of Object.entries(this.vm.account)) {
          if (this.vm.account[y].number + ' ' + this.vm.account[y].name == this.vm.item.costs[x].accountInput) {
            this.vm.item.costs[x].bom_id = this.vm.item.id;
            this.vm.item.costs[x].account_id = this.vm.account[y].id;
            break;
          }
        }
        break;
      }
    }
  }

  addRows(qty) {
    if (qty == '') {
      qty = 1;
    }
    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(this.vm.item.source)) {
      if (maxSortOrder < this.vm.item.source[x].sort_order) {
        maxSortOrder = this.vm.item.source[x].sort_order;
      }
    }
    for (let x = 0; x < qty; x++) {
      maxSortOrder++;
      this.vm.item.source.push({
        id: maxSortOrder,
        sort_order: maxSortOrder,
        quantity: 0,
      });
    }
  }

  addCostRows(qty) {
    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(this.vm.item.costs)) {
      if (maxSortOrder < this.vm.item.costs[x].sort_order) {
        maxSortOrder = this.vm.item.costs[x].sort_order;
      }
    }
    for (let x = 0; x < qty; x++) {
      maxSortOrder++;
      this.vm.item.costs.push({
        id: maxSortOrder,
        sort_order: maxSortOrder,
      });
    }
  }

  deleteLine(id) {
    for (let x = 0; x < this.vm.item.source.length; x++) {
      if (this.vm.item.source[x].id == id) {
        this.vm.item.source.splice(x, 1);
        break;
      }
    }
  }

  deleteCostLine(id) {
    for (let x = 0; x < this.vm.item.costs.length; x++) {
      if (this.vm.item.costs[x].id == id) {
        this.vm.item.costs.splice(x, 1);
        break;
      }
    }
  }

  buildInventoryItemName() {
    this.vm.inventoryItemName = [];
    for (const [y, value] of Object.entries(this.vm.inventoryItem)) {
      const tempName = this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name;
      this.vm.inventoryItemName.push({
        name: tempName,
      });
    }
  }

  buildInventoryItemNameBOM() {
    this.vm.inventoryItemNameBOM = [];
    for (const [y, value] of Object.entries(this.vm.inventoryItem)) {
      if (this.vm.inventoryItem[y].item_type_id === 'bom') {
        const tempName = this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name;
        this.vm.inventoryItemNameBOM.push({
          name: tempName,
        });
      }
    }
  }

  updateInventoryItem() {
    this.vm.startInventoryItem = 0;
    this.loadInventoryItems();
    this.buildInventoryItemName();
    this.buildInventoryItemNameBOM();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  checkDescritpion() {
    if (!this.vm.item.itemInput) {
      console.log('fired');
      this.vm.item.item_description = '';
    }
  }

  checkComponenetsDescription(index: number) {
    if (!this.vm.item.source.itemInput) {
      this.vm.item.source[index].description = '';
    }
  }

  onSave() {
    const errorFound = false;
    this.submitted = true;
    let errorMessage = '';

    // check form here - pseudo reactive since not reactive yet.
    if (this.isFieldEmpty(this.vm.item.itemInput) || this.isFieldEmpty(this.vm.item.quantity)) {
      errorMessage = $localize`:Form Error@@formError:FormError` + '</br>';
    }
    for (const [x, value] of Object.entries(this.vm.BillOfMaterials)) {
      if (
        this.vm.BillOfMaterials[x].item_id == this.vm.item.item_id &&
        this.vm.BillOfMaterials[x].id != this.vm.item.id
      ) {
        errorMessage =
          $localize`:Duplicate Bill of Material @@duplicateBillofMaterial:Duplicate Bill of Material` + '</br>';
        break;
      }
    }

    let sourceFound = false;
    for (const [x, value] of Object.entries(this.vm.item.source)) {
      if (this.vm.item.source[x].item_id) {
        sourceFound = true;
        if (!this.vm.item.source[x].quantity) {
          errorMessage += $localize`:Quantity is required.  @@quantityisrequired:Quantity is required. ` + '</br>';
        }
      }
    }
    if (!sourceFound) {
      errorMessage +=
        $localize`:At least one Source Item is required.  @@atleastoneSourceItemisrequired:At least one Source Item is required. ` +
        '</br>';
    }

    if (!this.vm.permissions.Admin && !this.vm.permissions.EditBillofMaterials) {
      errorMessage +=
        $localize`:You do not have access to save.  @@youdonothaveaccesstosave:You do not have access to save. ` +
        '</br>';
    }

    if (!errorMessage) {
      this.vm.buttonsDisabled = true;
      if (this.vm.item.id === undefined) {
        // new
        this.table.post('BillOfMaterials', this.vm.item).subscribe(
          (item: any) => {
            // this.toast.success($localize`:Saved @@saved:Saved`);
            this.vm.buttonsDisabled = false;
            this.vm.item.id = item.id;
            this.router.navigate(['inventory/bill-of-materials/bom-form', { id: item.id }]).then();
          },
          (error: { error: { Message: string }; statusText: string }) => {
            this.vm.buttonsDisabled = false;
            let ErrorMsg: string;
            if (typeof error.error.Message != 'undefined') {
              ErrorMsg = error.error.Message;
            } else {
              ErrorMsg = error.statusText;
            }
            console.log(ErrorMsg);
          }
        );
      } else {
        // edit

        // const params = {
        //   id: this.vm.item.id,
        //   tablename: 'BillOfMaterials',
        // };

        // diff
        const changes: any = {};

        changes.source = this.vm.item.source;
        changes.costs = this.vm.item.costs;

        for (const key in this.vm.item) {
          if (this.vm.item.hasOwnProperty(key)) {
            const value = this.vm.item[key];
            if (value != key && typeof value != 'object') {
              changes[key] = value;
            }
          }
        }
        this.table
          .patch('BillOfMaterials', this.vm.item.id, changes)
          .then((item: any) => {
            this.vm.buttonsDisabled = false;
            // this.toast.success($localize`:Updated @@updated:Updated`);
            if (item.description == 'Deleted') {
              this.router.navigate(['bom-list']).then();
              // this.toast.success($localize`:Deleted @@deleted:Deleted`);
            }
          })
          .catch((msg: string) => {
            this.vm.buttonsDisabled = false;
            console.log(msg);
          });
      }
    } else {
      // report error
      console.log('Error: ' + errorMessage);
      this.toast.showWarningToast(errorMessage);
      this.vm.buttonsDisabled = false;
    }
  }

  deleteBOM() {
    this.vm.buttonsDisabled = true;
    this.table.deleteTableDataById('BillOfMaterials', this.vm.item.id).subscribe(
      (item: any) => {
        // this.toast.success($localize`:Deleted @@deleted:Deleted`);
        this.vm.buttonsDisabled = false;
        this.router.navigate(['admin/bom-list']).then();
      },
      (error: { description: any[] }) => {
        this.vm.buttonsDisabled = false;
        console.log(error.description);
      }
    );
  }

  // Buttons

  deleteItem() {
    if (this.itemId) {
      const brClass = this;
      // let retMsg = '';
      const messages: string[] = [
        $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
        '',
      ];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.deleteBOM();
        },
        () => {
          brClass.toast.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
        }
      );
    }
  }
}
