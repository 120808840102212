import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { Title } from '@angular/platform-browser';
import { AppConfigService } from '../../../Services/app-config.service';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import { DatePipe, ViewportScroller } from '@angular/common';
import { ResponseModel } from '../../../DataModels/response-model';
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';
import {BsToastService} from '../../../Services/bs-toast-service';
import {ToWords} from '../../../Services/ToWordsService/ToWords';
import { Mutex, MutexInterface } from 'async-mutex';
import {HttpErrorResponse} from '@angular/common/http';
import {take, tap} from 'rxjs/operators';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css'],
})
export class PaymentListComponent implements OnInit, AfterViewInit, OnDestroy {
  private locks: Map<string, MutexInterface>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private datePipe: DatePipe,
    private vps: ViewportScroller,
    private ngbConfig: NgbPaginationConfig,
    private dtConfig: DatatableConfigService,
    private pagerStatus: PagerStateService,
    private pagerConfig: NgbpaginationConfigService,
    public confirmDialogService: ConfirmDialogService
  ) {
    this.locks = new Map();

    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.route.queryParams.subscribe((params) => {
      this.vm.type = params.type;
      this.vm.subLedger = params.type; // subledger same as type;
      this.ngOnInit();
    });
  }

  vm: any = {};
  buttonsDisabled: any;
  paymentCount = 0;
  isLoaded = false;

  checkAll = false;
  selectedRecords = [];

  // datatables stuff
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: Promise<DataTables.Api>;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;
  batchType = '';
  mutexVar = '123456';

  ngOnInit() {
    // this.router.navigate([this.router.url], { relativeTo: this.route, skipLocationChange: false }).then();
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();

    // this.route.queryParams.subscribe((params) => {
      this.vm.list = [];
      // this.vm.type = params.type;
      // this.vm.subLedger = params.type; // subledger same as type;
      this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
      this.vm.batchesHidden = !this.vm.Preferences.ShowBatches;
      this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

      // batch type for batch new post modal
      if (this.vm.subLedger == 'ap') {
        this.batchType = '5';
      } else {
        this.batchType = '3';
      }

      if (
        !this.vm.permissions.EditReceivablePayments &&
        !this.vm.permissions.ViewReceivablePayments &&
        !this.vm.permissions.EditPayablePayments &&
        !this.vm.permissions.ViewPayablePayments &&
        !this.vm.permissions.Admin
      ) {
        document.location.href = '/';
      }

      this.getTableData('bank', '?state=active&pageNumber=0&pageSize=999999',  (value) => {
        this.vm.bank = value.Data;
      });

      this.getTableData('company', '?state=active&pageNumber=0&pageSize=999999', (value) => {
        this.vm.company = value.Data;
      });

      this.getTableData('entity', '?state=active&pageNumber=0&pageSize=999999', (value) => {
        this.vm.entity = value.Data;
      });

      this.getTableData('check_stock', '?state=active&pageNumber=0&pageSize=999999', (value) => {
        this.vm.checkStock = value.Data;
      });

      // console.log('type: ' + this.vm.type);
      if (this.vm.type === 'ar') {
        this.vm.reverse = 1;
      } else {
        this.vm.reverse = -1;
      }

      this.vm.owingSelected = this.vm.totalSelected = 0;
      this.vm.paymentToDateOpen = this.vm.paymentFromDateOpen = false;
      this.vm.unappliedOnly = false;
      this.vm.notPrintedOnly = false;

      this.vm.paymentFilter = {
        text: '',
        sort: 'audit_sequence',
        ascending: true,
      };

      this.vm.paymentSearch = '';
      this.vm.paymentToDate = '';
      this.vm.paymentFromDate = '';
      this.vm.unappliedOnly = '';
      this.vm.notPrintedOnly = '';
      this.vm.batchId = '';
      this.vm.searchHidden = false;
      this.init();
    // });
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Payments @@payments:Payments`);
    this.dtTrigger.next(null);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([7, 'desc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
          // console.log('pager stuff returned: ', JSON.stringify(res));
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      if (this.vm.unappliedOnly === true) {
        this.includeUnapplied(true);
      } else {
        this.getPagedApiData();
      }
    }
  }

  private getTableData(tableName, params, fun) {
      this.tableDataService.getApiListData(tableName, params).subscribe((result: any) => {
        fun(result);
      });
    // }
  }

  init() {
    if (this.vm.batchId) {
      this.getBatches();
    } else {
      this.search();
    }
  }

  // convertToNumbers() {
  //   // for (const obj of this.vm.list) {
  //   //   obj.detail_total = Number(obj.detail_total) * this.vm.reverse;
  //   //   obj.audit_sequence = Number(obj.audit_sequence);
  //   //   if (obj.payment_applied_total) {
  //   //     obj.payment_applied_total = Number(obj.payment_applied_total) * this.vm.reverse;
  //   //   } else {
  //   //     obj.payment_applied_total = 0;
  //   //   }
  //   // }
  // }

  selectAllRows(event: any) {
    if (!event) {
      this.vm.list.forEach((item) => (item.selected = false));
    } else if (event) {
      // this.vm.list.forEach((item) => (item.selected = true));
      this.vm.list.forEach((item) => {
        if (item.locked || item.printed) {
          item.selected = false;
        } else {
          item.selected = true;
        }
      });
    }
    // console.log('all select records: ' + JSON.stringify(this.vm.list));
  }

  selectRow(rec: any, checked: boolean) {
    // console.log('rec val:' + rec + ' - ' + checked);
    if (this.checkAll && rec) {
      this.checkAll = false;
    }
    if (!checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec) {
          item.selected = false;
        }
      });
    } else if (checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec && !item.locked) {
          item.selected = true;
        }
      });
    }
    this.isAllChecked();

    // console.log('single select records' + JSON.stringify(this.vm.list));
  }

  isAllChecked(): boolean {
    const retVal = this.vm.list.every((x) => x.selected === true);
    this.checkAll = retVal;
    return retVal;
  }

  unCheckAll() {
    this.vm.list.forEach((item) => {
      // if (item.selected == true ) {
        item.selected = false;
      // }
    });
    this.checkAll = false;
  }

  // confirmPrintSelected() {
  //   if (this.vm.list.filter((x) => x.selected).length === 0) {
  //     this.notification.showWarningToast('No records selected to print!');
  //     return;
  //   }
  //   let sum = 0;
  //   this.vm.list
  //     .filter((x) => x.selected)
  //     .forEach((x) => {
  //       if (x.payment_applied_total != 0) {
  //         sum += x.payment_applied_total;
  //       }
  //     });
  //
  //   const messages: string[] = [
  //     $localize`:Are you sure you want to Print? @@areyousureyouwanttoprint:Are you sure you want to Print?`,
  //     'Selected Count: ' + this.vm.list.filter((x) => x.selected).length,
  //     +' ' + 'Amount: ' + this.decimalPipe.transform(sum),
  //   ];
  //   this.confirmDialogService.confirmThis(
  //     messages,
  //     () => {
  //       this.printSelected();
  //     },
  //     () => {
  //       this.notification.showSuccessToast($localize`:Print Canceled @@printCanceled:Print Canceled`);
  //     }
  //   );
  // }

  // printSelected() {
  //   // todo do stuff here to print all the checks in the array
  // }

  search() {
    this.vm.searching = true;
    this.vm.list = [];
    this.dataTableReady = false;
    this.vm.batchId = '';
    this.vm.tempDescription = '';
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '5';
    } else {
      tempBatchType = '3';
    }

    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&batchId=' + (this.vm.batchId ? this.vm.batchId : '');
    paramString += '&search=' + (this.vm.paymentSearch ? this.vm.paymentSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.paymentFromDate ? this.dateFilter.GetDateFormat(this.vm.paymentFromDate) : '');
    paramString += '&toDate=' + (this.vm.paymentToDate ? this.dateFilter.GetDateFormat(this.vm.paymentToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');

    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;

      paramString = '';
      paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
      paramString += '&batchId=' + (this.vm.batchId ? this.vm.batchId : '');
      paramString += '&search=' + (this.vm.paymentSearch ? this.vm.paymentSearch : '');
      paramString +=
        '&fromDate=' + (this.vm.paymentFromDate ? this.dateFilter.GetDateFormat(this.vm.paymentFromDate) : '');
      paramString += '&toDate=' + (this.vm.paymentToDate ? this.dateFilter.GetDateFormat(this.vm.paymentToDate) : '');
      paramString += '&unappliedOnly=' + (this.vm.unappliedOnly ? 'true' : '');
      paramString += '&notPrintedOnly=' + (this.vm.notPrintedOnly ? 'true' : '');
      paramString += '&numberOfResults=' + (this.vm.paymentNumberOfResults ? this.vm.paymentNumberOfResults : '');
      paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.tableDataService.getApiListData('PaymentList', '?' + paramString).subscribe((result2: ResponseModel) => {

        this.vm.list = result2.Data;
        // console.log(JSON.stringify(this.vm.list));
        // this.convertToNumbers();

        // console.log('list: ' + JSON.stringify(this.vm.list));
        this.totalRecords = result2.totalRecords;
        this.paginate(false);

        this.paymentCount = result2.totalRecords;

        this.vm.searching = false;
        this.isLoaded = true;
        this.dataTableReady = true;
        // datatable stuff
        // this.resetDatatable();
        // Destroy the table first
        this.dtElement.dtInstance.then(dtInstance => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next(null);
        });
        // this.dtTrigger.next('');
      });
    });


  }

  resetDatatable() {
    this.dtElement.dtInstance.then(dtInstance => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next(null);
    });
  }

  getCellStyle(data) {
    let cellstyle = data.payment_applied_total + data.detail_total > 0 ? 'text-danger' : '';
    if (this.vm.type === 'ap' || this.vm.type === 'purchaseorders') {
      cellstyle = 'payables';
    } else {
      cellstyle = 'receivables';
    }
    if (data.payment_applied_total == 0 - data.detail_total) {
      cellstyle = 'text-muted';
    }
    return cellstyle;
  }

  filter(filter) {
    this.vm.paymentFilter = Object.assign(this.vm.paymentFilter, filter);
    this.localStorage.StorageSet('paymentFilter', this.vm.paymentFilter);
  }

  getBatchItems(batchId) {
    this.localStorage.StorageSet('batchId', batchId);

    this.vm.tempDescription = '';
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '5';
    } else {
      tempBatchType = '3';
    }
    this.vm.batchId = batchId;
    this.vm.searching = true;
    // this.vm.tempDescription = 'Batch';
    const obj: any = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true);
    if (obj.length == 0) {
      obj[0] = { description: '', locked: '' };
    }
    this.vm.tempDescription = obj ? obj[0].description : '';
    this.vm.tempBatchLocked = obj ? obj[0].locked : false;
    let paramString = '';
    paramString += 'batchId=' + (this.vm.batchId ? this.vm.batchId : '');
    paramString += '&batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&pageNumber=0';
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('PaymentList', '?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;

      // this.setList(result);
      // this.convertToNumbers();
      this.vm.searching = false;

      this.totalRecords = result.totalRecords;
      this.paginate(false);
    });
  }

  updateBatchesSelectedInModal(batchId: string) {
    this.getBatchItems(batchId);
    // this.paginate(false);
  }

  getBatches() {
    this.vm.searching = true;
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '5';
    } else {
      tempBatchType = '3';
    }

    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    // paramString += '&search=' + ((this.vm.paymentSearch) ? this.vm.paymentSearch : '');
    // paramString += '&fromDate=' + ((this.vm.paymentFromDate) ? this.dateFilter.GetDateFormat(this.vm.paymentFromDate) : '');
    // paramString += '&toDate=' + ((this.vm.paymentToDate) ? this.dateFilter.GetDateFormat(this.vm.paymentToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    // paramString += '&paymentNumberOfResults=' + ((this.vm.paymentNumberOfResults) ? this.vm.paymentNumberOfResults : '');
    // paramString += '&includePosted=' + ((this.vm.includePosted) ? this.vm.includePosted : '');

    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      if (this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true).length == 1) {
        this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
        this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
      }
      this.vm.searching = false;
      if (this.vm.batchId) {
        this.getBatchItems(this.vm.batchId);
      }
    });
  }

  newBatch() {
    let newBatch;
    if (this.vm.subLedger == 'ar') {
      newBatch = { type_id: 3 };
    } else {
      newBatch = { type_id: 5 };
    }

    this.tableDataService.post('t_batch', newBatch).subscribe(
      (item: any) => {
        this.getBatches();
        this.vm.batchId = item.id;
        this.vm.tempDescription = 'Batch';
        this.notification.showSuccessToast($localize`:Batch Added @@batchAdded:Batch Added`);
      },
      (error: { error: { Message: string }; statusText: string }) => {
        this.vm.buttonsDisabled = false;
        let ErrorMsg: string;
        if (typeof error.error.Message != 'undefined') {
          ErrorMsg = error.error.Message;
        } else {
          ErrorMsg = error.statusText;
        }
        console.log(ErrorMsg);
      }
    );
  }

  updateBatch() {
    this.vm.buttonsDisabled = true;
    const changes = { description: this.vm.tempDescription };

    this.tableDataService
      .patch('t_batch', this.vm.batchId, changes)
      .then((item: any) => {
        // this.notification.success(this.vm.tempDescription);
        this.getBatches();
      })
      .catch((msg: string) => {
        this.vm.buttonsDisabled = false;
        console.log(msg);
      });
  }

  postBatch(batchId) {
    this.vm.buttonsDisabled = true;
    const changes = { locked: 'true' };
    this.tableDataService
      .patch('t_batch', batchId, changes)
      .then((item: any) => {
        if (item) {
          // this.notification.success(item.description);
        }
        this.getBatches();
      })
      .catch((msg: string) => {
        this.vm.buttonsDisabled = false;
        console.log(msg);
      });
  }

  checkPrintable(): boolean {
    return false;
  }

  prepareCheckPrint() {
    let badAddress = false;
    const noPayToAddressEntity = [];
    // console.log('verifying pay to list: ' + JSON.stringify(this.vm.list));
    this.vm.list.forEach((x) => {
      if (x.pay_to_address.length === 0 && x.selected === true) {
        noPayToAddressEntity.push(x.entity_name);
        // this.notification.showWarningToast(x.entity_name + ' has no Pay To address. Will be deselected.');
        x.selected = false;
        badAddress = true;
      }
    });
    if (badAddress) {
      this.notification.showWarningToast('The following Entities have no Pay To address and were deselected.' + '<br><br>'
      + noPayToAddressEntity.toString().split(',').join('<br>'));
      return;
    }


    if (!this.locks.has(this.mutexVar)) {
      this.locks.set(this.mutexVar, new Mutex());
    }

    this.locks
      .get(this.mutexVar)
      .acquire()
      .then(async (release) => {
        try {
          let allHaveAppliedChecker = true;
          let foundOne = false;
          // console.log('checks marked as selected when at prepare: ' + JSON.stringify(this.vm.list));
          this.vm.check = [];

          this.vm.list.find((x) => {
            if (x.selected && x.applied.length) {
              this.vm.check.push(x);
              foundOne = true;
            }
            if (x.selected && !x.applied.length) {
              allHaveAppliedChecker = false;
            }
          });

          // console.log('checks to print: ' + JSON.stringify(this.vm.check));

          const arrayOfItemsToCheck = [];
          this.vm.list.forEach((x) => {
            if (x.selected) {
              arrayOfItemsToCheck.push({ id: x.id, printed: x.printed  });
            }
          });

          this.tableDataService.putTableDataByArraySilent('t_header/VerifyChecks', arrayOfItemsToCheck)
            .subscribe({
              next: (v: HttpErrorResponse) => {
                // console.log(v);
                if (v !== null && v.status === 418 ) {
                  this.vm.check = [];
                  this.vm.confirmPrint = false;
                  this.notification.showWarningToast(v.error);
                  this.unCheckAll();
                 }
                },
              error: (e) => {console.error(e); },
              complete: () => {
                if (this.vm.confirmPrint == false) {
                  this.unCheckAll();
                  return;
                } else {
                  this.scrollToChecks('checks');
                }
              }
            });

          if (!allHaveAppliedChecker) {
            this.notification.showWarningToast(
              'Some selected payments have no applied invoices - print not available for these items'
            );
          }

          if (!foundOne) {
            this.notification.showWarningToast('No checks to print.');
            return;
          }

          // console.log('checks to print: ' + JSON.stringify(this.vm.check));

// *ngIf="check.pay_to_address[0].address_1 && check.pay_to_address[0].address_2"

          this.vm.check.forEach((x) => {
            this.vm.confirmPrint = true;
            this.vm.printLayout = {};
            let tempCompanyAddress2 = '';
            let tempBankAddress2 = '';
            const tempCompany = this.FilterObject(this.vm.company, { id: this.vm.check[0].company_id }, true)[0];
            // console.log('temp co: ' + JSON.stringify(tempCompany));

            const tempBank = this.FilterObject(this.vm.bank, { id: this.vm.check[0].bank_id }, true)[0];
            this.vm.stub = [{ id: 1 }, { id: 2 }];
            this.vm.printLayout.checkStock = this.FilterObject(this.vm.checkStock, { id: tempBank.stock_id }, true)[0];
            this.vm.entityAddress = x.pay_to_address;
            if (this.vm.entityAddress) {
              // if (this.vm.entityAddress.city && (this.vm.entityAddress.state || this.vm.entityAddress.zip)) {
              //   tempEntityAddress2 = this.vm.entityAddress.city + ', ' + this.vm.entityAddress.state + ' ' + this.vm.entityAddress.zip;
              // } else if (this.vm.entityAddress.city) {
              //   tempEntityAddress2 = this.vm.entityAddress.city;
              // } else {
              //   tempEntityAddress2 = tempCompany.state + ' ' + tempCompany.zip;
              // }
            } else {
              this.vm.entityAddress = {};
            }
            if (tempCompany.city && (tempCompany.state || tempCompany.zip)) {
              tempCompanyAddress2 = tempCompany.city + ', ' + tempCompany.state + ' ' + tempCompany.zip;
            } else if (tempCompany.city) {
              tempCompanyAddress2 = tempCompany.city;
            } else {
              tempCompanyAddress2 = tempCompany.state + ' ' + tempCompany.zip;
            }
            if (tempBank.city && (tempBank.state || tempBank.zip)) {
              tempBankAddress2 = tempBank.city + ', ' + tempBank.state + ' ' + tempBank.zip;
            } else if (tempBank.city) {
              tempBankAddress2 = tempBank.city;
            } else {
              tempBankAddress2 = tempBank.state + ' ' + tempBank.zip;
            }

            // todo remove logo unitl I figure out a way to avoid template parse issue with colon in log data string
            tempCompany.logo = '';

            // console.log('temp co: ' + JSON.stringify(tempCompany));
            for (const obj of this.vm.check) {
              const words = new ToWords();

              let tempAmountInWords;
              // tempAmountInWords = words.convert(obj.detail_total, { currency: true });
              if (this.vm.subLedger === 'ar') {
                tempAmountInWords = words.convert(obj.detail_total, { currency: true });
              } else {
                tempAmountInWords = words.convert(obj.detail_total * - 1, { currency: true });
              }

              obj.companyName = tempCompany.name;
              obj.companyDBA = tempCompany.dba;
              obj.companyAddress1 = tempCompany.address_1;
              obj.companyAddress2 = tempCompanyAddress2;
              obj.companyPhone = tempCompany.phone;
              obj.bankName = tempBank.check_name;
              obj.bankAddress1 = tempBank.address_1;
              obj.bankAddress2 = tempBankAddress2;
              obj.bankPhone = tempBank.phone;
              obj.payeeName = obj.entity_name;
              obj.payeeAddress1 = obj.address_1;
              obj.date = obj.header_date;
              obj.payeeAddress2 = obj.address_2;
              obj.accountNumber = tempBank.account_number;
              obj.transitNumber = tempBank.transit_number;
              obj.institutionNumber = tempBank.institution_number;
              obj.amountInWords = tempAmountInWords;
              obj.amount = (this.vm.subLedger === 'ap') ? obj.payment_applied_total * -1 : obj.payment_applied_total;
              obj.companyData = {
                company: tempCompany,
              };
              // obj.companyLogo = {
              //   logo: tempCompany.logo,
              // };
            }
          });
          // console.log('the check: ' + JSON.stringify(this.vm.check));
        } catch (error) {
          // alert('mutex;');
        } finally {
          release();
        }
      });
  }

  printActualCheckDocument() {
    window.print();
  }

  confirmPostChecks() {
    const messages: string[] = [
      $localize`:Print and mark checks as Printed? @@printandmarkchecksasprinted:Print and mark checks as Printed?`,
      'This action is not reversible.'
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        // this.printActualCheckDocument();
        this.updateHeaderPrinted();
      },
      () => {
        this.notification.showSuccessToast($localize`:Post Canceled @@postCanceled:Post Canceled`);
      }
    );
  }

  updateHeaderPrinted(printStatus: boolean = true) {
    this.vm.confirmPrint = false;

    const arrayOfItemsToChange = [];
    this.vm.list.forEach((x) => {
      if (x.selected) {
        arrayOfItemsToChange.push({ id: x.id, printed: printStatus  });
      }
    });
    // console.log('changes: ' + JSON.stringify(arrayOfItemsToChange));

    // check again before printing to be sure no updates to printed status
    this.tableDataService.putTableDataByArraySilent('t_header/VerifyChecks', arrayOfItemsToChange).subscribe((v: HttpErrorResponse) => {
      if (v != null && v.status === 418) {
        this.vm.check = [];
        this.vm.confirmPrint = false;
        this.notification.showWarningToast(v.error);
        this.unCheckAll();
        this.scrollToChecks('top');
      } else {
        this.printActualCheckDocument();
        this.updateCheckPrintedStatus(arrayOfItemsToChange);
      }
    });
  }

  updateCheckPrintedStatus(arrayOfItemsToChange) {
    this.tableDataService
      .putTableDataByArraySilent('t_header', arrayOfItemsToChange)
      .subscribe(((item: any) => {
        if (item) {
          // this.notification.success(item.description);
        }
        // this.dataTableFullClear();
        this.getBatches();
        this.vm.check = [];
        this.vm.confirmPrint = false;
        this.checkAll = false;
        this.scrollToChecks('top');

        this.getPagedApiData();
      }), error => {
        this.vm.buttonsDisabled = false;
        console.log(error);
      });

  }

  confirmDeleteSelected() {
    if (this.vm.list.filter(x => x.selected).length === 0) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }
    const messages: string[] = [
      $localize`:Are you sure you want to Delete? @@areyousureyouwanttodelete:Are you sure you want to Delete?`,
      'Selected Count: ' + this.vm.list.filter(x => x.selected).length,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        // this.printActualCheckDocument();
        this.deleteSelected();
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  deleteSelected() {
    // just need to send the header id to the api
    const arrayOfItemsToDelete = [];
    this.vm.list.forEach((x) => {
      if (x.selected) {
        arrayOfItemsToDelete.push({header_id: x.id});
      }
    });

    if (!arrayOfItemsToDelete.length) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }
    // console.log('selected recs: ' + JSON.stringify(arrayOfItemsToDelete));

    // makes action button spinner turn while deleting
    this.isLoaded = false;
    // pass array as body in a delete to api entryController
    this.tableDataService.deleteTableDataByArray('entry', arrayOfItemsToDelete).pipe(take(1)).subscribe((item: any) => {
      if (item) {
        //  console.log(item);
        if (item.Message === 'Success') {
          this.isLoaded = true;
          this.getPagedApiData();
        }
      }
    });
  }

  hideBatches() {
    // this.vm.buttonsDisabled = true;
    this.vm.batchesHidden = !this.vm.batchesHidden;
    this.vm.Preferences.ShowBatches = !this.vm.batchesHidden;
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  navigateTo(id, print = false) {
    this.tableDataService.getTableData('entry/' + id).subscribe((result: any[]) => {
      // this.localStorage.StorageSet('paymentItem', JSON.stringify(result));
      this.router.navigateByUrl('/transactions/payment/form?paymentId=' + id + '&batchId=').then();
      if (print) {
        this.router
          .navigate(['/transactions/payment/form'], { queryParams: { paymentId: id, action: 'print' } })
          .then();
      } else {
        this.router.navigate(['/transactions/payment/form'], { queryParams: { paymentId: id } }).then();
      }
    });
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    this.vm.searching = true;
    // this.vm.batchId = '';
    this.vm.tempDescription = '';
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '5';
    } else {
      tempBatchType = '3';
    }
    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&batchId=' + (this.vm.batchId ? this.vm.batchId : '');
    paramString += '&search=' + (this.vm.paymentSearch ? this.vm.paymentSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.paymentFromDate ? this.dateFilter.GetDateFormat(this.vm.paymentFromDate) : '');
    paramString += '&toDate=' + (this.vm.paymentToDate ? this.dateFilter.GetDateFormat(this.vm.paymentToDate) : '');
    paramString += '&unappliedOnly=' + (this.vm.unappliedOnly === true ? 'true' : '');
    paramString += '&notPrintedOnly=' + (this.vm.notPrintedOnly === true ? 'true' : '');
    paramString += '&numberOfResults=' + (this.vm.paymentNumberOfResults ? this.vm.paymentNumberOfResults : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;
    // console.log('params: ' + paramString);
    this.tableDataService.getApiListData('PaymentList', '?' + paramString).subscribe((result2: ResponseModel) => {
      this.vm.list = result2.Data;
      // console.log('list:' + JSON.stringify(this.vm.list));
      this.totalRecords = result2.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;
      // this.convertToNumbers();
      this.vm.searching = false;
      this.isLoaded = true;
    });
  }

  includeUnapplied(event: any = null) {
    if (event) {
      this.vm.unappliedOnly = true;
    } else {
      this.vm.unappliedOnly = false;
    }
    // this.vm.invoiceSearch = value.searchTerm;
    // this.vm.invoiceFromDate = value.fromDate;
    // this.vm.invoicetoDate = value.toDate;
    this.pageSize = this.appConfig.ListPageSize;
    this.page = 1;
    this.getPagedApiData();
  }

  getNotPrintedOnly(event: any = null) {
    if (event) {
      this.vm.notPrintedOnly = true;
    } else {
      this.vm.notPrintedOnly = false;
    }
    // this.vm.invoiceSearch = value.searchTerm;
    // this.vm.invoiceFromDate = value.fromDate;
    // this.vm.invoicetoDate = value.toDate;
    this.pageSize = this.appConfig.ListPageSize;
    this.page = 1;
    this.getPagedApiData();
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.paymentSearch = value.searchTerm;
    this.vm.paymentFromDate = value.fromDate;
    this.vm.paymentToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.paymentSearch = value.searchTerm;
    this.vm.paymentFromDate = value.fromDate;
    this.vm.paymentToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  scrollTo(anchor: string): void {
    if (!this.vm.batchesHidden) {
      this.vps.scrollToAnchor(anchor);
    }
  }

  scrollToChecks(anchor: string) {
    this.vps.scrollToAnchor(anchor);
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

}
