import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {
  filter,
  Observable,
  switchMap,
  throwError,
  take,
 combineLatest, EMPTY
} from 'rxjs';
import {BsToastService} from './bs-toast-service';
import {AuthService} from './auth.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
  currentUser: any;
  refreshTokenObservable: Observable<any>;

  constructor(private authenticationService: AuthService, private toast: BsToastService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // do nothing to attach headers to login
    if (request.url.includes('/login')) {
      return next.handle(request);
    }

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    request = this.attachTokenToRequest(request, this.currentUser.accessToken);

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && !request.url.includes('/refresh') && !request.url.includes('sys_user/me')) {
            // if throwing 401 it cannot be valid so set it to false BEFORE next read
            this.authenticationService.isAccessTokenValid$.next(false);
            return combineLatest([this.authenticationService.refreshingTokens$, this.authenticationService.isAccessTokenValid$]).pipe(
              take(1),
              switchMap(([refreshingTokens, isAccessTokenValid]) => {
                if (refreshingTokens || isAccessTokenValid) {
                  return this.authenticationService.isAccessTokenValid$;
                } else {
                  return this.authenticationService.refreshToken(this.currentUser).pipe(
                    switchMap(() => this.authenticationService.isAccessTokenValid$)
                  );
                }
              }),
              filter(isAccessTokenValid => isAccessTokenValid === true),
              switchMap(() => this.authenticationService.accessToken$),
              take(1),
              switchMap(encodedAccessToken => {
                return next.handle(request.clone({
                  headers: request.headers.set('Authorization', `Bearer ${encodedAccessToken}`)
                    .set('Content-Type', 'application/json')
                    .set('Accept', '*/*'),
                }));
              }),
            );
          } else if (error.status === 401 && request.url.includes('/refresh')) {
            // this.toast.showWarningToast('Refresh token has expired. Forced User logout.')
            return throwError(() => error);
          } else {
            return EMPTY;
          }
        }),
      );
  }
  private attachTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // console.log(`token in jwt: ${token}`);
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: '*/*'
      }
    });
  }
}
