import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { Observable } from 'rxjs';
import { TaxModel } from '../../../../DataModels/tax-model';
import { ItemCategoryModel } from '../../../../DataModels/item-category-model';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-item-category-form',
  templateUrl: './item-category-form.component.html',
  styleUrls: ['./item-category-form.component.css'],
})
export class ItemCategoryFormComponent implements OnInit {
  itemCategoryID;
  itemCategory: ItemCategoryModel;
  itemCategoryForm: UntypedFormGroup;
  tax$: Observable<TaxModel[]>;
  itemCategory$: Observable<ItemCategoryModel[]>;
  accountList: Array<object>;
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.itemCategoryID = this.route.snapshot.params.id;
  }

  get f() {
    return this.itemCategoryForm.controls;
  }

  createForm() {
    this.itemCategoryForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      expense_account_id: new UntypedFormControl(''),
      revenue_account_id: new UntypedFormControl(''),
      receivable_tax_1_id: new UntypedFormControl(''),
      receivable_tax_2_id: new UntypedFormControl(''),
      payable_tax_1_id: new UntypedFormControl(''),
      payable_tax_2_id: new UntypedFormControl(''),
      parent_name: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Item Category @@itemCategory:Item Category`);
    this.LoadAccount();
    // this.tax$ = this.table.getTableData('tax');
    // this.itemCategory$ = this.table.getTableData('item_category');
    this.table.getApiListData('tax', '?pageNumber=0&pageSize=999999').subscribe((res: any) => {
      this.tax$ = res.Data;
    });
    this.table.getApiListData('item_category', '?pageNumber=0&pageSize=999999').subscribe((res: any) => {
      this.itemCategory$ = res.Data;
    });
    if (this.itemCategoryID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('item_category', this.itemCategoryID).subscribe(
      (result) => {
        this.itemCategory = result;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
        this.itemCategoryForm.patchValue(this.itemCategory);
      }
    );
  }

  private LoadAccount() {
    this.table.getApiListData('account', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.accountList = result.Data;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {}
    );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.itemCategoryForm.reset();
    }
  }

  resetForm() {
    this.itemCategoryForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/item-category-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete item category? @@areYouSureYouWantToDeleteItemCategory:Are you sure you want to delete item category?`,
      this.itemCategory.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('item_category', this.itemCategoryID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.itemCategoryForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return false;
    }
    this.itemCategory = Object.assign({}, this.itemCategoryForm.value);
    if (this.itemCategoryID) {
      if (this.itemCategoryForm.invalid === false) {
        this.table.patch('item_category', this.itemCategoryID, this.itemCategory).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('item_category', this.itemCategory).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.itemCategoryID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
