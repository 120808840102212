import {Injectable} from '@angular/core';
import {TableDataService} from './tableData.service';
import {NotificationService} from './notification.service';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
              public tableDataService: TableDataService,
              private notification: NotificationService
              ) {
    // const mailObj = new EmailModel();
  }

  sendEmail(mailObj = null, silent = false) {
    // alert(JSON.stringify(mailObj));
    // console.log('at email post: ' + JSON.stringify(mailObj));

    // let it ignore preflights coming back so we don't get dup success messages
    this.tableDataService.post('email', mailObj).pipe(take(1))
      .subscribe({
        next: (result: any) => {
          if (result.Message !== 'Success' && result.Message !== 'Preflight') {
            this.notification.warning(result.description);
          } else if (result.Message === 'Success' && !silent){
            // this.notification.success(result.description);
          }
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {
          // do stuff here
        },
      });
  }
}
