import {Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-modal-new-item',
  templateUrl: './modal-new-item.component.html',
  styleUrls: ['./modal-new-item.component.css']
})
export class ModalNewItemComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;

  @Output() saveNewItemEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  saveNewItem(){
    this.saveNewItemEmit.emit();
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
