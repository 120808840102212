import { ConstructorOf, ConverterOptions, LocaleInterface, NumberWordMap, ToWordsOptions } from './types';

import enUs from './locales/en-US';
import enGb from './locales/en-GB';
import frFr from './locales/fr-FR';

export const DefaultConverterOptions: ConverterOptions = {
  currency: false,
  ignoreDecimal: false,
  ignoreZeroCurrency: false,
  doNotAddOnly: false,
};

export const DefaultToWordsOptions: ToWordsOptions = {
  localeCode: 'en-US',
  converterOptions: DefaultConverterOptions,
};

export class ToWords {
  private options: ToWordsOptions = {};

  private locale: InstanceType<ConstructorOf<LocaleInterface>> | undefined = undefined;

  constructor(options: ToWordsOptions = {}) {
    this.options = Object.assign({}, DefaultToWordsOptions, options);
  }

  public getLocaleClass(): ConstructorOf<LocaleInterface> {
    /* eslint-disable @typescript-eslint/no-var-requires */
    switch (this.options.localeCode) {
      case 'en-US':
        return enUs;
      case 'en-GB':
        return enGb;
      case 'fr-FR':
        return frFr;
    }

    // https://github.com/mastermunj/to-words/tree/main/src


    // ***** NOTE ***** can config at local usage to override here - just use this this to do so
    // const toWords = new ToWords({
    //   localeCode: 'en-IN',
    //   converterOptions: {
    //     currency: true,
    //     ignoreDecimal: false,
    //     ignoreZeroCurrency: false,
    //     doNotAddOnly: false,
    //     currencyOptions: { // can be used to override defaults for the selected locale
    //       name: 'Rupee',
    //       plural: 'Rupees',
    //       symbol: '₹',
    //       fractionalUnit: {
    //         name: 'Paisa',
    //         plural: 'Paise',
    //         symbol: '',
    //       },
    //     }
    //   }
    // });


    /* eslint-enable @typescript-eslint/no-var-requires */
    throw new Error(`Unknown Locale "${this.options.localeCode}"`);
  }

  public getLocale(): InstanceType<ConstructorOf<LocaleInterface>> {
    if (this.locale === undefined) {
      const LocaleClass = this.getLocaleClass();
      this.locale = new LocaleClass();
    }
    return this.locale;
  }

  public convert(num: number, options: ConverterOptions = {}): string {
    options = Object.assign({}, this.options.converterOptions, options);

    if (!this.isValidNumber(num)) {
      throw new Error(`Invalid Number "${num}"`);
    }

    if (options.ignoreDecimal) {
      num = Number.parseInt(num.toString());
    }

    let words: string[] = [];
    if (options.currency) {
      words = this.convertCurrency(num, options);
    } else {
      words = this.convertNumber(num);
    }
    return words.join(' ');
  }

  protected convertNumber(num: number): string[] {
    const locale = this.getLocale();

    const isNegativeNumber = num < 0;
    if (isNegativeNumber) {
      num = Math.abs(num);
    }

    const split = num.toString().split('.');
    const ignoreZero = this.isNumberZero(num) && locale.config.ignoreZeroInDecimals;
    let words = this.convertInternal(Number(split[0]));
    const isFloat = this.isFloat(num);
    if (isFloat && ignoreZero) {
      words = [];
    }
    const wordsWithDecimal = [];
    if (isFloat) {
      if (!ignoreZero) {
        wordsWithDecimal.push(locale.config.texts.point);
      }
      if (split[1].startsWith('0') && !locale.config?.decimalLengthWordMapping) {
        const zeroWords = [];

        for (let num of split[1]) {
          zeroWords.push(...this.convertInternal(Number(num)));
        }
        wordsWithDecimal.push(...zeroWords);
      } else {
        wordsWithDecimal.push(...this.convertInternal(Number(split[1])));
        const decimalLengthWord = locale.config?.decimalLengthWordMapping?.[split[1].length];
        if (decimalLengthWord) {
          wordsWithDecimal.push(decimalLengthWord);
        }
      }
    }
    const isEmpty = words.length <= 0;
    if (!isEmpty && isNegativeNumber) {
      words.unshift(locale.config.texts.minus);
    }
    words.push(...wordsWithDecimal);
    return words;
  }

  protected convertCurrency(num: number, options: ConverterOptions = {}): string[] {
    const locale = this.getLocale();

    const currencyOptions = options.currencyOptions ?? locale.config.currency;

    const isNegativeNumber = num < 0;
    if (isNegativeNumber) {
      num = Math.abs(num);
    }

    num = this.toFixed(num);
    // Extra check for isFloat to overcome 1.999 rounding off to 2
    const split = num.toString().split('.');
    let words = [...this.convertInternal(Number(split[0]))];
    if (currencyOptions.plural) {
      words.push(currencyOptions.plural);
    }
    const ignoreZero =
      this.isNumberZero(num) &&
      (options.ignoreZeroCurrency || (locale.config?.ignoreZeroInDecimals && num !== 0));

    if (ignoreZero) {
      words = [];
    }

    const wordsWithDecimal = [];
    const isFloat = this.isFloat(num);
    if (isFloat) {
      if (!ignoreZero) {
        wordsWithDecimal.push(locale.config.texts.and);
      }
      wordsWithDecimal.push(
        ...this.convertInternal(
          Number(split[1]) * (!locale.config.decimalLengthWordMapping ? Math.pow(10, 2 - split[1].length) : 1),
        ),
      );
      const decimalLengthWord = locale.config?.decimalLengthWordMapping?.[split[1].length];
      if (decimalLengthWord?.length) {
        wordsWithDecimal.push(decimalLengthWord);
      }
      wordsWithDecimal.push(currencyOptions.fractionalUnit.plural);
    } else if (locale.config.decimalLengthWordMapping && words.length) {
      wordsWithDecimal.push(currencyOptions.fractionalUnit.plural);
    }
    const isEmpty = words.length <= 0 && wordsWithDecimal.length <= 0;
    if (!isEmpty && isNegativeNumber) {
      words.unshift(locale.config.texts.minus);
    }
    if (!isEmpty && locale.config.texts.only && !options.doNotAddOnly) {
      wordsWithDecimal.push(locale.config.texts.only);
    }
    if (wordsWithDecimal.length) {
      words.push(...wordsWithDecimal);
    }
    return words;
  }

  protected convertInternal(num: number): string[] {
    const locale = this.getLocale();

    if (locale.config.exactWordsMapping) {
      const exactMatch = locale.config?.exactWordsMapping?.find((elem) => {
        return num === elem.number;
      });
      if (exactMatch) {
        return [exactMatch.value];
      }
    }

    const match = locale.config.numberWordsMapping.find((elem) => {
      return num >= elem.number;
    }) as NumberWordMap;

    const words: string[] = [];
    if (num <= 100 || (num < 1000 && locale.config.namedLessThan1000)) {
      words.push(match.value);
      num -= match.number;
      if (num > 0) {
        if (locale.config?.splitWord?.length) {
          words.push(locale.config.splitWord);
        }
        words.push(...this.convertInternal(num));
      }
      return words;
    }

    const quotient = Math.floor(num / match.number);
    const remainder = num % match.number;
    let matchValue = match.value;
    if (quotient > 1 && locale.config?.pluralWords?.find((word) => word === match.value) && locale.config?.pluralMark) {
      matchValue += locale.config.pluralMark;
    }
    if (quotient === 1 && locale.config?.ignoreOneForWords?.includes(matchValue)) {
      words.push(matchValue);
    } else {
      words.push(...this.convertInternal(quotient), matchValue);
    }

    if (remainder > 0) {
      if (locale.config?.splitWord?.length) {
        if (!locale.config?.noSplitWordAfter?.find((word) => word === match.value)) {
          words.push(locale.config.splitWord);
        }
      }
      words.push(...this.convertInternal(remainder));
    }
    return words;
  }

  public toFixed(num: number, precision = 2): number {
    return Number(Number(num).toFixed(precision));
  }

  public isFloat(num: number | string): boolean {
    return Number(num) === num && num % 1 !== 0;
  }

  public isValidNumber(num: number | string): boolean {
    return !isNaN(parseFloat(num as string)) && isFinite(num as number);
  }

  public isNumberZero(num: number): boolean {
    return num >= 0 && num < 1;
  }
}
