import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TableDataService} from '../../../../Services/tableData.service';
import {PermissionsService} from '../../../../Services/permissions.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EntityContactModel} from '../../../../DataModels/entity-contact-model';
import {Observable} from 'rxjs';
import {EntityAddressModel} from '../../../../DataModels/entity-address-model';
import {NotificationService} from '../../../../Services/notification.service';
import {ResponseModel} from '../../../../DataModels/response-model';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';

@Component({
  selector: 'app-entity-contact',
  templateUrl: './entity-contact.component.html',
  styleUrls: ['./entity-contact.component.css']
})
export class EntityContactComponent implements OnInit {

  @Input() entityID: string;
  entityContactForm: UntypedFormGroup;
  ContactList: Array<EntityContactModel>;
  contacts$: Observable<EntityContactModel[]>;
  contactCount = 0;
  showContactMerge = false;
  contactItem: EntityContactModel;

  addContact = false;
  formContact = false;
  buttonsDisabled = false;
  deleteButtonsDisabled = false;
  deleteId = '';


  constructor(private table: TableDataService,
              public p: PermissionsService,
              private formBuilder: UntypedFormBuilder,
              private notification: NotificationService,
              private confirmDialogService: ConfirmDialogService) {
    this.createForm();
  }
  createForm() {
    this.entityContactForm = this.formBuilder.group({
      id:  new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
      phone: new UntypedFormControl(''),
      cell: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      other: new UntypedFormControl(''),
      position: new UntypedFormControl(''),
      merge_to_contact_id: new UntypedFormControl('')
  });
  }

  ngOnInit() {
    this.loadEntityContacts();
  }

  loadEntityContacts() {
    // console.log('ent id: ' + this.entityID);
    this.table.getTableData('entity_contact_list', this.entityID + '?pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.ContactList = data;
      this.contactCount = data.length;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
      });
  }


  getContact(id: any) {
    this.deleteId = id;
    this.table.getTableData('entity_contact', id).subscribe(result => {
        this.contactItem = result;
        // console.log(id);
        // console.log(this.contactItem);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
        this.entityContactForm.patchValue(this.contactItem);
        this.addContact = true;
        this.formContact = true;
      });
    // this.isThisUsed(id, 'contact_id', 't_header').then();
  }

  saveNewAddress() {
    //
  }

  itemClose() {
    this.contactItem = null;
    this.contactItem = new EntityContactModel();
    /// this.entityAddressForm.patchValue(this.addContact);
    this.entityContactForm.reset();
    this.loadEntityContacts();
  }

  async isThisUsed(idToCheck: string, columnToCheck: string, tableToCheck: string) {

    this.deleteButtonsDisabled = false;

    let paramString = '';
    paramString += 'id=' + idToCheck;
    paramString += '&';
    paramString += 'columnName=' + columnToCheck;
    paramString += '&';
    paramString += 'tableName=' + tableToCheck;

    this.table.getTableDataWithParams('in_use', paramString).subscribe(
      result => {
        this.deleteButtonsDisabled = (result.toString() == 'true');
        // console.log('button:' + this.buttonsDisabled);
        // console.log('res:' + result);
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error validating usage of item @@errorValidatingUsageOfItem:Error validating usage of item.`);
        console.log(error);
      },
      () => {
        // ending stuff here
      });
  }

  onSave() {
    // only need this for the merge and it screws up posting model if included in normal post patch
    delete this.entityContactForm.value.merge_to_contact_id;
    this.contactItem = Object.assign({}, this.entityContactForm.value);
    // temp patch for empty checkbox value from form.
    if (this.contactItem.active === null){
      this.contactItem.active = false;
    }

    if (this.contactItem.id) {
      if (this.entityContactForm.invalid === false) {
        this.table.patch('entity_contact', this.contactItem.id, this.contactItem).then();
        // this.notification.success($localize`:Updated @@updated:Updated`);
      }
    } else {
      this.contactItem.entity_id = this.entityID;
      // console.log('vals before post: ' + JSON.stringify(this.contactItem));
      this.table.postWithPromise('entity_contact', this.contactItem).then();
      // this.notification.success($localize`:Saved @@saved:Saved`);
    }
    this.loadEntityContacts();
  }

  newContact() {
    this.contactItem = null;
    this.contactItem = new EntityContactModel();
    this.addContact = true;

    // this.entityContactForm.reset();

    this.contactItem.active = true;
  }

  onDelete() {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete contact? @@areYouSureYouWantToDeleteContact:Are you sure you want to delete contact`, this.contactItem.name];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('entity_contact', this.deleteId).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          (success: HttpErrorResponse) => {
            this.notification.success($localize`:Deleted @@deleted:Deleted`);
            this.itemClose();
          });
      }, () => {
        this.notification.success($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }


  mergeContact() {

    const urlParamsJson = {
      merge_to_contact_id: this.contactItem.id,
      delete_contact_id: this.entityContactForm.get('merge_to_contact_id').value
    };

    if (this.contactItem.id === this.entityContactForm.get('merge_to_contact_id').value){
      this.notification.warning($localize`:Cannot merge to the same contact @@cannotMergeToTheSameContact:Cannot merge to the same contact`);
    } else {
      const Param: string = this.encodeQueryData(urlParamsJson);
      this.table.getTableData('MergeEntity/contact', '/MergeContact?' + Param).subscribe(result => {
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {

        });
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  loadLookupContacts() {
    this.contacts$ = this.table.getTableData('entity_contact_list', this.entityID);
  }
}
