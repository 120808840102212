import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../Services/storage.service';
import { TableDataService } from '../../../../Services/tableData.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { Title } from '@angular/platform-browser';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { AppConfigService } from '../../../../Services/app-config.service';
import { DecimalNewPipe } from '../../../common/DecimalNewPipe';
import { DatePipe, ViewportScroller } from '@angular/common';
import { ResponseModel } from '../../../../DataModels/response-model';
import {Subject, take} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../../Services/ngbpagination-config.service';
import {BsToastService} from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-assembly-list',
  templateUrl: './assembly-list.component.html',
  styleUrls: ['./assembly-list.component.css'],
})
export class AssemblyListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private vps: ViewportScroller,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    this.vm.batchId = '';
  }

  vm: any = {
    title: 'Assemblies',
    Preferences: [],
    permissions: [],
    formRoute: '/inventory/assemble/form',
    assembleFilter: { text: null },
    batches: [],
  };
  assemblyCount = 0;
  isLoaded = false;
  selectItems = true;
  showItemsInBatchIsOn = false;
  batchType: any;

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngOnInit() {
    this.batchType = 14;
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    if (
      !this.vm.permissions.EditReceivableAssembles &&
      !this.vm.permissions.ViewReceivableAssembles &&
      !this.vm.permissions.EditPayableAssembles &&
      !this.vm.permissions.ViewPayableAssembles &&
      !this.vm.permissions.Admin
    ) {
      document.location.href = '/';
    }

    this.vm.batchesHidden = true;
    this.vm.owingSelected = this.vm.totalSelected = 0;
    this.vm.assembleToDateOpen = this.vm.assembleFromDateOpen = false;

    // this.vm.assembleFilter = JSON.parse(this.localStorage.StorageGet('assembleFilter', {
    //     text:  	    sort: 'audit_sequence',
    //     ascending: true
    // }));

    // this.vm.assembleSearch = this.localStorage.StorageGet('assembleSearch', '');
    // this.vm.assembleToDate = this.localStorage.StorageGet('assembleToDate', '');
    // this.vm.assembleFromDate = this.localStorage.StorageGet('assembleFromDate', '');
    // this.vm.assembleNumberOfResults = '25';
    // this.vm.unpaidAssembles = this.localStorage.StorageGet('unpaidAssembles', '');
    this.search();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([4, 'desc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  convertToNumbers() {
    for (const [x, value] of Object.entries(this.vm.list)) {
      this.vm.list[x].detail_total = Number(this.vm.list[x].detail_total);
      this.vm.list[x].payment_applied_total = Number(this.vm.list[x].payment_applied_total);
      this.vm.list[x].audit_sequence = Number(this.vm.list[x].audit_sequence);
    }
  }

  openAssembleToDate() {
    this.vm.assembleToDateOpen = true;
  }

  openAssembleFromDate() {
    this.vm.assembleFromDateOpen = true;
  }

  selectAll() {
    for (const list of this.vm.list) {
      (document.getElementById(list.id) as HTMLInputElement).checked = this.vm.selectAllItems;
    }
    this.selectItem();
  }

  selectItem() {
    this.vm.owingSelected = this.vm.totalSelected = 0;
    let firstEntity = '';
    document.getElementById('pay-btn').classList.remove('d-none');
    document.getElementById('owingSelected').classList.remove('d-none');
    this.vm.itemsSelected = false;
    for (const [x, value] of Object.entries(this.vm.list)) {
      if ((document.getElementById(this.vm.list[x].id) as HTMLInputElement).checked) {
        if (firstEntity == '') {
          this.vm.itemsSelected = true;
          firstEntity = this.vm.list[x].entity_name;
        } else if (firstEntity != this.vm.list[x].entity_name) {
          document.getElementById('pay-btn').classList.add('d-none');
          document.getElementById('owingSelected').classList.add('d-none');
        }
        this.vm.totalSelected += Number(this.vm.list[x].header_total);
        this.vm.owingSelected += Number(this.vm.list[x].header_owing);
        document.getElementById(this.vm.list[x].id + '-tr').classList.add('success');
      } else {
        document.getElementById(this.vm.list[x].id + '-tr').classList.remove('success');
      }
    }
  }

  clearSearch() {
    this.vm.assembleSearch = this.vm.assembleFromDate = this.vm.assembleToDate = this.vm.unpaidAssembles = '';
    this.vm.assembleNumberOfResults = '25';
    this.vm.list = [];
    // this.localStorage.StorageSet('assembleSearch', '');
    // this.localStorage.StorageSet('assembleToDate', '');
    // this.localStorage.StorageSet('assembleFromDate', '');
    // this.localStorage.StorageSet('unpaidAssembles', '');
    // this.localStorage.StorageSet('assembleNumberOfResults', '25');
    this.search();
  }

  search(batchId = '') {
    // console.log('batch id: ' + batchId);
    this.vm.searching = true;
    this.vm.batchId = '';
    this.vm.tempDescription = '';
    const tempBatchType = '14';
    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    paramString += '&batch_id=' + (batchId ? batchId : '');


    this.tableDataService.getTableData('t_batch?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      // console.log('Batches');
      // console.log(this.vm.batches);
    });

    paramString = '?';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    // paramString += 'batchId=' + (batchId ? batchId : '');
    paramString += '&search=' + (this.vm.assembleSearch ? this.vm.assembleSearch : '');
    paramString += '&fromDate=' + (this.vm.assembleFromDate ? this.dateFilter.GetDateFormat(this.vm.assembleFromDate) : '');
    paramString += '&toDate=' + (this.vm.assembleToDate ? this.dateFilter.GetDateFormat(this.vm.assembleToDate) : '');
    paramString += '&numberOfResults=' + (this.vm.assembleNumberOfResults ? this.vm.assembleNumberOfResults : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('ListAssembly' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dtTrigger.next('');

      this.dataTableReady = true;
      this.assemblyCount = result.totalRecords;
      this.convertToNumbers();
      this.vm.searching = false;
      this.isLoaded = true;
    });

    // this.localStorage.StorageSet('assembleSearch', this.vm.assembleSearch);
    // this.localStorage.StorageSet('assembleToDate', this.vm.assembleToDate);
    // this.localStorage.StorageSet('assembleFromDate', this.vm.assembleFromDate);
    // this.localStorage.StorageSet('unpaidAssembles', this.vm.unpaidAssembles);
    // this.localStorage.StorageSet('assembleNumberOfResults', this.vm.assembleNumberOfResults);
  }

  listAssemblyByBatchId(id ='') {


  }

  filter(filter) {
    this.vm.assembleFilter = Object.assign(this.vm.assembleFilter, filter);
    // this.localStorage.StorageSet('assembleFilter', JSON.stringify(this.vm.assembleFilter));
  }

//   getBatchItems(batchId) {
//     this.vm.tempDescription = '';
//     this.vm.batchId = batchId;
//     this.vm.searching = true;
//     this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
//     this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
//
//     let paramString = '?search=' + (this.vm.searchTerm ? this.vm.searchTerm : '');
//     paramString += '&batch_id=' + (this.vm.batchId ? this.vm.batchId : '');
//     // paramString += '&fromDate=' + (this.vm.fromDate ? this.dateFilter.GetDateFormat(this.vm.fromDate) : '');
//     // paramString += '&toDate=' + (this.vm.toDate ? this.dateFilter.GetDateFormat(this.vm.toDate) : '');
//     // paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
//     // paramString += '&pageSize=' + this.pageSize;
// console.log('params to fetch batches: ' + paramString);
//     // this.tableDataService.getApiListData('ListAssembly' + paramString).subscribe((result: ResponseModel) => {
//     //   this.vm.list = result.Data;
//     this.tableDataService.getTableData('t_batch' + paramString).subscribe((result: any[]) => {
//       this.vm.batches = result;
//       console.log('batch fetch result: ' + JSON.stringify(this.vm.batches));
//
//       this.convertToNumbers();
//       this.vm.searching = false;
//     });
//   }

  updateBatchesSelectedInModal(batchId: string) {
    this.getBatchItems(batchId);
    // this.paginate(false);
  }

  getBatches() {
    this.vm.searching = true;
    let paramString = '';
    paramString += 'batchType=14';
    paramString += '&search=' + ((this.vm.invoiceSearch) ? this.vm.invoiceSearch : '');
    paramString += '&fromDate=' + ((this.vm.invoiceFromDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
    paramString += '&toDate=' + ((this.vm.invoiceToDate) ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
    paramString += '&lockedBatches=' + ((this.vm.lockedBatches) ? this.vm.lockedBatches : '');
    paramString += '&entryNumberOfResults=' + ((this.vm.entryNumberOfResults) ? this.vm.entryNumberOfResults : '');

    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      console.log(result);
      if (this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true).length == 1) {
        this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
        this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
      }
      this.vm.searching = false;
    });
  }

  getBatchItems(batchId) {
    // console.log('params to fetch batch items: ' + batchId);
    this.showItemsInBatchIsOn = true;
    this.vm.tempDescription = '';
    this.vm.batchId = batchId;
    this.vm.searching = true;
    // this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
    // this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
    this.tableDataService.getTableData('EntryList/batch', '?batch_id=' + this.vm.batchId).subscribe((result: any[]) => {
      this.vm.searching = false;
      this.vm.batches = result;
      this.convertToNumbers();
      // console.log('batch items fetch result: ' + JSON.stringify(this.vm.batches));
      this.vm.listInitiated = true;
    });
    this.getListItemsByBatch(batchId);
  }

  getListItemsByBatch(batchId = '') {
    // console.log('params to fetch items by batch: ' + batchId);
    this.vm.tempDescription = '';
      this.vm.batchId = batchId;
      this.vm.searching = true;
      // this.vm.tempDescription = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].description;
      // this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, { id: this.vm.batchId }, true)[0].locked;
      this.tableDataService.getTableData('ListAssembly/GetAssemblyById', '?batchId=' + this.vm.batchId).subscribe((result: ResponseModel) => {

        // this.convertToNumbers();
        this.vm.searching = false;
        this.vm.list = result.Data;
        // this.vm.tempDescription = this.vm.listData.description;
        // this.vm.tempBatchLocked = this.vm.listData.locked;
        // console.log('list items: ' + JSON.stringify(this.vm.list));
        this.vm.listInitiated = true;
    });
  }

  newBatch() {
    if (this.vm.permissions.Admin || this.vm.permissions.EditAssembles) {
      const newBatch = { type_id: 14 };

      this.tableDataService.post('t_batch', newBatch).subscribe(
        (item: any) => {
          this.getBatches();
          this.vm.batchId = item.id;
          this.vm.tempDescription = $localize`:Batch @@batch:Batch`;
          // this.notification.success($localize`:Success @@success:Success`);
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error);
          console.log(error);
        }
      );
    }
  }

  updateBatch() {
    if (this.vm.permissions.Admin || this.vm.permissions.EditAssembles) {
      this.vm.buttonsDisabled = true;
      const changes = { description: this.vm.tempDescription };
      this.tableDataService.patch('t_batch', this.vm.batchId, changes).then(
        (item: any) => {
          if (item) {
            // this.notification.success(this.vm.tempDescription);
          }
          this.getBatches();
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error);
          console.log(error);
        }
      );
    }
  }

  postBatch(batchId) {
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableAssembles) {
      this.vm.buttonsDisabled = true;
      // const locked = true;
      const changes = { locked: true };
      // console.log('changes for batch: ' +  batchId + ': ' + JSON.stringify(changes));
      this.tableDataService.patch('t_batch', batchId, changes).then(
        (item: any) => {
          if (item) {
            // this.notification.success($localize`:Success @@success:Success`);
            console.log('post batch return: ' + JSON.stringify(item));
          }
          // this.getBatches();
          this.vm.buttonsDisabled = false;
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error.description);
          console.log(error);
        }
      );
    }
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  onNavigate(aID: string) {
    this.router.navigateByUrl(this.vm.formRoute + '?assembleId=' + aID).then();
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    this.vm.searching = true;
    this.vm.tempDescription = '';
    const tempBatchType = '14';

    let paramString = '?';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&search=' + (this.vm.assembleSearch ? this.vm.assembleSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.assembleFromDate ? this.dateFilter.GetDateFormat(this.vm.assembleFromDate) : '');
    paramString += '&toDate=' + (this.vm.assembleToDate ? this.dateFilter.GetDateFormat(this.vm.assembleToDate) : '');
    paramString += '&numberOfResults=' + (this.vm.assembleNumberOfResults ? this.vm.assembleNumberOfResults : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('ListAssembly' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;

      // this.assemblyCount = this.vm.list.length;
      this.convertToNumbers();
      this.vm.searching = false;
      this.isLoaded = true;
    });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.assembleSearch = value.searchTerm;
    this.vm.assembleFromDate = value.fromDate;
    this.vm.assembleToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.assembleSearch = value.searchTerm;
    this.vm.assembleFromDate = value.fromDate;
    this.vm.assembleToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

  hideBatches() {
    this.vm.batchesHidden = !this.vm.batchesHidden;
  }

  scrollTo(anchor: string): void {
    if (!this.vm.batchesHidden) {
      this.vps.scrollToAnchor(anchor);
    }
  }
}
