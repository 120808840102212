import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AccountSubtotalModel} from '../../../DataModels/account-subtotal-model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {TableDataService} from '../../../Services/tableData.service';
import {NotificationService} from '../../../Services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ResponseModel} from '../../../DataModels/response-model';
import {
  AccountTypeSelectComponent
} from '../../Reports/report-resources/account-type-select/account-type-select.component';
import {AccountModel} from '../../../DataModels/account-model';
import {ReportDefinitionModel} from '../../../DataModels/report-definition-model';

@Component({
  selector: 'app-subtotal-for-account-modal',
  templateUrl: './subtotal-for-account-modal.component.html',
  styleUrls: ['./subtotal-for-account-modal.component.css']
})
export class SubtotalForAccountModalComponent implements OnInit, OnDestroy, OnChanges {

  @Input() editSubtotalId: string;
  @Output() recordSavedEvent = new EventEmitter<string>();
  @ViewChild('closeButton') closeButton: ElementRef;


  accountSubTotal: AccountSubtotalModel;
  accountType: any = [];

  accountSubTotalForm: UntypedFormGroup;
  accountSubtotalID;
  buttonsDisabled = false;
  deleteButtonDisabled = false;
  stopEdit = false;
  subTotalList = [];
  subTotalCount = 0;
  submitted = false;
  field: object;
  treeViewExpanded = false;
  items: any;
  modalTitle: string;
  parentIsSelectedHideType = false;
  reportSection: ReportDefinitionModel[];

  constructor(private table: TableDataService,
              private notification: NotificationService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDialogService: ConfirmDialogService) {

    this.createForm();
    this.accountSubtotalID = this.route.snapshot.params.id;
  }

  createForm() {
    this.accountSubTotalForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      type_id: new UntypedFormControl(1),
      parent_id: new UntypedFormControl(''),
      cash_flow_section: new UntypedFormControl(''),
      sort_order: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      // audit_sequence: new FormControl(''),
      active: new UntypedFormControl(true),
    });
  }


  ngOnInit() {
    // this.LoadAccountSubTotals();
  }

  get f() {
    return this.accountSubTotalForm.controls;
  }

  parentSelected(event: any) {
    // alert(event.target.value);
    const item = event.target.value;
    if (item) {
      this.parentIsSelectedHideType = true;
      this.f.type_id.disable();

    } else {
      this.parentIsSelectedHideType = false;
      this.f.type_id.enable();
    }
  }

  recSaved(value: string) {
    this.recordSavedEvent.emit(value);
  }

  ngOnChanges() {
    // this.accountSubTotalForm.reset();
    // alert('changing: ' + this.editSubtotalId);
    this.accountSubtotalID = this.editSubtotalId;

    this.accountType = [];
    this.subTotalList = [];

    if (this.accountSubtotalID === 'New') {
      this.accountSubtotalID = '';
      this.modalTitle = 'New';
      this.accountSubTotalForm.reset();
      this.accountSubTotalForm.patchValue({active: true});
      this.accountSubTotal = new AccountSubtotalModel();
    }
    this.loadModalFormValues();
  }

  loadModalFormValues() {
    // this.accountSubTotalForm.reset();

    this.loadLookups();

    if (this.accountSubtotalID !== '' && this.accountSubtotalID !== 'New') {
      // console.log('load one:' + this.accountSubtotalID);
      this.loadSubTotal();
    } else {
      // this.modalTitle = this.accountID;
    }

    this.table.getApiListData('report_definition?pageNumber=0&pageSize=999999').subscribe((res: ResponseModel) => {
      const data: any = res.Data;
      this.reportSection = data;
    });
}

  private loadSubTotal() {

    this.table.getTableData('account_subtotal', this.accountSubtotalID).subscribe(
      (result: AccountSubtotalModel) => {
        this.accountSubTotal = result;
        // console.log('single sub: ' + JSON.stringify(result));
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error loading Accounts @@errorLoadingAccounts:Error loading Accounts`);
        console.log(error);
      },
      () => {
       this.accountSubTotalForm.patchValue(this.accountSubTotal);
      });
  }

  private loadLookups() {
    // alert('loading type');

    // this.loadAllSubTotals();

    this.table.getTableData('account_type?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.accountType = result.Data;
        // console.log('type: ' + JSON.stringify(this.accountType));
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error loading Account Types @@errorLoadingAccountTypes:Error loading Account Types`);
        console.log(error);
      },
      () => {
        // this.accountSubTotalForm.patchValue(this.accountSubTotal);
      });

    this.table.getApiListData('account_subtotal/parents?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        const data: any = result.Data;
        this.subTotalList = data;
        this.subTotalCount = this.subTotalList.length;
        // console.log('parents only: ' + JSON.stringify(this.subTotalList));
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error loading Subtotal Account @@errorLoadingSubtotalAccount:Error loading Subtotal Account`);
        console.log(error);
      },
      () => {
      }
    );
  }

  saveModal() {
    if (this.onSave()) {
      // this.recSaved('sub-saved');
      this.resetForm();
      // this.loadModalFormValues();
    }
  }

  onSave() {
    this.submitted = true;
    if (this.accountSubTotalForm.invalid) {
      this.notification.error($localize`:Form Errors @@formErrors:Form Errors!`);
      return;
    }
    // check the cb vals and set to a false if null provided by form - due to setting init value to false has bug in ng
    if (this.accountSubTotalForm.get('active').value == null) {
      this.accountSubTotalForm.patchValue({active: false});
    }

    // push form values to post obj
    this.accountSubTotal = Object.assign({}, this.accountSubTotalForm.value);

    // api looking for string and the save below uses the passed in var versus form var
    if (this.accountSubTotal.id == null) {
      this.accountSubtotalID = null;
    }
    // console.log('formvals: ' + JSON.stringify(this.accountSubTotal));
    if (this.accountSubtotalID) {
      if (this.accountSubTotalForm.invalid === false) {
        this.table.patch('account_subtotal', this.accountSubtotalID, this.accountSubTotal).then(value => {
        console.log(value);
        this.recSaved('sub-saved');
        });

        // this.notification.success($localize`:Updated @@updated:Updated`);
        // this.loadModalFormValues();
        this.recSaved('sub-saved');
        return true;
      }
    } else {
      this.table.post('account_subtotal', this.accountSubTotal).subscribe(res => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        this.loadModalFormValues();
        this.recSaved('sub-saved');
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.accountSubtotalID = apiReturn.id;
      });
      // this.notification.success($localize`:Saved @@saved:Saved ` + this.accountSubTotal.name);
      // this.recSaved('sub-saved');
      return true;
    }
    this.submitted = false;
    return true;
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [$localize`:Confirmation for Delete Account Subtotal @@confirmDeleteAccountSubtotal:Are you sure you want to delete account subtotal?`, this.accountSubTotal.name];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('account_subtotal', this.accountSubtotalID).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            // this.onClose();
          });
      }, () => {
        this.notification.success($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }

  resetForm() {
    this.accountSubTotalForm.reset();
    this.f.type_id.enable();
  }

  onClose() {
    this.router.navigate(['admin/account-subtotal-list']);
  }
  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
