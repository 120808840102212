import {Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-payment-select',
  templateUrl: './payment-select.component.html',
  styleUrls: ['./payment-select.component.css']
})
export class PaymentSelectComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;

  @Output() updatePaymentEmit = new EventEmitter<string>();
  @Output() testPaymentEmit = new EventEmitter<string>();
  @Output() selectPaymentModalEmit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

  updatePayment() {
    this.updatePaymentEmit.emit();
  }

  testPayment() {
    this.testPaymentEmit.emit();
  }

  selectPayment(itemId) {
    // $('#inventoryItemSelect').modal('hide');
    this.selectPaymentModalEmit.emit(itemId);
  }
}
