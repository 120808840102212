import {AfterViewInit, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {AuthService} from '../../Services/auth.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AppConfigService} from '../../Services/app-config.service';
import {environment} from '../../../environments/environment';
import {StorageService} from '../../Services/storage.service';
import {SysUserModel} from '../../DataModels/sys-user-model';
import { Title } from '@angular/platform-browser';
import {BsToastService} from '../../Services/bs-toast-service';

@Component({
  selector: 'app-login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.css']
})


export class LoginComponent implements OnInit, AfterViewInit {
  version = environment.appVersion;

  // lee = 'Hide Tide Software, Inc.';
  // leenum = 123.45;
  // leestr = '';
  // lee1 = $localize`:used on the main page|explains who created the app@@created_by:Created by ${this.lee}`;
  // currencyPipeString: string;

  loading = false;
  submitted = false;
  testString: string;
  msgs = null;
  today: number = Date.now();

  public userInfo: SysUserModel;
  showPassword = false;

  loginForm: UntypedFormGroup;

  title = '';
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private authService: AuthService,
    private storage: StorageService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: BsToastService,
    private appConfig: AppConfigService,
    private titleService: Title,
    )
  {
    this.createForm();
    // this.currencyPipeString = this.currencyPipe.transform(this.leenum, 'USD');
    // currency and numbers - using formatNumber() can be made dynamic in this manner
    // this.leestr = formatCurrency(this.leenum, this.locale, getCurrencySymbol(getLocaleCurrencyCode(this.locale), 'wide', this.locale));
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['app-user', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    // console.log('Lee1:' + this.lee1);
    // console.log('LeeNum:' + this.leenum );
    // console.log('LeeStr:' + this.leestr );
    // so we dump dark theme on logout.
    // window.location.reload();
    // this.bsToastComponent.dispose(1);


    const tabTitle =  $localize`:accountXLogin: AccountX Login `;
    this.titleService.setTitle(tabTitle);
  }

  ngAfterViewInit() {
    const bodyThemeColor = this.appConfig.bodyThemeColor;
    // document.body.setAttribute('data-leftbar-theme', leftMenuColor);
    document.body.setAttribute('data-layout-color', bodyThemeColor);

    setTimeout(() => {
      // this._activateMenu();
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService.login(this.f.username.value, this.f.password.value).then();

    this.loading = false;
  }
}
