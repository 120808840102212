import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmailService } from '../../../../../Services/email.service';
import { EmailModel } from '../../../../../DataModels/email-model';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { NotificationService } from '../../../../../Services/notification.service';
import { DataExportService } from '../../../../../Services/dataExport.service';
import {EmailPassDataServiceService} from '../../../../../Services/email-pass-data-service.service';

@Component({
  selector: 'app-t4-report',
  templateUrl: './t4-report.component.html',
  styleUrls: ['./t4-report.component.css']
})

export class T4ReportComponent implements OnInit, AfterViewInit, AfterViewChecked {

  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public appConfigService: AppConfigService, public route: ActivatedRoute,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    public notification: NotificationService,
    public excelExporter: DataExportService,
    private emailData: EmailPassDataServiceService
  ) {

    this.timeOut = this.appConfigService.toastrWarnTimeOut;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = JSON.parse(this.localStorage.StorageGet('ToEmail', ''));

  }

  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;

  emailForm: UntypedFormGroup;
  private rptName: string;
  timeOut = 0;
  public urlParams: any = [];
  public emailInvalidMsg: string;

  public vm = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      currency_id: null,
      includeUnposted: null,
      entity_id: null,
      entity_type_id: null
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: ''
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    monthCount: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    Preferences: null,
    permissions: null,
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    companyId: null,
    currencyId: null,
    locationId: null,
    companyName: null,
    locationName: null,
    printLocation: null,
    printCompany: null,
    entity_type: [],
    entity: [],
    tempName: '',
    entityName: [],
    subLedger: null,
    reportBalance: '',
    reportCurrent: '',
    report1: '',
    report30: '',
    report60: '',
    report90: '',
    totalOwing: '',
    totalCurrent: '',
    total1to30: '',
    total31to60: '',
    total61to90: '',
    total91plus: '',
    entityOwing: '',
    entityCurrent: '',
    entity1: '',
    entity30: '',
    entity60: '',
    entity90: '',
    inventoryItemFilter: { search_string: null },
    payment: { total: 0 },
    startEntityName: 0,
    entity_email: '',
    entityInput: '',
    exportToExcel: true,
  };
  public submitted = false;



  ngOnInit() {
    for (const [ y , value ] of Object.entries(this.vm.entity)) {
      const tempName = this.vm.entity[y].name;
      this.vm.entityName.push({
        name: tempName
      });
    }

    if (this.urlParams.entityId) {
      for (const [ y , value ] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].id == this.urlParams.entityId) {
          this.vm.entityInput = this.vm.entity[y].name;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.entity_email = this.vm.entity[y].email;
          this.update();
          break;
        }
      }
    }

    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:T4 Report @@t4Report:T4 Report`;
    this.vm.subLedger = this.route.snapshot.paramMap.get('subLedger');
    // if (this.vm.subLedger === 'ap') {
    //   $('#payables').addClass('active');
    //   $('#ap-aging').addClass('active');
    // } else {
    //   $('#receivables').addClass('active');
    //   $('#ar-aging').addClass('active');
    // }
  }

  ngAfterViewChecked() {
    // alert('viewChecked fired');
    // sets data for email of report
    const div = document.getElementById('excel-export');

    // console.log(typeof div);
    // console.log(div.toString());
    // console.log(typeof div.outerHTML);
    // console.log('viewchk: ' + div.outerHTML);
     // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'T-4 Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);

  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, 'Payroll-Employee-Report.xlsx');
  }

  onSubmit() {
    this.emailForm.reset();
  }
  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.subLedger.toUpperCase() + ' ' + this.rptName + ' Report');
    // this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;

  }

  update() {

    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    let errorDescription = '';
    this.vm.fromDateError = this.vm.toDateError = this.vm.dateError = false;
    if (!this.vm.request.toDate) {
      errorDescription += $localize`:To Date is required. @@toDateisrequired:To Date is required.`+' <br/>';
      this.vm.toDateError = true;
    }
    if (!this.vm.request.fromDate) {
      errorDescription += $localize`:From Date is required. @@fromDateisrequired:From Date is required.`+' <br/>';
      this.vm.fromDateError = true;
    }
    if (tempToDate - tempFromDate < 0) {
      this.vm.dateError = true;
      errorDescription += $localize`:The To Date must be greater than the From Date. @@theToDatemustbegreaterthantheFromDate:The To Date must be greater than the From Date.`+' <br/>';
    }
    if (errorDescription) {
      this.notification.warning(errorDescription);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      const urlParamsJson = {
        toDate: this.vm.request.toDate,
        subLedger: this.vm.subLedger,
        entity_id: this.vm.request.entity_id,
        location_id: this.vm.request.location_id,
        company_id: this.vm.request.company_id,
        entity_type_id: this.vm.request.entity_type_id,
        currency_id: this.vm.request.currency_id,
      };

      const urlParams: string = this.encodeQueryData(urlParamsJson);
      this.tableDataService.getTableData('entry', '?' + urlParams).subscribe((result) => {
        this.vm.report = result;
        this.calcReportTotals();
        this.vm.showFilters = false;
        this.vm.buttonsDisabled = false;
        if (this.vm.entityInput) {
          this.vm.filtered = true;
        } else {
          this.vm.filtered = false;
        }
      });

    }
  }
  updateEntity() {
    this.vm.buttonsDisabled = true;
    this.localStorage.StorageSet('entity', '');
    this.tableDataService.getTableData('entry', '?state=active').subscribe((result) => {
      this.vm.entity = result;
      this.vm.buttonsDisabled = false;
    });

  }
  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id === '' || 1 == 1) {
      for (const [ y , value ] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.entity_email = this.vm.entity[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput == '') {
      this.vm.request.entity_id = '';
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.entityInput }).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].name;
        const entityEmail = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].email;
        if (entityId != '') {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
          this.vm.entity_email = entityEmail;
        } else {
          this.vm.request.entity_id = '';
          this.vm.entity_email = '';
        }
      } else {
        this.vm.request.entity_id = '';
        this.vm.entity_email = '';
      }
    }
    if (entityIdStart != this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }
  calcReportTotals() {
    for (const [ x , value ] of Object.entries(this.vm.report)) {
      for (const [ y , val ] of Object.entries (this.vm.report[x].invoices)) {
        this.vm.entityOwing += this.vm.report[x].invoices[y].owing;
        if (this.vm.report[x].invoices[y].days_late <= 0) {
          this.vm.entityCurrent += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 30) {
          this.vm.entity1 += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 60) {
          this.vm.entity30 += this.vm.report[x].invoices[y].owing;
        } else if (this.vm.report[x].invoices[y].days_late <= 90) {
          this.vm.entity60 += this.vm.report[x].invoices[y].owing;
        } else {
          this.vm.entity90 += this.vm.report[x].invoices[y].owing;
        }
      }
      this.vm.reportBalance += this.vm.entityOwing;
      this.vm.reportCurrent += this.vm.entityCurrent;
      this.vm.report1 += this.vm.entity1;
      this.vm.report30 += this.vm.entity30;
      this.vm.report60 += this.vm.entity60;
      this.vm.report90 += this.vm.entity90;
      this.vm.report[x].totalOwing = this.vm.entityOwing;
      this.vm.report[x].totalCurrent = this.vm.entityCurrent;
      this.vm.report[x].total1to30 = this.vm.entity1;
      this.vm.report[x].total31to60 = this.vm.entity30;
      this.vm.report[x].total61to90 = this.vm.entity60;
      this.vm.report[x].total91plus = this.vm.entity90;
    }
    this.vm.totalOwing = this.vm.reportBalance;
    this.vm.totalCurrent = this.vm.reportCurrent;
    this.vm.total1to30 = this.vm.report1;
    this.vm.total31to60 = this.vm.report30;
    this.vm.total61to90 = this.vm.report60;
    this.vm.total91plus = this.vm.report90;
  }

  calcTotals() {
    this.vm.payment.total = 0;
    for (const [ x , value ] of Object.entries(this.vm.report)) {
      this.vm.report[x].amountToPay = 0;
      if (this.vm.report[x].payAmount) {
        this.vm.report[x].amountToPay = this.vm.report[x].totalOwing;
        this.vm.payment.total += Number(this.vm.report[x].totalOwing);
      } else if (this.vm.report[x].payCurrent) {
        this.vm.report[x].amountToPay = this.vm.report[x].totalOwing;
        this.vm.payment.total += Number(this.vm.report[x].totalOwing);
      } else if (this.vm.report[x].pay1to30) {
        this.vm.report[x].amountToPay = this.vm.report[x].totalOwing - this.vm.report[x].totalCurrent;
        this.vm.payment.total += Number(this.vm.report[x].amountToPay);
      } else if (this.vm.report[x].pay31to60) {
        this.vm.report[x].amountToPay = this.vm.report[x].totalOwing - this.vm.report[x].totalCurrent - this.vm.report[x].total1to30;
        this.vm.payment.total += Number(this.vm.report[x].amountToPay);
      } else if (this.vm.report[x].pay61to90) {
        this.vm.report[x].amountToPay = this.vm.report[x].totalOwing - this.vm.report[x].totalCurrent - this.vm.report[x].total1to30 - this.vm.report[x].total31to60;
        this.vm.payment.total += Number(this.vm.report[x].amountToPay);
      } else if (this.vm.report[x].pay91plus) {
        this.vm.report[x].amountToPay = this.vm.report[x].total91plus;
        this.vm.payment.total += Number(this.vm.report[x].amountToPay);
      }
    }
  }

  FilterOptionsInit() {

    this.tableDataService.getTableData('company?state=active').subscribe((result: any[]) => {
      this.vm.company = result;
    });
    this.tableDataService.getTableData('currency?state=active').subscribe((result: any[]) => {
      this.vm.currency = result;
    });
    this.tableDataService.getTableData('location?state=active').subscribe((result: any[]) => {
      this.vm.location = result;
    });
    this.tableDataService.getTableData('entity?state=active').subscribe((result: any[]) => {
      this.vm.entity = result;
    });
    this.tableDataService.getTableData('entity_type?state=active').subscribe((result: any[]) => {
      this.vm.entity_type = result;
    });

    // if (Json.parse(this.localStorage.StorageGet('entity_type', '')) {
    //   this.vm.entity_type = JSON.parse(this.localStorage.StorageGet('entity_type', ''));
    // } else {
    //   this.localStorage.StorageSet('entity_type', this.vm.entity_type);
    // }
    // if (this.localStorage.StorageGet('entity', '')) {
    //   this.vm.entity = Json.parse(this.locaStorage.StorageGet('entity', ''));
    // } else {
    //   this.localStorage.StorageSet('entity', this.vm.entity);
    // }
    // if (this.localStorage.StorageGet('company', '')) {
    //   this.vm.company = JSON.parse(this.localStorage.StorageGet('company', ''));
    // } else {
    //   this.localStorage.StorageSet('company', this.vm.company);
    // }
    // if (this.localStorage.StorageGet('location', '')) {
    //   this.vm.location = JSON.parse(this.localStorage.StorageGet('location', ''));
    // } else {
    //   this.localStorage.StorageSet('location', this.vm.location);
    // }
    // if (this.localStorage.StorageGet('currency', '')) {
    //   this.vm.currency = JSON.parse(this.localStorage.StorageGet('currency', ''));
    // } else {
    //   this.localStorage.StorageSet('currency', this.vm.currency);
    // }

    if (this.urlParams.locationId) {
      this.vm.request.location_id = this.urlParams.locationId;
    }

    if (this.urlParams.currency_id) {
      this.vm.request.currency_id = this.urlParams.currency_id;
    }

    if (this.vm.company.length == 1) {
      this.vm.request.company_id = this.vm.company[0].id;
      this.vm.printCompany = this.vm.company[0].name;
    }
    if (this.vm.currency.length == 1) {
      this.vm.request.currency_id = this.vm.currency[0].id;
    }
    if (this.vm.location.length == 1) {
      this.vm.request.location_id = this.vm.location[0].id;
      this.vm.printLocation = this.vm.location[0].name;
    }


    if (this.vm.request.toDate && this.vm.request.fromDate
      && (this.urlParams.companyId
        || this.urlParams.currencyId
        || this.urlParams.locationId
      )
    ) {
      this.update();
    }


  }

  Print() {
    return window.print();
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }


  // update() {
  //   //console.log(this.vm.request);
  //   let errorDescription = '';
  //   this.vm.toDateError = this.vm.fromDateError = false;
  //   if (!this.vm.request.fromDate) {
  //     this.vm.dateError = true;
  //     errorDescription += 'A from date is required. ';
  //   }
  //   if (!this.vm.request.toDate) {
  //     this.vm.dateError = true;
  //     errorDescription += 'A To date is required. ';
  //   }

  //   let tempToDate: any = new Date(this.vm.request.toDate);
  //   let tempFromDate: any = new Date(this.vm.request.fromDate);
  //   if (tempToDate < tempFromDate) {
  //     errorDescription += 'From date must be less than the To date';
  //     this.vm.toDateError = true;
  //     this.vm.fromDateError = true;
  //   }
  //   if (errorDescription) {
  //     this.Notification(errorDescription, 'error');
  //   } else {
  //     this.vm.dateError = false;
  //     this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
  //     this.vm.buttonsDisabled = true;
  //     this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;

  //     const urlParamsJson = {
  //       currency_id: this.vm.request.currency_id,
  //       fromDate: this.vm.request.fromDate,
  //       toDate: this.vm.request.toDate,
  //       company_id: this.vm.request.company_id,
  //       location_id: this.vm.request.location_id,

  //     }

  //     const TransferRerpotParam: string = this.encodeQueryData(urlParamsJson);

  //     this.tableDataService.getTableData('TransferReport', '?' + TransferRerpotParam).subscribe((result) => {
  //       this.vm.report = result;
  //       this.vm.showFilters = this.vm.buttonsDisabled = false;
  //       this.vm.emailed = true;
  //       if ((this.vm.request.company_id && this.vm.company.length > 1)
  //         || this.vm.request.location_id
  //         || (this.vm.request.currency_id && this.vm.currency.length > 1)
  //       ) {
  //         this.vm.filtered = true;
  //       }
  //       //console.log(result);
  //     });
  //   }
  // }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  clear() {
    this.localStorage.StorageSet('defaultAccountId', '');
    this.vm.report = [];
    this.vm.request = {
      entity_type_id: null,
      entity_id: null,
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      currency_id: null,
      includeUnposted: null,
    };
    this.vm.filtered = false;
  }

  FilterObject(value, filterObj, test = false) {
    return new FilterPipe().transform(value, filterObj);
  }

}



