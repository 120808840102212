 // export class PrimeChartDataModel {
 export class PrimeChartDataModel {
   labels?: (string)[] | null;
   datasets?: DatasetsEntity[];

   // primeChartDataModel = new PrimeChartDataModel();
 }
 export class DatasetsEntity  {
   label: string;
   backgroundColor: string;
   data?: (number)[] | null;
 }

 // export interface Pokedex {
 //   labels:   string[];
 //   datasets: Dataset[];
 // }
 //
 // export interface Dataset {
 //   label:           string;
 //   backgroundColor: string;
 //   data:            number[];
 // }


