import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationGroupModel } from '../../../../DataModels/location-group-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-location-group-form',
  templateUrl: './location-group-form.component.html',
  styleUrls: ['./location-group-form.component.css'],
})
export class LocationGroupFormComponent implements OnInit {
  locationGroupID: string;
  locationGroup: LocationGroupModel;
  locationGroupForm: UntypedFormGroup;

  paid = false;
  showDelete = false;
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.locationGroupID = this.route.snapshot.params.id;
  }

  get f() {
    return this.locationGroupForm.controls;
  }

  createForm() {
    this.locationGroupForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Location Group @@locationGroup:Location Group`);
    if (this.locationGroupID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('location_Group', this.locationGroupID).subscribe(
      (result) => {
        this.locationGroup = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Location Access @@errorloadingLocationAccess:Error loading Location Access`);
        console.log(error);
      },
      () => {
        this.locationGroupForm.patchValue(this.locationGroup);
      }
    );
  }

  resetForm() {
    this.locationGroupForm.reset();
  }

  saveNew() {
    if (this.onSave(false)) {
      this.resetForm();
    }
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  onClose() {
    this.router.navigate(['admin/location-group-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete location group? @@areYouSureYouWantToDeleteLocationGroup:Are you sure you want to delete location group?`,
      this.locationGroup.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('location_Group', this.locationGroupID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.locationGroupForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.locationGroup = Object.assign({}, this.locationGroupForm.value);
    if (this.locationGroupID) {
      // TODO probably can move invalid out side if and capture both
      if (this.locationGroupForm.invalid === false) {
        this.table.patch('location_Group', this.locationGroupID, this.locationGroup).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('location_Group', this.locationGroup).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.locationGroupID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
