import {Component, OnInit, ViewChild} from '@angular/core';
import {TableDataService} from '../../../Services/tableData.service';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../../../Services/notification.service';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PermissionsService} from '../../../Services/permissions.service';
import {EntityDetailComponent} from '../resources/entity-detail/entity-detail.component';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.css']
})
export class EntityFormComponent implements OnInit {

  entityId;
  // Permissions
  Admin = true;
  EditReceivableEntities = false;
  EditPayableEntities = false;
  AllEntities = false;
  ReceivableAging = false;


  showNameAddress = false;
  showHistory = false;
  showActivityFeed = false;
  history: [];
  // isAddContact: true;
  // isItemIdValid: false;
  // historyRecentItems = 0;
  // itemdescriptionlength = 0;
  // entityNamelength = 0;
  // showMergeEntity = false;
  // showContactMerge = false;
  // isAddAddress = true;
  // addressItem = false;

  // Data Objects
  Entity;
  Location;
  // PaymentTypeList: Array<object>;
  // entityContactList: Array<object>;
  Term;
  TaxList;
  entityType;
  entityAddress;
  entityContact;
  auditTrail;
  receivable = 100;
  payable = 0;

// button stuff
  stopEdit = true;
  buttonsDisabled = false;
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public p: PermissionsService,
    private confirmDialogService: ConfirmDialogService,
    private notification: NotificationService,
    private titleService: Title) {
    this.entityId = this.route.snapshot.params.id;
  }

  @ViewChild(EntityDetailComponent, {static: false}) EntityDetail: EntityDetailComponent;

  ngOnInit() {
    this.titleService.setTitle($localize`:Entity@@entity:Entity`);
    this.showNameAddressClick();
    // todo don't think we need this anymore - don't use locs like that anymore - all user defined.
    // const regexpGUID: RegExp = /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
    // if (this.entityId && !regexpGUID.test(this.entityId)) {
    //   // if it is a GUID we can edit - means it was user entered. if not means it was a pre-loaded defined control location
    //   this.stopEdit = false;
    // }
    if (this.entityId) {
      this.buttonConfig.reset = false;
      this.stopEdit = false;
    }
    // this.isThisUsed(this.entityId, 'entity_id', 't_header');
  }

  async isThisUsed(idToCheck: string, columnToCheck: string, tableToCheck: string) {

    this.buttonsDisabled = false;

    let paramString = '';
    paramString += 'id=' + idToCheck;
    paramString += '&';
    paramString += 'columnName=' + columnToCheck;
    paramString += '&';
    paramString += 'tableName=' + tableToCheck;

    this.table.getTableDataWithParams('in_use', paramString).subscribe(
      result => {
        this.buttonsDisabled = (result.toString() == 'true');
        // console.log('button:' + this.buttonsDisabled);
        // console.log('res:' + result);
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error validating usage of item @@errorValidatingUsageOfItem:Error validating usage of item.`);
        console.log(error);
      },
      () => {
        // ending stuff here
      });
  }


  deleteItem(): void {
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete Entity? @@areYouSureYouWantToDeleteEntity:Are you sure you want to delete Entity?`, this.entityId];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('entity', this.entityId).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.close();
          });
      }, function() {
        this.notification.success($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }


  showNameAddressClick() {
    this.AllFalse();
    this.showNameAddress = true;
  }

  showHistoryClick() {
    this.AllFalse();
    // vm.getHistory()
    this.showHistory = true;
  }

  showActivityClick() {
    // vm.getActivityFeed()
    this.AllFalse();
    this.showActivityFeed = true;
  }

  AllFalse() {
    this.showNameAddress = false;
    this.showHistory = false;
    this.showActivityFeed = false;
  }


  save() {
    return this.EntityDetail.onSave();
  }

  saveNew() {
    if(this.save()) {
      this.close();
    }
  }

  async saveClose() {
    if(this.save()) {
      this.close();
    }
  }

  close() {
    this.router.navigate(['entity-list']);
  }
}
