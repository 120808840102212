import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-select-employee',
  templateUrl: './modal-select-employee.component.html',
  styleUrls: ['./modal-select-employee.component.css']
})
export class ModalSelectEmployeeComponent implements OnInit {
  @Input() vm: any;

  @Output() updateEmployeeEmit = new EventEmitter<string>();
  @Output() testEmployeeEmit = new EventEmitter<string>();
  @Output() selectEmployeeModalEmit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  updateEmployee() {
    this.updateEmployeeEmit.emit();
  }

  testEmployee() {
    this.testEmployeeEmit.emit();
  }

  selectEmployeeModal(itemId) {
    this.selectEmployeeModalEmit.emit(itemId);
  }

}
