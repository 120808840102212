export class FileUploadDataModel {
  fileName: string;
  fileExtension: string;
  fileType: string;
  fileIcon: string;
  id: string;
  header_id: string;
  deleted: boolean;
  displayThumbnail: boolean;
  fileData: string;
}
