import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportingFieldTypeModel } from '../../../../DataModels/reporting-field-type-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-reporting-field-type-form',
  templateUrl: './reporting-field-type-form.component.html',
  styleUrls: ['./reporting-field-type-form.component.css'],
})
export class ReportingFieldTypeFormComponent implements OnInit, AfterViewInit {
  vm: any = {};
  reportingFieldTypeId;
  reportingFieldType: ReportingFieldTypeModel;
  reportingFieldTypeForm: UntypedFormGroup;

  auditTrail: Array<object>;
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.createForm();
    this.reportingFieldTypeId = this.route.snapshot.params.id;
  }

  ngOnInit() {
    if (this.reportingFieldTypeId) {
      this.loadReportingFieldType();
      this.buttonConfig.reset = false;
    }
    this.vm.title = $localize`:Reporting Field Type @@reportingFieldType:Reporting Field Type`;
    this.vm.buttonsDisabled = false;
  }

  createForm() {
    this.reportingFieldTypeForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      plural_name: new UntypedFormControl(''),
      company_id: new UntypedFormControl(''),
      location_id: new UntypedFormControl(''),
      active: new UntypedFormControl('true'),
    });
  }

  loadReportingFieldType() {
    this.tableDataService.getTableData('reporting_field_type/' + this.reportingFieldTypeId).subscribe(
      (result) => {
        this.reportingFieldType = result;
        // console.log('result', result);
        // this.localStorage.StorageSet('formDataReportingFieldType', JSON.stringify(result));
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
        this.reportingFieldTypeForm.patchValue(this.reportingFieldType);
      }
    );
  }

  // loadAuditTrial() {
  //   this.tableDataService.getTableData('reporting_field_type/' + this.reportingFieldTypeId).subscribe(result => {
  //     this.auditTrail = result;
  //   },
  //   (error: HttpErrorResponse) => {
  //     console.log(error);
  //   });
  // }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.reportingFieldTypeForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete reporting field type? @@areYouSureYouWantToDeleteFieldType:Are you sure you want to delete reporting field type?`,
      this.reportingFieldType.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.tableDataService.deleteTableDataById('reporting_field_type', this.reportingFieldTypeId).subscribe(
          (result) => {
            retMsg = result;
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled@@deleted:Delete Canceled`);
      }
    );
  }

  resetForm() {
    this.reportingFieldTypeForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/reporting-field-type-list']).then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.reportingFieldTypeForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    let retVal = false;
    this.vm.buttonsDisabled = true;
    this.reportingFieldType = Object.assign({}, this.reportingFieldTypeForm.value);
    if (this.reportingFieldTypeId === undefined) {
      // new

      this.tableDataService.post('reporting_field_type', this.reportingFieldType).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.router.navigate(['admin/reporting-field-type-list']).then();
          this.localStorage.StorageSet('rfType', '');
          retVal = true;
          if (saveClose) {
            this.onClose();
          }
        },
        (error: HttpErrorResponse) => {
          // this.toast.error(error.message, $localize`:Error creating new Reporting Field Type @@errorcreatingnewReportingFieldType:Error creating new Reporting Field Type`, {
          //   timeOut: 4000
          // });
          console.log(error);
          retVal = false;
        }
      );
    } else {
      // edit
      this.tableDataService.patch('reporting_field_type', this.reportingFieldTypeId, this.reportingFieldType).then(
        (item: any) => {
          //  this.notification.success($localize`:Updated@@updated:Updated`);
          this.router.navigate(['admin/reporting-field-type-list']).then();
          this.localStorage.StorageSet('rfType', '');
          retVal = true;
          if (saveClose) {
            this.onClose();
          }
        },
        (error: HttpErrorResponse) => {
          // this.toast.error(error.message, $localize`:Error updating Reporting Field Type @@errorupdatingReportingFieldType:Error updating Reporting Field Type`, {
          //   timeOut: 4000
          // });
          console.log(error);
          retVal = false;
        }
      );
    }
    this.submitted = false;
    return true;
  }
}
