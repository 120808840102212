import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../Services/tableData.service';
import { AppConfigService } from '../../../../Services/app-config.service';
import { StorageService } from '../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { CustomFilterPipe } from '../../../admin/custom-filter.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmailService } from '../../../../Services/email.service';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { DataExportService } from '../../../../Services/dataExport.service';
import { PermissionsService } from '../../../../Services/permissions.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { RoutingTrackerService} from '../../../../Services/routing-tracker.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {EmailModel} from '../../../../DataModels/email-model';
import {Modal} from 'bootstrap';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';


@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.css'],
})
export class TransactionDetailComponent implements OnInit, AfterViewInit, AfterViewChecked {
  // @ViewChild('EmailContent', {static: false}) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;
  @ViewChildren('EmailContent') EmailContent: QueryList<ElementRef>;

  // fromDateEqualToDate: Date;
  reportToAttachToEmail = '';
  // string;
  // emailForm: UntypedFormGroup;
  timeOut = 0;
  public urlParams: any = [];
  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/dd/yyyy',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      location_group_id: null,
      columns: '',
      company_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      margin_id: null,
      location_id: null,
      accountInput: null,
      accountParentId: null,
      accountSubtotalId: null,
      accountTypeId: null,
      dataType: null,
      account_id: null,
      account_parent_id: null,
      account_subtotal_id: null,
      account_type_id: null,
      bank_id: null,
      entity_id: null,
      entity_city: null,
      entity_state: null,
      entity_type_id: null,
      item_id: null,
      payment_type_id: null,
      currency_id: null,
      includeUnposted: null,
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: '',
    },
    exportToExcel: true,
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    location_group: [],
    rfType: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    margin: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    monthCount: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    Preferences: null,
    permissions: null,
    currentMonthMargin: 0,
    priorMonthMargin: 0,
    currentPeriodMargin: 0,
    priorPeriodMargin: 0,
    account: [],
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    caclutedDate: null,
    toDateTitle: null,
    fromDateTitle: null,
    priorToDateTitle: null,
    priorToDateLink: null,
    priorMonthFromDateLink: null,
    currentMonthFromDateLink: null,
    priorFromDateLink: null,
    priorFromDateTitle: null,
    accountParents: [],
    accountSubtotal: [],
    bank: [],
    entity: [],
    entityAddress: [],
    entityType: [],
    paymentType: [],
    inventoryItem: [],
    accountType: [],
    inventoryItemName: [],
    entityState: [],
    entityCity: [],
    accountName: [],
    accountInput: null,
    accountId: null,
    bankId: null,
    companyId: null,
    currencyId: null,
    entityId: null,
    entityTypeId: null,
    locationId: null,
    locationGroupId: null,
    paymentTypeId: null,
    rfId1: null,
    rfId2: null,
    rfId3: null,
    rfId4: null,
    rfId5: null,
    marginId: null,
    accountTypeName: null,
    accountParentName: null,
    accountSubtotalName: null,
    dataType: null,
    bankName: null,
    companyName: null,
    locationName: null,
    locationGroupName: null,
    paymentTypeName: null,
    rf1Name: null,
    rf2Name: null,
    rf3Name: null,
    rf4Name: null,
    rf5Name: null,
    startAccount: null,
    entityInput: null,
    inventoryItemInput: '',
    entityName: [],
    entityTypeName: null,
    entityCityName: null,
    entityStateName: null,
    accountFilter: [],
    entityFilter: { name: '' },
    inventoryItemFilter: { search_string: '' },
    user: [],
    startInventoryItem: 0,
    startEntity: 0,
    paymentFilter: { search_string: '' },
  };

  buttonConfig: any = {
    reportFormat: false,
    print: true,
    email: true,
    update: true,
    exportExcel: true,
    close: true,
  };
  reportDataToSendInEmail: EmailModel = new EmailModel();

  rptName: string;

  isLoading = true;

  noDataFound = false;
  routedFrom = '';

  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public appConfigService: AppConfigService,
    public route: ActivatedRoute,
    public router: Router,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    public notification: BsToastService,
    private cd: ChangeDetectorRef,
    public excelExporter: DataExportService,
    public cFilter: CustomFilterPipe,
    public datePipe: DatePipe,
    public permissions: PermissionsService,
    public prevUrl: RoutingTrackerService,
    public isFiltered: IsFilterOnService
  ) {

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined && prevUrl2 !== '') {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  ngOnInit() {
    // this.urlParams = this.route.snapshot.queryParams;

    this.reportToAttachToEmail = $localize`:AcctX Transaction Details Report @@acctXTransactionDetailsReport:AcctX Transaction Details Report`;

    this.FilterOptionsInit().then();

    if (this.vm.request.toDate && !this.vm.request.fromDate) {
      const date = new Date(this.vm.request.toDate);
      const fromdate = new Date(date.getFullYear(), date.getMonth(), 1);
      this.vm.request.fromDate = fromdate;
      // console.log(fromdate);
    }

    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);

    this.rptName = $localize`:Transaction Details Report @@transactionDetailsReport:Transaction Details Report`;
  }

  exportToExcel() {
    const currentDate = new Date().toDateString();
    const table = this.ExcelExport.nativeElement;

    this.excelExporter.exportToExcel(table, 'TransDetailReport' + currentDate + '.xlsx', currentDate);
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);

    this.cd.detectChanges();
    // this.EmailContent.forEach((div: ElementRef) => console.log(div.nativeElement));
  }

  ngAfterViewChecked() {
  }

  setEmailData() {
    // do stuff to send email to via senemail modal inoput
    this.getReportData();
    const element = document.getElementById('sendEmailModal') as HTMLElement;
    const emailModal = new Modal(element);
    emailModal.show();
  }

  getReportData() {
    const emailDocObj = {} as EmailModel;
    const reportName = 'Transaction Detail Report';

    emailDocObj.fromEmail = '';
    emailDocObj.fromName = 'AcctXErp';
    emailDocObj.header_id = '';
    emailDocObj.toEmail = '';
    emailDocObj.body = 'Please see the attached ' + reportName + '.<br><br><br>'
      + 'Best regards,'
      + '<br><br>'
      + 'AcctXErp'
      + '<br><br>';
    emailDocObj.Subject = 'AcctXErp ' + reportName;
    emailDocObj.pdfHtml = this.ExcelExport.nativeElement.outerHTML;
    emailDocObj.activity_message = reportName;

    this.reportDataToSendInEmail = emailDocObj;
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    // console.log(JSON.stringify(ret.fromDateSelect) + '--' + JSON.stringify(ret.toDateSelect));
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  async FilterOptionsInit() {
    this.urlParams = this.route.snapshot.queryParams;
    this.vm.company = await this.getFilterValuesWithPaging('company', '?state=active');
    this.vm.currency = await this.getFilterValuesWithPaging('currency', '?state=active');
    this.vm.location = await this.getFilterValuesWithPaging('location', '?state=active');
    this.vm.accountType = await this.getFilterValuesWithPaging('account_type', '?state=active');

    if (this.urlParams.locationId) {
      this.vm.request.location_id = this.urlParams.locationId;
    }

    this.vm.locationGroup = await this.getFilterValuesWithPaging('location_group', '?state=active');
    this.vm.margin = await this.getFilterValuesWithPaging('account_margin', '?state=active');

    if (this.urlParams.marginId) {
      this.vm.request.margin_id = this.urlParams.marginId;
    }

    this.vm.account = await this.getFilterValuesWithPaging('account', '?state=active');
    // console.log('account data fecthed: ' + JSON.stringify(this.vm.account));
    this.vm.accountName = [];
    let tempNameAccount: string;
    for (const [y, value] of Object.entries(this.vm.account)) {
      tempNameAccount = this.vm.account[y].number + ' ' + this.vm.account[y].name;
      this.vm.accountName.push({
        name: tempNameAccount,
      });
    }

    if (this.urlParams.accountId) {
      this.vm.request.account_id = this.urlParams.accountId;
      for (const [y, value] of Object.entries(this.vm.account)) {
        if (this.vm.account[y].id == this.vm.request.account_id) {
          this.vm.accountInput = this.vm.account[y].number + ' ' + this.vm.account[y].name;
        }
      }
    }

    this.vm.accountParents = await this.getFilterValuesWithPaging('account_subtotal', '?state=active');
    // console.log('account sub data fecthed: ' + JSON.stringify(this.vm.accountParents));

    if (this.urlParams.accountParentId) {
      this.vm.request.accountParentId = this.urlParams.accountParentId;
    }

    this.vm.accountSubtotal = await this.getFilterValuesWithPaging('account_subtotal_no_parents', '?state=active');
    // console.log('account sub no parents data fecthed: ' + JSON.stringify(this.vm.accountSubtotal));
    if (this.urlParams.accountSubtotalId) {
      this.vm.request.accountSubtotalId = this.urlParams.accountSubtotalId;
    }

    this.vm.bank = await this.getFilterValuesWithPaging('bank', '?state=active');

    this.vm.entity = await this.getFilterValuesWithPaging('entity', '?state=active');
    this.vm.entityName = [];
    for (const [x, value] of Object.entries(this.vm.entity)) {
      this.vm.entityName.push({ name: this.vm.entity[x].name });
    }

    this.vm.entityAddress = await this.getFilterValuesWithPaging('entity_address', '?state=active');
    this.vm.entityCity = [];
    this.vm.entityState = [];

    for (const [x, value] of Object.entries(this.vm.entityAddress)) {
      if (
        this.FilterObject(this.vm.entityCity, { name: this.vm.entityAddress[x].city }, true)[0] === undefined &&
        this.vm.entityAddress[x].city
      ) {
        this.vm.entityCity.push({
          name: this.vm.entityAddress[x].city,
          id: this.vm.entityAddress[x].id
        });
      }
    }

    for (const [x, value] of Object.entries(this.vm.entityAddress)) {
      if (
        this.FilterObject(this.vm.entityState, { name: this.vm.entityAddress[x].state }, true)[0] === undefined &&
        this.vm.entityAddress[x].state
      ) {
        this.vm.entityState.push({
          name: this.vm.entityAddress[x].state,
          id: this.vm.entityAddress[x].id,
        });
      }
    }

    this.vm.entityType = await this.getFilterValuesWithPaging('entity_type', '?state=active');

    this.vm.paymentType = await this.getFilterValuesWithPaging('payment_type', '?state=active');

    this.vm.inventoryItem = await this.getFilterValuesWithPaging('item', '?state=active');

    this.vm.inventoryItemName = [];
    for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
      this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
    }

    this.vm.rfType = await this.getFilterValuesWithPaging('reporting_field_type', '?state=active');
    if (this.urlParams.rfId1) {
      this.vm.request.rf_id_1 = this.urlParams.rfId1;
    }

    this.vm.rf1 = await this.getFilterValuesWithPaging('reporting_field', '?state=active&type_id=rf1');

    this.vm.rf2 = await this.getFilterValuesWithPaging('reporting_field', '?state=active&type_id=rf2');
    if (this.urlParams.rfId2) {
      this.vm.request.rf_id_2 = this.urlParams.rfId2;
    }

    this.vm.rf3 = await this.getFilterValuesWithPaging('reporting_field', '?state=active&type_id=rf3');
    if (this.urlParams.rfId3) {
      this.vm.request.rf_id_3 = this.urlParams.rfId3;
    }

    this.vm.rf4 = await this.getFilterValuesWithPaging('reporting_field', '?state=active&type_id=rf4');
    if (this.urlParams.rfId4) {
      this.vm.request.rf_id_4 = this.urlParams.rfId4;
    }

    this.vm.rf5 = await this.getFilterValuesWithPaging('reporting_field', '?state=active&type_id=rf5');
    if (this.urlParams.rfId5) {
      this.vm.request.rf_id_5 = this.urlParams.rfId5;
    }

    if (this.urlParams.accountTypeId) {
      this.vm.request.accountTypeId = this.urlParams.accountTypeId;
    }

    if (this.urlParams.dataType) {
      this.vm.request.dataType = this.urlParams.dataType;
    } else {
      this.vm.request.dataType = '';
    }

    this.getFromToDates();
    this.isLoading = false; // done loading
    // this.testAccount();
    // this.testEntity();
    // this.testItem();

    if (
      this.vm.request.toDate &&
      this.vm.request.fromDate &&
      (this.urlParams.accountId ||
        this.urlParams.accountParentId ||
        this.urlParams.accountSubtotalId ||
        this.urlParams.accountTypeId ||
        this.urlParams.bankId ||
        this.urlParams.companyId ||
        this.urlParams.currencyId ||
        this.urlParams.dataType === 'budget' ||
        this.urlParams.entityId ||
        this.urlParams.entityTypeId ||
        this.urlParams.entityState ||
        this.urlParams.entityCity ||
        this.urlParams.locationId ||
        this.urlParams.locationGroupId ||
        this.urlParams.paymentTypeId ||
        this.urlParams.rfId1 ||
        this.urlParams.rfId2 ||
        this.urlParams.rfId3 ||
        this.urlParams.rfId4 ||
        this.urlParams.rfId5 ||
        this.urlParams.marginId)
    ) {
      this.update();
    }
  }

  getFromToDates() {
    if (this.urlParams.fromDate) {
      this.vm.request.fromDate = this.urlParams.fromDate;
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth() - 1, 1);
      d.setDate(d.getDate());
      this.vm.request.fromDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
    }

    // if (this.urlParams.toDate) {
    //   let d = new Date();
    //   // need to set this to toDate so they are equal - messy but works
    //   const pieces = this.urlParams.toDate.split('/', 3);
    //   d = new Date(Number(pieces[2]), Number(pieces[0]) - 1, 1);
    //   d.setDate(d.getDate());
    //   this.vm.request.fromDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
    // }

    if (this.urlParams.toDate) {
      this.vm.request.toDate = this.urlParams.toDate;
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      d.setDate(d.getDate());
      this.vm.request.toDate = new DatePipe('en-US').transform(d, 'MM/dd/yyyy');
    }
  }

  Print() {
    return window.print();
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  update() {
    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    let errorDescription = '';
    this.vm.fromDateError = this.vm.toDateError = this.vm.dateError = false;
    this.vm.accountTypeName = this.vm.accountParentName = this.vm.accountSubtotalName = '';
    if (!this.vm.request.toDate) {
      errorDescription += $localize`:To Date is required. @@toDateisrequired:To Date is required.` + '\n';
      this.vm.toDateError = true;
    }
    if (!this.vm.request.fromDate) {
      errorDescription += $localize`:From Date is required. @@fromDateisrequired:From Date is required.` + '\n';
      this.vm.fromDateError = true;
    }
    if (tempToDate - tempFromDate < 0) {
      this.vm.dateError = true;
      errorDescription +=
        $localize`:The To Date must be greater than the From Date. @@theToDatemustbegreaterthantheFromDate:The To Date must be greater than the From Date.` +
        '\n';
    }
    if (errorDescription) {
      const arr = errorDescription.split('\n');
      arr.forEach((item) => {
        if (item) {
          this.notification.showWarningToast(item);
        }
      });
    } else {
      // this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      // this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      this.vm.filtered = false;
      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      // this.vm.buttonsDisabled = true;
      const urlParamsJson = {
        // fromDate: this.datePipe.transform(new Date(this.vm.request.fromDate), 'yyyy-MM-dd').toString(),
        // toDate: this.datePipe.transform(new Date(this.vm.request.toDate), 'yyyy-MM-dd').toString(),
        fromDate: new Date(this.vm.request.fromDate).toISOString(),
        toDate: new Date(this.vm.request.toDate).toISOString(),
        account_id: this.vm.request.account_id,
        account_parent_id: this.vm.request.account_parent_id,
        account_subtotal_id: this.vm.request.account_subtotal_id,
        account_type_id: this.vm.request.account_type_id,
        bank_id: this.vm.request.bank_id,
        company_id: this.vm.request.company_id,
        entity_id: this.vm.request.entity_id,
        entity_city: this.vm.request.entity_city,
        entity_state: this.vm.request.entity_state,
        entity_type_id: this.vm.request.entity_type_id,
        item_id: this.vm.request.item_id,
        location_id: this.vm.request.location_id,
        location_group_id: this.vm.request.location_group_id,
        payment_type_id: this.vm.request.payment_type_id,
        rf_id_1: this.vm.request.rf_id_1,
        rf_id_2: this.vm.request.rf_id_2,
        rf_id_3: this.vm.request.rf_id_3,
        rf_id_4: this.vm.request.rf_id_4,
        rf_id_5: this.vm.request.rf_id_5,
        dataType: this.vm.request.dataType,
        margin_id: this.vm.request.margin_id,
      };

      const urlParams: string = this.encodeQueryData(urlParamsJson);
      // console.log('params: ' + urlParams);
      this.isLoading = true;
      this.tableDataService.getTableData('transaction_detail', '?' + urlParams).subscribe((result) => {
        this.vm.report = result;
        this.isLoading = false;
        if (!this.vm.report.length) {
          this.noDataFound = true;
        } else {
          this.noDataFound = false;
        }
        // console.log('rpt data: ' + JSON.stringify(this.vm.report));
        this.vm.showFilters = this.vm.buttonsDisabled = false;

        this.vm.filtered = this.isFiltered.isFiltered(this.vm);

        // if (
        //   this.vm.request.account_id ||
        //   this.vm.request.account_type_id ||
        //   this.vm.request.account_subtotal_id ||
        //   this.vm.request.account_parent_id ||
        //   this.vm.request.bank_id ||
        //   (this.vm.request.company_id && this.vm.company.length > 1) ||
        //   this.vm.request.location_id ||
        //   (this.vm.request.currency_id && this.vm.currency.length > 1) ||
        //   this.vm.request.rf_id_1 ||
        //   this.vm.request.rf_id_2 ||
        //   this.vm.request.rf_id_3 ||
        //   this.vm.request.rf_id_4 ||
        //   this.vm.request.rf_id_5 ||
        //   this.vm.request.margin_id ||
        //   this.vm.request.location_group_id ||
        //   this.vm.request.payment_type_id ||
        //   this.vm.request.entity_id ||
        //   this.vm.request.entity_type_id ||
        //   this.vm.request.entity_state ||
        //   this.vm.request.entity_city ||
        //   this.vm.request.dataType === 'budget'
        // ) {
        //   this.vm.filtered = true;
        // }

        if (this.vm.request.dataType) {
          this.vm.dataType = this.vm.request.dataType;
        } else {
          this.vm.dataType = '';
        }

        if (this.vm.request.account_type_id) {
          this.vm.accountTypeName = this.FilterObject(this.vm.accountType, {
            id: this.vm.request.account_type_id,
          })[0].name;
        } else if (this.vm.request.account_parent_id) {
          this.vm.accountParentName = this.FilterObject(this.vm.accountParents, {
            id: this.vm.request.account_parent_id,
          })[0].name;
        } else if (this.vm.request.account_subtotal_id) {
          this.vm.accountSubtotalName = this.FilterObject(this.vm.accountSubtotal, {
            id: this.vm.request.account_subtotal_id,
          })[0].name;
        }

        if (this.vm.request.bank_id) {
          this.vm.bankName = this.FilterObject(this.vm.bank, { id: this.vm.request.bank_id })[0].name;
        } else {
          this.vm.bankName = '';
        }

        if (this.vm.request.company_id) {
          this.vm.companyName = this.FilterObject(this.vm.company, { id: this.vm.request.company_id })[0].name;
        } else {
          this.vm.companyName = '';
        }

        if (this.vm.request.location_id) {
          this.vm.locationName = this.FilterObject(this.vm.location, { id: this.vm.request.location_id })[0].name;
        } else {
          this.vm.locationName = '';
        }

        if (this.vm.request.location_group_id) {
          this.vm.locationGroupName = this.FilterObject(this.vm.locationGroup, { id: this.vm.request.location_group_id })[0].name;
        } else {
          this.vm.locationGroupName = '';
        }

        if (this.vm.request.payment_type_id) {
          this.vm.paymentTypeName = this.FilterObject(this.vm.paymentType, {
            id: this.vm.request.payment_type_id,
          })[0].name;
        } else {
          this.vm.paymentTypeName = '';
        }

        if (this.vm.request.rf_id_1) {
          this.vm.rf1Name = this.FilterObject(this.vm.rf1, { id: this.vm.request.rf_id_1 }, true)[0].name;
        } else {
          this.vm.rf1Name = '';
        }

        if (this.vm.request.rf_id_2) {
          this.vm.rf2Name = this.FilterObject(this.vm.rf2, { id: this.vm.request.rf_id_2 }, true)[0].name;
        } else {
          this.vm.rf2Name = '';
        }

        if (this.vm.request.rf_id_3) {
          this.vm.rf3Name = this.FilterObject(this.vm.rf3, { id: this.vm.request.rf_id_3 }, true)[0].name;
        } else {
          this.vm.rf3Name = '';
        }

        if (this.vm.request.rf_id_4) {
          this.vm.rf4Name = this.FilterObject(this.vm.rf4, { id: this.vm.request.rf_id_4 }, true)[0].name;
        } else {
          this.vm.rf4Name = '';
        }

        if (this.vm.request.rf_id_5) {
          this.vm.rf5Name = this.FilterObject(this.vm.rf5, { id: this.vm.request.rf_id_5 }, true)[0].name;
        } else {
          this.vm.rf5Name = '';
        }

      });
    }
  }

  // async getFilterValues(tablename, param) {
  //   let values;
  //   await this.tableDataService
  //     .getTableData(tablename + param)
  //     .toPromise()
  //     .then((result: any[]) => {
  //       // console.log('old tables get: ' + tablename);
  //       values = result;
  //     });
  //   return values;
  // }

  async getFilterValuesWithPaging(tablename, param) {
    let values;
    await this.tableDataService
      .getTableData(tablename + param + '&pageNumber=0&pageSize=999999')
      .toPromise()
      .then((result: ResponseModel) => {
        // console.log('tables get: ' + tablename);
        // console.log('values: ' + JSON.stringify(values));
        values = result.Data;
      });
    return values;
  }

  IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  selectAccount(accountId) {
    for (const [y, value] of Object.entries(this.vm.account)) {
      if (this.vm.account[y].id == accountId) {
        this.vm.request.account_id = this.vm.account[y].id;
        this.vm.accountInput = this.vm.account[y].number + ' ' + this.vm.account[y].name;
        this.vm.report = [];
        break;
      }
    }
  }

  testAccount() {
    let accountIdStart: any = '';
    if (this.vm.request.account_id !== undefined) {
      accountIdStart = this.vm.request.account_id;
    } else {
      accountIdStart = '';
    }
    if (!this.vm.accountInput || this.vm.accountInput == '' || this.vm.accountInput == null) {
      this.vm.request.account_id = '';
    } else {
      if (this.FilterObject(this.vm.account, { number_name: this.vm.accountInput }, true).length > 0) {
        this.vm.request.account_id = this.FilterObject(
          this.vm.account,
          { number_name: this.vm.accountInput },
          true
        )[0].id;
      } else {
        this.vm.request.account_id = '';
        this.vm.accountInput = '';
      }
    }
    if (accountIdStart != this.vm.request.account_id) {
      this.vm.report = [];
    }
  }

  updateAccount() {
    // this.vm.buttonsDisabled = true;
    this.vm.startAccount = 0;

    this.tableDataService
      .getTableData('account', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.account = data;
        this.vm.buttonsDisabled = false;
      });
  }

  updateEntity() {
    // this.vm.buttonsDisabled = true;
    this.vm.entityCity = this.vm.entityState = [];
    this.tableDataService.getTableData('entity', '?state=active').subscribe((result) => {
      this.vm.entity = result;
    });
    this.tableDataService.getTableData('entity_type', '?state=active').subscribe((result) => {
      this.vm.entityType = result;
    });
    this.tableDataService.getTableData('entity_address', '?state=active').subscribe((result) => {
      this.vm.entityAddress = result;

      this.vm.buttonsDisabled = false;

      for (const [x, value] of Object.entries(this.vm.entityAddress)) {
        if (
          this.FilterObject(this.vm.entityCity, { name: this.vm.entityAddress[x].city }, true)[0] !== undefined &&
          this.vm.entityAddress[x].city
        ) {
          this.vm.entityCity.push({
            name: this.vm.entityAddress[x].city,
          });
        }
      }
      for (const [x, value] of Object.entries(this.vm.entityAddress)) {
        if (
          this.FilterObject(this.vm.entityState, { name: this.vm.entityAddress[x].state })[0] !== undefined &&
          this.vm.entityAddress[x].state
        ) {
          this.vm.entityState.push({
            name: this.vm.entityAddress[x].state,
          });
        }
      }
    });
  }

  selectEntityModal(entityName) {
    this.vm.request.entity_id = '';
    if (this.vm.request.entity_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == entityName) {
          this.vm.entityInput = entityName;
          this.vm.request.entity_id = this.vm.entity[y].id;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEntity() {
    const entityIdStart = this.vm.request.entity_id;
    if (!this.vm.entityInput || this.vm.entityInput == '' || this.vm.entityInput == null) {
      this.vm.request.entity_id = '';
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.entityInput }).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.entityInput }, true)[0].name;
        if (entityId != '' || entityId != null) {
          this.vm.request.entity_id = entityId;
          this.vm.entityInput = entityName;
        } else {
          this.vm.request.entity_id = '';
        }
      } else {
        this.vm.request.entity_id = '';
      }
    }
    if (entityIdStart != this.vm.request.entity_id) {
      this.vm.report = [];
    }
  }

  updateInventoryItem() {
    this.vm.inventoryItemName = [];

    this.tableDataService
      .getTableData('item', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.inventoryItem = result.Data;
        for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
          this.vm.inventoryItemName.push({ name: this.vm.inventoryItem[x].search_string });
        }
      });
  }

  selectItemModal(itemId) {
    this.vm.request.item_id = itemId;
    for (const [x, value] of Object.entries(this.vm.inventoryItem)) {
      if (this.vm.inventoryItem[x].id == itemId) {
        this.vm.inventoryItemInput = this.vm.inventoryItem[x].code + ' ' + this.vm.inventoryItem[x].name;
        break;
      }
    }
  }

  testItem() {
    const itemIdStart = this.vm.request.item_id;
    if (!this.vm.inventoryItemInput || this.vm.inventoryItemInput == '' || this.vm.inventoryItemInput == null) {
      this.vm.request.item_id = '';
    } else {
      if (this.FilterObject(this.vm.inventoryItem, { search_string: this.vm.inventoryItemInput }, true).length > 0) {
        this.vm.request.item_id = this.FilterObject(
          this.vm.inventoryItem,
          { search_string: this.vm.inventoryItemInput },
          true
        )[0].id;
      } else {
        this.vm.request.item_id = '';
        this.vm.inventoryItemInput = '';
      }
    }
    if (itemIdStart != this.vm.request.item_id) {
      this.vm.report = [];
    }
  }

  close() {
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom === undefined) {
      window.close();
    } else {
      // should not ever get here
      this.router.navigateByUrl('/home').then();
    }
  }

  clear() {
    this.vm.accountInput = this.vm.entityInput = this.vm.inventoryItemInput = '';
    this.vm.report = [];
    this.vm.request = {
      toDate: null,
      fromDate: null,
      location_group_id: null,
      columns: '',
      company_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      margin_id: null,
      location_id: null,
      accountInput: null,
      accountParentId: null,
      accountSubtotalId: null,
      accountTypeId: null,
      dataType: null,
      account_id: null,
      account_parent_id: null,
      account_subtotal_id: null,
      account_type_id: null,
      bank_id: null,
      entity_id: null,
      entity_city: null,
      entity_state: null,
      entity_type_id: null,
      item_id: null,
      payment_type_id: null,
      currency_id: null,
      includeUnposted: null,
    };
    this.vm.filtered = false;
  }

  FilterObject(value, filterObj, actual = false) {
    return this.cFilter.transform(value, filterObj, actual);
  }
}
