import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from '../../../Services/storage.service';
import { TableDataService } from '../../../Services/tableData.service';
import {DatePipe, formatDate} from '@angular/common';
import {DateFilterService} from '../../../Services/dateFilter.service';
import {BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-home-manage-delinquent-receivables',
  templateUrl: './home-manage-delinquent-receivables.component.html',
  styleUrls: ['./home-manage-delinquent-receivables.component.css']
})
export class HomeManageDelinquentReceivablesComponent implements OnInit {

  // @Input() vm: any;
  vm: any = {};
  showDelinquentReceivablesHelp;
  todayDate: Date;
  formattedTodayDate = '';

  constructor(
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private datePipe: DatePipe,
    private notification: BsToastService,
    private dateFilter: DateFilterService) {
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    this.vm.editDelinquentReceivables = false;
    this.vm.delinquentReceivables = {};
   }

  ngOnInit() {
    if (this.vm.permissions.Admin || this.vm.permissions.ReceivableAging) {
      this.vm.daysLate = this.dateFilter.GetDateFormat(Date.now());
      this.queryDelinquentReceivables();
      this.todayDate = new Date();
      this.getTodaysDate();
    }
  }

  getTodaysDate() {
    this.formattedTodayDate = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
  }

  update() {
    this.notification.showSuccessToast('Updated');
    this.queryDelinquentReceivables();
  }
  queryDelinquentReceivables() {
      if (this.vm.daysLate) {
        this.vm.daysLate = this.datePipe.transform(this.vm.daysLate, 'MM/dd/yyyy');
        this.tableDataService.getTableData('aging?toDate=' + this.vm.daysLate + '&subLedger=ar&company_id=&entity_id=&entity_type_id=&includePaid=false&pastDueOnly=false').subscribe((result: any[]) => {
          this.vm.delinquentReceivables = result;

          for (const [x, value] of Object.entries(this.vm.delinquentReceivables)) {
            let total = 0;
            for (const [y, value2] of Object.entries(this.vm.delinquentReceivables[x].invoices)) {
              total += this.vm.delinquentReceivables[x].invoices[y].owing;
            }
            this.vm.delinquentReceivables[x].total = total;
          }
          const sorted = this.vm.delinquentReceivables.sort((a, b) => (a.total > b.total ? -1 : 1));
          this.vm.delinquentReceivables = sorted;
        });
      } else {
        this.notification.showErrorToast($localize`:Date required @@dateRequired:Date is required.`);
      }
  }

  goToNew(type: string, id: string, subLedger: string, date: string){
    window.open('/transaction-reports/aging/' + type + '?subLedger=' + subLedger + '&entity_id=' + id + '&toDate=' + date, '_blank');
  }

}
