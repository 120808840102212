import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TableDataService} from '../../../../Services/tableData.service';
import {DecimalPipe} from '@angular/common';
import {StorageService} from '../../../../Services/storage.service';
import {BsToastService} from '../../../../Services/bs-toast-service';


@Component({
  selector: 'app-entity-history',
  templateUrl: './entity-history.component.html',
  styleUrls: ['./entity-history.component.css']
})
export class EntityHistoryComponent implements OnInit {
  @Input() entityHistoryID: string;

  vm: any = {};
  searchReceivablePayments;
  searchReceivableInvoices;
  paid = false;
  t: any = [];
  t2 = 0;
  t3 = 0;
  t4 = 0;
  t5 = 0;


  constructor(
    private table: TableDataService,
    private toast: BsToastService,
    private localStorage: StorageService,
    private decimal: DecimalPipe) {

    this.vm.permissions = {};
    this.vm.searchRecentItems = {};
    this.vm.searchTopItems = {};
   }

  ngOnInit() {
    this.vm.history = {};
    this.vm.history.Pricing = [];
    this.vm.history.RecentItems = [];
    this.vm.history.TopItems = [];
    this.vm.history.Taxes = {};
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    if (this.entityHistoryID){
      this.loadHistory();
    }
  }

  loadHistory() {
    this.table.getTableData('entity_history', this.entityHistoryID).subscribe((result: any) => {
        this.vm.history = result;
        // console.log(this.vm.history);
      },
      (error: HttpErrorResponse) => {
        // this.toast.error(error.message, $localize`:Error loading balance @@errorloadingbalance:Error loading balance`, {
        //   timeOut: 4000
        // });
        console.log(error);
      },
      () => {
     });
  }
}
