import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {NotificationService} from '../../Services/notification.service';
import {finalize, first} from 'rxjs/operators';
import {TableDataService} from '../../Services/tableData.service';
import {AppConfigService} from '../../Services/app-config.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {

  // form: FormGroup;
  loading = false;
  submitted = false;

  resetPassswordForm!: UntypedFormGroup;
  formSubmitted: boolean = false;
  successMessage: string = '';

  today: number = Date.now();

  constructor(private fb: UntypedFormBuilder,
              private alertService: NotificationService,
              private tableDataService: TableDataService,
              private appConfig: AppConfigService) { }

  ngOnInit(): void {
    this.resetPassswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngAfterViewInit() {
    // const leftMenuColor = this.appConfig.leftMenuThemeColor;
    const bodyThemeColor = this.appConfig.bodyThemeColor;
    // document.body.setAttribute('data-leftbar-theme', leftMenuColor);
    document.body.setAttribute('data-layout-color', bodyThemeColor);

    setTimeout(() => {
      // this._activateMenu();
    });

    // document.body.setAttribute('data-layout-color', leftMenuColor);
  }

  getUser() {

  }


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.resetPassswordForm.controls;
  }

  get f() { return this.resetPassswordForm.controls; }

  /**
   * On form submit
   */
  onSubmit(): void {
    this.formSubmitted = true;
    if (this.resetPassswordForm.valid) {
      this.successMessage = 'We have sent you an email containing a link to reset your password';
    }

    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.resetPassswordForm.invalid) {
      return;
    }

    this.loading = true;
    // this.alertService.clear();
    const postVals: object = {
      email: this.f.email.value,
      requestedFrom: 'app'
    };
    // console.log('posting email to account/forgot-password: ' + this.f.email.value);
    this.tableDataService.post('identity/account/forgot-password', postVals)
      .pipe(first())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => this.alertService.success('Please check your email for password reset instructions'),
        error: error => this.alertService.error(error)
      });
  }
}
