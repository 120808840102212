import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { CustomFilterPipe } from '../../../admin/custom-filter.pipe';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService } from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-count-form',
  templateUrl: './count-form.component.html',
  styleUrls: ['./count-form.component.css'],
})
export class CountFormComponent implements OnInit, AfterViewInit {
  constructor(
    private decimalPipe: DecimalPipe,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private titleService: Title,
    private cFilterPipe: CustomFilterPipe,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.vm.title = $localize`:Inventory Counts @@inventoryCounts:Inventory Counts`;
    this.vm.item = {};
    this.vm.tempitems = {};
    this.vm.countSearch = null;
    this.vm.showAdjustmensOnly = false;
    this.vm.systemCount = [];
    this.vm.systemCount.locations = [];
    this.vm.systemCount.locations.items = [];

    this.vm.countId = this.route.snapshot.params.countId;
    this.vm.item.countId = this.vm.countId;
  }

  itemBalances = [];
  vm: any = {};
  isloaded: boolean;
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: false,
    locked: true,
    print: true,
  };

  companyError = false;
  locationError = false;
  countDateError = false;
  categoryError = false;
  countExists = false;
  itemsLoaded = false;

  detTot = 0;

  ngOnInit() {
    // this.route.queryParams.subscribe((params) => {
    //   // if (params.countId !== undefined) {
    //   this.vm.countId = params.countId;
    //
    //   // this.vm.showSpecificDates = true;
    //   // }
    // });

    if(this.vm.countId) {
      this.buttonConfig.reset = false;
    }

    // this.vm.countId = this.route.snapshot.params.countId;
    this.isloaded = false;
    this.vm.item.count = 0;
    this.vm.adjustment = {};

    this.vm.showZeroItems = true;
    this.vm.showAdjustments = true;

    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions', ''));
    if (!this.vm.permissions.EditCounts && !this.vm.permissions.ViewCounts && !this.vm.permissions.Admin) {
      document.location.href = '/';
    }

    this.vm.company = [];
    this.tableDataService
      .getApiListData('company', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data = result.Data;
        this.vm.company = data;
      });

    this.vm.currency = [];
    this.tableDataService
      .getApiListData('currency', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result;
      });

    this.vm.location = [];
    this.tableDataService
      .getApiListData('location', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.location = result.Data;
      });

    this.vm.category = [];
    this.tableDataService
      .getApiListData('item_category', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.category = result.Data;
      });

    // now go get any exist count passed in url
    if (this.vm.countId !== 'new') {
      this.loadExistingCount(this.vm.countId);

    } else {
      this.isloaded = true;
    }
  }

  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  initItems(categoryId: string) {
    // if (this.countExists) {
    //   this.countExists = true;
    //   return;
    // } else {
    this.vm.inventoryItem = [];
    this.tableDataService
      .getApiListData('item', '?state=active&pageNumber=0&pageSize=999999&category_id=' + categoryId)
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.inventoryItem = data;
        this.itemsLoaded = true;
        // console.log('inventory items which get copied to vm.item.count: ' + JSON.stringify(this.vm.inventoryItem));
      });
    // }
  }

  afterViewInit() {}

  loadExistingCount(id: string) {
    // console.log('in load existing count - id=: ' + id);

    // if (this.vm.countId != 'new') {
    this.tableDataService.getTableData('Count', id).subscribe((result: any) => {
      this.vm.item = result;
      this.vm.tempitems = this.vm.item; // for patch compare - don't really need to compare as api will handle
      // this.disableGetSystemCountButton = true;
      // console.log('loaded saved count from load existing count: ' + JSON.stringify(this.vm.item));
      // this.init();

      // format date so datepicker understands
      let fixDateForPicker: any;
      fixDateForPicker = new Date(this.vm.item.count_date);
      this.vm.item.count_date = fixDateForPicker;
      const checkLoaded = this.vm.item.count.filter((x) => x.physical_count > 0);

      // console.log('chk: ' + JSON.stringify(checkLoaded));
      if (checkLoaded.length > 0) {
        this.initItems(this.vm.item.category_id);
        this.calcAdjustment();
        this.isloaded = true;
      }
    });
  }

  clearCountItemsFromUi() {
    this.vm.item.count = [];
  }

  resetUiParamsHaveChanged() {
    this.vm.item.count = [];
    this.countExists = false;
    this.initItems(this.vm.item.category_id);
  }

  checkCountOpen() {
    // refetch zap anything present for items in case user has switched around UI
    const urlParamsJson = {
      // count_date: this.datePipe.transform(this.vm.item.count_date, 'MM/dd/yyyy'),
      count_date: new Date(this.vm.item.count_date).toISOString(),
      company_id: this.vm.item.company_id,
      location_id: this.vm.item.location_id,
      category_id: this.vm.item.category_id,
      // countId: this.vm.countId,
      adjustments_required: false,
      locked: true,
      pageNumber: 0,
      pageSize: 999999,
    };
    const sysCountParam: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getApiListData('Count/Open?' + sysCountParam).subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      // console.log('data exists: ' + data.length + ' data:' + JSON.stringify(data)  + ' and id of: ' + this.vm.countId);
      if (data.length > 0 && this.vm.countId === 'new') {
        this.notification.showWarningToast(
          'An open count exists for this company, location, and category. You must complete or delete that count first.'
        );
        this.countExists = true;
        this.clearCountItemsFromUi();
      }
    });
  }

  getSystemCount() {
    // category filter of inventory items
    // if (this.vm.item.category_id !== undefined && this.vm.item.category_id !== '') {

    // filter items down to those in the category selected
    // this.vm.item.count = this.FilterObject(this.vm.inventoryItem, {category_id: this.vm.item.category_id}, true);
    // console.log('count obj after filter:' + JSON.stringify(this.vm.item.count));

    // } // else {
    //   // use all items if no category selected
    //   this.vm.item.count = this.vm.inventoryItem;
    //   console.log('no category selected');
    // }

    if (this.countExists) {
      // console.log('count exist: ' + this.countExists);
      this.countExists = false;
      return;
    } else {
      // we have pulled items by cat so now stuff them into the vm.item.count array
      this.vm.item.count = this.vm.inventoryItem;

      this.vm.buttonsDisabled = true;
      const urlParamsJson = {
        // count_date: this.datePipe.transform(this.vm.item.count_date, 'MM/dd/yyyy'),
        count_date: new Date(this.vm.item.count_date).toISOString(),
        company_id: this.vm.item.company_id,
        location_id: this.vm.item.location_id,
        category_id: this.vm.item.category_id,
        // countId: this.vm.countId,
        // adjustmentsRequired: true,
        pageNumber: 0,
        pageSize: 999999,
      };
      // console.log('date: ' + new Date(this.vm.item.count_date).toISOString());
      const sysCountParam: string = this.encodeQueryData(urlParamsJson);
      this.tableDataService.getApiListData('count/system?' + sysCountParam).subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.systemCount = data;

        // console.log('system count data to mod: ' + JSON.stringify(this.vm.systemCount));

        // item list was pushed over to item.count, by item, above
        // now we have pulled system count data by item so loop thru and populate the fields we need
        // TODO move this to api at some point so we don't need to do this on client.
        for (const [y, value] of Object.entries(this.vm.item.count)) {
          this.vm.item.count[y].count = 0;
          for (const [x, val] of Object.entries(this.vm.systemCount)) {
            if (this.vm.systemCount[x].item_id == this.vm.item.count[y].id) {
              if (!this.vm.item.id && !this.vm.item.count[y].physical_count) {
                if (this.vm.systemCount[x].count < 0) {
                  this.vm.item.count[y].physical_count = this.vm.systemCount[x].count * -1;
                } else {
                  this.vm.item.count[y].physical_count = this.vm.systemCount[x].count;
                }
              }
              // this.vm.item.count[y].count = this.vm.systemCount[x].count;
              this.vm.item.count[y].count = Math.round(this.vm.systemCount[x].count * 1000) / 1000;
              this.vm.item.count[y].cost = this.vm.systemCount[x].cost;
              break;
            }
          }
        }
        this.vm.buttonsDisabled = false;
      });
    }
  }

  getItemHistory(item_id) {
    this.vm.itemHistoryId = item_id;
    this.vm.itemHistory = [];
    const urlParamsJson = {
      // count_date: this.datePipe.transform(this.vm.item.count_date, 'MM/dd/yyyy'),
      count_date: new Date(this.vm.item.count_date).toISOString(),
      company_id: this.vm.item.company_id,
      location_id: this.vm.item.location_id,
      item_id: item_id,
      pageNumber: 0,
      pageSize: 999999,
    };

    const CountParam: string = this.encodeQueryData(urlParamsJson);
    this.tableDataService.getTableData('Count/system/history', '?' + CountParam).subscribe((result: ResponseModel) => {
      const data: any = result.Data;
      this.vm.itemHistory = data;
    });
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  errorCheck() {
    this.companyError = !this.vm.item.company_id;
    this.locationError = !this.vm.item.location_id;
    this.countDateError = !this.vm.item.count_date;
    this.categoryError = !this.vm.item.category_id;
  }

  save() {
    const physCountItemExistsCheck = this.vm.item.count.filter((x) => x.physical_count > 0);
    if (physCountItemExistsCheck.length < 1) {
      this.notification.showWarningToast('Cannot save an empty count');
      return;
    }
    this.submitted = true;
    if (
      !this.vm.item.category_id ||
      !this.vm.item.company_id ||
      !this.vm.item.count_date ||
      !this.vm.item.location_id ||
      !this.vm.item.description
    ) {
      this.notification.showWarningToast('Form Errors!');
      return;
    }
    if (this.vm.item.id === undefined) {
      this.tableDataService.post('Count', this.vm.item).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.router.navigateByUrl('/inventory/count/form?countId=' + item.id).then();
          // this.vm.buttonsDisabled = true;
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error($localize`:Error @@error:Error:` + error.message);
          console.log('post:' + error.message);
        }
      );
    } else {
      // diff
      const changes: any = {};

      for (const key in this.vm.item) {
        if (this.vm.item.hasOwnProperty(key)) {
          const value = this.vm.item[key];
          // if (value != this.vm.tempitems[key])
          {
            changes[key] = value;
          }
        }
      }
      this.tableDataService.patch('Count', this.vm.item.id, changes).then(
        (item: any) => {
          this.vm.buttonsDisabled = false;
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          console.log('post: ' + error);
        }
      );
    }
  }

  postAdjustment() {
    if (!this.vm.adjustment.detail) {
      this.notification.showInfoToast('No Adjustment Required');
    } else {
      this.vm.buttonsDisabled = true;
      this.tableDataService.post('entry', this.vm.adjustment).subscribe(
        (item: any) => {
          // this.getSystemCount();
          // lock if post sucessful
          this.vm.item.locked = true;
          this.save();
        },
        (error) => {
          // this.notification.error('Entry Post Error');
          this.vm.buttonsDisabled = false;
          console.log('post adj: ' + error);
        }
      );
    }
  }

  lockOnGoodPostAdj(){


  }

  calcAdjustment() {
    const descText = $localize`:@@adjustText:Auto-generated Inventory Adjustment From Count`;
    this.vm.adjustment = {};
    this.vm.adjustment.batch = {};
    this.vm.adjustment.header = {};
    this.vm.adjustment.detail = [];
    // this.vm.adjustment.header.detail_total = 0;
    this.vm.adjustment.batch.type_id = 1; // send as JE but with item recs so inv routines pick up adj to item count
    this.vm.adjustment.header.header_date = this.vm.item.count_date;
    this.vm.adjustment.header.company_id = this.vm.item.company_id;
    this.vm.adjustment.header.location_id = this.vm.item.location_id;
    this.vm.adjustment.header.currency_id = this.vm.item.currency_id;
    // this.vm.adjustment.header.description = this.vm.item.description;
    this.vm.adjustment.header.description = descText;
    this.vm.adjustment.header.number = 'InvAdj' + new Date().toDateString();
    this.vm.adjustment.header.detail_total = this.detTot;

    for (let x = 0; x < this.vm.item.count.length; x++) {
      if (isNaN(this.vm.item.count[x].physical_count)) {
        this.vm.item.count[x].physical_count = '';
      }

      if (this.vm.item.count[x].count - this.vm.item.count[x].physical_count != 0 || this.vm.item.count[x].count < 0) {
        // console.log('calc adj walking this: ' + JSON.stringify(this.vm.item.count));

        let adjQuantity = 0.0;

        if (this.vm.item.count[x].count < 0) {
          adjQuantity = +this.vm.item.count[x].physical_count;
        } else {
          adjQuantity = (+this.vm.item.count[x].count - +this.vm.item.count[x].physical_count) * -1;
        }

        // if(this.vm.item.count[x].count > 0 ){
        //   adjQuantity = this.vm.item.count[x].physical_count - this.vm.item.count[x].count;
        // } else {
        //   // neg inv on hand in system
        //   adjQuantity = this.vm.item.count[x].physical_count + this.vm.item.count[x].count;
        // }

        let adjAmount = 0.00;
        let invAdjAmt = 0.00;
        let invAdjQuantity = 0.00;
        let invQuantity = 0.00;
        let invAmt = 0.00;

        // console.log('adj qty: ' + adjQuantity);

        if (adjQuantity < 0 && this.vm.item.count[x].count > 0) {
          invAdjAmt = adjQuantity * this.vm.item.count[x].cost * -1;
          // invAmt = (adjQuantity * this.vm.item.count[x].cost);
          invAmt = this.vm.item.count[x].cost;
          invQuantity = adjQuantity;
          // invAdjQuantity = adjQuantity * -1;
          invAdjQuantity = 1;
        } else if (adjQuantity > 0 && this.vm.item.count[x].count < 0) {
          invAdjAmt = adjQuantity * this.vm.item.count[x].cost * -1;
          // invAmt = adjQuantity * this.vm.item.count[x].cost;
          invAmt = this.vm.item.count[x].cost;
          invQuantity = adjQuantity;
          // invAdjQuantity = adjQuantity * -1;
          invAdjQuantity = 1.00;
        } else {
          invAdjAmt = adjQuantity * this.vm.item.count[x].cost * -1;
          // invAmt = adjQuantity * this.vm.item.count[x].cost;
          invAmt = this.vm.item.count[x].cost;
          invQuantity = adjQuantity;
          // invAdjQuantity = adjQuantity;
          invAdjQuantity = 1.00;
        }

        // adjAmount = this.vm.item.count[x].cost * +adjQuantity;

        if (this.vm.item.count[x].count && this.vm.item.count[x].count != 0) {
          // adjAmount = Math.abs(Math.round(this.vm.item.count[x].cost / this.vm.item.count[x].count * 100) / 100);
          adjAmount = this.vm.item.count[x].cost * +adjQuantity;
        } else {
          adjAmount = this.vm.item.count[x].cost;
        }
        // this.vm.adjustment.header.detail_total = invAmt;
        // console.log('inv amt: ' + invAmt);
        if (invAmt > 0) {
          this.detTot += invAmt;
        } else if (invAmt < 0) {
          this.detTot += invAmt * -1;
        }

        // const adjDebitCredit = Math.round(adjAmount * adjQuantity * 100) / 100;
        const adjDebitCredit = invAdjAmt;
        // const invDebitCredit = invAmt;

        // if (adjDebitCredit != 0 && invDebitCredit != 0) {
        this.vm.adjustment.detail.push({
          item_id: this.vm.item.count[x].item_id,
          // account_id: this.vm.item.count[x].inventory_account_id,
          account_id: 'in',
          quantity: this.decimalPipe.transform(invQuantity, '1.2-2'),
          amount: this.decimalPipe.transform(invAmt, '1.2-2'),
          // debit_credit: invDebitCredit,
          debit_credit: this.decimalPipe.transform(adjQuantity * this.vm.item.count[x].cost, '1.2-2'),
          sort_order: x,
        });
        this.vm.adjustment.detail.push({
          item_id: this.vm.item.count[x].item_id,
          account_id: 'in-adj',
          quantity: this.decimalPipe.transform(invAdjQuantity, '1.2-2'),
          amount: this.decimalPipe.transform(invAdjAmt, '1.2-2'),
          debit_credit: this.decimalPipe.transform(adjDebitCredit, '1.2-2'),
          control_line: true,
          sort_order: x,
        });
        // }
      }
      this.vm.adjustment.header.detail_total = this.decimalPipe.transform(this.detTot, '1.2-2');
    }
    // console.log('adj obj: ' + JSON.stringify(this.vm.adjustment));
  }

  deleteCount() {
    this.vm.buttonsDisabled = true;
    const params = {
      id: this.vm.item.id,
      tablename: 'Count',
    };
    this.tableDataService.deleteTableDataById('Count', this.vm.item.id).subscribe(
      (result: any) => {
        this.vm.buttonsDisabled = false;
        this.router.navigateByUrl('/inventory/count/list').then();
      },
      (error) => {
        this.vm.buttonsDisabled = false;
        console.log('delete:' + error.description);
      }
    );
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  onClose() {
    this.router.navigate(['/inventory/count/list']).then();
  }

  deleteItem() {
    if (this.vm.countId != 'new') {
      const brClass = this;
      // let retMsg = '';
      const messages: string[] = [
        $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
        '',
      ];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.deleteCount();
        },
        () => {
          brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
        }
      );
    }
  }

  postAdjustingEntryVerify() {
    // if (this.vm.countId != 'new') {
    const brClass = this;
    // let retMsg = '';
    const messages: string[] = [
      $localize`:Continue posting GL entry for Inventory Adjustments and lock Count? @@continuePostingGLEntryForInventoryAdjustmentsAndLockCount?:Continue posting GL entry for Inventory Adjustments and lock Count?`,
      '',
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.postAdjustment();
      },
      () => {
        brClass.notification.showSuccessToast($localize`:Post Canceled @@postCanceled:Post Canceled`);
      }
    );
    // }
  }

  locked() {
    if (this.vm.countId != 'new') {
      this.vm.item.locked = true;
      this.save();
    }
  }

  print() {
    window.print();
  }
}
