import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-modal-select-account',
  templateUrl: './modal-select-account.component.html',
  styleUrls: ['./modal-select-account.component.css']
})
export class ModalSelectAccountComponent implements OnInit {

  @Input() vm: any;

  @Output() selectAccountModalEmit = new EventEmitter<string>();
  @Output() updateAccountEmit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  selectAccountModal(accountId){
    this.selectAccountModalEmit.emit(accountId);
  }

  updateAccount(){
    this.updateAccountEmit.emit();
  }

}
