import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {CustomFilterPipe} from '../../../admin/custom-filter.pipe';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';

@Component({
  selector: 'app-item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.css']
})
export class ItemSelectComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;

  @Output() updateInventoryItemEmit = new EventEmitter<string>();
  @Output() testItemEmit = new EventEmitter<string>();
  @Output() selectItemModalEmit = new EventEmitter<string>();

  constructor(public isFiltered: IsFilterOnService) {
  }

  ngOnInit() {
  }

  updateInventoryItem() {
    this.updateInventoryItemEmit.emit();
  }

  testItem() {
    this.testItemEmit.emit();
  }

  selectItemModal(itemId) {
    this.selectItemModalEmit.emit(itemId);
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

  checkFiltered(): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    this.vm.filtered = filteredValue;
    return filteredValue;
  }
}
