import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PermissionsService} from '../../../../Services/permissions.service';
import {TableDataService} from '../../../../Services/tableData.service';
import {StorageService} from '../../../../Services/storage.service';
import {DatePipe} from '@angular/common';
import {CustomFilterPipe} from '../../../admin/custom-filter.pipe';
import {Router} from '@angular/router';
import {PreferencesService} from '../../../../Services/preferences.service';
import {ResponseModel} from '../../../../DataModels/response-model';

@Component({
  selector: 'app-modal-items-to-order',
  templateUrl: './modal-items-to-order.component.html',
  styleUrls: ['./modal-items-to-order.component.css']
})
export class ModalItemsToOrderComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() vm: any;
  public pref: any;

  @Output() getItemsToOrderEmit = new EventEmitter<string>();
  @Output() gItemsToOrderEmit = new EventEmitter<string>();
  @Output() onCloseEmit = new EventEmitter<string>();

  locations = [];

  constructor(public p: PermissionsService,
              public pre: PreferencesService,
              public tableDataService: TableDataService,
              public localStorage: StorageService,
              public datePipe: DatePipe,
              public cFilter: CustomFilterPipe,
              private router: Router) {
  }

  ngOnInit() {
    // this.pref = this.pre.ListPreferences();
    if (!this.vm.itemsToOrder) {
      this.vm.itemsToOrder = [];
    }

    if (!this.vm.request.location_id) {
      this.loadLocations();
    }
  }

  onCloseModal() {
    this.onCloseEmit.emit();
  }

  loadLocations() {
    this.tableDataService.getTableData('location', '?pageNumber=0&pageSize=999999&linkedEntityType=ap').subscribe((result: ResponseModel) => {
      this.vm.location = result.Data;
    });
  }

  getItemToOrderProcess() {

    const d = this.datePipe.transform(new Date(), 'MM/dd/yyyy');

    this.vm.itemsToOrder = [];
    this.vm.itemPO = [];
    this.vm.noItemsToOrder = false;
    setTimeout(() => {
      this.vm.buttonsDisabled = true;
    }, 500);

    if (!this.vm.request.company_id) {
      this.vm.request.company_id = this.pre.LoadByLocalStorage('default_company_id');
    }
    if (!this.vm.request.location_id) {
      this.vm.request.location_id = this.vm.item.header.location_id;
    }

    this.tableDataService.getTableData('ItemPO', '?' + this.encodeQueryData({
      location_id: this.vm.request.location_id,
      company_id: this.vm.request.company_id,
      toDate: d,
      pageNumber: 0,
      pageSize: 999999
    })).subscribe((ItemPO: any[]) => {
      this.vm.itemPO = ItemPO;

      this.tableDataService.getTableData('ItemQuantity/locationid', '?' + this.encodeQueryData({
        locationid: this.vm.request.location_id,
        pageNumber: 0,
        pageSize: 999999
      })).subscribe((ItemQuantity: ResponseModel) => {
        const data: any = ItemQuantity.Data;
        this.vm.itemQuantities = data;

        this.tableDataService.getTableData('InventoryBalance', '?' + this.encodeQueryData({
          location_id: this.vm.request.location_id,
          company_id: this.vm.request.company_id,
          toDate: d,
          pageNumber: 0,
          pageSize: 999999
        })).subscribe((InventoryBalance: ResponseModel) => {
          const data2: any = InventoryBalance.Data;
          this.vm.itemBalances = data2;
          this.vm.buttonsDisabled = false;
          let tempItems = [];
          let tempItemPO = [];
          if (this.vm.itemBalances && this.vm.itemBalances.length == 1) {
            tempItems = this.vm.itemBalances[0].locations[0].items;
          }
          if (this.vm.itemPO.length > 0) {
            tempItemPO = this.vm.itemPO[0].locations[0].items;
          }
          for (const [x, value] of Object.entries(this.vm.itemQuantities)) {
            let itemPOAmount = 0;
            if (this.cFilter.transform(tempItemPO, {item_id: this.vm.itemQuantities[x].item_id}, true)[0] !== undefined) {
              itemPOAmount = this.cFilter.transform(tempItemPO, {item_id: this.vm.itemQuantities[x].item_id}, true)[0].quantity;
            }
            if (this.cFilter.transform(tempItems, {item_id: this.vm.itemQuantities[x].item_id}, true)[0] !== undefined) {
              const tempItemBalance = this.cFilter.transform(tempItems, {item_id: this.vm.itemQuantities[x].item_id}, true)[0];
              if (tempItemBalance.quantity < this.vm.itemQuantities[x].minimum_on_hand_quantity) {
                let tempQuantity = this.vm.itemQuantities[x].maximum_on_hand_quantity - tempItemBalance.quantity;
                tempQuantity = tempQuantity - itemPOAmount;
                if (tempQuantity < this.vm.itemQuantities[x].minimum_order_quantity && itemPOAmount === 0) {
                  tempQuantity = this.vm.itemQuantities[x].minimum_order_quantity;
                }
                if (tempQuantity > 0) {
                  this.vm.itemsToOrder.push({
                    item_id: tempItemBalance.item_id,
                    item_name: this.vm.itemQuantities[x].item_name,
                    quantity: tempQuantity,
                    quantity_on_hand: tempItemBalance.quantity,
                    quantity_on_po: itemPOAmount,
                    minimum_on_hand_quantity: this.vm.itemQuantities[x].minimum_on_hand_quantity,
                    maximum_on_hand_quantity: this.vm.itemQuantities[x].maximum_on_hand_quantity,
                    minimum_order_quantity: this.vm.itemQuantities[x].minimum_order_quantity,
                    include: true,
                  });
                }
              }
            } else {
              let tempQuantity = this.vm.itemQuantities[x].maximum_on_hand_quantity;
              tempQuantity = tempQuantity - itemPOAmount;
              if (tempQuantity < this.vm.itemQuantities[x].minimum_order_quantity && itemPOAmount === 0) {
                tempQuantity = this.vm.itemQuantities[x].minimum_order_quantity;
              }
              if (tempQuantity > 0) {
                this.vm.itemsToOrder.push({
                  item_id: this.vm.itemQuantities[x].item_id,
                  item_name: this.vm.itemQuantities[x].item_name,
                  quantity: tempQuantity,
                  quantity_on_hand: 0,
                  quantity_on_po: itemPOAmount,
                  minimum_on_hand_quantity: this.vm.itemQuantities[x].minimum_on_hand_quantity,
                  maximum_on_hand_quantity: this.vm.itemQuantities[x].maximum_on_hand_quantity,
                  minimum_order_quantity: this.vm.itemQuantities[x].minimum_order_quantity,
                  include: true,
                });

              }
            }
          }
          if (this.vm.itemsToOrder.length == 0) {
            this.vm.noItemsToOrder = true;
          }
        });
      });
    });
  }

  getItemsToOrder() {
    //  console.log('in get: ' + this.vm.getItemsToOrderEmit);
    if (!this.vm.getItemsToOrderEmit) {
      this.getItemToOrderProcess();
    } else {
      this.getItemsToOrderEmit.emit();
    }
  }

  generateItemsToOrder() {
    // console.log('in generate: ' + this.vm.gItemsToOrderEmit);
    if (!this.vm.gItemsToOrderEmit) {
      // console.log('in generate = co: ' + this.vm.request.location_id + ' - loc:' + this.vm.request.location_id );
      // get ent name
      let entName = '';
      let coId = '';
      let entId = '';

      this.vm.location.forEach((element, index, array) => {
        if (element.id === this.vm.request.location_id) {
          entName = element.entity_name;
          entId = element.entity_id;
          coId = element.company_id;
        }
      });
      // console.log('in orders to generate: ' +  JSON.stringify(this.vm.itemsToOrder));
      // these get removed by the PO form opening below
      localStorage.setItem('itemsToOrderCompanyId', coId);
      localStorage.setItem('itemsToOrderEntityName', entName);
      localStorage.setItem('itemsToOrderEntityId', entId);
      localStorage.setItem('itemsToOrderLocationId', this.vm.request.location_id);
      localStorage.setItem('itemsToOrder', JSON.stringify(this.vm.itemsToOrder));

      this.router.navigate(['/transactions/invoice/form'], {queryParams: {invoiceId: 'newPurchaseOrder'}});

      // this.getItemsToOrderEmit.emit(this.vm.itemsToOrder);

    } else {
      // console.log('in else = co: ' + this.vm.request.location_id + ' - loc:' + this.vm.request.location_id + ' - data:' + JSON.stringify(this.vm.itemsToOrder));
      this.gItemsToOrderEmit.emit(JSON.stringify(this.vm.itemsToOrder));
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      } else if (data[d] != null && data[d] == '') {
        ret.push(encodeURIComponent(d) + '=');
      }
    }
    return ret.join('&');
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

}
