import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from '../../Services/app-config.service';

@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

  constructor(private appConfigService: AppConfigService) { }

  transform(val: any) {
    if (val !== undefined && val !== null && val !== '') {
      const str = val.toString().replace(/,/g, '');
      return parseFloat(str).toFixed(this.appConfigService.decimalPlaces);
    } else {
      return '';
    }
  }
}
