import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NotificationService } from '../../../Services/notification.service';
import { TableDataService } from '../../../Services/tableData.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DateFilterService } from '../../../Services/dateFilter.service';
import { StorageService } from '../../../Services/storage.service';
import { ArraySortPipe } from '../../Reports/transaction-reports/orderBy';
import { CustomFilterPipe } from '../../admin/custom-filter.pipe';
import { DatePipe, ViewportScroller } from '@angular/common';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../DataModels/response-model';
import { DecimalPipe } from '@angular/common';
import { ToWords } from '../../../Services/ToWordsService/ToWords';
import { BsToastService } from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-payroll-payment-form',
  templateUrl: './payroll-payment-form.component.html',
  styleUrls: ['./payroll-payment-form.component.css'],
})
export class PayrollPaymentFormComponent implements OnInit {
  vm: any = {
    formUrl: '/payroll/payment-form',
    mainListPage: '/payroll/payment-list',
    startEmployee: 0,
    employeeFilter: [],
  };
  checkPrint: any = {};
  paymentId;
  batchId;
  action;
  rptName = '';
  showDelete;
  developerView;
  isLoaded = false;
  urlParams: any;
  words = new ToWords();
  printPreview = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private cFilterPipe: CustomFilterPipe,
    private confirmDialogService: ConfirmDialogService,
    private decimalPIpe: DecimalPipe,
    private vps: ViewportScroller
  ) {
    this.vm.alert = {};
    this.vm.alert.message = [];
    this.vm.print = {};
  }

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: false,
    email: true,
    print: true,
  };
  disabled: any = {};

  async ngOnInit() {
    await this.dataInit();
  }

  async dataInit() {
    this.vm.getInvoices = false;
    this.vm.manualAmount = false;
    this.vm.startemployee = 0;
    this.vm.paymentSubTotal = 0;
    this.vm.paymentTaxTotal = 0;
    this.vm.paymentPaymentTotal = 0;

    this.vm.item = [];
    this.vm.bank = {};
    this.vm.checkStock = {};
    this.vm.company = {};
    this.vm.currency = {};
    this.vm.earning = {};
    this.vm.employee = {};
    this.vm.paymentType = {};
    this.vm.rfType = {};
    const params = this.route.snapshot.queryParams;
    const _paymentId = params.paymentId;
    const _batchId = params.batchId;
    this.vm.paymentId = _paymentId;
    if (_paymentId == 'new') {
      this.vm.item = { batchTypeId: '7' };
      this.vm.item.batch = {};
      this.vm.item.batch.description = $localize`:Payment Batch @@paymentBatch:Payment Batch`;
      this.vm.item.header = {};
      this.vm.item.header.currency_rate = '1';
      this.vm.item.detail = [];
      this.vm.applied = this.vm.item.applied = [];
    }

    this.vm.rfType = await this.getFilterValues('reporting_field_type', '?pageNumber=0&pageSize=999999');
    this.vm.bank = await this.getFilterValues('bank', '?state=active&pageNumber=0&pageSize=999999');
    this.vm.checkStock = await this.getFilterValues('check_stock', '?state=active&pageNumber=0&pageSize=999999');
    this.vm.company = await this.getFilterValues('company', '?state=active&pageNumber=0&pageSize=999999');
    this.vm.currency = await this.getFilterValues('currency', '?state=active&pageNumber=0&pageSize=999999');
    this.vm.earning = await this.getFilterValues('earning', '?state=active&pageNumber=0&pageSize=999999');
    this.vm.employee = await this.getFilterValues('employee', '?state=active&pageNumber=0&pageSize=999999');

    // this.vm.bank = await this.tableDataService.getTableData('bank?state=active').toPromise();
    // this.vm.checkStock = await this.tableDataService.getTableData('check_stock?state=active').toPromise();
    // this.vm.company = await this.tableDataService.getTableData('company?state=active').toPromise();
    // this.vm.currency = await this.tableDataService.getTableData('currency?state=active').toPromise();
    // this.vm.earning = await this.tableDataService.getTableData('earning?state=active').toPromise();
    // this.vm.employee = await this.tableDataService.getTableData('employee?state=active').toPromise();

    let tempName;
    this.vm.employeeName = [];
    for (const [y, value] of Object.entries(this.vm.employee)) {
      tempName = this.vm.employee[y].name;
      this.vm.employeeName.push({
        name: tempName,
      });
    }

    await this.tableDataService
      .getTableData('payment_type?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.paymentType = result.Data;
      });

    if (_paymentId == 'new') {
      if (this.vm.bank.length === 1) {
        this.vm.item.detail[0].account_id = this.vm.bank[0].account_id;
      }
      this.vm.item.batch.type_id = '7';
      if (_batchId) {
        this.vm.batchId = this.vm.item.batch.id = _batchId;
      } else {
        this.vm.item.batch.description = 'Batch';
      }
      for (const [x, value] of Object.entries(this.vm.earning)) {
        this.vm.item.detail.push({
          earningInput: this.vm.earning[x].name,
          earning_id: this.vm.earning[x].id,
          audit_sequence: this.vm.earning[x].sort_order,
          quantity: 1,
          deduction: this.vm.earning[x].deduction,
          control_line: '0',
        });
      }
    } else {
      this.tableDataService.getTableData('employee?state=active').subscribe((result: ResponseModel) => {
        // console.log('EMP1: ' + JSON.stringify(result.Data));
        this.vm.employee = result.Data;
      });

      this.tableDataService.getTableData('entry', _paymentId).subscribe((result) => {
        this.vm.item = result;
        // console.log(this.vm.item);

        if (this.vm.item.header.employee_id) {
          if (
            this.FilterObject(this.vm.employee, { id: this.vm.item.header.employee_id }, true)[0] &&
            this.FilterObject(this.vm.employee, { id: this.vm.item.header.employee_id }, true)[0].name
          ) {
            this.vm.item.header.employeeInput = this.FilterObject(
              this.vm.employee,
              { id: this.vm.item.header.employee_id },
              true
            )[0].name;
          }
        }
        //   this.vm.item.header.employeeInput = this.vm.employee.find( x => {
        //     if (x.id === this.vm.item.header.employee_id) {
        //       return x.name;
        //     }
        //   });
        // console.log('pay rec: ' + JSON.stringify(result));
        const tempDate = this.vm.item.header.header_date;
        this.vm.item.header.header_date =
          tempDate.substring(5, 7) + '/' + tempDate.substring(8, 10) + '/' + tempDate.substring(0, 4);
        this.vm.clearedDate = '';
        for (const [x, value] of Object.entries(this.vm.item.detail)) {
          for (const [y, value2] of Object.entries(this.vm.earning)) {
            if (this.vm.earning[y].id === this.vm.item.detail[x].earning_id) {
              this.vm.item.detail[x].earningInput = this.vm.earning[y].name;
              this.vm.item.detail[x].deduction = this.vm.earning[y].deduction;
              this.vm.item.detail[x].sort_order = this.vm.earning[y].sort_order;
              break;
            }
          }
          this.vm.item.detail[x].quantity = Number(this.vm.item.detail[x].quantity);
          this.vm.item.detail[x].amount = Number(this.vm.item.detail[x].amount);
          if (this.vm.item.detail[x].cleared_date) {
            this.vm.clearedDate = this.vm.item.detail[x].cleared_date;
          }
          if (this.vm.item.detail[x].earning_id == 'ei' || this.vm.item.detail[x].earning_id == 'cpp') {
            // this.vm.item.detail[x].quantity = 1;
          }
        }
        for (let x2 = this.vm.item.detail.length - 1; x2 > -1; x2--) {
          if (this.vm.item.detail[x2].control_line == '1') {
            this.vm.item.detail.splice(x2, 1);
          }
        }
        // this.updateEmployee();
        this.calcTotals();
        this.vm.item.header.company_id;
        if (this.urlParams.print == 'true') {
          this.print();
        }
      });
    }

    if (this.vm.bank.length === 1) {
      this.vm.item.header.bank_id = this.vm.bank[0].id;
    }
    if (this.vm.company.length === 1) {
      this.vm.item.header.company_id = this.vm.company[0].id;
    }
    if (this.vm.currency.length === 1) {
      if (!this.vm.item.header) {
        this.vm.item.header = { currency_id: null };
      }
      if (!this.vm.item.batch) {
        this.vm.item.batch = { locked: null };
      }
      this.vm.item.header.currency_id = this.vm.currency[0].id;
    }
    if (this.vm.paymentType.length === 1) {
      this.vm.item.header.payment_type_id = this.vm.paymentType[0].id;
    }
    this.isLoaded = true;
    this.urlParams = this.route.snapshot.queryParams;

    // if (this.urlParams.print == 'true') {
    //   this.print();
    // }
  }

  async getFilterValues(tablename, param) {
    let values;
    await this.tableDataService
      .getTableData(tablename + param)
      .toPromise()
      .then((result: ResponseModel) => {
        values = result.Data;
      });
    // this.localStorage.StorageSet(tablename, JSON.stringify(values));
    return values;
  }

  IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  updateEmployee() {
    this.vm.startemployeeName = 0;
    // this.localStorage.StorageSet('employee', '');
    this.tableDataService.getTableData('employee?state=active').subscribe((result: ResponseModel) => {
      // console.log('EMP: ' + JSON.stringify(result.Data));
      this.vm.employee = result.Data;
    });
  }

  selectEmployeeModal(employeeName) {
    this.vm.item.header.employee_id = '';
    if (this.vm.item.header.employee_id === '' || 1 == 1) {
      for (const [y, value] of Object.entries(this.vm.employee)) {
        if (this.vm.employee[y].name == employeeName) {
          this.vm.item.header.employeeInput = employeeName;
          this.vm.item.header.employee_id = this.vm.employee[y].id;
          break;
        }
      }
    }
  }

  testEmployee() {
    if (!this.vm.item.header.employeeInput && !this.vm.getInvoices) {
      this.vm.errorDescription += $localize`:employee is required. @@employee is required:employee is required.` + '\n';
      this.vm.employeeError = true;
    } else if (this.vm.item.header.employeeInput) {
      if (this.FilterObject(this.vm.employee, { name: this.vm.item.header.employeeInput }).length > 0) {
        const employee_id = this.FilterObject(this.vm.employee, { name: this.vm.item.header.employeeInput }, true)[0]
          .id;
        const employee_name = this.FilterObject(this.vm.employee, { name: this.vm.item.header.employeeInput }, true)[0]
          .name;
        if (employee_id != '') {
          this.vm.item.header.employee_id = employee_id;
          this.vm.item.header.employeeInput = employee_name;
        } else {
          this.vm.item.header.employee_id = '';
          this.vm.errorDescription += $localize`:employee not found. @@employeenotfound:employee not found. ` + '\n';
          this.vm.employeeError = true;
        }
      } else {
        this.vm.item.header.employee_id = '';
        this.vm.errorDescription +=
          $localize`:employee @@employee:employee` +
          ' <strong>' +
          this.vm.item.header.employeeInput +
          '</strong> ' +
          $localize`:not found. @@not found:not found.` +
          ' <br/>';
        this.vm.employeeError = true;
      }
    }
  }

  // UTILITIES
  calcTotals() {
    this.vm.grossPay = this.vm.totalDeductions = this.vm.netPay = 0;
    this.vm.grossPayYTD = this.vm.totalDeductionsYTD = this.vm.netPayYTD = 0;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].deduction == '1') {
        if (this.vm.item.detail[x].amount) {
          if (this.vm.item.detail[x].earning_id == 'ei' || this.vm.item.detail[x].earning_id == 'cpp') {
            this.vm.totalDeductions += Number(this.vm.item.detail[x].amount);
            this.vm.netPay -= Number(this.vm.item.detail[x].amount);
            this.vm.totalDeductionsYTD += Number(this.vm.item.detail[x].ytd);
            this.vm.netPayYTD -= Number(this.vm.item.detail[x].ytd);
          } else {
            this.vm.totalDeductions += Number(this.vm.item.detail[x].amount) * Number(this.vm.item.detail[x].quantity);
            this.vm.netPay -= Number(this.vm.item.detail[x].amount) * Number(this.vm.item.detail[x].quantity);
            this.vm.totalDeductionsYTD += Number(this.vm.item.detail[x].ytd);
            this.vm.netPayYTD -= Number(this.vm.item.detail[x].ytd);
          }
        }
      } else {
        if (this.vm.item.detail[x].amount) {
          this.vm.grossPay += Number(this.vm.item.detail[x].amount) * Number(this.vm.item.detail[x].quantity);
          this.vm.netPay += Number(this.vm.item.detail[x].amount) * Number(this.vm.item.detail[x].quantity);
          this.vm.grossPayYTD += Number(this.vm.item.detail[x].ytd);
          this.vm.netPayYTD += Number(this.vm.item.detail[x].ytd);
        }
      }
    }
  }

  removeComma() {
    if (
      !Number(this.vm.item.detail[0].amount) &&
      this.vm.item.detail[0].amount &&
      this.vm.item.detail[0].amount != '-' &&
      this.vm.item.detail[0].amount != '0'
    ) {
      let tempAmount = this.vm.item.detail[0].amount;
      tempAmount = tempAmount.replace(',', '');
      if (Number(tempAmount)) {
        this.vm.item.detail[0].amount = tempAmount;
      } else {
        this.vm.item.detail[0].amount = '';
      }
    }
  }

  // ACTIONS
  print() {
    this.vm.action = 'print';
    let tempEntityAddress2 = '';
    let tempCompanyAddress2 = '';
    let tempBankAddress2 = '';
    // console.log('company: ' + this.vm.item.header.company_id);
    const tempCompany = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0];
    const tempBank = this.FilterObject(this.vm.bank, { id: this.vm.item.header.bank_id }, true)[0];
    this.vm.stub = [{ id: 1 }, { id: 2 }];
    this.vm.print.checkStock = this.vm.checkStock[0] !== undefined ? this.vm.checkStock[0] : [];
    this.vm.printLayout = [];
    this.vm.printLayout.checkStock = this.FilterObject(this.vm.checkStock, { id: tempBank.stock_id }, true)[0];
    const tempAddress: any = {};
    let tempAmount;
    if (this.vm.entityAddress) {
      if (this.vm.entityAddress.city && (this.vm.entityAddress.state || this.vm.entityAddress.zip)) {
        tempEntityAddress2 =
          this.vm.entityAddress.city + ', ' + this.vm.entityAddress.state + ' ' + this.vm.entityAddress.zip;
      } else if (this.vm.entityAddress.city) {
        tempEntityAddress2 = this.vm.entityAddress.city;
      } else {
        tempEntityAddress2 = tempCompany.state + ' ' + tempCompany.zip;
      }
    } else {
      this.vm.entityAddress = {};
    }

    if (tempCompany.city && (tempCompany.state || tempCompany.zip)) {
      tempCompanyAddress2 = tempCompany.city + ', ' + tempCompany.state + ' ' + tempCompany.zip;
    } else if (tempCompany.city) {
      tempCompanyAddress2 = tempCompany.city;
    } else {
      tempCompanyAddress2 = tempCompany.state + ' ' + tempCompany.zip;
    }
    if (tempBank.city && (tempBank.state || tempBank.zip)) {
      tempBankAddress2 = tempBank.city + ', ' + tempBank.state + ' ' + tempBank.zip;
    } else if (tempBank.city) {
      tempBankAddress2 = tempBank.city;
    } else {
      tempBankAddress2 = tempBank.state + ' ' + tempBank.zip;
    }
    // if (1 == 1) {
    tempAmount = this.vm.netPay;
    // }

    // const tempAmountInt = Math.floor(tempAmount);
    // const decimals = Math.round((tempAmount - tempAmountInt) * 100);

    // let tempAmountInWords = this.inWords(Math.round(tempAmountInt)) + ' AND ' + decimals + '/100';
    const tempAmountInWords = this.words.convert(tempAmount, { currency: true });
    // const firtLetter = tempAmountInWords.substring(0, 1).toUpperCase();
    //
    // tempAmountInWords = firtLetter + tempAmountInWords.substring(1, 1000);

    this.vm.check = [
      {
        companyName: tempCompany.name,
        companyAddress1: tempCompany.address_1,
        companyAddress2: tempCompanyAddress2,
        companyPhone: tempCompany.phone,
        bankName: tempBank.name,
        bankAddress1: tempBank.address_1,
        bankAddress2: tempBankAddress2,
        bankPhone: tempBank.phone,
        payeeName: this.vm.item.header.employeeInput,
        payeeAddress1: '', // this.vm.entityAddress.address_1
        payeeAddress2: '', // tempEntityAddress2
        accountNumber: tempBank.account_number,
        transitNumber: tempBank.transit_number,
        institutionNumber: tempBank.institution_number,
        number: '5604',
        amountInWords: tempAmountInWords,
        amount: tempAmount,
        date: this.vm.item.header.header_date,
      },
    ];

    // this.vm.check[0].applied = [{number:'123',date:'05/03/2017',amount:'300'}];

    // if (this.vm.item.batch.locked != '1') {
    //   this.save();
    // } else {
    //   this.tableDataService.getTableData('uom?state=active').subscribe((result: any[]) => {
    //     const tempUom = result;
    //     window.print();
    //   });
    // }
  }

  confirmPostChecks() {
    const messages: string[] = [
      $localize`:Are you sure checks printed correctly? @@areyousurechecksprintedcorrectly:Are you sure checks printed correct?`,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.save();
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  printActualCheckDocument() {
    window.print();
  }

  scrollToChecks(anchor: string) {
    this.vps.scrollToAnchor(anchor);
  }

  email() {
    this.vm.item.header.email = true;
    this.save();
  }

  save() {
    this.vm.buttonsDisabled = true;
    this.vm.errorDescription = '';
    let tempYearEndDate;
    let tempCutOffDate;
    let headerDate;
    // check errors
    this.testEmployee();

    if (!this.vm.item.header.header_date) {
      this.vm.alert.status = 'error';
      this.vm.alert.message.push({ text: $localize`:Date is required @@jan:Date is required` });
      this.vm.errorDescription = $localize`:Date is required @@dateisrequired:Date is required` + '\n';
      this.vm.dateMissing = true;
    } else {
      this.vm.alert.status = 'success';
      const yearEndDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .year_end_date;
      const cutOffDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .cut_off_date;

      tempYearEndDate = new DatePipe(yearEndDate);
      tempCutOffDate = new DatePipe(cutOffDate);
      headerDate = new DatePipe(this.vm.item.header.header_date);

      if (headerDate < tempYearEndDate) {
        this.vm.alert.status = 'error';
        this.vm.alert.message.push({
          text:
            $localize`:Date must be greater than the year end  @@datemustbegreaterthantheyearend:Date must be greater than the year end ` +
            yearEndDate,
        });
      }
      if (headerDate < tempCutOffDate) {
        this.vm.alert.status = 'error';
        this.vm.alert.message.push({
          text:
            $localize`:Date must be greater than the cut off date  @@datemustbegreaterthanthecutoffdate:Date must be greater than the cut off date ` +
            cutOffDate,
        });
      }
    }
    if (!this.vm.item.header.company_id) {
      this.vm.errorDescription += $localize`:Company is required.@@companyisrequired:Company is required.` + '\n';
      this.vm.companyError = true;
    }
    if (!this.vm.item.header.currency_id) {
      this.vm.errorDescription += $localize`:Currency is required.@@currencyisrequired:Currency is required.` + '\n';
      this.vm.currencyError = true;
    }
    if (!this.vm.item.header.bank_id) {
      this.vm.errorDescription += $localize`:Bank is required. @@bankisrequired:Bank is required.` + '\n';
      this.vm.bankError = true;
    }
    if (!this.vm.item.header.payment_type_id) {
      this.vm.errorDescription +=
        $localize`:Payment Type is required. @@paymentTypeisrequired:Payment Type is required.` + '\n';
      this.vm.paymentTypeError = true;
    }

    if (this.vm.errorDescription === '' && this.vm.alert.status != 'error') {
      if (this.vm.item.header.id === undefined) {
        // new
        // console.log('pr payment post data: ' + JSON.stringify(this.vm.item));
        this.tableDataService.post('entry', this.vm.item).subscribe(
          (item: any) => {
            // this.notification.success(item.description);
            this.router.navigateByUrl(this.vm.formUrl + '?paymentId=' + item.id);
            this.vm.buttonsDisabled = false;
          },
          (error2: any) => {
            this.vm.buttonsDisabled = false;
            let ErrorMsg: string;
            if (typeof error2.error.Message !== 'undefined') {
              ErrorMsg = error2.error.Message;
            } else {
              ErrorMsg = error2.statusText;
            }
            console.log(ErrorMsg);
          }
        );
      } else {
        // edit
        // diff
        const changes: any = {};
        changes.detail = this.vm.item.detail;
        changes.applied = this.vm.item.applied;
        const headerChanges = {};
        for (const key in this.vm.item.header) {
          if (this.vm.item.header.hasOwnProperty(key)) {
            const value = this.vm.item.header[key];
            if (key != 'entityInput') {
              headerChanges[key] = value;
            }
          }
        }
        if (headerChanges) {
          changes.header = headerChanges;
        }
        this.vm.changes = changes;

        this.tableDataService
          .patch('entry', this.vm.item.header.id, changes)
          .then((item: any) => {
            this.vm.buttonsDisabled = false;
            // this.notification.success(item.description);
            if (item.description == 'Deleted') {
              this.router.navigateByUrl(this.vm.mainListPage);
            }
            if (this.vm.action === 'print') {
              window.print();
            }
          })
          .catch((msg: string) => {
            this.vm.buttonsDisabled = false;
            console.log(msg);
          });
      }
    } else {
      // report error
      this.vm.buttonsDisabled = false;
      const arr = this.vm.errorDescription.split('\n');
      arr.forEach((item) => {
        if (item) {
          console.log(item);
        }
      });
    }
  }

  FilterObject(value, filterObj, actual = false) {
    return this.cFilterPipe.transform(value, filterObj, actual);
  }

  OrderBy(array, field, reverse = true) {
    return new ArraySortPipe().transform(array, field, reverse);
  }

  onClose() {
    this.router.navigate(['/payroll/payment-list']);
  }

  deleteItem() {
    if (this.vm.paymentId) {
      // let retMsg = '';
      const messages: string[] = [
        $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
        this.vm.item.header.employeeInput,
      ];
      this.confirmDialogService.confirmThis(
        messages,
        () => {
          this.vm.item.header.deleted = true;
          this.save();
        },
        () => {
         this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
        }
      );
    }
  }

  locked() {
    if (this.vm.paymentId) {
      this.vm.item.locked = true;
      this.save();
    }
  }
}
