import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {AppConfigService} from '../../Services/app-config.service';

@Pipe({name: 'decimal'})

export class DecimalNewPipe implements PipeTransform {

  constructor(private decimal: DecimalPipe, private appConfigService: AppConfigService) {

  }

  transform(value: any) {
    if (!value) {
      return '';
    }
    const decimalPlaces = this.appConfigService.decimalPlaces;
    const digitsInfo = '1.' + decimalPlaces + '-' + decimalPlaces;
    return this.decimal.transform(value, digitsInfo);
  }

}
