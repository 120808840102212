import { Component, OnInit } from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ResponseModel} from '../../../../DataModels/response-model';
import {Observable} from '../../../../../../node_modules/rxjs';
import {ItemTypeModel} from '../../../../DataModels/item-type-model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-item-type-form',
  templateUrl: './item-type-form.component.html',
  styleUrls: ['./item-type-form.component.scss']
})
export class ItemTypeFormComponent implements OnInit {

  itemTypeID;
  itemType: ItemTypeModel;
  itemTypeForm: UntypedFormGroup;
  itemType$: Observable<ItemTypeModel[]>;
  nonParentedTypeList: ItemTypeModel[];
  submitted = false;
  disableDelete = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.itemTypeID = this.route.snapshot.params.id;
  }

  get f() {
    return this.itemTypeForm.controls;
  }

  createForm() {
    this.itemTypeForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      parent_id: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
      allow_edit: new UntypedFormControl(true),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Item Type @@itemType:Item Type`);
    this.LoadNonParentedTypes();
    this.table.getApiListData('item_type', '?pageNumber=0&pageSize=999999').subscribe((res: any) => {
      this.itemType$ = res.Data;
    });
    if (this.itemTypeID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('item_type', this.itemTypeID).subscribe(
      (result) => {
        this.itemType = result;
        // console.log(this.itemType.allow_edit);
        if(!this.itemType.allow_edit) {
          this.disableDelete = true;
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
        this.itemTypeForm.patchValue(this.itemType);
      }
    );
  }

  private LoadNonParentedTypes() {
    this.table.getApiListData('item_type', '?pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        const tmpArray: ItemTypeModel[] = result.Data;
        this.nonParentedTypeList = tmpArray.filter(x => x.parent_id === '');
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {
      }
    );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.itemTypeForm.reset();
    }
  }

  resetForm() {
    this.itemTypeForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/item-type-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete item type? @@areYouSureYouWantToDeleteItemType:Are you sure you want to delete item type?`,
      this.itemType.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('item_type', this.itemTypeID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.itemTypeForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return false;
    }
    this.itemType = Object.assign({}, this.itemTypeForm.value);
    if (this.itemTypeID) {
      if (this.itemTypeForm.invalid === false) {
        this.table.patch('item_type', this.itemTypeID, this.itemType).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('item_type', this.itemType).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.itemTypeID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
