import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {AppConfigService} from '../../../../Services/app-config.service';
import {StorageService} from '../../../../Services/storage.service';
import {Title} from '@angular/platform-browser';
import {ArraySortPipe} from '../../transaction-reports/orderBy';
import {DatePipe} from '@angular/common';
import {DateFilterService} from '../../../../Services/dateFilter.service';
import {NotificationService} from '../../../../Services/notification.service';
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../../../Services/email.service';
import {CustomFilterPipe} from '../../../admin/custom-filter.pipe';
import {ResponseModel} from '../../../../DataModels/response-model';
import {EmailPassDataServiceService} from '../../../../Services/email-pass-data-service.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-margin-report',
  templateUrl: './margin-report.component.html',
  styleUrls: ['./margin-report.component.css']
})
export class MarginReportComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('EmailContent', {static: false}) EmailContent: ElementRef;
  private rptName: string;
  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      location_group_id: null,
      columns: '',
      company_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      margin_id: null,
      location_id: null
    },
    math: Math,
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: ''
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    location_group: [],
    rfType: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    margin: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    monthCount: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    Preferences: null,
    permissions: null,
    currentMonthMargin: 0,
    priorMonthMargin: 0,
    currentPeriodMargin: 0,
    priorPeriodMargin: 0,
    account: [],
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    caclutedDate: null,
    toDateTitle: null,
    fromDateTitle: null,
    priorToDateTitle: null,
    priorToDateLink: null,
    priorMonthFromDateLink: null,
    currentMonthFromDateLink: null,
    priorFromDateLink: null,
    priorFromDateTitle: null,
  };
  public submitted = false;

  constructor(public localStorage: StorageService,
              public tableDataService: TableDataService,
              private titleService: Title,
              public appConfigService: AppConfigService,
              public dateFilter: DateFilterService,
              public notification: NotificationService,
              public aEmail: EmailService,
              private fb: UntypedFormBuilder,
              public cFilter: CustomFilterPipe,
              private emailData: EmailPassDataServiceService,
              private router: Router) {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
    this.vm.summaryView = true;
  }

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Balance Sheet Report @@balanceSheetReport:Balance Sheet Report`;
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService.getTableData('company?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.company = result.Data;
    });
    this.tableDataService.getTableData('currency?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.currency = result.Data;
    });
    this.tableDataService.getTableData('location?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.location = result.Data;
    });
    this.tableDataService.getTableData('location_group?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.location_group = result.Data;
    });
    this.tableDataService.getTableData('account_margin?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.margin = result.Data;
    });
    this.tableDataService.getTableData('account?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.account = result.Data;
    });
    this.tableDataService.getTableData('reporting_field_type?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rfType = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf1&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf1 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf2&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf2 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf3&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf3 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf4&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf4 = result.Data;
    });
    this.tableDataService.getTableData('reporting_field', '?state=active&type_id=rf5&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf5 = result.Data;
    });
  }

  FilterObject(value, filterObj, actual = false) {
    return this.cFilter.transform(value, filterObj, actual);
  }

  calcTotals() {
    this.vm.currentMonthMargin = 0;
    this.vm.priorMonthMargin = 0;
    this.vm.currentPeriodMargin = 0;
    this.vm.priorPeriodMargin = 0;

    let tempReportLines;
    let currentMonth = 0;
    let priorMonth = 0;
    let currentPeriod = 0;
    let priorPeriod = 0;

    let currentMonthStat = 0;
    let priorMonthStat = 0;
    let currentPeriodStat = 0;
    let priorPeriodStat = 0;

    let currentMonthPercent = 0;
    let priorMonthPercent = 0;
    let currentPeriodPercent = 0;
    let priorPeriodPercent = 0;
    let revenueCount = 0;
    let costCount = 0;
    let currentMonthRevenue = 0;
    let priorMonthRevenue = 0;
    let currentPeriodRevenue = 0;
    let priorPeriodRevenue = 0;
    let currentMonthCost = 0;
    let priorMonthCost = 0;
    let currentPeriodCost = 0;
    let priorPeriodCost = 0;
    let maxAccountNumber = 0;


    for (const [x, value] of Object.entries(this.vm.report)) {
      this.vm.report[x].account_name = this.FilterObject(this.vm.account, {id: this.vm.report[x].account_id})[0].name;
      this.vm.report[x].account_number = this.FilterObject(this.vm.account, {id: this.vm.report[x].account_id})[0].number;
      this.vm.report[x].account_type = this.FilterObject(this.vm.account, {id: this.vm.report[x].account_id})[0].type_name;
      if (this.FilterObject(this.vm.margin, {id: this.vm.report[x].margin_id})[0] !== undefined) {
        this.vm.report[x].sort_order = this.FilterObject(this.vm.margin, {id: this.vm.report[x].margin_id})[0].sort_order;
      }
      if (this.vm.report[x].account_type === 'Revenue') {
        if (this.vm.report[x].account_number > maxAccountNumber) {
          maxAccountNumber = Number(this.vm.report[x].account_number);
        }
        this.vm.currentMonthMargin += this.vm.report[x].current_month;
        this.vm.priorMonthMargin += this.vm.report[x].prior_month;
        this.vm.currentPeriodMargin += this.vm.report[x].current_period;
        this.vm.priorPeriodMargin += this.vm.report[x].prior_period;
        // currentMonthRevenue += vm.report[x].current_month;
        // priorMonthRevenue += vm.report[x].prior_month;
        // currentPeriodRevenue += vm.report[x].current_period;
        // priorPeriodRevenue += vm.report[x].prior_period;
        // revenueCount++;
      } else {
        this.vm.currentMonthMargin -= this.vm.report[x].current_month;
        this.vm.priorMonthMargin -= this.vm.report[x].prior_month;
        this.vm.currentPeriodMargin -= this.vm.report[x].current_period;
        this.vm.priorPeriodMargin -= this.vm.report[x].prior_period;
        // currentMonthCost -= vm.report[x].current_month;
        // priorMonthCost -= vm.report[x].prior_month;
        // currentPeriodCost -= vm.report[x].current_period;
        // priorPeriodCost -= vm.report[x].prior_period;
        // costCount++
      }
    }

    for (const [x, value] of Object.entries(this.vm.report)) {
      this.vm.report[x].current_month_percent_margin = this.vm.report[x].current_month / this.vm.currentMonthMargin * 100;
      this.vm.report[x].prior_month_percent_margin = this.vm.report[x].prior_month / this.vm.priorMonthMargin * 100;
      this.vm.report[x].current_period_percent_margin = this.vm.report[x].current_period / this.vm.currentPeriodMargin * 100;
      this.vm.report[x].prior_period_percent_margin = this.vm.report[x].prior_period / this.vm.priorPeriodMargin * 100;
    }

    for (const [x, value] of Object.entries(this.vm.margin)) {

      tempReportLines = this.FilterObject(this.vm.report, {margin_id: this.vm.margin[x].id});
      currentMonth = 0;
      priorMonth = 0;
      currentPeriod = 0;
      priorPeriod = 0;

      currentMonthStat = 0;
      priorMonthStat = 0;
      currentPeriodStat = 0;
      priorPeriodStat = 0;

      currentMonthPercent = 0;
      priorMonthPercent = 0;
      currentPeriodPercent = 0;
      priorPeriodPercent = 0;
      revenueCount = 0;
      costCount = 0;
      currentMonthRevenue = 0;
      priorMonthRevenue = 0;
      currentPeriodRevenue = 0;
      priorPeriodRevenue = 0;
      currentMonthCost = 0;
      priorMonthCost = 0;
      currentPeriodCost = 0;
      priorPeriodCost = 0;
      maxAccountNumber = 0;

      for (const [y, value2] of Object.entries(tempReportLines)) {
        if (tempReportLines[y].account_type === 'Revenue') {
          if (tempReportLines[y].account_number > maxAccountNumber) {
            maxAccountNumber = Number(tempReportLines[y].account_number);
          }
          currentMonth += tempReportLines[y].current_month;
          priorMonth += tempReportLines[y].prior_month;
          currentPeriod += tempReportLines[y].current_period;
          priorPeriod += tempReportLines[y].prior_period;
          currentMonthRevenue += tempReportLines[y].current_month;
          priorMonthRevenue += tempReportLines[y].prior_month;
          currentPeriodRevenue += tempReportLines[y].current_period;
          priorPeriodRevenue += tempReportLines[y].prior_period;
          revenueCount++;
        } else {
          currentMonth -= tempReportLines[y].current_month;
          priorMonth -= tempReportLines[y].prior_month;
          currentPeriod -= tempReportLines[y].current_period;
          priorPeriod -= tempReportLines[y].prior_period;
          currentMonthCost -= tempReportLines[y].current_month;
          priorMonthCost -= tempReportLines[y].prior_month;
          currentPeriodCost -= tempReportLines[y].current_period;
          priorPeriodCost -= tempReportLines[y].prior_period;
          costCount++;
        }
      }
      if (revenueCount > 1 && costCount > 0) {
        this.vm.report.push({
          margin_name: 'Revenue',
          margin_id: this.vm.margin[x].id,
          current_month: currentMonthRevenue,
          prior_month: priorMonthRevenue,
          current_period: currentPeriodRevenue,
          prior_period: priorPeriodRevenue,
          sort_order: this.vm.margin[x].sort_order,
          account_number: maxAccountNumber + 0.1,
          total_row: true,
          current_month_percent: 100,
          prior_month_percent: 100,
          current_period_percent: 100,
          prior_period_percent: 100,
          current_month_percent_margin: currentMonthRevenue / this.vm.currentMonthMargin * 100,
          prior_month_percent_margin: priorMonthRevenue / this.vm.priorMonthMargin * 100,
          current_period_percent_margin: currentPeriodRevenue / this.vm.currentPeriodMargin * 100,
          prior_period_percent_margin: priorPeriodRevenue / this.vm.priorPeriodMargin * 100,
        });
        this.vm.report.push({
          margin_name: 'Revenue',
          sort_order: this.vm.margin[x].sort_order,
          account_number: 1,
          title_row: true,
        });
      }
      if (costCount > 1 && revenueCount > 0) {
        currentMonthPercent = 100;
        priorMonthPercent = 100;
        currentPeriodPercent = 100;
        priorPeriodPercent = 100;

        if (currentMonthRevenue !== 0) {
          currentMonthPercent = (currentMonthRevenue - currentMonth) / currentMonthRevenue * 100;
        }
        if (priorMonthRevenue !== 0) {
          priorMonthPercent = (priorMonthRevenue - priorMonth) / priorMonthRevenue * 100;
        }
        if (currentPeriodRevenue !== 0) {
          currentPeriodPercent = (currentPeriodRevenue - currentPeriod) / currentPeriodRevenue * 100;
        }
        if (priorPeriodRevenue !== 0) {
          priorPeriodPercent = (priorPeriodRevenue - priorPeriod) / priorPeriodRevenue * 100;
        }

        this.vm.report.push({
          margin_name: 'Costs',
          margin_id: this.vm.margin[x].id,
          current_month: currentMonthRevenue - currentMonth,
          prior_month: priorMonthRevenue - priorMonth,
          current_period: currentPeriodRevenue - currentPeriod,
          prior_period: priorPeriodRevenue - priorPeriod,
          sort_order: this.vm.margin[x].sort_order,
          account_number: 99998,
          total_row: true,
          current_month_percent: currentMonthPercent,
          prior_month_percent: priorMonthPercent,
          current_period_percent: currentPeriodPercent,
          prior_period_percent: priorPeriodPercent,
          current_month_percent_margin: (currentMonthRevenue - currentMonth) / this.vm.currentMonthMargin * 100,
          prior_month_percent_margin: (priorMonthRevenue - priorMonth) / this.vm.priorMonthMargin * 100,
          current_period_percent_margin: (currentPeriodRevenue - currentPeriod) / this.vm.currentPeriodMargin * 100,
          prior_period_percent_margin: (priorPeriodRevenue - priorPeriod) / this.vm.priorPeriodMargin * 100,
        });
        this.vm.report.push({
          margin_name: 'Costs',
          sort_order: this.vm.margin[x].sort_order,
          account_number: maxAccountNumber + 0.2,
          title_row: true,
        });
      }
      if (tempReportLines.length > 0) {
        currentMonthPercent = 100;
        priorMonthPercent = 100;
        currentPeriodPercent = 100;
        priorPeriodPercent = 100;
        if (currentMonthRevenue !== 0) {
          currentMonthPercent = currentMonth / currentMonthRevenue * 100;
        }
        if (priorMonthRevenue !== 0) {
          priorMonthPercent = priorMonth / priorMonthRevenue * 100;
        }
        if (currentPeriodRevenue !== 0) {
          currentPeriodPercent = currentPeriod / currentPeriodRevenue * 100;
        }
        if (priorPeriodRevenue !== 0) {
          priorPeriodPercent = priorPeriod / priorPeriodRevenue * 100;
        }
        this.vm.report.push({
          margin_name: this.vm.margin[x].name,
          margin_id: this.vm.margin[x].id,
          current_month: currentMonth,
          prior_month: priorMonth,
          current_period: currentPeriod,
          prior_period: priorPeriod,
          sort_order: this.vm.margin[x].sort_order,
          account_number: 99999,
          total_row: true,
          current_month_percent: currentMonthPercent,
          prior_month_percent: priorMonthPercent,
          current_period_percent: currentPeriodPercent,
          prior_period_percent: priorPeriodPercent,
          current_month_percent_margin: currentMonth / this.vm.currentMonthMargin * 100,
          prior_month_percent_margin: priorMonth / this.vm.priorMonthMargin * 100,
          current_period_percent_margin: currentPeriod / this.vm.currentPeriodMargin * 100,
          prior_period_percent_margin: priorPeriod / this.vm.priorPeriodMargin * 100,
        });
        this.vm.report.push({
          margin_name: this.vm.margin[x].name,
          sort_order: this.vm.margin[x].sort_order,
          account_number: 0,
          title_row: true,
        });
      }
      for (const [y, value2] of Object.entries(tempReportLines)) {
        for (const [z, value3] of Object.entries(this.vm.report)) {
          if (this.vm.report[z].account_id == tempReportLines[y].account_id) {
            if (currentMonthRevenue !== 0) {
              this.vm.report[z].current_month_percent = this.vm.report[z].current_month / currentMonthRevenue * 100;
            } else if (currentMonthCost !== 0) {
              this.vm.report[z].current_month_percent = this.vm.report[z].current_month / currentMonthCost * -100;
            }
            if (priorMonthRevenue !== 0) {
              this.vm.report[z].prior_month_percent = this.vm.report[z].prior_month / priorMonthRevenue * 100;
            } else if (priorMonthCost !== 0) {
              this.vm.report[z].prior_month_percent = this.vm.report[z].prior_month / priorMonthCost * -100;
            }
            if (currentPeriodRevenue !== 0) {
              this.vm.report[z].current_period_percent = this.vm.report[z].current_period / currentPeriodRevenue * 100;
            } else if (currentPeriodCost !== 0) {
              this.vm.report[z].current_period_percent = this.vm.report[z].current_period / currentPeriodCost * -100;
            }
            if (priorPeriodRevenue !== 0) {
              this.vm.report[z].prior_period_percent = this.vm.report[z].prior_period / priorPeriodRevenue * 100;
            } else if (priorPeriodCost !== 0) {
              this.vm.report[z].prior_period_percent = this.vm.report[z].prior_period / priorPeriodCost * -100;
            }
            break;
          }
        }
      }
    }

    const parents = [];
    let tempParentId = '';
    const tempMarginOrdered = new ArraySortPipe().transform(this.vm.margin, 'parent_id');

    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(tempMarginOrdered)) {
      if (tempParentId !== tempMarginOrdered[x].parent_id && tempMarginOrdered[x].parent_id != null) {
        if (maxSortOrder < tempMarginOrdered[x].sort_order) {
          maxSortOrder = Number(tempMarginOrdered[x].sort_order);
        }
        tempParentId = tempMarginOrdered[x].parent_id;
        parents.push({
          id: tempParentId,
          name: this.FilterObject(this.vm.margin, {id: tempParentId})[0].name
        });
      } else if (tempParentId == tempMarginOrdered[x].parent_id && tempMarginOrdered[x].parent_id != null) {
        if (maxSortOrder < tempMarginOrdered[x].sort_order) {
          maxSortOrder = Number(tempMarginOrdered[x].sort_order);
        }
      }
    }
    let marginChildren;
    for (const [z, value] of Object.entries(parents)) {
      marginChildren = this.FilterObject(this.vm.margin, {parent_id: parents[z].id});
      currentMonth = 0;
      priorMonth = 0;
      currentPeriod = 0;
      priorPeriod = 0;
      revenueCount = 0;
      costCount = 0;
      currentMonthRevenue = 0;
      priorMonthRevenue = 0;
      currentPeriodRevenue = 0;
      priorPeriodRevenue = 0;
      for (const [x, value2] of Object.entries(marginChildren)) {
        tempReportLines = this.FilterObject(this.vm.report, {margin_id: marginChildren[x].id});
        for (const [y, value3] of Object.entries(tempReportLines)) {
          if (tempReportLines[y].account_type) {
            // console.log(tempReportLines[y].account_type);
            if (tempReportLines[y].account_type === 'Revenue') {
              currentMonth += tempReportLines[y].current_month;
              priorMonth += tempReportLines[y].prior_month;
              currentPeriod += tempReportLines[y].current_period;
              priorPeriod += tempReportLines[y].prior_period;
              currentMonthRevenue += tempReportLines[y].current_month;
              priorMonthRevenue += tempReportLines[y].prior_month;
              currentPeriodRevenue += tempReportLines[y].current_period;
              priorPeriodRevenue += tempReportLines[y].prior_period;
              revenueCount++;
            } else {
              console.log(tempReportLines[y].account_type);
              currentMonth -= tempReportLines[y].current_month;
              priorMonth -= tempReportLines[y].prior_month;
              currentPeriod -= tempReportLines[y].current_period;
              priorPeriod -= tempReportLines[y].prior_period;
              currentMonthCost -= tempReportLines[y].current_month;
              priorMonthCost -= tempReportLines[y].prior_month;
              currentPeriodCost -= tempReportLines[y].current_period;
              priorPeriodCost -= tempReportLines[y].prior_period;
              costCount++;
            }
          }
        }
      }
      if (revenueCount > 1 || costCount > 1) {
        this.vm.report.push({
          margin_name: parents[z].name,
          account_number: 99999995,
          sort_order: maxSortOrder,
          title_row: true,
        });
      }
      if (revenueCount > 1 && costCount > 0) {
        this.vm.report.push({
          margin_name: 'Revenue',
          current_month: currentMonthRevenue,
          prior_month: priorMonthRevenue,
          current_period: currentPeriodRevenue,
          prior_period: priorPeriodRevenue,
          sort_order: maxSortOrder,
          account_number: 99999996,
          total_row: true,
          current_month_percent: 100,
          prior_month_percent: 100,
          current_period_percent: 100,
          prior_period_percent: 100,
          current_month_percent_margin: currentMonthRevenue / this.vm.currentMonthMargin * 100,
          prior_month_percent_margin: priorMonthRevenue / this.vm.priorMonthMargin * 100,
          current_period_percent_margin: currentPeriodRevenue / this.vm.currentPeriodMargin * 100,
          prior_period_percent_margin: priorPeriodRevenue / this.vm.priorPeriodMargin * 100,
        });
      }
      if (costCount > 1 && revenueCount > 0) {
        currentMonthPercent = 100;
        priorMonthPercent = 100;
        currentPeriodPercent = 100;
        priorPeriodPercent = 100;
        if (currentMonthRevenue !== 0) {
          currentMonthPercent = (currentMonthRevenue - currentMonth) / currentMonthRevenue * 100;
        }
        if (priorMonthRevenue !== 0) {
          priorMonthPercent = (priorMonthRevenue - priorMonth) / priorMonthRevenue * 100;
        }
        if (currentPeriodRevenue !== 0) {
          currentPeriodPercent = (currentPeriodRevenue - currentPeriod) / currentPeriodRevenue * 100;
        }
        if (priorPeriodRevenue !== 0) {
          priorPeriodPercent = (priorPeriodRevenue - priorPeriod) / priorPeriodRevenue * 100;
        }

        this.vm.report.push({
          margin_name: 'Costs',
          current_month: currentMonthRevenue - currentMonth,
          prior_month: priorMonthRevenue - priorMonth,
          current_period: currentPeriodRevenue - currentPeriod,
          prior_period: priorPeriodRevenue - priorPeriod,
          sort_order: maxSortOrder,
          account_number: 99999997,
          total_row: true,
          current_month_percent: currentMonthPercent,
          prior_month_percent: priorMonthPercent,
          current_period_percent: currentPeriodPercent,
          prior_period_percent: priorPeriodPercent,
          current_month_percent_margin: (currentMonthRevenue - currentMonth) / this.vm.currentMonthMargin * 100,
          prior_month_percent_margin: (priorMonthRevenue - priorMonth) / this.vm.priorMonthMargin * 100,
          current_period_percent_margin: (currentPeriodRevenue - currentPeriod) / this.vm.currentPeriodMargin * 100,
          prior_period_percent_margin: (priorPeriodRevenue - priorPeriod) / this.vm.priorPeriodMargin * 100,
        });
      }
      if (revenueCount > 1 || costCount > 1) {
        this.vm.report.push({
          margin_name: parents[z].name,
          current_month: currentMonth,
          prior_month: priorMonth,
          current_period: currentPeriod,
          prior_period: priorPeriod,
          sort_order: maxSortOrder,
          account_number: 99999998,
          total_row: true,
          current_month_percent: 100 - currentMonthPercent,
          prior_month_percent: 100 - priorMonthPercent,
          current_period_percent: 100 - currentPeriodPercent,
          prior_period_percent: 100 - priorPeriodPercent,
          current_month_percent_margin: currentMonth / this.vm.currentMonthMargin * 100,
          prior_month_percent_margin: priorMonth / this.vm.priorMonthMargin * 100,
          current_period_percent_margin: currentPeriod / this.vm.currentPeriodMargin * 100,
          prior_period_percent_margin: priorPeriod / this.vm.priorPeriodMargin * 100,
        });
        this.vm.report.push({
          account_number: 99999999,
          sort_order: maxSortOrder,
          title_row: true,
        });
      }
    }
  }

  Print() {
    return window.print();
  }

  update() {

    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.fromDate) {
      errorDescription += $localize`:A from date is required. @@afromdateisrequired:A from date is required.`+' ';
      this.vm.fromDateError = true;
    }
    if (!this.vm.request.toDate) {
      errorDescription += $localize`:A To date is required. @@aTodateisrequired:A To date is required.`+' ';
      this.vm.toDateError = true;
    }
    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);
    if (tempToDate < tempFromDate) {
      errorDescription += $localize`:From date must be less than the To date @@fromdatemustbelessthantheTodate:From date must be less than the To date`;
      this.vm.toDateError = true;
      this.vm.fromDateError = true;
    }
    if (errorDescription) {
      this.notification.warning(errorDescription);
    } else {
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);

      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
      let MarginRerpotParam = '?1=1';
      if (this.vm.request.fromDate != null) {
        MarginRerpotParam += '&fromDate=' + this.vm.request.fromDate;
      }
      if (this.vm.request.toDate != null) {
        MarginRerpotParam += '&toDate=' + this.vm.request.toDate;
      }
      if (this.vm.request.company_id != null) {
        MarginRerpotParam += '&company_id=' + this.vm.request.company_id;
      }
      if (this.vm.request.location_id != null) {
        MarginRerpotParam += '&location_id=' + this.vm.request.location_id;
      }
      if (this.vm.request.location_group_id != null) {
        MarginRerpotParam += '&location_group_id=' + this.vm.request.location_group_id;
      }
      if (this.vm.request.rf_id_1 != null) {
        MarginRerpotParam += '&rf_id_1=' + this.vm.request.rf_id_1;
      }
      if (this.vm.request.rf_id_2 != null) {
        MarginRerpotParam += '&rf_id_2=' + this.vm.request.rf_id_2;
      }
      if (this.vm.request.rf_id_3 != null) {
        MarginRerpotParam += '&rf_id_3=' + this.vm.request.rf_id_3;
      }
      if (this.vm.request.rf_id_4 != null) {
        MarginRerpotParam += '&rf_id_4=' + this.vm.request.rf_id_4;
      }
      if (this.vm.request.rf_id_5 != null) {
        MarginRerpotParam += '&rf_id_5=' + this.vm.request.rf_id_5;
      }
      if (this.vm.request.margin_id != null) {
        MarginRerpotParam += '&margin_id=' + this.vm.request.margin_id;
      }

      this.tableDataService.getTableData('MarginReport', MarginRerpotParam).subscribe((result: any[]) => {
        this.vm.report = result;

        this.vm.caclutedDate = new Date();
        this.vm.filtered = false;
        this.vm.showFilters = this.vm.buttonsDisabled = false;
        this.calcTotals();

        this.vm.toDateTitle = new Date(this.vm.request.toDate);
        this.vm.fromDateTitle = new Date(this.vm.request.fromDate);

        let d = new Date(this.vm.request.toDate);
        d.setFullYear(d.getFullYear() - 1);
        this.vm.priorToDateTitle = d;
        this.vm.priorToDateLink = new DatePipe('en').transform(d, 'MM/dd/yyyy');

        d = new Date(this.vm.request.fromDate);
        d.setFullYear(d.getFullYear() - 1);
        this.vm.priorFromDateTitle = d;
        this.vm.priorFromDateLink = new DatePipe('en').transform(d, 'MM/dd/yyyy');

        d = new Date(this.vm.request.fromDate);
        d.setMonth(d.getMonth() - 1);
        this.vm.priorMonthFromDateLink = new DatePipe('en').transform(d, 'MM/dd/yyyy');

        d = new Date(this.vm.request.toDate);
        d.setMonth(d.getMonth() - 1);
        d.setDate(d.getDate() + 1);
        this.vm.currentMonthFromDateLink = new DatePipe('en').transform(d, 'MM/dd/yyyy');
      });
    }
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    // let tempFromDate: string = this.vm.request.fromDate;
    // let tempToDate: string = this.vm.request.toDate;
    this.vm.report = [];
    this.vm.request = {
      toDate: '',
      fromDate: '',
      location_group_id: null,
      columns: '',
      company_id: null,
      rf_id_1: null,
      rf_id_2: null,
      rf_id_3: null,
      rf_id_4: null,
      rf_id_5: null,
      margin_id: null,
      location_id: null
    };
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  ngAfterViewChecked() {
    const div = document.getElementById('excel-export');
    if (div !== null && div.outerHTML !== undefined) {
       // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Margin Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);

    }
  }
}
