import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TableDataService} from '../../../Services/tableData.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../Services/notification.service';
import {StorageService} from '../../../Services/storage.service';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {FilterPipe} from 'ngx-filter-pipe';
import {ResponseModel} from '../../../DataModels/response-model';
// TODO - re-write this in proper reactive manner. I just made it work as is for now.
//  Need form fields and html fields sync so you can object assign and patchvalue to avoid
//  the mountain of wire up code written to assign value
@Component({
  selector: 'app-timesheet-form',
  templateUrl: './timesheet-form.component.html',
  styleUrls: ['./timesheet-form.component.css']
})
export class TimesheetFormComponent implements OnInit, AfterViewInit {
  TimesheetForm: UntypedFormGroup;
  vm: any = {
    title: $localize`:Time Sheet Form @@timeSheetForm:Time Sheet Form`,
    item: {},
    rfType: [],
    location: [],
    rf1: [],
    rf2: [],
    rf3: [],
    rf4: [],
    rf5: [],
    user: [],
    buttonsDisabled: false,
    Preferences: [],
    permissions: [],
    mainListPage: '/timesheet/timesheet-list',
    formInitiated: false,
    defaultItem: [],
  };

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  StartHour;
  StartMinute;
  StartAMPM;
  showDelete;
  timesheetId = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private localStorage: StorageService,
              private tableDataService: TableDataService,
              private notification: NotificationService,
              private datePipe: DatePipe,
              private decimal: DecimalPipe,
              private titleService: Title,
              private formBuilder: UntypedFormBuilder,
              private filter: FilterPipe) {
  }

  ngOnInit() {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    this.formInit();
    this.dataInit();
  }

  dataInit() {
    this.route.queryParams.subscribe(params => {
      // console.log(params);
      const TimesheetId = params.id;
      this.timesheetId = params.id;
      if (TimesheetId !== undefined) {
        this.tableDataService.getTableData('time/GetTimeById?id=' + TimesheetId).subscribe((result: any[]) => {
          this.vm.item = result;
          // console.log('get: ' + JSON.stringify(result));
          this.vm.formInitiated = true;
          this.vm.item.date = this.datePipe.transform(this.vm.item.date, 'MM/dd/yyyy');
          this.TimesheetForm.controls.item_date.setValue(this.vm.item.date);
          this.setValues();
          // this.localStorage.StorageSet('formDataTimesheet', JSON.stringify(this.vm.item));
        });
      } else {
        this.vm.item.StartHour = '8';
        this.vm.item.StartMinute = '00';
        this.vm.item.StartAMPM = 'AM';
        this.vm.item.EndHour = '4';
        this.vm.item.EndMinute = '00';
        this.vm.item.EndAMPM = 'PM';

        this.setValues();
        this.vm.item.Hours = 8;
      }
    });
    this.lookupInit();

    this.tableDataService.getTableData('reporting_field?state=active&type_id=rf5&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf5 = result.Data;
      if (this.vm.item.rf5 !== undefined) {
        this.TimesheetForm.controls.item_header_rf_id_5.setValue(this.vm.item.rf5);
      }
    });
    this.tableDataService.getTableData('sys_user?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.user = result.Data;
      if (!this.vm.permissions.Admin) {
        this.vm.item.user_id = this.filter.transform(this.vm.user, {me: true})[0].id;
      }
      this.TimesheetForm.controls.item_user_id.setValue(this.vm.item.user_id);
    });

    if (this.timesheetId) {
      this.buttonConfig.reset = false;
    }
  }

  lookupInit() {
    this.tableDataService.getTableData('location?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.location = result.Data;
    });
    this.tableDataService.getTableData('reporting_field_type?pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      // this.vm.rfType = result.Data;
      const data: any = result.Data;
      this.vm.rfType = data.sort((a, b) => (a.id < b.id ? -1 : 1));
    });
    this.tableDataService.getTableData('reporting_field?state=active&type_id=rf1&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf1 = result.Data;
      if (this.vm.item.rf1 !== undefined) {
        this.TimesheetForm.controls.item_header_rf_id_1.setValue(this.vm.item.rf1);
      }
    });
    this.tableDataService.getTableData('reporting_field?state=active&type_id=rf2&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf2 = result.Data;
      if (this.vm.item.rf2 !== undefined) {
        this.TimesheetForm.controls.item_header_rf_id_2.setValue(this.vm.item.rf2);
      }
    });
    this.tableDataService.getTableData('reporting_field?state=active&type_id=rf3&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf3 = result.Data;
      if (this.vm.item.rf3 !== undefined) {
        this.TimesheetForm.controls.item_header_rf_id_3.setValue(this.vm.item.rf3);
      }
    });
    this.tableDataService.getTableData('reporting_field?state=active&type_id=rf4&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf4 = result.Data;
      if (this.vm.item.rf4 !== undefined) {
        this.TimesheetForm.controls.item_header_rf_id_4.setValue(this.vm.item.rf4);
      }
    });

    this.tableDataService.getTableData('reporting_field?state=active&type_id=rf5&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      this.vm.rf5 = result.Data;
      if (this.vm.item.rf5 !== undefined) {
        this.TimesheetForm.controls.item_header_rf_id_5.setValue(this.vm.item.rf5);
      }
    });
  }

  formInit() {
    this.TimesheetForm = this.formBuilder.group({
      item_user_id: new UntypedFormControl(''),
      item_date: new UntypedFormControl('', Validators.required),
      item_dayoff: new UntypedFormControl(false),
      item_starthour: new UntypedFormControl('', Validators.required),
      item_start_minute: new UntypedFormControl('', Validators.required),
      item_start_am_pm: new UntypedFormControl('', Validators.required),
      item_lunch: new UntypedFormControl(false),
      item_endhour: new UntypedFormControl('', Validators.required),
      item_end_minute: new UntypedFormControl('', Validators.required),
      item_end_am_pm: new UntypedFormControl('', Validators.required),
      item_header_rf_id_1: new UntypedFormControl(''),
      item_header_rf_id_2: new UntypedFormControl(''),
      item_header_rf_id_3: new UntypedFormControl(''),
      item_header_rf_id_4: new UntypedFormControl(''),
      item_header_rf_id_5: new UntypedFormControl(''),
      item_time_notes: new UntypedFormControl(''),
      item_time_hours: new UntypedFormControl('')
    });
    // if (this.vm.batch && (this.vm.item.batch.locked == '1')) {
    //   this.TimesheetForm.controls.item_header_rf_id_1.disable();
    //   this.TimesheetForm.controls.item_header_rf_id_2.disable();
    //   this.TimesheetForm.controls.item_header_rf_id_3.disable();
    //   this.TimesheetForm.controls.item_header_rf_id_4.disable();
    //   this.TimesheetForm.controls.item_header_rf_id_5.disable();
    // }
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  get f() {
    return this.TimesheetForm.controls;
  }

  setValues() {
    // this.TimesheetForm.get('item_starthour').setValue(this.vm.item.StartHour);
    this.TimesheetForm.controls.item_starthour.setValue(this.vm.item.StartHour);
    this.TimesheetForm.controls.item_start_minute.setValue(this.vm.item.StartMinute);
    this.TimesheetForm.controls.item_start_am_pm.setValue(this.vm.item.StartAMPM);
    this.TimesheetForm.controls.item_endhour.setValue(this.vm.item.EndHour);
    this.TimesheetForm.controls.item_end_minute.setValue(this.vm.item.EndMinute);
    this.TimesheetForm.controls.item_end_am_pm.setValue(this.vm.item.EndAMPM);
    this.TimesheetForm.controls.item_time_notes.setValue(this.vm.item.Notes);
    this.TimesheetForm.controls.item_dayoff.setValue(this.vm.item.DayOff);
    this.TimesheetForm.controls.item_lunch.setValue(this.vm.item.Lunch);
    this.TimesheetForm.controls.item_time_hours.setValue(this.vm.item.Hours);

  }

  setupValuesForSave() {
    this.vm.item.user_id = this.TimesheetForm.controls.item_user_id.value;
    this.vm.item.rf1 = this.TimesheetForm.controls.item_header_rf_id_1.value;
    this.vm.item.rf2 = this.TimesheetForm.controls.item_header_rf_id_2.value;
    this.vm.item.rf3 = this.TimesheetForm.controls.item_header_rf_id_3.value;
    this.vm.item.rf4 = this.TimesheetForm.controls.item_header_rf_id_4.value;
    this.vm.item.rf5 = this.TimesheetForm.controls.item_header_rf_id_5.value;
    this.vm.item.date = this.TimesheetForm.controls.item_date.value;
    this.vm.item.DayOff = this.TimesheetForm.controls.item_dayoff.value;
    this.vm.item.Lunch = this.TimesheetForm.controls.item_lunch.value;
    this.vm.item.date = this.datePipe.transform(this.vm.item.date, 'MM/dd/yyyy');
    this.vm.item.Notes = this.TimesheetForm.controls.item_time_notes.value;
    this.vm.item.StartHour = this.TimesheetForm.controls.item_starthour.value;
    this.vm.item.StartMinute = this.TimesheetForm.controls.item_start_minute.value;
    this.vm.item.StartAMPM = this.TimesheetForm.controls.item_start_am_pm.value;
    this.vm.item.EndHour = this.TimesheetForm.controls.item_endhour.value;
    this.vm.item.EndMinute = this.TimesheetForm.controls.item_end_minute.value;
    this.vm.item.EndAMPM = this.TimesheetForm.controls.item_end_am_pm.value;
    // console.log('values to post patch: ' + JSON.stringify(this.vm.item));
  }


  close() {
    this.router.navigateByUrl(this.vm.mainListPage);
  }

  clear() {

  }

  saveNew() {

  }

  saveClose() {
    this.save();
    this.close();
  }

  save() {

    this.vm.buttonsDisabled = true;
    const errorMessage = '';

    if (!this.TimesheetForm.valid) {
      for (const nameOfField in this.TimesheetForm.controls) {
        if (this.TimesheetForm.controls[nameOfField].invalid) {
          this.notification.error(this.capitalizeFirstLetter(nameOfField.replace(/_/g, ' ')) + ' ' + $localize`:field is required! @@fieldisrequired:field is required!`);
        }
      }
      return false;
    }

    if (errorMessage == '') {
      this.setupValuesForSave();
      if (!this.vm.item.id) {
        this.tableDataService.post('time', this.vm.item).subscribe((item: any) => {
          this.notification.success(item.description);
          // this.router.navigateByUrl(this.vm.mainListPage);
          // this.localStorage.StorageSet('time', '');
        }, (error: any) => {
          this.vm.buttonsDisabled = false;
          if (error.error.Message !== undefined) {
            this.notification.error(error.error.Message);
          } else {
            this.notification.error(error);
          }
        });
      } else {
        this.tableDataService.patch('time', this.timesheetId, this.vm.item)
          .then((item: any) => {
           // do stuff
          })
          .catch((error: { description: any[] }) => {
            this.vm.buttonsDisabled = false;
            if (error.description !== undefined) {
              console.log(error.description);
            }
          });
      }
    } else {
      this.vm.buttonsDisabled = false;
      // this.notification.error(errorMessage);
    }
  }

  calcHours() {
    if (this.vm.item.StartAMPM == 'PM' && this.vm.item.EndAMPM == 'AM') {
      this.notification.error('Start time cannot be greater end time.');
    } else if ((this.vm.item.StartAMPM == 'AM' && this.vm.item.EndAMPM == 'AM' || this.vm.item.StartAMPM == 'PM' && this.vm.item.EndAMPM == 'PM') && Number(this.vm.item.StartHour) > Number(this.vm.item.EndHour)) {
      this.notification.error('Start time cannot be greater end time.');
    }

    this.vm.item.StartHour = this.TimesheetForm.controls.item_starthour.value;
    this.vm.item.StartMinute = this.TimesheetForm.controls.item_start_minute.value;
    this.vm.item.StartAMPM = this.TimesheetForm.controls.item_start_am_pm.value;
    this.vm.item.EndHour = this.TimesheetForm.controls.item_endhour.value;
    this.vm.item.EndMinute = this.TimesheetForm.controls.item_end_minute.value;
    this.vm.item.EndAMPM = this.TimesheetForm.controls.item_end_am_pm.value;
    this.vm.item.DayOff = this.TimesheetForm.controls.item_dayoff.value;
    this.vm.item.Lunch = this.TimesheetForm.controls.item_lunch.value;

    let Lunch = 0;
    let StartHour = Number(this.vm.item.StartHour);
    let EndHour = Number(this.vm.item.EndHour);
    this.vm.item.Hours = 0;

    if (this.vm.item.Lunch === true) {
      Lunch = 0.5;
    } else {
      Lunch = 0;
    }

    switch (this.vm.item.StartMinute) {
      case '15':
        StartHour = StartHour + 0.25;
        break;
      case '30':
        StartHour = StartHour + 0.5;
        break;
      case '45':
        StartHour = StartHour + 0.75;
        break;
    }

    switch (this.vm.item.EndMinute) {
      case '15':
        EndHour = EndHour + 0.25;
        break;
      case '30':
        EndHour = EndHour + 0.5;
        break;
      case '45':
        EndHour = EndHour + 0.75;
        break;
    }

    if (this.vm.item.StartAMPM === 'AM' && this.vm.item.EndAMPM === 'PM') {
      if (StartHour === 12) {
        this.vm.item.Hours = StartHour + EndHour - Lunch;
      } else {
        this.vm.item.Hours = (12 - StartHour) + EndHour - Lunch;
      }

    } else if (this.vm.item.StartAMPM === 'AM' && this.vm.item.EndAMPM === 'AM' || this.vm.item.StartAMPM === 'PM' && this.vm.item.EndAMPM === 'PM') {

      if (Number(this.vm.item.StartHour) > Number(this.vm.item.EndHour) && Number(this.vm.item.StartHour) != 12) {
        this.notification.error($localize`:Start time cannot be greater end time. @@starttimecannotbegreaterendtime:Start time cannot be greater end time.`);
      } else {
        if (StartHour === 12) {
          this.vm.item.Hours = EndHour - Lunch;
        } else {
          this.vm.item.Hours = EndHour - StartHour - Lunch;
        }
      }
    } else if (this.vm.item.StartAMPM === 'PM' && this.vm.item.EndAMPM === 'AM') {
      this.notification.error($localize`:Start time cannot be greater end time. @@starttimecannotbegreaterendtime:Start time cannot be greater end time.`);
    }
  }

  capitalizeFirstLetter(str): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  deleteTime() {
    this.vm.buttonsDisabled = true;
    this.tableDataService.deleteTableDataById('time', this.vm.item.id).subscribe((item: any) => {
      this.notification.error($localize`:Deleted@@deleted:Deleted`);
      this.vm.buttonsDisabled = false;
      this.router.navigateByUrl(this.vm.mainListPage);
    }, (error: { description: any[] }) => {
      this.vm.buttonsDisabled = false;
      this.notification.error(error.description + ' ' + $localize`:server side error @@serversideerror:server side error`);
    });
  }
}
