import {Component, ElementRef, ViewChild} from '@angular/core';
import {BsToastService} from './Services/bs-toast-service';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {AppConfigService} from './Services/app-config.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import {AuthService} from './Services/auth.service';
import {Modal} from 'bootstrap';
import {RoutingTrackerService} from './Services/routing-tracker.service';

@Component({
  selector: 'app-root',
  template: `
    <div class="modal fade mt-3 me-3" #idleModal style="z-index: 99999" id="idleModal" tabindex="-1" aria-labelledby="idleModalLabel" aria-hidden="true" data-bs-backdrop="false">
      <div class="modal-dialog">
        <div class="modal-content">
<!--          <div class="modal-header">-->
<!--            <h5 class="modal-title bi bi-exclamation-triangle-fill text-warning mt-1 me-1" id="exampleModalLabel"> Inactivity Timeout</h5>-->
<!--            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--          </div>-->
          <div class="modal-body">
            <h5 class="modal-title bi bi-exclamation-triangle-fill text-warning mt-1 me-1" id="exampleModalLabel"> Inactivity Timeout</h5>
            <br>
            <a class="text-warning"> {{idleState}}</a>
            <br><br>
            <div class="float-end">
            <a #idleLogoutBtn [hidden]="true" type="button" class="warning-no-data bg-warning-lighten text-lg-center text-warning me-2" data-bs-dismiss="modal" (click)="this.logOut()">Log out</a>
            <a #idleStayBtn [hidden]="true" type="button" class="warning-no-data bg-warning-lighten text-lg-center text-warning" data-bs-dismiss="modal" (click)="reset(); idleWarningShowing=false">Stay Logged In</a>
            </div>
          </div>
          </div>
<!--          <div class="modal-footer">-->
<!--            <button #idleLogoutBtn type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="auth.logout(true);idleWarningShowing=false">Log out</button>-->
<!--            <button #idleStayBtn type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="reset(); idleWarningShowing=false">Stay Logged In</button>-->
<!--          </div>-->
        </div>
      </div>
    <app-bs-toaster></app-bs-toaster>
  <router-outlet></router-outlet>`,

  styleUrls: ['./app.component.css']
})


export class AppComponent {
  title = 'app';

  idleState = 'Not started.';
  timedOut = false;
  idleWarningShowing = false;

  closeModalsBtnClicked = false;

  option: Partial<any> = {
    'data-bs-toggle': false
  };

  @ViewChild('idleLogoutBtn') idleLogoutBtn: ElementRef;
  @ViewChild('idleStayBtn') idleStayBtn: ElementRef;

  constructor( private toast: BsToastService,
               private router: Router,
               private appConfig: AppConfigService,
               private idle: Idle,
               public auth: AuthService,
               private prevUrl: RoutingTrackerService,
               private elementRef: ElementRef) {

    // console.log('TURN ME OFF IN APP COMPONENT previ url from app comp: ' + this.prevUrl.getPreviousUrl());
    // auto routing on - not recommended but user wanted so it was added. default is off
    const lastVisitedUrl: string = localStorage.getItem('last_visited_url');
    localStorage.removeItem('last_visited_url');
    if (lastVisitedUrl) {
      this.router.navigateByUrl(lastVisitedUrl).then();
    }

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          // console.log('back button clicked');
          // do stuff to handle odd back clicks
        }
      }

      if (event instanceof NavigationEnd) {
        if (this.appConfig.useLastPageAutoRouting) {
          if (event.url !== '/login'
            && event.url !== '/null'
            && event.url !== ''
            && event.url !== '/common/error500-page'
            && event.url !== '/common/error404-page') {
            localStorage.setItem('last_visited_url', event.url);
          }
        }
      }
    });

    // idle time starts here
    idle.setIdle(this.appConfig.userIdleTimeOut); // user inactivity in seconds
    idle.setTimeout(this.appConfig.userIdleCountdownLength); // countdown time in seconds.

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // there are various interrupts picked up from mouse and keyboard activity

    // console.log('user timeout value: ' + this.appConfig.userIdleTimeOutOn);
    if (this.appConfig.userIdleTimeOutOn) {
      // this is the place to do something if user comes back after being idle.
      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No Longer Idle';
        // console.log('end idle counting');
        if (this.idleWarningShowing && !this.router.url.includes('login')) {
          this.idleStayBtn.nativeElement.click();
          this.toast.showInfoToast('User activity detected ...');
        }
      });

      // this is place for redirect to login page.
      idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;

        if (!this.router.url.includes('login')) {
          this.idleWarningShowing = false;

          this.idleLogoutBtn.nativeElement.click(); // runs logout on click event via logout method below

          this.toast.showWarningToastStayOnScreen('Your session was ended due to inactivity');
        }
        this.reset();
      });

      // this is place to do something when user goes Idle.
      idle.onIdleStart.subscribe(() => {
        this.idleState = 'You have gone Idle';
      });

      // this is the place for alert to notify of impending timeout
      idle.onTimeoutWarning.subscribe((countdown) => {
        if (countdown !== 1) {
          this.idleState = 'Your session will end due to inactivity in ' + countdown + ' seconds unless activity detected';
        } else {
          this.idleState = 'Ending session and logging out ...';
        }
        // console.log(this.router.url);
        if (!this.idleWarningShowing && !this.router.url.includes('login')) {
          this.showIdleModal();
        }
        this.idleWarningShowing = true;
        // console.log('idle show status:' + this.idleWarningShowing);
      });

      this.reset(); // this method restarts the whole ng Idle process
    }
  }

  logOut() {
    this.auth.logout(true);
    this.idleWarningShowing = false;

    // some sort of bs bug - backdrop can hang around after 2 modals opened on same page. this will clean up if there
    const modalsBd = document.getElementsByClassName('modal-backdrop fade show');
    Array.from(modalsBd).forEach((x) => {
      x.remove();
    });
  }

  showIdleModal() {
    const element = document.getElementById('idleModal') as HTMLElement;
    const idleModal = new Modal(element);
    idleModal.show();
  }

  // reset idle props
  reset() {
    this.idle.watch();
    this.idleState = 'Started';
    this.timedOut = false;
    this.idleWarningShowing = false;
  }
}

