import {Component, OnInit, Input, Output, OnChanges, AfterViewInit, AfterViewChecked, AfterContentChecked} from '@angular/core';
import {DateFilterService} from '../../../../Services/dateFilter.service';

@Component({
  selector: 'app-from-to-date-select',
  templateUrl: './from-to-date-select.component.html',
  styleUrls: ['./from-to-date-select.component.css']
})
export class FromToDateSelectComponent implements OnInit, AfterContentChecked {
  @Input() vm: any;
  @Input() showSpecificDates;


  constructor(public dateFilter: DateFilterService) {
  }

  ngOnInit() {
    this.vm.monthCount = 0;
  }

  ngAfterContentChecked() {
    this.dateValueChange();
  }

  dateValueChange() {
    let monthCount;
    if (this.vm.request.toDate && this.vm.request.fromDate) {
      const tempToDate: any = new Date(this.vm.request.toDate);
      const tempFromDate: any = new Date(this.vm.request.fromDate);
      monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      if (monthCount > 0) {
        this.vm.monthCount = monthCount;
      } else {
        this.vm.monthCount = 0;
      }
    }
  }
}
