import {Injectable} from '@angular/core';
import {SharedDataUserInfoModel} from '../Models/SharedDataUserInfoModel';

@Injectable()
export class SharedDataService {

  // shared values
  private sharedPerms: string;
  private sharedPrefs: string;
  private sharedPassword: string;
  private sharedUsername: string;
  private sharedToken: string;
  private sharedUserDb: string;
  private sharedLoggedIn: boolean;
  private sharedCompany: string;
  private sharedLocation: string;
  private sharedEntity: string;
  private sharedAccount: string;
  private sharedSubAccount: string;
  private sharedInventoryItems: string;
  private sharedUsers: string;
  private sharedEntityType: string;
  private sharedCurrency: string;
  private sharedUOM: string;
  private sharedPaymentType: string;
  private sharedTax: string;
  private sharedTaxRate: string;
  private sharedTerm: string;
  private sharedModeOperations: boolean;


  private sharedRFType: string;
  private sharedRf1: string;
  private sharedRf2: string;
  private sharedRf3: string;
  private sharedRf4: string;
  private sharedRf5: string;
  private sharedSalesperson: string;
  private sharedSendEmailInfoObj: string;

  // report scratch stuff
  private sharedReportData: string;

  // object containing all user values in shared
  private sharedDataObject: SharedDataUserInfoModel = new SharedDataUserInfoModel();

  // gets
  getEntity(): string {
    return this.sharedEntity;
  }
  getReportData(): string {
    return this.sharedReportData;
  }
  getModeOperations(): boolean {
    return this.sharedModeOperations;
  }
  getRfType(): string {
    return this.sharedRFType;
  }
  getRf1(): string {
    return this.sharedRf1;
  }
  getRf2(): string {
    return this.sharedRf2;
  }
  getRf3(): string {
    return this.sharedRf3;
  }
  getRf4(): string {
    return this.sharedRf4;
  }
  getRf5(): string {
    return this.sharedRf5;
  }
  getSalesperson(): string {
    return this.sharedSalesperson;
  }
  getUOM(): string {
    return this.sharedUOM;
  }
  getPaymentType(): string {
    return this.sharedPaymentType;
  }
  getTax(): string {
    return this.sharedTax;
  }
  getTaxRate(): string {
    return this.sharedTaxRate;
  }
  getTerm(): string {
    return this.sharedTerm;
  }
  getCurrency(): string {
    return this.sharedCurrency;
  }
  getEntityType(): string {
    return this.sharedEntityType;
  }

  getUsers(): string {
    return this.sharedUsers;
  }

  getInventoryItems(): string {
    return this.sharedInventoryItems;
  }

  getAccount(): string {
    return this.sharedAccount;
  }

  getSubAccount(): string {
    return this.sharedSubAccount;
  }

  getCompany(): string {
    return this.sharedCompany;
  }

  getLocation(): string {
    return this.sharedLocation;
  }

  getLoggedIn(): boolean {
    return this.sharedLoggedIn;
  }

  getUserDb(): string {
    return this.sharedUserDb;
  }

  getToken(): string {
    return this.sharedToken;
  }

  getPermsData(): string {
    return this.sharedPerms;
  }

  getPrefsData(): string {
    return this.sharedPrefs;
  }

  getPassword(): string {
    return this.sharedPassword;
  }

  getUsername(): string {
    return this.sharedUsername;
  }

  getSendEmailInfoObj(): string {
    return this.sharedSendEmailInfoObj;
  }

  // get obj of all shared data - can add more of these by category - thats the plan anyway
   getSharedDataObject(): SharedDataUserInfoModel {
    this.sharedDataObject.Perms = this.getPermsData();
    this.sharedDataObject.Prefs = this.getPrefsData();
    this.sharedDataObject.Password = this.getPassword();
    this.sharedDataObject.Username = this.getUsername();
    this.sharedDataObject.UserDb = this.getUserDb();
    this.sharedDataObject.Token = this.getToken();
    this.sharedDataObject.LoggedIn = this.getLoggedIn();

    return this.sharedDataObject;
   }

  // adds
  addCompany(value: string): void {
    this.sharedCompany = value;
  }
  addModeOperations(value: boolean): void {
    this.sharedModeOperations = value;
  }
  addReportData(value: string): void {
    this.sharedReportData = value;
  }
  addRFType(value: string): void {
    this.sharedRFType = value;
  }
  addRf1(value: string): void {
    this.sharedRf1 = value;
  }
  addRf2(value: string): void {
    this.sharedRf2 = value;
  }
  addRf3(value: string): void {
    this.sharedRf3 = value;
  }
  addRf4(value: string): void {
    this.sharedRf4 = value;
  }
  addRf5(value: string): void {
    this.sharedRf5 = value;
  }
  addSalesperson(value: string): void {
    this.sharedSalesperson = value;
  }

  addInventoryItems(value: string): void {
    this.sharedInventoryItems = value;
  }

  addEntityType(value: string): void {
    this.sharedEntityType = value;
  }
  addTax(value: string): void {
    this.sharedTax = value;
  }
  addTaxRate(value: string): void {
    this.sharedTaxRate = value;
  }
  addPaymentType(value: string): void {
    this.sharedPaymentType = value;
  }
  addTerm(value: string): void {
    this.sharedTerm = value;
  }
  addCurrency(value: string): void {
    this.sharedCurrency = value;
  }
  addUOM(value: string): void {
    this.sharedUOM = value;
  }

  addUsers(value: string): void {
    this.sharedUsers = value;
  }

  addEntity(value: string): void {
    this.sharedEntity = value;
  }

  addAccount(value: string): void {
    this.sharedAccount = value;
  }

  addSubAccount(value: string): void {
    this.sharedSubAccount = value;
  }

  addLocation(value: string): void {
    this.sharedLocation = value;
  }

  addLoggedIn(value: boolean): void {
    this.sharedLoggedIn = value;
  }

  addUserDb(value: string): void {
    this.sharedUserDb = value;
  }

  addToken(value: string): void {
    this.sharedToken = value;
  }

  addPermsData(value: string): void {
    this.sharedPerms = value;
  }

  addPrefsData(value: string): void {
    this.sharedPrefs = value;
  }

  addUsername(value: string): void {
    this.sharedUsername = value;
  }

  addPassword(value: string): void {
    this.sharedPassword = value;
  }

  addSendEmailInfoObj(value: string): void {
    this.sharedSendEmailInfoObj = value;
  }
}

