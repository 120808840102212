import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SimplebarAngularModule } from 'simplebar-angular';

// components
import { StatisticsComponent } from './statistics/statistics.component';
import { ChartStatisticsComponent } from './chart-statistics/chart-statistics.component';
import { TodoComponent } from './todo/todo.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { MessagesComponent } from './messages/messages.component';
import { ChartComponent } from './chart/chart.component';
import { ChatComponent } from './chat/chat.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { ChartStatistics2Component } from './chart-statistics2/chart-statistics2.component';
import { TransactionComponent } from './transaction/transaction.component';
import { PricingCardComponent } from './pricing-card/pricing-card.component';
import { FaqComponent } from './faq/faq.component';
import { CardTitleComponent } from './card-title/card-title.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {SearchFilterPipe} from './search-filter/search-filter.pipe';


@NgModule({
  declarations: [
    StatisticsComponent,
    ChartStatisticsComponent,
    TodoComponent,
    PreloaderComponent,
    MessagesComponent,
    ChartComponent,
    ChatComponent,
    UserProfileComponent,
    RecentActivityComponent,
    ChartStatistics2Component,
    TransactionComponent,
    // VectormapComponent,
    // WorldMapComponent,
    // CanadaMapComponent,
    // RussiaMapComponent,
    // SpainMapComponent,
    // IraqMapComponent,
    // ItalyMapComponent,
    // UsaMapComponent,
    PricingCardComponent,
    FaqComponent,
    CardTitleComponent,
    SearchFilterPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    NgApexchartsModule,
    TooltipModule
  ],
  exports: [
    StatisticsComponent,
    ChartStatisticsComponent,
    TodoComponent,
    PreloaderComponent,
    MessagesComponent,
    ChartComponent,
    ChatComponent,
    UserProfileComponent,
    RecentActivityComponent,
    ChartStatistics2Component,
    TransactionComponent,
    // VectormapComponent,
    // WorldMapComponent,
    // CanadaMapComponent,
    // RussiaMapComponent,
    // SpainMapComponent,
    // IraqMapComponent,
    // ItalyMapComponent,
    // UsaMapComponent,
    PricingCardComponent,
    FaqComponent,
    CardTitleComponent,
    SearchFilterPipe
  ],
})
export class WidgetModule { }
