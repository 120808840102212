import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SysUserModel } from '../../../../DataModels/sys-user-model';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Subscription, take} from 'rxjs';

@Component({
  selector: 'app-sys-user-form',
  templateUrl: './sys-user-form.component.html',
  styleUrls: ['./sys-user-form.component.css'],
})
export class SysUserFormComponent implements OnInit, AfterViewInit, OnDestroy {
  vm: any = {};
  sysUser: SysUserModel;
  sysUserForm: UntypedFormGroup;
  submitted = false;
  isLoaded = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: true,
    print: false,
  };
  locales = [
    { id: 'en', name: 'United States' },
    { id: 'gb', name: 'England' },
    { id: 'cn', name: 'Canada' },
    { id: 'es', name: 'Mexico' },
    { id: 'fr', name: 'France' },
    { id: 'cn', name: 'China' },
  ];

  preferences = {
    default_company_id: '',
    ShowBatches: false,
    QuickLinks: {},
  };

  deleteButtonDisabled = true;
  subscriptionGroup: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private decimal: DecimalPipe,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.vm.item = { id: undefined };

    // set to correct value later below
    this.vm.Permissions = {};

    this.createForm();

    this.vm.sys_user_id = this.route.snapshot.params.id;
  }

  createForm() {
    this.sysUserForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      defaultCompany: new UntypedFormControl(''),
      email: new UntypedFormControl('', [Validators.required]),
      active: new UntypedFormControl(''),
      showBatches: new UntypedFormControl(false),
      // admin_user: new FormControl(false),
    });
  }

  get user() {
    return this.sysUserForm.controls;
  }

  get f() {
    return this.sysUserForm.controls;
  }

  SetValue() {
    this.sysUserForm.patchValue({
      name: this.vm.item.name,
      email: this.vm.item.email,
      active: this.vm.item.active,
      defaultCompany: this.vm.Preferences.default_company_id,
      showBatches: this.vm.Preferences.ShowBatches,
    });
    // console.log('control show: ' + this.vm.item.Preferences.ShowBatches);
  }

  ngOnInit() {
    this.isLoaded = false;
    this.vm.title = $localize`:System User @@systemUser:System User`;
    this.init();
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.subscriptionGroup.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {}

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete user? @@areYouSureYouWantToDeleteUser:Are you sure you want to delete user?`,
      this.sysUser.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.subscriptionGroup.push(this.tableDataService.deleteTableDataById('sysUser', this.sysUser.id).subscribe(
          (result) => {
            retMsg = result;
            this.notification.showSuccessToast($localize`:Deleted Canceled@@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          },
          () => {
            this.onClose();
          }
        ));
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled@@deleteCanceled:Delete Canceled`);
      }
    );
  }

  resetForm() {
    // this.sysUserForm.reset();
  }

  onClose() {
    this.router.navigateByUrl('admin/sys-user/list').then();
  }

  init() {
    this.isLoaded = false;

    if (this.vm.sys_user_id !== 'new') {
      this.buttonConfig.reset = false;
      this.subscriptionGroup.push(this.tableDataService.getTableData('sys_user', this.vm.sys_user_id).subscribe((result: any) => {
        this.vm.item = result;

        // console.log('results: ', this.vm.item);

        // this is a generic preferences template we use to setup a user - it will not be save until
        // after first edit of user, which is required to tune on user in co db
        const prefTemplate = {
          BodyClass: '',
          ShowOps: null,
          default_company_id: '',
          ShowBatches: false,
          QuickLinks: {
            Entities: true,
            Items: true,
            MarginReport: false,
            IncomeStatement: true,
            TransactionDetail: true,
            BalanceSheet: true,
            ReceivableAging: true,
            ReceivableInvoices: true,
            PayableInvoices: true,
            PurchaseOrders: true,
            PurchaseShipments: true,
            SalesOrders: true,
            SalesShipments: true,
            Transfers: true,
            WorkOrders: true,
          },
        };

        const tempPreferences = result.Preferences ? result.Preferences : JSON.stringify(prefTemplate);

        // no template used for perms - is sent from manager when user pushed to the sys_user table based on company registered.
        const tempPermissions = result.Permissions;

        if (tempPermissions) {
          this.vm.Permissions = JSON.parse(tempPermissions);
        }
        if (tempPreferences) {
          this.vm.Preferences = JSON.parse(tempPreferences);
        }

        this.SetValue();
      }));
    }

    this.subscriptionGroup.push(this.tableDataService
      .getApiListData('company', '?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      }));
  }

  ngAfterViewInit() {
    if (this.vm.sys_user_id !== 'new') {
      this.vm.title = $localize`:Edit @@edit:Edit ` + this.vm.title;
    } else {
      this.vm.title = $localize`:New @@new:New ` + this.vm.title;
    }
    this.titleService.setTitle(this.vm.title);
  }

  onSave(saveClose: boolean) {
    this.submitted = true;

    // console.log('perms: ' + JSON.stringify(this.vm.item.Permissions));
    // console.log('prefs: ' + JSON.stringify(this.vm.item.Preferences));

    if (this.sysUserForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }

    this.sysUser = Object.assign({}, this.sysUserForm.value);

    // to satisfy api model and since we do not allow pw CRUD here
    this.sysUser.password = 'deprecated in api v2';

    this.sysUser.Permissions = JSON.stringify(this.vm.Permissions);

    // this because they originally stored the values as json ... sigh. bad idea, but we can make it work. Move to table later
    // turned into obj on get then set value of props of the obj here and then stringify before store
    this.preferences.default_company_id = this.sysUserForm.get('defaultCompany').value;
    this.preferences.ShowBatches = this.sysUserForm.get('showBatches').value;

    // ql not set here - thru ui and selections made in header - but need to make part of prefs string to save correctly
    this.preferences.QuickLinks = this.vm.Preferences.QuickLinks;

    this.sysUser.Preferences = JSON.stringify(this.preferences);

    this.sysUser.admin_user = this.vm.Permissions.Admin;

    this.sysUser.audit_sequence = this.vm.audit_sequence;

    // console.log('perms: ' + this.sysUser.Permissions);
    // console.log('prefs: ' + this.sysUser.Preferences);
    // console.log('sysuser: ' + JSON.stringify(this.sysUser));

    if (this.vm.sys_user_id) {
      if (this.sysUserForm.invalid === false) {
        this.tableDataService.patch('sys_user', this.vm.sys_user_id, this.sysUser).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.tableDataService.postWithPromise('sys_user', this.sysUser).then((result) => {
        if (saveClose) {
          this.onClose();
        }
      });

      // this.notification.success($localize`:Saved@@saved:Saved`);
      // this.saveCompanyAccess(this.vm.sys_user_id);
      // return true;
    }
    this.submitted = false;
    return true;
  }
// TODO add in company and enity access here after we figure out subscription scheme as it relates to multicompany access by user by fee etc.
  saveCompanyAccess(id) {
    for (const company of this.vm.company) {
      if (company.access === true) {
        this.vm.tempCompanyAccess = { user_id: id, company_id: company.id };
        this.tableDataService.post('company_access', this.vm.tempCompanyAccess).pipe(take(1))
          .subscribe({
            next: (item: any) => {
            // this.notification.success('Company Access Updated');
          },
           error: (err: any) => {
            this.notification.showWarningToast(
              $localize`:Company access in User Setup failed @@companyAccessInUserSetupFailed:Company access in User Setup failed`
            );
            console.log(err.message);
          },
          complete: () => {
          },
      });
      }
    }
  }
}

