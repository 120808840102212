import { Component, OnInit, AfterViewInit, AfterViewChecked, ViewChild, LOCALE_ID } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TableDataService } from '../../../../Services/tableData.service';
import { StorageService } from '../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { BankReconciliationModel } from '../../../../DataModels/bank-reconciliation-model';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BankReconciliationDetailModel } from '../../../../DataModels/bank-reconciliation-detail-model';
import { DecimalPipe, DatePipe } from '@angular/common';
import { ResponseModel } from '../../../../DataModels/response-model';
import { ClearedDataModel } from '../../../../DataModels/cleared-data-model';
import { BsToastService} from '../../../../Services/bs-toast-service';

@Component({
  selector: 'app-bank-reconciliation-form',
  templateUrl: './bank-reconciliation-form.component.html',
  styleUrls: ['./bank-reconciliation-form.component.css'],
})
export class BankReconciliationFormComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('transactions') transactions: any;

  public vm: any = {};

  bankReconciliationId: string;
  bankReconciliationCount = 0;
  bankReconciliationForm: UntypedFormGroup;
  reconRows: UntypedFormGroup;
  currency = [];
  bank = [];
  company = [];
  bankReconciliation: BankReconciliationModel;
  bankRecDetail: BankReconciliationDetailModel;
  clearedItems: any;
  bankReconciliationData: any = [];
  bankTransactions = [];
  isBankLoaded = false;
  isLoaded = false;
  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: false,
    saveClose: true,
  };

  companyError;
  currencyError;
  currency_id;
  showDelete;
  hidecleared;
  clearedDate;
  bsConfig;
  editMode = false;
  clrDate = '';
  clearedTransactions: any;
  submitted = false;
  reconLineItems: UntypedFormArray;
  statementToBookVariance: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    private titleService: Title,
    private confirmDialogService: ConfirmDialogService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
  ) {
    // this.vm.bankTransactions = [];
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.createForm();
      this.bankReconciliationId = this.route.snapshot.params.id;
    });
  }

  ngOnInit() {
    this.bsConfig = {
      dateInputFormat: 'mm/dd/yyyy',
    };
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
      this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
      // console.log('id', this.bankReconciliationId);
      this.loadCurrency();
      this.loadBank();
      this.loadCompany();
      if (this.bankReconciliationId !== 'new') {
        this.loadBankReconciliation();
        this.editMode = true;
        this.bankReconciliationForm.get('account_id').disable();
      } else {
        this.isLoaded = true;
      }
      this.vm.title = $localize`:Bank Reconciliation @@bankReconciliation:Bank Reconciliation`;
      this.vm.buttonsDisabled = false;
      this.disablefields(false);
      this.reconLineItems = this.bankReconciliationForm.get('reconItems') as UntypedFormArray;
    });
  }

  ngAfterViewInit() {
    // this.titleService.setTitle(this.vm.title);
    this.titleService.setTitle($localize`:Bank Reconciliation@@bankReconciliation:Bank Reconciliation`);
  }

  ngAfterViewChecked() {}

  createForm() {
    this.bankReconciliationForm = this.formBuilder.group({
      account_id: ['', Validators.required],
      reconciliation_date: ['', Validators.required],
      balance: ['', Validators.required],
      company_id: [''],
      currency_id: [''],
      reconciled: [false],
      reconItems: this.formBuilder.array([]),
    });
  }

  reconItems(): UntypedFormArray {
    const items = this.bankReconciliationForm.get('reconItems') as UntypedFormArray;
    // items.clear();
    return items;
  }

  getArrayControlValue(index: number, controlName: string): string {
    // console.log(index);
    const controlArray = this.bankReconciliationForm.get('reconItems') as UntypedFormArray;
    // console.log(controlName + ': ' + controlArray.controls[index].get(controlName).value);
    return controlArray.controls[index].get(controlName).value;
  }

  isValidInput(fieldName): boolean {
    return (
      this.bankReconciliationForm.controls[fieldName].invalid &&
      (this.bankReconciliationForm.controls[fieldName].dirty || this.bankReconciliationForm.controls[fieldName].touched)
    );
  }

  loadFormArray(data: any) {
    const reconsData = data[0].transactions;
    // console.log('creating formgroup from these values: ' + JSON.stringify(reconsData));
    for (const item of reconsData) {
      this.reconItems().push(this.newReconItemWithData(item));
    }
  }

  newReconItemWithData(data: any = null): UntypedFormGroup {
    // console.log ('create one row with these values: ' + JSON.stringify(data));
    data = data || {
      isReconciled: false,
      datePickerClearedDate: '',
      clearedId: '',
      enteredDate: '',
      detailId: '',
      deposit: '',
      withdrawal: '',
    };

    if (data.cleared_date) {
      this.clrDate = this.datePipe.transform(data.cleared_date, 'MM/dd/yyyy');
    }

    this.reconRows = this.formBuilder.group({
      isReconciled: [data.cleared_date ? true : false],
      datePickerClearedDate: [
        data.cleared_date ? this.datePipe.transform(data.cleared_date, 'MM/dd/yyyy') : data.cleared_date,
      ],
      clearedId: [data.cleared_id],
      enteredDate: [this.datePipe.transform(data.header_date, 'MM/dd/yyyy')],
      detailId: [data.detail_id],
      deposit: [data.debit_credit > 0 ? this.makeDecimal(data.debit_credit) : 0],
      withdrawal: [data.debit_credit < 0 ? this.makeDecimal((data.debit_credit * -1).toString()) : 0],
    });
    return this.reconRows;
  }

  makeDecimal(num): any {
    // console.log('make decimal: ' + num);
    return this.decimalPipe.transform(num, '1.2-2');
    // return num;
  }

  removeThousandsSeparator(str: string, marker: string): string {
    while (str.toString().search(marker) >= 0) {
      str = (str.toString() + '').replace(marker, '');
    }
    return str;
  }

  createFeeEntry() {
    window.open('/transactions/entry/form?entryId=new');
    // this.router.navigate(['/transactions/entry/form?entryId=new']);
    // this.router.navigateByUrl('/transactions/entry/form?entryId=new');
  }

  getDecimalSeparator(locale) {
    // a hack but reliable way to get separator
    if (locale === '') {
      locale = LOCALE_ID;
    }
    const numberWithDecimalSeparator = 1000.0;

    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }

  spinDatetoUS(someDate: string) {
    // console.log('fixed date: ' + someDate.substring(5, 7) + '/' + someDate.substring(8, 10) + '/' + someDate.substring(0, 4));
    return this.datePipe.transform(someDate, 'MM/dd/yyyy');
    // return someDate.substring(5, 7) + '/' + someDate.substring(8, 10) + '/' + someDate.substring(0, 4);
  }

  private loadBankReconciliation() {
    this.tableDataService.getTableData('bank_reconciliation', this.bankReconciliationId).subscribe(
      (result) => {
        this.bankReconciliation = result;
        this.bankReconciliationData = result;
        this.bankReconciliationCount = result.length;
        // console.log('br res: ' + JSON.stringify(this.bankReconciliationData));
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Bank Reconciliation @@errorloadingBankReconciliation:Error loading Bank Reconciliation`
        // );
        console.log(error + ' - ' + $localize`:Error loading Bank Reconciliation @@errorloadingBankReconciliation:Error loading Bank Reconciliation`);
      },
      () => {
        if (this.bankReconciliation.reconciliation_date) {
          this.bankReconciliation.reconciliation_date = this.dateFilter.GetDateFormat(
            this.bankReconciliation.reconciliation_date
          );
        }
        this.bankReconciliation.balance = this.removeThousandsSeparator(
          this.makeDecimal(this.bankReconciliation.balance),
          this.getDecimalSeparator(LOCALE_ID)
        );
        this.bankReconciliationForm.patchValue(this.bankReconciliation, { emitEvent: false });
        // this.loadReconItemsCleared(this.bankReconciliationId);
        this.update();
      }
    );
  }

  private loadCurrency() {
    this.tableDataService.getTableData('currency', '?state=active&pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.currency = result.Data;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Bank Reconciliation @@errorloadingBankReconciliation:Error loading Bank Reconciliation`
        // );
        console.log(error);
      }
    );
  }

  private loadBank() {
    this.tableDataService.getTableData('bank', '?state=active&pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.bank = result.Data;
        this.isBankLoaded = true;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Bank Reconciliation @@errorloadingBankReconciliation:Error loading Bank Reconciliation`
        // );
        console.log(error);
      }
    );
  }

  private loadCompany() {
    this.tableDataService.getApiListData('company', '?state=active&pageNumber=0&pageSize=999999').subscribe(
      (result: ResponseModel) => {
        this.company = result.Data;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Bank Reconciliation @@Error loading Bank Reconciliation:Error loading Bank Reconciliation`
        // );
        console.log(error);
      }
    );
  }

  loadReconItemsCleared(reconId: string) {
    this.tableDataService.getTableData('DetailCleared', '?id=' + reconId).subscribe(
      (result: any) => {
        this.clearedItems = result;
        // console.log('cleared data: ' + JSON.stringify(result));
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Bank Reconciliation line items @@Error loading Bank Reconciliation line items:Error loading Bank Reconciliation line items`
        // );
        console.log(error);
      }
    );
  }
  disablefields(disable: boolean) {
    if (disable) {
      this.bankReconciliationForm.get('reconciliation_date')?.disable();
      this.bankReconciliationForm.get('balance')?.disable();
    } else {
      this.bankReconciliationForm.get('reconciliation_date')?.enable();
      this.bankReconciliationForm.get('balance')?.enable();
    }
  }

  update() {
    this.submitted = true;
    if (this.bankReconciliationForm.invalid) {
      this.notification.showWarningToast($localize`:Form error(s) exist @@formError(s)Exit:Form error(s) exist`);
      return;
      // if (!this.bankReconciliationForm.controls.reconciliation_date.value) {
      //   this.notification.error($localize`:Reconciliation Date is required @@reconciliationDateisrequired:Reconciliation Date is required`);
    } else {
      this.vm.buttonsDisabled = true;
      this.disablefields(true);
      this.vm.dateError = false;

      this.tableDataService
        .getTableData(
          'bank_reconciliation/transactions',
          '?account_id=' +
            this.bankReconciliationForm.controls.account_id.value +
            '&toDate=' +
            this.datePipe.transform(this.bankReconciliationForm.controls.reconciliation_date.value, 'MM/dd/yyyy') +
            '&bank_rec_id=' +
            this.bankReconciliationId
        )
        .subscribe(
          (result) => {
            this.bankTransactions = result;
            // console.log('transactions: ' + JSON.stringify(this.bankTransactions));
            this.loadFormArray(this.bankTransactions);
            this.calcTotals();
            this.vm.buttonsDisabled = false;
            this.disablefields(false);

            // format dates for display
            this.bankTransactions[0].fromDate = this.datePipe.transform(
              this.bankTransactions[0].fromDate,
              'MM/dd/yyyy'
            );

            // for (const [x, value] of Object.entries(this.bankTransactions[0].transactions)) {
            //
            //   if (this.bankTransactions[0].transactions[x].cleared_date) {
            //     this.bankTransactions[0].transactions[x].cleared_date = this.datePipe.transform(this.bankTransactions[0].transactions[x].cleared_date, 'MM/dd/yyyy');
            //   }
            //
            //   if (this.bankTransactions[0].transactions[x].header_date) {
            //     this.bankTransactions[0].transactions[x].header_date = this.datePipe.transform(this.bankTransactions[0].transactions[x].header_date, 'MM/dd/yyyy');
            //   }
            // }
            this.submitted = false;
          },
          (error: HttpErrorResponse) => {
            // this.notification.error(
            //   $localize`:Error loading Bank Reconciliation @@errorloadingBankReconciliation:Error loading Bank Reconciliation`
            // );
            console.log(error);
          }
        );
    }
  }

  onClose() {
    this.router.navigate(['transactions/bank-reconciliation/bank-reconciliation-list']).then();
  }

  onSubmit() {}

  get f(): { [key: string]: AbstractControl } {
    return this.bankReconciliationForm.controls;
  }

  onSave() {
    this.submitted = true;
    if (this.bankReconciliationForm.invalid) {
      console.log('some error in post / patch');
      return;
    }

    this.vm.buttonsDisabled = true;
    this.disablefields(true);
    // NOTE *** api has to post in 2 pieces as was originallly designed - first to recon table (its really the header)
    // then recons t detail cleared table (transactions of recon). The api doesnt currently understand header / detail in single object
    // post so we have to pick out the pieces we need for hdr post only, then do detail to cleared.
    const postReconHdrData = {
      account_id: this.bankReconciliationForm.get('account_id').value,
      reconciliation_date: this.datePipe.transform(
        this.bankReconciliationForm.get('reconciliation_date').value,
        'MM/dd/yyyy'
      ),
      balance: this.bankReconciliationForm.get('balance').value,
      company_id: this.bankReconciliationForm.get('company_id').value,
      currency_id: this.bankReconciliationForm.get('currency_id').value,
      reconciled: this.bankReconciliationForm.get('reconciled').value,
    };

    if (this.bankReconciliation === undefined) {
      this.bankReconciliation = new BankReconciliationModel();
      this.bankReconciliation.balance = this.bankReconciliationForm.get('balance').value;
      this.bankReconciliation.account_id = this.bankReconciliationForm.get('account_id').value;
      this.bankReconciliation.reconciled = this.bankReconciliationForm.get('reconciled').value;
      this.bankReconciliation.reconciliation_date = this.datePipe.transform(
        this.bankReconciliationForm.get('reconciliation_date').value,
        'MM/dd/yyyy'
      );
    }
    if (this.bankReconciliationId === 'new') {
      // new
      this.tableDataService.postWithPromise('bank_reconciliation', postReconHdrData).then(
        (item: any) => {
          this.bankReconciliationForm.reset(this.bankReconciliationForm.value);
          // this.notification.success(item.description);
          // console.log('post return: ' + JSON.stringify(item));
          this.bankReconciliationId = item.id;

          return true;
        },
        (error: HttpErrorResponse) => {
          // this.notification.error(
          //   $localize`:Error creating new Bank Reconciliation @@errorcreatingnewBankReconciliation:Error creating new Bank Reconciliation`
          // );
          console.log(error);
        }
      );
    } else {
      // edit
      this.tableDataService.patch('bank_reconciliation', this.bankReconciliationId, postReconHdrData).then(
        (item: any) => {
          // console.log('patched values: ' + JSON.stringify(postReconHdrData));
          // console.log('formvalue: ' + JSON.stringify(this.bankReconciliationForm.value));
          // this.notification.success($localize`:Updated@@updated:Updated`);
          return true;
        },
        (error: HttpErrorResponse) => {
          // this.notification.error(
          //   $localize`:Error updating Bank Reconciliation @@errorupdatingBankReconciliation:Error updating Bank Reconciliation`
          // );
          console.log(error);
        }
      );
    }

    this.saveClearedItems();
    this.submitted = false;
  }

  saveClose() {
    this.onSave();
    this.router.navigate(['transactions/bank-reconciliation/bank-reconciliation-list']).then();
  }

  calcTotals() {
    if (this.bankReconciliation === undefined) {
      return;
    }
    this.vm.unclearedItems = 0;
    this.vm.clearedItems = 0;
    this.statementToBookVariance = 0;
    let curRec: any;
    // const tempReconciliationDate = new Date(this.bankReconciliation.reconciliation_date);
    // console.log('temp rec date: ' + tempReconciliationDate);
    if (this.bankTransactions.length > 0) {
      for (const [x, value] of Object.entries(this.bankTransactions[0].transactions)) {
        if (!this.bankTransactions[0].transactions[x].cleared_date) {
          // console.log('cleared date: ' + this.bankTransactions[0].transactions[x].cleared_date + ' rec amt: ' + this.bankTransactions[0].transactions[x].debit_credit);
          this.vm.unclearedItems += this.bankTransactions[0].transactions[x].debit_credit;
          curRec = x;
        } else {
          // const tempClearedDate = new Date(this.bankTransactions[0].transactions[x].cleared_date);
          // console.log('else temp cleared date: ' + tempClearedDate + ' temp recon date: ' + tempReconciliationDate + ' amt: ' + this.bankTransactions[0].transactions[x].debit_credit);
          this.vm.clearedItems += this.bankTransactions[0].transactions[x].debit_credit;
          curRec = x;
        }
      }
      this.isLoaded = true;
      // console.log('cleared item: ' + this.vm.clearedItems + ' uncleared: ' + this.vm.unclearedItems);
      this.statementToBookVariance = this.vm.unclearedItems;
    }
  }

  setClearDateWhenChecked($event) {
    this.clearedDate = this.dateFilter.GetDateFormat($event);
  }

  statementBalanceChanged(value) {
    // console.log('bal value: ' + value);
    this.bankReconciliationData.balance = value;
  }

  onReconciledCheckboxChange(e: any, deposit: any, withdrawal: any, i: any) {
    const marker = this.getDecimalSeparator(LOCALE_ID);
    deposit = Number(this.removeThousandsSeparator(deposit, marker));
    withdrawal = Number(this.removeThousandsSeparator(withdrawal, marker));

    if (e.target.checked) {
      // console.log('dep type: ' + typeof(deposit) + ' value: ' + deposit);
      if (deposit !== 0) {
        this.vm.unclearedItems -= deposit;
      } else if (withdrawal !== 0) {
        this.vm.unclearedItems += withdrawal; // becasue its a neg number in data summed to get base
      }
      // this.vm.unclearedItems -= (+deposit + -withdrawal);
      // console.log('checked: deposit=' + deposit + ' withdrawal=' + withdrawal + ' uncleared totalizer: ' + this.vm.unclearedItems);
      // console.log(this.vm.unclearedItems);
      // reconItems.get('datePickerClearedDate')[0].patch('12/31/2022');
      this.reconLineItems
        .at(i)
        .get('datePickerClearedDate')
        .setValue(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
    } else {
      if (withdrawal !== 0) {
        this.vm.unclearedItems -= withdrawal;
      } else if (deposit !== 0) {
        this.vm.unclearedItems += deposit;
      }
      // console.log('UNchecked: deposit=' + deposit + ' withdrawal=' + withdrawal + ' uncleared totalizer: ' + this.vm.unclearedItems);
      this.reconLineItems.at(i).get('datePickerClearedDate').setValue('');
    }
    this.statementToBookVariance = this.bankTransactions[0].endingBalance - this.vm.unclearedItems;

    // let user know when balanced
    if (+this.makeDecimal(this.vm.unclearedItems) == 0.0) {
      this.notification.showSuccessToast('This account is now reconciled to book');
    }
  }

  saveClearedItems() {
    const reconItems = this.bankReconciliationForm.get('reconItems') as UntypedFormArray;
    this.vm.buttonsDisabled = true;
    this.disablefields(true);
    this.clearedTransactions = new ClearedDataModel();
    this.clearedTransactions.item = [];
    for (const i of reconItems.controls) {
      if (i.get('isReconciled').value === true) {
        // console.log('datepick val in if : ' + i.get('datePickerClearedDate').value);
        // console.log('recon chk val in if: ' + i.get('isReconciled').value);
        this.clearedTransactions.item.push({
          cleared_date: new Date(i.get('datePickerClearedDate').value).toISOString(),
          detail_id: i.get('detailId').value,
          recon_id: this.bankReconciliationId,
          id: '',
        });
      }
    }
    // console.log('posting detail: ' + JSON.stringify(this.clearedTransactions));
    if (this.clearedTransactions.item.length > 0) {
      this.tableDataService.post('DetailCleared', this.clearedTransactions).subscribe(
        (item: any) => {
          // this.calcTotals();
          this.vm.buttonsDisabled = false;
          this.disablefields(false);
          this.clearedTransactions = [];
        },
        (error: HttpErrorResponse) => {
          // this.notification.error($localize`:Error clearing detail @@errorclearingdetail:Error clearing detail`);
          console.log(error);
          this.clearedTransactions = [];
        }
      );
    } else {
      this.deleteBankRecClearedData(this.bankReconciliationId);
    }
  }

  deleteBankRecClearedData(id) {
    this.tableDataService.deleteTableDataById('DetailCleared', id).subscribe(
      (item: any) => {
        // this.notification.error($localize`:Deleted Canceled@@deleted:Deleted`);
        this.vm.buttonsDisabled = false;
        this.disablefields(false);
      },
      (error: HttpErrorResponse) => {
        this.vm.buttonsDisabled = false;
        this.disablefields(false);
        // this.notification.error($localize`:Error updating detail @@errorupdatingdetail:Error updating detail`);
        console.log(error);
      }
    );
  }

  deleteItem() {
    const brClass = this;
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete record? @@areyousureyouwanttodeleterecord:Are you sure you want to delete record?`,
      this.bankReconciliation.bank_name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.tableDataService.deleteTableDataById('bank_reconciliation', this.bankReconciliationData.id).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.showSuccessToast($localize`:Deleted Canceled@@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        brClass.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }
}
