import {inject, Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, CanActivateChildFn} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})

class PermissionsService {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authService.isAuthenticated();
    // console.log('in main auth guard: ' + isLoggedIn);
    if (isLoggedIn) {
      return true;
    } else {
      return false;
    }
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    const isLoggedIn = this.authService.isAuthenticated();
    // console.log('in child auth guard: ' + isLoggedIn);
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigateByUrl('/login').then(r => {});
      return false;
    }
  }
}
export const AuthGuard: CanActivateChildFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivateChild(next, state);
};
  // old pre 15 code
// export class AuthGuard implements CanActivate, CanActivateChild {
//   constructor(private authService: AuthService,
//               private router: Router,
//               private storage: StorageService) {
//   }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
  //   const a = route;
  //   const b = state;
  //   const isLoggedIn = this.authService.isAuthenticated();
  //   // console.log('in main auth guard: ' + isLoggedIn);
  //   if (isLoggedIn) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
  //   const a = route;
  //   const b = state;
  //   // if (b.url === '/Login') { return true; }
  //   const isLoggedIn = this.authService.isAuthenticated();
  //   // console.log('in child auth guard: ' + isLoggedIn);
  //   if (isLoggedIn) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // }
