import {AfterViewInit, OnInit, Component, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { Router } from '@angular/router';
import { StorageService } from '../../../../Services/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../../../../Services/app-config.service';
import { NotificationService } from '../../../../Services/notification.service';
import { EodHeaderModel } from '../../../../DataModels/eod-header-model';
import { AuditTrailModel } from '../../../../DataModels/audit-trail-model';
import { SysUserModel } from '../../../../DataModels/sys-user-model';
import { ResponseModel } from '../../../../DataModels/response-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateService } from '../../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../../Services/ngbpagination-config.service';
import {DataExportService} from '../../../../Services/dataExport.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-audit-trail-viewer',
  templateUrl: './audit-trail-viewer-form.component.html',
  styleUrls: ['./audit-trail-viewer-form.component.css'],
})
export class AuditTrailViewerFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private dayCount: number;

  constructor(
    private table: TableDataService,
    private router: Router,
    private storage: StorageService,
    private appConfig: AppConfigService,
    private notification: NotificationService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    public dataExport: DataExportService,
    private titleService: Title
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  tableToGet = '';
  datasetName = '';
  // exportDT: Date;
  selectedBDay = '';
  selectedUser = '';
  eod: AuditTrailModel;
  auditDataCount = 0;
  // accounts$: Observable<any>;
  userCount = 0;
  itemSalesCount = 0;
  currencyCount = 0;
  isLoaded = false;
  // dayList: Array<AuditTrailModel>;
  dayList: any;
  userList: Array<SysUserModel>;
  selectedTable: any;
  tableList: any;

  // excel export stuff
  @ViewChild('auditTrailList') auditTrailList: ElementRef;

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // ngb paginate
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  vm: any = {
    list: [],
  };

  ngOnInit() {
    this.vm.list = [];
    this.configDataTable();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([3, 'asc']);
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Audit Trail Viewer @@AuditTrailViewer:Audit Trail Viewer`);
    this.getBusinessDayData();
  }


  excelBtnOnClick() {
    const currentDate = new Date().toDateString();
    const table = this.auditTrailList.nativeElement;

    this.dataExport.exportToExcel(table, 'Audit_Trail ' + currentDate + '.xlsx', currentDate);
  }

  // define column and data fields in vars then set the grid to the values you want by type of dataset selected by user

  onChangeBDay(event): void {
    const newVal = event.target.value;
    // alert(newVal);
    this.selectedBDay = newVal;
    this.userList = null; // clear store  list because date change
    this.getusers(); // now we know the date so lets go get stores for that date - binds list var on get
  }

  onChangeUser(event): void {
    const newVal = event.target.value;
    // alert(newVal);
    this.selectedUser = newVal;
    // get list for tables now that we know user
    // console.log('selected user: ' + this.selectedUser);
    this.getTables(this.selectedUser);
    // console.log('finished onchguser');
  }

  onChangeDataset(event): void {
    const newVal = event.target.value;
    this.selectedTable = newVal;
    // this.source.localdata = null;
    this.datasetName = '';
  }

  getWidth(): any {
    return '100%';
  }

  getData(value: string) {

    this.tableToGet = value;

    this.datasetName = this.tableToGet;

    this.table
      .getTableDataWithParams(
        this.tableToGet + '/userdate',
        'userId=' + this.selectedUser + '&recDate=' + this.selectedBDay + '&table=' + this.selectedTable
      )
      .subscribe(
        (result) => {
          this.vm.list = result;
          this.totalRecords = result.length;
          this.dtTrigger.next('');
          this.dataTableReady = true;
          // console.log('audit trail data by userid:' + JSON.stringify(result));
          this.auditDataCount = result.length;
          this.isLoaded = true;
          this.userCount = this.vm.list.length;
          this.dataTableReady = true;
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
    // this.getItemSalesSummaryData(value);
  }

  getusers() {
    this.table.getTableData('sys_user').subscribe(
      (result: ResponseModel) => {
        // console.log('users:' + JSON.stringify(result));

        this.userList = result.Data;
        // this.userCount = this.userList.length;
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error loading User data @@errorLoadingUserData:Error loading User data`);
        console.log(error);
      },
      () => {}
    );
  }

  getTables(user: string) {
    user = this.selectedUser;
    const sqlDate = new Date(this.selectedBDay).toDateString();
    this.table
      .getTableDataWithParams('audit_trail/tables', 'getTables=true&userId=' + user + '&recDate=' + sqlDate)
      .subscribe(
        (result) => {
          this.tableList = result;
          // console.log('tables:' + result);
        },
        (error: HttpErrorResponse) => {
          this.notification.error(
            $localize`:Error loading Table data @@errorLoadingTableData:Error loading Table data`
          );
          console.log(error);
        },
        () => {}
      );
  }

  getBusinessDayData() {
    this.table.getTableDataWithParams('audit_trail/dates', 'getDates=true').subscribe(
      (result) => {
        this.dayList = result;
        // console.log('date:' + result);
        // this.userList = result;
        this.dayCount = this.dayList.length;
      },
      (error: HttpErrorResponse) => {
        this.notification.error($localize`:Error loading EOD data @@errorLoadingEODData:Error loading EOD data`);
        console.log(error);
      },
      () => {}
    );
  }

  // getItemSalesSummaryData(value: string) {
  //   // just for testing - link it up to the table data type selected after add more api queries etc.
  //   if (value == '0') {
  //     this.tableToGet = 'MenuItemSales';
  //   } else if (value == '1') {
  //     this.tableToGet = 'ItemSales';
  //   }
  //
  //   this.table.getTableData(this.tableToGet, '?fromdate=' + '01-01-2019' + '&todate=' + '01-03-2019').subscribe(
  //     result => {
  //       this.chartData = result;
  //       // console.log(JSON.stringify(result));
  //     },
  //     (error: HttpErrorResponse) => {
  //       this.toastr.error(error.message, 'Error loading POS Chart Data', {
  //         timeOut: 1000
  //       });
  //       console.log(error);
  //     },
  //     () => {
  //     }
  //   );
  //   // chart display settings
  //   this.primaryXAxis = {valueType: 'Category', title: 'Items', labelIntersectAction: 'Rotate45'};
  //   this.primaryYAxis = {title: 'Sales'};
  //   this.tooltip = { enable: true };
  //   this.marker = { visible: true, width: 10, height: 10 };
  //   this.emptySeries1 = {mode: 'Gap'};
  //   this.emptySeries2 = {mode: 'Average'};
  //   this.border = { width: 2, color: this.appConfig.chartBorderColor};
  //   this.margin = { left: 40, right: 40, top: 40, bottom: 40 };
  //   this.animation = { enable: true};
  //   // this.chart.animate(5);
  //   this.title = 'Top 10 Item Sales Summary';
  // }

  print() {
    // this.chart.print();
  }

  onNavigate(aID: string) {
    // this.router.navigate(['admin/bank-form', {id: aID}]);
  }

  onClose() {
    this.router.navigate(['home']).then();
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
