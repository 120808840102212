import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppConfigService} from '../../../Services/app-config.service';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import {RoutingTrackerService} from '../../../Services/routing-tracker.service';

@Component({
  selector: 'app-error400-page',
  templateUrl: './error400-page.component.html',
  styleUrls: ['./error400-page.component.css']
})
export class Error400PageComponent implements OnInit, AfterViewInit {

  constructor(
    private appConfig: AppConfigService,
    private location: Location,
    private routeTracker: RoutingTrackerService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    // console.log(this.routeTracker.getCurrentUrl());

    // const leftMenuColor = this.appConfig.leftMenuThemeColor;
    const bodyThemeColor = this.appConfig.bodyThemeColor;
    // document.body.setAttribute('data-leftbar-theme', leftMenuColor);
    document.body.setAttribute('data-layout-color', bodyThemeColor);

    setTimeout(() => {
      // this._activateMenu();
    });

    // document.body.setAttribute('data-layout-color', leftMenuColor);
  }

  back(): void {
    this.location.back();
    // if (prevPage?.length > 0) {
    //   console.log('prevpage back length : ' + prevPage?.length);
    //    this.location.historyGo(-2);
    //  } else {
    //    this.router.navigateByUrl('/login').then();
    //  }
  }

  home(): void {
    const prevPage = this.routeTracker.getPreviousUrl();
    if (prevPage?.length > 0) {
      // this.router.navigateByUrl('/home').then();
      this.router.navigate(['/home']).then();
    } else {
      // this.router.navigateByUrl('/login').then();
      this.router.navigate(['/login']).then();
    }
  }
}
