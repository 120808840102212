import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-margin-select',
  templateUrl: './margin-select.component.html',
  styleUrls: ['./margin-select.component.css']
})
export class MarginSelectComponent implements OnInit {
  @Input() vm: any;
  constructor() { }

  ngOnInit() {
  }

}
