/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Toast } from 'bootstrap';
import { fromEvent, take } from 'rxjs';
import { BsToastEventTypes } from 'src/app/DataModels/bs-toast-event-types';
import { AppConfigService } from '../../../Services/app-config.service';

@Component({
  selector: 'app-bs-toast',
  templateUrl: './bs-toast-component.html',
  styleUrls: ['./bs-toast-component.scss'],
})
export class BsToastComponent implements OnInit {
  constructor(private appConfig: AppConfigService) {}

  @Output() disposeEvent = new EventEmitter();

  @ViewChild('toastElement', { static: true })
  toastEl!: ElementRef;

  @Input()
  type!: BsToastEventTypes;

  @Input()
  title!: string;

  @Input()
  message!: string;

  toast!: Toast;

  ngOnInit() {
    this.show();
  }

  show() {
    this.toast = new Toast(
      this.toastEl.nativeElement,
      this.type === BsToastEventTypes.WarningStayOnScreen || this.type === BsToastEventTypes.ErrorStayOnScreen
        ? { autohide: false }
        : { delay: this.appConfig.bsToastOnScreenTime }
    );

    fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.hide());

    this.toast.show();
  }

  hide() {
    this.toast.dispose();
    this.disposeEvent.emit();
  }
}
