import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {IsFilterOnService} from '../../../../Services/is-filter-on.service';

@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.css']
})
export class AccountSelectComponent implements OnInit, OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @Input() vm: any;
  @Output() selectAccountEmit = new EventEmitter<string>();
  @Output() updateAccountEmit = new EventEmitter<string>();
  @Output() testAccountEmit = new EventEmitter<string>();
  public accounts: any;

  constructor(public isFiltered: IsFilterOnService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.closeButton.nativeElement.click();
  }

  updateAccount() {
    this.updateAccountEmit.emit();
  }

  selectAccount(accountId) {
    // $('#accountSelect').modal('hide');
    this.selectAccountEmit.emit(accountId);
  }

  testAccount($event = null) {
    this.testAccountEmit.emit($event);
  }

  checkFiltered(): boolean {
    const filteredValue = this.isFiltered.isFiltered(this.vm);
    this.vm.filtered = filteredValue;
    return filteredValue;
  }
}
