import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '../../../../../Services/email.service';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { EmailPassDataServiceService } from '../../../../../Services/email-pass-data-service.service';
import { BsToastService } from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-payable-report',
  templateUrl: './payable-report.component.html',
  styleUrls: ['./payable-report.component.css'],
})
export class PayableReportComponent implements OnInit, AfterViewInit, AfterViewChecked {
  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    public appConfigService: AppConfigService,
    public route: ActivatedRoute,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    public notification: BsToastService,
    private emailData: EmailPassDataServiceService,
    private router: Router
  ) {
    this.timeOut = this.appConfigService.toastrWarnTimeOut;
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;

  emailForm: UntypedFormGroup;
  private rptName: string;
  timeOut = 0;
  public urlParams: any = [];
  public emailInvalidMsg: string;

  public vm = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      company_id: null,
      currency_id: null,
      includeUnposted: null,
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    currency_id: null,
    company_id: null,
    d: {
      date: '',
      name: '',
    },
    columns: false,
    company: [],
    locationGroup: [],
    location: [],
    currency: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    monthCount: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    balanceOwing: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    Preferences: null,
    permissions: null,
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    companyId: null,
    currencyId: null,
    locationId: null,
    companyName: null,
    locationName: null,
    inventoryItemFilter: { search_string: null },
  };

  public submitted = false;

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = 'Payable Report';
  }

  ngAfterViewChecked() {
    // alert('viewChecked fired');
    // sets data for email of report
    const div = document.getElementById('excel-export');

    // console.log(typeof div);
    // console.log(div.toString());
    // console.log(typeof div.outerHTML);
    // console.log('viewchk: ' + div.outerHTML);
    // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Payables Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  onSubmit() {
    this.emailForm.reset();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
    // console.log('Time Out --> ' + this.timeOut);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService.getTableData('company?state=active').subscribe((result: any[]) => {
      this.vm.company = result;
    });
    this.tableDataService.getTableData('currency?state=active').subscribe((result: any[]) => {
      this.vm.currency = result;
    });

    // if (this.localStorage.StorageGet('company', '')) {
    //   this.vm.company = this.localStorage.StorageGet('company', '');
    // } else {
    //   this.localStorage.StorageSet('company', this.vm.company);
    // }
    // if (this.localStorage.StorageGet('currency', '')) {
    //   this.vm.currency = this.localStorage.StorageGet('currency', '');
    // } else {
    //   this.localStorage.StorageSet('currency', this.vm.currency);
    // }

    if (this.urlParams.currency_id) {
      this.vm.request.currency_id = this.urlParams.currency_id;
    }

    // if (this.urlParams.toDate) {
    //   this.vm.request.toDate = this.urlParams.toDate;
    // } else if (this.localStorage.StorageGet('toDate', '')) {
    //   this.vm.request.toDate = this.localStorage.StorageGet('toDate', '');
    // } else {
    //   let d = new Date();
    //   d = new Date(d.getFullYear(), d.getMonth(), 1);
    //   d.setDate(d.getDate() - 1);
    //   this.localStorage.StorageSet('toDate', this.vm.request.toDate);
    // }

    if (this.vm.request.toDate && (this.urlParams.companyId || this.urlParams.currencyId)) {
      this.update();
    }
  }

  Print() {
    return window.print();
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  calcTotals() {
    for (const [x, value] of Object.entries(this.vm.report)) {
      this.vm.balanceOwing += Number(0 - this.vm.report[x].debit_credit);
    }
  }

  update() {
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;

    if (!this.vm.request.toDate) {
      this.vm.dateError = true;
      errorDescription += $localize`:'Date is required. @@dateisrequired:'Date is required.` + ' ';
    }
    if (errorDescription) {
      this.notification.showWarningToastStayOnScreen(errorDescription);
    } else {
      this.vm.dateError = false;
      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      const urlParamsJson = {
        toDate: this.vm.request.toDate,
        company_id: this.vm.request.company_id,
      };

      const PayableRerpotParam: string = this.encodeQueryData(urlParamsJson);

      this.tableDataService.getTableData('PayrollPayable', '?' + PayableRerpotParam).subscribe((result) => {
        this.vm.report = result;
        this.vm.showFilters = this.vm.buttonsDisabled = false;
        this.vm.emailed = true;
        this.calcTotals();
        this.localStorage.StorageSet('toDate', this.vm.request.toDate);
        if (this.vm.request.company_id && this.vm.company.length > 1) {
          this.vm.filtered = true;
        }
      });
    }
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' ' + $localize`:Date @@date:Date` + ' ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.localStorage.StorageSet('defaultAccountId', '');
    this.vm.report = [];
    this.vm.request = {
      fromDate: null,
      toDate: null,
      company_id: null,
      currency_id: null,
      includeUnposted: null,
    };
    this.vm.filtered = false;
  }

  FilterObject(value, filterObj, test = false) {
    return new FilterPipe().transform(value, filterObj);
  }
}
