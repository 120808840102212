export class AccountSubtotalModel {
  id: string;
  name: string;
  type_id: number;
  parent_id: string;
  sort_order: number;
  audit_sequence: number;
  active: boolean;
  // accounts: any[];
}
