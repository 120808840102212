import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../../Services/tableData.service';
import { AppConfigService } from '../../../../../Services/app-config.service';
import { StorageService } from '../../../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../../../../Services/email.service';
import { DateFilterService } from '../../../../../Services/dateFilter.service';
import { Router } from '@angular/router';
import { DataExportService } from '../../../../../Services/dataExport.service';
import { ResponseModel } from '../../../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../../../Services/email-pass-data-service.service';
import { BsToastService } from '../../../../../Services/bs-toast-service';

@Component({
  selector: 'app-employee-report',
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.css'],
})
export class EmployeeReportComponent implements OnInit, AfterViewInit, AfterViewChecked {
  constructor(
    public localStorage: StorageService,
    public tableDataService: TableDataService,
    private titleService: Title,
    private router: Router,
    public notification: BsToastService,
    public appConfigService: AppConfigService,
    public route: ActivatedRoute,
    public aEmail: EmailService,
    private fb: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    public excelExporter: DataExportService,
    private emailData: EmailPassDataServiceService
  ) {
    this.timeOut = this.appConfigService.toastrWarnTimeOut;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    this.vm.email.toemail = this.localStorage.StorageGet('ToEmail', '');
  }

  @ViewChild('EmailContent', { static: false }) EmailContent: ElementRef;
  @ViewChild('ExcelExport', { static: false }) ExcelExport: ElementRef;
  @ViewChild('EmployeemodalClose', { static: false }) EmployeemodalClose: ElementRef;

  emailForm: UntypedFormGroup;
  private rptName: string;
  timeOut = 0;
  public urlParams: any = [];
  public emailInvalidMsg: string;

  public vm: any = {
    toDateSelect: [],
    fromDateSelect: [],
    DataFormat: 'MM/DD/YYYY',
    dateError: false,
    report: [],
    request: {
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      employee_id: null,
      employeeInput: null,
    },
    showSpecificDates: false,
    buttonsDisabled: false,
    d: {
      date: '',
      name: '',
    },
    columns: false,
    company: [],
    location: [],
    currency: [],
    filtered: false,
    showFilters: false,
    toDateError: false,
    totalDebits: 0,
    totalCredits: 0,
    monthCount: 0,
    liabEquityCurrent: 0,
    liabEquityPrior: 0,
    totalCurrentAssets: 0,
    totalPriorAssets: 0,
    companyData: null,
    locationData: null,
    calculatedTime: new Date(),
    priorToDate: 0,
    ToEmailError: null,
    showEmail: true,
    ToEmail: null,
    emailed: null,
    email: {
      toemail: null,
      fromemail: this.appConfigService.SysEmailAddress,
      PdfHtml: null,
      Subject: null,
    },
    startInventoryItem: 0,
    Preferences: null,
    permissions: null,
    currentMonthMargin: 0,
    priorMonthMargin: 0,
    currentPeriodMargin: 0,
    priorPeriodMargin: 0,
    account: [],
    fromDateError: null,
    netIncomeCurrent: null,
    netIncomePrior: null,
    caclutedDate: null,
    toDateTitle: null,
    fromDateTitle: null,
    priorToDateTitle: null,
    priorToDateLink: null,
    priorMonthFromDateLink: null,
    currentMonthFromDateLink: null,
    priorFromDateLink: null,
    priorFromDateTitle: null,
    accountParents: [],
    accountSubtotal: [],
    bank: [],
    entity: [],
    entityAddress: [],
    entityType: [],
    paymentType: [],
    inventoryItem: [],
    accountType: [],
    inventoryItemName: [],
    companyId: null,
    currencyId: null,
    locationId: null,
    companyName: null,
    locationName: null,
    employee: [],
    inventoryItemInput: '',
    employeeName: null,
    startEmployeeName: 0,
    startEmployee: 0,
    employeeFilter: { name: '' },
    employee_email: [],
    employeeInput: '',
    exportToExcel: true,
  };
  public submitted = false;

  ngOnInit() {
    this.newDateFilter(this.vm.request.toDate, this.vm.request.fromDate);
    this.FilterOptionsInit();
    this.rptName = $localize`:Employee Report @@employeeReport:Employee Report`;
  }

  ngAfterViewChecked() {
    const div = document.getElementById('excel-export');
    // this.localStorage.StorageSet('reportData', div.outerHTML);

    setTimeout(() => {
      this.emailData.clearAllEmailPassData();
      this.emailData.emailInfo.aRecordId = '';
      this.emailData.emailInfo.aMessage = '';
      this.emailData.emailInfo.aEntity = '';
      this.emailData.emailInfo.aBatchTypeId = '99';
      this.emailData.emailInfo.aReportString = div.outerHTML;
      this.emailData.emailInfo.aReportOrDocName = 'Employee Report';
      this.emailData.loadEmailDataToPassMailer();
    }, 3000);
  }

  exportToExcel() {
    this.excelExporter.exportToExcel(this.ExcelExport, 'Payroll-Employee-Report.xlsx');
  }

  onSubmit() {
    this.emailForm.reset();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.rptName);
  }

  newDateFilter(toDate, fromDate) {
    const ret = this.dateFilter.DateFilterInit(toDate, fromDate);
    this.vm.fromDateSelect = ret.fromDateSelect;
    this.vm.toDateSelect = ret.toDateSelect;
    this.vm.monthCount = ret.monthCount;
  }

  FilterOptionsInit() {
    this.tableDataService
      .getTableData('company?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.company = result.Data;
      });
    this.tableDataService
      .getTableData('currency?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.currency = result.Data;
      });
    this.vm.employeeName = [];
    this.tableDataService
      .getTableData('employee?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        const data: any = result.Data;
        this.vm.employee = data;
        for (const [x, value] of Object.entries(this.vm.employee)) {
          this.vm.employeeName.push({ name: this.vm.employee[x].name });
        }
      });

    // if (this.localStorage.StorageGet('company', '')) {
    //   this.vm.company = this.localStorage.StorageGet('company', '');
    // } else {
    //   this.localStorage.StorageSet('company', this.vm.company);
    // }
    // if (this.localStorage.StorageGet('employee', '')) {
    //   this.vm.employee = this.localStorage.StorageGet('employee', '');
    // } else {
    //   this.localStorage.StorageSet('location', this.vm.location);
    // }
    // if (this.localStorage.StorageGet('currency', '')) {
    //   this.vm.currency = this.localStorage.StorageGet('currency', '');
    // } else {
    //   this.localStorage.StorageSet('currency', this.vm.currency);
    // }

    if (this.urlParams.employeeId) {
      this.vm.request.employee_id = this.urlParams.employeeId;
    }

    if (this.urlParams.currency_id) {
      this.vm.request.currency_id = this.urlParams.currency_id;
    }

    if (
      this.vm.request.toDate &&
      this.vm.request.fromDate &&
      (this.urlParams.companyId || this.urlParams.currencyId || this.urlParams.employeeID || this.urlParams.itemId)
    ) {
      this.update();
    }
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null && data[d] != '') {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }

  update() {
    let errorDescription = '';
    this.vm.toDateError = this.vm.fromDateError = false;
    if (!this.vm.request.fromDate) {
      this.vm.dateError = true;
      errorDescription += $localize`:A From date is required. @@aFromdateisrequired:A From date is required.` + ' ';
    }
    if (!this.vm.request.toDate) {
      this.vm.dateError = true;
      errorDescription += $localize`:A To date is required. @@aTodateisrequired:A To date is required.` + ' ';
    }

    const tempToDate: any = new Date(this.vm.request.toDate);
    const tempFromDate: any = new Date(this.vm.request.fromDate);

    if (tempToDate < tempFromDate) {
      errorDescription += $localize`:From date must be less than the To date @@fromdatemustbelessthantheTodate:From date must be less than the To date`;
      this.vm.toDateError = true;
      this.vm.fromDateError = true;
    }
    if (errorDescription) {
      this.notification.showWarningToast(errorDescription);
    } else {
      this.vm.dateError = this.vm.emailed = false;
      this.vm.monthCount = (tempToDate - tempFromDate) / 86400 / 30 / 1000;
      this.vm.request.toDate = this.dateFilter.GetDateFormat(this.vm.request.toDate);
      this.vm.request.fromDate = this.dateFilter.GetDateFormat(this.vm.request.fromDate);
      this.vm.buttonsDisabled = true;
      this.vm.netIncomeCurrent = this.vm.netIncomePrior = 0;
      const urlParamsJson = {
        fromDate: this.vm.request.fromDate,
        toDate: this.vm.request.toDate,
        company_id: this.vm.request.company_id,
        employee_id: this.vm.request.employee_id,
        item_id: this.vm.request.item_id,
        currency_id: this.vm.request.currency_id,
        pageNumber: 0,
        pageSize: 999999,
      };

      const EmployeeReportParam: string = this.encodeQueryData(urlParamsJson);
      this.tableDataService
        .getTableData('EmployeeSummary', '?' + EmployeeReportParam)
        .subscribe((result: ResponseModel) => {
          const data: any = result.Data;
          this.vm.report = data;
          this.calcTotals();
          this.vm.showFilters = this.vm.buttonsDisabled = false;
          if (
            (this.vm.request.company_id && this.vm.company.length > 1) ||
            this.vm.request.employee_id ||
            (this.vm.request.currency_id && this.vm.currency.length > 1) ||
            this.vm.request.item_id
          ) {
            this.vm.filtered = true;
          }
          if (this.vm.request.company_id) {
            this.vm.companyName = this.FilterObject(this.vm.company, { id: this.vm.request.company_id })[0].name;
          } else {
            this.vm.companyName = [];
          }

          if (this.vm.request.location_id) {
            this.vm.employeeName = this.FilterObject(this.vm.employee, { id: this.vm.request.employee_id })[0].name;
          } else {
            this.vm.employeeName = [];
          }

          if (this.vm.request.item_id) {
            this.vm.inventoryItemName = this.FilterObject(this.vm.inventoryItem, {
              id: this.vm.request.item_id,
            })[0].name;
          } else {
            this.vm.inventoryItemName = [];
          }
        });
    }
  }

  calcTotals() {
    for (const [x, value] of Object.entries(this.vm.report)) {
      this.vm.report[x].grossPayYTD = 0;
      this.vm.report[x].totalDeductionsYTD = 0;
      this.vm.report[x].netPayYTD = 0;
      for (const [y, val] of Object.entries(this.vm.report[x].detail)) {
        if (this.vm.report[x].detail[y].deduction == '1') {
          if (this.vm.report[x].detail[y].amount) {
            if (this.vm.report[x].detail[y].earning_id === 'ei' || this.vm.report[x].detail[y].earning_id === 'cpp') {
              this.vm.report[x].totalDeductionsYTD += Number(this.vm.report[x].detail[y].amount);
              this.vm.report[x].netPayYTD -= Number(this.vm.report[x].detail[y].amount);
            } else {
              this.vm.report[x].totalDeductionsYTD += Number(this.vm.report[x].detail[y].amount);
              this.vm.report[x].netPayYTD -= Number(this.vm.report[x].detail[y].amount);
            }
          }
        } else {
          if (this.vm.report[x].detail[y].amount) {
            this.vm.report[x].grossPayYTD += Number(this.vm.report[x].detail[y].amount);
            this.vm.report[x].netPayYTD += Number(this.vm.report[x].detail[y].amount);
          }
        }
      }
    }
  }

  updateEmployee() {
    this.vm.employeeName = [];
    this.localStorage.StorageSet('employee', '');

    this.tableDataService.getTableData('employee', '?state=active').subscribe((result) => {
      this.vm.employee = result;
      this.vm.employeeName = [];
      for (const [x, value] of Object.entries(this.vm.employee)) {
        this.vm.employeeName.push({ name: this.vm.employee[x].name });
      }
    });
  }

  selectEmployeeModal(employeeName) {
    this.EmployeemodalClose.nativeElement.click();
    if (this.vm.request.employee_id == '' || 1 === 1) {
      for (const [y, value] of Object.entries(this.vm.employee)) {
        if (this.vm.employee[y].name == employeeName) {
          this.vm.request.employeeInput = this.vm.employeeInput = employeeName;
          this.vm.request.employee_id = this.vm.employee[y].id;
          this.vm.employee_email = this.vm.employee[y].email;
          this.vm.report = [];
          break;
        }
      }
    }
  }

  testEmployee() {
    const employeeIdStart = this.vm.request.employee_id;
    if (!this.vm.employeeInput || this.vm.employeeInput == '') {
      this.vm.request.employee_id = '';
    } else {
      if (this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }).length > 0) {
        const employeeId = this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }, true)[0].id;
        const employeeName = this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }, true)[0].name;
        const employeeEmail = this.FilterObject(this.vm.employee, { name: this.vm.employeeInput }, true)[0].email;
        if (employeeId != '') {
          this.vm.request.employee_id = employeeId;
          this.vm.employeeInput = employeeName;
          this.vm.employee_email = employeeEmail;
        } else {
          this.vm.request.employee_id = '';
          this.vm.employee_email = [];
        }
      } else {
        this.vm.request.employee_id = '';
        this.vm.employee_email = [];
      }
    }
    if (employeeIdStart != this.vm.request.employee_id) {
      this.vm.report = [];
    }
  }

  close() {
    this.router.navigateByUrl('/home').then();
  }

  clear() {
    this.vm.employeeInput = '';
    this.vm.report = [];
    this.vm.request = {
      employee_id: null,
      toDate: null,
      fromDate: null,
      company_id: null,
      location_id: null,
      item_id: null,
      currency_id: null,
      includeUnposted: null,
      employeeInput: null,
    };
    this.vm.filtered = false;
  }

  emailReport(toEmail) {
    const tempPdfHtml = this.EmailContent.nativeElement.innerHTML;
    this.vm.email.toemail = toEmail;
    this.vm.email.PdfHtml = tempPdfHtml;
    this.vm.email.Subject = this.rptName + ' - ' + this.vm.request.fromDate + ' to ' + this.vm.request.toDate;
    this.aEmail.sendEmail(this.vm.email);
  }

  FilterObject(value, filterObj, test = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  MathRound(int) {
    Math.round(int);
  }

  parseInt(val) {
    return parseInt(val, 0);
  }

  Print() {
    return window.print();
  }

  setCompanyId(event) {
    this.vm.report = [];
    this.vm.request.company_id = event;
  }

  filterReport() {
    this.vm.report = [];
  }
}
