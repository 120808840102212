export class EmailModel {
    toEmail: string;
    fromEmail: string;
    fromName?: string;
    pdfHtml: string;
    Subject: string;
    header_id: string;
    entity_id: string;
    body: string;
    activity_message: string;
    FileName: string;
    includeAttachment: boolean;
}
