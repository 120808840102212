import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EmailModel} from '../../../../DataModels/email-model';

@Component({
  selector: 'app-report-buttons',
  templateUrl: './report-buttons.component.html',
  styleUrls: ['./report-buttons.component.css']
})
export class ReportButtonsComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder) {
  }

  get fields() {
    return this.emailForm.controls;
  }
  @Input() vm: any;
  @Input() emailObj: EmailModel = new EmailModel();
  @Input() buttonConfig: any;

  @Output() updateEmit = new EventEmitter<string>();
  // @Output() setReportDataEmit = new EventEmitter<string>();
  @Output() clearEmit = new EventEmitter<string>();
  @Output() closeEmit = new EventEmitter<string>();
  @Output() exportToExcelEmit = new EventEmitter<string>();
  @Output() reportFormatEmit = new EventEmitter<string>();
  @Output() emailEmit = new EventEmitter<string>();

  public emailForm: UntypedFormGroup;
  public emailInvalidMsg: string;
  public submitted = false;

  buttons: any = {
    reportFormat: false,
    print: true,
    email: true,
    update: true,
    exportExcel: true,
  };

  protected readonly window = window;

  ngOnInit() {
    if (this.buttonConfig) {
      this.buttons = this.buttonConfig;
    }
  }

  onSubmit() {
    this.emailForm.reset();
  }

  update() {
    this.updateEmit.emit();
  }

  exportToExcel() {
    this.exportToExcelEmit.emit();
  }

  clear() {
    this.vm.monthCount = 0;
    this.vm.filtered = false;
    this.clearEmit.emit();
  }

  close() {
    this.vm.monthCount = 0;
    this.closeEmit.emit();
  }

  email() {
    this.emailEmit.emit();
  }

  // note - really no longer a use for this in new version but will leave for now.
  // data is now loaded via inputs and no reason to trigger a write of stuff to ls is why
  // setReportData() {
  //   this.setReportDataEmit.emit();
  // }

  reportFormat(event) {
    this.vm.report_format = event;
    this.reportFormatEmit.emit(event);

  }
}
