import {Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../Services/storage.service';
import {TableDataService} from '../../../Services/tableData.service';
import {DateFilterService} from '../../../Services/dateFilter.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {DatasetsEntity, PrimeChartDataModel} from '../../../DataModels/prime-chart-data-model';
import {StatisticsItem} from '../../common/widget/statistics/statistics.model';
import {AppConfigService} from '../../../Services/app-config.service';
import {CustomArrayFilterPipe} from '../../common/custom-array-filter.pipe';
import {Title} from '@angular/platform-browser';
import {BsToastService} from '../../../Services/bs-toast-service';


@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.css']
})
export class HomeDashboardComponent implements OnInit {

  vm: any = {};

  // nested data for prime chart control
  primeChartData: PrimeChartDataModel = new PrimeChartDataModel();
  primeChartDatasets: DatasetsEntity = new DatasetsEntity();

  primeChartLabelList = [];
  primeChartDataList: number[] = [];

  primeBarChartDataForChildComponent: any;

  private primeResultData: any;

  editTrending: boolean;
  showTrendingHelp: any;

  queryData: any = [];
  isLoaded = false;

  darkMode = false;

  public statisticsData: StatisticsItem[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private localStorage: StorageService,
              private tableDataService: TableDataService,
              private notification: BsToastService,
              private dateFilter: DateFilterService,
              private datePipe: DatePipe,
              private decimal: DecimalPipe,
              private changeDetectorRef: ChangeDetectorRef,
              private appConfig: AppConfigService,
              private titleService: Title) {

    this.vm.request = {};
    this.vm.request.labels = [];
    this.vm.balanceSheet = [];
    this.vm.reminderNew = {};
    this.vm.trending = {};
    this.vm.currentAssets = this.vm.totalRevenue = 0;
    this.vm.cutOffAlert = true;
    this.vm.addReminder = false;
    this.vm.request.includeForecast = false;
    this.vm.incomeStatementForWidgets = [];
    this.vm.balanceSheetForWidgets = [];

  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Dashboard@@Dashboard:Dashboard`);
    if (this.appConfig.bodyThemeColor === 'dark') {
      this.darkMode = true;
    }

    // open the Trends display header area
    this.editTrending = true;

    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    if (this.vm.Preferences.default_company_id) {
      this.vm.request.company_id = this.vm.Preferences.default_company_id;
    }

    this.getTableData('company', '?state=active&pageNumber=0&pageSize=9999999', 'company', (value) => {
      this.vm.company = value.Data;
      // console.log('value: ' + JSON.stringify(value));
      let chosenFound = false;
      value.Data.forEach(comp => {
        if (comp.id == this.vm.request.company_id) {
          chosenFound = true;
      }});
      // for (const comp of value.data) {
      //   if (comp.id == this.vm.request.company_id) {
      //     chosenFound = true;
      //   }
      // }
      if (!chosenFound) {
        this.vm.request.company_id = '';
      }
      this.init();
      // this.widgetLoader();
      this.isLoaded = true;
    });

  }

  widgetLoaderIncomeStatement() {
    this.statisticsData = [];

    for (const obj of this.vm.incomeStatementForWidgets) {
      if (!obj) {
        continue;
      }
      if (obj.id == 'revenue') {
        this.createIncomeStatementWidgetData('revenue', 'Revenue', 'Net Income');
      }
      if (obj.id == 'costs-of-sales') {
        this.createIncomeStatementWidgetData('costs-of-sales', 'Cost of Sales', 'COGS');
      }
      if (obj.id == 'margin') {
        this.createIncomeStatementWidgetData('margin', 'Margin', 'Margin');
      }
      if (obj.id == 'operating-expenses') {
        this.createIncomeStatementWidgetData('operating-expenses', 'Operating Expenses', 'Operating Expenses');
      }
      if (obj.id == 'net-income') {
        this.createIncomeStatementWidgetData('net-income', 'Net Income', 'Net Income');
      }
    }
  }
  widgetLoaderBalanceSheet() {
    // balance sheet items
    for (const obj of this.vm.balanceSheetForWidgets) {
      if (!obj) {
        continue;
      }
      if (obj.id == 'cash-bank') {
        this.createBalanceSheetWidgetData('cash-bank', 'Cash', 'Cash');
      }
      if (obj.id == 'inventory') {
        this.createBalanceSheetWidgetData('inventory', 'Inventory', 'Inventory');
      }
      if (obj.id == 'accounts-receivable') {
        this.createBalanceSheetWidgetData('accounts-receivable', 'Accounts Receivable', 'Accounts Receivable');
      }
      if (obj.id == 'accounts-payable') {
        this.createBalanceSheetWidgetData('accounts-payable', 'Accounts Payable', 'Accounts Payable');
      }
      if (obj.id == 'current-assets') {
        this.createBalanceSheetWidgetData('current-assets', 'Current Assets', 'Current Assets');
      }
      if (obj.id == 'current-liabilities') {
        this.createBalanceSheetWidgetData('current-liabilities', 'Current Liabilities', 'Current Liabilities');
      }
      // if (obj.id == 'working-capital') {
      //   this.createBalanceSheetWidgetData('working-capital', 'Working Capitol', 'Working Capitol');
      // }
    }
  }

  createIncomeStatementWidgetData(dataKey, widgetName, widgetDescription) {
    let dataValue: any = 0.00;
    let revenue: any = 0.00;
    let percent: any = 0.00;
    let reportLink = '';

    if(dataKey === 'revenue' || 'operating-expenses' || 'costs-of-sales' || 'margin' || 'net-income') {
      if (this.FilterObject(this.vm.incomeStatementForWidgets, {id: dataKey}, true)[0]) {
        dataValue = this.FilterObject(this.vm.incomeStatementForWidgets, {id: dataKey}, true)[0].amount;
      }
      // get net income to calc percent
      if (this.FilterObject(this.vm.incomeStatementForWidgets, {account: 'revenue'}, true)[0]) {
        revenue = this.FilterObject(this.vm.incomeStatementForWidgets, {account: 'revenue'}, true)[0].amount;
      }
      reportLink = '/transaction-reports/income-statement';
    }

    if (dataKey === 'revenue'){
        percent = 100;
      } else {
        percent = (dataValue / revenue) * 100;
      }

    percent = percent.toFixed(2);
    dataValue = parseFloat(dataValue).toFixed(2);

    const widgetRec: StatisticsItem = {
      id: 2,
      title: widgetName,
      description: widgetDescription,
      stats: dataValue,
      trendNumber:  percent + '%',
      trendTime: '% of Revenue',
      icon: 'mdi mdi-book-open-outline',
      trendIcon: 'mdi mdi-arrow-up-bold',
      trendTextClass: 'text-white',
      badgeVariant: 'text-success',
      report: reportLink,
      reportFromDate: this.vm.request.fromDate,
      reportToDate: this.vm.request.toDate
    };
    this.statisticsData.push(widgetRec);
  }

  createBalanceSheetWidgetData(dataKey, widgetName, widgetDescription) {
    let dataValue: any = 0.00;
    let currentAssets: any = 0.00;
    let percent: any = 0.00;
    let reportLink = '';

    if(dataKey === 'cash' || 'inventory' || 'current-assets' || 'current-liabilities' || 'accounts-recievable' || 'accounts=payable' || 'working-capital') {
      if (this.FilterObject(this.vm.balanceSheetForWidgets, {id: dataKey}, true)[0]) {
        dataValue = this.FilterObject(this.vm.balanceSheetForWidgets, {id: dataKey}, true)[0].amount;
      }
      if (this.vm.balanceSheetForWidgets && this.vm.balanceSheetForWidgets.length > 0
        && this.FilterObject(this.vm.balanceSheetForWidgets, {id: 'current-assets'}, true)[0]) {
        currentAssets = this.FilterObject(this.vm.balanceSheetForWidgets, {id: 'current-assets'}, true)[0].amount;
      }
      reportLink = '/transaction-reports/balance-sheet';
    }

    if (dataKey === 'current-assets'){
      percent = 100;
    } else {
      percent = (dataValue / currentAssets) * 100 ;
    }

    percent = percent.toFixed(2);
    dataValue = parseFloat(dataValue).toFixed(2);

    const widgetRec: StatisticsItem = {
      id: 2,
      title: widgetName,
      description: widgetDescription,
      stats: dataValue,
      trendNumber:  percent + '%',
      trendTime: '% of Current Assets',
      icon: 'mdi mdi-book-account-outline',
      trendIcon: 'mdi mdi-arrow-up-bold',
      trendTextClass: 'text-white',
      badgeVariant: 'text-success',
      report: reportLink,
      reportFromDate: this.vm.request.fromDate,
      reportToDate: this.vm.request.toDate
    };
    this.statisticsData.push(widgetRec);
  }

  private getTableData(apiEndPoint, params, cacheName, fun) {
    // const cache = this.localStorage.StorageGet(cacheName);
    // if (cache) {
    //   fun(JSON.parse(cache));
    // } else {
      this.tableDataService.getApiListData(apiEndPoint, params).subscribe((result: any) => {
        fun(result);
        if (cacheName !== 'company') {
          this.localStorage.StorageSet(cacheName, JSON.stringify(result));
          // console.log('cachename wrtitten: ' + cacheName);
        }
      });
    // }
  }

  init() {
    this.vm.request.type = 'line';

    const months = [$localize`:Jan @@jan:Jan`, $localize`:Feb @@feb:Feb`, $localize`:Mar @@mar:Mar`, $localize`:Apr @@apr:Apr`, $localize`:May @@may:May`, $localize`:Jun @@jun:Jun`, $localize`:Jul @@jul:Jul`, $localize`:Aug @@aug:Aug`, $localize`:Sep @@sep:Sep`, $localize`:Oct @@oct:Oct`, $localize`:Nov @@nov:Nov`, $localize`:Dec @@dec:Dec`];

    if (this.vm.request.toDate) {

      const d = new Date(this.vm.request.toDate);
      let tempMonth = d.getMonth();
      let columns;
      if (this.vm.request.includeForecast) {
        columns = 7;
        if (tempMonth === 11) {
          tempMonth = 1;
        } else if (tempMonth === 10) {
          tempMonth = 0;
        } else {
          tempMonth += 2;
        }
      } else {
        columns = 5;
      }
      for (let x = columns; x > -1; x--) {
        let fc = '';
        if (x > 5) {
          fc = ' *FC';
        }
        this.vm.request.labels[x] = months[tempMonth] + fc;
        tempMonth -= 1;
        if (tempMonth < 0) {
          tempMonth = 11;
        }
      }
    } else {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      let tempMonthStr;

      if ((d.getMonth() + 1) < 10) {
        tempMonthStr = '0' + (d.getMonth() + 1);
      } else {
        tempMonthStr = '' + (d.getMonth() + 1);
      }

      // if (!this.vm.request.toDate) {
      this.vm.request.toDate = tempMonthStr + '/' + d.getDate() + '/' + d.getFullYear();
      // }

      let tempMonth = d.getMonth();
      let columns;
      if (this.vm.request.includeForecast) {
        columns = 7;
        if (tempMonth === 11) {
          tempMonth = 1;
        } else if (tempMonth === 10) {
          tempMonth = 0;
        } else {
          tempMonth += 2;
        }
      } else {
        columns = 5;
      }
      for (let x = columns; x > -1; x--) {
        let fc = '';
        if (x > 5) {
          fc = ' *FC';
        }
        this.vm.request.labels[x] = months[tempMonth] + fc;
        tempMonth -= 1;
        if (tempMonth < 0) {
          tempMonth = 11;
        }
      }
    }

    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);

    if (!this.vm.request.fromDate) {
      this.vm.toDateSelect = [];
      this.vm.fromDateSelect = [];
      for (let x = 0; x < 38; x++) {
        const tempName = date.getFullYear() + ' ' + months[date.getMonth()];
        let tempMonthStr;
        if ((date.getMonth() + 1) < 10) {
          tempMonthStr = '0' + (date.getMonth() + 1);
        } else {
          tempMonthStr = '' + (date.getMonth() + 1);
        }
        const tempFromDate = tempMonthStr + '/' + '01/' + date.getFullYear();
        this.vm.fromDateSelect.push({date: tempFromDate, name: tempName});
        if (x < 26) {
          const tempToDate = tempMonthStr + '/' + date.getDate() + '/' + date.getFullYear();
          this.vm.toDateSelect.push({date: tempToDate, name: tempName});
          // console.log('fromDate:' + this.vm.fromDateSelect);
          // console.log('toDate:' + this.vm.toDateSelect);
        }
        date = new Date(date.getFullYear(), date.getMonth(), 1);
        date.setDate(date.getDate() - 1);
      }
    }
    this.queryIncomeStatement();
    this.queryBalanceSheet();
    this.buildChart();
  }

  queryIncomeStatement() {

    if (this.vm.request.toDate && !this.vm.request.fromDate) {
      const d = new Date(this.vm.request.toDate);
      d.setMonth(d.getMonth() - 3);
      d.setDate(1);
      this.vm.request.fromDate = this.datePipe.transform(d, 'MM/dd/yyyy');
    }

    let paramString = '';
    paramString += 'toDate=' + ((this.vm.request.toDate) ? this.dateFilter.GetDateFormat(this.vm.request.toDate) : '');
    paramString += '&fromDate=' + ((this.vm.request.fromDate) ? this.dateFilter.GetDateFormat(this.vm.request.fromDate) : '');
    paramString += '&company_id=' + ((this.vm.request.company_id) ? this.vm.request.company_id : '');

    this.tableDataService.getTableData('DashboardIncomeStatement', '?' + paramString).subscribe((result: any[]) => {
      this.vm.incomeStatement = [];
      this.vm.incomeStatement = result;

      this.vm.incomeStatementForWidgets = result;

      this.calcTotalRevenue();
      this.widgetLoaderIncomeStatement();

    });

  }

  queryBalanceSheet() {
    let paramString = '';
    paramString += 'toDate=' + ((this.vm.request.toDate) ? this.dateFilter.GetDateFormat(this.vm.request.toDate) : '');
    paramString += '&company_id=' + ((this.vm.request.company_id) ? this.vm.request.company_id : '');

    this.tableDataService.getTableData('DashboardBalanceSheet', '?' + paramString).subscribe((result: any[]) => {
      this.vm.balanceSheet = result;

      this.vm.balanceSheetForWidgets = result;

      this.calcCurrentAssets();
      this.widgetLoaderBalanceSheet();
    });
  }

  calcCurrentAssets() {
    this.vm.currentAssets = 0;
    if (this.vm.balanceSheet && this.vm.balanceSheet.length > 0
      && this.FilterObject(this.vm.balanceSheet, {id: 'current-assets'}, true)[0]) {
      this.vm.currentAssets = this.FilterObject(this.vm.balanceSheet, {id: 'current-assets'}, true)[0].amount;
    }
    for (const obj of this.vm.balanceSheet) {
      if (!obj) {
        continue;
      }
      if (obj.id == 'cash-bank' && this.vm.trendCash) {
        obj.onChart = true;
      }
      if (obj.id == 'inventory' && this.vm.trendInventory) {
        obj.onChart = true;
      }
      if (obj.id == 'accounts-receivable' && this.vm.trendReceivables) {
        obj.onChart = true;
      }
      if (obj.id == 'accounts-payable' && this.vm.trendPayables) {
        obj.onChart = true;
      }
      if (obj.id == 'current-assets' && this.vm.trendCurrentAssets) {
        obj.onChart = true;
      }
      if (obj.id == 'current-liabilities' && this.vm.trendCurrentLiabilities) {
        obj.onChart = true;
      }
      if (obj.id == 'working-capital' && this.vm.trendWorkingCapital) {
        obj.onChart = true;
      }
    }
  }

  calcTotalRevenue() {
    if (this.FilterObject(this.vm.incomeStatement, {id: 'revenue'}, true)[0]) {
      this.vm.totalRevenue = this.FilterObject(this.vm.incomeStatement, {id: 'revenue'}, true)[0].amount;
    }
    for (const obj of this.vm.incomeStatement) {
      if (obj.id == 'revenue' && this.vm.trendRevenue) {
        obj.onChart = true;
      }
      if (obj.id == 'costs-of-sales' && this.vm.trendCosts) {
        obj.onChart = true;
      }
      if (obj.id == 'margin' && this.vm.trendMargin) {
        obj.onChart = true;
      }
      if (obj.id == 'operating-expenses' && this.vm.trendExpenses) {
        obj.onChart = true;
      }
      if (obj.id == 'net-income' && this.vm.trendNetIncome) {
        obj.onChart = true;
      }
    }
  }

  refreshData() {
    this.notification.showSuccessToast('Updated');
    this.updateDashboard();
  }

  updateDashboard() {
    this.clearTrending();
    this.init();
  }

  buildChart() {

    // NOTE - this works oddly, but is effective.
    // Items without if of storage value existence here will get reversed in the update() and thus appear on chart for startup

    // default selected tabs
    this.vm.trendRevenue = false;
    this.updateChart('revenue');

    this.vm.trendCosts = false;
    this.updateChart('costs-of-sales');

    this.vm.trendMargin = false;
    this.updateChart('margin');
    // end default selected

    if (this.localStorage.StorageGet('trendExpenses', '')) {
      this.vm.trendExpenses = false;
      this.updateChart('operating-expenses');
    }

    if (this.localStorage.StorageGet('trendNetIncome', '')) {
      this.vm.trendNetIncome = false;
      this.updateChart('net-income');
    }

    if (this.localStorage.StorageGet('trendCash', '')) {
      this.vm.trendCash = false;
      this.updateChart('cash-bank');
    }

    if (this.localStorage.StorageGet('trendInventory', '')) {
      this.vm.trendInventory = false;
      this.updateChart('inventory');
    }

    if (this.localStorage.StorageGet('trendReceivables', '')) {
      this.vm.trendReceivables = false;
      this.updateChart('accounts-receivable');
    }

    if (this.localStorage.StorageGet('trendPayables', '')) {
      this.vm.trendPayables = false;
      this.updateChart('accounts-payable');
    }

    if (this.localStorage.StorageGet('trendCurrentAssets', '')) {
      this.vm.trendCurrentAssets = false;
      this.updateChart('current-assets');
    }
    if (this.localStorage.StorageGet('trendCurrentLiabilities', '')) {
      this.vm.trendCurrentLiabilities = false;
      this.updateChart('current-liabilities');
    }
    if (this.localStorage.StorageGet('trendWorkingCapital', '')) {
      this.vm.trendWorkingCapital = false;
      this.updateChart('working-capital');
    }
  }

  updateChart(account) {
    if (account == 'revenue') {
      this.vm.trendRevenue = !this.vm.trendRevenue;
      this.localStorage.StorageSet('trendRevenue', this.vm.trendRevenue);
      this.onChartIcon($localize`:Revenue @@revenue:Revenue`, this.vm.trendRevenue);
      if (this.vm.trendRevenue) {
        this.updateData(account, '#727cf5', $localize`:Revenue @@revenue:Revenue`); // Gold
      } else {
        this.localStorage.StorageSet('trendRevenue', '');
        // delete (this.primeChartData.datasets[0]);
        this.removeData('Revenue');
      }
    } else if (account == 'costs-of-sales') {
      this.vm.trendCosts = !this.vm.trendCosts;
      this.localStorage.StorageSet('trendCosts', this.vm.trendCosts);
      this.onChartIcon($localize`:Costs @@costs:Costs`, this.vm.trendCosts);
      if (this.vm.trendCosts) {
        this.updateData(account, '#e773b5', $localize`:Costs @@costs:Costs`);
      } else {
        this.localStorage.StorageSet('trendCosts', '');
        this.removeData('Costs');
      }
    } else if (account == 'margin') {
      this.vm.trendMargin = !this.vm.trendMargin;
      this.localStorage.StorageSet('trendMargin', this.vm.trendMargin);
      this.onChartIcon($localize`:Margin @@margin:Margin`, this.vm.trendMargin);
      if (this.vm.trendMargin) {
        this.updateData(account, '#63ee75', $localize`:Margin @@margin:Margin`); // YellowGreen
      } else {
        this.localStorage.StorageSet('trendMargin', '');
        this.removeData('Margin');
      }
    } else if (account == 'operating-expenses') {
      this.vm.trendExpenses = !this.vm.trendExpenses;
      this.localStorage.StorageSet('trendExpenses', this.vm.trendExpenses);
      this.onChartIcon($localize`:Expenses @@expenses:Expenses`, this.vm.trendExpenses);
      if (this.vm.trendExpenses) {
        this.updateData(account, '#ffea00', $localize`:Expenses @@expenses:Expenses`);
      } else {
        this.localStorage.StorageSet('trendExpenses', '');
        this.removeData('Expenses');
      }
    } else if (account == 'net-income') {
      this.vm.trendNetIncome = !this.vm.trendNetIncome;
      this.localStorage.StorageSet('trendNetIncome', this.vm.trendNetIncome);
      this.onChartIcon($localize`:Net Income @@netIncome:Net Income`, this.vm.trendNetIncome);
      if (this.vm.trendNetIncome) {
        this.updateData(account, '#00E396', $localize`:Net Income @@netIncome:Net Income`); // DarkGreen
      } else {
        this.localStorage.StorageSet('trendNetIncome', '');
        this.removeData('Net Income');
      }
    } else if (account == 'cash-bank') {
      this.vm.trendCash = !this.vm.trendCash;
      this.localStorage.StorageSet('trendCash', this.vm.trendCash);
      this.onChartIcon($localize`:Cash & Bank @@cashBank:Cash & Bank`, this.vm.trendCash);
      if (this.vm.trendCash) {
        this.updateData(account, '#8fc7ee', $localize`:Cash & Bank @@cashBank:Cash & Bank`); // Chocolate
      } else {
        this.localStorage.StorageSet('trendCash', '');
        this.removeData('Cash & Bank');
      }
    } else if (account == 'inventory') {
      this.vm.trendInventory = !this.vm.trendInventory;
      this.localStorage.StorageSet('trendInventory', this.vm.trendInventory);
      this.onChartIcon($localize`:Inventory @@inventory:Inventory`, this.vm.trendInventory);
      if (this.vm.trendInventory) {
        this.updateData(account, '#2e2ee0', $localize`:Inventory @@inventory:Inventory`); // DarkBlue
      } else {
        this.localStorage.StorageSet('trendInventory', '');
        this.removeData('Inventory');
      }
    } else if (account == 'accounts-receivable') {
      this.vm.trendReceivables = !this.vm.trendReceivables;
      this.localStorage.StorageSet('trendReceivables', this.vm.trendReceivables);
      this.onChartIcon($localize`:Accounts Receivable @@accountsReceivable:Accounts Receivable`, this.vm.trendReceivables);
      if (this.vm.trendReceivables) {
        this.updateData(account, '#92d7a2', $localize`:Accounts Receivable @@accountsReceivable:Accounts Receivable`); // DarkCyan
      } else {
        this.localStorage.StorageSet('trendReceivables', '');
        this.removeData('Accounts Receivable');
      }
    } else if (account == 'accounts-payable') {
      this.vm.trendPayables = !this.vm.trendPayables;
      this.localStorage.StorageSet('trendPayables', this.vm.trendPayables);
      this.onChartIcon($localize`:Accounts Payable @@accountsPayable:Accounts Payable`, this.vm.trendPayables);
      if (this.vm.trendPayables) {
        this.updateData(account, '#ff264e', $localize`:Accounts Payable @@accountsPayable:Accounts Payable`); // DarkRed
      } else {
        this.localStorage.StorageSet('trendPayables', '');
        this.removeData('Accounts Payable');
      }
    } else if (account == 'current-assets') {
      this.vm.trendCurrentAssets = !this.vm.trendCurrentAssets;
      this.localStorage.StorageSet('trendCurrentAssets', this.vm.trendCurrentAssets);
      this.onChartIcon($localize`:Current Assets @@currentAssets:Current Assets`, this.vm.trendCurrentAssets);
      if (this.vm.trendCurrentAssets) {
        this.updateData(account, '#B3F7CA', $localize`:Current Assets @@currentAssets:Current Assets`); // Teal
      } else {
        this.localStorage.StorageSet('trendCurrentAssets', '');
        this.removeData('Current Assets');
      }
    } else if (account == 'current-liabilities') {
      this.vm.trendCurrentLiabilities = !this.vm.trendCurrentLiabilities;
      this.localStorage.StorageSet('trendCurrentLiabilities', this.vm.trendCurrentLiabilities);
      this.onChartIcon($localize`:Current Liabilities @@currentLiabilities:Current Liabilities`, this.vm.trendCurrentLiabilities);
      if (this.vm.trendCurrentLiabilities) {
        this.updateData(account, '#775DD0', $localize`:Current Liabilities @@currentLiabilities:Current Liabilities`); // Salmon
      } else {
        this.localStorage.StorageSet('trendCurrentLiabilities', '');
        this.removeData('Current Liabilities');
      }
    } else if (account == 'working-capital') {
      this.vm.trendWorkingCapital = !this.vm.trendWorkingCapital;
      this.localStorage.StorageSet('trendWorkingCapital', this.vm.trendWorkingCapital);
      this.onChartIcon($localize`:Working Capital @@workingCapital:Working Capital`, this.vm.trendWorkingCapital);
      if (this.vm.trendWorkingCapital) {
        this.updateData(account, '#33a8b7', $localize`:Working Capital @@workingCapital:Working Capital`); // Sienna
      } else {
        this.localStorage.StorageSet('trendWorkingCapital', '');
        this.removeData('Working Capital');
      }
    }
  }

  updateData(account, bgColor, accountName) {

    // console.log('query this acct: '+ account);
    let tempAccount = account.replace(/&/g, '');
    tempAccount = tempAccount.replace(/ /g, '');

    let paramString = '';
    paramString += 'account=' + ((tempAccount) ? tempAccount : '');
    paramString += '&toDate=' + ((this.vm.request.toDate) ? this.dateFilter.GetDateFormat(this.vm.request.toDate) : '');
    paramString += '&includeForecast=' + this.vm.request.includeForecast;
    paramString += '&company_id=' + this.vm.request.company_id;

    this.tableDataService.getTableData('Trending', '?' + paramString).subscribe((result: any[]) => {
      this.queryData = [];
      this.queryData = result;
      // console.log('raw chart query: ' + JSON.stringify(this.queryData));

      // clear dataset vars on new run because they are not top level labels which are same throughout
      this.primeChartDatasets = new DatasetsEntity();
      this.primeChartDataList = [];
      this.primeChartLabelList = [];
      this.primeResultData = [];

      for (let x = 0; x < this.queryData.length; x++) {
        // prime chart
        // only need label values once and data corresponds to label so load both here
        if (this.primeChartLabelList.find((test) => test === this.vm.request.labels[x]) === undefined) {
          this.primeChartLabelList.push(this.vm.request.labels[x]);
        }
        this.primeChartDataList.push(Number(this.queryData[x].value));
      }
      // put values in the prime object
      // master labels for axis
      this.primeChartData.labels = this.primeChartLabelList;
      // end

      // dataset node
      // init datasets node if not already
      this.primeChartData.datasets = (typeof this.primeChartData.datasets !== 'undefined'
        && this.primeChartData.datasets instanceof Array) ? this.primeChartData.datasets : [];
      // chart bar name
      this.primeChartDatasets.label = accountName;

      // chart bar color
      this.primeChartDatasets.backgroundColor = bgColor;

      // chart charting data
      this.primeChartDatasets.data = this.primeChartDataList;
      // end

      // push new records into base object - do it this way due to push shallow copy
      this.primeChartData.datasets.push(Object.assign({}, this.primeChartDatasets));

      this.changeDetectorRef.detectChanges();

      this.loadPrimeChartObj(this.primeChartData);

      this.primeResultData = this.primeChartData;

      // console.log('chart data obj:' + JSON.stringify(this.primeChartData));
    });
  }

  loadPrimeChartObj(obj) {
    this.primeResultData = '';
    this.primeResultData = Object.assign({}, obj);
    this.primeBarChartDataForChildComponent = this.primeResultData;
  }

  removeData(account) {
    // console.log('removing: ' + account);
    const removeIndex = this.primeResultData.datasets.findIndex(item => item.label === account);
    this.primeResultData.datasets.splice(removeIndex, 1);
    this.primeBarChartDataForChildComponent = this.primeResultData;
    // console.log('updated data: ' + JSON.stringify(this.primeResultData));

    this.onChartIcon(account, false);
  }

  onChartIcon(account, trend) {
    if (this.vm.incomeStatement) {
      for (const each of this.vm.incomeStatement) {
        if (each.account == account) {
          each.onChart = trend;
        }
      }
    }
    if (this.vm.balanceSheet) {
      for (const each of this.vm.balanceSheet) {
        if (each && each.account == account) {
          each.onChart = trend;
        }
      }
    }
  }

  changeTrendingType() {
    this.localStorage.StorageSet('trendingType', this.vm.request.type);

    this.buildChart();
  }

  changeTrendingDate() {
    this.localStorage.StorageSet('trendingDate', this.vm.request.toDate);
    this.vm.request.labels = [];

    const d = new Date(this.vm.request.toDate);
    const months = [$localize`:Jan @@jan:Jan`, $localize`:Feb @@feb:Feb`, $localize`:Mar @@mar:Mar`, $localize`:Apr @@apr:Apr`, $localize`:May @@may:May`, $localize`:Jun @@jun:Jun`, $localize`:Jul @@jul:Jul`, $localize`:Aug @@aug:Aug`, $localize`:Sep @@sep:Sep`, $localize`:Oct @@oct:Oct`, $localize`:Nov @@nov:Nov`, $localize`:Dec @@dec:Dec`];
    let tempMonth = d.getMonth();
    let columns;
    if (this.vm.request.includeForecast) {
      columns = 7;
      if (tempMonth === 11) {
        tempMonth = 1;
      } else if (tempMonth === 10) {
        tempMonth = 0;
      } else {
        tempMonth += 2;
      }
    } else {
      columns = 5;
    }
    for (let x = columns; x > -1; x--) {
      let fc = '';
      if (x > 5) {
        fc = ' *FC';
      }
      this.vm.request.labels[x] = months[tempMonth] + fc;
      tempMonth -= 1;
      if (tempMonth < 0) {
        tempMonth = 11;
      }
    }
    this.buildChart();
  }

  changeForecast() {
    this.localStorage.StorageSet('trendingForecasting', this.vm.request.includeForecast);
    this.vm.request.labels = [];

    const d = new Date(this.vm.request.toDate);
    const months = [$localize`:Jan @@jan:Jan`, $localize`:Feb @@feb:Feb`, $localize`:Mar @@mar:Mar`, $localize`:Apr @@apr:Apr`, $localize`:May @@may:May`, $localize`:Jun @@jun:Jun`, $localize`:Jul @@jul:Jul`, $localize`:Aug @@aug:Aug`, $localize`:Sep @@sep:Sep`, $localize`:Oct @@oct:Oct`, $localize`:Nov @@nov:Nov`, $localize`:Dec @@dec:Dec`];
    let tempMonth = d.getMonth();
    let columns;
    if (this.vm.request.includeForecast) {
      columns = 7;
      if (tempMonth === 11) {
        tempMonth = 1;
      } else if (tempMonth === 10) {
        tempMonth = 0;
      } else {
        tempMonth += 2;
      }
    } else {
      columns = 5;
    }
    for (let x = columns; x > -1; x--) {
      let fc = '';
      if (x > 5) {
        fc = ' *FC';
      }
      this.vm.request.labels[x] = months[tempMonth] + fc;
      tempMonth -= 1;
      if (tempMonth < 0) {
        tempMonth = 11;
      }
    }

    this.buildChart();
  }

  clearTrending() {

    this.removeData('Revenue');
    this.removeData('Cost of Sales');
    this.removeData('Margin');
    this.removeData('Expenses');
    this.removeData('Net Income');
    this.removeData('Cash Bank');
    this.removeData('Inventory');
    this.removeData('Accounts Receivable');
    this.removeData('Accounts Payable');
    this.removeData('Current Assets');
    this.removeData('Current Liabilities');
    this.removeData('Working Capital');


    if (this.vm.trendRevenue !== true) {
      this.localStorage.StorageSet('trendRevenue', '');
    }

    if (this.vm.trendCosts !== true) {
      this.localStorage.StorageSet('trendCosts', '');
    }

    if (this.vm.trendMargin !== true) {
      this.localStorage.StorageSet('trendMargin', '');
    }

    if (this.vm.trendExpenses !== true) {
      this.localStorage.StorageSet('trendExpenses', '');
    }

    if (this.vm.trendNetIncome !== true) {
      this.localStorage.StorageSet('trendNetIncome', '');
    }

    if (this.vm.trendCash !== true) {
      this.localStorage.StorageSet('trendCash', '');
    }

    if (this.vm.trendInventory !== true) {
      this.localStorage.StorageSet('trendInventory', '');
    }

    if (this.vm.trendReceivables !== true) {
      this.localStorage.StorageSet('trendReceivables', '');
    }

    if (this.vm.trendPayables !== true) {
      this.localStorage.StorageSet('trendPayables', '');
    }

    if (this.vm.trendCurrentAssets !== true) {
      this.localStorage.StorageSet('trendCurrentAssets', '');
    }

    if (this.vm.trendCurrentLiabilities !== true) {
      this.localStorage.StorageSet('trendCurrentLiabilities', '');
    }

    if (this.vm.trendWorkingCapital !== true) {
      this.localStorage.StorageSet('trendWorkingCapital', '');
    }

    this.localStorage.StorageSet('trendingType', '');
    this.localStorage.StorageSet('trendingDate', '');
    this.localStorage.StorageSet('trendingForecasting', '');

    this.vm.trendRevenue = this.vm.trendCosts = this.vm.trendMargin = this.vm.trendExpenses = this.vm.trendNetIncome = false;
    this.vm.trendCash = this.vm.trendInventory = this.vm.trendReceivables = this.vm.trendPayables = false;
    this.vm.trendCurrentLiabilities = this.vm.trendCurrentAssets = this.vm.trendWorkingCapital = false;

  }

  FilterObject(value, filterObj, except = false) {
    return new CustomArrayFilterPipe().transform(value, filterObj);
  }

}
