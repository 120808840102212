import { Component, ElementRef, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../Services/app-config.service';
import { EventEmitter } from '@angular/core';
import { FileUploadDataModel } from '../../../DataModels/file-upload-data.model';
import {TableDataService} from '../../../Services/tableData.service';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';
import {BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  @Output() newFileEvent = new EventEmitter<any>();
  @Output() deleteFileEvent = new EventEmitter<any>();
  @Output() newFileNameEvent = new EventEmitter();
  @Output() attachmentArrayChangeEvent = new EventEmitter();

  @Input() requiredFileType: string;
  @Input() attachment: any;
  @Input() useType: number;

  @ViewChildren('imageDeleteCheckboxes') checkboxes: QueryList<ElementRef>;

  // useType = wo, SingleFileFormManaged
  // useTypes =
  // DEFAULT - full managed mode when used in transactions based documents - manages images save and delete and emits events to caller
  // 2 = same as 1 with some mods specific to how wo was written
  // 3 = Single File and Form Managed - just a loader of data and emits to form / caller - emits delete so form / caller can manage

  fileName = '';
  fileExtension = '';
  fileIcon = '';
  fileType = '';
  fileDisplayThumbnail = false;

  checkAllButtonOn = false;
  uncheckAllButtonOn = false;
  singleUnmanaged = false;
  woMode = false;

  dataFromFileObject: FileUploadDataModel[] = [];

  uploadProgress: number;
  uploadSub: Subscription;
  dataFromFile: any;
  vm: any;
  existingFilesArrayObject: any = [];

  constructor(
    private tableDataService: TableDataService,
    private appConfig: AppConfigService,
    private confirmDialogService: ConfirmDialogService,
    private notificationService: BsToastService)
  {

  }

  ngOnInit() {
    this.singleUnmanaged = this.useType === 3;
    this.woMode = this.useType === 2;

    if (!this.singleUnmanaged) {
      this.vm = this.attachment;
      this.checkAllButtonOn = true;
      this.uncheckAllButtonOn = true;
      this.loadExisting();
    }
    // console.log('from main - rec get');
    // console.log(this.vm);
  }

  loadExisting() {

    // use the correct structure for loading those already stored - varies do the the way WO was built
    if (this.vm.item.attachments) {
      // transactions, shipments, entries
      this.existingFilesArrayObject = JSON.parse(JSON.stringify(this.vm.item.attachments));
    } else if (this.vm.item.wo_attachments) {
      // workorder
      this.existingFilesArrayObject = JSON.parse(JSON.stringify(this.vm.item.wo_attachments));
    } else {
      return;
    }
    // console.log('recs passed in');
    // console.log('raw');
    // console.log(this.vm.item);

    this.existingFilesArrayObject.forEach((x) => {
      this.fileExtension = '';
      // console.log('existing attachment number loaded');
      // console.log(x);

      this.fileExtension = x.type;
      // console.log('extension used from type on old rec: ' + x.type);

      if (this.fileExtension == 'xlsx') {
        this.fileIcon = 'bi bi-filetype-xlsx';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'xls') {
        this.fileIcon = 'bi bi-filetype-xls';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'png') {
        this.fileIcon = 'bi bi-filetype-png';
        this.fileDisplayThumbnail = true;
      } else if (this.fileExtension == 'jpg') {
        this.fileIcon = 'bi bi-filetype-jpg';
        this.fileDisplayThumbnail = true;
      } else if (this.fileExtension == 'gif') {
        this.fileIcon = 'bi bi-filetype-gif';
        this.fileDisplayThumbnail = true;
      } else if (this.fileExtension == 'pdf') {
        this.fileIcon = 'bi bi-filetype-pdf';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'docx') {
        this.fileIcon = 'bi bi-filetype-docx';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'doc') {
        this.fileIcon = 'bi bi-filetype-doc';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'csv') {
        this.fileIcon = 'bi bi-filetype-csv';
        this.fileDisplayThumbnail = false;
      } else {
        this.notificationService.showWarningToast('The file type selected is not currently supported');
        return;
      }

      this.dataFromFileObject.push({
        fileName: x.file_name,
        fileExtension: this.fileExtension,
        fileType: x.type,
        fileIcon: this.fileIcon,
        displayThumbnail: this.fileDisplayThumbnail,
        id: x.id,
        header_id: this.vm.item.header.id,
        deleted: false,
        fileData: x.attachment,
      });
    });
  }

  addNewFile(event) {
    const file: File = event.target.files[0];

    if (file) {
      const fileData = event.target.files[0];

      this.fileName = file.name;

      this.fileExtension = this.fileName.split('.').pop();
      // console.log('ext:' + this.fileExtension);

      if (this.fileExtension == 'xlsx') {
        this.fileIcon = 'bi bi-filetype-xlsx';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'xls') {
        this.fileIcon = 'bi bi-filetype-xls';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'png') {
        this.fileIcon = 'bi bi-filetype-png';
        this.fileDisplayThumbnail = true;
      } else if (this.fileExtension == 'jpg') {
        this.fileIcon = 'bi bi-filetype-jpg';
        this.fileDisplayThumbnail = true;
      } else if (this.fileExtension == 'gif') {
        this.fileIcon = 'bi bi-filetype-gif';
        this.fileDisplayThumbnail = true;
      } else if (this.fileExtension == 'pdf') {
        this.fileIcon = 'bi bi-filetype-pdf';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'docx') {
        this.fileIcon = 'bi bi-filetype-docx';
        this.fileDisplayThumbnail = false;
      } else if (this.fileExtension == 'doc') {
        this.fileIcon = 'bi bi-filetype-doc';
        this.fileDisplayThumbnail = false;
      }

      this.fileType = file.type;

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onload = () => {
        this.dataFromFile = reader.result;

        // load model to push to main page
        const fileRec = new FileUploadDataModel();
        fileRec.fileName = this.fileName;
        fileRec.fileExtension = this.fileExtension;
        fileRec.fileType = this.fileExtension;
        fileRec.fileIcon = this.fileIcon;
        fileRec.displayThumbnail = this.fileDisplayThumbnail;
        fileRec.id = null;
        fileRec.header_id = null;
        fileRec.deleted = false;
        fileRec.fileData = this.dataFromFile;

        this.dataFromFileObject.push(fileRec);

        // console.log(fileRec);

        // single file data emit
        this.newFileNameEmit(this.fileName);
        this.newFileEmit(this.dataFromFile);

        // entire attachment array emit
        this.attachmentArrayChangeEventEmit(this.dataFromFileObject);
        this.addAttachment(fileRec);
      };
    }
  }

  displayImageInNewTab(index) {
    const src = this.dataFromFileObject[index].fileData;
    window.open(src);
    // console.log(this.dataFromFileObject);
  }
  deleteAttachment(id) {

    if (!this.woMode) {
      this.tableDataService.deleteTableDataById('t_header_attachment', id).subscribe((result: any) => {
      });
    } else if (this.woMode) {
      this.tableDataService.deleteTableDataById('wo_attachments', id).subscribe((result: any) => {
      });
    }
  }

  addAttachment(attachmentRecord) {
    // map it to what api wants
    if (!this.singleUnmanaged) {
      const postRec = {
        id: attachmentRecord.id,
        header_id: this.woMode ? this.vm.item.wo_header.id : this.vm.item.header.id,
        file_name: attachmentRecord.fileName,
        type: attachmentRecord.fileType,
        attachment: attachmentRecord.fileData,
        audit_sequence: 0
      };

      // console.log('adding a rec with this header id: ' + postRec.header_id);

      // if this item does NOT have a header id it will not be saved here - has to be saved master record and adding file for this to run
      if (!postRec.header_id) {
        return;
      }
      // console.log('use type: ' + this.useType);
      if (!this.woMode) {
        this.tableDataService.post('t_header_attachment', postRec).subscribe((result: any) => {
          // console.log('post file result:' + JSON.stringify(result));
        });
      } else if (this.woMode) {
        // console.log('post to wo');
        this.tableDataService.post('wo_attachments', postRec).subscribe((result: any) => {
          // console.log('post file result:' + JSON.stringify(result));
        });
      }
    }
  }
  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  checkAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = true;
    });
  }

  downloadFile(index) {
    const src = this.dataFromFileObject[index].fileData;
    const filename = this.dataFromFileObject[index].fileName;
    const link = document.createElement('a');
    link.download = filename ? filename : 'AcctXDownloadFile.' + this.dataFromFileObject[index].fileExtension;
    link.href = src;
    link.click();
  }

  adjustCheckboxArray(event: any) {
    // console.log(event.target.id);
    // console.log(event.target.checked);
  }

  deleteItem() {
    let msg = '';
    let checkedFound = false;

    this.checkboxes.forEach((element) => {
      if (element.nativeElement.checked) {
        msg += this.dataFromFileObject[Number(element.nativeElement.id)].fileName + ' ';
        checkedFound = true;
      }
    });

    if (!checkedFound) {
      this.notificationService.showWarningToast('No files selected');
      return;
    }

    const brClass = this;
    const retMsg = '';
    const messages: string[] = ['Are you sure you want to remove attachment', msg];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.removeFile();
      },
      () => {
        brClass.notificationService.showSuccessToast('Delete Canceled');
      }
    );
  }

  removeFile() {
    // console.log('array before delete');
    // console.log(this.dataFromFileObject);
    if (!this.singleUnmanaged) {
      this.checkboxes.forEach((element) => {
        if (element.nativeElement.checked) {
          // console.log('is checked: ' + element.nativeElement.id );
          this.deleteAttachment(this.dataFromFileObject[Number(element.nativeElement.id)].id);
          this.dataFromFileObject.splice(Number(element.nativeElement.id), 1);
          // this.dataFromFileObject = this.dataFromFileObject.filter(file => file.fileName !== element.nativeElement.name);
        }
      });
    }

    // full attachment array emit
    this.attachmentArrayChangeEventEmit(this.dataFromFileObject);
    this.deleteFileEmit();
  }

  deleteFileEmit() {
    this.deleteFileEvent.emit();
  }

  newFileEmit(fileData: any) {
    this.newFileEvent.emit(fileData);
  }

  newFileNameEmit(fileName: string) {
    this.newFileNameEvent.emit(fileName);
  }

  attachmentArrayChangeEventEmit(arrayData: any) {
    // console.log('emitting this array');
    // console.log(arrayData);
    this.attachmentArrayChangeEvent.emit(arrayData);
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
}
