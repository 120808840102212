export class ActivityFeedModel {
  id: string;
  name: string;
  message: string;
  user_id: string;
  ActivityDate: string;
  company_id: string;
  entity_id: string;
  location_id: string;
  header_id: string;
  batch_type_id: string;

}
