import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataService } from '../../../../Services/tableData.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { AccountSubtotalModel } from '../../../../DataModels/account-subtotal-model';
import { ResponseModel } from '../../../../DataModels/response-model';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-account-subtotal-form',
  templateUrl: './account-subtotal-form.component.html',
  styleUrls: ['./account-subtotal-form.component.css'],
})
export class AccountSubtotalFormComponent implements OnInit, AfterViewInit {
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  // @ViewChild('tree', {static: true})
  // @ViewChild('subtotalTree') treeObj: TreeViewComponent;
  accountSubTotal: AccountSubtotalModel;
  // treeviewList: Array<object> = [];
  accountSubTotalForm: UntypedFormGroup;
  accountSubtotalID;
  buttonsDisabled = false;
  deleteButtonDisabled = false;
  stopEdit = false;
  SubTotalList: Array<object>;
  accountTypeList: Array<object>;
  accountTypeCount = 0;
  SubTotalCount = 0;
  submitted = false;
  treeviewList: any;
  field: object;
  treeViewExpanded = false;
  items: any;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.accountSubtotalID = this.route.snapshot.params.id;
  }

  createForm() {
    this.accountSubTotalForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      type_id: new UntypedFormControl(''),
      parent_id: new UntypedFormControl(null),
      sort_order: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      // audit_sequence: new FormControl(''),
      active: new UntypedFormControl(true),
    });
  }

  ngOnInit() {
    // note case of L - 2 diff methods L and l
    this.LoadAccountSubTotals();

    this.loadAccountTypes();

    if (this.accountSubtotalID) {
      this.loadAccountSubTotal();
      this.buttonConfig.reset = false;
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Account Subtotal @@accounts:Account Subtotal`);
  }

  get f() {
    return this.accountSubTotalForm.controls;
  }

  private loadAccountSubTotal() {
    this.table
      .getTableData('account_subtotal', this.accountSubtotalID)
      .subscribe(
        (result) => {
          this.accountSubTotal = result;

          const regexpGUID: RegExp =
            /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;
          if (
            this.accountSubtotalID &&
            !this.accountSubTotal.parent_id &&
            !regexpGUID.test(this.accountSubtotalID)
          ) {
            // if it is a GUID we can edit - means it was user entered. if not means it was a pre-loaded defined account and
            // once loaded cannot change or will screw up accounting. can alter with sql script if a MUST, but many tables.
            this.stopEdit = true;
            this.accountSubTotalForm.get('id').disable();
            this.accountSubTotalForm.get('name').disable();
            this.accountSubTotalForm.get('type_id').disable();
            this.accountSubTotalForm.get('parent_id').disable();
            // console.log('after' + this.stopEdit);
          }
        },
        (error: HttpErrorResponse) => {
          // this.notification.showErrorToast($localize`:Error loading Accounts @@errorLoadingAccounts:Error loading Accounts`);
          console.log(error);
        },
        () => {
          this.accountSubTotalForm.patchValue(this.accountSubTotal);
        }
      );
  }

  private LoadAccountSubTotals() {
    this.table
      .getApiListData('account_subtotal?pageNumber=0&pageSize=999999')
      .subscribe(
        (result: ResponseModel) => {
          this.SubTotalList = result.Data;
          this.SubTotalCount = this.SubTotalList.length;
        },
        (error: HttpErrorResponse) => {
          // this.notification.showErrorToast()$localize`:Error loading Subtotal Account @@errorLoadingSubtotalAccount:Error loading Subtotal Account`);
          console.log(error);
        },
        () => {}
      );
  }

  private loadAccountTypes() {
    this.table
      .getApiListData('account_type?pageNumber=0&pageSize=999999')
      .subscribe(
        (result: ResponseModel) => {
          this.accountTypeList = result.Data;
          this.accountTypeCount = this.accountTypeList.length;
          // console.log(JSON.stringify(this.accountTypeList));
        },
        (error: HttpErrorResponse) => {
          // this.notification.showErrorToast($localize`:Error loading Account Type @@errorLoadingAccountType:Error loading Account Type`);
          console.log(error);
        },
        () => {}
      );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.accountSubTotalForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Confirmation for Delete Account Subtotal @@confirmDeleteAccountSubtotal:Are you sure you want to delete account subtotal?`,
      this.accountSubTotal.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table
          .deleteTableDataById('account_subtotal', this.accountSubtotalID)
          .subscribe(
            (result) => {
              retMsg = result;
              // this.notification.success($localize`:Deleted @@deleted:Deleted`);
            },
            (error: HttpErrorResponse) => {
              // this.notification.showErrorToast($localize`:Error @@error:Error`);
              console.log(error);
            },
            () => {
              this.onClose();
            }
          );
      },
      () => {
        this.notification.showSuccessToast(
          $localize`:Delete Canceled @@deleteCanceled:Delete Canceled`
        );
      }
    );
  }

  resetForm() {
    this.accountSubTotalForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/account-subtotal-list']).then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.accountSubTotalForm.invalid) {
      this.notification.showErrorToast(
        $localize`:Form Errors @@formErrors:Form Errors!`
      );
      return;
    }
    this.accountSubTotal = Object.assign({}, this.accountSubTotalForm.value);
    if (this.accountSubtotalID) {
      if (this.accountSubTotalForm.invalid === false) {
        this.table
          .patch(
            'account_subtotal',
            this.accountSubtotalID,
            this.accountSubTotal
          )
          .then(
            (result) => {
              if (saveClose) {
                this.onClose();
              }
            },
            (error: HttpErrorResponse) => {
              //
              console.log(error);
            }
          );
        // return true;
      }
    } else {
      this.table
        .postWithPromise('account_subtotal', this.accountSubTotal)
        .then((res) => {
          // stops double posting when clicking Save
          let apiReturn: any = null;
          if (typeof res === 'object') {
            apiReturn = JSON.parse(JSON.stringify(res));
          }
          this.accountSubtotalID = apiReturn.id;
          if (saveClose) {
            this.onClose();
          }
        });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
