import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RoutesRecognized, Event} from '@angular/router';
import {filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingTrackerService {
  private previousUrl: string;
  private currentUrl: string;
  public breadcrumbString: string = ''

  constructor(private router: Router) {

    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
        // console.log('previous url', this.previousUrl);
        // console.log('current url', this.currentUrl);
      });

    router.events.subscribe((event: Event) => {
      if (event instanceof RoutesRecognized) {
        // * RoutesRecognized: When the router parses the URL and the routes are recognized.
        // ! This is the earliest event in the navigation lifecycle that you can begin to update the breadcrumbs
        // console.log('route from event catch 1st: ' + this.findLastFirstChild(event.state.root));
        // console.log('route from event catch 2nd: ' + this.findLastFirstChild(event.state.root));
        // this.breadcrumbString = this.getBreadcrumbs(this.findLastFirstChild(event.state.root));
        // console.log('bread crumb string: ' + this.breadcrumbString);

      }
    });
  }

  private findLastFirstChild(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    return snapshot.firstChild
      ? this.findLastFirstChild(snapshot.firstChild)
      : snapshot;
  }

  private getBreadcrumbs(snapshot: ActivatedRouteSnapshot): string {
    const breadcrumbs: string[] = [];
    // * At the root url, url[0] will not exist
    if (snapshot.url[0]?.path) {
      breadcrumbs.push(snapshot.url[0]?.path);
    }
    // * snapshot.params will be an object with properties that match the route params used for your current route
    // * Ex: snapshot.params = {class: 'English', chapter: '7', assignment: '4'}
    for (const [key, value] of Object.entries(snapshot.params)) {
      breadcrumbs.push(`${key.slice(0, 1).toUpperCase() + key.slice(1)}: ${value.slice(0, 1).toUpperCase() + value.slice(1)}`);
      // Breadcrumb template = "Key: Value"
    }
    return breadcrumbs.join(' > ');
    // Full breadcrumbs output = "Root > Key: Value > Key: Value > ..."
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }
}
