import {Component, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {StorageService} from '../../../Services/storage.service';
import {TableDataService} from '../../../Services/tableData.service';
import {NotificationService} from '../../../Services/notification.service';
import {FilterPipe} from 'ngx-filter-pipe';
import {DateFilterService} from '../../../Services/dateFilter.service';
import {Title} from '@angular/platform-browser';
import {AppConfigService} from '../../../Services/app-config.service';
import {DecimalNewPipe} from '../../common/DecimalNewPipe';
import {DecimalPipe, DatePipe, ViewportScroller} from '@angular/common';
import {ResponseModel} from '../../../DataModels/response-model';
import {filter, Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {PagerStateModel} from '../../../DataModels/pager-state-model';
import {PagerStateService} from '../../../Services/pager-state.service';
import {DatatableConfigService} from '../../../Services/datatable-config.service';
import {NgbpaginationConfigService} from '../../../Services/ngbpagination-config.service';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.css'],
})
export class ShipmentListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: NotificationService,
    private dateFilter: DateFilterService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private decimalPipe: DecimalNewPipe,
    private datePipe: DatePipe,
    private vps: ViewportScroller,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService,
    public confirmDialog: ConfirmDialogService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    this.vm.list = {};
    this.vm.batches = {};
    this.vm.shipmentSearch = null;
    this.vm.company = [];
  }

  shipmentCount = 0;
  isLoaded = false;
  advancedSearch;
  vm: any = {};

  // datatables stuff
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  submitted = false;

  urlParams: any;

  batchType: any;

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();

    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';

    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));
    if (
      !this.vm.permissions.EditReceivableShipments &&
      !this.vm.permissions.ViewReceivableShipments &&
      !this.vm.permissions.EditPayableShipments &&
      !this.vm.permissions.ViewPayableShipments &&
      !this.vm.permissions.Admin
    ) {
      document.location.href = '/';
    }

    this.urlParams = this.route.snapshot.queryParams;
    this.vm.type = this.urlParams.type;
    this.vm.subLedger = this.urlParams.subLedger;

    this.init();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([2, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  routeInvoice = (id) => {
    this.router.navigateByUrl('/transactions/invoice/form?invoiceId=' + id);
  };

  init() {
    this.vm.batchId = '';
    this.vm.hideBatches = true;
    this.vm.searchHidden = false;
    // console.log(this.vm.type);

    // let filterSort;
    // if (this.vm.subLedger === 'ap') {
    //   filterSort = 'audit_sequence';
    //   this.vm.title = 'Purchase Order Receiving';
    // } else if (this.vm.subLedger === 'ar') {
    //   filterSort = 'number';
    //   this.vm.title = 'Sales Shipments';
    // }

    this.vm.owingSelected = this.vm.totalSelected = 0;
    this.vm.shipmentToDateOpen = this.vm.shipmentFromDateOpen = false;

    this.vm.shipmentFilter = {};
    // this.vm.shipmentFilter = this.localStorage.StorageGet('shipmentFilter', {
    //   text: '',
    //   sort: filterSort,
    //   ascending: true,
    // });

    // this.vm.shipmentSearch = this.localStorage.StorageGet('shipmentSearch', '');
    // this.vm.shipmentToDate = this.localStorage.StorageGet('shipmentToDate', '');
    // this.vm.shipmentFromDate = this.localStorage.StorageGet('shipmentFromDate', '');
    // //this.vm.shipmentNumberOfResults = '25';
    // this.vm.unshipped = this.localStorage.StorageGet('unshipped', '');
    // this.vm.uninvoiced = this.localStorage.StorageGet('uninvoiced', '');
    if (this.vm.unshipped === 'true') {
      this.vm.unshipped = true;
    } else if (this.vm.unshipped != true) {
      this.vm.unshipped = false;
    }
    if (this.vm.uninvoiced === 'true') {
      this.vm.uninvoiced = true;
    } else if (this.vm.uninvoiced != true) {
      this.vm.uninvoiced = false;
    }

    this.search();
    if (this.vm.subLedger == 'ar') {
      // queryDelinquentReceivables();
      // convertToNumbers();
    }
  }

  convertToNumbers() {
    for (const [x, value] of Object.entries(this.vm.list)) {
      this.vm.list[x].detail_total = Number(this.vm.list[x].detail_total);
      this.vm.list[x].payment_applied_total = Number(this.vm.list[x].payment_applied_total);
      this.vm.list[x].audit_sequence = Number(this.vm.list[x].audit_sequence);
    }
  }

  // selectAll() {
  //   for (const list of this.vm.list) {
  //     (document.getElementById(list.id) as HTMLInputElement).checked = this.vm.selectAllItems;
  //   }
  //   this.selectItem();
  // }
  //
  // selectItem() {
  //   this.vm.owingSelected = this.vm.totalSelected = 0;
  //   let firstEntity = '';
  //   document.getElementById('pay-btn').classList.remove('d-none');
  //   document.getElementById('owingSelected').classList.remove('d-none');
  //   this.vm.itemsSelected = false;
  //   for (const [x, value] of Object.entries(this.vm.list)) {
  //     if ((document.getElementById(this.vm.list[x].id) as HTMLInputElement).checked) {
  //       if (firstEntity == '') {
  //         this.vm.itemsSelected = true;
  //         firstEntity = this.vm.list[x].entity_name;
  //       } else if (firstEntity != this.vm.list[x].entity_name) {
  //         document.getElementById('pay-btn').classList.add('d-none');
  //         document.getElementById('owingSelected').classList.add('d-none');
  //       }
  //       this.vm.totalSelected += Number(this.vm.list[x].header_total);
  //       this.vm.owingSelected += Number(this.vm.list[x].header_owing);
  //       document.getElementById(this.vm.list[x].id + '-tr').classList.add('success');
  //     } else {
  //       document.getElementById(this.vm.list[x].id + '-tr').classList.remove('success');
  //     }
  //   }
  // }

  updateBatchesSelectedInModal(batchId: string) {
    this.getBatchItems(batchId);
    // this.paginate(false);
  }

  search() {
    this.vm.searching = true;
    this.vm.batchId = '';
    this.vm.tempDescription = '';
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '12';
      this.batchType = '12';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '11';
      this.batchType = '11';
    }

    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&search=' + (this.vm.shipmentSearch ? this.vm.shipmentSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.shipmentFromDate ? this.dateFilter.GetDateFormat(this.vm.shipmentFromDate) : '');
    paramString += '&toDate=' + (this.vm.shipmentToDate ? this.dateFilter.GetDateFormat(this.vm.shipmentToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    paramString += '&numberOfResults=' + (this.vm.shipmentNumberOfResults ? this.vm.shipmentNumberOfResults : '');
    paramString += '&includePosted=' + (this.vm.includePosted ? this.vm.includePosted : '');

    this.tableDataService.getTableData('t_batch?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
    });

    paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&batchId=' + (this.vm.batchId ? this.vm.batchId : '');
    paramString += '&search=' + (this.vm.shipmentSearch ? this.vm.shipmentSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.shipmentFromDate ? this.dateFilter.GetDateFormat(this.vm.shipmentFromDate) : '');
    paramString += '&toDate=' + (this.vm.shipmentToDate ? this.dateFilter.GetDateFormat(this.vm.shipmentToDate) : '');
    paramString += '&unshipped=' + (this.vm.unshipped ? this.vm.unshipped : '');
    paramString += '&uninvoiced=' + (this.vm.uninvoiced ? this.vm.uninvoiced : '');
    paramString += '&numberOfResults=' + (this.vm.shipmentNumberOfResults ? this.vm.shipmentNumberOfResults : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('ListShipments?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dtTrigger.next('');
      this.dataTableReady = true;

      this.convertToNumbers();
      this.vm.searching = false;
      this.isLoaded = true;
    });
  }

  generateInvoice(id) {
    const messages: string[] = [
      $localize`:Generate Invoice? @@generateInvoice:Generate Invoice?`,
      '',
    ];
    this.confirmDialog.confirmThis(
      messages,
      () => {
        // fall thru on yes
        this.generateInv(id);
      },
      () => {
        this.notification.success($localize`:Canceled @@Canceled:Canceled`);
        return;
      }
    );
  }

  generateInv(id) {
    if (this.vm.subLedger == 'ar') {
      this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newAR&batchId=&shipmentId=' + id);
    } else {
      this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newAP&batchId=&shipmentId=' + id);
    }
  }

  completeShipment(id) {
    // console.log(id);
    const messages: string[] = [
      $localize`:Create Invoice? @@createInvoice:Create Invoice?`,
      '',
    ];
    this.confirmDialog.confirmThis(
      messages,
      () => {
        // ok - just fall thru to task
        this.completeShip(id);
      },
      () => {
        // cancelled
        this.notification.success($localize`:Canceled @@canceled:Canceled`);
        return;
      }
    );
  }

  completeShip(id) {
    if (this.vm.permissions.Admin || this.vm.permissions.EditSalesShipments || this.vm.permissions.EditSalesOrders) {
      this.vm.buttonsDisabled = true;
      const changes = {completed: 'true'};
      this.tableDataService.patch('t_header', id, changes).then(
        (item: any) => {
          // this.notification.success($localize`:Completed @@completed:Completed`);
          this.getPagedApiData();
        },
        (error) => {
          this.vm.buttonsDisabled = false;
          this.notification.error(error.description);
        }
      );
    }
  }

  getBatchItems(batchId) {
    this.submitted = false;
    this.vm.tempDescription = '';
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '12';
      this.batchType = '12';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '11';
      this.batchType = '11';
    }

    this.vm.batchId = batchId;
    this.vm.searching = true;
    const obj: any = this.FilterObject(this.vm.batches, {id: this.vm.batchId}, true);
    if (obj.length == 0) {
      obj[0] = {description: '', locked: ''};
    }
    this.vm.tempDescription = (obj) ? obj[0].description : '';
    this.vm.tempBatchLocked = (obj) ? obj[0].locked : false;
    let paramString = '';
    paramString += 'batchId=' + ((this.vm.batchId) ? this.vm.batchId : '');
    paramString += '&batchType=' + ((tempBatchType) ? tempBatchType : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('ListShipments?' + paramString).pipe(take(1)).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.shipmentCount = result.totalRecords;
      this.convertToNumbers();
      this.vm.searching = false;

      this.totalRecords = result.totalRecords;
      this.paginate(false);
    });
  }

  getBatches() {
    this.vm.searching = true;
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '12';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '11';
    }

    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    // paramString += '&search=' + ((this.vm.shipmentSearch) ? this.vm.shipmentSearch : '');
    // paramString += '&fromDate=' + ((this.vm.shipmentFromDate) ? this.dateFilter.GetDateFormat(this.vm.shipmentFromDate) : '');
    // paramString += '&toDate=' + ((this.vm.shipmentToDate) ? this.dateFilter.GetDateFormat(this.vm.shipmentToDate) : '');
    paramString += '&lockedBatches=' + (this.vm.lockedBatches ? this.vm.lockedBatches : '');
    // paramString += '&shipmentNumberOfResults=' + ((this.vm.shipmentNumberOfResults) ? this.vm.shipmentNumberOfResults : '');
    // paramString += '&includePosted=' + ((this.vm.includePosted) ? this.vm.includePosted : '');

    this.tableDataService.getTableData('t_batch', '?' + paramString).subscribe((result: any[]) => {
      this.vm.batches = result;
      if (this.FilterObject(this.vm.batches, {id: this.vm.batchId}, true).length == 1) {
        this.vm.tempDescription = this.FilterObject(this.vm.batches, {id: this.vm.batchId}, true)[0].description;
        this.vm.tempBatchLocked = this.FilterObject(this.vm.batches, {id: this.vm.batchId}, true)[0].locked;
      }
      this.vm.searching = false;
    });
  }

  newBatch() {
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableShipments) {
      let newBatch;
      if (this.vm.subLedger == 'ar') {
        newBatch = {type_id: 11};
      } else if (this.vm.subLedger == 'ap') {
        newBatch = {type_id: 12};
      }

      this.tableDataService.post('t_batch', newBatch).subscribe(
        (item: any) => {
          this.getBatches();
          this.vm.batchId = item.id;
          this.vm.tempDescription = 'Batch';
          // this.notification.success($localize`:Batch Added @@batchAdded:Batch Added`);
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.buttonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          this.notification.warning(ErrorMsg);
        }
      );
    }
  }

  updateBatch() {
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableShipments) {
      this.vm.buttonsDisabled = true;
      const changes = {description: this.vm.tempDescription};
      this.tableDataService
        .patch('t_batch', this.vm.batchId, changes)
        .then((item: any) => {
          // this.notification.success(this.vm.tempDescription);
          this.getBatches();
        })
        .catch((msg: string) => {
          this.vm.buttonsDisabled = false;
          this.notification.error(msg);
        });
    }
  }

  postBatch(batchId) {
    if (this.vm.permissions.Admin || this.vm.permissions.EditReceivableShipments) {
      this.vm.buttonsDisabled = true;
      const changes = {locked: 'true'};
      this.tableDataService
        .patch('t_batch', batchId, changes)
        .then((item: any) => {
          if (item) {
            // this.notification.success(item.description);
          }
          this.getBatches();
        })
        .catch((msg: string) => {
          this.vm.buttonsDisabled = false;
          this.notification.error(msg);
        });
    }
  }

  filter(filterItem) {
    this.vm.shipmentFilter = Object.assign(this.vm.shipmentFilter, filterItem);
    // this.localStorage.StorageSet('shipmentFilter', this.vm.shipmentFilter);
  }

  FilterObject(value, filterObj, except = false) {
    return new FilterPipe().transform(value, filterObj);
  }

  onNavigate(id: any, print = false) {
    this.tableDataService
      .getApiListData('entity?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        // this.localStorage.StorageSet('entity', JSON.stringify(result.Data));
        if (print) {
          this.router.navigate(['/transactions/shipment/form'], {queryParams: {shipmentId: id, action: 'print'}});
        } else {
          this.router.navigate(['/transactions/shipment/form'], {
            queryParams: {shipmentId: id, type: this.vm.type},
          });
        }
      });
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  invoiceNavigate(id) {
    this.router.navigate(['/transactions/invoice/form'], {queryParams: {invoiceId: id}});
  }

  hideBatches() {
    this.vm.batchesHidden = !this.vm.batchesHidden;
  }

  scrollTo(anchor: string): void {
    if (!this.vm.batchesHidden) {
      this.vps.scrollToAnchor(anchor);
    }
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    this.vm.searching = true;
    // this.vm.batchId = '';
    this.vm.tempDescription = '';
    let tempBatchType;
    if (this.vm.subLedger == 'ap') {
      tempBatchType = '12';
    } else if (this.vm.subLedger == 'ar') {
      tempBatchType = '11';
    }

    let paramString = '';
    paramString += 'batchType=' + (tempBatchType ? tempBatchType : '');
    paramString += '&batchId=' + (this.vm.batchId ? this.vm.batchId : '');
    paramString += '&search=' + (this.vm.shipmentSearch ? this.vm.shipmentSearch : '');
    paramString +=
      '&fromDate=' + (this.vm.shipmentFromDate ? this.dateFilter.GetDateFormat(this.vm.shipmentFromDate) : '');
    paramString += '&toDate=' + (this.vm.shipmentToDate ? this.dateFilter.GetDateFormat(this.vm.shipmentToDate) : '');
    paramString += '&unshipped=' + (this.vm.unshipped ? this.vm.unshipped : '');
    paramString += '&uninvoiced=' + (this.vm.uninvoiced ? this.vm.uninvoiced : '');
    paramString += '&numberOfResults=' + (this.vm.shipmentNumberOfResults ? this.vm.shipmentNumberOfResults : '');
    paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('ListShipments?' + paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;

      this.convertToNumbers();
      this.vm.searching = false;
    });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.shipmentSearch = value.searchTerm;
    this.vm.shipmentFromDate = value.fromDate;
    this.vm.shipmentToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.shipmentSearch = value.searchTerm;
    this.vm.shipmentFromDate = value.fromDate;
    this.vm.shipmentToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
