import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AppConfigService} from './app-config.service';
import {HttpClient} from '@angular/common/http';
import {StorageService} from './storage.service';
import {PermissionsService} from './permissions.service';
import {PreferencesService} from './preferences.service';
import {SharedDataService} from './sharedData.service';
import {EncrDecrService} from './encr-decr.service';
import {SysUserModel} from '../DataModels/sys-user-model';
import {BehaviorSubject, Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {BsToastService} from './bs-toast-service';
import {jwtDecode} from 'jwt-decode';
import {RefreshTokenInfo, RootObjectTokenUser} from '../DataModels/auth.models';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<SysUserModel>;
  public userInfo: SysUserModel;

  public refreshingTokens$ = new BehaviorSubject<boolean>(false);
  public isAccessTokenValid$ = new BehaviorSubject<boolean>(false);
  public accessToken$ = new BehaviorSubject<string>('');
  private token = '';


  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
    protected http: HttpClient,
    private storage: StorageService,
    private permissions: PermissionsService,
    private preferences: PreferencesService,
    private toast: BsToastService,
    private sharedData: SharedDataService,
    private encryptDecrypt: EncrDecrService,
  ) {
  }

  isAuthenticated(): boolean {
    const accessToken: SysUserModel = JSON.parse(localStorage.getItem('currentUser'));
    if (accessToken.accessToken) {
      return true;
    }
    return false;
  }

  async login(username: string, password: string) {
    const tokenPath = this.appConfigService.tokenPath;
    const authPath = this.appConfigService.apiBaseUrl;

    console.log('User: ' + username +  ' attempting login at: ' + new Date().toISOString());

    this.http.post<SysUserModel>(authPath + tokenPath + 'login', {username, password}).pipe()
      .subscribe({
        next: (user: SysUserModel) => {
          this.userInfo = user;
        },
        error: (err: any) => {
          console.error(err);
          console.log('User: ' + username + ' failed login at: ' + new Date().toISOString());
        },
        complete: () => {
          // decode the jwt so we can store some of the values
          const decodedToken: any = jwtDecode(this.userInfo.accessToken);
          // console.log(decodedToken);
          // console.log('date from access token: ' + new Date(decodedToken.exp * 1000));
          // console.log('is token expired: ' + jwtDecode(this.userInfo.accessToken));
          // console.log('date from access token2: ' + Math.floor(new Date().getTime() / 1000));
          // const currentTime = Math.floor(new Date().getTime() / 1000);
          this.userInfo.accessTokenExpiration = decodedToken.exp;
          this.userInfo.refreshTokenExpiration = new Date(this.userInfo.refreshTokenExpiration).toUTCString();

          // store user details and jwt stuff in session - use this later to check token expiry
          localStorage.setItem('currentUser', JSON.stringify(this.userInfo));

          const accessToken: SysUserModel = JSON.parse(localStorage.getItem('currentUser'));

          if (accessToken.accessToken != '') {
            this.getUserInfo();
          } else {
            this.toast.showWarningToast('Invalid or missing token. Access denied.');
            this.logout();
          }
        },
      });
  }

  refreshToken(userInfo: any): Observable<RefreshTokenInfo> {
    this.refreshingTokens$.next(true);
    this.token = '';
    this.accessToken$.next(null);
    this.isAccessTokenValid$.next(false);

    const tokenPath = this.appConfigService.tokenPath;
    const authPath = this.appConfigService.apiBaseUrl + tokenPath;

    return this.http.post<any>(authPath + 'refresh', {
      accessToken: userInfo.accessToken,
      refreshToken: userInfo.refreshToken,
      username: userInfo.username
    }).pipe(tap(tokenInfo => {
      this.toast.showInfoToast('refreshing');
      this.refreshingTokens$.next(false);
      this.accessToken$.next(tokenInfo.accessToken);
      this.token = tokenInfo.accessToken;

      // update tokens in stored user info and save
      const existCurrentUserStored: RootObjectTokenUser = userInfo;
      existCurrentUserStored.accessToken = tokenInfo.accessToken;
      existCurrentUserStored.refreshToken = tokenInfo.refreshToken;
      existCurrentUserStored.expiration = tokenInfo.expiration;
      localStorage.setItem('currentUser', JSON.stringify(existCurrentUserStored));

      if (this.token) {
        this.isAccessTokenValid$.next(true);
      } else {
        this.isAccessTokenValid$.next(false);
      }
    }));
  }

  getUserInfo() {
    let retVal = false;
    const endPointUrl = this.appConfigService.apiBaseUrl + 'sys_user/me';

    this.http.get<SysUserModel>(endPointUrl).pipe(take(1)).subscribe({
      next: (user: SysUserModel) => {
        this.userInfo = user;
        if (!user.companyDbName) {
          this.toast.showErrorToast('No company database has been assigned to this user. Contact your System Administrator.');
          return;
        }
      },
      error: (e) => {
        console.error('Login error: ', e);
      },
      complete: () => {
        if (!this.userInfo.companyDbName) {
          this.toast.showErrorToast('No company database has been assigned to this user. Contact your System Administrator.');
          return;
        }

        this.permissions.LoadPermissions(JSON.parse(this.userInfo.Permissions), 'auth service');
        this.preferences.LoadPreferences(JSON.parse(this.userInfo.Preferences));

        this.storage.StorageSet('Permissions', this.encryptDecrypt.encrypt(this.userInfo.Permissions));
        this.storage.StorageSet('Preferences', this.encryptDecrypt.encrypt(this.userInfo.Preferences));

        this.sharedData.addLoggedIn(true);
        this.sharedData.addUsername(this.userInfo.name);
        this.sharedData.addPassword(this.userInfo.password);
        this.sharedData.addLoggedIn(true);
        this.sharedData.addPrefsData(this.userInfo.Preferences);
        this.sharedData.addPermsData(this.userInfo.Permissions);

        // good to here so set retVal to true
        retVal = true;
        console.log('User: ' + this.userInfo.name + ' successfully logged in at: ' + new Date().toISOString());

        if (this.appConfigService.useLastPageAutoRouting && this.isAuthenticated()) {
          // check to see if user has logged in on this ls session before and has a last page viewed- if so, go to it on login
          const lastPageViewed = localStorage.getItem('last_visited_url');
          if (lastPageViewed && lastPageViewed !== '/null'
            && lastPageViewed !== ''
            && lastPageViewed !== '/common/error500-page'
            && lastPageViewed !== '/common/error404-page') {
            this.router.navigateByUrl(lastPageViewed).then();
            // retVal = true;
          } else {
            this.router.navigateByUrl('/').then();
          }
        } else if (this.isAuthenticated()) {
          // if user is valid and logged in route to home
          this.router.navigateByUrl('/home').then();
        } else {
          // if not valid clear anything set and route to login
          this.logout();
        }
      }
    });
  }

  logout(isTimeOut: boolean = false) {
    // write out info before user and login removed
    const user = this.currentUser();
    if (!isTimeOut) {
      console.log('User: ' + user?.username + ' logged out at: ' + new Date().toISOString());
    } else {
      console.log('User: ' + user?.username + ' session timed out at: ' + new Date().toISOString());
    }

    // save last visited page to put back in below. this ls item url storage is managed in app.component
    const lastPage = localStorage.getItem('last_visited_url');
    const lastError = localStorage.getItem('lastErrorTrapped');
    const lastDateTime = localStorage.getItem('lastErrorDateTime');

    // need both clears on a log out so we start completely fresh with no locals fresh
    this.storage.Clear();
    localStorage.clear();
    localStorage.removeItem('currentUser'); // just to be sure

    // put last visited page back after we cleared all so login will find it and take user back to this page when authorized
    if (lastPage !== null && lastPage !== '/common/error500-page' && lastPage !== '/common/error404-page') {
      localStorage.setItem('last_visited_url', lastPage);
    }

    // put last error back in case 401 from many calls on same page. interceptor checks this value fro prev fire
    localStorage.setItem('lastErrorTrapped', lastError);
    localStorage.setItem('lastErrorDateTime', lastDateTime);

    this.router.navigateByUrl('/login').then(() => {
      // window.location.reload();
    });
  }

  public currentUser(): any | null {
    const storedUser = localStorage.getItem('currentUser');
    if (!this.user && storedUser) {
      this.user = JSON.parse(storedUser);
      return this.user;
    } else {
      return null;
    }
  }

}
