import {Component, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import {TableDataService} from '../../../Services/tableData.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../Services/storage.service';
import {Title} from '@angular/platform-browser';
import {DateFilterService} from '../../../Services/dateFilter.service';
import {AppConfigService} from '../../../Services/app-config.service';
import {DatePipe} from '@angular/common';
import {DecimalNewPipe} from '../../common/DecimalNewPipe';
import {ResponseModel} from '../../../DataModels/response-model';
import {ConfirmDialogService} from '../../../Services/confirm-dialog.service';
import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {PagerStateModel} from '../../../DataModels/pager-state-model';
import {PagerStateService} from '../../../Services/pager-state.service';
import {DatatableConfigService} from '../../../Services/datatable-config.service';
import {NgbpaginationConfigService} from '../../../Services/ngbpagination-config.service';
import {BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-invoice-recurring',
  templateUrl: './invoice-recurring-list.component.html',
  styleUrls: ['./invoice-recurring-list.component.css'],
})
export class InvoiceRecurringListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private titleService: Title,
    private appConfig: AppConfigService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalNewPipe,
    private dateFilter: DateFilterService,
    private confirmDialogService: ConfirmDialogService,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
    // this.vm.selectAllItems = false;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  vm: any = {};
  hideBatches;
  isLoaded = false;
  paymentId = '';

  checkAll = false;
  selectedRecords = [];
  subscriptionGroup: Subscription[] = [];

  // datatables stuff
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: Promise<DataTables.Api>;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;
  dataTableReady = false;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Recurring Invoices @@recurringInvoices:Recurring Invoices`);
  }

  ngOnInit() {

    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.configDataTable();
    this.getPagerStatus().then();

    this.route.queryParams.pipe().subscribe((params) => {
      this.vm.type = params.type;
      this.vm.list = [];
      // this.dtTrigger.next('');

      //  this.route.params.subscribe((params) => {
      this.vm.reverse = -1;
      if (this.vm.type == 'ap') {
        let paramString = '';
        paramString += 'batchType=4';
        paramString += '&recurring=1';
        paramString += '&search=' + (this.vm.entrySearch ? this.vm.entrySearch : '');
        paramString +=
          '&fromDate=' + (this.vm.entryFromDate ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
        paramString += '&toDate=' + (this.vm.entryToDate ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
        paramString += '&locked=' + this.vm.unappliedOnly;
        // paramString += '&numberOfResults=' + this.localStorage.StorageGet('numberOfResults', '');
        paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
        paramString += '&pageSize=' + this.pageSize;

        this.tableDataService.getTableData('entry', '?' + paramString).subscribe((resultAp: any) => {
          this.vm.list = [];
          this.vm.list = resultAp.Data;
          this.totalRecords = resultAp.totalRecords;
          this.dataTableReady = true;

          this.paginate(false);

          // this.convertToNumbers();
        });
      } else {
        let paramString = '';
        paramString += 'batchType=2';
        paramString += '&recurring=1';
        paramString += '&search=' + (this.vm.entrySearch ? this.vm.entrySearch : '');
        paramString +=
          '&fromDate=' + (this.vm.entryFromDate ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
        paramString += '&toDate=' + (this.vm.entryToDate ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
        paramString += '&locked=' + this.vm.unappliedOnly;
        paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
        paramString += '&pageSize=' + this.pageSize;


        this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((resultAr: ResponseModel) => {
          this.vm.list = [];
          this.vm.list = resultAr.Data;
          this.totalRecords = resultAr.totalRecords;
          this.paginate(false);
          this.dataTableReady = true;
        });
      }

      this.isLoaded = true;
      this.vm.listInitiated = true;

      this.vm.owingSelected = this.vm.totalSelected = 0;
      this.vm.invoiceToDateOpen = this.vm.invoiceFromDateOpen = false;
      // this.dataTableReady = true;

      //  });
    });
    this.dtTrigger.next('');
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([3, 'asc']);
  }

  reloadDataTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(x => x.clear);
    });
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  convertToNumbers() {
    for (const obj of this.vm.list) {
      obj.detail_total = Number(obj.detail_total) * this.vm.reverse;
      obj.audit_sequence = Number(obj.audit_sequence);
      if (obj.payment_applied_total) {
        obj.payment_applied_total = Number(obj.payment_applied_total) * this.vm.reverse;
      } else {
        obj.payment_applied_total = 0;
      }
    }
  }

  selectItem() {
    this.vm.owingSelected = this.vm.totalSelected = 0;

  }

  search() {
    this.dataTableReady = false;
    this.vm.searching = true;
    this.vm.selectAllItems = false;
    if (this.vm.unappliedOnly) {
      this.vm.numberOfResults = 'All';
    }
    if (this.vm.type === 'ap') {
      let paramString = '';
      paramString += 'batchType=4';
      paramString += '&recurring=1';
      paramString += '&search=' + (this.vm.entrySearch ? this.vm.entrySearch : '');
      paramString +=
        '&fromDate=' + (this.vm.entryFromDate ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
      paramString += '&toDate=' + (this.vm.entryToDate ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
      paramString += '&locked=' + this.vm.unappliedOnly;
      paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        // this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.vm.listInitiated = true;
        // this.convertToNumbers();
      });
    } else {
      this.dataTableReady = false;
      let paramString = '';
      paramString += 'batchType=2';
      paramString += '&recurring=1';
      paramString += '&search=' + (this.vm.entrySearch ? this.vm.entrySearch : '');
      paramString +=
        '&fromDate=' + (this.vm.entryFromDate ? this.dateFilter.GetDateFormat(this.vm.entryFromDate) : '');
      paramString += '&toDate=' + (this.vm.entryToDate ? this.dateFilter.GetDateFormat(this.vm.entryToDate) : '');
      paramString += '&locked=' + this.vm.unappliedOnly;
      paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;
        this.totalRecords = result.totalRecords;
        this.paginate(false);
        // this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.vm.listInitiated = true;
        // this.convertToNumbers();
      });
    }
  }

  filter(filter) {
    this.vm.invoiceFilter = Object.assign(this.vm.invoiceFilter, filter);
  }

  checkSelected() {
    const selectedIds = this.vm.list.filter((rec) => rec.selected).map((rec) => rec.id);
    // const selectedRows = this.myGrid.getselectedrowindexes();
    this.vm.recurring = [];
    for (const selectedId of selectedIds) {
      this.vm.recurring.push({
        header_id: selectedId,
      });
    }
    if (!this.vm.recurring) {
      this.notification.showWarningToast(
        $localize`:You must select at least one entry @@youmustselectatleastoneentry:You must select at least one entry`
      );
    }
  }

  // selectRow(recId: any, checked: boolean) {
  //   console.log('rec val:' + recId + ' - ' + checked);
  //   if (!checked) {
  //     this.selectedRecords.forEach((element, index) => {
  //       if (element == recId) {
  //         this.selectedRecords.splice(index, 1);
  //       }
  //     });
  //   } else if (checked) {
  //     this.selectedRecords.push(recId);
  //   }
  //   console.log('selected records' + JSON.stringify(this.selectedRecords));
  // }

  selectRow(rec: any, checked: boolean) {
    // console.log('rec val:' + rec + ' - ' + checked);
    if (this.checkAll && rec) {
      this.checkAll = false;
    }
    if (!checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec) {
          item.selected = false;
        }
      });
    } else if (checked) {
      this.vm.list.forEach((item) => {
        if (item.id === rec && !item.locked) {
          item.selected = true;
        }
      });
    }
    this.isAllChecked();

    // console.log('single select records' + JSON.stringify(this.vm.list));
  }

  selectAllRows(event: any) {
    if (!event) {
      this.vm.list.forEach((item) => (item.selected = false));
    } else if (event) {
      // this.vm.list.forEach((item) => (item.selected = true));
      this.vm.list.forEach((item) => {
        if (!item.locked) {
          item.selected = true;
        }
      });
    }
    // console.log('all select records: ' + JSON.stringify(this.vm.list));
  }

  isAllChecked(): boolean {
    const retVal = this.vm.list.every((x) => x.selected === true);
    this.checkAll = retVal;
    return retVal;
  }

  confirmDeleteSelected() {
    if (this.vm.list.filter(x => x.selected).length === 0) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }

    const messages: string[] = [
      $localize`:Are you sure you want to Delete? @@areyousureyouwanttodelete:Are you sure you want to Delete?`,
      'Selected Count: ' + this.vm.list.filter(x => x.selected).length,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.deleteSelected();
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      }
    );
  }


  deleteSelected() {
    // just need to send the header id to the api
    const arrayOfItemsToDelete = [];
    this.vm.list.forEach((x) => {
      if (x.selected) {
        arrayOfItemsToDelete.push({header_id: x.id});
      }
    });

    if (!arrayOfItemsToDelete.length) {
      this.notification.showWarningToast('No records selected to delete!');
      return;
    }
    // console.log('selected recs: ' + JSON.stringify(arrayOfItemsToDelete));

    // makes action button spinner turn while deleting
    this.isLoaded = false;
    // pass array as body in a delete to api entryController
    this.subscriptionGroup.push(this.tableDataService.deleteTableDataByArray('entry', arrayOfItemsToDelete).subscribe((item: any) => {
      if (item) {
        // console.log(item);
        if (item.Message === 'Success') {
          // this.isLoaded = true;
          this.getPagedApiData();
        }
      }
    }));
  }


  // old
  // selectAllRows() {
  //   console.log(this.checkAll);
  //
  //   if (!this.checkAll) {
  //     this.selectedRecords = [];
  //   } else if (this.checkAll) {
  //     this.vm.list.forEach((rec) => this.selectedRecords.push(rec.id));
  //   }
  //   // this.selectedRecords.forEach((rec) => (rec.selected = this.checkAll));
  //   console.log('selected records' + this.selectedRecords);
  // }

  // generate() {
  // this.vm.alert = {};
  // this.vm.alert.message = [];
  //
  // this.vm.recurring = [];
  // for (const selectedId of this.selectedRecords) {
  //   this.vm.recurring.push({
  //     header_id: selectedId,
  //   });
  // }
  //  this.checkPost();
  // }

  generate() {
    this.checkSelected();
    this.checkPost();
  }

  postEntries() {
    if (this.vm.recurring.length > 0) {
      this.tableDataService.post('recurring', this.vm.recurring).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.vm.buttonsDisabled = false;
          this.search();
          this.checkAll = false;
        },
        (error: any) => {
          this.vm.buttonsDisabled = false;
          // this.notification.error(error.statusText);
          console.log(error.statusText);
        }
      );
    }
  }

  onNavigate(id: any) {
    // console.log('id:' + id);
    this.tableDataService
      .getApiListData('entity?state=active&pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        // this.localStorage.StorageSet('entity', JSON.stringify(result.Data));
        if (this.vm.type === 'ar') {
          this.router.navigate(['/transactions/invoice/form'], {queryParams: {invoiceId: id}}).then();
        } else if (this.vm.type === 'ap') {
          this.router.navigate(['/transactions/invoice/form'], {queryParams: {invoiceId: id}}).then();
        }
      });
  }

  checkPost() {
    if (this.vm.recurring.length === 0) {
      this.notification.showWarningToast(
        $localize`:You must select at least one entry @@youmustselectatleastoneentry:You must select at least one entry`
      );
      return;
    }
    const messages: string[] = [
      $localize`:Are you sure you want to Generate Entries? @@areyousureyouwanttogenerat4eentries:Are you sure you want to Generate Entries?`,
      'Selected Count: ' + this.vm.recurring.length,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.postEntries();
        // this.notification.success($localize`:Entries Created@@entriesCreated:Entries Created`);
      },
      () => {
        this.notification.showSuccessToast($localize`:Generate Canceled @@GenerateCanceled:Generate Canceled`);
      }
    );
  }

  // ----------------- Server side paging --------------------

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }

  getPagedApiData() {
    // this.vm.selectAllItems = false;
    this.dataTableReady = false;
    this.checkAll = false;

    // if (this.vm.unappliedOnly) {
    //   this.vm.numberOfResults = 'All';
    // }

    if (this.vm.type === 'ap') {
      let paramString = '';
      paramString += 'batchType=4';
      paramString += '&recurring=1';
      paramString += '&search=' + (this.vm.invoiceSearch === undefined ? '' : this.vm.invoiceSearch);
      paramString +=
        '&fromDate=' + (this.vm.invoiceFromDate ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
      paramString += '&toDate=' + (this.vm.invoiceToDate ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
      // paramString += '&unappliedOnly=' + this.vm.unappliedOnly;
      // paramString += '&numberOfResults=' + this.vm.numberOfResults;
      paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;

        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.vm.listInitiated = true;
      });
    } else {
      let paramString = '';
      paramString += 'batchType=2';
      paramString += '&recurring=1';
      paramString += '&search=' + (this.vm.invoiceSearch === undefined ? '' : this.vm.invoiceSearch);
      paramString +=
        '&fromDate=' + (this.vm.invoiceFromDate ? this.dateFilter.GetDateFormat(this.vm.invoiceFromDate) : '');
      paramString += '&toDate=' + (this.vm.invoiceToDate ? this.dateFilter.GetDateFormat(this.vm.invoiceToDate) : '');
      // paramString += '&unappliedOnly=' + this.vm.unappliedOnly;
      // paramString += '&numberOfResults=' + this.vm.numberOfResults;
      paramString += '&pageNumber=' + (this.page !== 0 ? this.page - 1 : 0);
      paramString += '&pageSize=' + this.pageSize;

      this.tableDataService.getApiListData('entry', '?' + paramString).subscribe((result: ResponseModel) => {
        this.vm.list = result.Data;

        this.totalRecords = result.totalRecords;
        this.paginate(false);
        this.dataTableFullClear();
        this.dtTrigger.next('');
        this.dataTableAddLastFilter();
        this.dataTableReady = true;

        this.vm.listInitiated = true;
      });
    }
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.invoiceSearch = value.searchTerm;
    this.vm.invoiceFromDate = value.fromDate;
    this.vm.invoiceToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.invoiceSearch = value.searchTerm;
    this.vm.invoiceFromDate = value.fromDate;
    this.vm.invoiceToDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }
}
