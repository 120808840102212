import {AfterViewInit, Component, OnInit} from '@angular/core';
import { TableDataService } from '../../../../Services/tableData.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DateFilterService } from '../../../../Services/dateFilter.service';
import { CompanyModel } from '../../../../DataModels/company-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { Observable } from 'rxjs';
import { CurrencyModel } from '../../../../DataModels/currency-model';
import { AccountModel } from '../../../../DataModels/account-model';
import { ResponseModel } from '../../../../DataModels/response-model';
import { IsRecordInUseService } from '../../../../Services/is-record-in-use.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {take} from 'rxjs/operators';
import { RoutingTrackerService} from '../../../../Services/routing-tracker.service';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.css'],
})
export class CompanyFormComponent implements OnInit, AfterViewInit {
  companyID;
  private company: CompanyModel;
  companyForm: UntypedFormGroup;
  submitted = false;
  currencies$: Observable<CurrencyModel[]>;
  accounts$: Observable<AccountModel[]>;
  logoFile: any;
  buttonsDisabled = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  routedFrom: string;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public dateFilter: DateFilterService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private isRecUsed: IsRecordInUseService,
    private prevUrl: RoutingTrackerService,
    private titleService: Title
  ) {
    this.createForm();
    this.companyID = this.route.snapshot.params.id;

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  createForm() {
    this.companyForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      short_name: new UntypedFormControl(''),
      currency_id: new UntypedFormControl(''),
      inter_company_account_id: new UntypedFormControl(''),
      year_end_date: new UntypedFormControl(''),
      cut_off_date: new UntypedFormControl(''),
      dba: new UntypedFormControl(''),
      tax_number: new UntypedFormControl(''),
      address_1: new UntypedFormControl(''),
      address_2: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      zip: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      invoice_prefix: new UntypedFormControl('inv'),
      next_invoice_number: new UntypedFormControl(1),
      next_quote_number: new UntypedFormControl(1),
      next_sales_shipment_number: new UntypedFormControl(1),
      next_purchaseorder_number: new UntypedFormControl(1),
      next_work_order_number: new UntypedFormControl(1),
      next_order_number: new UntypedFormControl(1),
      active: new UntypedFormControl(true),
      logo: new UntypedFormControl(''),
      displayLogo: new UntypedFormControl(''),
    });
  }

  setLogoFormFieldValue(file: any): void {
    // alert('event passed from upload');
    this.companyForm.patchValue({
      logo: file,
    });
    // console.log('file data uploaded at parent: ' + file);
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsText(fileData);

      reader.onload = () => {
        this.logoFile = reader.result;
      };
      // this.companyForm.patchValue({
      //   logo: this.logoFile
      // });
    }
  }

  ngOnInit() {
    if (this.companyID) {
      this.LoadData();
      this.loadLookups();
      this.buttonsDisabled = this.isRecUsed.isThisUsed(
        this.companyID,
        'company_id',
        't_header'
      );
      this.buttonConfig.reset = false;
    } else {
      this.loadLookups();
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Company @@company:Company`);
  }

  get f() {
    return this.companyForm.controls;
  }

  private LoadData() {
    this.table.getTableData('company', this.companyID).pipe(take(1))
      .subscribe({
    next: (result: any) => {
        this.company = result;
        // console.log('image loading:' + JSON.stringify(result.logo));
      },
      error: (err: HttpErrorResponse) => {
        // this.notification.showErrorToast($localize`:Error loading Companies @@errorLoadingCompanies:Error loading Companies`);
        console.log(err);
      },
      complete:  () => {
          if (this.company.year_end_date) {
            this.company.year_end_date = this.dateFilter.GetDateFormat(
              this.company.year_end_date
            );
          }
          if (this.company.cut_off_date) {
            this.company.cut_off_date = this.dateFilter.GetDateFormat(
              this.company.cut_off_date
            );
          }
          this.companyForm.patchValue(this.company);
        }
    });
  }

  loadLookups() {
    // this.companies$ = this.table.getTableData('company');
    this.table
      .getApiListData('account', '?pageNumber=0&pageSize=999999')
      .subscribe((res: ResponseModel) => {
        const data: any = res.Data;
        // console.log(JSON.stringify(data));
        this.accounts$ = data;
      });
    this.table
      .getApiListData('currency', '?pageNumber=0&pageSize=999999')
      .subscribe((res: ResponseModel) => {
        const data: any = res.Data;
        this.currencies$ = data;
      });
    // this.checkStocks$ =  this.table.getTableData('check_stock');
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.companyForm.reset();
    }
  }

  deleteLogo() {
    // companyForm.controls.logo.value
    this.companyForm.patchValue({logo: ''});
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete company? @@areYouSureYouWantToDeleteCompany:Are you sure you want to delete company?`,
      this.company.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('company', this.companyID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.showErrorToast($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast(
          $localize`:Delete Canceled @@deleteCanceled:Delete Canceled`
        );
      }
    );
  }

  resetForm() {
    this.companyForm.reset();
  }

  onClose() {
    // // this.router.navigate(['admin/company-list']);
    this.router.navigate([this.routedFrom]).then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.companyForm.invalid) {
      this.notification.showErrorToast(
        $localize`:Form Errors! @@formErrors:Form Errors!`
      );
      return;
    }
    // remove the logo display field from the form as it it not stored - only logo itself stored.
    this.companyForm.removeControl('displayLogo');

    // convert the image data to base64 encoded so api can parse correctly
    // this.companyForm.controls.logo.setValue(btoa(this.companyForm.controls.logo.value));

    this.company = Object.assign({}, this.companyForm.value);
    if (this.companyID) {
      if (this.companyForm.invalid === false) {
        // console.log('patch company: ' + JSON.stringify(this.company));
        this.table.patch('company', this.companyID, this.company).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('company', this.company).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.companyID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });

      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
