import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableDataService } from '../../../../Services/tableData.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentTypeModel } from '../../../../DataModels/payment-type-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-payment-type-form',
  templateUrl: './payment-type-form.component.html',
  styleUrls: ['./payment-type-form.component.css']
})
export class PaymentTypeFormComponent implements OnInit {
  paymentTypeID: string;
  paymentType: PaymentTypeModel;
  paymentTypeForm: UntypedFormGroup;

  paid = false;
  showDelete = false;
  submitted = false;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.paymentTypeID = this.route.snapshot.params.id;
  }

  get f() {
    return this.paymentTypeForm.controls;
  }

  createForm() {
    this.paymentTypeForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      active: new UntypedFormControl('true'),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Payment Type@@paymentType:Payment Type`);
    if (this.paymentTypeID) {
      this.LoadData();
      this.buttonConfig.reset = false;
    }
  }

  private LoadData() {
    this.table.getTableData('payment_type', this.paymentTypeID).subscribe(
      (result) => {
        this.paymentType = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error(
        //   $localize`:Error loading Location Access @@errorloadingLocationAccess:Error loading Location Access`
        // );
        console.log(
          error +
            ' - ' +
            $localize`:Error loading Location Access @@errorloadingLocationAccess:Error loading Location Access`
        );
      },
      () => {
        this.paymentTypeForm.patchValue(this.paymentType);
      }
    );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.paymentTypeForm.reset();
    }
  }

  resetForm() {
    this.paymentTypeForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/payment-type-list']).then();
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete payment type? @@areYouSureYouWantToDeletePaymentType:Are you sure you want to delete payment type?`,
      this.paymentType.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('payment_type', this.paymentTypeID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled@@deleteCanceled:Delete Canceled`);
      }
    );
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.paymentTypeForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    this.paymentType = Object.assign({}, this.paymentTypeForm.value);
    if (this.paymentTypeID) {
      if (this.paymentTypeForm.invalid === false) {
        this.table.patch('payment_type', this.paymentTypeID, this.paymentType).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('payment_type', this.paymentType).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.paymentTypeID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
