import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {NotificationService} from '../../../Services/notification.service';
import {FilterPipe} from 'ngx-filter-pipe';
import {TableDataService} from '../../../Services/tableData.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../Services/storage.service';
import {ResponseModel} from '../../../DataModels/response-model';

@Component({
  selector: 'app-earning-deduction-form',
  templateUrl: './earning-deduction-form.component.html',
  styleUrls: ['./earning-deduction-form.component.css']
})
export class EarningDeductionFormComponent implements OnInit, AfterViewInit {
  EarningForm: UntypedFormGroup;
  vm: any = {
    title: 'Earning & Deduction Form',
    item: {},
    buttonsDisabled: false,
    Preferences: [],
    permissions: [],
    mainListPage: '/payroll/earnings-list',
    formInitiated: false,
    defaultItem: [],
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private localStorage: StorageService,
              private tableDataService: TableDataService,
              private notification: NotificationService,
              private datePipe: DatePipe,
              private decimal: DecimalPipe,
              private titleService: Title,
              private formBuilder: UntypedFormBuilder,
              private filter: FilterPipe) {
  }

  ngOnInit() {
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    this.formInit();
    this.dataInit();
  }

  dataInit() {
    this.route.params.subscribe(params => {
      const Id = params.id;
      this.tableDataService.getApiListData('account','?state=active&pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
        this.vm.account = result.Data;
        if (this.vm.item.deduction == '1') {
          this.vm.item.deduction = true;
        } else {
          this.vm.item.deduction = false;
        }
      });
      if (Id !== undefined) {
        this.tableDataService.getTableData('earning', Id).subscribe((result: any[]) => {
          this.vm.item = result;
          this.vm.formInitiated = true;
          this.setValues();
          this.vm.defaultItem = result;
          // this.localStorage.StorageSet('formDataEarnings', JSON.stringify(this.vm.item));
        });
      }
    });
  }

  formInit() {
    this.EarningForm = this.formBuilder.group({
      item_code: new UntypedFormControl('', Validators.required),
      item_name: new UntypedFormControl('', Validators.required),
      item_deduction: new UntypedFormControl(''),
      item_sort_order: new UntypedFormControl('', Validators.required),
      item_accrual_account_id: new UntypedFormControl(''),
      item_expense_account_id: new UntypedFormControl(''),
      item_active: new UntypedFormControl('')
    });
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  setValues() {
    this.EarningForm.controls.item_code.setValue(this.vm.item.code);
    this.EarningForm.controls.item_name.setValue(this.vm.item.name);
    this.EarningForm.controls.item_deduction.setValue(this.vm.item.deduction);
    this.EarningForm.controls.item_sort_order.setValue(this.vm.item.sort_order);
    this.EarningForm.controls.item_active.setValue(this.vm.item.active);
    this.EarningForm.controls.item_accrual_account_id.setValue(this.vm.item.accrual_account_id);
    this.EarningForm.controls.item_expense_account_id.setValue(this.vm.item.expense_account_id);
  }

  setupValuesForSave() {
    this.vm.item.code = this.EarningForm.controls.item_code.value;
    this.vm.item.name = this.EarningForm.controls.item_name.value;
    this.vm.item.deduction = this.EarningForm.controls.item_deduction.value;
    this.vm.item.sort_order = this.EarningForm.controls.item_sort_order.value;
    this.vm.item.accrual_account_id = this.EarningForm.controls.item_accrual_account_id.value;
    this.vm.item.expense_account_id = this.EarningForm.controls.item_expense_account_id.value;
    this.vm.item.active = this.EarningForm.controls.item_active.value;
  }

  capitalizeFirstLetter(str): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  save() {

    this.vm.buttonsDisabled = true;
    const errorMessage = '';
    if (!this.EarningForm.valid) {
      for (const nameOfField in this.EarningForm.controls) {
        if (this.EarningForm.controls[nameOfField].invalid) {
          this.vm.buttonsDisabled = false;
          this.notification.error(this.capitalizeFirstLetter(nameOfField.replace(/_/g, ' ')) + ' '+ $localize`:field is required! @@fieldisrequired:field is required!`);
        }
      }
      return false;
    }
    if (errorMessage === '') {
      this.setupValuesForSave();

      if (this.vm.item.id === undefined) {
        this.tableDataService.post('earning', this.vm.item).subscribe((item: any) => {
          this.notification.success(item.description);
          this.localStorage.StorageSet('earning', '');
          this.router.navigateByUrl(this.vm.mainListPage);
        }, (error: any) => {
          this.vm.buttonsDisabled = false;
          if (error.error.Message !== undefined) {
            this.notification.error(error.error.Message);
          } else {
            this.notification.error(error);
          }
        });

      } else {
        // const changes = {};
        // // this.vm.defaultItem = JSON.parse(this.localStorage.StorageGet('formDataEarnings'));
        // for (const key in this.vm.item) {
        //   if ((this.vm.item[key] != this.vm.defaultItem[key] && typeof(this.vm.item[key]) !== 'object' && key !== 'TimeNotes') || key === 'date') {
        //     changes[key] = this.vm.item[key];
        //   }
        // }
        this.tableDataService.patch('earning', this.vm.item.id, this.vm.item)
          .then((item: any) => {
            this.notification.success(item.description);
            this.localStorage.StorageSet('earning', '');
            this.router.navigateByUrl(this.vm.mainListPage);
          })
          .catch((error: { description: any[] }) => {
            this.vm.buttonsDisabled = false;
            if (error.description === undefined) {
              this.notification.success($localize`:Updated@@updated:Updated`);
              this.router.navigateByUrl(this.vm.mainListPage);
            } else {
              this.notification.error(error.description);
            }
          });
      }

    } else {
      this.vm.buttonsDisabled = false;
      this.notification.error(errorMessage);
    }

  }
}
