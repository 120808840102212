import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipe } from 'ngx-filter-pipe';

@Pipe({
  name: 'customFilterBy',
})
export class CustomFilterPipe implements PipeTransform {
  constructor(public filterPipe: FilterPipe) {}

  transform(value: any[], filter: any, actual: boolean = false) {
    // console.log('value: ' + value + ' filterObject: ' + JSON.stringify(filter) + ' actual: ' + actual);
    if (!value.length || filter === undefined ) {
    }

    try {
      if (actual === false) {
        return this.filterPipe.transform(value, filter);
      }
    } catch (error) {
      console.log('value: ' + value + ' filterObject: ' + filter + ' actual: ' + actual);
    }

    try {
      const keys = Object.keys(filter);
      if (typeof keys[0] !== undefined && value) {
        // console.log('value from pipe: ' + JSON.stringify(value));
        return value.filter((innerValue) => innerValue[keys[0]] == filter[keys[0]]);
      }
      return null;
    } catch (error) {
    }
  }
}
