import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-payroll-payment-print',
  templateUrl: './payroll-payment-print.component.html',
  styleUrls: ['./payroll-payment-print.component.css']
})
export class PayrollPaymentPrintComponent implements OnInit {

  @Input() vm: any;
  @Input() check: any;
  detail;
  constructor() {
  }

  ngOnInit() {
  }

}
