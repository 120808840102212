import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TableDataService} from '../../../../Services/tableData.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfirmDialogService} from '../../../../Services/confirm-dialog.service';
import {CheckStockModel} from '../../../../DataModels/check-stock-model';
import { Options } from '@angular-slider/ngx-slider';
import {BsToastService} from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-check-stock-form',
  templateUrl: './check-stock-form.component.html',
  styleUrls: ['./check-stock-form.component.css']
})
export class CheckStockFormComponent implements OnInit, AfterViewInit {
  checkstockID;
  submitted = false;
  checkStock: CheckStockModel;
  checkStockForm: UntypedFormGroup;

  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  options: Options = {
    floor: 0,
    ceil: 850,
    step: 1
  };

  buttonsDisabled = false;

  constructor(private table: TableDataService,
              private notification: BsToastService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDialogService: ConfirmDialogService,
              private titleService: Title) {
    this.createForm();
    this.checkstockID = this.route.snapshot.params.id;
  }

  createForm() {
    this.checkStockForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      print_company: new UntypedFormControl(''),
      company_top: new UntypedFormControl(0),
      company_left: new UntypedFormControl(80),
      print_bank: new UntypedFormControl(''),
      bank_top: new UntypedFormControl(0),
      bank_left: new UntypedFormControl(400),
      print_check_number: new UntypedFormControl(''),
      check_number_top: new UntypedFormControl(0),
      check_number_right: new UntypedFormControl(0),
      date_top: new UntypedFormControl(75),
      date_right: new UntypedFormControl(0),
      date_style: new UntypedFormControl(0),
      payment_amount_top: new UntypedFormControl(125),
      payment_amount_right: new UntypedFormControl(0),
      payment_amount_prefix: new UntypedFormControl('$**'),
      text_amount_top: new UntypedFormControl(165),
      text_amount_left: new UntypedFormControl(20),
      text_amount_prefix: new UntypedFormControl('**'),
      text_amount_suffix: new UntypedFormControl(''),
      text_amount_width: new UntypedFormControl(760),

      repeater_on: new UntypedFormControl(''),

      print_pay_to: new UntypedFormControl(''),
      pay_to_top: new UntypedFormControl(120),
      pay_to_left: new UntypedFormControl(110),

      vendor_address_top: new UntypedFormControl(195),
      vendor_address_left: new UntypedFormControl(80),

      print_memo: new UntypedFormControl(''),
      memo_top: new UntypedFormControl(270),
      memo_left: new UntypedFormControl(80),
      memo_width: new UntypedFormControl(400),

      print_micr: new UntypedFormControl(''),
      micr_top: new UntypedFormControl(350),

      check_height: new UntypedFormControl(420),

      print_details: new UntypedFormControl(''),
      print_signature_line: new UntypedFormControl(''),

      active: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    if (this.checkstockID) {
      this.loadCheckStock();
      this.buttonConfig.reset = false;
    }
  }

  ngAfterViewInit() {
    this.titleService.setTitle($localize`:Check Stock @@checkStock:Check Stock`);
  }

  get f() {
    return this.checkStockForm.controls;
  }

  loadCheckStock() {
    this.table.getTableData('check_stock', this.checkstockID).subscribe(
      result => {
        this.checkStock = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.showErrorToast($localize`:Error loading Companies @@errorLoadingCompanies:Error loading Companies`);
        console.log(error);
      },
      () => {
        this.checkStockForm.patchValue(this.checkStock);
      });
  }

  onClose() {
    this.router.navigateByUrl('admin/check-stock-list').then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.checkStockForm.invalid) {
      this.notification.showErrorToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    // console.log(JSON.stringify(this.checkStockForm.value));
    this.checkStock = Object.assign({}, this.checkStockForm.value);

    // console.log(JSON.stringify(this.checkstock));
    if (this.checkstockID) {
      if (this.checkStockForm.invalid === false) {
        this.table.patch('check_stock', this.checkstockID, this.checkStock).then(result => {
            if (saveClose){
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          });
        // return true;
      }
    } else {
      this.table.postWithPromise('check_stock', this.checkStock).then(res => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.checkstockID = apiReturn.id;
        if (saveClose){
          this.onClose();
        }
      });
      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  resetForm() {
    this.checkStockForm.reset();
  }

  saveNew() {
    if (this.onSave(false)) {
      this.resetForm();
    }
  }

  deleteItem() {
    // console.log(this.checkstockID);
    let retMsg = '';
    const messages: string[] = [$localize`:Are you sure you want to delete check stock @@areYouSureYouWantToDeleteCheckstock:Are you sure you want to delete check stock?`, this.checkStockForm.get('name').value];
    this.confirmDialogService.confirmThis(messages,
      () => {
        this.table.deleteTableDataById('check_stock', this.checkstockID).subscribe(
          result => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.showErrorToast($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          });
      }, () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@deleteCanceled:Delete Canceled`);
      });
  }
}
