import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TableDataService } from '../../../../Services/tableData.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityTypeModel } from '../../../../DataModels/entity-type-model';
import { ConfirmDialogService } from '../../../../Services/confirm-dialog.service';
import { BsToastService } from '../../../../Services/bs-toast-service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-entity-type-form',
  templateUrl: './entity-type-form.component.html',
  styleUrls: ['./entity-type-form.component.css'],
})
export class EntityTypeFormComponent implements OnInit {
  buttonConfig: any = {
    save: true,
    close: true,
    reset: true,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: false,
  };

  entityType = new EntityTypeModel();
  entityTypeForm: UntypedFormGroup;
  entityTypeID;
  submitted = false;

  constructor(
    private table: TableDataService,
    private notification: BsToastService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private titleService: Title
  ) {
    this.createForm();
    this.entityTypeID = this.route.snapshot.params.id;
  }

  get f() {
    return this.entityTypeForm.controls;
  }

  createForm() {
    this.entityTypeForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      active: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    this.titleService.setTitle($localize`:Entity Type @@entityType:Entity Type`);

    if (this.entityTypeID) {
      this.loadEntityType();
      this.buttonConfig.reset = false;
    }
  }

  private loadEntityType() {
    this.table.getTableData('entity_type', this.entityTypeID).subscribe(
      (result) => {
        this.entityType = result;
      },
      (error: HttpErrorResponse) => {
        // this.notification.error($localize`:Error loading Entity Type @@errorLoadingEntityType:Error loading Entity Type`);
        console.log(
          error + ' - ' + $localize`:Error loading Entity Type @@errorLoadingEntityType:Error loading Entity Type`
        );
      },
      () => {
        this.entityTypeForm.patchValue(this.entityType);
      }
    );
  }

  saveClose() {
    if (this.onSave(true)) {
    }
  }

  saveNew() {
    if (this.onSave(false)) {
      this.entityTypeForm.reset();
    }
  }

  deleteItem() {
    let retMsg = '';
    const messages: string[] = [
      $localize`:Are you sure you want to delete entity type? @@areYouSureYouWantToDeleteEntityType:Are you sure you want to delete entity type?`,
      this.entityType.name,
    ];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.table.deleteTableDataById('entity_type', this.entityTypeID).subscribe(
          (result) => {
            retMsg = result;
            // this.notification.success($localize`:Deleted @@deleted:Deleted`);
          },
          (error: HttpErrorResponse) => {
            // this.notification.error($localize`:Error @@error:Error`);
            console.log(error);
          },
          () => {
            this.onClose();
          }
        );
      },
      () => {
        this.notification.showSuccessToast($localize`:Delete Canceled @@Delete Canceled:Delete Canceled`);
      }
    );
  }

  resetForm() {
    this.entityTypeForm.reset();
  }

  onClose() {
    this.router.navigate(['admin/entity-type-list']).then();
  }

  onSave(saveClose: boolean) {
    this.submitted = true;
    if (this.entityTypeForm.invalid) {
      this.notification.showWarningToast($localize`:Form Errors! @@formErrors:Form Errors!`);
      return;
    }
    if (this.entityTypeForm.get('id').value === '') {
      this.entityTypeForm.patchValue({ id: null, active: true });
    }
    this.entityType = Object.assign({}, this.entityTypeForm.value);

    // console.log('entity type: ' + JSON.stringify(this.entityType));

    if (this.entityTypeID) {
      if (this.entityTypeForm.invalid === false) {
        this.table.patch('entity_type', this.entityTypeID, this.entityType).then(
          (result) => {
            if (saveClose) {
              this.onClose();
            }
          },
          (error: HttpErrorResponse) => {
            //
            console.log(error);
          }
        );
        // return true;
      }
    } else {
      this.table.postWithPromise('entity_type', this.entityType).then((res) => {
        // stops double posting when clicking Save
        let apiReturn: any = null;
        if (typeof res === 'object') {
          apiReturn = JSON.parse(JSON.stringify(res));
        }
        this.entityTypeID = apiReturn.id;
        if (saveClose) {
          this.onClose();
        }
      });
      // this.notification.success($localize`:Saved @@saved:Saved`);
      // return true;
    }
    this.submitted = false;
    return true;
  }
}
