import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

// full calendar
import { FullCalendarModule } from '@fullcalendar/angular';

// modules
// import { PageTitleModule } from '../../shared/page-title/page-title.module';
import { CalendarRoutingModule } from './calendar-routing.module';

// components
import { CalendarComponent } from './calendar/calendar.component';
import { EventComponent } from './event/event.component';

@NgModule({
    declarations: [
        CalendarComponent,
        EventComponent
    ],
    exports: [
        CalendarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        FullCalendarModule,
        NgbModalModule,
        // PageTitleModule,
        CalendarRoutingModule
    ]
})
export class CalendarModule { }
