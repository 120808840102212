import { Injectable } from '@angular/core';
import { NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class NgbpaginationConfigService {

  constructor(private ngbConfig: NgbPaginationConfig) { }

  getNgbPaginationConfig(): NgbPaginationConfig {
    this.ngbConfig.maxSize = 3;
    this.ngbConfig.boundaryLinks = true;
    this.ngbConfig.directionLinks = true;
    this.ngbConfig.ellipses = true;
    this.ngbConfig.size = 'sm';
    this.ngbConfig.rotate = true;

    return this.ngbConfig;
  }
}
