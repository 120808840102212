import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TableDataService } from '../../../Services/tableData.service';
import { AppConfigService } from '../../../Services/app-config.service';
import { FilterPipe } from 'ngx-filter-pipe';
import { StorageService } from '../../../Services/storage.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ResponseModel } from '../../../DataModels/response-model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PagerStateModel } from '../../../DataModels/pager-state-model';
import { PagerStateService } from '../../../Services/pager-state.service';
import { DatatableConfigService } from '../../../Services/datatable-config.service';
import { NgbpaginationConfigService } from '../../../Services/ngbpagination-config.service';
import { BsToastService} from '../../../Services/bs-toast-service';

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.css'],
})
export class TimesheetListComponent implements OnInit, AfterViewInit, OnDestroy {
  vm: any = {
    list: [],
    title: $localize`:Time Sheet List @@timeSheetList:Time Sheet List`,
    Preferences: [],
    permissions: [],
    listInitiated: false,
    formRoute: '/timesheet/timesheet-form',
  };

  timesheetCount = 0;
  isLoaded = false;

  constructor(
    private localStorage: StorageService,
    private titleService: Title,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private filterPipe: FilterPipe,
    private appConfig: AppConfigService,
    private router: Router,
    private ngbConfig: NgbPaginationConfig,
    private pagerStatus: PagerStateService,
    private dtConfig: DatatableConfigService,
    private pagerConfig: NgbpaginationConfigService
  ) {
    this.ngbConfig = this.pagerConfig.getNgbPaginationConfig();
  }

  // datatables stuff
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dataTableReady = false;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  dtTrigger: Subject<any> = new Subject<any>();
  dtSearchValue: string;
  dtPreviousSearchValue: string;
  dtLastFilterValue: string;

  // page size select
  pageSizeOptions: number[];

  // ngb paginate
  page = 1;
  pageSize = this.appConfig.ListPageSize;
  startIndex = 1;
  endIndex = 0;
  totalRecords = 0;

  ngOnInit() {
    this.vm.list = [];
    this.pageSizeOptions = this.appConfig.gridPageSizeSelection;
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    this.configDataTable();
    this.getPagerStatus().then();
    this.dataInit();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  configDataTable() {
    this.dtOptions = this.dtConfig.getTableConfig([1, 'asc']);
  }

  async getPagerStatus() {
    this.pagerStatus
      .getPagerState(this.router.url)
      .then((res) => {
        if (res) {
          const pagerStuff = res;
          if (pagerStuff.totalRecords !== 0) {
            this.page = pagerStuff.lastPage;
            this.pageSize = pagerStuff.lastPageSize;
            this.startIndex = pagerStuff.startIndex;
            this.endIndex = pagerStuff.endIndex;
          }
        }
      })
      .catch((err) => {
        console.log('pager error: ', err);
      })
      .then();
  }

  // ngb paginate
  paginate(withDataFetch: boolean): void {
    // model change will trigger on set of values so ignore if there's no records
    if (this.totalRecords === 0 || this.vm.list.length === 0) {
      return;
    }
    if (this.totalRecords === 0) {
      this.startIndex = 0;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize + 1;
    }

    if (this.page === 1) {
      this.endIndex = Number((this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.endIndex = Number(this.page * this.pageSize);
    }

    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }

    if (this.totalRecords < this.pageSize) {
      this.endIndex = this.totalRecords;
    }
    // console.log('end: ' + this.endIndex + ' total: ' + this.totalRecords);

    const model = new PagerStateModel();
    model.lastPage = this.page;
    model.lastPageSize = this.pageSize;
    model.totalRecords = this.totalRecords;
    model.dateTime = +new Date();
    model.startIndex = this.startIndex;
    model.endIndex = this.endIndex;
    this.pagerStatus.storePagerState(this.router.url, model);
    if (withDataFetch) {
      this.getPagedApiData();
    }
  }

  dataInit() {
    let paramString = '?search=' + (this.vm.searchTerm ? this.vm.searchTerm : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getTableData('time', paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      // console.log(result.Data);
      this.paginate(false);
      this.dtTrigger.next('');
      this.dataTableReady = true;

      this.vm.listInitiated = true;
      this.timesheetCount = result.Data.length;
      this.isLoaded = true;
    });
  }

  onNavigate(aID: string) {
    this.router.navigate([this.vm.formRoute], { queryParams: { id: aID } }).then();
  }

  // ----------------- Server side paging --------------------

  getPagedApiData() {
    let paramString = '?search=' + (this.vm.searchTerm ? this.vm.searchTerm : '');
    paramString += '&pageNumber=' + ((this.page !== 0) ? this.page - 1 : 0);
    paramString += '&pageSize=' + this.pageSize;

    this.tableDataService.getApiListData('time', paramString).subscribe((result: ResponseModel) => {
      this.vm.list = result.Data;
      this.totalRecords = result.totalRecords;
      this.paginate(false);
      this.dataTableFullClear();
      this.dtTrigger.next('');
      this.dataTableAddLastFilter();
      this.dataTableReady = true;

      this.isLoaded = true;
    });
  }

  serverSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  clearServerSearch(value) {
    this.datatableClearFilter();
    this.vm.searchTerm = value.searchTerm;
    this.vm.fromDate = value.fromDate;
    this.vm.toDate = value.toDate;
    this.page = 1;
    this.getPagedApiData();
  }

  hideSearch() {
    this.vm.searchHidden = !this.vm.searchHidden;
  }

  datatableClearFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.search('');
    });
  }

  dataTableAddLastFilter() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (this.dtLastFilterValue !== '') {
        dtInstance.search(this.dtLastFilterValue).draw(false);
      }
    });
  }

  dataTableFullClear() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtLastFilterValue = dtInstance.search();
      dtInstance.destroy(false);
    });
  }
}
